import { SubdirectoryArrowRight } from '@rossum/ui/icons';
import {
  Box,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { constructDocumentUrl } from '../../../../lib/url';
import { dataDisabled } from '../../../../redux/modules/annotation/helpers';
import { ID } from '../../../../types/basic';
import Filename from '../../../../ui/filename/Filename';
import StatusChip from '../../../../ui/status/StatusChip';
import { AttachmentT, isAnnotationDisabled } from './helpers';
import styles from './styles.module.sass';

type Props = {
  // Attachment with annotation present.
  attachment: AttachmentT & Pick<Required<AttachmentT>, 'annotation'>;
  userId: ID;
  isChild: boolean;
  onMouseEnter: (attachment: AttachmentT) => void;
  onMouseLeave: () => void;
};

const AnnotationAttachment = ({
  attachment,
  userId,
  isChild,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const { annotation } = attachment;
  const intl = useIntl();

  const annotationAttachmentListItemButton = (
    <ListItemButton
      disableRipple
      disableGutters
      sx={{
        py: 0,
        pl: 2,
        borderRadius: 1,
      }}
    >
      {isChild && (
        <SubdirectoryArrowRight
          fontSize="small"
          sx={{ color: theme => theme.palette.text.secondary }}
        />
      )}
      <ListItemIcon sx={{ minWidth: 25, mr: 1 }}>
        {annotation.status !== 'purged' ? (
          <StatusChip
            status={annotation.status}
            isOwned={attachment.annotationModifier?.id === userId}
            modifier={attachment.annotationModifier?.username}
          />
        ) : null}
      </ListItemIcon>
      <ListItemText sx={{ my: 0 }}>
        <Link
          // TODO isAnnotationDisabled cursor
          onClick={e =>
            isAnnotationDisabled(annotation.status) && e.preventDefault()
          }
          target="_blank"
          href={constructDocumentUrl({ id: annotation.id })}
          rel="noreferrer"
          underline="none"
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Box
            className={styles.PagePreview}
            onMouseEnter={() => onMouseEnter(attachment)}
            onMouseLeave={() => onMouseLeave()}
            sx={{ py: 1 }}
          >
            <Typography
              variant="body2"
              color="text.primary"
              whiteSpace="nowrap"
              component="div"
            >
              <Filename
                status={attachment.annotation.status}
                disabled={dataDisabled.includes(annotation.status)}
                originalFilename={attachment.document.originalFileName}
                disableClipboard
              />
            </Typography>
          </Box>
        </Link>
      </ListItemText>
    </ListItemButton>
  );

  return annotation.restrictedAccess ? (
    <Stack position="relative" width="1">
      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography whiteSpace="nowrap" variant="body2">
          {intl.formatMessage({
            id: 'features.pricing.documentArchive.blurredAnnotation.text',
          })}
        </Typography>
      </Stack>

      <Stack
        sx={{
          filter: 'blur(4px)',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {annotationAttachmentListItemButton}
      </Stack>
    </Stack>
  ) : (
    annotationAttachmentListItemButton
  );
};

export default AnnotationAttachment;
