import { ReactNode } from 'react';
import styles from './style.module.sass';

type Props = {
  children: ReactNode;
};

const ListTable = ({ children }: Props) => (
  <table className={styles.ListTable}>{children}</table>
);

export default ListTable;
