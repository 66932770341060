import { SchemaItem, SchemaTuple } from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { FieldsFormModel } from '../formModels';
import {
  isAnyButtonFormModel,
  isAnySimpleValueFormModel,
  isCapturedFieldFormModel,
  isDataFieldFormModel,
  isDateFieldFormModel,
  isEnumFieldFormModel,
  isFormulaFieldFormModel,
  isLineItemButtonFormModel,
  isLineItemsFormModel,
  isLineItemSimpleValueFormModel,
  isManualFieldFormModel,
  isMultivalueFormModel,
  isNumberFieldFormModel,
  isSectionFormModel,
  isStringFieldFormModel,
  isUnsetFieldFormModel,
  LineItemsFormModel,
  SectionFormModel,
} from '../formModels.guards';
import {
  extractCanExport,
  extractCanObtainToken,
  extractDescription,
  extractExactLength,
  extractFormat,
  extractFormula,
  extractHidden,
  extractId,
  extractLabel,
  extractMaxLength,
  extractMinLength,
  extractOptions,
  extractPopupUrl,
  extractRegexpPattern,
  extractRequired,
  extractRirFieldNames,
  extractScoreThreshold,
  extractType,
  extractUiEdit,
  extractUiType,
} from './toApiModel.extractors';
import { PropertyEvolver } from './toApiModel.utils';

// `blueprint` refers to the fact we are "shaping" the resulting object based on what's contained
// in `blueprint`

// Evolvers for fields that can be in root or `children` of multivalue (a.k.a. `SchemaDatapoint` properties)
export const evolveCategory: PropertyEvolver<'category'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
            isAnyButtonFormModel,
          ]),
          R.constant('datapoint'),
        ],
        [
          R.anyPass([isMultivalueFormModel, isLineItemsFormModel]),
          R.constant('multivalue'),
        ],
        [isSectionFormModel, R.constant('section')],
        R.conditional.defaultCase(R.constant(fallback))
      )
    );

export const evolveId: PropertyEvolver<'id'> = blueprint => fallback =>
  R.pipe(blueprint, extractId(fallback));

export const evolveLabel: PropertyEvolver<'label'> = blueprint => fallback =>
  R.pipe(blueprint, extractLabel(fallback));

export const evolveDescription: PropertyEvolver<'description'> =
  blueprint => fallback =>
    R.pipe(blueprint, extractDescription(fallback));

export const evolveHidden: PropertyEvolver<'hidden'> = blueprint => fallback =>
  R.pipe(blueprint, extractHidden(fallback));

export const evolveCanExport: PropertyEvolver<'canExport'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        // equivalent to `if (isSimpleValueFormModel || isButtonFormModel || (isMultivalueFormModel && inChildren))`
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            isAnyButtonFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          extractCanExport(fallback),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveCanCollapse: PropertyEvolver<'canCollapse'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isLineItemSimpleValueFormModel,
            isLineItemButtonFormModel,
          ]),
          R.constant(fallback),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveRirFieldNames: PropertyEvolver<'rirFieldNames'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [isAnyButtonFormModel, R.constant(undefined)],
        [isLineItemsFormModel, extractRirFieldNames(fallback)],
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped(
            R.conditional(
              [
                R.anyPass([isCapturedFieldFormModel, isDataFieldFormModel]),
                extractRirFieldNames(fallback),
              ],
              R.conditional.defaultCase(R.constant([]))
            )
          ),
        ],
        [
          R.allPass([isMultivalueFormModel, () => !inChildren]),
          R.constant(undefined),
        ],
        R.conditional.defaultCase(R.constant([]))
      )
    );

export const evolveDefaultValue: PropertyEvolver<'defaultValue'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.constant(fallback),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveConstraints: PropertyEvolver<'constraints'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped((bp: FieldsFormModel) => {
            return [
              R.pipe(
                bp,
                R.conditional(
                  [
                    isStringFieldFormModel,
                    ff => ({
                      length: {
                        min: extractMinLength(fallback?.length?.min)(ff),
                        max: extractMaxLength(fallback?.length?.max)(ff),
                        exact: extractExactLength(fallback?.length?.exact)(ff),
                      },
                      regexp: {
                        pattern: extractRegexpPattern(
                          fallback?.regexp?.pattern
                        )(ff),
                      },
                    }),
                  ],
                  R.conditional.defaultCase(R.constant({}))
                )
              ),
              {
                required: extractRequired(fallback?.required)(bp),
              },
            ];
          }, R.mergeAll),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveUiConfiguration: PropertyEvolver<'uiConfiguration'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          bp => ({
            type: extractUiType(fallback?.type)(bp),
            edit: extractUiEdit(fallback?.edit)(bp),
          }),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveWidth: PropertyEvolver<'width'> = blueprint => fallback =>
  R.pipe(
    blueprint,
    R.conditional(
      [isLineItemSimpleValueFormModel, R.constant(fallback)],
      R.conditional.defaultCase(R.constant(undefined))
    )
  );

export const evolveStretch: PropertyEvolver<'stretch'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [isLineItemSimpleValueFormModel, R.constant(fallback)],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveWidthChars: PropertyEvolver<'widthChars'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [isLineItemSimpleValueFormModel, R.constant(fallback)],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveScoreThreshold: PropertyEvolver<'scoreThreshold'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped(
            R.conditional(
              [
                R.anyPass([
                  isFormulaFieldFormModel,
                  isDataFieldFormModel,
                  isManualFieldFormModel,
                ]),
                R.constant(0),
              ],
              [
                R.anyPass([isCapturedFieldFormModel, isUnsetFieldFormModel]),
                extractScoreThreshold(fallback),
              ],
              R.conditional.defaultCase(R.constant(undefined))
            )
          ),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveFormula: PropertyEvolver<'formula'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped(
            R.conditional(
              [isFormulaFieldFormModel, extractFormula(fallback)],
              R.conditional.defaultCase(R.constant(undefined))
            )
          ),
        ],

        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveDisablePrediction: PropertyEvolver<'disablePrediction'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.allPass([isAnySimpleValueFormModel, isCapturedFieldFormModel]),
          R.constant(false),
        ],
        [
          R.anyPass([
            isFormulaFieldFormModel,
            isDataFieldFormModel,
            isManualFieldFormModel,
          ]),
          R.constant(true),
        ],
        R.conditional.defaultCase(R.constant(fallback))
      )
    );

export const evolveType: PropertyEvolver<'type'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [isAnyButtonFormModel, R.constant('button')],
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          extractType(fallback),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveFormat: PropertyEvolver<'format'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped(
            R.conditional(
              [
                R.anyPass([isNumberFieldFormModel, isDateFieldFormModel]),
                extractFormat(fallback),
              ],
              R.conditional.defaultCase(R.constant(undefined))
            )
          ),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveAggregations: PropertyEvolver<'aggregations'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped(
            R.conditional(
              [isNumberFieldFormModel, R.constant(fallback)],
              R.conditional.defaultCase(R.constant(undefined))
            )
          ),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveOptions: PropertyEvolver<'options'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped(
            R.conditional(
              [isEnumFieldFormModel, extractOptions(fallback)],
              R.conditional.defaultCase(R.constant(undefined))
            )
          ),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveEnumValueType: PropertyEvolver<'enumValueType'> =
  (blueprint, inChildren) => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([
            isAnySimpleValueFormModel,
            R.allPass([isMultivalueFormModel, () => !!inChildren]),
          ]),
          R.piped(
            R.conditional(
              [isEnumFieldFormModel, R.constant(fallback)],
              R.conditional.defaultCase(R.constant(undefined))
            )
          ),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

// Evolvers for fields that only exist in `SchemaDatapointButton`
export const evolvePopupUrl: PropertyEvolver<'popupUrl'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [isAnyButtonFormModel, extractPopupUrl(fallback)],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveCanObtainToken: PropertyEvolver<'canObtainToken'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [isAnyButtonFormModel, extractCanObtainToken(fallback)],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

// Evolvers for fields that only exist in `SchemaSimpleMultivalue` and `SchemaTableMultivalue`
export const evolveMinOccurrences: PropertyEvolver<'minOccurences'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([isMultivalueFormModel, isLineItemsFormModel]),
          R.constant(fallback),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveMaxOccurrences: PropertyEvolver<'maxOccurences'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([isMultivalueFormModel, isLineItemsFormModel]),
          R.constant(fallback),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

export const evolveGrid: PropertyEvolver<'grid'> = blueprint => fallback =>
  R.pipe(
    blueprint,
    R.conditional(
      [
        R.anyPass([isMultivalueFormModel, isLineItemsFormModel]),
        R.constant(fallback),
      ],
      R.conditional.defaultCase(R.constant(undefined))
    )
  );

export const evolveShowGridByDefault: PropertyEvolver<'showGridByDefault'> =
  blueprint => fallback =>
    R.pipe(
      blueprint,
      R.conditional(
        [
          R.anyPass([isMultivalueFormModel, isLineItemsFormModel]),
          R.constant(fallback),
        ],
        R.conditional.defaultCase(R.constant(undefined))
      )
    );

const makeEvolverWithoutChildren =
  (inChildren: boolean) => (blueprint: FieldsFormModel) => {
    return {
      category: evolveCategory(blueprint, inChildren),
      id: evolveId(blueprint),
      label: evolveLabel(blueprint, inChildren),
      description: evolveDescription(blueprint, inChildren),
      hidden: evolveHidden(blueprint, inChildren),
      canExport: evolveCanExport(blueprint, inChildren),
      type: evolveType(blueprint, inChildren),
      constraints: evolveConstraints(blueprint, inChildren),
      options: evolveOptions(blueprint, inChildren),
      enumValueType: evolveEnumValueType(blueprint, inChildren),
      format: evolveFormat(blueprint, inChildren),
      uiConfiguration: evolveUiConfiguration(blueprint, inChildren),
      rirFieldNames: evolveRirFieldNames(blueprint, inChildren),
      scoreThreshold: evolveScoreThreshold(blueprint, inChildren),
      formula: evolveFormula(blueprint, inChildren),
      popupUrl: evolvePopupUrl(blueprint, inChildren),
      canObtainToken: evolveCanObtainToken(blueprint, inChildren),
      defaultValue: evolveDefaultValue(blueprint, inChildren),
      canCollapse: evolveCanCollapse(blueprint, inChildren),
      width: evolveWidth(blueprint, inChildren),
      stretch: evolveStretch(blueprint, inChildren),
      widthChars: evolveWidthChars(blueprint, inChildren),
      disablePrediction: evolveDisablePrediction(blueprint, inChildren),
      aggregations: evolveAggregations(blueprint, inChildren),
      minOccurrences: evolveMinOccurrences(blueprint, inChildren),
      maxOccurrences: evolveMaxOccurrences(blueprint, inChildren),
      grid: evolveGrid(blueprint, inChildren),
      showGridByDefault: evolveShowGridByDefault(blueprint, inChildren),
    };
  };

const evolveLineItemChildren =
  (blueprint: LineItemsFormModel) => (fallback: SchemaTuple) =>
    R.merge(fallback, {
      id: fallback.id || evolveId(blueprint)(fallback.id),
      label: fallback.label || evolveLabel(blueprint, true)(fallback.label),
      description:
        fallback.description ||
        evolveDescription(blueprint, true)(fallback.description),
    });

const evolveSectionChildren =
  (_blueprint: SectionFormModel) => (fallback: SchemaItem[]) =>
    fallback;

const makeOnlyChildrenEvolver = (blueprint: FieldsFormModel) =>
  R.pipe(
    blueprint,
    R.conditional(
      [isLineItemsFormModel, bp => ({ children: evolveLineItemChildren(bp) })],
      [isSectionFormModel, bp => ({ children: evolveSectionChildren(bp) })],
      [
        isMultivalueFormModel,
        bp => ({ children: makeEvolverWithoutChildren(true)(bp) }),
      ],
      R.conditional.defaultCase(R.constant({}))
    )
  );

export const makeEvolver = (blueprint: FieldsFormModel) =>
  R.mergeAll([
    makeEvolverWithoutChildren(false)(blueprint),
    makeOnlyChildrenEvolver(blueprint),
  ]);
