import { ExtensionOutlined, ShortTextOutlined } from '@rossum/ui/icons';
import {
  find,
  first,
  flattenDeep,
  get,
  isArray,
  isEmpty,
  isString,
  isUndefined,
  join,
  keys,
  negate,
  pickBy,
  values,
} from 'lodash';
import { Formula } from '../../../components/icons/Formula';
import {
  AnyDatapointSchema,
  AnySchemaObject,
  ButtonSchemaObject,
  EnumSchemaObject,
  FormatSchemaObject,
  OriginalDatapointSchema,
  SchemaUIConfiguration,
  SimpleMultivalueSchemaObject,
  StringSchemaObject,
  TableMultivalueSchemaObject,
  UiFieldType,
} from '../../../types/schema';
import { SchemaBeforeFlattening, SchemaFulfilledPayload } from './types';

export const flattenSchema = (
  schema: SchemaBeforeFlattening
): SchemaFulfilledPayload => ({
  ...schema,
  originalContent: schema.content,
  content: flattenDeep(
    schema.content.map((tree, index) => flattenSchemaTree(tree, [`${index}`]))
  ),
});

const joinDatapoints =
  (datapoints: Array<AnyDatapointSchema>) =>
  ({
    children: __children,
    path,
    ...rest
  }: AnyDatapointSchema): OriginalDatapointSchema => {
    const _children =
      __children &&
      __children
        .map(id => find(datapoints, { id }))
        .filter((datapoint): datapoint is AnyDatapointSchema => !!datapoint)
        .map(joinDatapoints(datapoints));

    const children =
      rest.category === 'multivalue' ? first(_children) : _children;

    return pickBy(
      { children, ...rest },
      negate(isUndefined)
    ) as OriginalDatapointSchema;
  };

export const nestSchema = ({ content, ...schema }: SchemaFulfilledPayload) => ({
  content: content
    .filter(({ category }) => category === 'section')
    .map(joinDatapoints(content)),
  ...schema,
});

const messageBodySeparator = '###UltimateMessageBodySeparator###';

const generateMessage = (message: string, messageKeys: string[] = []) => {
  if (!messageKeys.length) return message;
  return `${join(messageKeys, ' ')} ${messageBodySeparator}${message}`;
};

const parseErrors = <
  T = Record<string, string | Record<string, string> | Array<string>>,
>(
  errorObject: T | Array<T> | string,
  messageKeys: string[] = []
): string[] => {
  if (typeof errorObject !== 'string' && !(errorObject instanceof Array)) {
    return parseErrors(
      values(errorObject).reduce<string[]>(
        (acc, _errorObject, i) => [
          ...acc,
          ...parseErrors(_errorObject, [...messageKeys, keys(errorObject)[i]]),
        ],
        []
      )
    );
  }

  if (isArray(errorObject)) {
    return flattenDeep<string>(
      errorObject.reduce<string[]>(
        (acc, _errorObject) =>
          isString(_errorObject)
            ? [...acc, generateMessage(_errorObject, messageKeys)]
            : [...acc, ...parseErrors(_errorObject, messageKeys)],
        []
      )
    );
  }

  return [generateMessage(errorObject as string, messageKeys)];
};

export const generateErrors = (
  errorObject:
    | Record<string, string | Array<string> | Record<string, string>>
    | Array<unknown>
    | undefined
): Array<{ key: string; message: string }> => {
  if (errorObject === undefined || isEmpty(errorObject)) return [];

  const parsedErrors = parseErrors(errorObject);

  return parsedErrors.map((error: string) => {
    const [key, message] = error.split(messageBodySeparator);
    return { key, message };
  });
};

export const isFormatSchemaObject = (
  datapoint: AnySchemaObject
): datapoint is FormatSchemaObject =>
  datapoint.category === 'datapoint' &&
  ['date', 'number'].includes(datapoint.type);

export const isStringSchemaObject = (
  datapoint: AnySchemaObject
): datapoint is StringSchemaObject =>
  datapoint.category === 'datapoint' && ['string'].includes(datapoint.type);

export const isEnumSchemaObject = (
  datapoint: AnySchemaObject
): datapoint is EnumSchemaObject =>
  (datapoint as EnumSchemaObject).options !== undefined;

export const isButtonSchemaObject = (
  datapoint: AnySchemaObject
): datapoint is ButtonSchemaObject =>
  datapoint.category === 'datapoint' && datapoint.type === 'button';

export const isTableMultivalue = (
  datapoint: AnySchemaObject
): datapoint is TableMultivalueSchemaObject =>
  (datapoint as TableMultivalueSchemaObject).category === 'multivalue' &&
  (datapoint as TableMultivalueSchemaObject).children.children !== undefined;

export const isSimpleMultivalue = (
  datapoint: AnySchemaObject
): datapoint is SimpleMultivalueSchemaObject =>
  (datapoint as SimpleMultivalueSchemaObject).category === 'multivalue' &&
  !('children' in (datapoint as SimpleMultivalueSchemaObject).children);

// using lodash's `get` here to avoid the necessity of having typeguard
// TS cannot infer correctly the type from AnyDatapointSchema union
export const getUIConfigurationFromSchema = (
  schema: AnyDatapointSchema | undefined
) => {
  const uiConfiguration: SchemaUIConfiguration | undefined = get(schema, [
    'uiConfiguration',
  ]);

  return uiConfiguration;
};

export const getUIEditabilityFromSchema = (
  schema: AnyDatapointSchema | undefined
) => {
  const uiEdit = getUIConfigurationFromSchema(schema)?.edit ?? 'enabled';

  return uiEdit;
};

export const getUITypeFromSchema = (schema: AnyDatapointSchema | undefined) => {
  const uiType = getUIConfigurationFromSchema(schema)?.type;

  return uiType;
};

export const isFieldEditable = (schema: AnyDatapointSchema | undefined) => {
  const uiEdit = getUIEditabilityFromSchema(schema);

  return uiEdit !== 'disabled';
};

export const unselectableTypesWithoutBbox: Array<NonNullable<UiFieldType>> = [
  'data',
  'manual',
  'formula',
];

export const isUnselectableType = (schema: AnyDatapointSchema | undefined) => {
  const uiFieldType = getUITypeFromSchema(schema);

  return uiFieldType && unselectableTypesWithoutBbox.includes(uiFieldType);
};

/** Denotes if datapoint is focusable/selectable based on its schema configuration
 *
 * Exceptional states are when uiConfiguration.type is not defined (undefined or null - legacy fields) or set to `captured`
 * in this case it is considered selectable even with edit: `disabled` because user can enter new value using bbox. `captured` fields
 * have special placeholder "Use a bounding box" in this case.
 *
 */
export const isFieldSelectable = (schema: AnyDatapointSchema | undefined) => {
  const dependsOnEditability = isUnselectableType(schema);
  const isEditable = isFieldEditable(schema);

  return dependsOnEditability ? isEditable : true;
};

export const canHaveBbox = (schema: AnyDatapointSchema | undefined) => {
  return !!(
    schema &&
    schema.category === 'datapoint' &&
    !['enum', 'button'].includes(schema.type) &&
    !isUnselectableType(schema)
  );
};

// TODO: @formulas-v2 - unify place of icons definition with fieldsIconsMap
export const uiFieldTypeIconsMap: Partial<
  Record<NonNullable<UiFieldType>, React.ElementType>
> = {
  data: ExtensionOutlined,
  manual: ShortTextOutlined,
  formula: Formula,
};

const flattenSchemaTree = (
  tree: OriginalDatapointSchema,
  path: Array<string>
): AnyDatapointSchema[] | AnyDatapointSchema => {
  if ('children' in tree && tree.children !== undefined) {
    const childrenArray = ([] as OriginalDatapointSchema[]).concat(
      tree.children
    );
    return [
      { ...tree, path, children: childrenArray.map(({ id }) => id) },
      ...childrenArray
        .map((child: OriginalDatapointSchema, index) => {
          const indexSuffix = Array.isArray(tree.children) ? [`${index}`] : [];
          const newPath = [...path, 'children', ...indexSuffix];
          return flattenSchemaTree(child, newPath);
        })
        .flat(),
    ];
  }

  return { ...tree, path, children: undefined };
};
