import { DedicatedEngineListQuery } from '@rossum/api-client/dedicatedEngines';
import { EngineFieldListQuery } from '@rossum/api-client/engineFields';
import { EngineListQuery } from '@rossum/api-client/engines';
import { GenericEngineListQuery } from '@rossum/api-client/genericEngines';
import { QueueListQuery } from '@rossum/api-client/queues';

// Design pattern from https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

const scope = 'features/engines' as const;

export const engineKeys = {
  all: () => [scope, 'engines'] as const,
  lists: () => [...engineKeys.all(), 'list'] as const,
  list: ({ filters }: { filters: EngineListQuery }) =>
    [...engineKeys.lists(), { filters }] as const,
  details: () => [...engineKeys.all(), 'detail'] as const,
  detail: (id: number) => [...engineKeys.details(), id] as const,
};

export const engineFieldKeys = {
  all: () => [scope, 'engine_fields'] as const,
  lists: () => [...engineFieldKeys.all(), 'list'] as const,
  list: ({ filters }: { filters: EngineFieldListQuery }) =>
    [...engineFieldKeys.lists(), { filters }] as const,
  unpaginatedList: ({ filters }: { filters: EngineFieldListQuery }) =>
    [...engineFieldKeys.lists(), 'unpaginated', { filters }] as const,
  details: () => [...engineFieldKeys.all(), 'detail'] as const,
  detail: (id: number) => [...engineFieldKeys.details(), id] as const,
};

export const pretrainedFieldKeys = {
  list: () => [scope, 'pretrained_fields'] as const,
};

export const genericEngineKeys = {
  list: (query: GenericEngineListQuery) =>
    [scope, 'generic_engines', query] as const,
};

export const dedicatedEngineKeys = {
  list: (query: DedicatedEngineListQuery) =>
    [scope, 'dedicated_engines', query] as const,
};

export const queueInfoKeys = {
  list: (query: QueueListQuery) => [scope, 'queue_info', query] as const,
  stats: (engineUrl: string) => [scope, 'queue_stats', engineUrl] as const,
};

// Probably no point in scoping mutations
export const mutationKey = <T extends ReadonlyArray<unknown>>(key: T) =>
  [scope, ...key] as const;
