import PagePreviewDrawer from '../../../ui/page-preview/PagePreviewDrawer';
import { usePagePreview } from '../hooks/usePagePreview';

type PagePreviewProps = {
  originalFileName: string | undefined;
  pages: Array<string>;
};

const PagePreview = ({ pages, originalFileName }: PagePreviewProps) => {
  const { data: pagePreviewData } = usePagePreview(pages[0]);

  return (
    <PagePreviewDrawer
      previewImage={pagePreviewData}
      originalFileName={originalFileName}
      pageCount={pages.length}
    />
  );
};

export default PagePreview;
