import { Url } from '@rossum/api-client';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@rossum/ui/x-data-grid-pro';
import { compact } from 'lodash';
import { useIntl } from 'react-intl';
import RenderDate from '../../document-list/columns/RenderDate';
import { RenderDetails } from '../../document-list/columns/RenderDetails';
import RenderStatus from '../../document-list/columns/RenderStatus';
import { SupportedAnnotationView } from '../../document-list/hooks/useDashboardQuery';
import { useUnpaginatedWorklowRuns } from '../../document-list/hooks/useUnpaginatedWorkflowRuns';
import { useLabelsEnabled } from '../../labels/hooks/useLabelsEnabled';
import { FILE_NAME_WIDTH, SimpleFilename } from '../components/SimpleFilename';
import { SimpleLabels } from '../components/SimpleLabels';
import { SimpleQueue } from '../components/SimpleQueue';
import { SimpleColumnsLocation } from '../types';
import { TransformedAnnotation } from './useTransformAnnotation';

const LABELS_WIDTH = 150;
const EMPTY_SPACE = 115;

const getFileNameColumnWidth = (
  hasRemoveAction: boolean,
  isLabelsEnabled: boolean
) => {
  const labelsColumnOffset = isLabelsEnabled ? 0 : LABELS_WIDTH;
  const removeColumnOffset = hasRemoveAction ? 0 : EMPTY_SPACE;

  return FILE_NAME_WIDTH + removeColumnOffset + labelsColumnOffset;
};

const defaultColumnConfig = {
  filterable: false,
  sortable: false,
  pinnable: false,
  disableReorder: true,
};

type Props = {
  removeProps?: {
    onRemove: (annotationUrl: Url) => void;
    dataCyPrefix: string;
    Icon: React.ElementType;
    disableButton?: (annotation: TransformedAnnotation) => boolean;
  };
  columnsLocation?: SimpleColumnsLocation;
  detailsColumnProps?: {
    handleSelectAnnotation: (params: {
      annotationUrl: string;
      view: SupportedAnnotationView;
    }) => void;
    annotations: TransformedAnnotation[];
  };
};

export type SimpleColumnFieldNames =
  | 'status'
  | 'original_file_name'
  | 'queueName'
  | 'labels'
  | 'createdAt'
  | 'actions'
  | 'details';

export type SimpleColumn = Omit<GridColDef<TransformedAnnotation>, 'field'> & {
  field: SimpleColumnFieldNames;
  getActions?: GridActionsColDef['getActions'];
};

export const useSimpleColumns = ({
  removeProps,
  columnsLocation,
  detailsColumnProps,
}: Props = {}) => {
  const intl = useIntl();
  const labelsEnabled = useLabelsEnabled();

  const { data: workflowRuns } = useUnpaginatedWorklowRuns({
    annotationIds: detailsColumnProps?.annotations?.map(a => a.id) ?? [],
  });

  const columns: SimpleColumn[] = compact([
    {
      field: 'status',
      width: 140,
      renderCell: RenderStatus,
      headerName: intl.formatMessage({
        id: 'components.documentOverview.status',
      }),
    },
    {
      field: 'original_file_name',
      width: getFileNameColumnWidth(!!removeProps, labelsEnabled),
      headerName: intl.formatMessage({
        id: 'components.documentOverview.document__original_file_name',
      }),
      renderCell: props => (
        <SimpleFilename
          {...props}
          shouldShowAttachmentWarning={columnsLocation === 'attachmentsModal'}
        />
      ),
    },
    detailsColumnProps && {
      field: 'details',
      headerName: intl.formatMessage({
        id: 'components.documentOverview.details',
      }),
      width: 140,
      renderCell: props => {
        const hasWorkflowRun =
          workflowRuns?.some(
            ({ annotation }) => annotation === props.row.url
          ) ?? false;

        return (
          <RenderDetails
            {...props}
            hasWorkflowRun={hasWorkflowRun}
            handleSelectAnnotation={detailsColumnProps.handleSelectAnnotation}
            componentLocation={columnsLocation}
            row={{
              ...props.row,
              hasAttachments: props.row.hasAttachmentRelation,
            }}
          />
        );
      },
    },
    {
      field: 'queueName',
      headerName: intl.formatMessage({
        id: 'components.documentOverview.queue',
      }),
      width: 145,
      renderCell: SimpleQueue,
    },
    labelsEnabled && {
      field: 'labels',
      headerName: intl.formatMessage({
        id: 'components.documentOverview.labels',
      }),
      width: LABELS_WIDTH,
      renderCell: SimpleLabels,
    },
    {
      field: 'createdAt',
      headerName: intl.formatMessage({
        id: 'components.documentOverview.createdAtAlt',
      }),
      width: 100,
      renderCell: RenderDate,
      valueGetter: ({ value }) => value && new Date(value),
    },
    removeProps && {
      field: 'actions',
      type: 'actions',
      width: 50,
      getActions: ({ row }) => {
        const { onRemove, dataCyPrefix, Icon, disableButton } = removeProps;

        return [
          <GridActionsCellItem
            key={`actions-${row.id}`}
            data-cy={`${dataCyPrefix}-remove-${row.id}`}
            icon={<Icon />}
            onClick={() => {
              onRemove?.(row.url);
            }}
            label="Remove"
            disabled={disableButton?.(row) ?? false}
          />,
        ];
      },
    },
  ]);

  return columns.map(col => ({ ...defaultColumnConfig, ...col }));
};
