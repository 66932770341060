import { useSelector } from 'react-redux';
import SuggestionPositionsDialog from '../../../../components/DocumentPage/SuggestionPositionsDialog';
import { currentDatapointSelector } from '../../../../redux/modules/datapoints/selector';
import { suggestedPositionsForValueSelector } from '../../../../redux/modules/datapoints/suggestedPositionsForValue/selectors';
import { rectangleFromCoordinates } from '../../document-canvas/utils/geometry';
import { useSuggestedPositionsContext } from '../../SuggestedPositionsContext';
import { UseCanvasDimensions } from '../useCanvasDimensions';
import { ContentInBboxCoordinates } from './ContentInBboxCoordinates';

type SuggestedPositionDialogSvgProps = {
  dimensions: UseCanvasDimensions;
};

export const SuggestedPositionDialogSvg = ({
  dimensions,
}: SuggestedPositionDialogSvgProps) => {
  const {
    setNextPosition,
    setPreviousPosition,
    confirmSuggestion,
    currentIndex,
    closeSuggestingPositions,
  } = useSuggestedPositionsContext();

  const currentDatapoint = useSelector(currentDatapointSelector);
  const suggestedPositions = useSelector(suggestedPositionsForValueSelector);
  const box = currentDatapoint
    ? suggestedPositions[currentDatapoint.id]?.[currentIndex]
    : undefined;

  if (!box) {
    return null;
  }

  const page = dimensions.pages.find(page => box.page === page.pageNumber);
  const boxRectangle = rectangleFromCoordinates(box.rectangle);
  const position = page
    ? {
        x: page.dimensions.x + boxRectangle.x,
        y: page.dimensions.y + boxRectangle.y,
        height: boxRectangle.height,
        width: boxRectangle.width,
      }
    : undefined;

  if (!position) return null;

  return (
    <ContentInBboxCoordinates
      id="editbox-wrapper"
      pos={position}
      style={{ width: 'auto' }}
      sx={{ paddingTop: 1, zIndex: 10002 }}
    >
      <div style={{ pointerEvents: 'all' }}>
        <SuggestionPositionsDialog
          onRejectClick={closeSuggestingPositions}
          onNextClick={setNextPosition}
          onPrevClick={setPreviousPosition}
          onConfirmClick={confirmSuggestion}
        />
      </div>
    </ContentInBboxCoordinates>
  );
};
