import { yupResolver } from '@hookform/resolvers/yup';
import { Url } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { DriveFileMove } from '@rossum/ui/icons';
import { MenuItem, Stack, Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useMoveQueue } from '../../../../business/queues/useMoveQueue';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import { boldText } from '../../../../lib/formaterValues';
import { WorkspaceWithQueues } from '../../../document-list/helpers';

type Props = {
  queue: Queue;
  targetWorkspaceUrl?: Url;
  onMove: () => void;
  onCancel: () => void;
  workspaces: WorkspaceWithQueues[];
};

type MoveQueueFormModel = {
  workspace: string | undefined;
};

const moveQueueFormSchema = (
  currentWorkspace: string | undefined,
  intl: IntlShape
) =>
  yup.object().shape({
    workspace: yup
      .string()
      .required('required')
      .notOneOf(
        [currentWorkspace],
        intl.formatMessage({ id: 'components.modal.moveQueue.error.text' })
      ),
  });

export const QueueMove = ({
  queue,
  targetWorkspaceUrl,
  onMove,
  onCancel,
  workspaces,
}: Props) => {
  const { mutate: moveQueue, isLoading } = useMoveQueue(onMove);

  const intl = useIntl();

  const formSubmitted = (model: MoveQueueFormModel) => {
    if (model.workspace) {
      moveQueue({ queueId: queue.id, workspace: model.workspace });
    }
  };

  const queueWorkspace = queue.workspace ?? undefined;
  const defaultValues: MoveQueueFormModel = {
    workspace: targetWorkspaceUrl ?? queueWorkspace,
  };

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(moveQueueFormSchema(queueWorkspace, intl)),
  });

  return (
    <form onSubmit={handleSubmit(formSubmitted)}>
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'components.modal.moveQueue.title',
        })}
        onCancel={() => onCancel()}
        dialogColor="primary"
        DialogTitleIcon={DriveFileMove}
        dialogActions={
          <ActionDialogActions
            confirmButtonProps={{
              type: 'submit',
              disabled: isLoading,
            }}
            confirmButtonText={intl.formatMessage({
              id: 'components.modal.moveQueue.button.delete',
            })}
            dataCyConfirm="move-queue-confirm-btn-footer"
            dataCyCancel="move-queue-cancel-btn-footer"
          />
        }
        dataCy="move-queue-cancel-btn"
      >
        <Stack spacing={2} direction="column">
          <Typography variant="body1">
            {intl.formatMessage(
              {
                id: 'components.modal.moveQueue.text',
              },
              {
                boldText,
                queue: queue.name,
              }
            )}
          </Typography>
          <TextFieldControl
            ControllerProps={{ control, name: 'workspace' }}
            FieldLabelProps={{ layout: 'floating' }}
            label={intl.formatMessage({
              id: 'components.queueDetailForm.workspace.label',
            })}
            select
            autoComplete="off"
          >
            {(workspaces ?? []).map(workspace => (
              <MenuItem
                key={workspace.id}
                value={workspace.url}
                data-cy={`queue-form-workspace-item-${workspace.id}`}
              >
                {workspace.name}
              </MenuItem>
            ))}
          </TextFieldControl>
        </Stack>
      </ActionDialog>
    </form>
  );
};
