import { disallowedStatuses } from '../../features/document-list/filtering/constants';
import { Status } from '../../types/annotationStatus';

export const ALL_DOCUMENTS_TAB = 'ALL_DOCUMENTS_TAB';
export const REVIEW_TAB = 'REVIEW_TAB';
export const POSTPONED_TAB = 'POSTPONED_TAB';
export const CONFIRMED_TAB = 'CONFIRMED_TAB';
export const EXPORT_TAB = 'EXPORT_TAB';
export const TRASH_TAB = 'TRASH_TAB';
export const REJECTED_TAB = 'REJECTED_TAB';
export const IN_WORKFLOW_TAB = 'IN_WORKFLOW_TAB';

export type AnnotationTabType =
  | typeof ALL_DOCUMENTS_TAB
  | typeof REVIEW_TAB
  | typeof POSTPONED_TAB
  | typeof CONFIRMED_TAB
  | typeof EXPORT_TAB
  | typeof TRASH_TAB
  | typeof REJECTED_TAB
  | typeof IN_WORKFLOW_TAB;

type ExcludedStatuses = (typeof disallowedStatuses)[number];

type SupportedStatus = Exclude<Status, ExcludedStatuses>;

export const isStatusVisible = (s: Status): s is SupportedStatus =>
  !(disallowedStatuses as readonly string[]).includes(s);

export type FilterableAnnotationOptions =
  | 'created_at'
  | 'modified_at'
  | 'exported_at'
  | 'confirmed_at'
  | 'rejected_at'
  | 'deleted_at';
