import { isEqual, isString, last } from 'lodash';
import { FieldPath } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import Immutable from 'seamless-immutable';
import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { assertNever, DistributiveOmit } from '../../../../lib/typeUtils';
import {
  isButtonSchemaObject,
  isEnumSchemaObject,
  isFormatSchemaObject,
  isSimpleMultivalue,
  isStringSchemaObject,
  isTableMultivalue,
} from '../../../../redux/modules/schema/helpers';
import {
  EditableSchemaObject,
  EnumOption,
  FieldEditability,
  NestedSchemaObject,
  SchemaObjectType,
  SectionSchemaObject,
  SimpleSchemaObject,
  TupleSchemaObject,
  UiFieldType,
} from '../../../../types/schema';
import {
  calculateCanExport,
  flattenWithPaths,
  yupToReactHookFormPathNotation,
} from './helpers';

export type ResolverContext = {
  schemaObjectPath: string;
  rawValidationErrors: unknown[] | Record<string, unknown>;
  backendValidationStatus: BackendValidationStatus;
  schemaConcept: SectionSchemaObject[];
};

const FORMULA_MAX_LENGTH = 2000;

const idUniquenessTest = (
  intl: IntlShape
): yup.TestConfig<string | undefined, AnyObject> => ({
  name: 'unique-id',
  message: intl.formatMessage({
    id: 'containers.settings.fields.edit.id.unique',
  }),
  exclusive: true,
  test: (id, context) => {
    // Since this is a nested schema object, we need to get the root one.
    // context.from is not exposed in types, but in runtime it contains
    // a stack of schemas (the last one is the root one).
    // @ts-expect-error
    const values: SchemaObjectFormData = last(context.from).value;

    const currentPath = yupToReactHookFormPathNotation(context.path);

    const sameIdsInThisSchemaObject = flattenWithPaths<
      string,
      FieldPath<SchemaObjectFormData>
    >(values, isString).filter(
      ([path, value]) =>
        // 1. other ID fields
        path !== currentPath &&
        // 2. that are active for the selected field type
        (path === 'id' ||
          (path === 'simpleMultivalueChildren.id' &&
            values.fieldType === 'simple-multivalue') ||
          (values.fieldType === 'table-multivalue' &&
            /^children\.children\.\d+\.id$/.test(path))) &&
        // 3. have the same value
        value === id
    );

    const isId = (value: unknown, key: string): value is string =>
      key === 'id' && typeof value === 'string';

    const resolverContext = context.options.context as ResolverContext;

    const otherIdsWithPaths = flattenWithPaths(
      resolverContext.schemaConcept,
      isId
    ).filter(
      // Ignore part of the schema that we are currently editing

      input => !input[0].startsWith(`${resolverContext.schemaObjectPath}.`)
    );

    // As a potential improvement, by using context.createError we could return the message which would contain
    // the labels of the schema objects where the duplicated ID is located
    const sameIdsInOtherSchemaObjects = otherIdsWithPaths.filter(
      ([, value]) => value === id
    );

    return (
      sameIdsInThisSchemaObject.length === 0 &&
      sameIdsInOtherSchemaObjects.length === 0
    );
  },
});

export type BackendValidationStatus =
  // We don't validate anything on backend before all local validations are passing
  | 'never'
  // We are waiting for the result of backend validation
  | 'waiting'
  // We are waiting for the result of backend validation, and if it's successful, we save the schema
  // (this handles first submit)
  | 'waiting-autosave'
  // Current data has been validated (successfully or unsuccessfully)
  | 'validated';

export type NestedFieldType = SchemaObjectType;

type FieldType =
  | NestedFieldType
  | 'table-multivalue'
  | 'simple-multivalue'
  | 'section';

// `null` is a regular option for old customers but we need to convert it to
// a string value 'unset' in formStateToEntity function so that we are
// able to use it as a form value.
// 'unset' is converted back to `null` in formDataToDatapoint function
// This type should be used only in QueueFields form and related components
export type UiFieldTypeFormValues = Exclude<UiFieldType, null> | 'unset';

export type SimpleSchemaObjectFormData = {
  fieldType: FieldType;
  format?: string;
  hidden: boolean;
  // canExport (unlike hidden) doesn't exist on multivalues, and it's only a virtual field in the schema editor
  canExport: boolean;
  id: string;
  label: string;
  uiFieldType?: UiFieldTypeFormValues;
  edit?: FieldEditability;
  required: boolean;
  rirFieldNames: string[];
  options?: EnumOption[];
  popupUrl: string;
  canObtainToken?: boolean;
  formula?: string;

  // We need to store the original object here for a correct roundtrip behavior.
  // 'null' means this object is a new one.
  originalData: EditableSchemaObject | null;
  scoreThreshold: string | null;
};

const simpleSchemaObjectSchema = (intl: IntlShape) => ({
  format: yup.string().nullable(),
  id: yup
    .string()
    .required(
      intl.formatMessage({
        id: 'containers.settings.fields.edit.id.required',
      })
    )
    .test(idUniquenessTest(intl)),
  label: yup.string().required(
    intl.formatMessage({
      id: 'containers.settings.fields.edit.label.required',
    })
  ),
  required: yup.boolean().required(
    intl.formatMessage({
      id: 'containers.settings.fields.edit.required',
    })
  ),
  fieldType: yup
    .string()
    .oneOf([
      'string',
      'number',
      'date',
      'table-multivalue',
      'simple-multivalue',
      'enum',
      'button',
      'section',
    ])
    .required(
      intl.formatMessage({
        id: 'containers.settings.fields.edit.required',
      })
    ),
  rirFieldNames: yup.array(yup.string()).nullable(),
  hidden: yup.boolean().required(
    intl.formatMessage({
      id: 'containers.settings.fields.edit.required',
    })
  ),
  canExport: yup.boolean().required(
    intl.formatMessage({
      id: 'containers.settings.fields.edit.required',
    })
  ),
  options: yup.array(yup.object()).nullable(),
  popupUrl: yup.string().url(
    intl.formatMessage({
      id: 'containers.settings.fields.edit.popupUrl.url',
    })
  ),
  canObtainToken: yup.boolean(),
  scoreThreshold: yup
    .number()
    .typeError(
      intl.formatMessage({
        id: 'containers.settings.fields.edit.scoreThreshold.range',
      })
    )
    .min(
      0,
      intl.formatMessage({
        id: 'containers.settings.fields.edit.scoreThreshold.range',
      })
    )
    .max(
      1,
      intl.formatMessage({
        id: 'containers.settings.fields.edit.scoreThreshold.range',
      })
    )
    .nullable()
    .transform((value, originVal) => (originVal === '' ? null : value)),
  formula: yup.string().when('uiFieldType', {
    is: 'formula',
    then: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'containers.settings.fields.edit.formula.required',
        })
      )
      .max(
        FORMULA_MAX_LENGTH,
        intl.formatMessage(
          {
            id: 'containers.settings.fields.edit.formula.maxLength',
          },
          { limit: FORMULA_MAX_LENGTH }
        )
      ),
    otherwise: yup.string().optional().notRequired(),
  }),
});

export type SchemaObjectFormData = SimpleSchemaObjectFormData & {
  fieldType: FieldType;
  children: {
    category: 'tuple';
    children: (SimpleSchemaObjectFormData & { fieldType: NestedFieldType })[];
    rirFieldNames?: string[];
    id: string;
    hidden: boolean;
    label: string;
  };
  simpleMultivalueChildren: SimpleSchemaObjectFormData & {
    fieldType: NestedFieldType;
  };
  nonFieldErrors: '__unused__';
};

export type NamePrefixType =
  | ''
  | `children.children.${number}.`
  | `simpleMultivalueChildren.`;

export const schemaObjectSchema = (intl: IntlShape) =>
  yup.object().shape({
    ...simpleSchemaObjectSchema(intl),
    children: yup.object().when('fieldType', {
      is: 'table-multivalue',
      then: yup.object().shape({
        children: yup.array(yup.object().shape(simpleSchemaObjectSchema(intl))),
      }),
    }),
    simpleMultivalueChildren: yup.object().when('fieldType', {
      is: 'simple-multivalue',
      then: yup.object().shape(simpleSchemaObjectSchema(intl)),
    }),
  });

const getFieldType = (
  datapoint: EditableSchemaObject
): SchemaObjectFormData['fieldType'] => {
  if (datapoint.category === 'datapoint') {
    return datapoint.type;
  }

  if (datapoint.category === 'section') {
    return 'section';
  }

  return datapoint.children?.category === 'tuple'
    ? 'table-multivalue'
    : 'simple-multivalue';
};

const nestedFormStateToEntity = (datapoint: EditableSchemaObject) => {
  const canHaveUiConfiguration =
    isFormatSchemaObject(datapoint) ||
    isStringSchemaObject(datapoint) ||
    isEnumSchemaObject(datapoint);

  return {
    format: isFormatSchemaObject(datapoint) ? datapoint.format : undefined,
    hidden: datapoint.hidden ?? false,
    canExport:
      datapoint.category === 'multivalue'
        ? calculateCanExport(datapoint)
        : 'canExport' in datapoint
          ? datapoint.canExport ?? true
          : true,
    id: datapoint.id,
    label: datapoint.label,
    required:
      'constraints' in datapoint
        ? datapoint.constraints?.required ?? true
        : true,
    rirFieldNames: Immutable(
      ('rirFieldNames' in datapoint && datapoint.rirFieldNames) || []
    ).asMutable(),
    options: isEnumSchemaObject(datapoint) ? datapoint.options : [],
    popupUrl:
      isButtonSchemaObject(datapoint) && datapoint.popupUrl
        ? datapoint.popupUrl
        : '',
    canObtainToken:
      isButtonSchemaObject(datapoint) && datapoint.canObtainToken
        ? datapoint.canObtainToken
        : undefined,
    originalData: datapoint,
    scoreThreshold:
      'scoreThreshold' in datapoint && datapoint.scoreThreshold !== undefined
        ? datapoint.scoreThreshold.toString()
        : null,
    formula:
      'uiConfiguration' in datapoint &&
      datapoint.uiConfiguration?.type === 'formula'
        ? datapoint.formula
        : '',

    ...(canHaveUiConfiguration && {
      uiFieldType:
        datapoint.uiConfiguration?.type === null
          ? ('unset' as const)
          : datapoint.uiConfiguration?.type,
      edit: datapoint.uiConfiguration?.edit,
    }),
  };
};

export const formStateToEntity = (
  datapoint: EditableSchemaObject
): SchemaObjectFormData => {
  return {
    ...nestedFormStateToEntity(datapoint),
    fieldType: getFieldType(datapoint),
    children: isTableMultivalue(datapoint)
      ? {
          ...datapoint.children,
          children: datapoint.children.children.map(o => ({
            ...nestedFormStateToEntity(o),
            fieldType: o.type,
          })),
          hidden: datapoint.children.hidden ?? false,
        }
      : {
          category: 'tuple',
          id: ``,
          label: ``,
          children: [],
          hidden: false,
          rirFieldNames: [],
        },
    simpleMultivalueChildren: isSimpleMultivalue(datapoint)
      ? {
          ...nestedFormStateToEntity(datapoint.children),
          fieldType: datapoint.children.type,
        }
      : {
          fieldType: 'string',
          hidden: false,
          canExport: true,
          id: '',
          label: '',
          required: false,
          rirFieldNames: [],
          popupUrl: '',
          originalData: null,
          scoreThreshold: null,
        },
    nonFieldErrors: '__unused__',
  };
};

export const isMultivalueFieldType = (
  fieldType: SchemaObjectFormData['fieldType']
): fieldType is 'simple-multivalue' | 'table-multivalue' =>
  fieldType === 'simple-multivalue' || fieldType === 'table-multivalue';

const generateRandomString = (length: number) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

const toSchemaObjectConstraints = (
  required: boolean,
  originalSchemaObject: EditableSchemaObject | null
) => {
  const originalConstraints =
    originalSchemaObject &&
    'constraints' in originalSchemaObject &&
    originalSchemaObject.constraints
      ? originalSchemaObject.constraints
      : {};

  const newConstraints = {
    // There can be other constraints that we are not editing on UI
    ...originalConstraints,
    // We omit the default 'true' value if it hasn't been already present in the schema
    required: required
      ? 'required' in originalConstraints
        ? required
        : undefined
      : false,
  };

  const canBeOmitted =
    isEqual(newConstraints, {}) ||
    isEqual(newConstraints, { required: undefined });

  return canBeOmitted ? undefined : newConstraints;
};

export const formDataToDatapoint = (
  newData: SchemaObjectFormData
): EditableSchemaObject => {
  const idLabelHidden = {
    id: newData.id,
    label: newData.label,
    hidden: newData.hidden ? true : undefined,
    canExport: !newData.canExport ? false : undefined,
  };

  const {
    required,
    fieldType,
    children,
    format,
    options,
    rirFieldNames,
    simpleMultivalueChildren,
    popupUrl,
    canObtainToken,
    originalData,
    scoreThreshold,
    uiFieldType,
    edit,
    formula,
  } = newData;

  const sameCategory =
    newData.originalData === null
      ? false
      : isMultivalueFieldType(newData.fieldType) ===
        (newData.originalData.category === 'multivalue');

  // There are schema object properties which are not editable/visible in the UI and we should preserve them.
  // We are also assuming that backend will ignore any property not relevant to a specific 'type',
  // e.g. 'format' will be ignored for a field with "type: string".

  const commonData = sameCategory
    ? {
        ...originalData,
        ...idLabelHidden,
        scoreThreshold:
          scoreThreshold === '' || scoreThreshold === null
            ? undefined
            : Number(scoreThreshold),
      }
    : { ...idLabelHidden };

  const constraints = toSchemaObjectConstraints(required, originalData);

  const resolvedUiFieldType =
    !uiFieldType || uiFieldType === 'unset' ? null : uiFieldType;

  const headerFieldData: Partial<DistributiveOmit<SimpleSchemaObject, 'type'>> =
    uiFieldType || edit
      ? {
          uiConfiguration: {
            type: resolvedUiFieldType,
            edit: edit ?? 'enabled',
          },
        }
      : {};

  const overridesPerFieldType: Partial<
    Record<
      NonNullable<UiFieldType>,
      Partial<DistributiveOmit<SimpleSchemaObject, 'type'>>
    >
  > = {
    formula: {
      rirFieldNames: [],
      uiConfiguration: {
        type: 'formula',
        edit: 'disabled',
      },
      scoreThreshold: 0,
      disablePrediction: true,
      formula,
    },
    data: {
      scoreThreshold: 0,
      disablePrediction: true,
    },
    manual: {
      scoreThreshold: 0,
      disablePrediction: true,
    },
  };

  const fieldOverride = resolvedUiFieldType
    ? overridesPerFieldType[resolvedUiFieldType]
    : {};

  switch (fieldType) {
    case 'table-multivalue': {
      // If 'id' is empty, this is a new multivalue object,
      // and we generate ID/label automatically since there is no UI for those fields.
      // Otherwise, this is an existing multivalue
      // and we keep the existing values.
      // @ts-expect-error
      const childrenAsSchemaObject: NestedSchemaObject | TupleSchemaObject =
        children.id === ''
          ? {
              ...children,
              id: `${newData.id}_tuple_${generateRandomString(2)}`,
              label: `${newData.id}_tuple`,
              children: children.children.map(n =>
                // @ts-expect-error
                formDataToDatapoint(n)
              ),
              hidden: children.hidden ? true : undefined,
            }
          : {
              ...children,
              children: children.children.map(n =>
                // @ts-expect-error TODO figure out a way how to type this
                formDataToDatapoint(n)
              ),
              hidden: children.hidden ? true : undefined,
            };
      const tableMultivalueData: EditableSchemaObject = {
        ...commonData,
        category: 'multivalue',
        children: childrenAsSchemaObject,

        rirFieldNames,
      };
      return tableMultivalueData;
    }
    case 'simple-multivalue': {
      // @ts-expect-error TODO figure out a way how to type this
      const childrenAsSchemaObject: NestedSchemaObject | TupleSchemaObject =
        // @ts-expect-error TODO figure out a way how to type this
        formDataToDatapoint(simpleMultivalueChildren);

      const simpleValueData: EditableSchemaObject = {
        ...commonData,
        category: 'multivalue',
        children: childrenAsSchemaObject,
        // rirFieldNames is not used on simple multivalues
        rirFieldNames: undefined,
      };
      return simpleValueData;
    }
    case 'string':
      return {
        ...commonData,
        ...headerFieldData,
        category: 'datapoint',
        type: 'string',
        rirFieldNames,
        constraints,
        ...fieldOverride,
      };
    case 'number':
      return {
        ...commonData,
        ...headerFieldData,
        category: 'datapoint',
        type: 'number',
        format,
        rirFieldNames,
        constraints,
        ...fieldOverride,
      };
    case 'date':
      return {
        ...commonData,
        ...headerFieldData,
        category: 'datapoint',
        type: 'date',
        format,
        rirFieldNames,
        constraints,
        ...fieldOverride,
      };
    case 'enum':
      return {
        ...commonData,
        ...headerFieldData,
        category: 'datapoint',
        type: 'enum',
        options: options || [],
        rirFieldNames,
        constraints,
        ...fieldOverride,
      };
    case 'button':
      return {
        ...commonData,
        category: 'datapoint',
        type: 'button',
        popupUrl: popupUrl === '' ? undefined : popupUrl,
        canObtainToken,
      };
    case 'section':
      return {
        ...idLabelHidden,
        category: 'section',
        // children are not editable in the drawer - we take them from original data

        children: originalData
          ? (originalData as SectionSchemaObject).children
          : [],
        icon:
          originalData && 'icon' in originalData
            ? originalData.icon
            : undefined,
      };

    default:
      return assertNever(fieldType);
  }
};
