import {
  Autocomplete,
  autocompleteClasses,
  Chip,
  TextField,
} from '@rossum/ui/material';
import { VALUE_INPUT_WIDTH } from '../../constants';
import { InputSelectorProps } from './types';

export const MultiString = ({
  filterContext,
  handleFilterChange,
  autoFocus,
}: InputSelectorProps) => {
  const controlledValue = Array.isArray(filterContext.value)
    ? filterContext.value
    : [];

  return (
    <Autocomplete
      clearIcon={false}
      fullWidth
      multiple
      freeSolo
      value={controlledValue}
      onChange={(_, values) =>
        handleFilterChange({
          ...filterContext,
          value: values.map(value =>
            typeof value === 'string' ? value.toLowerCase() : value
          ),
        })
      }
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option}
            variant="outlined"
            label={option}
            size="small"
            sx={{ [`&.${autocompleteClasses.tag}`]: { m: 0.2 } }}
          />
        ))
      }
      renderInput={params => <TextField {...params} autoFocus={autoFocus} />}
      options={[]}
      sx={{
        width: VALUE_INPUT_WIDTH,
      }}
    />
  );
};
