import { useCallback, useState } from 'react';
import { canResetOnMove } from '../../../../redux/modules/annotations/selector';
import { AllDocsAnnotation } from '../../types';
import { MoveAnnotationDialog } from '../MoveAnnotationDialog';

export const useMoveAnnotationsModal = () => {
  const [selectedAnnotations, setSelectedAnnotations] = useState<
    Array<AllDocsAnnotation>
  >([]);

  const canResetData = canResetOnMove(selectedAnnotations);

  const [options, setOptions] = useState<{
    onSuccess?: () => void;
    onSettled?: () => void;
  }>();

  const openModal = useCallback(
    ({
      annotations,
      onSuccess,
      onSettled,
    }: {
      annotations: Array<AllDocsAnnotation>;
      onSuccess?: () => void;
      onSettled?: () => void;
    }) => {
      setSelectedAnnotations(annotations);
      setOptions(prev => ({ ...prev, onSuccess, onSettled }));
    },
    []
  );

  const node = (
    <MoveAnnotationDialog
      canResetData={canResetData}
      selectedAnnotations={selectedAnnotations}
      onCancel={() => setSelectedAnnotations([])}
      onSettled={() => {
        setSelectedAnnotations([]);
        options?.onSettled?.();
        options?.onSuccess?.();
      }}
    />
  );

  return { node, openModal };
};
