import {
  extensionFunctionType,
  extensionWebhookType,
} from '@rossum/api-client/hooks';
import formatJs from 'js-beautify';
import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import {
  ExtensionFunction,
  ExtensionType,
  ExtensionWebhook,
} from '../../types/extensions';

export const formatCode = (code: string): string =>
  formatJs(code, { indent_size: 2 });

const extesionIsOfType = (
  type: ExtensionType,
  selectedExtension: ExtensionWebhook | ExtensionFunction
) => selectedExtension.type === type;

const isWebhook = (
  selectedExtension: ExtensionWebhook | ExtensionFunction
): selectedExtension is ExtensionWebhook =>
  extesionIsOfType(extensionWebhookType, selectedExtension);

export const isPublicWebhook = (
  selectedExtension: ExtensionWebhook | ExtensionFunction
): selectedExtension is ExtensionWebhook =>
  isWebhook(selectedExtension) && !selectedExtension.config.private;

const isFunction = (
  selectedExtension: ExtensionWebhook | ExtensionFunction
): selectedExtension is ExtensionFunction =>
  extesionIsOfType(extensionFunctionType, selectedExtension);

export const isPublicFunction = (
  selectedExtension: ExtensionWebhook | ExtensionFunction
): selectedExtension is ExtensionFunction =>
  isFunction(selectedExtension) && !selectedExtension.config.private;

const urlStartsWithSchema = (value: string | undefined) =>
  value?.startsWith('http://') || value?.startsWith('https://') || false;

export const webhookUrlValidation = (intl: IntlShape) =>
  yup
    .string()
    .required(
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.url.required',
      })
    )
    .test(
      'missingSchema',
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.url.missingSchema',
      }),
      urlStartsWithSchema
    );
