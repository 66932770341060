import React, { Fragment } from 'react';
import { Control, FieldError, UseFormSetFocus } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import ValidationMultiInput from '../../../../../components/ReactHookForm/ValidationMultiInput';
import { QueueSettingsData, SessionTimeout } from './formType';
import styles from './styles.module.sass';

const MultiInput = ({
  clearValidationsOnChange,
  control,
  getErrorMessage,
  inputs,
  intl,
  setFocus,
}: {
  clearValidationsOnChange: (
    _id: string
  ) => (
    _handleChange: (_e: React.ChangeEvent<unknown>) => void
  ) => (_event: React.ChangeEvent<unknown>) => void;
  control: Control<QueueSettingsData>;
  getErrorMessage: (_id: string, _errorMessage: FieldError) => string;
  inputs: SessionTimeout;
  intl: IntlShape;
  setFocus: UseFormSetFocus<QueueSettingsData>;
}) => (
  <div className={styles.MultiInputWrapper}>
    {inputs.map(({ name, letter }, index) => (
      <Fragment key={`multiinput-${name}-${letter}`}>
        <ValidationMultiInput<QueueSettingsData>
          clearValidationsOnChange={clearValidationsOnChange(name)}
          className={styles.MultiInput}
          control={control}
          getErrorMessage={getErrorMessage}
          placeholder={intl.formatMessage({
            id: `containers.settings.queues.${name}.placeholder`,
          })}
          maxLength={2}
          name={name}
          setFocus={setFocus}
          nextFieldName={inputs[index + 1]?.name}
        />
        {letter}
      </Fragment>
    ))}
  </div>
);

export default MultiInput;
