import { UniqueIdentifier } from '@dnd-kit/core';
import {
  isSchemaDatapointButton,
  isSchemaSection,
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  Schema,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';
import {
  ExtensionOutlined,
  FitScreenRounded,
  FormatListBulletedRounded,
  GridOnRounded,
  HelpOutline,
  LooksOneOutlined,
  ShortTextOutlined,
} from '@rossum/ui/icons';
import { SvgIconProps } from '@rossum/ui/material';
import * as R from 'remeda';
import { ButtonIcon } from '../../../components/icons/ButtonIcon';
import { Formula } from '../../../components/icons/Formula';
import { FieldTypeOptionExclSection, ValueSourceOption } from './constants';
import {
  insertItemAtIndex,
  SchemaListPath,
  SchemaObjectPath,
} from './form-model/transformations/toSchema.utils';
import { FieldRouteItem, SchemaFieldWithPathNonNullableField } from './types';

export const fieldsIconsMap: Record<
  FieldTypeOptionExclSection | ValueSourceOption,
  React.FunctionComponent<SvgIconProps>
> = {
  captured: FitScreenRounded,
  data: ExtensionOutlined,
  manual: ShortTextOutlined,
  unset: HelpOutline,
  formula: Formula,
  button: ButtonIcon,
  lineItemSimpleValue: LooksOneOutlined,
  lineItemButton: ButtonIcon,
  simpleValue: LooksOneOutlined,
  multivalue: FormatListBulletedRounded,
  lineItems: GridOnRounded,
};

export const getFlattenSchemaFields = (
  items: SchemaSection[] | SchemaItem[],
  parentPath: FieldRouteItem[] = []
) => {
  return items.reduce<{
    [key: string]: SchemaFieldWithPathNonNullableField;
  }>((acc, item, index) => {
    const canBeParent =
      item.category === 'section' || isSchemaTableMultiValue(item);

    const path = [
      ...parentPath,
      {
        // for simple multivalues, we are using the child's id to identify it
        id: isSchemaSimpleMultiValue(item) ? item.children.id : item.id,
        label: item.label,
        canBeParent,
        index,
      },
    ];
    const schemaItem = {
      field: item,
      path,
    };

    const children: {
      [key: string]: SchemaFieldWithPathNonNullableField;
    } = canBeParent
      ? getFlattenSchemaFields(
          Array.isArray(item.children) ? item.children : item.children.children,
          path
        )
      : {};

    return {
      ...acc,
      // for simple multivalues, we are using the child's id to identify it
      ...(isSchemaSimpleMultiValue(item)
        ? { [item.children.id]: schemaItem }
        : { [item.id]: schemaItem }),
      ...children,
    };
  }, {});
};

export const getDnDItems = (
  items: { id: string; children: SchemaSection[] | SchemaItem[] }[]
): Record<UniqueIdentifier, UniqueIdentifier[]> =>
  items.reduce((acc, parent) => {
    return {
      ...acc,
      [parent.id]: (isSchemaTableMultiValue(parent)
        ? parent.children.children
        : parent.children
      ).map(child =>
        isSchemaSimpleMultiValue(child) ? child.children.id : child.id
      ),
    };
  }, {});

export const isQuickActionDisabled = (item: SchemaItem) =>
  isSchemaSimpleMultiValue(item) ||
  isSchemaTableMultiValue(item) ||
  isSchemaDatapointButton(item);

const getSchemaObjectPathForSchemaEntry = (
  entry: SchemaItem | SchemaSection | SchemaItem[] | SchemaSection[],
  prefix: (string | number)[] = [],
  accumulator: Record<string, SchemaObjectPath> = {}
): Record<string, SchemaObjectPath> => {
  if (Array.isArray(entry)) {
    return entry.reduce((acc, item, index) => {
      return getSchemaObjectPathForSchemaEntry(item, [...prefix, index], {
        ...acc,
        [item.id]: [...prefix, index] as SchemaObjectPath,
      });
    }, accumulator);
  }

  if (isSchemaSection(entry)) {
    return getSchemaObjectPathForSchemaEntry(
      entry.children,
      [...prefix, 'children'],
      { ...accumulator, [entry.id]: prefix as SchemaObjectPath }
    );
  }

  if (isSchemaTableMultiValue(entry)) {
    return getSchemaObjectPathForSchemaEntry(
      entry.children.children,
      [...prefix, 'children', 'children'],
      accumulator
    );
  }

  return { ...accumulator, [entry.id]: prefix as SchemaObjectPath };
};

// Return a map of valid SchemaPath for schema object (section or item)
// to retrieve a path based on id
// TODO: Tests
export const getSchemaObjectPaths = (
  schema: Schema
): Record<string, SchemaObjectPath> => {
  return schema.content
    ? getSchemaObjectPathForSchemaEntry(schema.content)
    : {};
};

export const getChildListPath = (
  schemaObjectPath: SchemaObjectPath
): SchemaListPath | null => {
  if (schemaObjectPath.length === 1) {
    return [...schemaObjectPath, 'children'];
  }

  if (schemaObjectPath.length === 3) {
    return [...schemaObjectPath, 'children', 'children'];
  }

  return null;
};

// Returns if `id` is pointint to an identifier of a container (first level)
export const isContainerIdentifier =
  (id: UniqueIdentifier) =>
  (items: Record<UniqueIdentifier, UniqueIdentifier[]>) =>
    R.keys.strict(items).includes(String(id));

export const containerHasItem =
  (containerId: UniqueIdentifier, itemId: UniqueIdentifier) =>
  (items: Record<UniqueIdentifier, UniqueIdentifier[]>) =>
    R.pipe(items, R.prop(containerId), val => val.includes(String(itemId)));

// Helper to manage state of draggable items
// move is the same as remove from whenever and insert at `to`
export const moveItem =
  (itemId: UniqueIdentifier, to: readonly [UniqueIdentifier, number]) =>
  (
    items: Record<UniqueIdentifier, UniqueIdentifier[]>
  ): Record<UniqueIdentifier, UniqueIdentifier[]> =>
    R.pipe(
      items,
      R.mapValues(R.filter(R.isNot(R.isDeepEqual(itemId)))),
      R.evolve({ [to[0]]: insertItemAtIndex(to[1], itemId) })
    );

export const itemPosition =
  (itemId: UniqueIdentifier) =>
  (items: Record<UniqueIdentifier, UniqueIdentifier[]>) =>
    R.pipe(
      items,
      R.pickBy(val => val.includes(String(itemId))),
      R.mapValues(val => val.indexOf(String(itemId))),
      R.entries.strict(),
      R.flat()
    ) as [UniqueIdentifier, number];

export const itemContainer =
  (itemId: UniqueIdentifier) =>
  (items: Record<UniqueIdentifier, UniqueIdentifier[]>) =>
    R.pipe(items, itemPosition(itemId), R.first());

export const itemIndex =
  (itemId: UniqueIdentifier) =>
  (items: Record<UniqueIdentifier, UniqueIdentifier[]>) =>
    R.pipe(items, itemPosition(itemId), R.last());

// missing `canExport` means that the field can be exported
export const resolveCanExport = (item: SchemaItem) =>
  'canExport' in item ? !!item.canExport : true;

export const replaceUrlSegment = (
  pathname: string,
  newSegment: string,
  segmentsToDrop: number
) =>
  R.pipe(
    pathname,
    s => s.split('/'),
    R.dropLast(segmentsToDrop),
    segments => [...segments, newSegment],
    R.filter(R.isTruthy),
    R.join('/')
  );
