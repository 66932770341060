import { WarningAmberRounded } from '@rossum/ui/icons';
import { Button, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useBillingEnabled } from '../../billing';

export const PricingBanner = ({ warningText }: { warningText: string }) => {
  const intl = useIntl();
  const { isEnabled } = useBillingEnabled();
  return (
    <Stack
      direction="row"
      spacing={1}
      py={1}
      px={1.5}
      alignItems="center"
      sx={{
        backgroundColor: 'warning.main',
        borderBottom: t => `1px solid ${t.palette.other.muted}`,
        color: t => t.palette.getContrastText(t.palette.warning.main),
      }}
      data-cy="pricing-banner"
    >
      <WarningAmberRounded />
      <Typography whiteSpace="nowrap" variant="h6">
        {intl.formatMessage({ id: 'features.pricing.banner.title' })}
      </Typography>
      <Typography
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
        minWidth={0}
        variant="body2"
      >
        {warningText}
      </Typography>
      {isEnabled ? (
        <Stack flex={1}>
          <Button
            color="inherit"
            sx={{
              alignSelf: 'flex-end',
              py: 0.5,
              '&:hover': {
                color: 'unset',
              },
            }}
            component={Link}
            to="/billing/my-plan"
            data-cy="pricing-banner-view-more-button"
          >
            {intl.formatMessage({ id: 'features.pricing.banner.button' })}
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
};
