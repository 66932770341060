import { EngineFieldType } from '@rossum/api-client/engineFields';

export const types = [
  'string',
  'number',
  'date',
  'enum',
] as const satisfies EngineFieldType[];

export type DataType = {
  type: EngineFieldType;
  subtype: string;
};

const typesAndSubtypes: DataType[] = [
  { type: 'string', subtype: '' },
  { type: 'string', subtype: 'alphanumeric' },
  { type: 'string', subtype: 'numeric' },
  { type: 'string', subtype: 'country_code' },
  { type: 'string', subtype: 'currency_code' },
  { type: 'string', subtype: 'iban' },
  { type: 'string', subtype: 'vat_number' },
  { type: 'number', subtype: '' },
  { type: 'number', subtype: 'integer' },
  { type: 'number', subtype: 'rate' },
  { type: 'number', subtype: 'amount' },
  { type: 'date', subtype: '' },
  { type: 'date', subtype: 'period_end' },
  { type: 'date', subtype: 'period_begin' },
  { type: 'enum', subtype: '' },
];

export const useTypesAndSubtypes = () => ({ typesAndSubtypes });
