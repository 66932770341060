import {
  Divider,
  List,
  ListItem,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import PaperSection from '../../../ui/paper-section/PaperSection';
import { useGetWorkflows } from './hooks/useGetWorkflows';

export type WorkflowQueueSettingsValue = {
  enabled: boolean;
  bypassWorkflowsAllowed: boolean;
  workflows: Array<{
    url: string;
    priority: number;
  }>;
};

type WorkflowQueueSettingsProps = {
  value: WorkflowQueueSettingsValue;
  onChange: (value: WorkflowQueueSettingsValue) => void;
};

export const WorkflowQueueSettings = ({
  value,
  onChange,
}: WorkflowQueueSettingsProps) => {
  const intl = useIntl();
  const { data: workflows, isLoading, isSuccess } = useGetWorkflows();

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.workflows',
      })}
    >
      <Stack
        spacing={2}
        sx={{
          width: '400px',
          maxWidth: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {intl.formatMessage({
            id: 'containers.settings.queues.titles.workflows.includeWorkflow',
          })}
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          <Typography variant="subtitle1">
            {intl.formatMessage({
              id: 'containers.settings.queues.titles.workflows.enableWorkflows',
            })}
          </Typography>
          <Stack>
            <Switch
              size="small"
              data-cy="workflows-enabled-toggle"
              checked={value.enabled === true}
              onChange={() =>
                onChange({
                  workflows: value.workflows,
                  bypassWorkflowsAllowed: value.bypassWorkflowsAllowed,
                  enabled: !value.enabled,
                })
              }
            />
          </Stack>
        </Stack>
        <Divider />
        <Typography variant="caption" color="text.secondary">
          {intl.formatMessage({
            id: 'containers.settings.queues.titles.workflows.availableWorkflows',
          })}
        </Typography>
        {isLoading && (
          <List>
            {[1, 2].map(key => (
              <ListItem key={key} sx={{ p: 0, pb: 2 }}>
                <Skeleton width={200} height={25} />
              </ListItem>
            ))}
          </List>
        )}
        {isSuccess && (
          <List>
            {workflows.map(({ name, id, url }) => (
              <ListItem key={id} sx={{ p: 0, pb: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexGrow: 1 }}
                >
                  <Typography variant="body2">{name}</Typography>
                  <Stack sx={{ ml: 1 }}>
                    <Switch
                      size="small"
                      disabled={!value.enabled}
                      checked={value.workflows.some(
                        workflow => workflow.url === url
                      )}
                      onChange={(_, active) => {
                        const workflowsWithoutCurrent = value.workflows.filter(
                          workflow => workflow.url !== url
                        );
                        return onChange({
                          enabled: value.enabled,
                          bypassWorkflowsAllowed: value.bypassWorkflowsAllowed,
                          workflows: [
                            ...workflowsWithoutCurrent,
                            ...(active ? [{ url, priority: id }] : []),
                          ],
                        });
                      }}
                    />
                  </Stack>
                </Stack>
              </ListItem>
            ))}
          </List>
        )}
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          <Typography variant="subtitle1">
            {intl.formatMessage({
              id: 'containers.settings.queues.titles.workflows.bypassWorkflows',
            })}
          </Typography>
          <Switch
            size="small"
            data-cy="bypass-workflows-enabled-toggle"
            disabled={!value.enabled}
            checked={value.bypassWorkflowsAllowed === true}
            onChange={() =>
              onChange({
                enabled: value.enabled,
                workflows: value.workflows,
                bypassWorkflowsAllowed: !value.bypassWorkflowsAllowed,
              })
            }
          />
        </Stack>
      </Stack>
    </PaperSection>
  );
};
