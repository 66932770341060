import { createSelector } from 'reselect';
import { isEmbedded } from '../../../constants/config';
import {
  isTrialSelector,
  organizationSelector,
} from '../organization/selectors';

export const shouldShowIntercomSelector = createSelector(
  isTrialSelector,
  organizationSelector,
  (isTrial, organization) => {
    const userFromRegistration =
      organization.metadata?.source === 'registration';

    const shouldShowIntercom = isTrial && userFromRegistration && !isEmbedded();

    return shouldShowIntercom;
  }
);
