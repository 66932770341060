import { ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from '../../../ui/header/Header';
import { useQueueSettingsContext } from '../contexts/QueueSettingsContext';

type Props = {
  breadcrumbs: ReactNode;
  tabs?: ReactNode;
  scrollableDivRef: React.RefObject<HTMLDivElement>;
};

export const QueueSettingsHeader = ({
  breadcrumbs,
  tabs,
  scrollableDivRef,
}: Props) => {
  // TODO: send buttons title and description as props when context will be moved higher
  const {
    headerMeta: { buttons, title, description },
  } = useQueueSettingsContext();

  const history = useHistory();

  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return (
    <Header
      breadcrumbs={breadcrumbs}
      description={description}
      onBackButtonClicked={onBackButtonClicked}
      scrollableDivRef={scrollableDivRef}
      buttons={buttons}
      tabs={tabs}
      title={title}
    />
  );
};
