import {
  alpha,
  Box,
  Chip,
  ChipProps,
  Skeleton,
  Stack,
  StackProps,
} from '@rossum/ui/material';
import { ReactNode, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../types/state';
import { usePageContent } from '../annotation-view/document-canvas-svg/page/usePageContent';

const A4_RATIO = 210 / 297;

// This component is used in both left and right part of the split pane
export const DocumentPage = ({
  pageNumber,
  isCurrent,
  onClick,
  rotationDeg,
  overlay,
  blackOverlay,
  imageContainerProps,
  pageChipProps,
}: {
  pageNumber: number;
  isCurrent: boolean;
  onClick: () => void;
  rotationDeg: number;
  overlay?: ReactNode;
  blackOverlay: number;
  imageContainerProps?: StackProps;
  pageChipProps?: ChipProps;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const page = useSelector(
    // This should be guaranteed when initializing the local state
    (state: State) => state.pages.pages.find(p => p.number === pageNumber)!
  );

  const { data, isSuccess } = usePageContent(page.content, {
    enabled: true,
  });

  // TODO: Revisit this logic
  const isLeftPane = !imageContainerProps;

  useEffect(() => {
    if (isCurrent) {
      // NOTE: It's important that scrolling container doesn't have scrollBehavior: 'smooth',
      // because it interferes with scrolling by dragging as implemented in dnd-kit.
      // That's why we set behavior here instead.
      // scrollIntoView with scrollBehavior: smooth doesn't work on multiple elements at the same time
      // https://stackoverflow.com/questions/49318497/google-chrome-simultaneously-smooth-scrollintoview-with-more-elements-doesn
      // For now, we use auto on the left part.
      // TODO: scrollTo() should be a workaround according to that SO question

      ref.current?.scrollIntoView({
        behavior: isLeftPane ? 'auto' : 'smooth',
        block: 'nearest',
      });
    }
  }, [isCurrent, isLeftPane]);

  // 'image' is a blob URL, so when it's present, the image data has been already loaded
  const isLoaded = isSuccess;

  return (
    <Stack
      spacing={1}
      onClick={onClick}
      sx={{ height: '100%', scrollMargin: '32px' }}
      ref={ref}
    >
      <Box
        sx={{
          flex: '1 0',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          position: 'relative',
          '&:hover + div': {
            color: 'primary.contrastText',
            backgroundColor: 'primary.dark',
          },
          pointer: 'cursor',
          border: theme =>
            `1px solid ${alpha(
              theme.palette.mode === 'light'
                ? theme.palette.common.black
                : theme.palette.common.white,
              0.23
            )}`,
          // border radius of pages (4px) + width of the border (1px)
          borderRadius: '5px',
        }}
        {...imageContainerProps}
      >
        <Box
          sx={{
            flex: '1 0',
            transition: 'all 0.3s',
            transform: `rotate(${rotationDeg}deg)`,
            maxHeight: '100%',
            maxWidth:
              rotationDeg % 180 === 0
                ? '100%'
                : `${
                    (page.width ? page.width / page.height : A4_RATIO) * 100
                  }%`,
            // blackOverlay is used to indicate negative states (deleted/read-only/...)
            '&:after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: theme =>
                alpha(theme.palette.common.black, blackOverlay),
            },
            boxShadow: `0px 0px 10px rgba(81, 81, 81, 0.04), inset 0px 0px 0px 1px #EEEEEE`,
            borderRadius: 1,
          }}
        >
          {data ? (
            <img
              src={data}
              alt={page.url}
              hidden={!isLoaded}
              style={{
                userSelect: 'none',
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '4px',
              }}
            />
          ) : null}
          {!isLoaded && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="auto"
              sx={{
                aspectRatio: page.width
                  ? `${page.width} / ${page.height}`
                  : `${A4_RATIO} / 1`,
              }}
            />
          )}
        </Box>
        {overlay}
      </Box>
      <Chip
        label={page.number}
        color={isCurrent ? 'primary' : 'default'}
        size="small"
        sx={{ alignSelf: 'center' }}
        {...pageChipProps}
      />
    </Stack>
  );
};
