import { useSelector } from 'react-redux';
import { isStatusVisible } from '../../../containers/AnnotationList/types';
import { State } from '../../../types/state';
import StatusChip from '../../../ui/status/StatusChip';
import { RequestDetailChip } from '../../workflows';
import { useWorkflowStepNames } from '../hooks/useWorkflowStepNames';
import { AllDocsAnnotation, CellProps } from '../types';

const RenderStatus = (
  props: CellProps<AllDocsAnnotation['status'], 'modifier' | 'id' | 'url'>
) => {
  const {
    value: status,
    row: { modifier, id, url },
  } = props;

  const userId = useSelector((state: State) => state.user.id);

  const { data: workflowStepNames } = useWorkflowStepNames(id, status);

  const stepName =
    status === 'inWorkflow' && workflowStepNames?.stepNames
      ? workflowStepNames.stepNames?.[url]
      : undefined;

  const isValidModifer = typeof modifier !== 'string';

  const isOwned = isValidModifer ? modifier.id === userId : false;

  if (status === undefined || !isStatusVisible(status)) {
    return null;
  }

  return stepName && status === 'inWorkflow' ? (
    <RequestDetailChip name={stepName} status="progress" />
  ) : (
    <StatusChip
      status={status}
      isOwned={isOwned}
      modifier={isValidModifer ? modifier.username : undefined}
    />
  );
};

export default RenderStatus;
