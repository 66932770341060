import type { IStorageProvider } from '@unleash/proxy-client-react';

export default class SessionStorageProvider implements IStorageProvider {
  private prefix = 'unleash:repository';

  public async save(name: string, data: unknown) {
    const repo = JSON.stringify(data);
    const key = `${this.prefix}:${name}`;
    try {
      window.sessionStorage.setItem(key, repo);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex);
    }
  }

  // eslint-disable-next-line consistent-return
  public get(name: string) {
    try {
      const key = `${this.prefix}:${name}`;
      const data = window.sessionStorage.getItem(key);
      return data ? JSON.parse(data) : undefined;
    } catch (e) {
      // tslint:disable-next-line
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
