import { IAceEditor } from 'react-ace/lib/types';
import { assertNever } from '../../../lib/typeUtils';

export type OnInsertMeta = {
  insertMode: 'inline' | 'new-block';
};

export type OnInsertCallback = (value: string, meta: OnInsertMeta) => void;

export const prependAuroraPrompt = (snippet: string, prompt: string) =>
  `# Generated by Aurora
# Prompt: ${prompt}
${snippet}\${1}`;

export const handleInsertion = ({
  value,
  meta,
  editor,
}: {
  editor: IAceEditor;
  value: string;
  meta: OnInsertMeta;
}) => {
  switch (meta.insertMode) {
    case 'inline': {
      editor.insertSnippet(value);
      break;
    }

    case 'new-block': {
      const { row } = editor.getCursorPosition();
      const textOnCurrentLine = editor.session.getLine(row).trim();
      const textOnNextLine = editor.session.getLine(row + 1).trim();

      // to prevent adding an extra line when the next line is empty
      const append = textOnNextLine === '' ? '' : '\n';

      // to prevent adding an extra line when the current line is empty
      if (textOnCurrentLine === '') {
        editor.insertSnippet(`${value}${append}`);
      } else {
        editor.navigateLineEnd();
        editor.insertSnippet('\n\n');
        editor.insertSnippet(`${value}${append}`);
      }
      break;
    }

    default:
      assertNever(meta.insertMode);
  }
};
