import { FieldLabel, FieldLabelProps } from '@rossum/rossum-ui/FieldLabel';
import {
  alpha,
  TextField,
  TextFieldProps,
  Typography,
} from '@rossum/ui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { ControlledFieldProps, LabeledFieldProps } from '../utils';

type TextFieldControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps &
  ControlledFieldProps<TFieldValues, TName> &
  LabeledFieldProps;

const TextFieldControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ControllerProps,
  FieldLabelProps,
  ...textFieldProps
}: TextFieldControlProps<TFieldValues, TName>) => {
  const { control, name } = ControllerProps;
  const {
    helperText,
    id,
    label,
    disabled,
    SelectProps,
    InputProps,
    ...restTextFieldProps
  } = textFieldProps;

  const resolvedFieldLabelProps: FieldLabelProps = {
    htmlFor: id ?? name,
    required: restTextFieldProps.required,
    label,
    ...FieldLabelProps,
  };

  return (
    <FieldLabel {...resolvedFieldLabelProps}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState, formState }) => (
          <TextField
            {...field}
            id={id ?? name}
            size="small"
            error={fieldState.invalid}
            label={
              resolvedFieldLabelProps.layout === 'floating'
                ? resolvedFieldLabelProps.label
                : undefined
            }
            helperText={
              fieldState.invalid && fieldState.error
                ? fieldState.error.message
                : helperText ?? ''
            }
            disabled={disabled || formState.isSubmitting}
            SelectProps={
              SelectProps
                ? {
                    ...SelectProps,
                    renderValue:
                      field.value.length === 0
                        ? () => (
                            <Typography variant="caption" color="text.disabled">
                              {SelectProps.placeholder}
                            </Typography>
                          )
                        : SelectProps.renderValue
                          ? SelectProps.renderValue
                          : undefined,
                  }
                : undefined
            }
            InputProps={{
              ...InputProps,
              sx: {
                ...InputProps?.sx,
                input: {
                  '&:-webkit-autofill': {
                    WebkitBackgroundClip: 'text',
                    WebkitBoxShadow: t =>
                      `0 0 0 100px ${alpha(t.palette.primary.main, 0.2)} inset`,
                  },
                },
              },
            }}
            {...restTextFieldProps}
          />
        )}
      />
    </FieldLabel>
  );
};

export default TextFieldControl;
