import { DetailDrawer } from '@rossum/rossum-ui/DetailDrawer';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSchemaContent,
  validateSchema,
} from '../../../../../redux/modules/schema/actions';
import {
  EditableSchemaObject,
  OriginalAnyDatapointSchema,
} from '../../../../../types/schema';
import { State } from '../../../../../types/state';
import { useOpenModal } from '../../../../../utils/hooks/useOpenModal';
import { recalculateHidden } from '../helpers';
import { SchemaEditorDrawerTitle } from './SchemaEditorDrawerTitle';
import SchemaObjectForm from './SchemaObjectForm';

const newSchemaObjectDraft: EditableSchemaObject = {
  category: 'datapoint',
  type: 'string',
  hidden: false,
  id: '',
  label: '',
  constraints: { required: false },
};

const newSectionDraft: EditableSchemaObject = {
  id: '',
  label: '',
  children: [],
  hidden: false,
  category: 'section',
};

type SchemaEditorDrawerProps = {
  editPath?: string[];
  setEditPath: (editPath: string[] | undefined) => void;
  schemaConcept: OriginalAnyDatapointSchema[];
};

export const SchemaEditorDrawer = ({
  editPath,
  schemaConcept,
  setEditPath,
}: SchemaEditorDrawerProps) => {
  const dispatch = useDispatch();
  const validationTimeStamp = useSelector<State, number | undefined>(
    s => s.schema.validationTimeStamp
  );

  const schemaUrl = useSelector<State, string | undefined>(s => s.schema.url);

  const [pendingChanges, setPendingChanges] = useState(false);
  const [ModalDialog, openModal] = useOpenModal();

  const isEditingSection = editPath && editPath.length === 1;

  const validateUpdatedSchema =
    (path: Array<string>) => (_datapoint: EditableSchemaObject) =>
      dispatch(
        validateSchema({
          // @ts-expect-error
          content: schemaConcept.setIn(path, _datapoint),
          url: schemaUrl ?? '',
        })
      );

  const onSave =
    (path: Array<string>) => (_datapoint: EditableSchemaObject) => {
      dispatch(
        updateSchemaContent(
          schemaConcept
            // @ts-expect-error
            .setIn(path, _datapoint)
            .map((dtp: OriginalAnyDatapointSchema) => recalculateHidden(dtp))
        )
      );
      setEditPath(undefined);
    };

  const handleClose = () => {
    if (pendingChanges) {
      openModal({
        textId: 'confirmCloseDrawer',
        onConfirm: () => {
          setEditPath(undefined);
        },
      });
    } else {
      setEditPath(undefined);
    }
  };

  return (
    <>
      <DetailDrawer
        open={editPath && editPath.length > 0}
        onClose={() => handleClose()}
        anchor="right"
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            '.DetailDrawer-titlePaper': {
              zIndex: '1',
            },
            borderRadius: 0,
            '.DetailDrawer-bodyBox': {
              backgroundColor: 'inherit',
            },
          },
        }}
        title={
          editPath ? (
            <SchemaEditorDrawerTitle
              editPath={editPath}
              schemaConcept={schemaConcept}
            />
          ) : null
        }
      >
        {editPath && (
          <SchemaObjectForm
            datapoint={
              // @ts-expect-error
              schemaConcept.getIn(editPath)?.asMutable({ deep: true }) ??
              (isEditingSection ? newSectionDraft : newSchemaObjectDraft)
            }
            validateSchema={validateUpdatedSchema(editPath)}
            onSave={onSave(editPath)}
            setPendingChanges={setPendingChanges}
            validateUpdatedSchema={validateUpdatedSchema(editPath)}
            validationTimeStamp={validationTimeStamp}
            schemaObjectPath={`${editPath.join('.')}`}
            // @ts-expect-error
            schemaConcept={schemaConcept}
          />
        )}
      </DetailDrawer>
      {ModalDialog}
    </>
  );
};
