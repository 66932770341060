// Generated by ts-to-zod
import { z } from 'zod';
import { documentSchema } from '../../documents/models/document.schema';
import { labelSchema } from '../../labels/models/label.schema';
import { queueSchema } from '../../queues/models/queue.schema';
import { relationSchema } from '../../relations/models/relations.schema';
import { userSchema } from '../../users/models/user.schema';
import {
  datapointDataSchema,
  multivalueDataSchema,
} from './annotationData.schema';

export const sideloadOptionsSchema = z.union([
  z.literal('documents'),
  z.literal('modifiers'),
  z.literal('content'),
  z.literal('assignees'),
  z.literal('relations'),
  z.literal('labels'),
  z.literal('childRelations'),
  z.literal('exportedBys'),
  z.literal('deletedBys'),
  z.literal('confirmedBys'),
  z.literal('rejectedBys'),
]);

export const annotationListSideloadSchema = z.object({
  documents: z.array(documentSchema),
  modifiers: z.array(userSchema),
  content: z.array(z.union([datapointDataSchema, multivalueDataSchema])),
  assignees: z.array(userSchema),
  labels: z.array(labelSchema),
  relations: z.array(relationSchema),
  childRelations: z.array(relationSchema),
  queues: z.array(queueSchema),
  exportedBys: z.array(userSchema),
  rejectedBys: z.array(userSchema),
  confirmedBys: z.array(userSchema),
  deletedBys: z.array(userSchema),
});
