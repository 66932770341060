import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { BeamerProvider } from '../../beamer/BeamerProvider';
import GlobalErrorBoundary from '../../components/errorBoundaries/global/GlobalErrorBoundary';
import ObservableProvider from '../../components/ObservableProvider';
import { REACT_QUERY_DEVTOOLS_ENABLED } from '../../constants/config';
import { INTERCOM_APP_ID } from '../../constants/values';
import { BootIntercom } from '../../intercom/BootIntercom';
import { queryClient } from '../../lib/queryClient';
import store from '../../redux/configureStore';
import Router from '../../routes';
import RuiThemeProvider from '../../RuiThemeProvider';
import UnleashProvider from '../../unleash/Unleash';
import ConnectedIntlProvider from '../ConnectedIntlProvider';
import { LoginAnimationContextProvider } from '../Login/LoginAnimationContext';
import { handleIntlProviderError } from './handleIntlProviderError';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ObservableProvider>
      <Provider store={store}>
        <UnleashProvider>
          <RuiThemeProvider>
            <LoginAnimationContextProvider>
              <ConnectedIntlProvider onError={handleIntlProviderError}>
                <GlobalErrorBoundary>
                  <BeamerProvider>
                    <IntercomProvider appId={INTERCOM_APP_ID}>
                      <BootIntercom />
                      <Router />
                    </IntercomProvider>
                  </BeamerProvider>
                </GlobalErrorBoundary>
                {REACT_QUERY_DEVTOOLS_ENABLED && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              </ConnectedIntlProvider>
            </LoginAnimationContextProvider>
          </RuiThemeProvider>
        </UnleashProvider>
      </Provider>
    </ObservableProvider>
  </QueryClientProvider>
);

export default App;
