import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getAuthToken } from '../../../../lib/token';

const QUERY_KEY_DOCUMENT_PAGE_CONTENT = 'document-page-content' as const;
const ONE_HOUR = 1000 * 60 * 60;

const getBase64Image = (imgUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      try {
        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas
          .toDataURL('image/png')
          .replace(/^data:image\/png;base64,/, '');
        resolve(`data:image/png;base64,${dataURL}`);
      } catch {
        reject(new Error('Failed to convert image to Base64.'));
      }
    };

    img.onerror = () => {
      reject(new Error('Failed to load image.'));
    };

    img.setAttribute('crossOrigin', 'anonymous');
    img.src = imgUrl;
  });
};

export const usePageContent = (
  contentUrl: string,
  options: { enabled: boolean }
) =>
  useQuery({
    queryKey: [QUERY_KEY_DOCUMENT_PAGE_CONTENT, contentUrl],

    queryFn: async () => {
      const response = await axios.get<Blob>(`${contentUrl}`, {
        headers: { Authorization: `Token ${getAuthToken()}` },
        responseType: 'blob',
        method: 'GET',
      });

      const imageUrl = URL.createObjectURL(response.data);

      // We use base64 so that we see the image in smartlook if enabled.
      const base64 = await getBase64Image(imageUrl).catch(() => imageUrl);

      return base64;
    },

    ...options,
    staleTime: ONE_HOUR,
  });
