import { Settings } from '@rossum/ui/icons';
import { Skeleton, Stack, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import TranslatedTooltip from '../../../../../components/UI/Tooltip/TranslatedTooltip';
import { link } from '../../../../../lib/formaterValues';
import { SuggestedEditValue } from '../../../../../types/queue';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import DefaultAlignedToggle from '../../../components/DefaultAlignedToggle';
import styles from '../../../styles.module.sass';
import { QueueSettingsData } from './formType';
import QueueSettingsLabel from './QueueSettingsLabel';
import SuggestedEditDropdown from './SuggestedEditDropdown';

type Props = {
  suggestedEditEnabled: boolean;
  value?: SuggestedEditValue;
  onChange: (_value: string) => void;
  control: Control<QueueSettingsData>;
  isTriageReady: boolean;
  openTriageSettings: () => void;
};
const Document = ({
  suggestedEditEnabled,
  value,
  onChange,
  control,
  isTriageReady,
  openTriageSettings,
}: Props) => {
  const intl = useIntl();

  const {
    field: { value: triageValue },
  } = useController({
    name: 'triageStateEnabled',
    control,
  });

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.document',
      })}
    >
      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel
            titleId="containers.settings.queues.splitBatchFiles.label"
            tooltipId="containers.settings.queues.splitBatchFiles.tooltip"
          />
        </div>
        <div data-cy="basic-setting-split-batch-select">
          <TranslatedTooltip
            translationValues={{ link: link('mailto:support@rossum.ai') }}
            translationId="containers.settings.queues.splitBatchFiles.unavailable"
            popperClassName={clsx(
              !suggestedEditEnabled &&
                `${styles.TooltipWithLink} ${styles.NotAllowedCursor}`
            )}
            innerPopperClassName={clsx(styles.Tooltip, styles.TooltipGray)}
            disabled={suggestedEditEnabled}
          >
            <SuggestedEditDropdown
              value={value}
              setValue={(_value: string) => onChange(_value)}
              options={[{ value: 'disable' }, { value: 'suggest' }]}
              disabled={!suggestedEditEnabled}
            />
          </TranslatedTooltip>
        </div>
      </div>
      {/* Hide if triage value did not load successfully */}
      {isTriageReady && triageValue === undefined ? null : (
        <Stack maxWidth={600} spacing={2}>
          {isTriageReady ? (
            <DefaultAlignedToggle
              name="triageStateEnabled"
              control={control}
              onClick={openTriageSettings}
              buttonIcon={<Settings />}
              buttonTitle={intl.formatMessage({
                id: 'containers.settings.queues.triageState.button',
              })}
              dataCy="queue-settings-triage-state"
            >
              {intl.formatMessage({
                id: 'containers.settings.queues.triageState.sublabel',
              })}
            </DefaultAlignedToggle>
          ) : (
            <Skeleton height={36} width="100%" />
          )}
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'containers.settings.queues.triageState.description',
            })}
          </Typography>
        </Stack>
      )}
    </PaperSection>
  );
};

export default Document;
