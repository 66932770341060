import { useRouteMatch } from 'react-router';
import { SettingsSidebarItems } from '../../containers/Settings/containers/SettingsSidebarItems';
import Sidebar from '../../containers/Settings/containers/Sidebar';
import { fieldManagerSettingsPath } from './constants';
import { FieldManager, FieldManagerProps } from './field-manager';
import {
  fieldCreateRoute,
  fieldDetailsRoute,
  FieldManagerCreateFieldParams,
  FieldManagerDetailParams,
} from './field-manager/routes';
import FieldManagerWrapper from './FieldManagerWrapper';

export const FieldManagerComponent = (props: FieldManagerProps) => {
  const fieldManagerSettingsPathname = fieldManagerSettingsPath();

  const matchDetail = useRouteMatch<FieldManagerDetailParams>(
    fieldDetailsRoute(fieldManagerSettingsPathname)
  );

  const matchCreateField = useRouteMatch<FieldManagerCreateFieldParams>(
    fieldCreateRoute(fieldManagerSettingsPathname)
  );

  const hideItems = matchDetail;
  const hideSidebar = matchCreateField;

  return (
    <>
      {!hideSidebar ? (
        <Sidebar
          backLink={
            matchDetail
              ? {
                  title: 'containers.settings.sidebar.title.fieldDetail',
                  to: fieldManagerSettingsPath(),
                }
              : null
          }
        >
          {hideItems ? null : <SettingsSidebarItems />}
        </Sidebar>
      ) : null}

      <FieldManagerWrapper fullWidth={!!hideSidebar}>
        <FieldManager {...props} />
      </FieldManagerWrapper>
    </>
  );
};
