import { useDispatch } from 'react-redux';
import { throwInfo } from '../../../redux/modules/messages/actions';

type EnginesToastMessageKey =
  | 'engineCreated'
  | 'engineDeleted'
  | 'engineDuplicated'
  | 'engineUpdated'
  | 'engineFieldCreated'
  | 'engineFieldDeleted'
  | 'engineFieldUpdated';

export const useToastMessage = () => {
  const dispatch = useDispatch();

  const showToastMessage = (key: EnginesToastMessageKey) =>
    dispatch(throwInfo(key));

  return { showToastMessage };
};
