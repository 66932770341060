import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import { Add } from '@rossum/ui/icons';
import { Button, Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import { difference, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Control,
  FieldError,
  useFieldArray,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import SortableWrapper from '../../../../../components/Dnd/SortableWrapper';
import { isNotNullOrUndefined } from '../../../../../lib/typeGuards';
import { flattenWithPaths } from '../helpers';
import styles from '../style.module.sass';
import {
  NestedFieldType,
  SchemaObjectFormData,
  SimpleSchemaObjectFormData,
} from '../types';
import Field from './Field';
import Section from './Section';
import { SimpleFields } from './SimpleFields';

// TODO move to types?
const newColumnSchemaObject: SimpleSchemaObjectFormData & {
  fieldType: NestedFieldType;
} = {
  fieldType: 'string',
  hidden: false,
  canExport: true,
  label: '',
  format: '',
  id: '',
  required: false,
  rirFieldNames: [],
  options: [],
  popupUrl: '',
  originalData: null,
  scoreThreshold: null,
};

const isFieldError = (obj: unknown): obj is FieldError =>
  typeof obj === 'object' && obj !== null && 'type' in obj && 'message' in obj;

const TableMultivalueFields = ({
  control,
  setValue,
}: {
  control: Control<SchemaObjectFormData>;
  setValue: UseFormSetValue<SchemaObjectFormData>;
}) => {
  const intl = useIntl();

  // TODO merge together to useExpandableFieldArray
  const fieldArray = useFieldArray({
    control,
    name: 'children.children',
    keyName: 'key',
  });
  const watchFieldArray = useWatch({ control, name: 'children.children' });
  const parentIsHidden = useWatch({ control, name: 'hidden' });
  const parentCanExport = useWatch({ control, name: 'canExport' });

  const { errors } = useFormState({ control });

  const childrenWithErrors = uniq(
    flattenWithPaths(errors, isFieldError)
      .map(([path]) => path.match(/^children\.children\.(\d+)\./))
      .filter(isNotNullOrUndefined)
      .map(matches => Number(matches[1]))
  );

  const [expandedChildren, setExpandedChildren] = useState<number[]>([]);

  const history = useHistory();
  const { pathname, state: locationState } = useLocation<{
    backLink?: string;
    expandFieldOnIndex: string | undefined;
  }>();

  // expand children datapoint
  const indexToExpand = locationState?.expandFieldOnIndex;
  useEffect(() => {
    if (indexToExpand !== undefined) {
      setExpandedChildren([Number(indexToExpand)]);
      // clear openFieldOnPath from location state but keep the rest
      history.replace(pathname, {
        ...locationState,
        expandedChildren: undefined,
      });
    }
  }, [history, indexToExpand, locationState, pathname]);

  const [draggedField, setDraggedField] = useState<
    (typeof fieldArray.fields)[number] | null
  >(null);

  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const toExpand = difference(childrenWithErrors, expandedChildren);
    if (toExpand.length > 0) {
      // This ensures that setExpandedChildren won't be called again (that's why no dependencies should be OK here)

      setExpandedChildren(expandedChildren.concat(toExpand));
    }
  });

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDraggedField(
      fieldArray.fields.find(field => field.key === active.id) ?? null
    );
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeFieldIndex = fieldArray.fields.findIndex(
        item => item.key === active.id
      );

      const overFieldIndex = fieldArray.fields.findIndex(
        item => item.key === over?.id
      );

      fieldArray.move(activeFieldIndex, overFieldIndex);
    }

    setDraggedField(null);
  };

  const onChildClick = (index: number) =>
    expandedChildren.includes(index)
      ? setExpandedChildren(expandedChildren.filter(child => child !== index))
      : setExpandedChildren([...expandedChildren, index]);

  const onChildToggle = (index: number) => {
    // NOTE:
    // 1. Don't use update() from useFieldArray() - it doesn't work properly with errors (it sets the values as errors, and it breaks everything)
    // 2. The source of truth is the value from useWatch, not 'fields' from useFieldArray(), since that is only a local copy
    setValue(
      `children.children.${index}.hidden`,
      !watchFieldArray[index].hidden
    );
  };

  const onChildDelete = (index: number) => {
    fieldArray.remove(index);
    setExpandedChildren(expandedChildren.filter(child => child !== index));
  };
  return (
    <>
      <div className={styles.TableColumnsTitle}>
        {intl.formatMessage({
          id: 'containers.settings.fields.edit.tableMultivalue.title',
        })}
        <div className={styles.TableColumnsCount}>
          {fieldArray.fields.length}
        </div>
      </div>

      <Section title="">
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext items={fieldArray.fields.map(item => item.key)}>
            <div className={styles.SectionDroppable}>
              {fieldArray.fields.map((datapoint, i) => (
                <SortableWrapper
                  id={datapoint.key}
                  key={datapoint.key}
                  render={dragHandleProps =>
                    dragHandleProps.isDragging ? (
                      <Stack
                        sx={{
                          minHeight: 44,
                        }}
                      />
                    ) : (
                      <>
                        <Field
                          // The source of truth is the value from useWatch(), not 'fields' from useFieldArray()
                          // However, when adding a new item, it seems the item is not immediately available
                          // through useWatch(), so we need to use the value from useFieldArray()
                          datapoint={watchFieldArray[i] ?? datapoint}
                          indexInSection={i}
                          onToggle={onChildToggle}
                          onDelete={onChildDelete}
                          onClick={onChildClick}
                          canClick={!childrenWithErrors.includes(i)}
                          dragHandleProps={
                            // Enable dragging of any field only if none is expanded
                            expandedChildren.length <= 0
                              ? dragHandleProps
                              : undefined
                          }
                        />
                        {expandedChildren.includes(i) && (
                          <div
                            className={clsx(
                              styles.EditFieldDrawerContent,
                              styles.EditFieldNestedContent
                            )}
                          >
                            <SimpleFields
                              control={control}
                              namePrefix={`children.children.${i}.`}
                            />
                          </div>
                        )}
                      </>
                    )
                  }
                />
              ))}
            </div>
          </SortableContext>
          <DragOverlay>
            {draggedField && (
              <Field indexInSection={-1} datapoint={draggedField} isOverlay />
            )}
          </DragOverlay>
        </DndContext>

        <div className={styles.FooterButtonWrapper}>
          <Button
            color="secondary"
            startIcon={<Add />}
            onClick={() => {
              // Adding a new field shouldn't change the visibility state of the parent,
              // so we need to use the same hidden value
              fieldArray.append({
                ...newColumnSchemaObject,
                hidden: parentIsHidden,
                canExport: parentCanExport,
              });
              setExpandedChildren([
                ...expandedChildren,
                fieldArray.fields.length,
              ]);
            }}
          >
            {intl.formatMessage({
              id: 'containers.settings.fields.multivalue.addColumn',
            })}
          </Button>
        </div>
      </Section>
    </>
  );
};

export default TableMultivalueFields;
