import { Key, PersonRemove } from '@rossum/ui/icons';
import { Button, Dialog, Grid, Stack, Typography } from '@rossum/ui/material';
import { get } from 'lodash';
import UserIcon from 'mdi-react/AccountOutlineIcon';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteChildrenProps, useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { useResetPasswordByAdmin } from '../../business/users/useResetPasswordByAdmin';
import { getRoleName } from '../../redux/modules/groups/helpers';
import { getName } from '../../redux/modules/user/helpers';
import { updateUserDetail } from '../../redux/modules/users/actions';
import { SSO } from '../../redux/modules/users/values';
import { Groups } from '../../types/group';
import { State } from '../../types/state';
import { OrganizationUser, User as UserT } from '../../types/user';
import UserStats from '../Settings/containers/UserStats';
import DeleteUserDialog from '../Users/components/DeleteUser/DeleteUserDialog';
import ResetUserPasswordDialog from '../Users/components/ResetUserPasswordDialog';
import Toggle from '../Users/components/Toggle';
import { getActiveUserPage, usersPath } from '../Users/helpers';
import styles from './styles.module.sass';

type StateProps = {
  loggedInUserId: number;
  groups: Groups;
};

type DispatchProps = {
  toggleUserActive: (id: number, isActive: boolean) => void;
};

type OwnProps = {
  children: ReactNode;
  selectedUser: OrganizationUser | UserT;
} & Pick<RouteChildrenProps, 'location'>;

type OpenDialog = 'deleteAccount' | 'resetPassword' | null;

type Props = StateProps & DispatchProps & OwnProps;

const User = ({
  children,
  groups,
  loggedInUserId,
  selectedUser,
  toggleUserActive,
  location,
}: Props) => {
  const [open, setOpen] = useState<OpenDialog>(null);

  const activePage = getActiveUserPage(location.pathname);
  const intl = useIntl();
  const history = useHistory();

  const { mutate } = useResetPasswordByAdmin();

  const getDialogContent = (open: OpenDialog) => {
    switch (open) {
      case 'deleteAccount':
        return (
          <DeleteUserDialog
            user={selectedUser}
            onDelete={() => history.push(usersPath())}
            onCancel={() => setOpen(null)}
          />
        );
      case 'resetPassword':
        return (
          <ResetUserPasswordDialog
            userName={getName(selectedUser) ?? selectedUser.username}
            onReset={() =>
              mutate({
                email: selectedUser.email ?? selectedUser.username,
              })
            }
            onCancel={() => setOpen(null)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.UserLayout} data-page-title="user">
      <div className={styles.UserHeading}>
        <div className={styles.UserNameInfo}>
          <UserIcon />
          <span className={styles.UserName}>{getName(selectedUser)}</span>
          <Toggle
            disabled={
              loggedInUserId === get(selectedUser, 'id') ||
              getRoleName(get(selectedUser, 'groups', []), groups) ===
                'organization_group_admin'
            }
            active={get(selectedUser, 'isActive')}
            onToggle={() =>
              toggleUserActive(
                get(selectedUser, 'id'),
                get(selectedUser, 'isActive')
              )
            }
            isUserOrgGroupAdmin={
              getRoleName(get(selectedUser, 'groups', []), groups) ===
              'organization_group_admin'
            }
          />
        </div>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpen('deleteAccount')}
          >
            <PersonRemove />
            <Typography variant="button">
              {intl.formatMessage({
                id: 'containers.settings.users.user.deleteAccount',
              })}
            </Typography>
          </Button>
          {get(selectedUser, 'authType') !== SSO && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpen('resetPassword')}
              startIcon={<Key />}
            >
              {intl.formatMessage({
                id: 'containers.settings.users.user.resetPassword',
              })}
            </Button>
          )}
        </Stack>
        <Dialog
          open={!!open}
          PaperProps={{
            sx: { width: 480, minHeight: 213, position: 'fixed' },
            elevation: 2,
          }}
          onClose={() => setOpen(null)}
          sx={{ transition: 'smooth' }}
        >
          {getDialogContent(open)}
        </Dialog>
      </div>
      {activePage === 'access' && (
        <Grid container spacing={2} sx={{ my: 3 }}>
          <UserStats selectedUser={selectedUser} />
        </Grid>
      )}
      {activePage ? (
        <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
          {intl.formatMessage({
            id: `containers.settings.users.user.${activePage}`,
          })}
        </Typography>
      ) : null}
      {children}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  loggedInUserId: state.user.id,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    toggleUserActive: (id: number, isActive: boolean) =>
      dispatch(updateUserDetail(id, { isActive: !isActive })),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(User);
