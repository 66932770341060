import { last } from 'lodash';
import { AnyDatapointDataST } from '../../../types/datapoints';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';

export const useFieldsRoute = (
  datapoint: AnyDatapointDataST | undefined,
  queueId: number | null
) => {
  const isNewQueueSettingsLayout = useFeatureFlag(
    'ac-4366-queue-settings-layout-v2'
  );

  if (datapoint && datapoint.schema && queueId) {
    const path = isNewQueueSettingsLayout
      ? `/queues/${queueId}/settings/fields/${datapoint.schema.id}`
      : `/settings/queues/${queueId}/fields`;

    const state = isNewQueueSettingsLayout
      ? undefined
      : {
          openFieldOnPath: datapoint.schema.path.slice(0, 3),
          expandFieldOnIndex: last(datapoint.schema.path.slice(3)),
        };

    return [path, state] as const;
  }

  return [] as const;
};
