import {
  Checkbox,
  Paper,
  Stack,
  StackProps,
  Typography,
} from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { sort } from 'remeda';
import { linebreak } from '../../lib/formaterValues';
import { typeSafeObjectEntries } from '../../lib/fromEntries';
import { ActiveSubscriptionUsage, FeatureKey } from '../pricing/schemas';
import { getRateLimitFeatureMap } from '../pricing/utils';
import { SubscriptionExceededBanner } from './components/SubscriptionExceededBanner';

const featureRowStyles: StackProps['sx'] = {
  flexDirection: 'row',
  borderBottom: t => `1px solid ${t.palette.other.muted}`,
  alignItems: 'center',
  p: 2,
  ':last-child': {
    borderBottom: 'none',
  },
};

const freeFeatures = {
  documentArchiveFree: { purchased: true },
} as const;

const labelledFeatures = [
  'documentArchiveFree',
  'documentArchive',
  'queues',
  'sandboxes',
] as const satisfies Array<FeatureKey | keyof typeof freeFeatures>;

const isFeaturePlanLabelled = (
  featureKey: FeatureKey | keyof typeof freeFeatures
): featureKey is (typeof labelledFeatures)[number] => {
  return labelledFeatures.some(label => label === featureKey);
};

export const MyPlan = ({
  subscriptionUsage,
}: {
  subscriptionUsage: ActiveSubscriptionUsage;
}) => {
  const intl = useIntl();

  const sortedListOfFeatures = useMemo(() => {
    const { hasSubscriptionPlan, ...subscribedFeatures } = subscriptionUsage;
    const features = { ...subscribedFeatures, ...freeFeatures };

    const formattedFeatureEntries = typeSafeObjectEntries(features).map(
      ([featureKey, featureValues]) => {
        return [
          featureKey,
          {
            ...featureValues,
            featureName: intl.formatMessage({
              id: `containers.billing.myPlan.featureList.feature.${featureKey}.name`,
            }),
            featurePlan: isFeaturePlanLabelled(featureKey)
              ? intl.formatMessage(
                  {
                    id: `containers.billing.myPlan.featureList.feature.${featureKey}.plan`,
                  },
                  {
                    count:
                      'limit' in featureValues ? featureValues.limit : null,
                    linebreak,
                  }
                )
              : null,
          },
        ] as const;
      }
    );

    return sort(formattedFeatureEntries, ([_aKey, aValue], [_bKey, bValue]) =>
      aValue.featureName.localeCompare(bValue.featureName)
    );
  }, [intl, subscriptionUsage]);

  const rateLimitedFeatures = getRateLimitFeatureMap(subscriptionUsage);

  const exceededFeatureKeys = typeSafeObjectEntries(
    rateLimitedFeatures
  ).flatMap(([featureKey, { hasExceededLimit }]) =>
    hasExceededLimit ? featureKey : []
  );
  return (
    <Stack py={8} spacing={4}>
      <Typography variant="h4">
        {intl.formatMessage({ id: 'containers.billing.myPlan' })}
      </Typography>
      {exceededFeatureKeys.length > 0 ? (
        <SubscriptionExceededBanner rateLimitFeatures={rateLimitedFeatures} />
      ) : null}
      <Stack component={Paper}>
        <Stack sx={{ ...featureRowStyles, color: 'text.secondary' }}>
          <Typography variant="subtitle2" flex="0 0 40%">
            {intl.formatMessage({
              id: 'containers.billing.myPlan.featureList.header.featureName',
            })}
          </Typography>
          <Typography variant="subtitle2" flex="0 0 60%" textAlign="center">
            {intl.formatMessage({
              id: 'containers.billing.myPlan.featureList.header.myPlan',
            })}
          </Typography>
        </Stack>
        {sortedListOfFeatures.map(([featureKey, featureParams]) => {
          const shouldWarnUser = exceededFeatureKeys.some(
            key => key === featureKey
          );
          return (
            <Stack
              key={featureKey}
              sx={{
                ...featureRowStyles,
                minHeight: 66,
                color: shouldWarnUser ? 'warning.main' : 'unset',
              }}
              data-cy={`subscription-item-${featureKey}${shouldWarnUser ? '-exceeded' : ''}`}
            >
              <Typography variant="subtitle2" flex="0 0 40%">
                {featureParams.featureName}
              </Typography>
              {featureParams.featurePlan ? (
                <Typography variant="body2" flex="0 0 60%" textAlign="center">
                  {featureParams.featurePlan}
                </Typography>
              ) : 'purchased' in featureParams ? (
                <Checkbox
                  checked={featureParams.purchased}
                  disabled
                  size="small"
                  sx={{
                    p: 0,
                    flex: '0 0 60%',
                  }}
                />
              ) : null}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
