import { useMemo, useState } from 'react';
import { AddAttachmentsModal } from '../AddAttachmentsModal';

export const useAddAttachmentsModal = () => {
  const [rootAnnotationUrl, setRootAnnotationUrl] = useState<string | null>(
    null
  );

  const onClose = () => setRootAnnotationUrl(null);

  const openModal = (url: string) => setRootAnnotationUrl(url);

  const node = useMemo(
    () => (
      <AddAttachmentsModal
        onClose={onClose}
        rootAnnotationUrl={rootAnnotationUrl}
      />
    ),
    [rootAnnotationUrl]
  );

  return { node, openModal };
};
