import update from 'immutability-helper';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { ActiveSubscriptionUsage } from '../../../features/pricing/schemas';
import { safeCheckSubscriptionUsage } from '../../../features/pricing/utils';
import { OrganizationGroup } from '../../../types/organizationGroup';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { fetchOrgGroupFulfilled } from './actions';

export type OrganizationGroupState = {
  current: OrganizationGroup | undefined;
  resolvedFeatures: ActiveSubscriptionUsage | undefined;
};

const initialState: OrganizationGroupState = {
  current: undefined,
  resolvedFeatures: undefined,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchOrgGroupFulfilled):
      return update(state, {
        current: {
          $set: action.payload,
        },
        resolvedFeatures: {
          $set: safeCheckSubscriptionUsage(action.payload),
        },
      });

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
