import { getIDFromUrl } from '@rossum/api-client';
import { LinkOffRounded, LinkRounded } from '@rossum/ui/icons';
import {
  Button,
  Dialog,
  List,
  ListItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { link } from '../../../lib/formaterValues';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { useEngine } from '../hooks/useEngine';
import { useEngineFields } from '../hooks/useEngineFields';
import { engineDetailPath, engineFieldDetailPath } from '../paths';
import { CreateFieldDialogContent } from './CreateFieldDialogContent';
import TilesListEmptyState from './TilesListEmptyState';
import { renderOption } from './TypesSubtypesInput';

type FieldConfigurationProps = {
  children: ReactNode;
  tabular: boolean;
  engineFieldName: string;
  engineFieldLabel: string;
  engineUrl: string;
};

export const FieldConfiguration = ({
  children,
  tabular,
  engineFieldName,
  engineFieldLabel,
  engineUrl,
}: FieldConfigurationProps) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const { data: engine } = useEngine(getIDFromUrl(engineUrl));
  const { data: fields } = useEngineFields({
    engine: getIDFromUrl(engineUrl),
    name: engineFieldName,
  });

  const field = fields?.results[0];

  return (
    <ContentGroup
      title={
        <Stack direction="row" gap={1}>
          {intl.formatMessage({
            id: 'features.engines.fieldConfiguration.title',
          })}
          {field ? (
            <LinkRounded color="success" data-cy="indicator-connected" />
          ) : (
            <Tooltip
              arrow
              placement="top"
              title={intl.formatMessage({
                id: 'features.engines.fieldConfiguration.notConnected.tooltip',
              })}
            >
              <LinkOffRounded color="error" data-cy="indicator-not-connected" />
            </Tooltip>
          )}
        </Stack>
      }
      description={
        field && engine
          ? intl.formatMessage(
              {
                id: `features.engines.fieldConfiguration.connected.description`,
              },
              {
                link: link(
                  engineFieldDetailPath(getIDFromUrl(engineUrl), field.id)
                ),
              }
            )
          : null
      }
      isFullWidthLayout={!(field && engine)}
    >
      {field && engine ? (
        <>
          <List>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: `features.engines.fieldConfiguration.engineName`,
                  })}
                  :
                </Typography>
                <Typography
                  component={Link}
                  to={engineDetailPath(engine.id)}
                  color="text.primary"
                  data-cy="connected-engine-name"
                >
                  {engine.name}
                </Typography>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: 'features.engines.fieldDetail.basicInformation.field.name',
                  })}
                  :
                </Typography>
                <Typography
                  component={Link}
                  to={engineFieldDetailPath(engine.id, field.id)}
                  color="text.primary"
                  data-cy="connected-field-name"
                >
                  {field.label}
                </Typography>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: 'features.engines.fieldDetail.type.field.dataType',
                  })}
                  :
                </Typography>
                <Typography color="text.primary">
                  {renderOption({
                    type: field.type,
                    subtype: field.subtype || '',
                  })}
                </Typography>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack direction="row" flex={1}>
                <Typography color="text.secondary" flex={1}>
                  {intl.formatMessage({
                    id: 'features.engines.fieldDetail.basicInformation.field.uptrainFrom',
                  })}
                  :
                </Typography>
                <Typography color="text.primary">
                  {field.uptrainFrom ?? '-'}
                </Typography>
              </Stack>
            </ListItem>
          </List>

          <Paper
            elevation={8}
            sx={{
              borderRadius: 1,
              p: 2,
            }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'features.engines.fieldConfiguration.info.description',
                })}
              </Typography>
              <Button
                component={Link}
                to={engineFieldDetailPath(getIDFromUrl(engineUrl), field.id)}
                variant="text"
                color="secondary"
                size="small"
              >
                {intl.formatMessage({
                  id: 'features.engines.fieldConfiguration.info.button',
                })}
              </Button>
            </Stack>
          </Paper>

          {children}
        </>
      ) : (
        <TilesListEmptyState
          title={intl.formatMessage({
            id: `features.engines.fieldConfiguration.notConnected.title`,
          })}
          subtitle={intl.formatMessage({
            id: `features.engines.fieldConfiguration.notConnected.subtitle`,
          })}
        >
          <Button
            onClick={() => {
              setOpen(true);
            }}
            data-cy="create-engine-field-button"
            variant="outlined"
            color="secondary"
          >
            {intl.formatMessage({
              id: 'features.engines.fieldConfiguration.notConnected.button',
            })}
          </Button>
        </TilesListEmptyState>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ transition: 'smooth' }}
        data-cy="create-field-dialog"
        PaperProps={{
          elevation: 2,
        }}
      >
        {open ? (
          <CreateFieldDialogContent
            engineUrl={engineUrl}
            onClose={() => setOpen(false)}
            tabular={tabular}
            name={engineFieldName}
            label={engineFieldLabel}
          />
        ) : null}
      </Dialog>
    </ContentGroup>
  );
};
