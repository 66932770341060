const surveys = ['dataCaptureSurvey', 'auroraForLineItemsSurvey'] as const;

export type SurveyName = (typeof surveys)[number];

export const surveysUuidMap: Record<SurveyName, string> = {
  dataCaptureSurvey: '1e90b867-8bcd-4fce-be37-9b3b0fa6dacd',
  auroraForLineItemsSurvey: '533d578c-8432-46fe-8117-d07baf6f68bc',
  // not used anymore
  // unifiedDashboardSurvey: '36b5b99e-d1ba-432c-967e-87e9732695d4',
};
