import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import {
  AutomationPayload,
  AutomationResponse,
} from '@rossum/api-client/queues';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { api } from '../../lib/apiClient';

const QUERY_KEY_QUEUE_AUTOMATION = 'queue-automation';

export const useAutomation = (
  queueId: number,
  payload: AutomationPayload,
  queryOptions: Omit<
    UseQueryOptions<
      AutomationResponse,
      ElisClientError,
      AutomationResponse,
      readonly [typeof QUERY_KEY_QUEUE_AUTOMATION, number, AutomationPayload]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery({
    queryKey: [QUERY_KEY_QUEUE_AUTOMATION, queueId, payload] as const,
    queryFn: () => api.request(endpoints.queues.automation(queueId, payload)),
    ...queryOptions,
    select: data => ({
      ...data,
      datapoints: data.datapoints.map(datapoint => ({
        ...datapoint,
        uuid: uuidv4(),
      })),
    }),
  });
};
