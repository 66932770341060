import { listSubheaderClasses, MenuListProps } from '@rossum/ui/material';

export const menuListSharedProps: Partial<MenuListProps> = {
  sx: {
    background: 'inherit',
    [`.${listSubheaderClasses.root}`]: {
      background: 'inherit',
      color: 'text.disabled',
    },
  },
};

export type Template = {
  title: string;
  description: string;
  snippet: string;
  example: string;
  auroraSuggestion?: boolean;
};
