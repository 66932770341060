import { Url } from '@rossum/api-client';
import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import ActionDialog from '../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../components/UI/ActionDialog/ActionDialogActions';
import { useCreateEngineField } from '../hooks/useCreateEngineField';
import { useFieldForm } from '../pages/FieldDetailPage';
import { FieldForm } from './FieldForm';

export const FIELD_INLINE_FORM_ID = 'engine_field_inline_form';

type CreateFieldDialogContentProps = {
  engineUrl: Url;
  name: string;
  label: string;
  onClose: () => void;
  tabular: boolean;
};

export const CreateFieldDialogContent = ({
  engineUrl,
  name,
  label,
  onClose,
  tabular,
}: CreateFieldDialogContentProps) => {
  const intl = useIntl();

  const { mutate, error, isLoading } = useCreateEngineField(engineUrl);

  const { control, handleSubmit } = useFieldForm({
    error,
    defaultValues: {
      label,
      name,
      uptrainFrom: null,
      type: 'string',
      subtype: '',
      tabular,
      multiline: 'false',
    },
  });

  return (
    <ActionDialog
      dialogTitle={intl.formatMessage({
        id: 'features.engines.dialog.createEngineField.title',
      })}
      onCancel={onClose}
      dialogColor="secondary"
      dialogActions={
        <ActionDialogActions
          dataCyConfirm="createFieldDialog-confirm-btn"
          confirmButtonText={intl.formatMessage({
            id: 'features.engines.dialog.createEngineField.confirm',
          })}
          confirmButtonProps={{
            color: 'primary',
            type: 'submit',
            form: FIELD_INLINE_FORM_ID,
            disabled: isLoading,
          }}
        />
      }
      dataCy="createFieldDialog-cancel-btn"
    >
      <Stack
        pt={0.5}
        component="form"
        id={FIELD_INLINE_FORM_ID}
        onSubmit={handleSubmit(({ field }) => {
          mutate(field);
          onClose();
        })}
      >
        <FieldForm control={control} isCompact disableName tabular={tabular} />
      </Stack>
    </ActionDialog>
  );
};
