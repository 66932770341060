import { FilterAltOffRounded, FilterAltRounded } from '@rossum/ui/icons';
import { Badge, IconButton } from '@rossum/ui/material';
import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { TypedGridColDef } from '../filtering/types';

type Props = {
  onClick: () => void;
  filterChipsCount: number;
  showFilterPanel: boolean;
};

export const getFilterChipsCount = ({
  existingFilter,
  filterableColumns,
  dashboardLevel,
}: {
  existingFilter: GridFilterModel | null;
  filterableColumns: TypedGridColDef[];
  dashboardLevel: string | undefined;
}) =>
  existingFilter?.items.reduce((acc, item) => {
    if (dashboardLevel === 'all') {
      return acc + 1;
    }
    const isColumnValid = filterableColumns.some(
      col => col.field === item.field
    );
    if (isColumnValid) return acc + 1;

    return acc;
  }, 0) ?? 0;

export const HideFiltersButton = ({
  onClick,
  filterChipsCount,
  showFilterPanel,
}: Props) => {
  return (
    <IconButton onClick={onClick} color="secondary">
      <Badge
        color="primary"
        invisible={showFilterPanel}
        badgeContent={filterChipsCount}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {showFilterPanel ? <FilterAltOffRounded /> : <FilterAltRounded />}
      </Badge>
    </IconButton>
  );
};
