import { Schema } from '@rossum/api-client/schemas';
import { useIntl } from 'react-intl';
import QueueSettingsBreadcrumbs from '../components/QueueSettingsBreadcrumbs';
import { getFlattenSchemaFields } from '../fields/helpers';

export const useBreadcrumbs = (
  queueId: number,
  subPageKeys: string[],
  queueName: string | undefined,
  schema: Schema | undefined
) => {
  const intl = useIntl();

  const fieldsIdsMap = getFlattenSchemaFields(schema?.content || []);

  const dynamicKeys = ['queueName'];
  const dynamicBreadcrumbs = dynamicKeys.map(key => {
    if (key === 'queueName') {
      return {
        value: `${queueId}`,
        label: queueName ?? `${queueId}`,
        isLink: false,
      };
    }

    return {
      value: key,
      label: key,
      isLink: false,
    };
  });

  const dynamicFieldsBreadcrumbs = subPageKeys.map(key => {
    return {
      value: key,
      label:
        fieldsIdsMap[key]?.field.label ??
        (subPageKeys.length === 1
          ? intl.formatMessage({
              id: 'features.queueSettings.fields.breadcrumbs.newSection',
            })
          : intl.formatMessage({
              id: 'features.queueSettings.fields.breadcrumbs.newField',
            })),
      isLink: true,
    };
  });

  const breadcrumbs = (
    <QueueSettingsBreadcrumbs
      dynamicBreadcrumbs={[...dynamicBreadcrumbs, ...dynamicFieldsBreadcrumbs]}
    />
  );

  return { breadcrumbs };
};
