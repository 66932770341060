// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const createQueueFromTemplateQuerySchema = z.object({
  legacy: z.boolean().optional(),
});

export const createQueueFromTemplatePayloadSchema = z.object({
  name: z.string(),
  workspace: urlSchema,
  templateName: z.string(),
  includeDocuments: z.boolean(),
});
