import { Stack, Typography } from '@rossum/ui/material';
import { GridRow, GridRowProps } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';

export const BlurredDataGridRow = ({
  disableOverlayText = false,
  ...props
}: Omit<GridRowProps, 'row'> & {
  row?: unknown;
  disableOverlayText?: boolean;
}) => {
  const intl = useIntl();
  return (
    <Stack position="relative" data-cy="annotation-row-blurred">
      {!disableOverlayText ? (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography whiteSpace="nowrap" variant="body2">
            {intl.formatMessage({
              id: 'features.pricing.documentArchive.blurredAnnotation.text',
            })}
          </Typography>
        </Stack>
      ) : null}
      <Stack
        sx={{
          filter: 'blur(4px)',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <GridRow {...props} />
      </Stack>
    </Stack>
  );
};
