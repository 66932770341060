import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  suggestFormulaPayloadSchema,
  suggestFormulaResponseSchema,
} from './suggestFormula.schema';

export type SuggestFormulaPayload = {
  fieldSchemaId: string;
  hint: string;
  schemaContent: SchemaSection[];
};

export type SuggestFormulaResult = {
  type: string;
  name: string;
  description: string;
  formula: string;
};

export type SuggestFormulaResponse = {
  results: Array<SuggestFormulaResult>;
};

export const suggestFormula = (payload: SuggestFormulaPayload) =>
  ({
    endpoint: `internal/schemas/suggest_formula`,
    method: 'POST',
    responseSchema: suggestFormulaResponseSchema,
    payloadSchema: suggestFormulaPayloadSchema,
    payload,
  } as const);
