import {
  Button,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from '@rossum/ui/material';
import {
  DataGridPro,
  gridClasses,
  GridNoRowsOverlay,
} from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { mergeDeep } from 'remeda';
import { boldText } from '../../../lib/formaterValues';
import {
  commonDataGridStyles,
  virtualScrollerOnResizeStyles,
} from '../../../ui/data-grid/styles';
import CustomNoRowsOverlay from './components/CustomNoRowsOverlay';
import { useFormulaPreviewContext } from './contexts/formulaPreview/formulaPreviewContext';
import { PAGE_SIZE } from './contexts/formulaPreview/useGetAnnotationsForPreview';
import { useColumns } from './hooks/useColumns';
import { useUpdateColumWidth } from './hooks/useColumnWidths';
import { documentsColumn, useRows } from './hooks/useRows';

const MAX_COUNT = 20;
const PAGES_LIMIT = MAX_COUNT / PAGE_SIZE;

const gridStyles = mergeDeep(
  { ...commonDataGridStyles, ...virtualScrollerOnResizeStyles },
  {
    // fix cropped empty NoRowsOverlay
    [`.${gridClasses.virtualScroller}`]: {
      minHeight: 200,
    },
  }
);

const FormulaPreview = () => {
  const intl = useIntl();
  const { annotationsQuery, evaluationQueries, previewEnabled } =
    useFormulaPreviewContext();
  const { data, hasNextPage, fetchNextPage, isFetching } = annotationsQuery;

  const isIdle = evaluationQueries.some(
    query => query.isLoading && !query.isFetching
  );

  const columns = useColumns();
  const rows = useRows({ columns });

  const updateColumnWidth = useUpdateColumWidth();

  return (
    <Stack sx={{ mb: 7, backgroundColor: 'inherit' }} spacing={2}>
      <Stack spacing={1}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold ' }}
          color="text.primary"
        >
          {intl.formatMessage({
            id: 'features.queueSettings.fields.formulaPreview.title',
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage(
            {
              id: 'features.queueSettings.fields.formulaPreview.description',
            },
            { bold: boldText }
          )}
        </Typography>
      </Stack>
      <DataGridPro
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay:
            rows.length === 0 ? GridNoRowsOverlay : CustomNoRowsOverlay,
        }}
        slotProps={{
          noRowsOverlay: {
            sx: { backgroundColor: 'inherit' },
          },
          loadingOverlay: {
            color: 'secondary',
          },
        }}
        localeText={
          rows.length === 0
            ? {
                noRowsLabel: intl.formatMessage({
                  id: 'features.queueSettings.fields.formulaPreview.noAnnotations',
                }),
              }
            : undefined
        }
        autoHeight
        loading={
          annotationsQuery.isFetching ||
          evaluationQueries.some(query => query.isFetching)
        }
        columns={columns}
        rows={isIdle ? [] : rows}
        onColumnWidthChange={updateColumnWidth}
        density="compact"
        disableColumnMenu
        disableColumnReorder
        hideFooter
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: { right: [documentsColumn] },
        }}
        sx={gridStyles}
      />

      {hasNextPage && (data?.pages.length ?? 0) < PAGES_LIMIT && !isIdle && (
        <Button
          onClick={() => fetchNextPage()}
          variant="outlined"
          color="secondary"
          sx={{ alignSelf: 'flex-end' }}
          disabled={isFetching || !previewEnabled}
          startIcon={
            isFetching && <CircularProgress size={16} color="inherit" />
          }
          data-cy="formula-preview-show-more"
        >
          {intl.formatMessage(
            {
              id: 'features.queueSettings.fields.formulaPreview.showMore',
            },
            {
              count: Math.min(
                (data?.pages[0]?.pagination.total ?? 0) -
                  (data?.pages.length ?? 0) * PAGE_SIZE,
                PAGE_SIZE
              ),
            }
          )}
        </Button>
      )}
    </Stack>
  );
};

export default FormulaPreview;
