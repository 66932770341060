import { LogsQuery } from '@rossum/api-client/hooks';
import { Close } from '@rossum/ui/icons';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  paperClasses,
  Select,
  SelectProps,
  Skeleton,
  Stack,
} from '@rossum/ui/material';
import { kebabCase } from 'lodash';
import { useIntl } from 'react-intl';
import { LogLevel } from 'react-use-intercom';
import {
  activeLabelStyle,
  activeOutlineStyle,
} from '../../../../components/UI/SingleQueueSelect/styles';

type FilterSelectType = 'pageSize' | 'logLevel';

type Props = {
  setFilters: (filters: LogsQuery) => void;
  isLoadingExtensions: boolean;
  value: SelectProps<LogLevel | string>['value'] | undefined;
  options: string[];
  filterSelectType: FilterSelectType;
  filters: LogsQuery;
};

const FILTER_SELECT_WIDTH = 150;

const FilterSelect = ({
  isLoadingExtensions,
  value,
  setFilters,
  filters,
  options,
  filterSelectType,
}: Props) => {
  const intl = useIntl();
  const hasValue = !!value;

  const label = kebabCase(filterSelectType);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormControl variant="outlined" sx={{ width: FILTER_SELECT_WIDTH }}>
        <InputLabel
          sx={hasValue && !isLoadingExtensions ? activeLabelStyle : {}}
          id={`${label}-logs-label`}
          size="small"
        >
          {intl.formatMessage({
            id: `containers.settings.extensions.logs.filters.${filterSelectType}.label`,
          })}
        </InputLabel>

        <Select
          labelId={`${label}-logs-label`}
          label={intl.formatMessage({
            id: `containers.settings.extensions.logs.filters.${filterSelectType}.label`,
          })}
          size="small"
          fullWidth
          value={hasValue ? value : ''}
          sx={hasValue && !isLoadingExtensions ? activeOutlineStyle : {}}
          startAdornment={
            isLoadingExtensions &&
            hasValue && <Skeleton variant="text" width="200%" />
          }
          data-cy={`${label}-logs-select`}
          onChange={({ target: { value } }) => {
            return setFilters({
              ...filters,
              [filterSelectType]:
                filterSelectType === 'logLevel' ? [value] : value,
            });
          }}
          disabled={isLoadingExtensions}
          endAdornment={
            hasValue ? (
              <Stack
                sx={{ position: 'absolute', right: 30 }}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    setFilters({ ...filters, [filterSelectType]: undefined })
                  }
                >
                  <Close fontSize="small" />
                </IconButton>
              </Stack>
            ) : null
          }
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              [`& .${paperClasses.root}`]: {
                width: 'fit-content',
              },
            },
          }}
        >
          {options.map(value => (
            <MenuItem key={`${value}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default FilterSelect;
