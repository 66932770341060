import { ActionsObservable, combineEpics } from 'redux-observable';
import { filter, map, pluck } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EMAIL_THREAD_ATTACHMENTS } from '../../../containers/EmailDrawer/hooks/useEmailThreadDocuments';
import { invalidateQueryClientCache } from '../../middlewares/queryClientMiddleware';
import { RootActionType } from '../../rootActions';
import { deleteAnnotationFulfilled } from '../annotation/actions';
import { DeleteAnnotationMeta } from '../annotation/types';

const markAttachmentAsDeletedEpic = (
  action$: ActionsObservable<RootActionType>
) =>
  action$.pipe(
    filter(isActionOf([deleteAnnotationFulfilled])),
    pluck('meta'),
    filter(({ emailId }: DeleteAnnotationMeta) => typeof emailId === 'number'),
    map(() => invalidateQueryClientCache([EMAIL_THREAD_ATTACHMENTS]))
  );

export default combineEpics(markAttachmentAsDeletedEpic);
