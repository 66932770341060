import { Check, DoDisturb } from '@rossum/ui/icons';
import { Button, Switch } from '@rossum/ui/material';
import clsx from 'clsx';
import { filter, get, includes, map, pickBy } from 'lodash';
import UsersIcon from 'mdi-react/AccountMultipleOutlineIcon';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Dispatch } from 'redux';
import InfoPlaceholder from '../../../components/UI/InfoPlaceHolder';
import SearchInput from '../../../components/UI/SearchInput';
import { pageSizes } from '../../../constants/values';
import { useQueueSettingsContext } from '../../../features/queue-settings/contexts/QueueSettingsContext';
import { boldText, white } from '../../../lib/formaterValues';
import { parse, stringify } from '../../../lib/url';
import {
  getRoleName,
  isUserAdminLike,
} from '../../../redux/modules/groups/helpers';
import { openModal } from '../../../redux/modules/modal/actions';
import {
  updateQueueDetail as _updateQueueDetail,
  updateUsersOnQueue,
} from '../../../redux/modules/queues/actions';
import { getName } from '../../../redux/modules/user/helpers';
import { Group } from '../../../types/group';
import { Queue } from '../../../types/queue';
import { State } from '../../../types/state';
import { User, UserList } from '../../../types/user';
import styles from '../styles.module.sass';

type DispatchProps = {
  updateQueueDetail: (payload: Partial<Queue>) => void;
  onEnableAll: (count: number) => () => void;
  onDisableAll: (count: number) => () => void;
};

type OwnProps = RouteChildrenProps & {
  selectedQueue?: Queue | null;
};

type StateProps = {
  users: UserList;
  batchButtonsDisabled: boolean;
  count: number;
  groups: Array<Group>;
};

type Props = StateProps &
  OwnProps & {
    updateQueueDetail: (payload: Partial<Queue>) => void;
    onEnableAll: () => void;
    onDisableAll: () => void;
  };

const defaultQuery = { pageSize: 15, page: 1, ordering: 'username' };

const QueueAccess = ({
  batchButtonsDisabled,
  users,
  selectedQueue,
  history,
  location,
  updateQueueDetail,
  count,
  onEnableAll,
  onDisableAll,
  groups,
}: Props) => {
  const { search = '', ...query } = parse(location.search);

  const intl = useIntl();

  const { setHeaderMeta } = useQueueSettingsContext();

  useEffect(() => {
    setHeaderMeta(prevState => ({
      ...prevState,
      title: intl.formatMessage({
        id: `containers.settings.queues.queue.access`,
      }),
      description: intl.formatMessage({
        id: `containers.settings.queues.queue.access.description`,
      }),
      buttons: [],
    }));
  }, [intl, setHeaderMeta]);

  useEffect(() => {
    const { pageSize, page, ordering } = parse(location.search) || {};
    if (!ordering || !page || !pageSize || !includes(pageSizes, pageSize)) {
      history.replace({
        state: get(location, 'state'),
        search: stringify(defaultQuery),
      });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSearchPhrase = (value: string) =>
    history.replace({
      state: get(location, 'state'),
      search: stringify(pickBy({ ...query, search: value, page: 1 })),
    });

  const isEnabled = ({ url }: User) =>
    includes(get(selectedQueue, 'users', []), url);

  const isAdmin = ({ groups: userGroups }: User) =>
    isUserAdminLike(getRoleName(userGroups, groups));

  const onToggle = (enabled: boolean, user: User) =>
    updateQueueDetail({
      users: enabled
        ? [...get(selectedQueue, 'users', []), user.url]
        : filter(
            get(selectedQueue, 'users', []),
            userUrl => userUrl !== user.url
          ),
    });

  return (
    <div className={styles.AccessLayout} data-page-title="queue-access">
      <div className={styles.AccessHeader}>
        <div className={styles.AccessTitle}>
          <FormattedMessage id="containers.settings.queues.queue.title" />
        </div>
        {!batchButtonsDisabled && (
          <div className={styles.AccessBadges}>
            <Button
              color="secondary"
              size="small"
              startIcon={<Check />}
              data-cy="queue-access-enable-all"
              onClick={() => onEnableAll()}
            >
              <FormattedMessage
                id="containers.users.components.workspace.enableAll"
                values={{ count }}
              />
            </Button>
            <Button
              color="secondary"
              size="small"
              data-cy="queue-access-disable-all"
              onClick={() => onDisableAll()}
              startIcon={<DoDisturb />}
            >
              <FormattedMessage
                id="containers.users.components.workspace.disableAll"
                values={{ count }}
              />
            </Button>
          </div>
        )}
        <SearchInput
          className={styles.Search}
          value={search ?? ''}
          onChange={setSearchPhrase}
          placeholder="containers.settings.queues.queue.searchPlaceholder"
        />
      </div>
      {users.length ? (
        <table className={styles.UsersTable}>
          <tbody>
            {map(users, user => (
              <tr
                className={clsx(
                  !isEnabled(user) && !isAdmin(user) && styles.DisabledUser
                )}
                key={user.id}
              >
                <td>
                  <Switch
                    data-cy="queue-access-enable-user-toggle"
                    disabled={!selectedQueue || isAdmin(user)}
                    onChange={(_, value) => onToggle(value, user)}
                    checked={isEnabled(user) || isAdmin(user)}
                    size="small"
                  />
                </td>
                <td>{getName(user)}</td>
                <td>
                  {isAdmin(user) && (
                    <FormattedMessage id="containers.settings.queues.queue.admin" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <InfoPlaceholder
          icon={<UsersIcon />}
          title="containers.settings.queues.noUserFound"
          text="containers.settings.queues.noUserFound.text"
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ groups, users }: State, { location }: OwnProps) => {
  const isSearchActive = get(parse(location.search), 'search');
  const usersTotal = get(users, 'pagination.total', 0);
  const adminsTotal = get(users, 'adminsCount', 0);

  return {
    users: users.list,
    batchButtonsDisabled: usersTotal === adminsTotal,
    count: isSearchActive ? Math.max(usersTotal - adminsTotal, 0) : 0,
    groups,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { selectedQueue }: OwnProps
): DispatchProps => ({
  updateQueueDetail: (payload: Partial<Queue>) =>
    selectedQueue &&
    dispatch(_updateQueueDetail(get(selectedQueue, 'id'), payload)),
  onEnableAll: (count: number) => () =>
    selectedQueue &&
    dispatch(
      openModal({
        textId: 'enableUsersOnQueue',
        confirmAction: () =>
          updateUsersOnQueue({
            queueId: get(selectedQueue, 'id'),
            adding: true,
          }),
        values: {
          count,
          queueName: get(selectedQueue, 'name'),
          white,
          bold: boldText,
        },
      })
    ),
  onDisableAll: (count: number) => () =>
    dispatch(
      openModal({
        textId: 'disableUsersOnQueue',
        confirmAction: () =>
          updateUsersOnQueue({
            queueId: get(selectedQueue, 'id')!,
            adding: false,
          }),
        values: {
          count,
          queueName: get(selectedQueue, 'name'),
          white,
          bold: boldText,
        },
      })
    ),
});

const mergeProps = (
  stateProps: StateProps,
  { onEnableAll, onDisableAll, ...dispatchProps }: DispatchProps,
  ownProps: OwnProps
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onDisableAll: onDisableAll(stateProps.count),
  onEnableAll: onEnableAll(stateProps.count),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(QueueAccess);
