import { Tooltip } from '@rossum/ui/material';
import { useEffect, useRef, useState } from 'react';
import { ExtensionText } from './ExtensionText';

type EventNameWithActionsProps = {
  secondaryText: string;
  primaryText: string;
};

const ExtensionTextWithTooltip = ({
  secondaryText,
  primaryText,
}: EventNameWithActionsProps) => {
  const [tooltipDisabled, setTooltipDisabled] = useState(true);
  const textRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    const node = textRef.current;
    if (node && node.offsetWidth < node.scrollWidth) {
      setTooltipDisabled(false);
    }
  }, []);

  const TextWithoutTooltip = (
    <ExtensionText
      ref={textRef}
      primaryText={primaryText}
      secondaryText={secondaryText}
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
    />
  );

  return tooltipDisabled ? (
    TextWithoutTooltip
  ) : (
    <Tooltip
      title={
        <ExtensionText
          primaryText={primaryText}
          secondaryText={secondaryText}
        />
      }
      placement="top"
      slotProps={{
        tooltip: {
          onClick: e => e.stopPropagation(),
        },
      }}
    >
      {TextWithoutTooltip}
    </Tooltip>
  );
};

export default ExtensionTextWithTooltip;
