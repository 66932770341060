import {
  listResponse,
  unpaginatedListResponse,
} from '../../utils/listResponse';
import { uptrainFromFieldSchema } from '../models/uptrainFromField.schema';

export const listUptrainFromFields = () => {
  return {
    endpoint: `/engine_fields/uptrain_from_fields`,
    method: 'GET',
    responseSchema: unpaginatedListResponse(uptrainFromFieldSchema),
  } as const;
};
