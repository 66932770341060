import {
  csCZ,
  deDE,
  enUS,
  esES,
  frFR,
  GridLocaleText,
  jaJP,
  ptBR,
  zhCN,
} from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';

// DataGridPro does not export Localization type, so we have to copy it here
// see: https://github.com/mui/mui-x/issues/5124
export interface Localization {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: Partial<GridLocaleText>;
      };
    };
  };
}

const dataGridLocalizationMap: Record<string, Localization> = {
  zh: zhCN,
  cs: csCZ,
  fr: frFR,
  de: deDE,
  ja: jaJP,
  'pt-br': ptBR,
  es: esES,
  en: enUS,
};

export const useDataGridLocaleText = () => {
  const { locale, formatMessage } = useIntl();

  // dataGridLocalizationMap covers all currently available options so localeText should never be undefined.
  const localeText =
    dataGridLocalizationMap[locale]?.components.MuiDataGrid.defaultProps
      .localeText;

  return Object.assign(localeText ?? {}, {
    filterOperatorNotIn: formatMessage({
      id: 'containers.allDocuments.operators.notIn.label',
    }),
  });
};
