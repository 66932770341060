import { Ace } from 'ace-builds';
import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { schemaFieldRefPrefix } from '../constants';

export const getAceCompleter = (
  completions: Array<Ace.Completion>,
  cancel?: () => void
): Ace.Completer => ({
  getCompletions: (_editor, _session, _position, _prefix, callback) => {
    callback(null, completions);
  },
  cancel,
});

export const getFieldCompletions = (
  fields: Array<SchemaSection | SchemaItem>,
  withPrefix?: boolean
): Array<Ace.Completion> =>
  fields.map(({ id, label }) => ({
    caption: `${label} (${id})`,
    value: withPrefix ? `${schemaFieldRefPrefix}.${id}` : id,
    type: 'value',
    meta: 'field',
  }));

export const matchSchemaFieldRefPrefix = (token: string) => {
  const regexString = `(?<!\\.)\\b(${schemaFieldRefPrefix})\\b$`;
  return token.match(new RegExp(regexString));
};

export const matchMultivalueDatapointReference = ({
  currentFormulaId,
  lastToken,
  datapointIdsInTuples,
}: {
  datapointIdsInTuples: { [tupleId: string]: Array<string> };
  lastToken: string;
  currentFormulaId: string | undefined;
}) => {
  const currentFormulaTupleId = currentFormulaId
    ? R.pipe(
        datapointIdsInTuples,
        R.entries.strict(),
        R.find(([_, datapointIds]) => datapointIds.includes(currentFormulaId)),
        found => found?.[0]
      )
    : undefined;

  const regexString = `(?<=${schemaFieldRefPrefix}\\.)\\w+$`;
  const referencedField = lastToken.match(new RegExp(regexString))?.[0];
  const referencedFieldTupleId = referencedField
    ? R.pipe(
        datapointIdsInTuples,
        R.entries.strict(),
        R.find(([_, datapointIds]) => datapointIds.includes(referencedField)),
        found => found?.[0]
      )
    : undefined;

  return (
    referencedFieldTupleId && currentFormulaTupleId !== referencedFieldTupleId
  );
};
