import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router';
import ContentWrapper from '../../components/ContentWrapper';
import PageLayout from '../../components/PageLayout';
import Scrollable from '../../components/Scrollable';
import { enterUser, enterUserList } from '../../redux/modules/ui/actions';
import { selectedUserSelector } from '../../redux/modules/users/selectors';
import Route from '../../routes/Route';
import DetailSidebarItems from '../Settings/components/DetailSidebarItems';
import SidebarSection from '../Settings/components/SidebarSection';
import { SettingsSidebarItems } from '../Settings/containers/SettingsSidebarItems';
import Sidebar from '../Settings/containers/Sidebar';
import User from '../User';
import Access from '../User/containers/Access';
import Info from '../User/containers/Info';
import { userSidebarItems } from '../User/sidebarItems';
import Users from '.';
import {
  userDetailsAccessPath,
  userDetailsAccessRoute,
  userDetailsInfoRoute,
  userDetailsRoute,
  UserParams,
  usersPath,
} from './helpers';
import UsersFooter from './UsersFooter';

export const UsersRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation<{ backlinkSearch?: string }>();
  const match = useRouteMatch<UserParams>(userDetailsRoute);
  const selectedUser = useSelector(selectedUserSelector);

  return (
    <PageLayout>
      <Sidebar
        backLink={
          match
            ? {
                title: 'containers.settings.sidebar.title.usersDetail',
                to: `${usersPath()}${location.state?.backlinkSearch ?? ''}`,
              }
            : null
        }
      >
        <Switch>
          <Route path={usersPath()} exact component={SettingsSidebarItems} />
          <Route
            path={userDetailsRoute}
            render={routeProps => (
              <SidebarSection label="containers.settings.sidebar.users">
                <DetailSidebarItems
                  {...routeProps}
                  items={userSidebarItems.items.map(item => ({
                    ...item,
                    text: `containers.settings.users.user.${item.name}` as const,
                  }))}
                  type={userSidebarItems.type}
                />
              </SidebarSection>
            )}
          />
        </Switch>
      </Sidebar>

      <ContentWrapper>
        <Scrollable>
          <Switch>
            <Route
              path={usersPath()}
              exact
              component={Users}
              onEnter={() => dispatch(enterUserList())}
            />
            {match && Number(match.params.userId) ? (
              <Route
                onEnter={() => dispatch(enterUser())}
                path={userDetailsRoute}
              >
                {!selectedUser ? null : (
                  <User location={location} selectedUser={selectedUser}>
                    <Switch>
                      <Route path={userDetailsAccessRoute} exact>
                        <Access selectedUser={selectedUser} />
                      </Route>
                      <Route path={userDetailsInfoRoute} exact>
                        <Info selectedUser={selectedUser} />
                      </Route>
                      <Redirect
                        to={userDetailsAccessPath(Number(match.params.userId))}
                      />
                    </Switch>
                  </User>
                )}
              </Route>
            ) : (
              <Redirect to={usersPath()} />
            )}
          </Switch>
        </Scrollable>
        <Switch>
          <Route path={usersPath()} component={UsersFooter} exact />
        </Switch>
      </ContentWrapper>
    </PageLayout>
  );
};
