import { useRouteMatch } from 'react-router';
import { engineDetailRoute, engineFieldDetailRoute } from '../paths';

// We do the matching manually so that we can re-use this in header / breadcrumbs.
// TODO: Handle unsupported routes:
// http://localhost:3000/automation/engines/new/fields/1
// http://localhost:3000/automation/engines/new/fields/new
export const useRouteParams = () => {
  const fieldDetailMatch = useRouteMatch<{
    engineId?: string;
    fieldId?: string;
  }>(engineFieldDetailRoute);

  const engineDetailMatch = useRouteMatch<{
    engineId?: string;
  }>(engineDetailRoute);

  const engineId = engineDetailMatch?.params.engineId;
  const fieldId = fieldDetailMatch?.params.fieldId;

  const resolveId = (urlParam: string | undefined) =>
    urlParam === 'new' ? ('new' as const) : Number(urlParam) || undefined;

  return {
    engineId: resolveId(engineId),
    fieldId: resolveId(fieldId),
  };
};
