import { GridPreProcessEditCellProps } from '@rossum/ui/x-data-grid-pro';
import { isFinite, isNumber } from 'lodash';
import { IntlShape } from 'react-intl';

// both functions taken from elis-frontend
const limitPercentageDecimalPlaces = (num: number) =>
  parseFloat(num.toFixed(1));

const limitFloatDecimalPlaces = (num: number) => parseFloat(num.toFixed(3));

export const valueToPercentage = (value: number | null | undefined) =>
  isNumber(value) && isFinite(value)
    ? limitPercentageDecimalPlaces(value * 100)
    : value;

export const percentageToValue = (value: unknown) => {
  const valueAsNumber = Number(value);

  return value !== null && isNumber(valueAsNumber) && isFinite(valueAsNumber)
    ? limitFloatDecimalPlaces(valueAsNumber / 100)
    : undefined;
};

export const appendPercentSignToNumber = <
  O extends { value: number | null | undefined },
>({
  value,
}: O) => (isNumber(value) ? `${value} %` : undefined);

export const validatePercentageRange =
  (fieldName: string, intl: IntlShape) =>
  (params: GridPreProcessEditCellProps) => {
    const hasError = params.props.value < 0 || params.props.value > 100;

    return {
      ...params.props,
      error: hasError,
      errorMessage: intl.formatMessage(
        {
          id: 'features.fieldManager.fieldDetail.columns.percentages.validatePercentageRange.error',
        },
        { fieldName }
      ),
    };
  };
