import axios from 'axios';
import {
  appUrl,
  prometheusPasskey,
  prometheusUsername,
} from '../constants/config';

export const sendMetricsToPrometheus = (metrics: string) => {
  const url =
    appUrl.includes('review') || appUrl.includes('localhost')
      ? 'https://elis.develop.r8.lol/prometheus/metrics'
      : '/prometheus/metrics';

  axios
    .post(url, metrics, {
      auth: { username: prometheusUsername, password: prometheusPasskey },
    })
    .catch(e => {
      throw new Error(`Metric to Prometheus failed with: ${e}`);
    });
};
