import { Autocomplete, TextField, TextFieldProps } from '@rossum/ui/material';
import { forwardRef, Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DataType, useTypesAndSubtypes } from '../hooks/useTypesAndSubtypes';
import { AutocompleteGroup } from './autocomplete/AutocompleteGroup';
import { AutocompleteItem } from './autocomplete/AutocompleteItem';
import { AutocompleteNoResults } from './autocomplete/AutocompleteNoResults';

const renderSubtype = (subtype: string) => subtype || 'default';

export const renderOption = (option: DataType) =>
  `${option.type} - ${renderSubtype(option.subtype)}`;

export const TypesSubtypesInput = forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      TextFieldProps,
    }: {
      value: DataType;
      onChange: (value: DataType) => void;
      disabled: boolean;
      TextFieldProps: Pick<TextFieldProps, 'helperText' | 'error'>;
    },
    ref: React.ForwardedRef<unknown>
  ) => {
    const intl = useIntl();
    const { typesAndSubtypes } = useTypesAndSubtypes();
    const [inputValue, setInputValue] = useState('');

    const selectedItem = useMemo(
      () =>
        typesAndSubtypes.find(
          option =>
            option.type === value.type && option.subtype === value.subtype
        ) ?? { type: 'string' as const, subtype: '' },
      [typesAndSubtypes, value.subtype, value.type]
    );

    return (
      <Autocomplete
        id="field.dataType"
        ref={ref}
        value={selectedItem}
        disableClearable
        onChange={(_, value) => {
          if (value) {
            onChange(value);
          }
        }}
        onInputChange={(_, enteredText) => {
          setInputValue(enteredText);
        }}
        inputValue={inputValue}
        options={typesAndSubtypes}
        getOptionLabel={option => (option ? renderOption(option) : '')}
        renderInput={params => (
          <TextField
            {...params}
            {...TextFieldProps}
            label={intl.formatMessage({
              id: 'features.engines.fieldDetail.type.field.dataType',
            })}
          />
        )}
        groupBy={option => option.type ?? ''}
        renderGroup={params => {
          return (
            <Fragment key={params.key}>
              {params.group && (
                <AutocompleteGroup>{params.group}</AutocompleteGroup>
              )}
              {params.children}
            </Fragment>
          );
        }}
        renderOption={(params, option) => (
          <AutocompleteItem
            MenuItemProps={params}
            key={`${option.type}/${option.subtype}`}
            label={renderSubtype(option.subtype)}
            description={renderSubtype(option.subtype)}
          />
        )}
        noOptionsText={<AutocompleteNoResults inputValue={inputValue} />}
        disabled={disabled}
      />
    );
  }
);
