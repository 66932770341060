import InformationIcon from 'mdi-react/InformationOutlineIcon';
import { FormattedMessage } from 'react-intl';
import styles from '../style.module.sass';

const SchemaEditorDescription = () => (
  <div className={styles.SchemaEditorDescription}>
    <InformationIcon size={35} />
    <div className={styles.SchemaEditorText}>
      <FormattedMessage id="containers.settings.fields.info.description" />
    </div>
  </div>
);

export default SchemaEditorDescription;
