import { Schema, SchemaSection } from '@rossum/api-client/schemas';
import { compact, isEqual } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getFlattenSchemaFields } from '../helpers';
import { FieldRouteParams, SchemaFieldWithPath } from '../types';
import { useResolveUrl } from './useResolveUrl';

const getCorrectFieldAndPath = (
  schemaContent: SchemaSection[],
  urlFieldRoute: string[]
) => {
  const fieldsIdsMap = getFlattenSchemaFields(schemaContent);

  return urlFieldRoute.reduce<SchemaFieldWithPath>(
    (acc, fieldId) => {
      const fieldData = fieldsIdsMap[fieldId];

      if (!acc.path?.length) {
        if (!fieldData) {
          return {
            field: null,
            path: [],
          };
        }

        return fieldData;
      }

      if (!fieldData) {
        return {
          field: null,
          path: [...acc.path],
        };
      }

      return {
        field: fieldData.field,
        path: [...(fieldData.path ?? [])],
      };
    },
    { field: null, path: null }
  );
};

export type UseResolveSchemaFieldProps = {
  schema: Schema;
  fieldsRoute: FieldRouteParams;
};

export const useResolveSchemaField = ({
  schema,
  fieldsRoute: { sectionId, schemaItemId, schemaItemChildId },
}: UseResolveSchemaFieldProps) => {
  const history = useHistory();
  const getCorrectUrl = useResolveUrl();

  const urlFieldRoute = compact([sectionId, schemaItemId, schemaItemChildId]);

  const { field, path } = getCorrectFieldAndPath(
    schema.content ?? [],
    urlFieldRoute
  );

  useEffect(() => {
    const schemaFieldRoute = path?.map(({ id }) => id) ?? [];

    if (path && !isEqual(schemaFieldRoute, urlFieldRoute)) {
      const correctUrl = getCorrectUrl(!field, path, urlFieldRoute);

      if (correctUrl) {
        history.replace(correctUrl);
      }
    }
  }, [field, getCorrectUrl, history, path, urlFieldRoute]);

  if (!schema || !sectionId || sectionId.length === 0) {
    return { field: null, path: null };
  }

  return { field, path };
};
