import { useEffect, useMemo, useState } from 'react';
import { FIELDS_SECTIONS_EXPANDED } from '../../../redux/modules/localStorage/actions';

type SectionsAccordionsState = {
  [schemaId: string]: {
    [sectionId: string]: boolean;
  };
};

export const useSectionsAccordions = ({ schemaId }: { schemaId: string }) => {
  const safelyParsedState: SectionsAccordionsState = useMemo(() => {
    const savedState = localStorage.getItem(FIELDS_SECTIONS_EXPANDED);

    if (!savedState) return {};

    try {
      const stateAsJSON = JSON.parse(savedState);

      return stateAsJSON;
    } catch {
      return {};
    }
  }, []);

  const [state, setState] = useState(safelyParsedState);

  useEffect(() => {
    localStorage.setItem(FIELDS_SECTIONS_EXPANDED, JSON.stringify(state));
  }, [state]);

  const toggleSectionAccordion = (section: string) => {
    setState(prevState => {
      return {
        ...prevState,
        [schemaId]: {
          ...prevState[schemaId],
          [section]: !(prevState[schemaId]?.[section] ?? true),
        },
      };
    });
  };

  const sectionsAccordionsState = state[schemaId];

  return { sectionsAccordionsState, toggleSectionAccordion };
};
