import {
  Grow,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from '@rossum/ui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { organizationSelector } from '../../redux/modules/organization/selectors';
import { State } from '../../types/state';
import { Tab, visibleMenuItemsForTab } from './navigationStructure';

type PopperMenuProps = {
  open: boolean;
  anchorEl: (EventTarget & HTMLElement) | null;
  hoveredTab: Tab | null;
  handleNavigation: (e: React.MouseEvent, pathname: string) => void;
};

const PopperMenu = ({
  open,
  anchorEl,
  hoveredTab,
  handleNavigation,
}: PopperMenuProps) => {
  const intl = useIntl();
  const features = useSelector(
    (state: State) => organizationSelector(state).uiSettings?.features ?? {}
  );

  const menuItems = !hoveredTab
    ? []
    : visibleMenuItemsForTab(hoveredTab, { features });

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      id="menu-list-grow"
      style={{ zIndex: 12002 }}
    >
      {!!menuItems.length && hoveredTab && (
        <Paper elevation={24}>
          <Grow in={open} appear unmountOnExit key={hoveredTab.name}>
            <List disablePadding sx={{ pt: 1, pb: 1 }}>
              {menuItems.map(menuItem => (
                <ListItem
                  disablePadding
                  dense
                  key={`${hoveredTab.name}-${menuItem.name}`}
                  data-cy={`menu-item-${hoveredTab.name}-${menuItem.name}`}
                >
                  <ListItemButton
                    component={Link}
                    to={menuItem.link}
                    onClick={e => handleNavigation(e, menuItem.link)}
                  >
                    <ListItemIcon sx={{ minWidth: 32, color: 'text.disabled' }}>
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        color: 'text.primary',
                        fontSize: theme => theme.typography.pxToRem(13),
                      }}
                    >
                      {intl.formatMessage({
                        id: `components.appBar.menu.${menuItem.translationKey}`,
                      })}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grow>
        </Paper>
      )}
    </Popper>
  );
};

export default PopperMenu;
