import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { approvalWorkflowsEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';

export const useUnpaginatedWorklowRuns = ({
  annotationIds,
}: {
  annotationIds: number[];
}) => {
  const useWorkflows = useSelector(approvalWorkflowsEnabledSelector);
  return useQuery({
    queryKey: ['showWorkflowActivity', annotationIds],

    queryFn: () =>
      api.unpaginatedRequest(endpoints.workflowRuns.list)({
        annotation: annotationIds,
      }),

    enabled: !!useWorkflows && annotationIds.length !== 0,
  });
};
