import { ValidationInput } from '@rossum/rossum-ui/ValidationInput';
import { isFinite } from 'lodash';
import React, { FocusEvent, ReactNode } from 'react';
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useController,
  UseFormSetFocus,
} from 'react-hook-form';

const ControlledValidationMultiInput = <T extends FieldValues>({
  className,
  clearValidationsOnChange,
  control,
  dataCy,
  disabled,
  getErrorMessage,
  placeholder,
  maxLength,
  name,
  nextFieldName,
  rightIcon,
  rightIconProps,
  setFocus,
  type,
}: {
  control: Control<T>;
  getErrorMessage: (_id: string, _errorMessage: FieldError) => string;
  disabled?: boolean;
  className?: string;
  name: Path<T>;
  placeholder?: string;
  nextFieldName?: Path<T>;
  maxLength?: number;
  dataCy?: string;
  type?: 'text' | 'password';
  rightIcon?: ReactNode;
  rightIconProps?: unknown;
  clearValidationsOnChange?: (
    _onChange: (_event: React.ChangeEvent<unknown>) => void
  ) => (_event: React.ChangeEvent<unknown>) => void;
  setFocus: UseFormSetFocus<T>;
}) => {
  const {
    field: { ref, value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <ValidationInput
      className={className}
      dataCy={dataCy}
      disabled={disabled}
      placeholder={placeholder}
      errorMessage={error ? getErrorMessage(name, error) : ''}
      onBlur={(e: FocusEvent<HTMLInputElement>) => {
        const trimmedValue = e.target.value.trim();
        const sanitizedValue = isFinite(Number(trimmedValue))
          ? trimmedValue.padStart(2, '0')
          : '00';

        if (value !== sanitizedValue) {
          return (
            clearValidationsOnChange
              ? clearValidationsOnChange(onChange)
              : onChange
          )(
            // @ts-expect-error Shouldn't this be event?
            // https://gitlab.rossum.cloud/elis/elis-frontend/-/merge_requests/4810#note_446573
            sanitizedValue
          );
        }
        return undefined;
      }}
      maxLength={maxLength}
      pureOnChange={e => {
        (clearValidationsOnChange
          ? clearValidationsOnChange(onChange)
          : onChange)(e);

        if (e.target.value.length === 2 && !!nextFieldName) {
          setFocus(nextFieldName);
        }
      }}
      isMultiInput
      // @ts-expect-error
      setRef={ref}
      value={value as string}
      type={type}
      rightIconProps={rightIconProps}
      rightIcon={rightIcon}
    />
  );
};

export default ControlledValidationMultiInput;
