import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@rossum/ui/material';
import React, { CSSProperties } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './styles.module.sass';

export type Chunks = React.ReactNode;

export const white = (chunks: Chunks[]) => (
  <span className={styles.WhiteText}>{chunks}</span>
);

export const whiteDarkBackground = (chunks: Chunks[]) => (
  <span className={styles.WhiteTextDarkBackground}>{chunks}</span>
);

export const gray = (chunks: Chunks[]) => (
  <span className={styles.GrayText}>{chunks}</span>
);

export const boldText = (chunks: Chunks[]) => (
  <span className={styles.BoldText}>{chunks}</span>
);

export const italicText = (chunks: Chunks[]) => (
  <span className={styles.ItalicText}>{chunks}</span>
);

export const linebreak = <br />;

export const nbsp = '\u00a0';

export const button =
  (onClick: () => void, style?: CSSProperties, dataCy?: string) =>
  (chunks: Chunks[]) => (
    <button
      className={styles.TranslatedButton}
      onClick={onClick}
      style={style}
      data-cy={dataCy}
    >
      {chunks}
    </button>
  );

export const link =
  (href: string, style?: CSSProperties, dataCy?: string) =>
  (chunks: Chunks[]) => (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className={styles.TranslatedLink}
      style={style}
      data-cy={dataCy}
    >
      {chunks}
    </a>
  );

export const routerLink =
  ({
    route,
    backLink,
    attributes = {},
  }: {
    route: string;
    backLink?: string;
    attributes?: Omit<LinkProps, 'to'>;
  }) =>
  (chunks: Chunks[]) => (
    <Link
      {...attributes}
      to={
        backLink
          ? {
              pathname: route,
              state: { backLink },
            }
          : route
      }
    >
      {chunks}
    </Link>
  );

export const tooltip =
  (
    tooltipProps: Omit<TooltipProps, 'children'>,
    childrenTypographyProps: TypographyProps
  ) =>
  (chunks: Chunks[]) => (
    <Tooltip {...tooltipProps}>
      <Typography {...childrenTypographyProps}>{chunks}</Typography>
    </Tooltip>
  );
