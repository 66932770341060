import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineKeys, mutationKey } from '../keys';
import { useToastMessage } from './useToastMessage';

export const useDeleteEngine = () => {
  const queryClient = useQueryClient();
  const { showToastMessage } = useToastMessage();

  return useMutation({
    mutationKey: mutationKey(['delete_engine'] as const),
    mutationFn: (id: ID) => api.request(endpoints.engines.delete(id)),
    onSuccess: (_, id) => {
      showToastMessage('engineDeleted');
      queryClient.removeQueries(engineKeys.detail(id));
    },
  });
};
