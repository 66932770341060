import { GridLogicOperator } from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { DataGridConditionModel, filteringSchema } from '../filtering/mql';

export const useSafelyParsedFiltering = (filteringFromQuery: string) => {
  const safelyParsedFiltering: DataGridConditionModel = useMemo(() => {
    // try to parse stored query and validate it against schema
    // return default shape if it is not valid in catch block
    try {
      const filteringAsJSON = JSON.parse(filteringFromQuery);
      filteringSchema.parse(filteringAsJSON);

      return filteringAsJSON;
    } catch (error) {
      if (filteringFromQuery !== '') {
        // eslint-disable-next-line no-console
        console.error('Filtering query parsing error: ', error);
      }
      return { items: [], logicOperator: GridLogicOperator.And };
    }
  }, [filteringFromQuery]);

  return safelyParsedFiltering;
};
