import clsx from 'clsx';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styles from '../style.module.sass';
import { SchemaObjectFormData } from '../types';
import { SimpleFields } from './SimpleFields';

const SimpleMultivalueFields = ({
  control,
}: {
  control: Control<SchemaObjectFormData>;
}) => {
  const intl = useIntl();

  return (
    <>
      <div className={styles.TableColumnsTitle}>
        {intl.formatMessage({
          id: 'containers.settings.fields.edit.simpleMultivalue.title',
        })}
      </div>
      <div className={styles.SchemaDatapoints}>
        <div
          className={clsx(
            styles.EditFieldDrawerContent,
            styles.EditFieldNestedContent
          )}
        >
          <SimpleFields
            control={control}
            namePrefix="simpleMultivalueChildren."
          />
        </div>
      </div>
    </>
  );
};

export default SimpleMultivalueFields;
