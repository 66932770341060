import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { HighlightMatches } from './searchMatching';
import { Template } from './shared';

const MenuItemContentFormula = ({
  template,
  search,
}: {
  search: string;
  template: Template;
}) => {
  const [beforeFrom, afterFrom] = template.example.split(' from ');
  const [beforeIf, afterIf] = beforeFrom?.split(' if ') ?? [
    undefined,
    undefined,
  ];
  const [resolvedResult, resolvedOriginal] = [
    afterFrom ? beforeFrom : beforeIf,
    afterFrom || afterIf,
  ];
  const intl = useIntl();

  return (
    <Stack
      sx={{ maxWidth: '100%' }}
      data-cy={
        template.auroraSuggestion
          ? 'template-suggester-aurora-formula'
          : 'template-suggester-predefined-formula'
      }
    >
      <Typography variant="body2">
        {template.auroraSuggestion ? (
          template.title
        ) : (
          <HighlightMatches search={search} text={template.title} />
        )}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {template.auroraSuggestion ? (
          template.description
        ) : (
          <HighlightMatches search={search} text={template.description} />
        )}
      </Typography>
      <span
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {resolvedOriginal ? (
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body2" component="span" color="success.main">
              {resolvedResult}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              color="action.disabled"
            >
              {intl.formatMessage({
                id: afterFrom
                  ? 'features.queueSettings.fields.formulaEditor.suggester.templates.from'
                  : 'features.queueSettings.fields.formulaEditor.suggester.templates.if',
              })}
            </Typography>
            <Typography variant="body2" component="span" color="text.secondary">
              {resolvedOriginal}
            </Typography>
          </Stack>
        ) : (
          <Typography variant="body2" component="span" color="text.secondary">
            {resolvedResult}
          </Typography>
        )}
      </span>
    </Stack>
  );
};

export default MenuItemContentFormula;
