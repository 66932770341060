import { counts } from './counts';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
export { type EmailThreadsListQuery } from './list';

export const emailThreads = {
  counts,
  get,
  list,
  patch,
};
