import { Queue } from '@rossum/api-client/queues';
import { classes, DetailDrawer } from '@rossum/rossum-ui/DetailDrawer';
import {
  AddRounded,
  ArrowBackRounded,
  ViewColumnRounded,
} from '@rossum/ui/icons';
import {
  Button,
  Grow,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import { safeOrganizationSelector } from '../../../../redux/modules/organization/selectors';
import { isUserAdmin } from '../../../../redux/modules/user/selectors';
import { MetaColDef, SchemaColDef } from '../../filtering/types';
import { getAnnotationListColumns, getColumnName } from '../helpers';
import { useTableConfig } from '../hooks/useTableConfig';
import { AddColumns } from './AddColumns';
import { ColumnList } from './ColumnList';
import { PanelColumn } from './helpers';
import { useCustomizeColumns } from './hooks/useCustomizeColumns';

type Props = {
  columns: Array<MetaColDef | SchemaColDef>;
  selectionActive: boolean;
  activeQueue: Queue | null;
};

type Views = 'columnList' | 'addColumns';
const defaultView: Views = 'columnList';

export const ColumnsPanel = ({
  columns,
  selectionActive,
  activeQueue,
}: Props) => {
  const intl = useIntl();
  const [view, setView] = useState<Views>(defaultView);
  const [isOpen, setIsOpen] = useState(false);
  const isUserAdminLike = useSelector(isUserAdmin);
  const orgId = useSelector(safeOrganizationSelector)?.id;

  const { tableConfig } = useTableConfig({
    queueId: activeQueue?.id ?? 0,
    orgId: orgId ?? 0,
  });

  const panelColumns = useMemo<PanelColumn[]>(() => {
    // if nothing is saved in database we use existing table columns, which can change when user changes status tabs.
    if (tableConfig.columns.length === 0)
      return getAnnotationListColumns(columns).map(column => {
        const headerName = columns.find(
          c => getColumnName(c) === getColumnName(column)
        )?.headerName;
        return {
          ...column,
          headerName,
        };
      });

    return tableConfig.columns.map(savedColumn => {
      const matchingColumn = columns.find(
        c => getColumnName(savedColumn) === getColumnName(c)
      );

      return {
        ...savedColumn,
        headerName: matchingColumn?.headerName,
        // when user changes status tab, columns and tableConfig.columns are likely not to match
        isColumnFilteredOut: !matchingColumn,
      };
    });
  }, [columns, tableConfig.columns]);

  const { handleAddColumn, handleRemoveColumn } = useCustomizeColumns({
    columns: panelColumns,
    activeQueue,
  });

  const handleClose = () => {
    setIsOpen(false);
    setView(defaultView);
  };

  return isUserAdminLike ? (
    <Stack>
      <IconButton
        color="secondary"
        disabled={selectionActive}
        onClick={() => setIsOpen(true)}
      >
        <ViewColumnRounded />
      </IconButton>

      <DetailDrawer
        open={isOpen}
        onClose={handleClose}
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              onClick={() => setView('columnList')}
              sx={{
                visibility: view === 'addColumns' ? 'visible' : 'hidden',
              }}
            >
              <ArrowBackRounded />
            </IconButton>

            <Typography variant="h5">
              {intl.formatMessage({
                id:
                  view === 'columnList'
                    ? 'containers.allDocuments.columnsPanel.title.listView'
                    : 'containers.allDocuments.columnsPanel.title.addView',
              })}
            </Typography>
          </Stack>
        }
        PaperProps={{
          sx: { width: 450 },
        }}
        sx={{
          [`& .${classes.body}`]: { height: 1 },
        }}
      >
        <Stack px={3} height={1}>
          <SwitchTransition>
            <Grow key={view} appear unmountOnExit timeout={200}>
              <Stack overflow="hidden" flex={1}>
                {view === 'columnList' ? (
                  <Stack pb={4} overflow="hidden">
                    <Button
                      startIcon={<AddRounded />}
                      color="secondary"
                      sx={{ width: 'fit-content', mt: 3, mb: 2, mx: 'auto' }}
                      onClick={() => setView('addColumns')}
                    >
                      {intl.formatMessage({
                        id: 'containers.allDocuments.columnsPanel.button.add',
                      })}
                    </Button>

                    <ColumnList
                      columns={panelColumns}
                      activeQueue={activeQueue}
                      handleRemoveColumn={handleRemoveColumn}
                    />
                  </Stack>
                ) : (
                  <AddColumns
                    activeQueue={activeQueue}
                    handleAddColumn={handleAddColumn}
                    schemaColumns={panelColumns.flatMap(c =>
                      c.columnType === 'schema' ? [c] : []
                    )}
                    handleRemoveColumn={handleRemoveColumn}
                  />
                )}
              </Stack>
            </Grow>
          </SwitchTransition>
        </Stack>
      </DetailDrawer>
    </Stack>
  ) : null;
};
