import { ID, Url } from '@rossum/api-client';
import { EmailThread } from '@rossum/api-client/emailThreads';
import { LABEL_REJECTED } from '../../../types/email';

export type TransformedEmailThread = {
  id: ID;
  url: Url;
  queueUrl: Url;
  subject: string | null;
  from__email: string;
  created_at: string;
  last_email_created_at: string;
  counts: {
    annotations: number;
    annotationsPurged: number;
  };
  meta: {
    hasReplies: boolean;
    hasNewReplies: boolean;
    rejected: boolean;
    rootEmailRead: boolean;
  };
};

export const transformEmailThreads = (
  emailThreads: EmailThread[]
): TransformedEmailThread[] => {
  return emailThreads.map(emailThread => {
    const threadLabels = emailThread.labels;
    const rejected = !!threadLabels?.includes(LABEL_REJECTED);

    return {
      id: emailThread.id,
      url: emailThread.url,
      queueUrl: emailThread.queue,
      subject: emailThread.subject,
      from__email: emailThread.from?.email || '',
      created_at: emailThread.createdAt || '',
      last_email_created_at: emailThread.lastEmailCreatedAt || '',
      counts: {
        annotations: emailThread.annotationCounts.annotations,
        annotationsPurged: emailThread.annotationCounts.annotationsPurged ?? 0,
      },
      meta: {
        hasReplies: emailThread.hasReplies,
        hasNewReplies: emailThread.hasNewReplies,
        rejected,
        rootEmailRead: emailThread.rootEmailRead,
      },
    };
  });
};
