import { ExtensionOutlined as ExtensionOutlinedIcon } from '@rossum/ui/icons';
import { FitScreen as FitScreenIcon } from '@rossum/ui/icons';
import { GridOn as GridOnIcon } from '@rossum/ui/icons';
import { List as ListIcon } from '@rossum/ui/icons';
import { LooksOneOutlined as LooksOneOutlinedIcon } from '@rossum/ui/icons';
import { ShortText as ShortTextIcon } from '@rossum/ui/icons';
import { SxProps, Theme } from '@rossum/ui/material';
import { Formula } from '../../../../components/icons/Formula';
import SectionIcon from './SectionIcon';

type ValueIconProps = { value: string };

export const ValueIcon = ({ value }: ValueIconProps) => {
  const sx: SxProps<Theme> = {
    verticalAlign: 'middle',
    color: theme => theme.palette.text.secondary,
  };

  switch (value) {
    case 'Single value':
      return <LooksOneOutlinedIcon sx={sx} />;
    case 'Multivalue or Line item':
      return <ListIcon sx={sx} />;

    case 'Line item':
      return <GridOnIcon sx={sx} />;

    case 'Section':
      return <SectionIcon sx={sx} />;

    case 'Captured':
      return <FitScreenIcon sx={sx} />;
    case 'Formula':
      return <Formula sx={sx} />;

    case 'Data':
      return <ExtensionOutlinedIcon sx={sx} />;

    case 'Manual':
      return <ShortTextIcon sx={sx} />;
    default:
      return null;
  }
};
