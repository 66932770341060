import { UniqueIdentifier } from '@dnd-kit/core';
import { getEtag, WithEtag } from '@rossum/api-client';
import { isElisClientError } from '@rossum/api-client/errors';
import { Schema } from '@rossum/api-client/schemas';
import { DeleteOutline } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import { Location } from 'history';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, RouteChildrenProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import * as R from 'remeda';
import { v4 as uuidv4 } from 'uuid';
import { usePatchSchema } from '../../../../business/schema/usePatchSchema';
import {
  throwError,
  throwInfo,
} from '../../../../redux/modules/messages/actions';
import { formulaFieldsEnabledSelector } from '../../../../redux/modules/organizationGroup/selectors';
import { userSelector } from '../../../../redux/modules/user/selectors';
import { useQueueSettingsContext } from '../../contexts/QueueSettingsContext';
import { ItemContent, ItemContentProps } from '../components/ItemContent';
import {
  SectionContent,
  SectionContentProps,
} from '../components/SectionContent';
import { SectionsReorderingProps } from '../components/SectionsReordering';
import { UpdateIndicator } from '../components/UpdateIndicator';
import {
  DELETE_FIELD_FORM_ID,
  FIELD_FORM_ID,
  RELEASE_DATE_OF_NEW_FIELDS_TYPE,
} from '../constants';
import { toSchemaPatchPayload } from '../form-model/transformations/toSchema';
import { SchemaActionHandler } from '../form-model/transformations/toSchema.utils';
import { replaceUrlSegment } from '../helpers';
import { useResolveSchemaField } from '../hooks/useResolveSchemaField';
import { FieldRouteParams } from '../types';

type FieldPageProps = RouteChildrenProps<FieldRouteParams> & {
  schema: WithEtag<Schema>;
};

// replace the last non-undefined value with fieldId
const redirectToRouteParams = (
  params: FieldRouteParams,
  fieldId: string
): FieldRouteParams =>
  R.pipe(
    params,
    R.entries.strict(),
    entries =>
      R.map.strict.indexed(entries, ([key, value], index) =>
        value !== undefined && entries[index + 1]?.[1] === undefined
          ? ([key, fieldId] as const)
          : ([key, value] as const)
      ),
    val => val,
    R.fromEntries.strict()
  ) as FieldRouteParams;

export const FieldPage = ({ match, schema }: FieldPageProps) => {
  const intl = useIntl();

  const { setHeaderMeta } = useQueueSettingsContext();

  const user = useSelector(userSelector);
  const formulaFieldsEnabled = useSelector(formulaFieldsEnabledSelector);

  const { path, field: currentField } = useResolveSchemaField({
    schema,
    fieldsRoute: match?.params ?? {
      sectionId: '',
    },
  });

  const history = useHistory();

  const newField = !currentField && !!path;

  const key = useRef('initial-key');

  // TODO: Can we get this a little more clearly?
  // is it true now that `parentId` is exactly the segment of `match.params` that is 2 indexes above the first `undefined` occurence?
  // (assuming that if all three are defined, there is a "fourth undefined")
  // could be more transparent
  const parentId = path
    ? newField
      ? path.at(-1)?.id ?? null
      : path.at(-2)?.id ?? null
    : null;

  const { mutate: patchSchema, isLoading: schemaIsPatching } = usePatchSchema({
    etag: getEtag(schema),
  });

  const dispatch = useDispatch();

  const executeSchemaAction: SchemaActionHandler = useCallback(
    options => {
      const payload = toSchemaPatchPayload(schema, options);
      if (payload) {
        patchSchema(
          { id: payload.id, payload },
          {
            onSuccess: () => {
              dispatch(throwInfo('schemaUpdated'));
              if (match) {
                // when deleting, always redirect
                if (options.op === 'delete') {
                  // when on LI child, go to the parent
                  if (match.params.schemaItemChildId) {
                    history.replace(
                      generatePath(match.path, {
                        ...match.params,
                        schemaItemChildId: undefined,
                      })
                    );

                    return;
                  }

                  // otherwise go to fields route root
                  history.replace(
                    generatePath(match?.path ?? '', {
                      ...(match?.params ?? {}),
                      sectionId: undefined,
                      schemaItemId: undefined,
                      schemaItemChildId: undefined,
                    })
                  );

                  return;
                }

                // if adding or editing, always* redirect
                if (options.op === 'add' || options.op === 'edit') {
                  // after successful submit, we change key which FORCES forms to remount
                  // this their _dirty_ state and `skipPrompt` is reset
                  const toPath = generatePath(
                    match.path,
                    redirectToRouteParams(
                      match.params,
                      options.formModel.field.id
                    )
                  );

                  history.replace(toPath);
                  key.current = uuidv4();
                }
              }
            },
            onError: error => {
              if (isElisClientError(error)) {
                dispatch(throwError('schemaUpdateFailed'));
              } else {
                dispatch(throwError('clientError'));
              }
            },
          }
        );
      }
    },
    [dispatch, history, match, patchSchema, schema]
  );

  // TODO: These could be unified, but since they are going to two different components
  // I kept them separate for now
  const handleSectionSubmit: SectionContentProps['onSubmit'] = useCallback(
    formModel => {
      if (!currentField) {
        executeSchemaAction({
          op: 'add',
          parentId,
          formModel,
        });
      } else {
        executeSchemaAction({
          op: 'edit',
          id: currentField.id,
          formModel,
        });
      }
    },
    [currentField, executeSchemaAction, parentId]
  );

  const handleSectionDelete: SectionContentProps['onDelete'] = useCallback(
    sectionId => {
      executeSchemaAction({
        op: 'delete',
        id: sectionId,
      });
    },
    [executeSchemaAction]
  );

  const handleItemSubmit: ItemContentProps['onSubmit'] = useCallback(
    formModel => {
      if (!currentField) {
        executeSchemaAction({
          op: 'add',
          parentId,
          formModel,
        });
      } else {
        executeSchemaAction({
          op: 'edit',
          id: currentField.id,
          formModel,
        });
      }
    },
    [currentField, executeSchemaAction, parentId]
  );

  const handleItemDelete: ItemContentProps['onDelete'] = useCallback(
    itemId => {
      executeSchemaAction({
        op: 'delete',
        id: itemId,
      });
    },
    [executeSchemaAction]
  );

  const handleSectionsReorder: SectionsReorderingProps['onSectionsReorder'] =
    useCallback(
      (from, to) => {
        executeSchemaAction({
          op: 'move',
          from,
          to,
        });
      },
      [executeSchemaAction]
    );

  const handleChildrenReorder: ItemContentProps['onChildrenReorder'] =
    useCallback(
      (from, to) => {
        executeSchemaAction({
          op: 'move',
          from,
          to,
        });
      },
      [executeSchemaAction]
    );

  const handleQuickAction = useCallback(
    (
      parentId: UniqueIdentifier,
      items: Record<
        UniqueIdentifier,
        {
          prop: 'hidden' | 'canExport' | 'required';
          value: boolean;
        }
      >
    ) => {
      executeSchemaAction({
        op: 'quick-action',
        parentId,
        items,
      });
    },
    [executeSchemaAction]
  );

  useEffect(() => {
    setHeaderMeta(prevState => ({
      ...prevState,
      title: currentField?.label ?? '',
      description: currentField?.description ?? '',
      buttons: [
        newField ? null : (
          <Button
            key="json-schema"
            component={Link}
            to={(location: Location) => {
              const searchQuery = currentField?.id
                ? `id=${currentField.id}`
                : '';

              const allSegments = location.pathname.split('/');
              const fieldsIndex = allSegments.indexOf('fields');

              const segmentsToDrop = allSegments.slice(fieldsIndex).length;

              return {
                pathname: `/${replaceUrlSegment(
                  location.pathname ?? '',
                  `schema`,
                  segmentsToDrop
                )}`,
                state: { backLink: location.pathname },
                search: searchQuery,
              };
            }}
            variant="outlined"
            color="secondary"
            sx={{
              '&:hover': {
                color: theme => theme.palette.secondary.main,
                textDecoration: 'none',
              },
            }}
          >
            {intl.formatMessage({
              id: 'containers.settings.queues.queue.editSchema',
            })}
          </Button>
        ),
        newField ? null : (
          <Button
            key="remove-field"
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutline />}
            type="submit"
            form={DELETE_FIELD_FORM_ID}
          >
            {intl.formatMessage({
              id: 'features.queueSettings.actions.delete',
            })}
          </Button>
        ),
        <Button
          key="submit-field-form"
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
        >
          {intl.formatMessage({
            id: 'features.queueSettings.actions.save',
          })}
        </Button>,
      ],
    }));
  }, [currentField, intl, newField, setHeaderMeta]);

  const dateJoined = user.dateJoined ? new Date(user.dateJoined) : new Date();
  const releaseDate = new Date(RELEASE_DATE_OF_NEW_FIELDS_TYPE);
  const supportLegacyUser = dateJoined < releaseDate;

  if (currentField || newField) {
    const isSection =
      currentField?.category === 'section' || (newField && path?.length === 0);

    return (
      <>
        {schemaIsPatching ? <UpdateIndicator /> : null}
        {isSection ? (
          <SectionContent
            key={key.current}
            schema={schema}
            data={currentField}
            sections={schema.content ?? []}
            onSubmit={handleSectionSubmit}
            onDelete={handleSectionDelete}
            onSectionsReorder={handleSectionsReorder}
          />
        ) : parentId ? (
          <ItemContent
            key={key.current}
            data={currentField}
            onSubmit={handleItemSubmit}
            onDelete={handleItemDelete}
            onChildrenReorder={handleChildrenReorder}
            onQuickAction={handleQuickAction}
            schema={schema}
            parentId={parentId}
            supportLegacyUser={supportLegacyUser}
            formulaFieldsEnabled={formulaFieldsEnabled}
          />
        ) : null}
      </>
    );
  }

  return null;
};
