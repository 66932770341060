import { zodResolver } from '@hookform/resolvers/zod';
import { getIDFromUrl } from '@rossum/api-client';
import { EngineField } from '@rossum/api-client/engineFields';
import { Engine } from '@rossum/api-client/engines';
import { HttpError } from '@rossum/api-client/errors';
import { Stack } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { mapValues } from 'remeda';
import { z } from 'zod';
import { FieldForm } from '../components/FieldForm';
import { useCreateEngineField } from '../hooks/useCreateEngineField';
import { usePatchEngineField } from '../hooks/usePatchEngineField';
import { types } from '../hooks/useTypesAndSubtypes';
import { engineFieldDetailPath } from '../paths';

export const FIELD_FORM_ID = 'engine_field_detail_form';

type FieldFormType = z.TypeOf<ReturnType<typeof fieldFormSchema>>;

export const useFieldForm = ({
  error,
  defaultValues,
}: {
  error: unknown;
  defaultValues: FieldFormType['field'];
}) => {
  const backendErrors = mapValues(resolveErrorPayload(error), apiError =>
    apiError ? { type: 'api_error', message: apiError[0] } : undefined
  );

  const intl = useIntl();
  return useForm<FieldFormType>({
    errors: {
      // TODO: Translate some common errors / add some F/E validation.
      field: backendErrors,
    },
    defaultValues: {
      field: defaultValues,
    },
    resolver: zodResolver(fieldFormSchema(intl)),
  });
};

const resolveErrorPayload = (error: unknown) => {
  if (error instanceof HttpError) {
    const errorSchema = z
      .object({
        label: z.array(z.string()),
        name: z.array(z.string()),
        uptrainFrom: z.array(z.string()),
        type: z.array(z.string()),
        subtype: z.array(z.string()),
        tabular: z.array(z.string()),
      })
      .partial();

    return errorSchema.catch({}).parse(error.data);
  }

  return {};
};

export const fieldFormSchema = (intl: IntlShape) =>
  z.object({
    field: z.object({
      label: z.string().min(
        1,
        intl.formatMessage({
          id: 'features.engines.error.form.fieldRequired',
        })
      ),
      name: z.string().min(
        1,
        intl.formatMessage({
          id: 'features.engines.error.form.fieldRequired',
        })
      ),
      uptrainFrom: z.string().nullable(),
      type: z.enum(types),
      subtype: z.string(),
      tabular: z.boolean(),
      multiline: z.string(),
    }),
  });

export const FieldAddPage = ({ engine }: { engine: Engine }) => {
  const { mutate, error } = useCreateEngineField(engine.url);

  const history = useHistory();
  const { control, handleSubmit } = useFieldForm({
    error,
    defaultValues: {
      label: '',
      name: '',
      uptrainFrom: null,
      type: 'string',
      subtype: '',
      tabular: false,
      multiline: 'false',
    },
  });

  return (
    <Stack
      px={4}
      py={4}
      id={FIELD_FORM_ID}
      component="form"
      onSubmit={handleSubmit(({ field }) => {
        mutate(field, {
          onSuccess: field =>
            history.push(
              engineFieldDetailPath(getIDFromUrl(field.engine), field.id)
            ),
        });
      })}
    >
      <FieldForm control={control} />
    </Stack>
  );
};

export const FieldDetailPage = ({ field }: { field: EngineField }) => {
  const { mutate, error } = usePatchEngineField(field.id);
  const { control, handleSubmit } = useFieldForm({
    error,
    defaultValues: {
      ...field,
      subtype: field.subtype ?? '',
    },
  });

  return (
    <Stack
      px={4}
      py={4}
      id={FIELD_FORM_ID}
      component="form"
      onSubmit={handleSubmit(({ field }) => {
        mutate(field);
      })}
    >
      <FieldForm control={control} disableName />
    </Stack>
  );
};
