import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Add } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMatchingUrl } from '../../../../constants/config';
import { boldText, white } from '../../../../lib/formaterValues';
import {
  displayDataMatchingBannerSelector,
  isDataMatchingEnabledSelector,
  isTrialSelector,
} from '../../../../redux/modules/organization/selectors';
import {
  clearValidationErrors as clearValidationErrorsAction,
  UpdateSchemaContent,
  updateSchemaContent,
} from '../../../../redux/modules/schema/actions';
import { originalSchemaSelector } from '../../../../redux/modules/schema/selectors';
import { removeIndexInArray } from '../../../../redux/modules/utils';
import { OriginalAnyDatapointSchema } from '../../../../types/schema';
import { State } from '../../../../types/state';
import { useOpenModal } from '../../../../utils/hooks/useOpenModal';
import CustomFields from './components/CustomFields';
import DataMatching from './components/DataMatching';
import Field from './components/Field';
import SchemaEditorDescription from './components/SchemaEditorDescription';
import { SchemaEditorDrawer } from './components/SchemaEditorDrawer';
import SectionWithDraggableFields from './components/SectionWithDraggableFields';
import {
  getDropResult,
  propagatePropertiesToChildren,
  recalculateHidden,
  reorderSchemaAndRecalculateHidden,
} from './helpers';
import styles from './style.module.sass';

type DispatchProps = {
  updateSchema: UpdateSchemaContent;
  clearValidationErrors: () => void;
};

type StateProps = {
  dataMatchingEnabled: boolean;
  schema: Array<OriginalAnyDatapointSchema>;
  shouldDisplayBanner: boolean;
  isTrial: boolean | undefined;
};

type Props = StateProps & DispatchProps;

const Fields = ({
  clearValidationErrors,
  dataMatchingEnabled,
  schema,
  shouldDisplayBanner,
  isTrial,
  updateSchema,
}: Props) => {
  const history = useHistory();
  const { pathname, state: locationState } = useLocation<{
    backLink?: string;
    openFieldOnPath?: string[];
  }>();
  const intl = useIntl();
  const [editPath, setEditPath] = useState<string[] | undefined>();
  const [schemaConcept, setSchemaConcept] = useState(schema);
  const [draggedByKeyboard, setDraggedByKeyboard] = useState(false);
  const [draggedField, setDraggedField] =
    useState<OriginalAnyDatapointSchema | null>(null);

  // open drawer with field to edit on path
  const fieldToOpen = locationState?.openFieldOnPath;
  useEffect(() => {
    // we must wait for the schema to be loaded in redux
    if (fieldToOpen && schemaConcept.length) {
      setEditPath(fieldToOpen);
      // clear openFieldOnPath from location state but keep the rest
      history.replace(pathname, {
        ...locationState,
        openFieldOnPath: undefined,
      });
    }
  }, [fieldToOpen, history, locationState, pathname, schemaConcept.length]);

  const [ModalDialog, openModal] = useOpenModal();

  useEffect(() => setSchemaConcept(schema), [schema, setSchemaConcept]);
  useEffect(() => clearValidationErrors(), [clearValidationErrors, editPath]);

  const toggleSchemaDatapoint = (path: Array<string>) => (index: number) => {
    return updateSchema(
      schemaConcept
        // @ts-expect-error
        .updateIn(
          [...path, index.toString()],
          (datapoint: OriginalAnyDatapointSchema) =>
            propagatePropertiesToChildren(datapoint, {
              hidden: !datapoint.hidden ? true : undefined,
            })
        )
        .map((dtp: OriginalAnyDatapointSchema) => recalculateHidden(dtp))
    );
  };

  const onDelete = (path: string[]) => (index: number, label: string) => {
    openModal({
      textId: 'deleteField',
      onConfirm: () => {
        updateSchema(
          // @ts-expect-error
          schemaConcept.updateIn(path, children =>
            removeIndexInArray(children, index)
          )
        );
      },
      values: { label, white, boldText },
    });
  };

  const onDeleteSection = (sectionIndex: number) => {
    const sectionLabel = schemaConcept[sectionIndex]?.label;

    openModal({
      textId: 'deleteSection',
      onConfirm: () => {
        const newSchemaWithoutSection = removeIndexInArray(
          schemaConcept,
          sectionIndex
        );

        updateSchema(newSchemaWithoutSection);
      },
      values: { sectionLabel, white, boldText },
    });
  };

  const addNewField = (index: number) => {
    // @ts-expect-error
    const newFieldIndexInSection = schemaConcept.getIn([
      String(index),
      'children',
    ]).length;

    setEditPath([String(index), 'children', newFieldIndexInSection]);
  };

  const getSectionFields = (containerId: string) => {
    return schemaConcept.find(schema => schema.id === containerId)
      ?.children as OriginalAnyDatapointSchema[];
  };

  const handleDragStart = (e: DragStartEvent) => {
    const { active } = e;
    if (!active.data.current) return;

    const activeId = active.data.current.sortable.index;
    const activeSectionFields = getSectionFields(
      active.data.current.sortable.containerId
    );

    if (isNumber(activeId) && activeSectionFields) {
      setDraggedField(activeSectionFields[activeId]);
    }
  };

  const handleDragEnd = ({ over }: DragEndEvent) => {
    if (!over) {
      return;
    }

    updateSchema(schemaConcept);
    setDraggedField(null);

    if (draggedByKeyboard) {
      setDraggedByKeyboard(false);
    }
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const overSection = over.data.current?.sortable.containerId;
    const activeSeciton = active.data.current?.sortable.containerId;

    if (overSection !== activeSeciton) {
      const sectionFieldsLenght = getSectionFields(overSection)?.length;
      const overIndex = parseInt(over.data.current?.sortable.index, 10);
      const isLastInNewSection =
        sectionFieldsLenght > 0 && sectionFieldsLenght - 1 === overIndex;

      setSchemaConcept(
        reorderSchemaAndRecalculateHidden(
          schemaConcept,
          getDropResult(active, over, isLastInNewSection)
        )
      );

      return;
    }

    setSchemaConcept(
      reorderSchemaAndRecalculateHidden(
        schemaConcept,
        getDropResult(active, over)
      )
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      onActivation: () => setDraggedByKeyboard(true),
    })
  );

  if (!schemaConcept) return null;

  return (
    <div className={styles.SchemaEditor} data-page-title="queue-fields">
      <SchemaEditorDescription />
      <SchemaEditorDrawer
        editPath={editPath}
        setEditPath={setEditPath}
        schemaConcept={schemaConcept}
      />
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragCancel={handleDragEnd}
        modifiers={[restrictToWindowEdges]}
      >
        <div className={styles.SchemaDatapointsWrapper}>
          {schemaConcept.map((section, i) => (
            <SectionWithDraggableFields
              key={section.id}
              section={section}
              onDelete={onDelete([String(i), 'children'])}
              onToggle={toggleSchemaDatapoint([String(i), 'children'])}
              onClick={(index: number) =>
                setEditPath([String(i), 'children', index.toString()])
              }
              onEdit={() => setEditPath([String(i)])}
              onDeleteSection={() => onDeleteSection(i)}
              footer={
                <div className={styles.FooterButtonWrapper}>
                  <Button
                    color="secondary"
                    startIcon={<Add />}
                    onClick={() => addNewField(i)}
                    data-cy="add-new-field"
                  >
                    {intl.formatMessage({
                      id: 'containers.settings.fields.add',
                    })}
                  </Button>
                </div>
              }
            />
          ))}
          {draggedField && (
            <DragOverlay zIndex={10}>
              <Field
                datapoint={draggedField}
                indexInSection={0}
                isOverlay
                isDraggingByKeyboard={draggedByKeyboard}
              />
            </DragOverlay>
          )}
          <div className={styles.AddSectionButtonWrapper}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => {
                const newSectionIndex = schemaConcept.length;
                setEditPath([String(newSectionIndex)]);
              }}
              data-cy="add-new-section"
            >
              {intl.formatMessage({
                id: 'containers.settings.queueFields.section.add',
              })}
            </Button>
          </div>
          {isTrial && <CustomFields />}
          <DataMatching
            isEnabled={dataMatchingEnabled && !!dataMatchingUrl}
            displayBanner={shouldDisplayBanner}
          />
          {ModalDialog}
        </div>
      </DndContext>
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  dataMatchingEnabled: isDataMatchingEnabledSelector(state),
  // Not diving into this. The type with 'Any' is basically the same, just weaker
  // let's leave this be for now and then refactor the shit out of it
  schema: originalSchemaSelector(state) as OriginalAnyDatapointSchema[],
  shouldDisplayBanner: displayDataMatchingBannerSelector(state),
  isTrial: isTrialSelector(state),
});

const mapDispatchToProps = {
  clearValidationErrors: clearValidationErrorsAction,
  updateSchema: updateSchemaContent as UpdateSchemaContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
