import FolderOutlineIcon from 'mdi-react/FolderOutlineIcon';
import { FormattedMessage } from 'react-intl';
import InfoPlaceholder from '../../../components/UI/InfoPlaceHolder';
import styles from '../style.module.sass';

export const NoWorkspacesPlaceholder = () => (
  <div className={styles.NoWorkspacesPlaceholder}>
    <InfoPlaceholder
      icon={<FolderOutlineIcon />}
      title="containers.settings.queues.noWorkspaces.title"
    />
    <FormattedMessage id="containers.settings.queues.noWorkspaces.text" />
  </div>
);
