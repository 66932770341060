import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormLabel, Stack } from '@rossum/ui/material';
import { get, invoke } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useChangePassword } from '../../../business/user/useChangePassword';
import { usePasswordValidation } from '../../../business/user/usePasswordValidation';
import PasswordValidationField from '../../../components/PasswordValidationField';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { userSelector } from '../../../redux/modules/user/selectors';
import useDebounce from '../../../utils/hooks/useDebounce';
import FormRow, { FORM_FIELD_WIDTH } from '../components/FormRow';
import styles from '../style.module.sass';

type Props = {
  username: string;
};

const ChangePasswordForm = ({ username }: Props) => {
  const [score, setScore] = useState(0);
  const oldPasswordInput = useRef(null);
  const intl = useIntl();
  const user = useSelector(userSelector);

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const changePasswordValidationFormSchema = yup.object().shape({
    oldPassword: yup.string().required(
      intl.formatMessage({
        id: 'containers.personalInfo.oldPassword.error',
      })
    ),
    newPassword: yup
      .string()
      .test(
        '$score',
        '',
        (_, scoreContext) => scoreContext?.options?.context?.score > 2
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        intl.formatMessage({ id: 'components.user.resetPassword.errors.match' })
      )
      .required(
        intl.formatMessage({
          id: 'containers.personalInfo.confirmPassword.error',
        })
      ),
  });

  const { handleSubmit, control, setValue, watch, setError, reset } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(changePasswordValidationFormSchema),
    context: {
      score,
    },
  });

  const newPassword = watch('newPassword');
  const debounceNewPassword = useDebounce(newPassword, 100);

  const { refetch, data } = usePasswordValidation({
    password: debounceNewPassword,
    email: user?.email ?? user?.username,
  });

  const { mutate: updateUserPassword, error } = useChangePassword();

  useEffect(() => {
    refetch();
  }, [debounceNewPassword, refetch]);

  useEffect(() => {
    if (data?.score) {
      setScore(data.score);
    }
  }, [data?.score, setScore]);

  useEffect(() => {
    const oldPasswordError = get(error, ['data', 'old_password']);
    if (oldPasswordError) {
      setError('oldPassword', {
        message: intl.formatMessage({
          id: 'containers.personalInfo.oldPassword.invalid',
        }),
      });
      setValue('oldPassword', '');
      invoke(oldPasswordInput, ['current', 'focus']);
    }
  }, [setValue, setError, intl, error]);

  return (
    <form
      onSubmit={handleSubmit(({ oldPassword, newPassword, confirmPassword }) =>
        updateUserPassword(
          {
            new_password1: newPassword,
            new_password2: confirmPassword,
            old_password: oldPassword,
            username,
          },
          {
            onSuccess: () => reset(),
          }
        )
      )}
    >
      <input type="text" className={styles.Hidden} disabled value={username} />
      <Stack spacing={2} justifyContent="center" width={455}>
        <FormRow>
          <FormLabel>
            {intl.formatMessage({
              id: 'containers.personalInfo.oldPassword.label',
            })}
          </FormLabel>
          <TextFieldControl
            type="password"
            autoComplete="off"
            FieldLabelProps={{ layout: 'none' }}
            ControllerProps={{ control, name: 'oldPassword' }}
            placeholder={intl.formatMessage({
              id: 'containers.personalInfo.oldPassword.placeholder',
            })}
            inputProps={{
              'data-cy': 'my-profile-current-password-input',
            }}
          />
        </FormRow>
        <Stack>
          <FormRow>
            <FormLabel>
              {intl.formatMessage({
                id: 'containers.personalInfo.newPassword.label',
              })}
            </FormLabel>
            <PasswordValidationField
              data={data}
              ControllerProps={{ control, name: 'newPassword' }}
            />
          </FormRow>
          <Stack sx={{ mt: 2 }}>
            <FormRow>
              <FormLabel>
                {intl.formatMessage({
                  id: 'containers.personalInfo.confirmPassword.label',
                })}
              </FormLabel>
              <TextFieldControl
                type="password"
                FieldLabelProps={{ layout: 'none' }}
                ControllerProps={{ control, name: 'confirmPassword' }}
                autoComplete="new-password"
                placeholder={intl.formatMessage({
                  id: 'containers.personalInfo.confirmPassword.placeholder',
                })}
                inputProps={{
                  'data-cy': 'my-profile-confirm-password-input',
                }}
              />
            </FormRow>
          </Stack>
        </Stack>
        <Stack
          width={FORM_FIELD_WIDTH}
          alignSelf="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            type="submit"
            sx={{ alignSelf: 'center' }}
            data-cy="my-profile-update-password"
          >
            {intl.formatMessage({
              id: 'containers.personalInfo.changePassword.button',
            })}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ChangePasswordForm;
