import { useIsMutating } from '@tanstack/react-query';
import { createEngineKey } from './useCreateEngine';
import { createEngineFieldKey } from './useCreateEngineField';
import { useEngine } from './useEngine';
import { useEngineField } from './useEngineField';
import { useEngineFields } from './useEngineFields';
import { patchEngineKey } from './usePatchEngine';
import { patchEngineFieldKey } from './usePatchEngineField';
import { useRouteParams } from './useRouteParams';

type RouteArgument = 'new' | number | undefined;

const convertToNumber = (routeArgument: RouteArgument) =>
  typeof routeArgument === 'number' ? routeArgument : 0;

export const useRouteResources = () => {
  const { engineId, fieldId } = useRouteParams();
  const engineQuery = useEngine(convertToNumber(engineId));
  const fieldQuery = useEngineField(convertToNumber(fieldId));

  const { data: unusedData } = useEngineFields(
    {
      id: [convertToNumber(fieldId)],
      used: false,
      pageSize: 1,
    },
    { enabled: convertToNumber(fieldId) > 0 }
  );

  const patchingEngineMutations = useIsMutating({
    mutationKey: patchEngineKey(convertToNumber(engineId)),
  });

  const creatingEngineMutations = useIsMutating({
    mutationKey: createEngineKey(),
  });

  const patchingFieldMutations = useIsMutating({
    mutationKey: patchEngineFieldKey(convertToNumber(fieldId)),
  });

  const creatingFieldMutations = useIsMutating({
    mutationKey: createEngineFieldKey(),
  });

  return {
    engine: {
      ...engineQuery,
      isCreating: engineId === 'new',
      isSaving: patchingEngineMutations > 0 || creatingEngineMutations > 0,
    },
    field: {
      ...fieldQuery,
      isCreating: fieldId === 'new',
      isSaving: patchingFieldMutations > 0 || creatingFieldMutations > 0,
      isUnused: unusedData && unusedData.pagination.total > 0,
    },
  };
};
