import { ChevronRight } from '@rossum/ui/icons';
import { Breadcrumbs } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useEngineBreadcrumbs } from '../../features/engines/useEngineBreadcrumbs';
import { Breadcrumb } from '../../ui/breadcrumb/Breadcrumb';
import { automationEnginesPath, automationPath } from './helpers';

type AutomationBreadcrumbsProps = {
  pageKey: 'aiEngines' | 'automationSettings';
};

export const AutomationBreadcrumbs = ({
  pageKey,
}: AutomationBreadcrumbsProps) => {
  const intl = useIntl();

  const engineBreadcrumbs = useEngineBreadcrumbs();
  const pageBreadcrumbs = {
    automationSettings: (
      <Breadcrumb to={automationPath()}>
        {intl.formatMessage({
          id: 'components.appBar.menu.automation.automationSettings',
        })}
      </Breadcrumb>
    ),
    aiEngines: (
      <Breadcrumb to={automationEnginesPath()}>
        {intl.formatMessage({
          id: 'components.appBar.menu.automation.aiEngines',
        })}
      </Breadcrumb>
    ),
  };

  return (
    <Breadcrumbs separator={<ChevronRight fontSize="small" color="disabled" />}>
      {/* Up to the second level, breadcrumbs are handled on the container level */}
      <Breadcrumb to={automationPath()}>
        {intl.formatMessage({ id: 'components.appBar.automation' })}
      </Breadcrumb>
      {pageBreadcrumbs[pageKey]}
      {/* After that, we leave it to engines feature to handle breadcrumbs by themselves. */}
      {...pageKey === 'aiEngines' ? engineBreadcrumbs : []}
    </Breadcrumbs>
  );
};
