import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Queue } from '../models';
import { queueSchema } from '../models/queue.schema';
import {
  createQueueFromTemplatePayloadSchema,
  createQueueFromTemplateQuerySchema,
} from './createFromTemplate.schema';

export type CreateQueueFromTemplateQuery = {
  legacy?: boolean;
};

export type CreateQueueFromTemplatePayload = {
  name: string;
  workspace: Url;
  templateName: string;
  includeDocuments: boolean;
};

export const createFromTemplate = (
  payload: CreateQueueFromTemplatePayload,
  query: CreateQueueFromTemplateQuery = {}
) => {
  return {
    endpoint: `/queues/from_template`,
    method: 'POST',
    responseSchema: queueSchema,
    payloadSchema: createQueueFromTemplatePayloadSchema,
    payload,
    querySchema: createQueueFromTemplateQuerySchema,
    query,
  } as const;
};
