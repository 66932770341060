import { engineSchema } from '../models/engine.schema';
import { engineCreatePayloadSchema } from './create.schema';

export type EngineCreatePayload = {
  name: string;
  learningEnabled: boolean;
  description: string;
  // We are not allowed to create splitter engines.
  type: 'extractor';
};

export const create = (payload: EngineCreatePayload) => {
  return {
    endpoint: `/engines`,
    method: 'POST',
    responseSchema: engineSchema,
    payloadSchema: engineCreatePayloadSchema,
    payload,
  } as const;
};
