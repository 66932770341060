import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getAuthToken } from '../../../lib/token';

export const usePagePreview = (pageUrl: string | undefined) =>
  useQuery({
    queryKey: [pageUrl],

    queryFn: async () => {
      if (!pageUrl) {
        return null;
      }

      const response = await axios.get<Blob>(`${pageUrl}/preview`, {
        headers: { Authorization: `Token ${getAuthToken()}` },
        responseType: 'blob',
        method: 'GET',
      });
      return URL.createObjectURL(response.data);
    },

    staleTime: 1000 * 60 * 60 * 24,
  });
