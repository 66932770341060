import { createSelector } from 'reselect';
import { SurveyName } from '../../business/surveys/surveysUuidMap';
import { isEmbedded } from '../../constants/config';
import { isNotNullOrUndefined } from '../../lib/typeGuards';
import { annotationSelector } from '../../redux/modules/annotation/selectors';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { userSelector } from '../../redux/modules/user/selectors';

export const dataCaptureSurveyBlockersSelector = createSelector(
  annotationSelector,
  userSelector,
  isTrialSelector,
  complexLineItemsEnabledSelector,
  (currentAnnotation, currentUser, isTrial, complexLineItemsEnabled) => {
    const internalUser =
      currentUser.email && currentUser.email.endsWith('rossum.ai');

    const sampleDocument = currentAnnotation?.metadata?.sampleDocument;
    const embedded = isEmbedded();

    const commonBlockers = [
      // no survey in embedded mode
      embedded ? 'embedded' : undefined,
      // no survey for sample documents
      sampleDocument ? 'sample_document' : undefined,
      // do not show to internal users
      internalUser ? 'internal_user' : undefined,
    ].filter(isNotNullOrUndefined);

    return (surveyType: SurveyName) => {
      return [
        !isTrial && surveyType === 'dataCaptureSurvey'
          ? 'not_trial'
          : undefined,
        !complexLineItemsEnabled && surveyType === 'auroraForLineItemsSurvey'
          ? 'no_cli'
          : undefined,
        ...commonBlockers,
      ].filter(isNotNullOrUndefined);
    };
  }
);
