import { ActionType, createAction } from 'typesafe-actions';
import { Url } from '../../../types/basic';
import { EmailThread } from '../../../types/emailThread';

type UpdateEmailThreadMeta = { url: Url; shouldInvalidateQuery?: boolean };

export const updateEmailThread = createAction(
  'UPDATE_EMAIL_THREAD',
  (payload: Partial<EmailThread>, _: UpdateEmailThreadMeta) => payload,
  (_, meta) => meta
)<Partial<EmailThread>, UpdateEmailThreadMeta>();

export const updateEmailThreadFulfilled = createAction(
  'UPDATE_EMAIL_THREAD_FULFILLED',
  (payload: EmailThread) => payload
)<EmailThread>();

export const markThreadAsReadFulfilled = createAction(
  'MARK_THREAD_AS_READ_FULFILLED',
  (payload: EmailThread) => payload
)<EmailThread>();

export type EmailThreadsActions = ActionType<
  | typeof updateEmailThread
  | typeof updateEmailThreadFulfilled
  | typeof markThreadAsReadFulfilled
>;
