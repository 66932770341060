import { FilterCenterFocus as FilterCenterFocusIcon } from '@rossum/ui/icons';
import { MailOutline as MailOutlineIcon } from '@rossum/ui/icons';
import { PersonOutline as PersonOutlineIcon } from '@rossum/ui/icons';
import { Settings as SettingsIcon } from '@rossum/ui/icons';
import { AutomationIconBoxOutline } from '../../components/UI/AutomationIcon';

const items = [
  { name: 'settings', Icon: SettingsIcon },
  { name: 'fields', Icon: FilterCenterFocusIcon },
  { name: 'emails', Icon: MailOutlineIcon },
  { name: 'automation', Icon: AutomationIconBoxOutline },
  { name: 'access', Icon: PersonOutlineIcon },
] as const;

export const queueSidebarItems = {
  items,
  type: 'queue' as const,
};
