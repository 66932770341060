import { Survey } from '@rossum/api-client/surveys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@rossum/ui/material';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import { SmileyRating } from '../../../ui/smiley-rating/SmileyRating';
import { useAnswerDataCaptureSurvey } from '../hooks/useAnswerDataCaptureSurvey';

export type SatisfactionSurveyDialogProps = {
  survey: Survey;
  open: boolean;
};

export const SatisfactionSurveyDialog = ({
  survey,
  open,
}: SatisfactionSurveyDialogProps) => {
  const intl = useIntl();
  const { handleSkip, handleSubmit, increaseViewCount } =
    useAnswerDataCaptureSurvey({
      survey,
    });

  const hasUpdatedViewCount = useRef(false);

  // increment viewCount every time dialog is opened
  useEffect(() => {
    if (open && !hasUpdatedViewCount.current) {
      hasUpdatedViewCount.current = true;
      increaseViewCount().catch(() => {});
    }
    // we only want to update this if dialog changed open state to true
  }, [increaseViewCount, open]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 450,
        },
      }}
    >
      <DialogTitle
        title={intl.formatMessage({
          id: 'components.surveys.dataCaptureSurvey.questions.satisfaction',
        })}
      />
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 0,
        }}
      >
        <SmileyRating
          name="data-capture-survey"
          sx={{
            mt: 4,
          }}
          onChange={(_e: unknown, value: number | null) => {
            if (value !== null) {
              handleSubmit([{ value }]);
            }
          }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button variant="outlined" color="secondary" onClick={handleSkip}>
          {intl.formatMessage({
            id: 'components.surveys.dataCaptureSurvey.buttons.skip',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
