import { IntlShape } from 'react-intl';
import * as R from 'remeda';
import * as z from 'zod';
import {
  atLeastOneOptionMessage,
  getErrorLabel,
  invalidOptionMessage,
  requiredMessage,
  wrongTypeMessage,
} from './errorMessages';

export const fieldsFormErrorMap =
  (intl: IntlShape): z.ZodErrorMap =>
  (issue, ctx) => {
    if (
      issue.message &&
      (issue.code === 'invalid_string' ||
        issue.code === 'custom' ||
        (issue.code === 'too_small' && issue.type === 'string'))
    ) {
      return { message: issue.message };
    }

    if (
      issue.code === 'invalid_type' &&
      (issue.received === 'undefined' || issue.received === undefined)
    ) {
      return {
        message: requiredMessage(intl)(getErrorLabel(intl, R.last(issue.path))),
      };
    }

    if (issue.code === 'invalid_type' && !issue.expected.includes('|')) {
      return {
        message: wrongTypeMessage(intl)(
          getErrorLabel(intl, R.last(issue.path)),
          issue.expected
        ),
      };
    }

    if (issue.code === 'invalid_literal' && issue.received === undefined) {
      return {
        message: requiredMessage(intl)(getErrorLabel(intl, R.last(issue.path))),
      };
    }

    if (
      issue.code === 'invalid_enum_value' &&
      typeof issue.received === 'string'
    ) {
      return {
        message: invalidOptionMessage(intl)(
          getErrorLabel(intl, R.last(issue.path))
        ),
      };
    }

    if (issue.code === 'too_small' && issue.type === 'array') {
      return {
        message: atLeastOneOptionMessage(intl)(
          getErrorLabel(intl, R.last(issue.path))
        ),
      };
    }

    if (issue.code === 'invalid_union_discriminator') {
      return {
        message: invalidOptionMessage(intl)(
          getErrorLabel(intl, R.last(issue.path))
        ),
      };
    }

    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.warn(
        'A non-localized error message encountered',
        issue.code,
        issue.message,
        issue.path
      );
    }
    if (window?.Rollbar && process.env.NODE_ENV === 'production') {
      window.Rollbar.error(`A non-localized error message encountered!`, {
        form: 'Fields form',
        path: issue.path,
        code: issue.code,
        message: issue.message,
      });
    }
    return { message: ctx.defaultError };
  };
