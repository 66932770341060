import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  List,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { partition, some } from 'lodash';
import GoogleCirclesExtendedIcon from 'mdi-react/GoogleCirclesExtendedIcon';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { QUERY_KEY_HOOKS } from '../../../../business/hooks/useHooks';
import { boldText } from '../../../../lib/formaterValues';
import { queryClient } from '../../../../lib/queryClient';
import {
  ExtendedHook,
  ExtensionsRelationMap,
  getQueueHooksQuery,
} from '../helpers';
import { useUpdateDependenciesHook } from '../hooks';

const ICON_SIZE = 48;

type UpdateDependenciesDrawerProps = {
  extensions: Array<ExtendedHook>;
  selectedExtension: ExtendedHook | null;
  extensionGraphNodesMap: ExtensionsRelationMap;
  onClose: () => void;
  selectedQueueId?: number;
};

type ErrorKey = 'noUpdates' | 'runAfter' | 'other';

type ErrorT = { message: string } | { data: { run_after: unknown } };

const resolveErrorKey = (e: ErrorT): ErrorKey => {
  if ('message' in e && e.message === 'no_updates') {
    return 'noUpdates';
  }

  const errors = 'data' in e ? e.data : null;

  if (errors && 'run_after' in errors) {
    return 'runAfter';
  }

  return 'other';
};

const UpdateDependenciesForm = ({
  extensions,
  selectedExtension,
  extensionGraphNodesMap,
  onClose,
  selectedQueueId,
}: UpdateDependenciesDrawerProps) => {
  const intl = useIntl();

  const [extensionsToAdd, setExtensionsToAdd] = useState<Array<ExtendedHook>>(
    []
  );

  const [extensionsToRemove, setExtensionsToRemove] = useState<
    Array<ExtendedHook>
  >([]);

  const [error, setError] = useState<ErrorKey | 'none'>('none');

  const selectableExtensions = useMemo(() => {
    if (selectedExtension) {
      const [toRemove, toAdd] = partition(
        extensions.filter(
          ({ url }) =>
            ![
              selectedExtension.url,
              ...extensionGraphNodesMap[selectedExtension.url].parents,
            ].includes(url)
        ),
        extension =>
          extension.runAfter.some(url => url === selectedExtension?.url)
      );
      return [...toRemove, ...toAdd];
    }
    return [];
  }, [extensions, selectedExtension, extensionGraphNodesMap]);

  const { mutateAsync: updateDependencies, isLoading } =
    useUpdateDependenciesHook();

  return (
    <Box
      component="form"
      sx={{ height: '100%' }}
      key={`${selectedExtension}`}
      onSubmit={e => {
        e.preventDefault();
        setError('none');

        updateDependencies({
          selectedExtensionUrl: selectedExtension?.url,
          extensionsToAdd,
          extensionsToRemove,
        })
          .then(() => {
            queryClient.invalidateQueries([
              QUERY_KEY_HOOKS,
              getQueueHooksQuery(selectedQueueId),
            ]);
            onClose();
          })
          .catch((e: ErrorT) => {
            setError(resolveErrorKey(e));
          });
      }}
    >
      <Stack
        padding={3}
        height="100%"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack sx={{ flex: '1 1 100%', overflowY: 'scroll' }}>
          <Typography variant="body2" mb={3}>
            {intl.formatMessage(
              {
                id: 'containers.queueExtensions.dialog.updateDependencies.description',
              },
              {
                name: selectedExtension?.name,
                id: selectedExtension?.id,
                bold: boldText,
              }
            )}
          </Typography>

          <List
            dense
            sx={{
              marginLeft: -1,
              overflowY: 'scroll',
            }}
          >
            {selectableExtensions.length ? (
              selectableExtensions.map(extension => {
                const { runAfter, id, name, url } = extension;
                const isAttached = runAfter.some(
                  url => url === selectedExtension?.url
                );

                return (
                  <FormGroup key={id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-cy={`checkbox-${name}`}
                          onClick={() => {
                            setError('none');
                            (isAttached
                              ? setExtensionsToRemove
                              : setExtensionsToAdd)(prevState =>
                              some(prevState, { url })
                                ? prevState.filter(
                                    ({ url: prevStateUrl }) =>
                                      prevStateUrl !== url
                                  )
                                : [...prevState, extension]
                            );
                          }}
                        />
                      }
                      labelPlacement="end"
                      label={
                        <Stack sx={{ paddingTop: '4px' }}>
                          <Typography>{name}</Typography>
                          <Typography color="text.disabled">{`ID: ${id}`}</Typography>
                        </Stack>
                      }
                      checked={
                        isAttached
                          ? !some(extensionsToRemove, { url })
                          : some(extensionsToAdd, { url })
                      }
                      sx={{
                        ml: 1,
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    />
                  </FormGroup>
                );
              })
            ) : (
              <Stack spacing={2} alignItems="center">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    height: ICON_SIZE,
                    width: ICON_SIZE,
                    borderRadius: ICON_SIZE,
                    backgroundColor: 'background.paper',
                    color: 'text.disabled',
                  }}
                >
                  <GoogleCirclesExtendedIcon />
                </Stack>

                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'containers.queueExtensions.dialog.updateDependencies.empty',
                  })}
                </Typography>
              </Stack>
            )}
          </List>

          {error !== 'none' && (
            <Typography variant="body2" color="error">
              {intl.formatMessage({
                id: `containers.queueExtensions.dialog.updateDependencies.errors.${error}`,
              })}
            </Typography>
          )}
          {selectableExtensions.length > 0 && (
            <Typography
              color="text.disabled"
              variant="caption"
              marginTop="24px"
            >
              {intl.formatMessage({
                id: 'containers.queueExtensions.dialog.updateDependencies.note',
              })}
            </Typography>
          )}
        </Stack>

        <Stack sx={{ flex: '0 0 auto' }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              data-cy="select-dependencies-cancel-btn"
            >
              {intl.formatMessage({
                id: 'containers.queueExtensions.dialog.updateDependencies.cancel',
              })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-cy="select-dependencies-submit-btn"
              startIcon={
                isLoading && <CircularProgress size={22} color="inherit" />
              }
            >
              {intl.formatMessage({
                id: 'containers.queueExtensions.dialog.updateDependencies.submit',
              })}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default UpdateDependenciesForm;
