import { Fade, Stack } from '@rossum/ui/material';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { TransitionGroup } from 'react-transition-group';
import Scrollable from '../../components/Scrollable';
import { EngineRoutes } from '../../features/engines/EngineRoutes';
import DedicatedEngineDetail from '../../features/legacy-engines/containers/DedicatedEngineDetail';
import { auroraEngineConfigEnabledSelector } from '../../redux/modules/organizationGroup/selectors';
import { enterQueueList } from '../../redux/modules/ui/actions';
import Route from '../../routes/Route';
import Automation from '.';
import { AutomationHeader } from './AutomationHeader';
import {
  automationEnginesPath,
  AutomationParams,
  automationPath,
  dedicatedEnginesDetailRoute,
} from './helpers';

const CONTAINER_WIDTH = 1024;

export const AutomationRoutes = () => {
  const dispatch = useDispatch();
  const dedicatedEngineMatch = useRouteMatch<AutomationParams>(
    dedicatedEnginesDetailRoute
  );

  const auroraConfigEnabled = useSelector(auroraEngineConfigEnabledSelector);
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  return (
    <Scrollable ref={scrollableDivRef}>
      <Stack
        sx={{
          minHeight: '100%',
          backgroundColor: t => t.palette.background.default,
        }}
      >
        <AutomationHeader scrollableDivRef={scrollableDivRef} />
        <Stack width={CONTAINER_WIDTH} mx="auto">
          <TransitionGroup>
            <Fade appear unmountOnExit>
              <div>
                <Switch>
                  <Route
                    exact
                    path={automationPath()}
                    component={Automation}
                    onEnter={() => dispatch(enterQueueList())}
                  />

                  {dedicatedEngineMatch &&
                    Number(dedicatedEngineMatch.params.id) && (
                      <Route
                        exact
                        path={dedicatedEnginesDetailRoute}
                        component={DedicatedEngineDetail}
                      />
                    )}

                  {/* Engine routes are managed by the feature itself. */}
                  <Route
                    path={automationEnginesPath()}
                    render={() => (
                      <EngineRoutes auroraConfigEnabled={auroraConfigEnabled} />
                    )}
                  />

                  <Redirect to={automationPath()} />
                </Switch>
              </div>
            </Fade>
          </TransitionGroup>
        </Stack>
      </Stack>
    </Scrollable>
  );
};
