// Too difficult to type properly.
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  camelCase,
  includes,
  isArray,
  isPlainObject,
  isString,
  mapValues,
  omitBy,
  pickBy,
  snakeCase,
  upperFirst as _upperFirst,
} from 'lodash';

// https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case
export type SnakeToCamel<S extends string> =
  S extends `${infer T}_${infer U}_${infer V}`
    ? `${T}${Capitalize<U>}${Capitalize<SnakeToCamel<V>>}`
    : S extends `${infer T}_${infer U}`
      ? `${T}${Capitalize<SnakeToCamel<U>>}`
      : S;

export type CamelToSnake<S extends string> =
  S extends `${infer T}${infer U}${infer V}`
    ? `${T extends Capitalize<T>
        ? '_'
        : ''}${Lowercase<T>}${U extends Capitalize<U>
        ? '_'
        : ''}${Lowercase<U>}${CamelToSnake<V>}`
    : S extends `${infer T}${infer U}`
      ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnake<U>}`
      : S;

export const snakeToCamel = <T extends string>(s: T): SnakeToCamel<T> =>
  camelCase(s) as SnakeToCamel<T>;

export const camelToSnake = <T extends string>(s: T): CamelToSnake<T> =>
  snakeCase(s) as CamelToSnake<T>;

type SnakeToCamelObject<T> = T extends object
  ? {
      [K in keyof T as SnakeToCamel<K & string>]: SnakeToCamelObject<T[K]>;
    }
  : T;

type CamelToSnakeObject<T> = T extends object
  ? { [K in keyof T as CamelToSnake<K & string>]: CamelToSnakeObject<T[K]> }
  : T;

const caseArrayOrObject = (
  target: any,
  {
    asObject,
    asArray,
    asString = x => x,
  }: {
    asObject: (_obj: Record<string, any>) => object;
    asArray: (_arr: Array<any>) => Array<any>;
    asString?: (_str: string) => string;
  }
) =>
  isPlainObject(target)
    ? asObject(target)
    : isArray(target)
      ? asArray(target)
      : isString(target)
        ? asString(target)
        : target;

// possible overloads, last one is just for backwards compatibility
type KeyConvertor = {
  <InputType>(
    fn: <T extends string>(_key: T) => SnakeToCamel<T>,
    excludeParentKeys?: string[]
  ): (target: InputType) => SnakeToCamelObject<InputType>;
  <InputType>(
    fn: <T extends string>(_key: T) => CamelToSnake<T>,
    excludeParentKeys?: string[]
  ): (target: InputType) => CamelToSnakeObject<InputType>;
  <InputType>(
    fn: (_key: string) => string,
    excludeParentKeys?: string[]
  ): (target: InputType) => any;
};

export const convertKeys: KeyConvertor =
  (fn: any, excludeParentKeys: string[] = []) =>
  (target: any): any =>
    caseArrayOrObject(target, {
      asObject: object =>
        Object.keys(object).reduce(
          (acc, key) => ({
            ...acc,
            [fn(key)]: excludeParentKeys.includes(key)
              ? object[key]
              : convertKeys(fn, excludeParentKeys)(object[key]),
          }),
          {}
        ),
      asArray: array => array.map(convertKeys(fn, excludeParentKeys)),
    });

export const convertStringValues =
  (fn: (_key: string) => string, exclude: string[] = []) =>
  (target: any): any =>
    caseArrayOrObject(target, {
      asObject: object => {
        const toConvert = omitBy(object, (_, key) => includes(exclude, key));
        const excluded = pickBy(object, (_, key) => includes(exclude, key));
        const converted = mapValues(toConvert, convertStringValues(fn));

        return { ...converted, ...excluded };
      },
      asArray: array => array.map(convertStringValues(fn)),
      asString: fn,
    });

type SnakeToSpaces<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T} ${SnakeToSpaces<U>}`
  : S;

export const snakeToSpaces = <S extends string>(type: S): SnakeToSpaces<S> =>
  type.replaceAll('_', ' ') as SnakeToSpaces<S>;

export const upperFirst = <S extends string>(s: S): Capitalize<S> =>
  _upperFirst(s) as Capitalize<S>;

// https://stackoverflow.com/a/70831818
export type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ''
    ? []
    : S extends `${infer T}${D}${infer U}`
      ? [T, ...Split<U, D>]
      : [S];

export default convertKeys;
