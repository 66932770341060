// Generated by ts-to-zod
import { z } from 'zod';
import { schemaItemBaseSchema } from './schemaItemBase.schema';

export const schemaDatapointTypeSchema = z.union([
  z.literal('string'),
  z.literal('number'),
  z.literal('date'),
  z.literal('enum'),
  z.literal('button'),
]);

export const schemaUiConfigTypeSchema = z.union([
  z.literal('captured'),
  z.literal('data'),
  z.literal('manual'),
  z.literal('formula'),
]);

export const schemaUiConfigEditSchema = z.union([
  z.literal('enabled'),
  z.literal('enabled_without_warning'),
  z.literal('disabled'),
]);

export const schemaDatapointUiConfigSchema = z
  .object({
    type: schemaUiConfigTypeSchema.nullable(),
    edit: schemaUiConfigEditSchema,
  })
  .partial();

export const schemaDatapointConstraintsSchema = z
  .object({
    length: z
      .object({
        min: z.number().nullable(),
        max: z.number().nullable(),
        exact: z.number().nullable(),
      })
      .partial()
      .nullable(),
    regexp: z
      .object({
        pattern: z.string().optional(),
      })
      .nullable(),
    required: z.boolean().nullable(),
  })
  .partial();

export const schemaDatapointBaseSchema = schemaItemBaseSchema
  .and(
    z.object({
      category: z.literal('datapoint'),
    })
  )
  .and(
    z
      .object({
        canExport: z.boolean().nullable(),
        canCollapse: z.boolean().nullable(),
        rirFieldNames: z.array(z.string()).nullable(),
        defaultValue: z.string().nullable(),
        constraints: schemaDatapointConstraintsSchema.nullable(),
        uiConfiguration: schemaDatapointUiConfigSchema.nullable(),
        width: z.number().nullable(),
        stretch: z.boolean().nullable(),
        widthChars: z.number().nullable(),
        scoreThreshold: z.number().nullable(),
        formula: z.string().nullable(),
      })
      .partial()
  );
