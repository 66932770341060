import { GridFilterModel, GridLogicOperator } from '@rossum/ui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';

const gridLogicOperator = ['and', 'or'] as const;

const gridFilterModelSchema = z
  .object({
    items: z.array(
      z.object({
        id: z.number().or(z.string()).optional(),
        field: z.string(),
        value: z.any(),
        operator: z.string(),
      })
    ),
    logicOperator: z.enum(gridLogicOperator),
    quickFilterValues: z.any().array().optional(),
    quickFilterLogicOperator: z.enum(gridLogicOperator).optional(),
    quickFilterExcludeHiddenColumns: z.boolean().optional(),
  })
  .nullable();

export const useStroredFilterModel = () => {
  const sessionStorageKeyForFilterModel = 'fieldManagerFilterModel:overview';

  const setFilterModel = useCallback(
    (model: GridFilterModel) =>
      sessionStorage.setItem(
        sessionStorageKeyForFilterModel,
        JSON.stringify(model)
      ),
    []
  );

  const stroredFilterModel = useMemo(() => {
    const storedModelQuery = sessionStorage.getItem(
      sessionStorageKeyForFilterModel
    );

    try {
      const storedModel = storedModelQuery && JSON.parse(storedModelQuery);
      // TODO: Check why zod not validate enums
      gridFilterModelSchema.parse(storedModel);

      return storedModel;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Filtering query parsing error: ', error);
      return { items: [], logicOperator: GridLogicOperator.And };
    }
  }, []);

  return [stroredFilterModel, setFilterModel];
};
