import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import CopyToClipboardButton from '../../ui/copy-to-clipboard/CopyToClipboardButton';
import { HeaderProps } from '../../ui/header/Header';
import DeleteConfirmationDialog, {
  DialogState,
} from './components/DeleteConfirmationDialog';
import DuplicateEngineDialog from './components/engine-duplicate/DuplicateEngineDialog';
import { useDeleteEngine } from './hooks/useDeleteEngine.ts';
import { useDeleteEngineField } from './hooks/useDeleteEngineField';
import { useQueuesForEngine } from './hooks/useQueuesForEngine';
import { useRouteResources } from './hooks/useRouteResources';
import { ENGINE_FORM_ID } from './pages/EngineDetailPage';
import { FIELD_FORM_ID } from './pages/FieldDetailPage';
import { basePath, engineDetailPath } from './paths';

export const useEngineHeaderProps = (): Pick<
  HeaderProps,
  'buttons' | 'description' | 'title' | 'onBackButtonClicked'
> => {
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [confirmationDialogState, setConfirmationDialogState] =
    useState<DialogState | null>(null);

  const intl = useIntl();
  const { mutate: deleteEngine, isLoading: isDeletingEngine } =
    useDeleteEngine();

  const { mutate: deleteField, isLoading: isDeletingField } =
    useDeleteEngineField();
  const {
    engine: {
      data: engineData,
      isSaving: isEngineSaving,
      isCreating: isEngineCreating,
    },
    field: {
      data: fieldData,
      isSaving: isFieldSaving,
      isCreating: isFieldCreating,
      isUnused: isFieldUnused,
    },
  } = useRouteResources();

  const { data } = useQueuesForEngine({
    engine: engineData?.id,
  });

  const totalCount = data?.pagination?.total ?? 0;

  const history = useHistory();

  if (isEngineCreating) {
    return {
      title: intl.formatMessage({
        id: 'features.engines.header.title.newEngine',
      }),
      description: '',
      buttons: [
        <Button
          key="new_engine"
          variant="contained"
          type="submit"
          data-cy="submit-form"
          form={ENGINE_FORM_ID}
          startIcon={
            isEngineSaving && <CircularProgress color="inherit" size={20} />
          }
          disabled={isEngineSaving}
        >
          {intl.formatMessage({ id: 'features.engines.header.buttons.save' })}
        </Button>,
      ],
      onBackButtonClicked: () => history.push(basePath),
    };
  }

  if (engineData && isFieldCreating) {
    return {
      title: intl.formatMessage({
        id: 'features.engines.header.title.newField',
      }),
      description: '',
      buttons: [
        <Button
          key="new_field"
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
          startIcon={
            isFieldSaving && <CircularProgress color="inherit" size={20} />
          }
          data-cy="submit-form"
          disabled={isFieldSaving}
        >
          {intl.formatMessage({ id: 'features.engines.header.buttons.save' })}
        </Button>,
      ],
      onBackButtonClicked: () => history.push(engineDetailPath(engineData.id)),
    };
  }

  if (fieldData && engineData) {
    const deleteDisabled = !isFieldUnused;

    return {
      title: fieldData.label,
      description: fieldData.name,
      buttons: [
        <Fragment key="delete_field">
          <Tooltip
            title={
              deleteDisabled
                ? intl.formatMessage({
                    id: 'features.engines.header.buttons.deleteField.disabledTooltip',
                  })
                : ''
            }
          >
            <span>
              <Button
                variant="outlined"
                color="secondary"
                data-cy="delete-button"
                disabled={deleteDisabled || isDeletingField}
                onClick={() =>
                  setConfirmationDialogState({
                    key: 'deleteEngineField',
                    onConfirm: () => {
                      setConfirmationDialogState(null);
                      deleteField(fieldData.id, {
                        onSuccess: () =>
                          history.push(engineDetailPath(engineData.id)),
                      });
                    },
                  })
                }
                startIcon={
                  isDeletingField && (
                    <CircularProgress color="inherit" size={20} />
                  )
                }
              >
                {intl.formatMessage({
                  id: 'features.engines.header.buttons.deleteField',
                })}
              </Button>
            </span>
          </Tooltip>
          <DeleteConfirmationDialog
            dialogState={confirmationDialogState}
            setDialogState={setConfirmationDialogState}
          />
        </Fragment>,
        <Button
          key="save_field"
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
          startIcon={
            isFieldSaving && <CircularProgress color="inherit" size={20} />
          }
          data-cy="submit-form"
          disabled={isFieldSaving}
        >
          {intl.formatMessage({ id: 'features.engines.header.buttons.save' })}
        </Button>,
      ],
      onBackButtonClicked: () => history.push(engineDetailPath(engineData.id)),
    };
  }

  if (engineData) {
    const deleteDisabled = totalCount !== undefined && totalCount > 0;

    return {
      title: (
        <Stack gap={1} direction="row" alignItems="center">
          {engineData.name}
        </Stack>
      ),
      description: (
        <Stack spacing={3}>
          <span>{engineData.description}</span>
          <Stack direction="row" alignItems="center" gap={1}>
            <span>
              {intl.formatMessage({
                id: 'features.engines.header.description.engineId',
              })}
              {': '}
            </span>
            <Typography variant="body2" color="text.primary">
              {engineData.agendaId}
            </Typography>
            <CopyToClipboardButton
              content={`${engineData.agendaId}`}
              iconColor="secondary.main"
            />
          </Stack>
        </Stack>
      ),
      buttons: [
        <Fragment key="delete">
          <Tooltip
            title={
              deleteDisabled
                ? intl.formatMessage({
                    id: 'features.engines.header.buttons.delete.disabledTooltip',
                  })
                : ''
            }
          >
            <span>
              <Button
                variant="outlined"
                color="secondary"
                disabled={deleteDisabled || isDeletingEngine}
                data-cy="delete-button"
                onClick={() =>
                  setConfirmationDialogState({
                    key: 'deleteEngine',
                    onConfirm: () => {
                      setConfirmationDialogState(null);
                      deleteEngine(engineData.id, {
                        onSuccess: () => history.push(basePath),
                      });
                    },
                  })
                }
                startIcon={
                  isDeletingEngine && (
                    <CircularProgress color="inherit" size={20} />
                  )
                }
              >
                {intl.formatMessage({
                  id: 'features.engines.header.buttons.delete',
                })}
              </Button>
            </span>
          </Tooltip>
          <DeleteConfirmationDialog
            dialogState={confirmationDialogState}
            setDialogState={setConfirmationDialogState}
          />
        </Fragment>,
        <Fragment key="duplicate">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setDuplicateDialogOpen(true)}
            data-cy="duplicate-engine-button"
          >
            {intl.formatMessage({
              id: 'features.engines.header.buttons.duplicate',
            })}
          </Button>
          <DuplicateEngineDialog
            engine={engineData}
            open={duplicateDialogOpen}
            closeDialog={() => setDuplicateDialogOpen(false)}
          />
        </Fragment>,
        <Button
          key="save_engine"
          variant="contained"
          type="submit"
          form={ENGINE_FORM_ID}
          startIcon={
            isEngineSaving && <CircularProgress color="inherit" size={20} />
          }
          disabled={isEngineSaving}
          data-cy="submit-form"
        >
          {intl.formatMessage({ id: 'features.engines.header.buttons.save' })}
        </Button>,
      ],
      onBackButtonClicked: () => history.push(basePath),
    };
  }

  return {
    title: intl.formatMessage({ id: 'features.engines.header.title.main' }),
    description: intl.formatMessage({
      id: 'features.engines.header.description.main',
    }),
    buttons: [],
  };
};
