import { endpoints, ID } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { Survey } from '@rossum/api-client/surveys';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_SURVEY = 'QUERY_KEY_SURVEY';

// gets a survey by ID
export const useSurvey = <TReturnedData = Survey>(
  surveyId: ID | null,
  queryOptions: Omit<
    UseQueryOptions<
      Survey,
      ElisClientError,
      TReturnedData,
      [typeof QUERY_KEY_SURVEY, ID | null]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery({
    queryKey: [QUERY_KEY_SURVEY, surveyId],

    queryFn: ({ queryKey }) => {
      if (queryKey[1] === null) {
        throw new Error('Called useSurvey with null ID');
      }

      return api.request(endpoints.surveys.get(queryKey[1]));
    },

    ...queryOptions,
    enabled: queryOptions.enabled && surveyId !== null,
  });
};
