import { useCallback, useState } from 'react';
import { isFilterContextSimple } from '../../helpers';
import { FilterOperator } from '../../operators';
import { FilterComponentProps, FilterItem } from '../../types';
import Operators from '.';
import { isOperatorEmpty, resolveValueBasedOnBooleanOperator } from './utils';

export const useOperators = ({
  filterItem,
  initialState,
  onChange,
  value,
}: {
  onChange?: FilterComponentProps['onOperatorChange']; // coming function should be always memoized and prevent rerender of node
  initialState?: FilterOperator;
  filterItem: FilterItem;
  value?: unknown;
}) => {
  const {
    column: { headerName, operators },
    filterContext,
  } = filterItem;

  const firstOperator = operators[0];

  if (firstOperator === undefined) {
    throw new Error(
      `Column '${headerName}' has no available filtering operators.`
    );
  }

  const defaultOperatorValue = isFilterContextSimple(filterContext)
    ? filterContext.operator
    : firstOperator;

  const [operator, setOperator] = useState<FilterOperator>(
    initialState ?? defaultOperatorValue
  );

  const memoizedNode = useCallback(
    () => (
      <Operators
        headerName={headerName}
        operators={operators}
        operator={operator}
        onOperatorChange={o => {
          setOperator(o);
          onChange?.(o, resolveValueBasedOnBooleanOperator(o, value));
        }}
      />
    ),
    [headerName, onChange, operator, operators, value]
  );

  return {
    node: memoizedNode,
    operator,
    setOperator,
    emptyOperatorIsUsed: isOperatorEmpty(operator),
  };
};
