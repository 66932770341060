import { Annotation } from '@rossum/api-client/annotations';
import { AnnotationListSideload } from '@rossum/api-client/annotations';
import {
  AnnotationListColumn,
  AnnotationListColumns,
  MetaField,
  SchemaColumn,
} from '@rossum/api-client/shared';
import { User } from '@rossum/api-client/users';
import { ListResponse } from '@rossum/api-client/utils';
import { Dictionary, uniqBy } from 'lodash';
import {
  MetaColDef,
  SchemaColDef,
  TypedGridColDef,
  ValueOptions,
} from '../filtering/types';

export const DEFAULT_COLUMN_WIDTH = 120;

export type DashboardAnnotationListWithSideload = ListResponse<Annotation> &
  // These are always present
  Pick<AnnotationListSideload, 'relations' | 'documents' | 'childRelations'> &
  // These are present if relevant
  Partial<
    Pick<
      AnnotationListSideload,
      | 'assignees'
      | 'labels'
      | 'modifiers'
      | 'exportedBys'
      | 'deletedBys'
      | 'rejectedBys'
      | 'confirmedBys'
    >
  >;

export const sortValueOptions = (valueOptions: ValueOptions) =>
  valueOptions.sort((a, b) => a.label.localeCompare(b.label));

export const getColumnField = <
  T extends {
    columnType: AnnotationListColumn['columnType'];
    field: string;
    dataType?: SchemaColumn['dataType'];
  },
>(
  c: T
) =>
  `${c.columnType === 'schema' ? 'field' : c.columnType}.${c.field}.${
    c.dataType ?? ''
  }`;

// pass only unique columns by columnType and field properties
// we do not support columns with same field and different dataType for now
export const uniqColumns = (columns: AnnotationListColumns) =>
  uniqBy(columns, c =>
    getColumnField({ columnType: c.columnType, field: getColumnName(c) })
  );

export const getModifierLabel = ({ email, username }: User) =>
  email || username;

export const getModifierValueOptions = (modifiers: Dictionary<User>) =>
  sortValueOptions(
    Object.values(modifiers).map(modifier => {
      const { id } = modifier;

      return {
        value: `${id}`,
        label: getModifierLabel(modifier),
      };
    })
  );

export const filterSchemaColumns = (columns: AnnotationListColumns) =>
  columns.flatMap(col => (col.columnType === 'schema' ? [col] : []));

export const filterMetaColumns = (columns: AnnotationListColumns) =>
  columns.flatMap(col => (col.columnType === 'meta' ? [col] : []));

export const getColumnName = (column: AnnotationListColumn) =>
  column.columnType === 'meta' ? column.metaName : column.schemaId;

export const isSchemaColumn = (
  column: TypedGridColDef
): column is SchemaColDef => 'dataType' in column;

export const getAnnotationListColumns = (
  tableColumns: Array<SchemaColDef | MetaColDef>
): AnnotationListColumns =>
  tableColumns.map(col =>
    col.columnType === 'meta'
      ? {
          columnType: col.columnType,
          metaName: col.metaName,
          width: col.width,
          visible: col.visible,
        }
      : {
          columnType: col.columnType,
          schemaId: col.schemaId,
          dataType: col.dataType,
          width: col.width,
          visible: col.visible,
        }
  );

export type DocumentModifier = User | 'none' | 'unknown';

export const resolveModifierFromUrl = <Modifiers extends Record<string, User>>(
  url: string | null,
  modifiers: Modifiers
): DocumentModifier => {
  if (!url) return 'none';
  return modifiers[url] ?? 'unknown';
};

// when the user changes status tab it is likely that the table config saved in the database won't match the table columns displayed in the ui
// This fn is used to not overwrite tables when performing PATCH operations
export const preserveMissingColumns = ({
  updatedColumns,
  previousColumns,
}: {
  previousColumns: AnnotationListColumns;
  updatedColumns: AnnotationListColumns;
}) => {
  const missingColumnIndexPairs = previousColumns.flatMap((column, index) =>
    updatedColumns.some(c => getColumnName(c) === getColumnName(column))
      ? []
      : [[column, index] as const]
  );

  // here we add missing/hidden columns preserving their column order (index)
  return missingColumnIndexPairs.reduce((acc, [col, index]) => {
    acc.splice(index, 0, col);
    return acc;
  }, updatedColumns);
};

export const isUserField = (value: string) => {
  const listOfUserFields: MetaField[] = [
    'assignees',
    'confirmed_by',
    'deleted_by',
    'exported_by',
    'modifier',
    'rejected_by',
  ];
  return listOfUserFields.some(userField => userField === value);
};
