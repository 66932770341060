import './styles.sass';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { pageSizes } from '../../../constants/values';
import styles from './style.module.sass';

const options = pageSizes.map(option => ({
  label: option,
  value: option,
}));

type OptionType = { label: string; value: string };

type Props = {
  pageSize: string | string[] | null;
  setPageSize: (pageSize: string) => void;
};

const PageSizeSelector = ({ pageSize, setPageSize }: Props) => (
  <div className={styles.PageSizeSelector}>
    <Select
      classNamePrefix="pageSizeSelector"
      id="pageSizeSelector"
      placeholder="-"
      isSearchable={false}
      menuPortalTarget={document.body}
      options={options}
      value={options.find(({ value }) => value === pageSize)}
      onChange={(value: ValueType<OptionType>) =>
        setPageSize((value as OptionType).value)
      }
      menuPlacement="top"
      styles={{
        control: base => ({
          ...base,
          width: 67,
          height: 32,
          borderRadius: 10,
          backgroundColor: 'transparent',
          borderWidth: 1,
          boxShadow: 'none',
          borderStyle: 'solid',
          fontSize: 14,
        }),
        dropdownIndicator: base => ({
          ...base,
          svg: {
            width: 16,
            height: 11,
          },
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menu: base => ({
          ...base,
          width: 67,
        }),
      }}
    />
    <div className={styles.Unit}>
      <FormattedMessage id="containers.annotationList.paginatorSelector.unit" />
    </div>
  </div>
);

export default PageSizeSelector;
