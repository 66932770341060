import { endpoints } from '@rossum/api-client';
import { EmailThreadsListQuery } from '@rossum/api-client/emailThreads';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';
import { DataGridConditionModel } from '../../filtering/mql';
import { encodeEmailsFiltering } from '../../filtering/transformers';
import { transformEmailThreads } from '../helpers';

export const QUERY_KEY_EMAIL_THREADS = 'email-threads';

export const useFetchEmailThreads = (
  query: EmailThreadsListQuery,
  filtering: DataGridConditionModel,
  ordering: string
) => {
  const { queue } = query;
  const api = useApiClient();

  const targetQuery = {
    ...query,
    ...encodeEmailsFiltering(filtering),
    ...(ordering ? { ordering } : {}),
  };

  return useQuery({
    queryKey: [QUERY_KEY_EMAIL_THREADS, targetQuery] as const,

    queryFn: () => {
      return api.request(endpoints.emailThreads.list(targetQuery));
    },
    enabled: !!queue,
    select: emailThreads => {
      return {
        pagination: emailThreads.pagination,
        results: transformEmailThreads(emailThreads.results ?? []),
      };
    },
  });
};
