import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { parse } from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { stringify } from '../../../lib/url';
import {
  DOCUMENTS_QUERY,
  DOCUMENTS_QUERY_FOR_BROWSER_TAB,
  EMAILS_QUERY,
} from '../../../redux/modules/localStorage/actions';
import { useJsonParse } from '../../../utils/hooks/useJsonParse';
import {
  getStringFromValue,
  useStoredState,
} from '../../../utils/hooks/useStoredState';
import { createNewFilterQuery } from '../filtering/transformers';
import { DEFAULT_PAGE_SIZE } from './usePagination';

const queryKeys = [
  'search' as const,
  'ordering' as const,
  'filtering' as const,
  'page' as const,
  'level' as const,
  'view' as const,
  'page_size' as const,
  'selected_annotation' as const,
  'selected_annotation_view' as const,
];

export const supportedAnnotationViews = [
  'attachments',
  'duplicates',
  'emails',
  'activities',
] as const;

export type SupportedAnnotationView = (typeof supportedAnnotationViews)[number];

const getQueueId = (
  existingFilter: GridFilterModel | null,
  level: LevelOptions | null
) => {
  const queueId: string | undefined = existingFilter?.items.find(
    item => item.field === 'queue' && item.value.length === 1
  )?.value[0];

  return queueId && level === 'queue' ? parseInt(queueId, 10) : undefined;
};

export const validateLevel = (
  value: string | undefined
): LevelOptions | null =>
  value === 'all' || value === 'queue' || value === 'initial' ? value : null;

const getQueryFromStorage = (view: ViewOptions) => {
  const storedQuery = localStorage.getItem(
    view === 'emails' ? EMAILS_QUERY : DOCUMENTS_QUERY
  );

  if (!storedQuery) return undefined;

  const parsedData = parse(storedQuery);

  const search = getStringFromValue(parsedData.search) ?? '';
  const ordering = getStringFromValue(parsedData.ordering) ?? '';
  const filtering = getStringFromValue(parsedData.filtering) ?? '';
  const level = validateLevel(getStringFromValue(parsedData.level));
  const page = getStringFromValue(parsedData.page) ?? '1';

  return {
    ...(search ? { search } : {}),
    ...(filtering ? { filtering } : {}),
    ...(ordering ? { ordering } : {}),
    ...(level ? { level } : {}),
    view,
    page,
  };
};

const getFilterWithQueueId = (filter: GridFilterModel, queueId: number) =>
  ({
    ...filter,
    items: filter.items.map(item => {
      if (item.field === 'queue') {
        return {
          ...item,
          value: [`${queueId}`],
        };
      }

      return item;
    }),
  }).items;

const keysToBeOmitted = ['selected_annotation', 'selected_annotation_view'];

export const useDashboardQuery = () => {
  const { push } = useHistory();
  const { search } = useLocation();

  // take emails from url, before query in context of this hook is created, for solving which view should be handled first
  const [view, setView] = useState<ViewOptions>(() => {
    const existingViewQuery = parse(search).view;

    if (existingViewQuery !== 'emails' && existingViewQuery !== 'documents')
      return 'documents';

    return existingViewQuery;
  });

  const storageKey = view === 'emails' ? EMAILS_QUERY : DOCUMENTS_QUERY;

  const [query, setQuery] = useStoredState({
    name: storageKey,
    defaultValues: {
      page: '1',
      page_size: `${DEFAULT_PAGE_SIZE}`,
      level: 'initial',
    },
    stringKeys: queryKeys,
    historyFn: push,
    parseFn: queryString => parse(queryString, { arrayFormat: 'none' }),
    omitFromLocalStorage: keysToBeOmitted,
  });

  const [existingFilter] = useJsonParse<GridFilterModel>(query.filtering ?? '');

  const level = validateLevel(query.level);

  const queueIdFromUrl = getQueueId(existingFilter, level);

  const [emailExistingFilter] = useJsonParse<GridFilterModel>(
    getQueryFromStorage('emails')?.filtering ?? ''
  );

  const [documentsExistingFilter] = useJsonParse<GridFilterModel>(
    getQueryFromStorage('documents')?.filtering ?? ''
  );

  const handleSetQuery = useCallback(
    (targetView: ViewOptions) => {
      // this should never happen as this function only runs in queue level, never in all docs level
      if (!queueIdFromUrl) return;

      const isTargetEmails = targetView === 'emails';

      const targetFilter = isTargetEmails
        ? emailExistingFilter
        : documentsExistingFilter;

      const targetFilterQuery = createNewFilterQuery(
        targetFilter
          ? getFilterWithQueueId(targetFilter, queueIdFromUrl)
          : [
              {
                field: 'queue',
                operator: 'isAnyOf',
                value: [`${queueIdFromUrl}`],
              },
            ]
      );

      const targetQuery = {
        ...(getQueryFromStorage(targetView) ?? { view: targetView }),
        filtering: targetFilterQuery,
      };

      setQuery(prevQuery => {
        // when searching does not exists in target query, we don't want preserve it from prevQuery
        const { search, ...restPrevQuery } = prevQuery;

        return {
          ...restPrevQuery,
          ...targetQuery,
        };
      });
      setView(targetView);
    },
    [queueIdFromUrl, emailExistingFilter, documentsExistingFilter, setQuery]
  );

  useEffect(() => {
    // needed because of getReviewableStatusesFromAnnotationsQuery
    sessionStorage.setItem(DOCUMENTS_QUERY_FOR_BROWSER_TAB, stringify(query));
  }, [query]);

  return {
    query,
    setQuery,
    view,
    setView: handleSetQuery,
    existingFilter,
    level,
    currentQueueId: queueIdFromUrl,
  };
};

export type ViewOptions = 'emails' | 'documents';
export type LevelOptions = 'all' | 'queue' | 'initial';
export type DashboardQuery = ReturnType<typeof useDashboardQuery>['query'];
