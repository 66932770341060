import update from 'immutability-helper';
import { findLast, sumBy } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'remeda';
import { assertNever } from '../../lib/typeUtils';
import {
  deleteAllDatapoints,
  deleteDatapoint,
  insertLine,
} from '../../redux/modules/datapoints/actions';
import { datapointsSelector } from '../../redux/modules/datapoints/selector';
import {
  MultivalueDatapointDataST,
  TupleDatapointDataST,
} from '../../types/datapoints';

export type TupleSelection = {
  // We want to find quickly, if given tuple ID
  // is present in the selection or not.
  // That's why it's represented as an object, not an array.
  tupleIds: Record<number, true>;
  allSelected: boolean;
  atLeastOneSelected: boolean;
  selectionMetaPerPart: Record<
    string,
    { allSelected: boolean; atLeastOneSelected: boolean }
  >;
  count: number;
};

export type SelectionAction =
  | { type: 'select-all' }
  | { type: 'deselect-all' }
  | { type: 'select-range'; tupleId: number }
  | { type: 'toggle'; tupleId: number }
  | { type: 'select-part'; partId: string }
  | { type: 'deselect-part'; partId: string }
  | { type: 'select-from-part'; partId: string };

export const useTupleActions = (
  allTupleIds: number[],
  currentMultivalue: MultivalueDatapointDataST,
  tupleIdsByPart: Record<string, number[]>
) => {
  const dispatch = useDispatch();

  const datapoints = useSelector(datapointsSelector);

  const [selectedTuples, setSelectedTuples] = useState<Record<number, true>>(
    {}
  );

  const multivalueId = currentMultivalue.id;
  const sectionId = currentMultivalue.meta.parentId!;
  const multivalueIndex = currentMultivalue.meta.index;

  const tupleCount = allTupleIds.length;

  const selection: TupleSelection = useMemo(() => {
    const count = Object.keys(selectedTuples).length;

    return {
      tupleIds: selectedTuples,
      allSelected: count === tupleCount,
      atLeastOneSelected: count > 0,
      selectionMetaPerPart: Object.fromEntries(
        Object.entries(tupleIdsByPart).map(([part, tupleIdsInPart]) => [
          part,
          {
            atLeastOneSelected: tupleIdsInPart.some(id => !!selectedTuples[id]),
            allSelected: tupleIdsInPart.every(id => !!selectedTuples[id]),
          },
        ])
      ),
      count,
    };
  }, [selectedTuples, tupleCount, tupleIdsByPart]);

  const countOfSelectedTuples = sumBy(allTupleIds, id =>
    id in selectedTuples ? 1 : 0
  );

  // Rows can be deleted outside of footer.
  // If the selection contains tuple IDs which are not longer valid, recreate the selection
  if (countOfSelectedTuples !== selection.count) {
    setSelectedTuples(
      Object.fromEntries(
        allTupleIds.filter(id => id in selectedTuples).map(id => [id, true])
      )
    );
  }

  // PERF: To ensure that onSelect and onDelete callbacks are stable references,
  // and won't cause re-rendering of child components,
  // we store the necessary context in a useRef
  const callbackContext = useRef<{
    allTupleIds: number[];
    selection: typeof selection;
    selectedTuples: typeof selectedTuples;
    tupleIdsByPart: typeof tupleIdsByPart;
    previouslyClicked: number | null;
  }>({
    allTupleIds,
    selection,
    selectedTuples,
    tupleIdsByPart,
    previouslyClicked: null,
  });

  callbackContext.current = {
    ...callbackContext.current,
    allTupleIds,
    selection,
    selectedTuples,
    tupleIdsByPart,
  };

  const onSelect = useCallback((newSelection: SelectionAction) => {
    const { type } = newSelection;
    switch (type) {
      case 'deselect-all': {
        setSelectedTuples({});
        break;
      }
      case 'select-all': {
        setSelectedTuples(
          Object.fromEntries(
            callbackContext.current.allTupleIds.map(id => [id, true])
          )
        );
        break;
      }
      case 'toggle': {
        setSelectedTuples(selection => {
          if (newSelection.tupleId in selection) {
            return update(selection, { $unset: [newSelection.tupleId] });
          }
          return update(selection, { [newSelection.tupleId]: { $set: true } });
        });
        break;
      }

      case 'select-range': {
        const { previouslyClicked, allTupleIds } = callbackContext.current;

        const previouslyClickedIndex = allTupleIds.findIndex(
          id => id === previouslyClicked
        );

        // In case that row is deleted it should act as toggle
        if (previouslyClickedIndex === -1) {
          setSelectedTuples(selection => {
            if (newSelection.tupleId in selection) {
              return update(selection, { $unset: [newSelection.tupleId] });
            }
            return update(selection, {
              [newSelection.tupleId]: { $set: true },
            });
          });
          break;
        }

        const currentTupleId = newSelection.tupleId;

        const currentTupleIndex = allTupleIds.findIndex(
          id => id === currentTupleId
        );

        const [startIndex, endIndex] = [
          previouslyClickedIndex,
          currentTupleIndex,
        ].sort();

        const tupleIdsToSelect = allTupleIds.slice(startIndex, endIndex + 1);

        setSelectedTuples(selection => {
          if (newSelection.tupleId in selection) {
            return update(selection, {
              $unset: tupleIdsToSelect.filter(
                id => id !== newSelection.tupleId
              ),
            });
          }
          return update(
            selection,
            R.fromEntries(tupleIdsToSelect.map(id => [id, { $set: true }]))
          );
        });

        break;
      }
      case 'select-part': {
        setSelectedTuples(selection => ({
          // Preserve previously selected tuples and add new ones
          ...selection,
          ...Object.fromEntries(
            callbackContext.current.tupleIdsByPart[newSelection.partId].map(
              id => [id, true]
            )
          ),
        }));
        break;
      }
      case 'deselect-part': {
        setSelectedTuples(selection => {
          const idsInDeselectedPart =
            callbackContext.current.tupleIdsByPart[newSelection.partId];

          return update(selection, { $unset: idsInDeselectedPart });
        });
        break;
      }
      case 'select-from-part': {
        const idsByPartArr = Object.entries(
          callbackContext.current.tupleIdsByPart
        );

        const sliceFrom = idsByPartArr.findIndex(
          ([partId]) => partId === newSelection.partId
        );

        if (sliceFrom > -1) {
          const idsToBeAdded = idsByPartArr
            .slice(sliceFrom)
            .flatMap(([_, ids]) => ids);

          setSelectedTuples(selection => ({
            // Preserve previously selected tuples and add new ones
            ...selection,
            ...Object.fromEntries(idsToBeAdded.map(id => [id, true])),
          }));
          break;
        }
        break;
      }
      default:
        assertNever(type);
    }
    callbackContext.current.previouslyClicked =
      'tupleId' in newSelection ? newSelection.tupleId : null;
  }, []);

  const onDelete = useCallback(
    (tuple: 'selection' | number) => {
      if (typeof tuple === 'number') {
        dispatch(deleteDatapoint([sectionId, multivalueId, tuple]));
      } else {
        if (callbackContext.current.selection.allSelected) {
          dispatch(deleteAllDatapoints(multivalueIndex));
        } else {
          Object.keys(callbackContext.current.selectedTuples).forEach(tupleId =>
            dispatch(
              deleteDatapoint([sectionId, multivalueId, Number(tupleId)])
            )
          );
        }
        setSelectedTuples({});
      }
    },
    [multivalueIndex, sectionId, multivalueId, dispatch]
  );

  const onAddTuple = useCallback(() => {
    const edgeTupleId = findLast(allTupleIds, id => id in selection.tupleIds);
    const tuple = datapoints.find(
      (dp): dp is TupleDatapointDataST =>
        dp.id === edgeTupleId && dp.category === 'tuple'
    );

    if (!tuple) {
      return;
    }

    dispatch(insertLine({ tuple }));
  }, [allTupleIds, datapoints, dispatch, selection.tupleIds]);

  return {
    selection,
    onSelect,
    onDelete,
    onAddTuple,
  };
};
