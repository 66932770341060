import { LinearProgress } from '@rossum/ui/material';
import clsx from 'clsx';
import { flow, get, includes, isNumber, round } from 'lodash';
import { Component } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import DataCaptureSurvey from '../../components/DataCaptureSurvey';
import { DataCaptureTourDialog } from '../../components/productTour/DataCaptureTourDialog';
import { MIN_FOOTER_HEIGHT } from '../../decorators/makeResizable/config';
import { getFittingHeight } from '../../decorators/makeResizable/helpers';
import ValidationKeyboardHandler from '../../decorators/ValidationKeyboardHandler';
import { ValidationDocumentDrawer } from '../../features/annotation-view/document-drawer/ValidationDocumentDrawer';
import { DocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import {
  DocumentContext,
  DocumentContextProvider,
} from '../../features/annotation-view/DocumentContext';
import { shouldComponentUpdateDeepEqual } from '../../lib/shouldComponentUpdateDeepEqual';
import { getCurrentAnnotationId, parse } from '../../lib/url';
import { exitAnnotation } from '../../redux/modules/annotation/actions';
import {
  currentMultivalueDatapointSelector,
  getTableDatapointsWithIds,
  isFooterOpenSelector,
} from '../../redux/modules/datapoints/selector';
import { closeSelectMenu } from '../../redux/modules/ui/actions';
import { ensureArray } from '../../redux/modules/utils';
import { ID } from '../../types/basic';
import { State as ReduxState } from '../../types/state';
import { DocumentV2 } from '../Document/DocumentV2';
import Footer from '../Footer/Footer';
import Loader from '../Loader';
import Sidebar from '../Sidebar';
import { DocumentMetrics } from './DocumentMetrics';
import styles from './style.module.sass';
import { DrawerConfig, ValidationEmailDrawer } from './ValidationEmailDrawer';
import { ViewportSizeUpdater } from './ViewportSizeUpdater';

type StateProps = {
  currentDatapointPath: Array<number>;
  annotationId: number;
  editModeActive: boolean;
  documentLoaded: boolean;
  showFooter: boolean;
  progressPercentage: number | undefined;
  selectMenuIsOpen: boolean;
};

type OwnProps = RouteComponentProps;

type DispatchProps = {
  closeSelectMenu: () => void;
  exitAnnotation: (annotationId: ID) => void;
};

type Props = StateProps & OwnProps & DispatchProps;

type State = {
  footerHeight: number;
  drawerOpen: boolean;
  drawerConfig: DrawerConfig;
};

class DocumentValidation extends Component<Props, State> {
  closeSelectMenuSubscription: Subscription | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      footerHeight: 0,
      drawerOpen: false,
      drawerConfig: {},
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return shouldComponentUpdateDeepEqual(this, nextProps, nextState);
  }

  componentDidMount() {
    this.closeSelectMenuSubscription = fromEvent(window.document, 'mousedown')
      .pipe(
        filter(() => this.props.selectMenuIsOpen),
        // FIXME: remove this filter when 'react-select' will be removed from project
        filter(
          ({ srcElement }) =>
            !includes(
              (srcElement as Element).getAttribute('class'),
              'react-select'
            )
        ),
        // avoid to close select when user click inside select
        filter(({ srcElement }) => {
          return !includes(
            (srcElement as Element).getAttribute('data-id'),
            'select_box'
          );
        })
      )
      .subscribe(() => this.props.closeSelectMenu());
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const previousAnnotationId = this.props.annotationId;
    const nextAnnotationId = nextProps.annotationId;
    if (nextAnnotationId !== previousAnnotationId) {
      this.props.exitAnnotation(previousAnnotationId);
    }
  }

  componentWillUnmount() {
    const annotationIdFromUrl = getCurrentAnnotationId(
      window.location.pathname
    );

    const stayingOnSameAnnotation =
      annotationIdFromUrl && annotationIdFromUrl === this.props.annotationId;

    // When redirecting to the new edit mode, the component unmounts,
    // but we don't want to exit annotation
    if (!stayingOnSameAnnotation) {
      this.props.exitAnnotation(this.props.annotationId);
    }

    if (this.closeSelectMenuSubscription)
      this.closeSelectMenuSubscription.unsubscribe();
  }

  setFooterHeight = (footerHeight: number) => this.setState({ footerHeight });

  render() {
    const {
      annotationId,
      documentLoaded,
      editModeActive,
      progressPercentage,
      showFooter,
    } = this.props;

    const { footerHeight } = this.state;

    const displayProgressBar = !editModeActive && isNumber(progressPercentage);

    const onEmailThreadOpen = (drawerConfig?: DrawerConfig) => {
      return this.setState({
        drawerOpen: true,
        drawerConfig: drawerConfig || {},
      });
    };

    return (
      <>
        <DocumentContextProvider>
          <DocumentContext.Consumer>
            {documentContextValue =>
              documentContextValue ? (
                <div
                  className={styles.DocumentValidationWrapper}
                  data-page-title="document-validation"
                >
                  {displayProgressBar && (
                    <LinearProgress
                      variant="determinate"
                      value={progressPercentage}
                    />
                  )}
                  <div
                    className={clsx(
                      styles.DocumentValidationContainer,
                      !displayProgressBar &&
                        styles.DocumentValidationIndentation
                    )}
                  >
                    <DocumentStore key={annotationId}>
                      <ValidationDocumentDrawer />
                      <DocumentMetrics />
                      <DataCaptureTourDialog />
                      <Sidebar onEmailThreadOpen={onEmailThreadOpen} />
                      <ViewportSizeUpdater height={footerHeight} />
                      <div className={styles.RightPane}>
                        {documentLoaded ? (
                          <DocumentV2
                            footerHeight={footerHeight}
                            onEmailThreadOpen={onEmailThreadOpen}
                          />
                        ) : (
                          <div className={styles.LoaderWrapper}>
                            <Loader absolute />
                          </div>
                        )}
                        {documentLoaded && showFooter && (
                          <FooterSwitch
                            setFooterHeight={this.setFooterHeight}
                          />
                        )}
                      </div>
                    </DocumentStore>
                  </div>
                </div>
              ) : null
            }
          </DocumentContext.Consumer>
        </DocumentContextProvider>
        {/* The key is important - re-mounts data capture survey to track stuff from scratch. */}
        <DataCaptureSurvey annotationId={annotationId} key={annotationId} />
        <ValidationEmailDrawer
          DrawerProps={{
            open: this.state.drawerOpen,
            onClose: () => {
              this.setState({ drawerOpen: false });
            },
          }}
          {...this.state.drawerConfig}
        />
      </>
    );
  }
}

// AFI: Refactor this together with withResizable
const getFittingHeightSelector = (state: ReduxState) => {
  const [tuples] = getTableDatapointsWithIds(state);
  const currentMultivalueDatapoint = currentMultivalueDatapointSelector(state);

  return currentMultivalueDatapoint
    ? getFittingHeight(
        tuples,
        !!get(state, [
          'datapoints',
          'aggregations',
          currentMultivalueDatapoint.id,
        ])
      )
    : MIN_FOOTER_HEIGHT;
};

const FooterSwitch = ({
  setFooterHeight,
}: {
  setFooterHeight: (footerHeight: number) => void;
}) => {
  const initialHeight = useSelector(getFittingHeightSelector);

  return (
    <Footer
      minHeight={MIN_FOOTER_HEIGHT}
      setHeight={setFooterHeight}
      height={initialHeight}
    />
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  const {
    stack,
    router: {
      location: { pathname },
    },
  } = state;

  const annotationId = getCurrentAnnotationId(pathname);
  const datapointPath = ensureArray(
    parse(state.router.location.search).datapointPath
  );

  return {
    currentDatapointPath: datapointPath,
    annotationId,
    editModeActive: state.ui.editModeActive,
    documentLoaded: typeof state.annotation.id === 'number',
    showFooter: isFooterOpenSelector(state) && !state.ui.editModeActive,
    progressPercentage: includes(stack, annotationId)
      ? round((stack.indexOf(annotationId) / stack.length) * 100)
      : undefined,
    selectMenuIsOpen: state.ui.selectMenuIsOpen,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeSelectMenu: () => dispatch(closeSelectMenu()),
  exitAnnotation: (id: number) => dispatch(exitAnnotation(id)),
});

const decorate = flow(
  connect<StateProps, DispatchProps, OwnProps, ReduxState>(
    mapStateToProps,
    mapDispatchToProps
  ),
  ValidationKeyboardHandler,
  withRouter
);

export default decorate(DocumentValidation);
