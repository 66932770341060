import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import FormatListBulletedSquare from 'mdi-react/FormatListBulletedSquareIcon';
import { FormattedMessage } from 'react-intl';
import { dataMatchingUrl } from '../../../../../constants/config';
import {
  CONTACT_FORM_LINK,
  MATCH_DATA_LINK,
} from '../../../../../constants/values';
import { boldText, gray, link } from '../../../../../lib/formaterValues';
import { buildAuthLink } from '../../../../../lib/url';
import Banner from '../../../components/Banner';
import styles from '../style.module.sass';
import FieldSection from './FieldSection';

type Props = {
  isEnabled: boolean;
  displayBanner: boolean;
};

const DataMatching = ({ isEnabled, displayBanner }: Props) => {
  const translationKey = isEnabled ? 'enabled' : 'disabled';

  return (
    <FieldSection
      title={<FormattedMessage id="containers.settings.fields.dataMatching" />}
    >
      <div className={styles.FieldSection}>
        {displayBanner && (
          <Banner className={styles.Banner}>
            <FormattedMessage
              id="containers.settings.fields.dataMatching.banner"
              values={{ boldText, gray }}
            />
          </Banner>
        )}
        <div className={styles.InnerContent}>
          <div className={styles.Oval}>
            <FormatListBulletedSquare className={styles.IconInOval} />
          </div>
          <div className={styles.InnerContentHeading}>
            <FormattedMessage id="containers.settings.fields.dataMatching.heading" />
          </div>
          <div className={clsx(styles.InnerContentText, styles.WithLink)}>
            <FormattedMessage
              id={`containers.settings.fields.dataMatching.${translationKey}.text`}
              values={{
                link: link(MATCH_DATA_LINK),
              }}
            />
          </div>
          <a
            href={
              isEnabled ? buildAuthLink(dataMatchingUrl) : CONTACT_FORM_LINK
            }
            target="_blank"
            rel="noopener noreferrer"
            className={styles.InnerContentButton}
          >
            <Button variant="contained">
              <FormattedMessage
                id={`containers.settings.fields.dataMatching.${translationKey}.button`}
              />
            </Button>
          </a>
        </div>
      </div>
    </FieldSection>
  );
};

export default DataMatching;
