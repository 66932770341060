import { Queue } from '@rossum/api-client/queues';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
} from '@rossum/ui/material';
import { uniqBy } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { UPLOAD_HASH } from '../../../constants/values';
import { WorkspaceWithQueues } from '../../document-list/helpers';
import { useUploadDocuments } from '../hooks/useUploadDocument';
import { useUploadEnabled } from '../hooks/useUploadEnabled';
import { useUploadEvents } from '../hooks/useUploadEvents';
import { DragOverlay } from './DragOverlay';
import {
  absoluteMaxSizeStyles,
  AdditionalUploadValues,
  createFileSignature,
  findQueueInWorkspace,
  UploadDocumentsPayload,
} from './helpers';
import { UploadDialog } from './UploadDialog';
import { ValuesFooter } from './ValuesFooter';

export type UploadProps = {
  children:
    | ReactNode
    | (({
        onUpload,
      }: {
        onUpload: (files: FileList | null) => void;
      }) => ReactNode);
  activeQueueUrl?: Queue['url'];
  workspacesWithQueues?: WorkspaceWithQueues[];
  areColumnsInMotion: boolean;
  pageTitle?: string;
  onUploadDialogClose?: () => void;
};

export const UploadV2 = ({
  children,
  activeQueueUrl,
  workspacesWithQueues,
  pageTitle,
  onUploadDialogClose,
  areColumnsInMotion,
}: UploadProps) => {
  const history = useHistory();
  const intl = useIntl();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const activeQueue = useMemo(
    () =>
      workspacesWithQueues && activeQueueUrl
        ? findQueueInWorkspace(workspacesWithQueues, activeQueueUrl)
        : undefined,
    [activeQueueUrl, workspacesWithQueues]
  );

  const isOnQueueLevel = !!activeQueue;
  // relying on the Queue type we have in api client was causing type inconsistencies
  // for now we're using the Queue type we have in /types/queue
  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>(
    activeQueue
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const {
    uploadDocuments,
    isLoading: isUploading,
    cancelUpload,
  } = useUploadDocuments();

  const uploadEnabled = useUploadEnabled(true);

  const acceptedMimeTypes = selectedQueue?.settings.acceptedMimeTypes || [];

  const commonInputProps = {
    type: 'file',
    accept: acceptedMimeTypes.join(),
    multiple: true,
  };

  const isUploadDialogOpen = history.location.hash === UPLOAD_HASH;

  const closeUploadDialog = useCallback(() => {
    history.replace({ ...history.location, hash: '' });

    if (!isOnQueueLevel) setSelectedQueue(undefined);

    setSelectedQueue(activeQueue);

    cancelUpload();
    setSelectedFiles([]);
  }, [history, isOnQueueLevel, activeQueue, cancelUpload]);

  const showUploadDialog = useCallback(() => {
    history.replace({ ...history.location, hash: UPLOAD_HASH });
  }, [history]);

  const onDocumentSettled = (signature: string) =>
    setSelectedFiles(prevState => {
      const filteredPrevState = prevState.filter(
        f => createFileSignature(f) !== signature
      );

      // this side effect is ugly but it's the only way what we found how to close the dialog automatically on the last file upload
      if (filteredPrevState.length === 0) {
        if (onUploadDialogClose) onUploadDialogClose();
        closeUploadDialog();
      }

      return filteredPrevState;
    });

  const filterFileFromState = (signature: string) =>
    setSelectedFiles(prevState =>
      prevState.filter(f => createFileSignature(f) !== signature)
    );

  const startUpload = ({
    payload,
    queue,
  }: {
    payload: UploadDocumentsPayload;
    queue: Queue;
  }) => {
    uploadDocuments({
      payload,
      queue,
      onEverySuccess: onDocumentSettled,
      onEveryError: onDocumentSettled,
    });
  };

  // This function handles the cases when;
  // 1. The user drags & drops onto the dashboard.
  // 2. The user clicks on upload button, clicks on choose files and selects files.
  // 3. The user clicks on upload button, and drags & drops files into the upload area.
  // 4. The trial user tries to upload, we pause the upload and we show a warning.
  const handleOnChange = useCallback(
    (fileList: FileList | null) => {
      const fileArray = fileList?.length ? Array.from(fileList) : [];

      if (fileArray.length > 0) {
        setSelectedFiles(prev => [...prev, ...fileArray]);
        if (selectedFiles.length === 0) {
          showUploadDialog();
        }
      }
    },
    [selectedFiles, showUploadDialog]
  );

  const onUploadConfirm = ({ values, metadata }: AdditionalUploadValues) => {
    if (selectedQueue) {
      const payload =
        selectedFiles.map(
          file =>
            ({
              file,
              signature: createFileSignature(file),
              name: file.name,
              queueUrl: selectedQueue.url,
              values,
              metadata,
            }) as const
        ) ?? [];

      const deduplicated = uniqBy(payload, f => f.signature);

      if (deduplicated.length) {
        startUpload({
          payload: deduplicated,
          queue: selectedQueue,
        });
      }
    }
  };

  const onEscape = useCallback(() => closeUploadDialog(), [closeUploadDialog]);

  useUploadEvents({
    onEscape,
  });

  return (
    <Box
      onDragStart={e => {
        // this prevents from dragging elements on the page (e.g. links etc.)
        e.preventDefault();
      }}
      // onDragEnter does not work because of a verified issue of Data Grid Pro: https://github.com/mui/mui-x/issues/10164
      onDragOver={e => {
        e.preventDefault();
        if (uploadEnabled && !isDragging && !areColumnsInMotion) {
          setIsDragging(true);
        }
      }}
      data-page-title={pageTitle}
      position="relative"
      flexGrow={1}
      height={1}
      sx={{ backgroundColor: 'inherit' }}
    >
      <Box
        component="input"
        disabled={!uploadEnabled}
        onDragLeave={() => setIsDragging(false)}
        onDrop={() => setIsDragging(false)}
        onClick={event => event.preventDefault()}
        width={isDragging ? 1 : 0}
        sx={{
          cursor: 'copy',
          opacity: 0,
          zIndex: theme => theme.zIndex.tooltip,
          ...absoluteMaxSizeStyles,
        }}
        // changing key clears the file input
        key={`${isUploadDialogOpen}-1`}
        onChange={e => handleOnChange(e.target.files)}
        {...commonInputProps}
      />
      {isDragging && <DragOverlay />}
      <UploadDialog
        open={isUploadDialogOpen}
        onClose={closeUploadDialog}
        fileList={selectedFiles}
        removeFileFromList={filterFileFromState}
        currentQueue={selectedQueue}
        inbox={selectedQueue?.inbox}
        uploadInputProps={{
          ...commonInputProps,
          onChange: e => handleOnChange(e.target.files),
        }}
        dialogActions={
          <DialogActions sx={{ p: 3 }}>
            <Button
              variant="outlined"
              onClick={() => closeUploadDialog()}
              color="secondary"
              data-cy="upload-docs-cancel-btn"
            >
              {intl.formatMessage({
                id: 'containers.annotationList.upload.cancel',
              })}
            </Button>
            <Button
              variant="contained"
              type="submit"
              data-cy="upload-docs-confirm-queue-and-submit-btn"
              form="upload-additional-values-form"
              disabled={isUploading}
              startIcon={isUploading && <CircularProgress size={16} />}
            >
              {intl.formatMessage({
                id: 'containers.annotationList.upload.confirmAndUpload',
              })}
            </Button>
          </DialogActions>
        }
      >
        {selectedFiles.length ? (
          <ValuesFooter
            selectedQueue={selectedQueue}
            setSelectedQueue={setSelectedQueue}
            workspacesWithQueues={workspacesWithQueues}
            onConfirm={values => onUploadConfirm(values)}
          />
        ) : null}
      </UploadDialog>
      {typeof children === 'function'
        ? children({
            onUpload: files => handleOnChange(files),
          })
        : children}
    </Box>
  );
};
