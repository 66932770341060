import { getIDFromUrl, Url } from '@rossum/api-client';
import { classes, DetailDrawer } from '@rossum/rossum-ui/DetailDrawer';
import { Delete } from '@rossum/ui/icons';
import { Box, CircularProgress, Paper, Stack } from '@rossum/ui/material';
import { DataGridPro, gridClasses } from '@rossum/ui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AttachmentDrawerDataGridStyles } from '../annotation-attachment/helpers';
import { useSimpleColumns } from '../annotation-simple-columns/hooks/useSimpleColumns';
import { CustomRow } from '../document-list/components/CustomRow';
import { SupportedAnnotationView } from '../document-list/hooks/useDashboardQuery';
import { useDeleteAnnotations } from '../document-list/selection-panel/hooks/useDeleteAnnotations';
import { UnpaidFeatureOverlay } from '../pricing/components/UnpaidFeatureOverlay';
import { duplicateHandlingFeatureSelector } from '../pricing/selectors';
import { useCanRemoveDuplicates } from './hooks/useCanRemoveDuplicates';
import {
  DUPLICATES_QUERY_KEY,
  useDocumentDuplicates,
} from './hooks/useDocumentDuplicates';
import { NoDuplicatesFound } from './NoDuplicatesFound';

type HandleSelectAnnotation = (params: {
  annotationUrl: string;
  view: SupportedAnnotationView;
}) => void;

export type UseDuplicatesDrawerState = {
  parentAnnotationUrl: Url | null;
};

type OwnProps = {
  onClose: () => void;
  afterDuplicateDelete?: (url: Url, parentUrl: Url) => void;
  handleSelectAnnotation: HandleSelectAnnotation;
};

type DuplicatesDrawerProps = UseDuplicatesDrawerState & OwnProps;

export const DuplicatesDrawer = ({
  onClose,
  afterDuplicateDelete,
  parentAnnotationUrl,
  handleSelectAnnotation,
}: DuplicatesDrawerProps) => {
  const intl = useIntl();

  const isDuplicateFeaturePurchased = useSelector(
    duplicateHandlingFeatureSelector
  );

  return (
    <DetailDrawer
      PaperProps={{ elevation: 2, sx: { width: 1000 } }}
      open={!!parentAnnotationUrl}
      title={intl.formatMessage({
        id: 'components.duplicatesDrawer.title',
      })}
      onClose={onClose}
      sx={{
        [`& .${classes.body}`]: {
          height: '100%',
        },
      }}
    >
      {!isDuplicateFeaturePurchased ? (
        <UnpaidFeatureOverlay
          title={intl.formatMessage({
            id: 'features.pricing.unpaidFeatureOverlay.title.duplicates',
          })}
          dataCy="unpaid-overlay-duplicates-drawer"
        />
      ) : (
        parentAnnotationUrl && (
          <DuplicatesDrawerContent
            parentAnnotationUrl={parentAnnotationUrl}
            onClose={onClose}
            afterDuplicateDelete={afterDuplicateDelete}
            handleSelectAnnotation={handleSelectAnnotation}
          />
        )
      )}
    </DetailDrawer>
  );
};

const DuplicatesDrawerContent = ({
  onClose: _,
  parentAnnotationUrl,
  afterDuplicateDelete,
  handleSelectAnnotation,
}: OwnProps & { parentAnnotationUrl: Url }) => {
  const { duplicates, isLoading, isError } =
    useDocumentDuplicates(parentAnnotationUrl);

  const canPerformDuplicatesActions = useCanRemoveDuplicates();

  const queryClient = useQueryClient();
  const { mutate: deleteAnnotations } = useDeleteAnnotations();

  const onDuplicateDelete = (duplicateUrl: Url, parentAnnotationUrl: Url) => {
    deleteAnnotations([getIDFromUrl(duplicateUrl)], {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [DUPLICATES_QUERY_KEY, parentAnnotationUrl],
        });
        return afterDuplicateDelete?.(duplicateUrl, parentAnnotationUrl);
      },
    });
  };

  const columns = useSimpleColumns({
    removeProps: canPerformDuplicatesActions
      ? {
          dataCyPrefix: 'duplicate',
          onRemove: duplicateUrl =>
            onDuplicateDelete(duplicateUrl, parentAnnotationUrl),
          Icon: Delete,
          disableButton: annotation => annotation.status === 'deleted',
        }
      : undefined,
    detailsColumnProps: { handleSelectAnnotation, annotations: duplicates },
    columnsLocation: 'duplicatesDrawer',
  });

  return (
    <Stack p={3}>
      <Stack height={1}>
        <DataGridPro
          loading={isLoading}
          autoHeight
          columns={columns}
          rows={!isError ? duplicates : []}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          hideFooterRowCount
          sx={{
            ...AttachmentDrawerDataGridStyles,
            [`& .${gridClasses.virtualScrollerContent}`]: {
              height: isLoading ? '720px !important' : 'auto',
              maxHeight: isLoading ? '250px' : '64vh',
            },
            [`& .${gridClasses.virtualScroller}`]: {
              overflow: isLoading ? 'hidden' : 'auto',
            },
          }}
          slotProps={{
            row: {
              disableOverlayText: false,
            },
          }}
          slots={{
            row: CustomRow,
            noRowsOverlay: NoDuplicatesFound,
            noResultsOverlay: NoDuplicatesFound,
            loadingOverlay: () => (
              <Stack
                width={1}
                height={1}
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <Box
                  width={1}
                  height={1}
                  component={Paper}
                  elevation={2}
                  position="absolute"
                  sx={{ opacity: 0.8 }}
                />
                <CircularProgress />
              </Stack>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};
