import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

type HeaderMeta = {
  buttons: ReactNode[];
  title: string | null;
  description: ReactNode;
};
const DEFAULT_STATE: HeaderMeta = {
  buttons: [],
  title: null,
  description: null,
};

const QueueSettingsContext = createContext<{
  headerMeta: HeaderMeta;
  setHeaderMeta: Dispatch<SetStateAction<HeaderMeta>>;
} | null>(null);

type QueueSettingsContextProviderProps = {
  children: ReactNode;
};

export const QueueSettingsContextProvider = ({
  children,
}: QueueSettingsContextProviderProps) => {
  const [headerMeta, setHeaderMeta] = useState<HeaderMeta>(DEFAULT_STATE);

  const values = useMemo(() => ({ headerMeta, setHeaderMeta }), [headerMeta]);

  return (
    <QueueSettingsContext.Provider value={values}>
      {children}
    </QueueSettingsContext.Provider>
  );
};

export const useQueueSettingsContext = () => {
  const context = useContext(QueueSettingsContext);

  if (context === null) {
    throw new Error(
      '`useQueueSettingsContext` must be used within a QueueSettingsContext provider'
    );
  }

  return context;
};
