import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { boolify } from '../../../constants/config';
import { UI } from '../../../types/ui';
import { RootActionType } from '../../rootActions';
import {
  confirmAnnotation,
  confirmAnnotationFulfilled,
  deleteAnnotation,
  displayAnnotation,
  postponeAnnotation,
  rejectAnnotationFulfilled,
  startAnnotation,
  startAnnotationFulfilled,
} from '../annotation/actions';
import {
  onDatapointSelection,
  recountDatapointPositionFulfilled,
  selectDatapoint,
  updateDatapointValue,
  updatePosition,
} from '../datapoints/actions';
import {
  acceptSuggestedOperationsAction,
  acceptSuggestedOperationsFulfilledAction,
} from '../datapoints/suggestedOperations/actions';
import { removeSuggestedPositionsForValue } from '../datapoints/suggestedPositionsForValue/actions';
import {
  applyColumnsToAllGrids,
  applyGridToNextPages,
  createGrid,
  deleteAllGrids,
  deleteGrid,
  deleteGridFulfilled,
  pasteGrid,
  updateGridAction,
  updateGridAfterExtractAllRows,
  updateGridFulfilled,
} from '../grid/actions';
import { NAVIGATE_TO_NEXT } from '../localStorage/actions';
import {
  alertNetworkOutage,
  cancelEditMode,
  closeSelectMenu,
  exitQueue,
  fetchEmbeddedConfigFullfiled,
  hideBlockers,
  hideDrawer,
  hideHints,
  leaveValidation,
  openSelectMenu,
  revealDrawer,
  setApplicationTabVisibility,
  setGridActionInProgress,
  setIsSuggestingPositionsForValue,
  setLeavingModalOpened,
  setNavigateToNext,
  setRepeatedStatus,
  setShoulShowLeavingModal,
  showBlockers,
  showHints,
  startEditingDatapointValue,
  startEditMode,
  startValidation,
  stopEditingDatapointValue,
  toggleFooter,
} from './actions';

const NAVIGATE_TO_NEXT_CACHED = localStorage.getItem(NAVIGATE_TO_NEXT);

const SHOULD_NAVIGATE_TO_NEXT = NAVIGATE_TO_NEXT_CACHED
  ? boolify(localStorage.getItem(NAVIGATE_TO_NEXT))
  : true;

const initialState = Immutable<UI>({
  applicationTabVisible: true,
  confirming: false,
  editModeActive: false,
  editingDatapointValue: false,
  embeddedConfig: undefined,
  fieldAutomationBlockersVisible: false,
  footerExpanded: false,
  // magicGridMultivalueId: undefined,
  navigateToNext: SHOULD_NAVIGATE_TO_NEXT,
  readOnly: false,
  repeatedRequestStatus: undefined,
  selectMenuIsOpen: false,
  shouldShowHints: true,
  drawerConfig: {
    isOpen: false,
  },
  showNetworkAlert: false,
  showRejectionModal: false,
  suggestingOperations: false,
  isSuggestingPositionsForValue: false,
  leavingModal: { isOpen: false, shouldShow: false, onLeave: undefined },
  actionInProgress: false,
  editingBbox: false,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(startAnnotationFulfilled):
    case getType(leaveValidation):
    case getType(startValidation):
      return state
        .set('actionInProgress', false)
        .set('readOnly', false)
        .set('fieldAutomationBlockersVisible', false)
        .set('suggestingOperations', false);

    case getType(confirmAnnotationFulfilled):
    case getType(postponeAnnotation):
    case getType(displayAnnotation):
    case getType(deleteAnnotation): {
      // skipRedirect doesn't exist in DISPLAY_ANNOTATION action
      return 'skipRedirect' in action.meta && action.meta.skipRedirect
        ? state
        : state.set('readOnly', true).set('confirming', false);
    }

    case getType(setIsSuggestingPositionsForValue): {
      return state.set('isSuggestingPositionsForValue', action.payload);
    }

    case getType(removeSuggestedPositionsForValue):
    case getType(updateDatapointValue):
    case getType(onDatapointSelection): {
      return state.set('isSuggestingPositionsForValue', false);
    }

    case getType(showHints):
      return state.set('shouldShowHints', true);

    case getType(hideHints):
      return state.set('shouldShowHints', false);

    case getType(startEditMode):
      return state.set('editModeActive', true);

    case getType(cancelEditMode):
      return state.set('editModeActive', false);

    case getType(setNavigateToNext):
      return state.set('navigateToNext', action.payload);

    case getType(startEditingDatapointValue):
      return state.set('editingDatapointValue', true);

    case getType(stopEditingDatapointValue):
      return state.set('editingDatapointValue', false);

    case getType(toggleFooter):
      return state.set('footerExpanded', !state.footerExpanded);

    case getType(openSelectMenu):
      return state.set('selectMenuIsOpen', true);

    case getType(closeSelectMenu):
      return state.set('selectMenuIsOpen', false);

    case getType(selectDatapoint): {
      return state
        .set('editingDatapointValue', false)
        .set('selectMenuIsOpen', false);
    }

    case getType(alertNetworkOutage):
      return state.set('showNetworkAlert', true);

    case getType(fetchEmbeddedConfigFullfiled):
      return state.set('embeddedConfig', action.payload);

    case getType(confirmAnnotation):
      return state.set('confirming', true);

    case getType(startAnnotation):
      return state.set('confirming', false);

    case getType(setRepeatedStatus): {
      return state.set('repeatedRequestStatus', action.payload);
    }

    case getType(setApplicationTabVisibility): {
      return state.set('applicationTabVisible', action.payload);
    }

    case getType(acceptSuggestedOperationsAction): {
      return state.set('suggestingOperations', true);
    }

    case getType(acceptSuggestedOperationsFulfilledAction): {
      return state.set('suggestingOperations', false);
    }

    case getType(revealDrawer):
      return state.setIn(['drawerConfig', 'isOpen'], true);

    case getType(hideDrawer):
    case getType(exitQueue):
      return state.setIn(['drawerConfig', 'isOpen'], false);

    case getType(rejectAnnotationFulfilled):
      return state.set('readOnly', true);

    case getType(showBlockers):
      return state
        .set('fieldAutomationBlockersVisible', true)
        .set('readOnly', true);

    case getType(hideBlockers): {
      const { readOnly } = action.meta;
      return state
        .set('fieldAutomationBlockersVisible', false)
        .set('readOnly', readOnly);
    }

    case getType(setShoulShowLeavingModal):
      return state.setIn(['leavingModal', 'shouldShow'], action.payload);

    case getType(setLeavingModalOpened): {
      return state
        .setIn(['leavingModal', 'isOpen'], action.payload)
        .setIn(['leavingModal', 'onLeave'], action.meta?.onLeave);
    }
    case getType(updateGridAction):
      return state.set('actionInProgress', action.meta.actionType || true);

    // TODO: Get rid of this when rewriting batch operations on grid
    case getType(setGridActionInProgress): {
      const { payload } = action;
      return state.set('actionInProgress', payload);
    }
    case getType(createGrid):
    case getType(applyGridToNextPages):
    case getType(deleteGrid):
    case getType(deleteAllGrids):
    case getType(pasteGrid):
    case getType(applyColumnsToAllGrids):
    case getType(updateGridAfterExtractAllRows): {
      return state.set('actionInProgress', true);
    }
    case getType(updateGridFulfilled):
    case getType(deleteGridFulfilled): {
      return state.set('actionInProgress', false);
    }
    case getType(updatePosition): {
      return state
        .set('editingBbox', true)
        .set('isSuggestingPositionsForValue', false);
    }
    case getType(recountDatapointPositionFulfilled): {
      return state.set('editingBbox', false);
    }
    default:
      return state;
  }
};

export default reducer;
