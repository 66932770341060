import { Select } from '@rossum/rossum-ui/Select';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

const ControlledSelect = <T extends FieldValues>({
  control,
  name,
  options,
  placeholder,
}: {
  options: {
    value: string;
    label: string;
  }[];
  control: Control<T>;
  name: Path<T>;
  placeholder: string;
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return (
    <Select
      value={value as string}
      onChange={onChange}
      options={options}
      title={name}
      id={name}
      placeholder={placeholder}
    />
  );
};

export default ControlledSelect;
