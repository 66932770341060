import { Box, TableCell } from '@rossum/ui/material';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FooterDatapoint from '../../components/Datapoint/FooterDatapoint';
import { findDatapointById } from '../../redux/modules/datapoints/navigation/findDatapointIndex';
import { datapointPathSelector } from '../../redux/modules/datapoints/selector';
import { schemaMapSelector } from '../../redux/modules/schema/schemaMapSelector';
import { AnyDatapointSchema } from '../../types/schema';
import { State } from '../../types/state';
import { BatchUpdateEvent } from './useBatchUpdate';
import {
  calculateWidthStyle,
  commonCellStyle,
  includedInBatchUpdateStyle,
} from './utils';

export const FooterDatapointTableCell = ({
  id,
  tupleId,
  column,
  onBatchUpdateEvent,
  isIncludedInBatchUpdate,
  readOnly,
  isGhostRow,
}: {
  id: number;
  tupleId: number;
  column: AnyDatapointSchema;
  onBatchUpdateEvent: (event: BatchUpdateEvent) => void;
  isIncludedInBatchUpdate: boolean;
  readOnly: boolean;
  isGhostRow: boolean;
}) => {
  const schemaMap = useSelector(schemaMapSelector);
  const datapoint = useSelector((state: State) => {
    return findDatapointById(state.datapoints.content, id);
  });

  // if datapoint does not exist, state is invalid anyway and we render nothing
  const schema = schemaMap.get(datapoint?.schemaId ?? '');

  const isLoading = useSelector((state: State) =>
    state.datapoints.loadingDatapointIds.includes(id)
  );
  const message = useSelector((state: State) => state.datapoints.messages[id]);

  const [isActive, path] = useSelector((state: State) => {
    const [sectionId, multivalueId, _, datapointIdInPath] =
      datapointPathSelector(state);

    const result: [boolean, number[]] = [
      datapointIdInPath === datapoint?.id,
      // if datapoint does not exist, state is invalid and we render nothing anyway
      [sectionId, multivalueId, tupleId, datapoint?.id ?? 0],
    ];

    return result;
  }, isEqual);

  const startDrag = useCallback(() => {
    onBatchUpdateEvent({ type: 'start', tupleId, columnSchemaId: column.id });
  }, [onBatchUpdateEvent, column, tupleId]);

  const endDrag = useCallback(() => {
    onBatchUpdateEvent({ type: 'end' });
  }, [onBatchUpdateEvent]);

  if (!datapoint) {
    return null;
  }

  return (
    <TableCell
      key={datapoint.id}
      scope="row"
      sx={{
        padding: 0,
        pl: '1px',
        position: 'relative',
        ...commonCellStyle,
      }}
      style={calculateWidthStyle(column)}
      data-schema-id={column.id}
    >
      {isIncludedInBatchUpdate ? <Box sx={includedInBatchUpdateStyle} /> : null}
      <FooterDatapoint
        active={isActive}
        data={datapoint}
        isLoading={isLoading}
        startDrag={startDrag}
        endDrag={endDrag}
        /* AFI TODO */
        hideDragger={/* tuples.length <= 1 || */ schema?.type === 'button'}
        inFooter
        isSimpleMultivalue={false}
        readOnly={readOnly}
        myPath={path}
        message={message}
        schema={schema}
        isGhostRow={isGhostRow}
      />
    </TableCell>
  );
};
