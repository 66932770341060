import { compact } from 'lodash';
import { useEffect } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { SchemaObjectFormData } from '../types';

type PropNameTypes = 'canExport' | 'hidden';

export const usePropagationInMultivalues = (
  {
    watch,
    setValue,
  }: {
    watch: UseFormWatch<SchemaObjectFormData>;
    setValue: UseFormSetValue<SchemaObjectFormData>;
  },
  propName: PropNameTypes
) => {
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      // 'type' is set to change, when the new value was set from the form input.
      if (name === propName && type === 'change') {
        const expectedChildValue = values[propName]!;

        if (
          values.fieldType === 'simple-multivalue' &&
          values.simpleMultivalueChildren
        ) {
          if (
            values.simpleMultivalueChildren[propName] !== expectedChildValue
          ) {
            setValue(
              `simpleMultivalueChildren.${propName}`,
              expectedChildValue
            );
          }
        }

        if (
          values.fieldType === 'table-multivalue' &&
          values.children?.children
        ) {
          values.children.children.forEach((child, i) => {
            if (child![propName] !== expectedChildValue) {
              setValue(
                `children.children.${i}.${propName}`,
                expectedChildValue
              );
            }
          });
        }
      }

      if (
        values.fieldType === 'simple-multivalue' &&
        values.simpleMultivalueChildren &&
        name === `simpleMultivalueChildren.${propName}` &&
        type === 'change'
      ) {
        const expectedParentValue = values.simpleMultivalueChildren[propName]!;

        if (values[propName] !== expectedParentValue) {
          setValue(propName, expectedParentValue);
        }
      }

      const patternAndCondition = {
        canExport: {
          pattern: /^children\.children\.\d+\.canExport/,
          condition: (
            conditionData: Array<Record<string, unknown>>,
            searchName: PropNameTypes
          ) => conditionData.some(child => child![searchName]),
        },
        hidden: {
          pattern: /^children\.children\.\d+\.hidden/,
          condition: (
            conditionData: Array<Record<string, unknown>>,
            searchName: PropNameTypes
          ) => conditionData.every(child => child![searchName]),
        },
      };

      if (
        values.fieldType === 'table-multivalue' &&
        values.children?.children &&
        name &&
        (patternAndCondition[propName].pattern.test(name) ||
          name === 'children.children')
      ) {
        // Keep the existing visibility when there are no children.
        // This ensures that initially, a table multivalue is visible even if there are no children
        const expectedParent =
          values.children.children.length === 0
            ? values[propName]!
            : patternAndCondition[propName].condition(
                compact(values.children.children) ?? [],
                propName
              );

        if (values[propName] !== expectedParent) {
          setValue(propName, expectedParent);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, propName]);
};
