import { endpoints } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';

const VALIDATE_SCHEMA_KEY = 'validate-schema';

export const useValidateSchema = () => {
  return useMutation([VALIDATE_SCHEMA_KEY], (payload: Schema) =>
    api.request(endpoints.schemas.validate(payload))
  );
};
