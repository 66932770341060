import { useDroppable } from '@dnd-kit/core';
import { Queue } from '@rossum/api-client/queues';
import { ArrowDropDownRounded } from '@rossum/ui/icons';
import { MoreVert as MoreVertIcon } from '@rossum/ui/icons';
import {
  Box,
  Chip,
  chipClasses,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  listItemIconClasses,
  Stack,
  styled,
} from '@rossum/ui/material';
import { List } from '@rossum/ui/material';
import { ListItemButton } from '@rossum/ui/material';
import { ListItemText } from '@rossum/ui/material';
import clsx from 'clsx';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
  useRenameQueue,
  useSetQueueName,
} from '../../../../business/queues/useRenameQueue';
import { useRenameWorkspace } from '../../../../business/workspaces/useRenameWorkspace';
import { usePrevious } from '../../../../lib/hooks';
import { WORKSPACES } from '../../../../redux/modules/localStorage/actions';
import { LIST_ITEM_PADDINGS } from '../../../document-list/sidebar/styles';
import { sidebarIdleState, SidebarState } from '../SidebarState';
import { DraggableQueue } from './DraggableQueue';
import EditName from './EditName';

const PREFIX_WQ = 'WQ';
const PREFIX_W = 'W';
const PREFIX_Q = 'Q';

const classes = {
  hasDropdown: `${PREFIX_WQ}-hasDropdown`,
  root: `${PREFIX_WQ}-root`,
  editable: `${PREFIX_WQ}-editable`,
  active: `${PREFIX_Q}-active`,
  editIcon: `${PREFIX_WQ}-editIcon`,
  emptyWorkspace: `${PREFIX_W}-empty`,
  emptyMessage: `${PREFIX_W}-emptyMessage`,
};

const StyledListItem = styled(ListItem)(() => ({
  [`.${listItemIconClasses.root}`]: { minWidth: '30px' },
  [`&.${classes.editable}`]: {
    // Use opacity so that the element is always available (for tests / accessibility)
    [`.${classes.editIcon}`]: {
      opacity: 0,
    },
    // do not change order of :focus-within, .active and :hover for proper tabIndexing
    [`&:focus-within .${classes.editIcon}`]: {
      opacity: 1,
    },
    [`&.${classes.active} .${classes.editIcon}`]: {
      opacity: 0,
    },
    [`&:hover .${classes.editIcon}`]: {
      opacity: 1,
    },
    [`&:focus-within .${chipClasses.root}`]: {
      display: 'none',
    },
    [`&.${classes.active} .${chipClasses.root}`]: {
      display: 'flex',
    },
    [`&:hover .${chipClasses.root}`]: {
      display: 'none',
    },
    [`.${classes.editIcon}.${classes.hasDropdown}`]: {
      opacity: 1,
    },
  },
  [`&:hover .${classes.emptyWorkspace}`]: {
    display: 'none',
  },
  [`&:hover .${classes.emptyMessage}`]: {
    display: 'inherit',
  },
}));

type Workspace = {
  active: boolean;
  currentQueueId?: number;
  url: string;
  id: number;
  name: string;
  queues: Array<Queue>;
  selectQueue: (queueId: number) => void;
  toReviewCount: number | undefined;
  editable: boolean;
  sidebarState: SidebarState;
  setSidebarState: Dispatch<SetStateAction<SidebarState>>;
  setQueueRef?: (el: HTMLElement | null) => void;
  searchValue: string;
};

const Workspace = forwardRef(
  (
    {
      id,
      name,
      url,
      currentQueueId,
      queues,
      toReviewCount,
      selectQueue,
      active,
      editable,
      sidebarState,
      setSidebarState,
      setQueueRef,
      searchValue,
    }: Workspace,
    ref
  ) => {
    const { isOver, setNodeRef } = useDroppable({
      id: `droppable-workspace-${id}`,
      data: { url },
    });

    const queueRef = useRef<HTMLLIElement | null>(null);

    const intl = useIntl();

    const { mutate: renameQueue } = useRenameQueue();
    const { setQueueName } = useSetQueueName();

    const { mutate: renameWorkspace } = useRenameWorkspace();

    const getCachedWorkspaceState = useCallback((id?: number) => {
      const workspaces = JSON.parse(localStorage.getItem(WORKSPACES) || '{}');
      return id ? workspaces[id] : workspaces;
    }, []);

    const updateWorkspaceName = useCallback(
      (id: number, newName: string) =>
        renameWorkspace({ workspaceId: id, newName: newName.trim() }),
      [renameWorkspace]
    );

    const updateQueueName = useCallback(
      (id: number, newName: string) => {
        const payload = { queueId: id, newName: newName.trim() };
        renameQueue(payload);
        setQueueName(payload);
      },
      [renameQueue, setQueueName]
    );

    const prevQueueId = usePrevious(currentQueueId);

    const getDefaultExpandState = useCallback(
      () => active || Boolean(getCachedWorkspaceState(id)),
      [active, getCachedWorkspaceState, id]
    );

    const [open, setOpen] = useState(getDefaultExpandState());

    useEffect(() => {
      if ((prevQueueId !== currentQueueId && active) || !!searchValue) {
        setOpen(true);
      }

      if (!searchValue) setOpen(getDefaultExpandState());
    }, [
      active,
      currentQueueId,
      getDefaultExpandState,
      prevQueueId,
      searchValue,
    ]);

    const cacheWorkspaceState = useCallback(
      (state: boolean) =>
        localStorage.setItem(
          WORKSPACES,
          JSON.stringify({
            ...getCachedWorkspaceState(),
            [id]: state,
          })
        ),
      [getCachedWorkspaceState, id]
    );

    const handleQueueClick = (queueId: number) => () => {
      if (currentQueueId !== queueId) selectQueue(queueId);
    };

    const workspaceRef = useRef<HTMLDivElement>(null);

    const handleClick = useCallback(() => {
      setOpen(!open);
      cacheWorkspaceState(!open);
      workspaceRef.current?.blur();
    }, [cacheWorkspaceState, open]);

    const onDropdownClick = (target: Element, queueId?: number | undefined) => {
      setSidebarState({
        action: 'dropdown',
        workspaceId: id,
        workspaceUrl: url,
        queueId,
        anchorEl: target,
      });
    };

    const rowConfig = (state: SidebarState, queueId?: number | undefined) => ({
      chipsVisible:
        state.action === 'idle' ||
        ('queueId' in state && state.queueId !== queueId),
      editingVisible: state.action === 'editing' && state.queueId === queueId,
      hasDropdown: state.action === 'dropdown' && state.queueId === queueId,
    });

    const workspaceRowConfig = rowConfig(sidebarState);

    return (
      <Stack
        ref={(el: HTMLDivElement | null) => {
          // Forwarded ref for scroll position.
          if (typeof ref === 'function') {
            ref(el);
          }
          // Ref for drag & drop.
          setNodeRef(el);
        }}
      >
        <Divider />
        <StyledListItem
          sx={{
            boxShadow: t =>
              isOver
                ? `0 0 1px 3px ${t.palette.primary.main} inset`
                : undefined,
          }}
          key={name}
          className={clsx(classes.root, editable && classes.editable)}
          secondaryAction={
            !workspaceRowConfig.editingVisible && (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'grid',
                  '& > *': {
                    gridColumn: 1,
                    gridRow: 1,
                  },
                }}
              >
                {!!toReviewCount && workspaceRowConfig.chipsVisible && (
                  <Chip
                    label={toReviewCount}
                    size="tiny"
                    sx={{ fontWeight: t => t.typography.fontWeightBold }}
                  />
                )}
                {editable && (
                  <IconButton
                    className={clsx(
                      classes.editIcon,
                      workspaceRowConfig.hasDropdown ? classes.hasDropdown : ''
                    )}
                    size="small"
                    onClick={e => onDropdownClick(e.currentTarget)}
                    data-cy="workspace-menu-btn"
                  >
                    <MoreVertIcon fontSize="small" color="action" />
                  </IconButton>
                )}
              </Box>
            )
          }
          disablePadding
        >
          {!workspaceRowConfig.editingVisible ? (
            <ListItemButton
              ref={workspaceRef}
              data-cy="workspace"
              onClick={handleClick}
              disableRipple={!queues.length}
              sx={{
                px: LIST_ITEM_PADDINGS.px,
                py: LIST_ITEM_PADDINGS.py,
                cursor: !queues.length ? 'auto' : '',
              }}
              role={undefined}
            >
              <ListItemIcon>
                {queues.length ? (
                  <ArrowDropDownRounded
                    sx={{
                      mr: 1,
                      color: t => t.palette.secondary.main,
                      transform: `rotate(${open ? 0 : -90}deg)`,
                      transition: t =>
                        t.transitions.create(['all'], { duration: 200 }),
                    }}
                  />
                ) : null}
              </ListItemIcon>
              <ListItemText
                data-cy="sidebar-heading"
                primary={name}
                className={!queues.length ? classes.emptyWorkspace : ''}
                sx={{ m: 0, pr: 1.5 }}
                primaryTypographyProps={{
                  color: 'text.primary',
                  fontWeight: t => t.typography.fontWeightBold,
                  fontSize: t => t.typography.pxToRem(14),
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
              <ListItemText
                className={!queues.length ? classes.emptyMessage : ''}
                primary={intl.formatMessage({
                  id: 'containers.annotationList.sidebar.emptytooltip',
                })}
                sx={{ m: 0, display: 'none' }}
                primaryTypographyProps={{
                  color: 'text.disabled',
                  fontSize: t => t.typography.pxToRem(14),
                }}
              />
            </ListItemButton>
          ) : (
            <EditName
              ContainerProps={{
                sx: { paddingLeft: '30px', pt: 1.1, pb: 1.1 },
                spacing: 1.5,
              }}
              originalName={name}
              onClose={() => setSidebarState(sidebarIdleState)}
              onSubmit={name => updateWorkspaceName(id, name)}
            />
          )}
        </StyledListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {queues.map(
              ({
                id: queueId,
                name: queueName,
                counts: { to_review = 0, reviewing = 0, importing = 0 },
              }) => {
                const queueCount = to_review + reviewing + importing;
                const queueRowConfig = rowConfig(sidebarState, queueId);

                return (
                  <StyledListItem
                    ref={queueRef}
                    key={`${queueId}-${queueName}`}
                    className={clsx(
                      classes.root,
                      editable && classes.editable,
                      currentQueueId === queueId && classes.active
                    )}
                    onClick={e => setQueueRef?.(e.currentTarget)}
                    secondaryAction={
                      !queueRowConfig.editingVisible && (
                        <Box
                          sx={{
                            display: 'grid',
                            alignItems: 'center',
                            '& > *': {
                              gridColumn: 1,
                              gridRow: 1,
                            },
                          }}
                        >
                          {!!queueCount && queueRowConfig.chipsVisible && (
                            <Chip
                              label={queueCount}
                              size="tiny"
                              sx={{
                                fontWeight: t =>
                                  currentQueueId === queueId
                                    ? t.typography.fontWeightBold
                                    : undefined,
                              }}
                            />
                          )}
                          {editable && (
                            <IconButton
                              className={clsx(
                                classes.editIcon,
                                queueRowConfig.hasDropdown
                                  ? classes.hasDropdown
                                  : ''
                              )}
                              size="small"
                              onClick={e => {
                                e.stopPropagation();
                                onDropdownClick(e.currentTarget, queueId);
                              }}
                              data-cy="queue-menu-btn"
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Box>
                      )
                    }
                    disablePadding
                    sx={{
                      height: 40,
                    }}
                  >
                    {!queueRowConfig.editingVisible ? (
                      <DraggableQueue
                        workspaceUrl={url}
                        onClick={handleQueueClick(queueId)}
                        key={queueId}
                        currentQueueId={currentQueueId}
                        queueId={queueId}
                        queueName={queueName}
                      >
                        <ListItemText
                          primary={queueName}
                          sx={{ m: 0 }}
                          primaryTypographyProps={{
                            fontSize: t => t.typography.pxToRem(14),
                            pl: 5,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        />
                      </DraggableQueue>
                    ) : (
                      <EditName
                        ContainerProps={{
                          sx: { paddingLeft: '40px', pt: 0.5, pb: 0.5 },
                          spacing: 1,
                        }}
                        originalName={queueName}
                        onClose={() => setSidebarState(sidebarIdleState)}
                        onSubmit={name => updateQueueName(queueId, name)}
                        key={queueId}
                      />
                    )}
                  </StyledListItem>
                );
              }
            )}
          </List>
        </Collapse>
      </Stack>
    );
  }
);

export default Workspace;
