import { TextField } from '@rossum/ui/material';
import { VALUE_INPUT_WIDTH } from '../../constants';
import { InputSelectorProps } from './types';

export const TextFieldInput = ({
  filterContext,
  handleFilterChange,
  autoFocus,
}: InputSelectorProps) => {
  // we expect the value to be always string for this component - however the value will come from the url directly.
  // controlledValue is to restrict the 'broken' values to an empty string
  const controlledValue =
    typeof filterContext.value === 'string' ? filterContext.value : '';

  return (
    <TextField
      sx={{ width: VALUE_INPUT_WIDTH }}
      onChange={e => {
        handleFilterChange({ ...filterContext, value: e.target.value });
      }}
      autoFocus={autoFocus}
      value={controlledValue}
      size="small"
    />
  );
};
