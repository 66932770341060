import { Check, ContentCopy } from '@rossum/ui/icons';
import { Button, Grow, Stack, Typography } from '@rossum/ui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SwitchTransition } from 'react-transition-group';
import { NonAdminRestrictor } from '../../../../components/Restrictors';
import { ROSSUM_EMAIL_INBOX } from '../../../../constants/values';
import { link } from '../../../../lib/formaterValues';
import { useCopyToClipboard } from '../../../../ui/copy-to-clipboard/useCopyToClipboard';
import { useFeatureFlag } from '../../../../unleash/useFeatureFlag';

const Headline = ({ children }: { children: React.ReactNode }) => (
  <Typography data-cy="no-results-title" variant="h5" color="text.primary">
    {children}
  </Typography>
);

type SuggestInboxProps = {
  inboxEmail?: string;
  queueId?: number;
};

export const SuggestInbox = ({ inboxEmail, queueId }: SuggestInboxProps) => {
  const intl = useIntl();
  const { copied, copyToClipboard } = useCopyToClipboard(inboxEmail || '');

  const isNewQueueSettingsLayout = useFeatureFlag(
    'ac-4366-queue-settings-layout-v2'
  );

  const route = isNewQueueSettingsLayout
    ? `/queues/${queueId}/settings/emails`
    : `/settings/queues/${queueId}/emails`;

  return (
    <Stack spacing={2} alignItems="center">
      <Stack spacing={0.5} justifyContent="center" alignItems="center">
        <Headline>
          {intl.formatMessage({
            id: `containers.annotationList.empty.suggestInbox.title`,
          })}
        </Headline>
        <Typography variant="caption" color="text.secondary">
          {intl.formatMessage(
            {
              id: 'containers.annotationList.empty.suggestInbox.sendEmail',
            },
            {
              link: link(ROSSUM_EMAIL_INBOX, { color: 'inherit' }),
            }
          )}
        </Typography>
      </Stack>

      {inboxEmail ? (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          onClick={copyToClipboard}
          sx={{ cursor: 'pointer' }}
          data-cy="copy-email-address"
        >
          <Button variant="contained" disabled>
            <Stack direction="row" sx={{ minWidth: 0 }}>
              <Typography
                variant="button"
                fontSize="small"
                color="text.primary"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  flexShrink: 1,
                }}
              >
                {inboxEmail}
              </Typography>
            </Stack>
          </Button>

          <Button
            color={copied ? 'success' : 'primary'}
            variant={copied ? 'outlined' : 'contained'}
            size="medium"
            sx={{ minWidth: '134px' }}
          >
            <SwitchTransition>
              <Grow
                key={copied ? 'copied' : 'copy'}
                appear
                unmountOnExit
                timeout={100}
              >
                <Stack direction="row" spacing={1}>
                  {copied ? (
                    <Check color="success" fontSize="small" />
                  ) : (
                    <ContentCopy fontSize="small" color="inherit" />
                  )}
                  <Typography
                    variant="button"
                    fontSize="small"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {intl.formatMessage({
                      id: copied
                        ? 'containers.annotationList.empty.suggestInbox.copyInfo'
                        : 'containers.annotationList.empty.suggestInbox.copyEmail',
                    })}
                  </Typography>
                </Stack>
              </Grow>
            </SwitchTransition>
          </Button>
        </Stack>
      ) : (
        <Stack spacing={2} alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            {intl.formatMessage({
              id: 'containers.annotationList.empty.suggestInbox.noInbox.hint',
            })}
          </Typography>
          {queueId && (
            <NonAdminRestrictor
              restrictComponent={
                <Typography variant="subtitle2" color="text.secondary">
                  {intl.formatMessage({
                    id: 'containers.annotationList.empty.suggestInbox.noInbox.restricted',
                  })}
                </Typography>
              }
            >
              <Button
                variant="contained"
                component={Link}
                to={route}
                sx={{
                  '&:hover': {
                    color: theme => theme.palette.primary.contrastText,
                  },
                }}
              >
                {intl.formatMessage({
                  id: 'containers.annotationList.empty.suggestInbox.noInbox.button',
                })}
              </Button>
            </NonAdminRestrictor>
          )}
        </Stack>
      )}
    </Stack>
  );
};
