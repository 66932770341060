import { Stack } from '@rossum/ui/material';
import DedicatedEnginesList from '../components/DedicatedEnginesList';
import ExtractorsList from '../components/ExtractorsList';
import GenericEnginesList from '../components/GenericEnginesList';
import SplittersList from '../components/SplittersList';

export const EngineListPage = ({
  auroraConfigEnabled,
}: {
  auroraConfigEnabled?: true;
}) => {
  return (
    <Stack spacing={8} p={4}>
      {auroraConfigEnabled && <ExtractorsList />}
      {auroraConfigEnabled && <SplittersList />}
      <DedicatedEnginesList />
      <GenericEnginesList />
    </Stack>
  );
};
