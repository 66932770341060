import { endpoints, withSideload } from '@rossum/api-client';
import { useInfiniteQuery } from '@tanstack/react-query';
import { absoluteApiUrl } from '../../../../../constants/config';
import { api } from '../../../../../lib/apiClient';
import { asScalar, parse } from '../../../../../lib/url';
import { encodeMqlQueryItem } from '../../../../document-list/filtering/mql';
import { ACCESSIBLE_ANNOTATIONS_FILTER_ITEM } from '../../../../pricing/utils';

export const FORMULA_PREVIEW_KEY = 'formula-preview';
export const PAGE_SIZE = 5;

const query = {
  pageSize: PAGE_SIZE,
  ordering: '-created_at',
};

export const useGetAnnotationsForPreview = (
  currentFormulaId: string | undefined,
  currentQueueId: number | undefined
) => {
  const currentQueueUrl = currentQueueId
    ? `${absoluteApiUrl}/queues/${currentQueueId}`
    : undefined;

  const mqlQueryPayload = {
    query: {
      $and: [
        {
          status: {
            $in: ['to_review', 'reviewing'],
          },
        },
        {
          queue: { $in: [currentQueueUrl] },
        },
        ...encodeMqlQueryItem(ACCESSIBLE_ANNOTATIONS_FILTER_ITEM),
      ],
    },
  };

  const annotationsQuery = useInfiniteQuery({
    queryKey: [
      FORMULA_PREVIEW_KEY,
      'annotations',
      currentFormulaId,
      currentQueueUrl,
    ] as const,
    queryFn: ({ pageParam: searchAfter }) =>
      api.request(
        withSideload(
          endpoints.annotations.search(
            { ...query, searchAfter },
            mqlQueryPayload
          ),
          { documents: true }
        )
      ),
    getNextPageParam: data => {
      if (data.pagination.next) {
        return asScalar(parse(data.pagination.next).search_after);
      }

      return undefined;
    },
    cacheTime: 0, // clears data on closing drawer
    enabled: !!currentQueueId,
  });

  return annotationsQuery;
};
