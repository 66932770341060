import { z, ZodType } from 'zod';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Queue } from '../models/queue';
import { queueSchema } from '../models/queue.schema';
import { queueListQuerySchema } from './list.schema';

export type QueueListQuery = {
  pageSize?: number;
  page?: number;
  ordering?: string[];
  id?: number[];
  deleting?: boolean;
  name?: string;
  dedicatedEngine?: number;
  genericEngine?: number;
  engine?: number;
};

/**
 * @noSchema
 */
type WithFields<T, F> = T & { fields?: F[] };

/**
 * @noSchema
 */
export type QueueListQueryWithFields<T extends keyof Queue = keyof Queue> =
  WithFields<QueueListQuery, T>;

export const list = <FieldsT extends keyof Queue = keyof Queue>(
  query: WithFields<QueueListQuery, FieldsT> = {}
) => {
  const fieldsObject = query.fields?.reduce<Partial<Record<keyof Queue, true>>>(
    (acc, curr) => Object.assign(acc, { [curr]: true }),
    {}
  );

  return {
    endpoint: `/queues`,
    method: 'GET',
    responseSchema: listResponse(
      fieldsObject ? queueSchema.pick(fieldsObject) : queueSchema
    ),
    query: { ...query, deleting: false },
    querySchema: queueListQuerySchema.and(
      z.object({
        fields: z.array(z.string()).optional(),
      })
    ) as unknown as ZodType<WithFields<QueueListQuery, FieldsT>>,
  } as const;
};
