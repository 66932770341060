import {
  DatapointData,
  MultivalueData,
  SectionData,
  TupleData,
} from '@rossum/api-client/annotations';
import * as R from 'remeda';

export type FlatContent = {
  id: number;
  url: string;
  type: 'datapoint' | 'tuple' | 'multivalue' | 'section';
  schemaId: string;
  parentId: number | null;
  content:
    | {
        value?: string | null;
        page?: number | null;
        position?: [number, number, number, number] | null;
      }
    | undefined;
};

export const flattenContent = (
  content: Array<
    SectionData | DatapointData | TupleData | MultivalueData
  > | null,
  parentId: number | null
): FlatContent[] =>
  content?.flatMap<FlatContent>(item => {
    if (!item.id || !item.url || !item.schemaId) {
      return [];
    }

    if (item.category === 'datapoint') {
      const content: FlatContent['content'] = item.content
        ? {
            value: item.content.value,
            page: item.content.page,
            position: item.content.position,
          }
        : undefined;
      return [
        {
          id: item.id,
          url: item.url,
          type: 'datapoint' as const,
          schemaId: item.schemaId,
          parentId,
          content,
        },
      ];
    }

    if (item.category === 'section') {
      return [
        {
          id: item.id,
          url: item.url,
          type: 'section' as const,
          schemaId: item.schemaId,
          parentId,
          content: undefined,
        },
        ...flattenContent(item.children, item.id),
      ];
    }

    if (item.category === 'tuple') {
      return [
        {
          id: item.id,
          url: item.url,
          type: 'tuple' as const,
          schemaId: item.schemaId,
          parentId,
          content: undefined,
        },
        ...flattenContent(item.children.filter(R.isDefined), item.id),
      ];
    }

    if (item.category === 'multivalue') {
      return [
        {
          id: item.id,
          url: item.url,
          type: 'multivalue' as const,
          schemaId: item.schemaId,
          parentId,
          content: undefined,
        },
        ...flattenContent(item.children, item.id),
      ];
    }

    return [];
  }) ?? [];
