import { ApplyLabelPayload } from '@rossum/api-client/labels';
import { Label } from '@rossum/api-client/labels';
import {
  CircularProgress,
  Dialog,
  MenuItem,
  MenuList,
  Popover,
  Stack,
} from '@rossum/ui/material';
import { useIsMutating } from '@tanstack/react-query';
import { intersection, without } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { RoleRestrictor } from '../../components/Restrictors';
import { isNotNullOrUndefined } from '../../lib/typeGuards';
import { asArray, parse } from '../../lib/url';
import { throwError } from '../../redux/modules/messages/actions';
import { useMenuPosition } from '../document-list/hooks/useMenuPosition';
import {
  MENU_MAX_HEIGHT,
  MENU_MIN_HEIGHT,
  MENU_WIDTH,
} from './components/constants';
import EmptyListLabels from './components/EmptyListLabels';
import { CreateLabelDialog } from './components/LabelDialogs';
import { LabelMenuActions } from './components/LabelMenuActions';
import LabelMenuItem from './components/LabelMenuItem';
import Loading from './components/Loading';
import { useRequestUnpaginatedLabels } from './hooks/useRequestLabels';
import { MUTATION_KEY_LABELS_APPLY } from './hooks/useRequestLabelsApply';

type OnLabelArgument = {
  payload: ApplyLabelPayload;
  onSuccess: () => void;
  onError: () => void;
  labelsInQuery: string[];
};

export type OnLabelAction = (args: OnLabelArgument) => void;

type Props<T extends { labels: Array<string>; url: string }> = {
  onLabel: OnLabelAction;
  selectedAnnotations: Array<T>;
  children: ({ isHovered }: { isHovered: boolean }) => React.ReactNode;
  // TODO: nestedMenu is temporary solution whole dropdown will be replaced
  // with dialog here: https://rossumai.atlassian.net/browse/AC-3435
  nestedMenu?: boolean;
};

const defaultOperations: Required<ApplyLabelPayload['operations']> = {
  add: [],
  remove: [],
};

const BatchLabels = <T extends { labels: Array<string>; url: string }>({
  children,
  selectedAnnotations,
  onLabel,
  nestedMenu,
}: Props<T>) => {
  const ref = useRef(null);
  const initialState = useMemo(() => {
    const annotationsLabels = selectedAnnotations.map(({ labels }) => labels);

    const allChecked = intersection(...annotationsLabels);
    const indeterminates = without(annotationsLabels.flat(), ...allChecked);

    return {
      indeterminates,
      allChecked,
    };
  }, [selectedAnnotations]);

  const [operations, setOperations] =
    useState<Required<ApplyLabelPayload['operations']>>(defaultOperations);

  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const menuListRef = useRef<HTMLUListElement>(null);
  const [createdLabel, setCreatedLabel] = useState<Label | null>(null);

  const [showCreateLabelDialog, setShowCreateLabelDialog] =
    useState<boolean>(false);

  const [isExiting, setIsExiting] = useState<boolean>(false);

  const { search } = useLocation();

  const labelsAreApplying = !!useIsMutating({
    mutationKey: [MUTATION_KEY_LABELS_APPLY],
  });

  const { data, isInitialLoading: isLoading } = useRequestUnpaginatedLabels();

  const dispatch = useDispatch();
  const applyLabels = () => {
    const annotationUrls = selectedAnnotations.map(({ url }) => url);
    const payload = {
      operations,
      objects: { annotations: annotationUrls },
    };

    const labelsInQuery = asArray(parse(search).labels).filter(
      isNotNullOrUndefined
    );

    onLabel({
      payload,
      onSuccess: () => {
        setOperations(defaultOperations);
        setAnchor(null);
        setIsExiting(true);
      },
      onError: () => {
        dispatch(throwError('labelApplyError'));
      },
      labelsInQuery,
    });
  };

  const changedLabels = [...operations.add, ...operations.remove];

  const open = !!anchor;

  const { anchorOrigin, transformOrigin } = useMenuPosition(!!nestedMenu);

  useEffect(() => {
    if (createdLabel && menuListRef.current && data) {
      // Using data.length, and not data.length -1, ensures that we scroll just at the top edge
      // of the createdLabel.
      const relativePosition =
        data.findIndex(label => label.id === createdLabel.id) / data.length;

      // see https://github.com/facebook/react/issues/23396 for why we don't use scrollIntoView
      menuListRef.current.scrollTo({
        top: menuListRef.current.scrollHeight * relativePosition,
        behavior: 'smooth',
      });
    }
  }, [createdLabel, data]);

  return (
    <RoleRestrictor
      requiredRoles={[
        'admin',
        'organization_group_admin',
        'manager',
        'annotator',
      ]}
    >
      <div>
        <Stack
          onClick={e => {
            setAnchor(e.currentTarget);
          }}
        >
          {children({ isHovered: open || changedLabels.length > 0 })}
        </Stack>

        <Popover
          container={ref.current}
          open={open}
          onClose={() => setAnchor(null)}
          anchorEl={anchor}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          PaperProps={{
            sx: {
              maxHeight: MENU_MAX_HEIGHT,
              minHeight: MENU_MIN_HEIGHT,
              width: MENU_WIDTH,
            },
          }}
          TransitionProps={{
            onExited: () => {
              if (isExiting) setIsExiting(false);
            },
          }}
        >
          {isLoading ? (
            <Loading />
          ) : data?.length ? (
            <Stack minHeight="inherit">
              <MenuList
                ref={menuListRef}
                sx={{
                  maxHeight: MENU_MAX_HEIGHT - 100,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {data.map(label => {
                  const isChanged = changedLabels.includes(label.url);
                  const checkedProps = isChanged
                    ? {
                        checked: operations.add.includes(label.url),
                        indeterminate: undefined,
                      }
                    : {
                        checked: initialState.allChecked.includes(label.url),
                        indeterminate: initialState.indeterminates.includes(
                          label.url
                        ),
                      };

                  const shouldAppend =
                    (isChanged &&
                      initialState.indeterminates.includes(label.url)) ||
                    initialState.allChecked.includes(label.url);

                  const handleChange = () => {
                    if (checkedProps.checked) {
                      return setOperations(prev => ({
                        add: without(prev.add, label.url),
                        remove: shouldAppend
                          ? [...prev.remove, label.url]
                          : prev.remove,
                      }));
                    }
                    return setOperations(prev => ({
                      add: shouldAppend ? prev.add : [...prev.add, label.url],
                      remove: without(prev.remove, label.url),
                    }));
                  };

                  return (
                    <MenuItem key={label.id} dense onClick={handleChange}>
                      <LabelMenuItem label={label} {...checkedProps} />
                    </MenuItem>
                  );
                })}
              </MenuList>

              <LabelMenuActions
                shouldShowApplyButton={isExiting || !!changedLabels.length}
                applyButtonProps={{
                  endIcon: labelsAreApplying && (
                    <CircularProgress size={18} color="inherit" />
                  ),
                  onClick: applyLabels,
                  disabled: changedLabels.length === 0,
                }}
                onCreateLabel={() => setShowCreateLabelDialog(true)}
              />
            </Stack>
          ) : (
            <EmptyListLabels>
              <LabelMenuActions
                shouldShowApplyButton={false}
                onCreateLabel={() => setShowCreateLabelDialog(true)}
              />
            </EmptyListLabels>
          )}
          <Dialog open={showCreateLabelDialog}>
            <CreateLabelDialog
              onCancel={() => setShowCreateLabelDialog(false)}
              onSuccess={newLabel => {
                setOperations(prev => ({
                  ...prev,
                  add: [...prev.add, newLabel.url],
                }));
                setCreatedLabel(newLabel);
              }}
            />
          </Dialog>
        </Popover>
      </div>
    </RoleRestrictor>
  );
};

export default BatchLabels;
