import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  inputLabelClasses,
  List,
  ListItem,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useRequestDemo } from '../../business/internal/useRequestDemo';
import TextFieldControl from '../../components/ReactHookForm/controls/TextFieldControl';
import { DocumentType } from '../../features/queues/add-queue/data';
import { LocalizationKeys } from '../../i18n';
import { logBookDemo } from '../../lib/gtm';
import { State } from '../../types/state';
import DialogTitle from '../../ui/dialog-title/DialogTitle';
import SuccessfullySentMessage from './components/SuccessfullySentMessage';

type Props = {
  open: boolean;
  onClose: () => void;
  extraInfoPayload?: string;
  ctaLocation?: string | undefined;
  selectedDocType?: DocumentType['id'] | null;
};

const sharedInputProps = {
  InputLabelProps: {
    sx: {
      [`.${inputLabelClasses.asterisk}`]: {
        color: 'error.main',
        fontSize: '1.2em',
        lineHeight: 1,
      },
    },
  },
  required: true,
  FieldLabelProps: {
    layout: 'floating',
  },
} as const;

const PHONE_MIN_LENGTH = 7;

const phoneRegex =
  /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*?((?:#|ext\.?|extension|x)[-. \\/]?(\d+))*$/;

const requestDemoSchema = (intl: IntlShape) =>
  yup.object().shape({
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'containers.requestDemo.dialog.email.invalid',
        })
      )
      .required(
        intl.formatMessage({
          id: 'containers.requestDemo.dialog.email.required',
        })
      ),
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'containers.requestDemo.dialog.phone.required',
        })
      )
      .min(
        PHONE_MIN_LENGTH,
        intl.formatMessage(
          {
            id: 'containers.requestDemo.dialog.phone.minLength',
          },
          { minLength: PHONE_MIN_LENGTH }
        )
      )
      .matches(
        phoneRegex,
        intl.formatMessage({
          id: 'containers.requestDemo.dialog.phone.invalid',
        })
      ),
  });

const CallToActionDialog = ({
  open,
  onClose,
  extraInfoPayload,
  ctaLocation,
  selectedDocType,
}: Props) => {
  const intl = useIntl();
  const user = useSelector((state: State) => state.user);
  const { mutate: requestDemo, isLoading, isSuccess } = useRequestDemo();

  const { handleSubmit, control } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: user.email || user.username,
      phone: user.phoneNumber ?? '',
      extraInfo: extraInfoPayload,
    },
    resolver: yupResolver(requestDemoSchema(intl)),
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 448, position: 'fixed' },
        elevation: 4,
      }}
    >
      <DialogTitle
        title={intl.formatMessage({
          id: 'containers.callToAction.dialog.title',
        })}
        onClose={onClose}
        closeDataCy="request-demo-close-icon"
      />
      <DialogContent sx={{ mt: 3, mx: 3, px: 0 }}>
        {isSuccess ? (
          <SuccessfullySentMessage onClose={onClose} />
        ) : (
          <Stack spacing={2}>
            <Typography color="text.secondary">
              {intl.formatMessage({
                id: 'containers.callToAction.dialog.description',
              })}
            </Typography>

            <List disablePadding>
              {(['presentation', 'meetExperts', 'pricing'] as const).map(
                translationKey => {
                  const id: LocalizationKeys = `containers.requestDemo.dialog.${translationKey}`;
                  return (
                    <ListItem
                      key={`request-demo-${translationKey}`}
                      sx={{ pl: 0 }}
                    >
                      <Stack
                        spacing={1.5}
                        direction="row"
                        alignItems="flex-start"
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            mt: 0.7,
                            flex: '0 0 auto',
                            backgroundColor: 'success.main',
                            borderRadius: 12,
                          }}
                        />
                        <Typography>
                          {intl.formatMessage({
                            id,
                          })}
                        </Typography>
                      </Stack>
                    </ListItem>
                  );
                }
              )}
            </List>

            <form
              id="request-demo-form"
              onSubmit={handleSubmit(formData => {
                requestDemo(formData);
                logBookDemo(ctaLocation, selectedDocType);
              })}
              noValidate
            >
              <Stack spacing={3} sx={{ pt: 2 }}>
                <TextFieldControl
                  ControllerProps={{ control, name: 'email' }}
                  label={intl.formatMessage({
                    id: 'containers.requestDemo.dialog.email',
                  })}
                  inputProps={{
                    'data-cy': 'request-demo-form-email',
                  }}
                  {...sharedInputProps}
                  disabled={isLoading}
                />
                <TextFieldControl
                  ControllerProps={{ control, name: 'phone' }}
                  label={intl.formatMessage({
                    id: 'containers.requestDemo.dialog.phone',
                  })}
                  inputProps={{
                    'data-cy': 'request-demo-form-phone',
                  }}
                  {...sharedInputProps}
                  disabled={isLoading}
                />
                <Stack alignItems="center">
                  <Button
                    type="submit"
                    variant="contained"
                    data-cy="request-demo-form-button"
                    sx={{ mt: 4 }}
                    disabled={isLoading}
                    startIcon={
                      isLoading ? (
                        <CircularProgress color="inherit" size={16} />
                      ) : null
                    }
                  >
                    {intl.formatMessage({
                      id: 'containers.callToAction.dialog.button.submit',
                    })}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CallToActionDialog;
