import { Check } from '@rossum/ui/icons';
import { Button, Skeleton, Stack, Typography } from '@rossum/ui/material';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { NonAdminRestrictor } from '../../../../components/Restrictors';
import { ROSSUM_EMAIL_INBOX } from '../../../../constants/values';
import { link } from '../../../../lib/formaterValues';
import { useCopyToClipboard } from '../../../../ui/copy-to-clipboard/useCopyToClipboard';
import { useFeatureFlag } from '../../../../unleash/useFeatureFlag';

type SuggestInboxProps = {
  inboxEmail?: string;
  queueId?: number;
  isLoading: boolean;
  showHelpArticle?: boolean;
};

export const SuggestInbox = memo(
  ({
    inboxEmail,
    queueId,
    isLoading,
    showHelpArticle = true,
  }: SuggestInboxProps) => {
    const intl = useIntl();
    const { copied, copyToClipboard } = useCopyToClipboard(inboxEmail || '');

    const isNewQueueSettingsLayout = useFeatureFlag(
      'ac-4366-queue-settings-layout-v2'
    );

    const route = isNewQueueSettingsLayout
      ? `/queues/${queueId}/settings/emails`
      : `/settings/queues/${queueId}/emails`;

    if (!isLoading && !inboxEmail) {
      return (
        <Stack spacing={2} alignItems="center">
          <Typography variant="subtitle2" color="text.secondary">
            {intl.formatMessage({
              id: 'containers.annotationList.empty.suggestInbox.noInbox.hint',
            })}
          </Typography>
          {queueId && (
            <NonAdminRestrictor
              restrictComponent={
                <Typography variant="subtitle2" color="text.secondary">
                  {intl.formatMessage({
                    id: 'containers.annotationList.empty.suggestInbox.noInbox.restricted',
                  })}
                </Typography>
              }
            >
              <Button
                variant="contained"
                component={Link}
                to={route}
                sx={{
                  '&:hover': {
                    color: theme => theme.palette.primary.contrastText,
                  },
                }}
              >
                {intl.formatMessage({
                  id: 'containers.annotationList.empty.suggestInbox.noInbox.button',
                })}
              </Button>
            </NonAdminRestrictor>
          )}
        </Stack>
      );
    }

    return (
      <Stack alignItems="center" spacing={3}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          onClick={copyToClipboard}
          sx={{ cursor: 'pointer' }}
          data-cy="copy-email-address"
        >
          <Button variant="outlined" disabled>
            <Stack direction="row" sx={{ minWidth: 0 }}>
              <Typography
                variant="button"
                fontSize="small"
                color="text.secondary"
                fontWeight={400}
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  flexShrink: 1,
                }}
              >
                {isLoading ? <Skeleton width={250} /> : inboxEmail}
              </Typography>
            </Stack>
          </Button>
          <Button
            color={copied ? 'success' : 'secondary'}
            variant="outlined"
            size="medium"
            disabled={isLoading}
            sx={{ minWidth: '134px' }}
          >
            <Stack direction="row" spacing={1}>
              {copied ? <Check color="success" fontSize="small" /> : null}
              <Typography
                variant="button"
                fontSize="small"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {intl.formatMessage({
                  id: copied
                    ? 'containers.annotationList.empty.suggestInbox.copyInfo'
                    : 'containers.annotationList.empty.suggestInbox.copyEmail',
                })}
              </Typography>
            </Stack>
          </Button>
        </Stack>
        {showHelpArticle ? (
          <Typography variant="caption" color="text.secondary">
            {intl.formatMessage(
              {
                id: 'containers.annotationList.empty.suggestInbox.sendEmail',
              },
              {
                link: link(ROSSUM_EMAIL_INBOX, { color: 'inherit' }),
              }
            )}
          </Typography>
        ) : null}
      </Stack>
    );
  }
);

export default SuggestInbox;
