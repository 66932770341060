import { PageContainer } from '@rossum/rossum-ui/PageContainer';
import { Add, Close } from '@rossum/ui/icons';
import {
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { includes, isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import HelpLinkTooltip from '../../components/UI/HelpLinkTooltip';
import InfoBox from '../../components/UI/InfoBox/index';
import SingleQueueSelect from '../../components/UI/SingleQueueSelect';
import {
  FUNCTIONS_LINK,
  pageSizes,
  WEBHOOKS_LINK,
} from '../../constants/values';
import { useExtensionsFeatureSubscription } from '../../features/pricing/hooks/useExtensionsFeatureSubscription';
import { link } from '../../lib/formaterValues';
import { asScalar, parse, setQueryCreator, stringify } from '../../lib/url';
import { HOOK_LIST_QUERY } from '../../redux/modules/localStorage/actions';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { updateUiSettings } from '../../redux/modules/user/actions';
import { displayExtensionsIntroBannerSelector } from '../../redux/modules/user/selectors';
import { workspacesWithNonEmptyQueuesSelector } from '../../redux/modules/workspaces/selector';
import { Extension } from '../../types/extensions';
import { Queue } from '../../types/queue';
import { State } from '../../types/state';
import { WorkspaceWithQueues } from '../../types/workspace';
import SearchInput from '../../ui/search-input/SearchInput';
import { useStoredState } from '../../utils/hooks/useStoredState';
import EventsSelect from './components/EventsSelect';
import IntroToExtensions from './components/IntroToExtensions';
import ViewSwitcher from './components/ViewSwitcher';
import DependenciesGraph from './DependenciesGraph';
import { useGraphHooks } from './DependenciesGraph/hooks';
import { toSelectedEvent } from './DependenciesGraph/types';
import ExtensionsList from './ExtensionsList';
import { assureCorrectView, webhookNormalizedEvents } from './lib/helpers';
import { myExtensionsDefaultQuery, MyExtensionsQuery } from './lib/routes';

type StateProps = {
  activeExtensionsCount: number | null;
  extensions: Array<Extension>;
  extensionsLoaded: boolean;
  displayIntroBanner: boolean;
  isTrial: boolean;
  workspacesWithNonEmptyQueues: WorkspaceWithQueues[];
};

type DispatchProps = {
  setHideIntroBanner: () => void;
};

type Props = StateProps & DispatchProps;

const Extensions = ({
  activeExtensionsCount,
  extensions,
  extensionsLoaded,
  displayIntroBanner,
  isTrial,
  setHideIntroBanner,
  workspacesWithNonEmptyQueues,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { name: search, events, queues, view } = parse(location.search);
  const [defaultStoredQuery, setStoredQuery] = useStoredState({
    name: HOOK_LIST_QUERY,
    stringKeys: ['pageSize', 'page', 'name', 'queues', 'events', 'view'],
    defaultValues: myExtensionsDefaultQuery,
  });

  const {
    pageSize: pageSizeStored,
    page: pageStored,
    name: nameStored,
    events: eventsStored,
    queues: queuesStored,
    view: viewStored,
  } = defaultStoredQuery || {};

  const isGraphView = view === 'graph';

  const filtersActive = !!(search || events !== 'all' || queues);

  const [displaySearch, setDisplaySearch] = useState(!!search);

  const allQueuesLoaded = useSelector(
    (state: State) => state.workspaces.isLoaded && state.queues.loaded
  );

  const extensionsSubscription = useExtensionsFeatureSubscription();

  useEffect(() => {
    const searchShouldBeVisible = isGraphView
      ? false
      : !!extensions.length || !!filtersActive || !extensionsLoaded;

    setDisplaySearch(searchShouldBeVisible);
  }, [isGraphView, extensions.length, filtersActive, extensionsLoaded]);

  useEffect(() => {
    const { page, pageSize, ...parsedQuery } = parse(location.search) || {};

    if (
      pageStored !== asScalar(page) ||
      pageSizeStored !== asScalar(pageSize) ||
      viewStored !== asScalar(parsedQuery.view) ||
      eventsStored !== asScalar(parsedQuery.events)
    ) {
      setStoredQuery({
        page: page ? asScalar(page) : myExtensionsDefaultQuery.page,
        pageSize: pageSize
          ? asScalar(pageSize)
          : myExtensionsDefaultQuery.pageSize,
        name: nameStored,
        events: eventsStored,
        queues: queuesStored,
        view: viewStored,
      });
    }
    if (
      !page ||
      !pageSize ||
      !parsedQuery.view ||
      !parsedQuery.events ||
      !includes(pageSizes, pageSize)
    ) {
      history.replace({
        search: stringify(defaultStoredQuery),
      });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFilterChange = (filter: Partial<MyExtensionsQuery>) => {
    setStoredQuery({
      ...defaultStoredQuery,
      page: myExtensionsDefaultQuery.page,
      ...filter,
    });

    return setQueryCreator(history)(filter);
  };

  const intl = useIntl();

  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>(
    undefined
  );

  useEffect(() => {
    if (allQueuesLoaded) {
      const allQueues = workspacesWithNonEmptyQueues.flatMap(
        ({ queues }) => queues
      );

      const queue = allQueues.find(({ id }) => `${id}` === asScalar(queues));

      setSelectedQueue(queue);
    }
  }, [allQueuesLoaded, queues, workspacesWithNonEmptyQueues]);

  const eventsAsScalar = asScalar(events);
  const selectedEvent =
    eventsAsScalar !== null ? toSelectedEvent(eventsAsScalar) : 'all';
  const selectedQueueId = asScalar(queues)
    ? Number(asScalar(queues))
    : undefined;

  const { isLoading, isFetching, graphDataGroupedPerEvents } = useGraphHooks({
    queueId: asScalar(queues) ? Number(asScalar(queues)) : undefined,
    enabled: isGraphView,
  });

  return (
    <PageContainer maxWidth={false} data-page-title="extensions">
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" minHeight={35}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6" data-cy="extensions-heading">
              {intl.formatMessage({
                id: `containers.settings.sidebar.extensions`,
              })}
            </Typography>
            {isNumber(activeExtensionsCount) && (
              <Chip
                size="tiny"
                label={`${activeExtensionsCount}`}
                data-cy="extensions-active-count"
              />
            )}
            <HelpLinkTooltip
              id="containers.settings.extensions.helpTooltip"
              values={{
                webhooksLink: link(WEBHOOKS_LINK),
                functionsLink: link(FUNCTIONS_LINK),
              }}
            />
            <ViewSwitcher
              activeView={assureCorrectView(view)}
              onViewChange={value => onFilterChange({ view: value })}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Tooltip
              title={
                extensionsSubscription.purchased
                  ? ''
                  : intl.formatMessage({
                      id: 'containers.settings.extensions.createExtensionDisabled',
                    })
              }
            >
              <span>
                <Button
                  component={Link}
                  disabled={!extensionsSubscription.purchased}
                  to="/settings/extensions/create"
                  variant="contained"
                  startIcon={<Add />}
                  data-cy="extensions-add-extension"
                  sx={{
                    '&:hover': {
                      color: 'inherit',
                      textDecoration: 'none',
                    },
                  }}
                >
                  <FormattedMessage id="containers.settings.extensions.createExtension" />
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" spacing={2}>
            <SingleQueueSelect
              workspaces={workspacesWithNonEmptyQueues}
              allQueuesLoaded={allQueuesLoaded}
              selectedQueue={selectedQueue}
              setSelectedQueue={queue => {
                setSelectedQueue(queue);
                onFilterChange({ queues: queue ? `${queue.id}` : undefined });
              }}
              onCloseClick={e => {
                e.stopPropagation();
                setSelectedQueue(undefined);
                onFilterChange({ queues: undefined });
              }}
            />
            {view === 'list' && (
              <EventsSelect
                value={selectedEvent}
                onChange={({ target: { value } }) =>
                  onFilterChange({ events: toSelectedEvent(value) })
                }
                options={['all', ...webhookNormalizedEvents] as const}
                closeIcon={
                  selectedEvent !== 'all' && (
                    <IconButton
                      size="small"
                      onClick={() => onFilterChange({ events: 'all' })}
                      sx={{ position: 'absolute', right: 30 }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  )
                }
              />
            )}
          </Stack>
          {displaySearch && (
            <SearchInput
              placeholder={intl.formatMessage({
                id: 'containers.settings.extensions.search',
              })}
              value={asScalar(search) || ''}
              onChange={search => onFilterChange({ name: search || undefined })}
              inputProps={{
                'data-cy': 'search-input',
              }}
            />
          )}
        </Stack>
        {isTrial && (
          <InfoBox
            title="containers.settings.extensions.upgrade.title"
            text="containers.settings.extensions.upgrade.text"
          />
        )}
        {displayIntroBanner && (
          <IntroToExtensions
            dataCy="extensions-intro-banner"
            onClose={setHideIntroBanner}
          />
        )}
        {view === 'list' ? (
          <ExtensionsList
            extensions={extensions}
            extensionsLoaded={extensionsLoaded}
            filtersActive={filtersActive}
            allQueuesLoaded={allQueuesLoaded}
          />
        ) : (
          <DependenciesGraph
            isLoading={isLoading}
            isFetching={isFetching}
            graphDataGroupedPerEvents={graphDataGroupedPerEvents}
            selectedQueueId={selectedQueueId}
          />
        )}
      </Stack>
    </PageContainer>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  activeExtensionsCount: state.extensions.activeExtensionsCount,
  extensions: state.extensions.list,
  extensionsLoaded: state.extensions.loaded,
  displayIntroBanner: displayExtensionsIntroBannerSelector(state),
  isTrial: !!isTrialSelector(state),
  workspacesWithNonEmptyQueues: workspacesWithNonEmptyQueuesSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  setHideIntroBanner: () =>
    updateUiSettings({
      hideExtensionsIntroBanner: true,
    }),
};

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(Extensions);
