import { PageContainer } from '@rossum/rossum-ui/PageContainer';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  List,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import ContentWrapper from '../../../components/ContentWrapper';
import PageLayout from '../../../components/PageLayout';
import Scrollable from '../../../components/Scrollable';
import { SettingsSidebarItems } from '../../../containers/Settings/containers/SettingsSidebarItems';
import Sidebar from '../../../containers/Settings/containers/Sidebar';
import { useRequestUnpaginatedLabels } from '../hooks/useRequestLabels';
import { CreateLabelDialog } from './LabelDialogs';
import { LabelListRow } from './LabelListRow';

const LOADER_HEIGHT = 600;

export const LabelList = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const intl = useIntl();

  const {
    data: labels,
    isInitialLoading: isLoading,
    isSuccess,
    isError,
  } = useRequestUnpaginatedLabels();

  return (
    <PageLayout>
      <Sidebar backLink={null}>
        <SettingsSidebarItems />
      </Sidebar>

      <ContentWrapper>
        <Scrollable>
          <PageContainer maxWidth={false} data-page-title="labels">
            <Stack direction="row" alignItems="center" mb={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h6" data-cy="extensions-heading">
                  {intl.formatMessage({
                    id: 'containers.settings.sidebar.item.labels',
                  })}
                </Typography>
                {labels && <Chip label={labels.length} size="tiny" />}
              </Stack>

              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 'auto' }}
                onClick={() => setShowDialog(true)}
              >
                {intl.formatMessage({
                  id: 'containers.settings.labels.actions.createNew',
                })}
              </Button>
            </Stack>

            {isLoading ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                height={LOADER_HEIGHT}
              >
                <CircularProgress />
              </Stack>
            ) : isSuccess && labels ? (
              <List>
                {labels.map(label => (
                  <LabelListRow key={label.id} label={label} />
                ))}
              </List>
            ) : isError ? (
              <Typography color="text.secondary">
                {intl.formatMessage({
                  id: 'containers.settings.labels.fetchFailed',
                })}
              </Typography>
            ) : null}
          </PageContainer>
        </Scrollable>

        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <CreateLabelDialog onCancel={() => setShowDialog(false)} />
        </Dialog>
      </ContentWrapper>
    </PageLayout>
  );
};
