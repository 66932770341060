import { UseQueryResult } from '@tanstack/react-query';
import { useRef } from 'react';

/**
 * This hook is used to fetch the initial preview data when the component is mounted
 * and the preview is enabled AKA formula field is valid. It should not fetch initial
 * preview for new formula fields.
 */
export const useInitialPreview = ({
  previewEnabled,
  evaluationQueries,
}: {
  previewEnabled: boolean;
  evaluationQueries: UseQueryResult<unknown>[];
}) => {
  const initialFetchResolution = useRef<'initial' | 'disabled' | 'fulfilled'>(
    'initial'
  );

  if (
    initialFetchResolution.current === 'initial' &&
    evaluationQueries.length > 0
  ) {
    if (
      // assume that once one query is idle, all queries are idle
      evaluationQueries.some(query => query.isLoading && !query.isFetching) &&
      previewEnabled
    ) {
      initialFetchResolution.current = 'fulfilled';
      evaluationQueries.forEach(({ refetch }) => {
        refetch();
      });
      return;
    }

    initialFetchResolution.current = 'disabled';
  }
};
