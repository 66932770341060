import { UniqueIdentifier } from '@dnd-kit/core';
import { SchemaSection } from '@rossum/api-client/schemas';
import { Stack } from '@rossum/ui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { getDnDItems } from '../helpers';
import { FieldsList, FieldsListProps } from './FieldsList';
import { SchemaFieldTileContent } from './SchemaFieldTileContent';

export type SectionsReorderingProps = {
  sections: SchemaSection[];
  onSectionsReorder: (
    from: readonly [UniqueIdentifier, number],
    to: readonly [UniqueIdentifier, number]
  ) => void;
};

export const SECTIONS_DROPPABLE_ID = 'sections-droppable';

export const SectionsReordering = ({
  sections,
  onSectionsReorder,
}: SectionsReorderingProps) => {
  const intl = useIntl();

  const sectionsFieldsMap = sections.reduce<{
    [key: string]: SchemaSection;
  }>((acc, field) => {
    return {
      ...acc,
      [field.id]: field,
    };
  }, {});

  const items = getDnDItems([
    { id: SECTIONS_DROPPABLE_ID, children: sections },
  ]);

  const renderFieldItem: FieldsListProps['renderFieldItem'] = useCallback(
    (fieldId, isActive, isDragging, parentId) => {
      const section = sectionsFieldsMap[fieldId];

      return section ? (
        <SchemaFieldTileContent
          field={section}
          isActive={isActive}
          isDragging={isDragging}
          parentId={parentId}
          quickActions={[]}
        />
      ) : null;
    },
    [sectionsFieldsMap]
  );

  const handleSectionsReorder: FieldsListProps['onItemsReorder'] = useCallback(
    (from, to) => {
      onSectionsReorder(from, to);
    },
    [onSectionsReorder]
  );

  return (
    <Stack px={4} spacing={4}>
      <FieldsList
        items={items}
        renderFieldItem={renderFieldItem}
        onItemsReorder={handleSectionsReorder}
        sectionsData={{
          [SECTIONS_DROPPABLE_ID]: {
            label: intl.formatMessage({
              id: 'features.queueSettings.fields.sections.title',
            }),
          },
        }}
      />
    </Stack>
  );
};
