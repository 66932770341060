import { useSelector } from 'react-redux';
import {
  tabByName,
  TabWithMenuName,
  visibleMenuItemsForTab,
} from '../../../components/AppBar/navigationStructure';
import SidebarNavLink from '../../../components/Sidebar/SidebarNavLink';
import { organizationSelector } from '../../../redux/modules/organization/selectors';
import { State } from '../../../types/state';
import SidebarSection from '../components/SidebarSection';

const SidebarItems = ({ section }: { section: TabWithMenuName }) => {
  const features = useSelector(
    (state: State) => organizationSelector(state).uiSettings?.features ?? {}
  );

  const menuItems = visibleMenuItemsForTab(tabByName(section), { features });

  return (
    <SidebarSection label={`containers.settings.sidebar.sections.${section}`}>
      {menuItems.map(item => (
        <SidebarNavLink
          icon={item.icon}
          text={`containers.settings.sidebar.${item.name}`}
          to={item.link}
          dataCy={item.dataCy}
          key={`${item.name}-${section}`}
          isActive={item.isActive}
        />
      ))}
    </SidebarSection>
  );
};

export default SidebarItems;
