import { Stack } from '@rossum/ui/material';
import { some } from 'lodash';
import { ComponentType, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { UnpaidFeatureOverlay } from '../../features/pricing/components/UnpaidFeatureOverlay';
import {
  useExtensionLogsFeatureSubscription,
  useExtensionsFeatureSubscription,
} from '../../features/pricing/hooks/useExtensionsFeatureSubscription';
import {
  embeddedFeatureSelector,
  labelsFeatureSelector,
  statisticsFeatureSelector,
} from '../../features/pricing/selectors';
import { safeOrganizationSelector } from '../../redux/modules/organization/selectors';
import {
  isUserOfRoleSelector,
  userRoleNameSelector,
} from '../../redux/modules/user/selectors';
import { GroupRole } from '../../types/group';
import { State } from '../../types/state';

type OwnProps = {
  children: ReactNode;
  restrictComponent?: ReactElement | null;
};
type StateProps = { enabled?: boolean; inconclusive?: boolean };
type Props = OwnProps & StateProps;

export type RoleProps = { role: GroupRole };

type RoleRestrictorProps = {
  requiredRoles: Array<GroupRole>;
  isFeatureEnabled?: (_state: State) => boolean;
  resolveConclusiveness?: (_state: State) => boolean;
};

const Restrictor = ({
  children,
  enabled,
  restrictComponent = null,
  inconclusive = true,
}: Props) =>
  inconclusive ? null : enabled ? restrictComponent : <>{children}</>;

export const RoleRestrictor = connect<
  StateProps,
  null,
  OwnProps & RoleRestrictorProps,
  State
>(
  (
    state,
    {
      requiredRoles,
      resolveConclusiveness = () => true,
      isFeatureEnabled = () => true,
    }
  ) => {
    const enabled = !(
      some(requiredRoles, role => isUserOfRoleSelector(state)(role)) &&
      isFeatureEnabled(state)
    );
    const inconclusive = !(state.groups.length && resolveConclusiveness(state));

    return {
      enabled,
      inconclusive,
    };
  }
)(Restrictor);

export const ViewerRestrictor = (props: OwnProps) => (
  <RoleRestrictor
    requiredRoles={[
      'admin',
      'organization_group_admin',
      'manager',
      'annotator',
      'annotator_limited',
    ]}
    {...props}
  />
);

export const AnnotatorLimitedRestrictor = (props: OwnProps) => (
  <RoleRestrictor
    requiredRoles={[
      'admin',
      'organization_group_admin',
      'manager',
      'annotator',
      'viewer',
      'approver',
    ]}
    {...props}
  />
);

export const canUploadRoles: GroupRole[] = [
  'admin',
  'organization_group_admin',
  'manager',
  'annotator',
];

export const UploadRestrictor = (props: OwnProps) => (
  <RoleRestrictor requiredRoles={canUploadRoles} {...props} />
);

export const EditDocumentsRestrictor = (props: OwnProps) => (
  <RoleRestrictor
    requiredRoles={[
      'admin',
      'organization_group_admin',
      'manager',
      'annotator',
    ]}
    {...props}
  />
);

export const NonAdminRestrictor = (props: OwnProps) => (
  <RoleRestrictor
    requiredRoles={['admin', 'organization_group_admin']}
    {...props}
  />
);

export const RoleProvider = connect((state: State) => ({
  role: userRoleNameSelector(state),
}))(
  ({
    children,
    role,
  }: {
    children: ({ role }: RoleProps) => ReactElement | null;
    role: GroupRole;
  }) => children({ role })
);

export const nonAdminRestrictorRoute =
  <T,>(Route: ComponentType<T>) =>
  (routeProps: T & JSX.IntrinsicAttributes) => (
    <NonAdminRestrictor restrictComponent={<Redirect to="/annotations" />}>
      <Route {...routeProps} />
    </NonAdminRestrictor>
  );

const StatisticsGuard = (props: OwnProps) => (
  <RoleRestrictor
    requiredRoles={['admin', 'organization_group_admin', 'manager']}
    resolveConclusiveness={state =>
      !!safeOrganizationSelector(state)?.id &&
      !!state.organizationGroup.current?.id
    }
    isFeatureEnabled={statisticsFeatureSelector}
    {...props}
  />
);

export const StatisticsRouteGuard = ({ children }: OwnProps) => {
  const intl = useIntl();
  return (
    <StatisticsGuard
      restrictComponent={
        <Stack height={1}>
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.statistics',
            })}
            dataCy="unpaid-overlay-statistics"
          />
        </Stack>
      }
    >
      {children}
    </StatisticsGuard>
  );
};

export const EmbeddedModeGuard = (props: OwnProps) => {
  const intl = useIntl();

  return (
    <RoleRestrictor
      requiredRoles={[
        'annotator',
        'annotator_limited',
        'manager',
        'admin',
        'viewer',
        'organization_group_admin',
        'approver',
      ]}
      resolveConclusiveness={state =>
        !!safeOrganizationSelector(state)?.id &&
        !!state.organizationGroup.current?.id
      }
      isFeatureEnabled={embeddedFeatureSelector}
      restrictComponent={
        <Stack height={1}>
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.embeddedMode',
            })}
            dataCy="unpaid-overlay-embedded-mode"
          />
        </Stack>
      }
      {...props}
    />
  );
};

export const LabelsGuard = (props: OwnProps) => (
  <RoleRestrictor
    requiredRoles={['admin', 'organization_group_admin']}
    resolveConclusiveness={state =>
      !!safeOrganizationSelector(state)?.id &&
      !!state.organizationGroup.current?.id
    }
    isFeatureEnabled={labelsFeatureSelector}
    restrictComponent={<Redirect to="/documents" />}
    {...props}
  />
);

export const ExtensionLogsGuard = (props: OwnProps) => {
  const intl = useIntl();
  const logsSubscription = useExtensionLogsFeatureSubscription();

  return (
    <RoleRestrictor
      requiredRoles={['admin', 'organization_group_admin']}
      resolveConclusiveness={state =>
        !!safeOrganizationSelector(state)?.id &&
        !!state.organizationGroup.current?.id &&
        logsSubscription.status === 'success'
      }
      isFeatureEnabled={() => logsSubscription.canAccess}
      restrictComponent={
        <Stack height={1}>
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.extensionLogs',
            })}
            dataCy="unpaid-overlay-extension-logs"
          />
        </Stack>
      }
      {...props}
    />
  );
};

export const ExtensionsCreateGuard = (props: OwnProps) => {
  const extensionsSubscription = useExtensionsFeatureSubscription();
  const intl = useIntl();

  return (
    <RoleRestrictor
      requiredRoles={['admin', 'organization_group_admin']}
      resolveConclusiveness={state =>
        !!safeOrganizationSelector(state)?.id &&
        !!state.organizationGroup.current?.id
      }
      isFeatureEnabled={() => extensionsSubscription.purchased}
      restrictComponent={
        <Stack height={1}>
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.extensionsCreate',
            })}
            dataCy="unpaid-overlay-create-new-extension"
          />
        </Stack>
      }
      {...props}
    />
  );
};

export const ExtensionsGuard = (props: OwnProps) => {
  const extensionsSubscription = useExtensionsFeatureSubscription();
  const intl = useIntl();

  return (
    <RoleRestrictor
      requiredRoles={['admin', 'organization_group_admin']}
      resolveConclusiveness={state =>
        !!safeOrganizationSelector(state)?.id &&
        !!state.organizationGroup.current?.id &&
        extensionsSubscription.status === 'success'
      }
      isFeatureEnabled={() => extensionsSubscription.canAccess}
      restrictComponent={
        <Stack height={1}>
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.extensions',
            })}
            dataCy="unpaid-overlay-extensions"
          />
        </Stack>
      }
      {...props}
    />
  );
};
