import { Message } from '@rossum/api-client/shared';
import { BboxParams } from '../lib/spaceConvertor';
import { ID } from './basic';
import { Nullable } from './CustomTypes/nullable';
import { AggregationMessage } from './message';
import { AnyDatapointSchema, EnumOption } from './schema';
import { SearchResult } from './search';

export type ValidationSources =
  | 'score'
  | 'checks'
  | 'not_found'
  | 'history'
  | 'data_matching'
  | 'connector'
  | 'human'
  | 'non_required'
  | 'additional_value'
  | 'default_value'
  | 'table_suggester';

/*
  Pure Collections in State
    **
    _ST appended to the type name
    signifies the data is pure
    representation in state
    **
*/

export type DatapointValueDataST = {
  value: string;
  page: number | null;
  position?: BboxParams | null;
  rirConfidence?: number | null;
  ocrText?: string;
  // HACK: Used to represent position of a tuple for a ghost row
  ocrPosition?: BboxParams | null;
};

type TreeMetaData = {
  index: number;
  parentId: ID | undefined;
};

type SidebarDatapointMetaData = TreeMetaData & {
  sidebarDatapoint: true;
} & Partial<{
    index: number;
    isHumanValidated: boolean;
    isSimpleMultivalue: boolean;
    isValidated: boolean;
    visibleIndex: number;
  }>;

type MetaData = TreeMetaData & { sidebarDatapoint: false } & Partial<{
    index: number;
    isSimpleMultivalue: false;
    visibleIndex: number;
  }>;

export type DatapointMetaData = MetaData | SidebarDatapointMetaData;

type WithSidebarMeta<T> = T & { meta: SidebarDatapointMetaData };

export type SidebarDatapointST = WithSidebarMeta<
  SimpleDatapointDataST | MultivalueDatapointDataST
>;

export type SimpleDatapointDataST = {
  category: 'datapoint';
  // null is used for button datapoints
  content: DatapointValueDataST | null;
  hidden: boolean;
  id: number;
  meta: DatapointMetaData;
  options?: Array<EnumOption>;
  schema?: AnyDatapointSchema;
  schemaId: string;
  timeSpentOverall?: number;
  timeSpent?: number;
  // These properties allow us to handle simple and multivalue datapoints
  // in the same way, but they are never set by BE.
  timeSpentGrid?: number;
  timeSpentGridOverall?: number;
  validationSources: Array<ValidationSources>;
};

export type Children = { id: ID; index: number };

export type TupleDatapointDataST = {
  category: 'tuple';
  id: number;
  hidden: boolean;
  schemaId: string;
  children: Array<Children>;
  schema?: AnyDatapointSchema;
  meta: DatapointMetaData;
};

export type TupleDatapointDataFromSelector = {
  category: 'tuple';
  id: number;
  hidden: boolean;
  schemaId: string;
  children: TupleChildFromSelector[];
  schema?: AnyDatapointSchema;
  meta: DatapointMetaData;
};

type TupleChildFromSelector = {
  id: ID;
  index: number;
};

export type ColumnPosition = {
  headerTexts: Array<string>;
  last?: true;
  leftPosition: number;
  schemaId: string | null | undefined;
};

export type RowPosition = {
  last?: true;
  topPosition: number;
  type: string | null | undefined;
  tupleId: number | null;
};

export type Grid = {
  page: number;
  columns: Array<ColumnPosition>;
  rows: Array<RowPosition>;
  width: number;
  height: number;
};

export type MultivalueDatapointDataST = {
  category: 'multivalue';
  id: number;
  url?: string;
  hidden: boolean;
  schemaId: string;
  children: Array<Children>;
  grid: { parts: Array<Grid> } | null | undefined;
  schema?: AnyDatapointSchema;
  meta: DatapointMetaData;
  timeSpentOverall?: number;
  timeSpent?: number;
  timeSpentGrid?: number;
  timeSpentGridOverall?: number;
};

export type NestedDatapointST =
  | SectionDatapointDataST
  | MultivalueDatapointDataST
  | TupleDatapointDataST;

export type SectionDatapointDataST = {
  category: 'section';
  id: number;
  hidden: boolean;
  schemaId: string;
  children: Array<Children>;
  schema?: AnyDatapointSchema;
  meta: DatapointMetaData;
};

export type AnyDatapointDataST =
  | SimpleDatapointDataST
  | TupleDatapointDataST
  | MultivalueDatapointDataST
  | SectionDatapointDataST;

export type MatchedTriggerRulesPerLevel = {
  datapointLevel: Record<number, MatchedTriggerRuleDatapoint[]>;
  annotationLevel: Array<
    MatchedTriggerRulePageCount | MatchedTriggerRuleFilename
  >;
};

export type SuggestedOperationSource = 'table' | 'extension';
export type SuggestedOperationState<
  T extends SuggestedOperation = SuggestedOperation,
> = T & {
  source: SuggestedOperationSource;
};

export type DatapointsST = {
  aggregations: { [key: number]: Array<AggregationMessage> };
  content: Array<AnyDatapointDataST>;
  gridClipboard: Nullable<Grid>;
  initiallyValidated: boolean;
  loaded: boolean;
  messages: {
    [key: number]: Message;
    all?: Message;
  };
  pendingOCR: boolean;
  pendingValidation: boolean;
  pendingMaterialization: boolean;
  sidebarDatapointUpdatedTimestamp: number;
  suggestedPositionsForValue: Record<number, SearchResult[]>;
  suggestedOperations: Record<number, SuggestedOperationState>;
  matchedTriggerRules: MatchedTriggerRulesPerLevel;
  unvalidatedContent: boolean;
  updatedTimestamp: number;
  documentTimestamp: number;
  originalGrid?: Grid;
  loadingDatapointIds: number[];
  waitingForSuggestions: boolean;
};

/* Merged Collections in Components */
type DatapointValueData = {
  value: string;
  page: number | null;
  position?: BboxParams | null;
  ocrText?: string;
  // HACK: Used to represent position of a tuple for a ghost row
  ocrPosition?: BboxParams | null;
};

export type SimpleDatapointData = {
  category: 'datapoint';
  id: number;
  schemaId: string;
  hidden: boolean;
  timeSpentOverall?: number;
  timeSpent?: number;
  // null is used for button datapoints
  content: DatapointValueData | null;
  validationSources: Array<ValidationSources>;
  schema?: AnyDatapointSchema;
};

export type ColumnDatapoint = SimpleDatapointData & { url: string };

export type MultivalueDatapointData = {
  category: 'multivalue';
  id: number;
  hidden: boolean;
  schemaId: string;
  grid: { parts: Array<Grid> } | null | undefined;
  schema?: AnyDatapointSchema;
  children: Array<
    SimpleDatapointData | TupleDatapointData | MultivalueDatapointData
  >;
};

export type TupleDatapointData = {
  category: 'tuple';
  id: number;
  hidden: boolean;
  schemaId: string;
  schema?: AnyDatapointSchema;
  children: Array<
    SimpleDatapointData | TupleDatapointData | MultivalueDatapointData
  >;
};

export type SectionDatapointData = {
  category: 'section';
  id: number;
  hidden: boolean;
  schemaId: string;
  schema?: AnyDatapointSchema;
  children: Array<
    SimpleDatapointData | TupleDatapointData | MultivalueDatapointData
  >;
};

export type PassiveBbox = {
  completed: boolean;
  id: number;
  isInCurrentTuple: boolean;
  position: BboxParams;
  page: number;
  schemaId: string;
};

type NonNullableObject<T> = {
  [K in keyof T]-?: NonNullable<T[K]>;
};

export type ReplaceSuggestedOperation = {
  op: 'replace';
  id: ID;
  value: NonNullableObject<Pick<SimpleDatapointData, 'id' | 'content'>>;
};

type ReplaceSuggestedOperationWithMeta = ReplaceSuggestedOperation & {
  meta: {
    datapoint: SimpleDatapointDataST;
  };
};

export type AddSuggestedOperation = {
  op: 'add';
  id: ID;
  value: Array<
    NonNullableObject<
      Pick<SimpleDatapointData, 'content' | 'schemaId' | 'validationSources'>
    >
  >;
};

export type SuggestedOperation =
  | ReplaceSuggestedOperation
  | AddSuggestedOperation;

export const isReplaceSuggestedOperation = (
  op: SuggestedOperation
): op is ReplaceSuggestedOperation => op.op === 'replace';

export const isAddSuggestedOperation = (
  op: SuggestedOperation
): op is AddSuggestedOperation => op.op === 'add';

export type SuggestedOperationWithMeta =
  SuggestedOperationState<ReplaceSuggestedOperationWithMeta>;

export type AnyDatapointData =
  | SimpleDatapointData
  | TupleDatapointData
  | MultivalueDatapointData
  | SectionDatapointData;

export type MatchedTriggerRuleDatapoint = {
  type: 'datapoint';
  value: string;
  id: ID;
};

type MatchedTriggerRulePageCount = {
  type: 'page_count';
  value: number;
  threshold: number;
};

export type MatchedTriggerRuleFilename = {
  type: 'filename';
  value: string;
  regex: string;
};

export type MatchedTriggerRule =
  | MatchedTriggerRuleDatapoint
  | MatchedTriggerRulePageCount
  | MatchedTriggerRuleFilename;
