import { ChevronRight } from '@rossum/ui/icons';
import { Breadcrumbs } from '@rossum/ui/material';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Breadcrumb } from '../../../ui/breadcrumb/Breadcrumb';
import { queueSettingsPages } from '../fields/constants';

type Breadcrumb = {
  value: string;
  label: string;
  isLink: boolean;
  link?: string;
};

const globalKeys = ['queues', 'settings'] as const;

const QueueSettingsBreadcrumbs = ({
  dynamicBreadcrumbs,
}: {
  dynamicBreadcrumbs: Breadcrumb[];
}) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  const paths = useMemo(() => pathname.split('/').filter(Boolean), [pathname]);

  const getSegmentPathname = useCallback(
    (segment: string) => {
      return `/${paths.slice(0, paths.indexOf(segment) + 1).join('/')}`;
    },
    [paths]
  );

  const getBreadcrumb = useCallback(
    (segment: string) => {
      const globalKey = globalKeys.find(p => p === segment);
      const queueSettingsKey = queueSettingsPages.find(p => p === segment);
      const dynamicBreadcrumb = dynamicBreadcrumbs.find(
        p => p.value === segment
      );

      if (queueSettingsKey) {
        return {
          value: segment,
          label: intl.formatMessage({
            id: `containers.settings.queues.queue.${queueSettingsKey}`,
          }),
          isLink: true,
        };
      }

      if (globalKey) {
        return {
          value: segment,
          label: intl.formatMessage({
            id: `features.queueSettings.breadcrumbs.${globalKey}`,
          }),
          isLink: globalKey !== 'queues',
        };
      }

      if (dynamicBreadcrumb) {
        return dynamicBreadcrumb;
      }

      return {
        value: segment,
        label: segment,
        isLink: false,
      };
    },
    [intl, dynamicBreadcrumbs]
  );

  const breadcrumbs = useMemo(
    () =>
      paths.map(segment => ({
        ...getBreadcrumb(segment),
        link: getSegmentPathname(segment),
      })),
    [getBreadcrumb, getSegmentPathname, paths]
  );

  return (
    <Breadcrumbs separator={<ChevronRight fontSize="small" color="disabled" />}>
      {breadcrumbs.map(({ value, label, link, isLink }) => (
        <Breadcrumb to={isLink ? link : undefined} key={value}>
          {label}
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
};

export default QueueSettingsBreadcrumbs;
