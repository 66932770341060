import { isArray } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { assertNever } from '../../lib/typeUtils';
import { batchDatapointUpdateV2 } from '../../redux/modules/datapoints/actions';

/**
 * Slices the array between two given values (both values included).
 */
const sliceBetween = <T>(arr: T[], a: T, b: T) => {
  const indexRange = arr.reduce<null | number | [number, number]>(
    (acc, value, idx) => {
      if (acc === null && (value === a || value === b)) {
        return idx;
      }
      if (typeof acc === 'number' && (value === a || value === b)) {
        return [acc, idx];
      }
      return acc;
    },
    null
  );

  if (!isArray(indexRange)) return [];

  const [start, end] = indexRange;

  return arr.slice(start, end + 1);
};

export type BatchUpdateState = {
  schemaId: string;
  startAt: number;
  includedTupleIds: Record<number, true>;
} | null;

export type BatchUpdateEvent =
  | { type: 'start'; tupleId: number; columnSchemaId: string }
  | { type: 'over'; tupleId: number }
  | { type: 'end' };

export const useBatchUpdate = (tupleIds: number[]) => {
  const dispatch = useDispatch();

  const [batchUpdateState, setBatchUpdateState] =
    useState<BatchUpdateState>(null);

  const callbackContext = useRef<{
    state: typeof batchUpdateState;
    allTupleIds: number[];
  }>({ state: null, allTupleIds: [] });

  // Store necessary  context in useRef, so that onDrag reference is stable, and won't cause re-rendering
  callbackContext.current = { state: batchUpdateState, allTupleIds: tupleIds };

  const onBatchUpdateEvent = useCallback(
    (event: BatchUpdateEvent) => {
      const { state: dragState, allTupleIds } = callbackContext.current;

      switch (event.type) {
        case 'start': {
          setBatchUpdateState({
            startAt: event.tupleId,
            schemaId: event.columnSchemaId,
            includedTupleIds: { [event.tupleId]: true },
          });
          return;
        }
        case 'over': {
          if (dragState !== null) {
            const ids = sliceBetween(
              allTupleIds,
              dragState.startAt,
              event.tupleId
            );

            setBatchUpdateState({
              ...dragState,
              includedTupleIds: Object.fromEntries(ids.map(id => [id, true])),
            });
          }
          return;
        }
        case 'end': {
          const { state: dragState } = callbackContext.current;

          if (dragState === null) return;

          const targetTupleIds = Object.keys(dragState.includedTupleIds)
            .map(Number)
            .filter(id => id !== dragState.startAt);

          dispatch(
            batchDatapointUpdateV2(
              dragState.schemaId,
              dragState.startAt,
              targetTupleIds
            )
          );

          setBatchUpdateState(null);
          return;
        }
        default:
          assertNever(event);
      }
    },
    [dispatch]
  );

  const handleDragEnd = useCallback(() => {
    if (batchUpdateState?.startAt) {
      onBatchUpdateEvent({ type: 'end' });
    }
  }, [batchUpdateState?.startAt, onBatchUpdateEvent]);

  useEffect(() => {
    document.addEventListener('mouseup', handleDragEnd);

    return () => {
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [handleDragEnd]);

  return {
    onBatchUpdateEvent,
    batchUpdateState,
  };
};
