import { useDroppable } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { Fragment, ReactNode } from 'react';
import { asMutable } from 'seamless-immutable';
import SortableWrapper from '../../../../../components/Dnd/SortableWrapper';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';
import styles from '../style.module.sass';
import Field from './Field';
import Section from './Section';

type Props = {
  section: OriginalAnyDatapointSchema;
  onClick: (_index: number) => void;
  onDelete: (_index: number, _label: string) => void;
  onDeleteSection?: () => void;
  onEdit?: () => void;
  onToggle: (_index: number) => void;
  footer?: ReactNode;
};

const SectionWithDraggableFields = ({
  section,
  onClick,
  onDelete,
  onDeleteSection,
  onEdit,
  onToggle,
  footer,
}: Props) => {
  const { id, label } = section;
  const { setNodeRef } = useDroppable({ id });

  const sectionDatapoints = section.children as OriginalAnyDatapointSchema[];
  const sectionItems = sectionDatapoints.map(
    datapoint => datapoint && datapoint.id
  );

  return (
    <SortableContext
      id={id}
      strategy={rectSortingStrategy}
      items={asMutable(sectionItems)}
    >
      <Section title={label} onEdit={onEdit} onDelete={onDeleteSection}>
        <div ref={setNodeRef} className={styles.SectionDroppable}>
          {sectionDatapoints.map((datapoint, i) => {
            const itemId = datapoint && datapoint.id;

            return (
              <Fragment key={itemId}>
                {itemId && (
                  <SortableWrapper
                    id={itemId}
                    render={renderProps => (
                      <Field
                        datapoint={datapoint}
                        indexInSection={i}
                        onToggle={onToggle}
                        onDelete={onDelete}
                        onClick={onClick}
                        dragHandleProps={renderProps}
                        isVisibleDuringDragging
                      />
                    )}
                  />
                )}
              </Fragment>
            );
          })}
        </div>

        {footer}
      </Section>
    </SortableContext>
  );
};

export default SectionWithDraggableFields;
