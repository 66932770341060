import { compact } from 'lodash';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ALL_DOCUMENTS_QUERY_KEY } from '../../../features/document-list/hooks/useFetchDashboardData';
import { errorHandler } from '../../../lib/api';
import { EpicDependencies } from '../../../lib/apiTypes';
import { EmailThread } from '../../../types/emailThread';
import { State } from '../../../types/state';
import { invalidateQueryClientCache } from '../../middlewares/queryClientMiddleware';
import { RootActionType } from '../../rootActions';
import {
  markThreadAsReadFulfilled,
  updateEmailThread,
  updateEmailThreadFulfilled,
} from './actions';

const updateEmailThreadEpic = (
  action$: ActionsObservable<RootActionType>,
  _: StateObservable<State>,
  { authPatch$ }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf(updateEmailThread)),
    mergeMap(({ payload, meta: { url, shouldInvalidateQuery } }) => {
      return authPatch$<EmailThread>(url, payload).pipe(
        switchMap(response =>
          from(
            compact([
              updateEmailThreadFulfilled(response),
              payload.hasNewReplies === false &&
                (shouldInvalidateQuery
                  ? invalidateQueryClientCache([ALL_DOCUMENTS_QUERY_KEY])
                  : markThreadAsReadFulfilled(response)),
            ])
          )
        ),
        catchError(errorHandler)
      );
    })
  );

export default combineEpics(updateEmailThreadEpic);
