import { endpoints } from '@rossum/api-client';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { api } from '../../../../../lib/apiClient';
import { useCurrentSchemaConceptContext } from '../currentSchema/currentSchemaContext';
import { FORMULA_PREVIEW_KEY, PAGE_SIZE } from './useGetAnnotationsForPreview';

export const useCalculatePreview = (
  annotationIds: Array<number> | undefined,
  currentFormulaId: string | undefined
) => {
  const { getCurrentSchemaConcept } = useCurrentSchemaConceptContext();
  const queryClient = useQueryClient();
  // do not fetch first page on mount automatically
  // after first fetch switch flag to enabled: true so that we do not refetch
  // previous annotations when requesting new annotation rows
  const queriesEnabled = useRef(false);
  const queries =
    annotationIds?.map(id => {
      const queryKey = [
        FORMULA_PREVIEW_KEY,
        'annotation-content',
        currentFormulaId,
        id,
      ] as const;

      return {
        queryKey,
        queryFn: async () => {
          const { results: annotationContent } = await api.request(
            endpoints.annotations.getContent(id)
          );

          return api
            .request(
              endpoints.internal.schemas.evaluateFormulas({
                annotationContent,
                schemaContent: getCurrentSchemaConcept(),
              })
            )
            .then(response => ({
              // adding the annotation id to the response for easier matching
              ...response,
              annotationId: id,
            }));
        },
        enabled: queriesEnabled.current || annotationIds.length > PAGE_SIZE,
        cacheTime: 0, // clears data on closing drawer,
        onError: () => {
          // since we do not have whole schema content object in query key,
          // we need to invalidate it manually by setting annotationContent to empty array
          queryClient.setQueryData(queryKey, <O>(prev: O) => ({
            ...prev,
            annotationContent: [],
          }));
        },
      };
    }) ?? [];

  const evaluationQueries = useQueries({ queries });

  if (
    !queriesEnabled.current &&
    evaluationQueries.some(query => !query.isLoading || query.isFetching)
  ) {
    queriesEnabled.current = true;
  }

  return { evaluationQueries };
};
