import { Add, Edit } from '@rossum/ui/icons';
import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';

export const SchemaEditorDrawerTitle = ({
  editPath,
  schemaConcept,
}: {
  editPath: string[];
  schemaConcept: OriginalAnyDatapointSchema[];
}) => {
  const intl = useIntl();

  const isEditingNewSchemaObject =
    // @ts-expect-error
    schemaConcept.getIn(editPath) === undefined;

  const isEditingSection = editPath.length === 1;

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {isEditingNewSchemaObject ? <Add /> : <Edit />}
      <Typography variant="h5">
        {intl.formatMessage({
          id: isEditingSection
            ? isEditingNewSchemaObject
              ? 'containers.settings.queueFields.section.add'
              : 'containers.settings.fields.editSection.title'
            : isEditingNewSchemaObject
              ? 'containers.settings.fields.add'
              : 'containers.settings.fields.edit',
        })}
      </Typography>
    </Stack>
  );
};
