import { Check } from '@rossum/ui/icons';
import { alpha, Box, Divider, Stack, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import TickIconBold from 'mdi-react/CheckBoldIcon';
import TickIcon from 'mdi-react/CheckIcon';
import { FormattedMessage } from 'react-intl';
import {
  AUTOMATION_PROCESS,
  FIELDS_VALUE_NOT_FOUND,
} from '../../../../constants/values';
import { whiteDarkBackground } from '../../../../lib/formaterValues';
import {
  AnyDatapointDataST,
  ValidationSources,
} from '../../../../types/datapoints';
import TooltipItem from '../../../DatapointTooltip/components/TooltipItem';
import { irrelevantTickInfo } from '../../helpers';
import styles from '../../style.module.sass';
import KeyboardShortcutsInfo from './KeyboardShortcutsInfo';

type ValidationSourcesContentProps = {
  data: AnyDatapointDataST;
  documentAutomated?: boolean;
};

export const ValidationSourcesContent = ({
  data,
  documentAutomated,
}: ValidationSourcesContentProps) => {
  const validationSources = get(
    data,
    'validationSources',
    []
  ) as Array<ValidationSources>;

  const confidenceScore =
    'content' in data ? data.content?.rirConfidence : undefined;

  const validatedByHuman = validationSources.includes('human');

  const validatedByOtherSources = validationSources.filter(
    source => source !== 'human'
  );

  return (
    <Stack
      divider={
        <Divider
          sx={{
            borderColor: theme => alpha(theme.palette.common.white, 0.05),
          }}
        />
      }
      spacing={1.5}
      sx={{ pb: 1 }}
    >
      {validatedByHuman && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Check fontSize="small" color="success" />
          <Typography
            variant="body2"
            color={theme => alpha(theme.palette.common.white, 0.6)}
          >
            <FormattedMessage id="components.documentValidation.validationSources.tooltip.human" />
          </Typography>
        </Stack>
      )}
      {validatedByOtherSources.length > 0 &&
        !irrelevantTickInfo(validatedByOtherSources, documentAutomated) && (
          <Stack spacing={1}>
            <Typography
              variant="body2"
              fontSize={13}
              color={theme => alpha(theme.palette.common.white, 0.6)}
            >
              <FormattedMessage
                id="components.documentValidation.validationSources.tooltip.otherSources.title"
                values={{ white: whiteDarkBackground }}
              />
            </Typography>
            {validatedByOtherSources.map(validationSource => {
              switch (validationSource) {
                case 'connector':
                case 'non_required':
                case 'additional_value':
                case 'default_value':
                case 'history':
                case 'data_matching':
                case 'checks':
                case 'table_suggester':
                  return (
                    <TooltipItem
                      key={validationSource}
                      icon={
                        <Check
                          fontSize="small"
                          sx={{
                            color: theme =>
                              alpha(theme.palette.common.white, 0.6),
                          }}
                        />
                      }
                      localeKey={`components.documentValidation.validationSources.tooltip.${validationSource}`}
                      helpLink={AUTOMATION_PROCESS}
                    />
                  );

                case 'not_found':
                  return documentAutomated ? (
                    <TooltipItem
                      key={validationSource}
                      icon={
                        <Check
                          fontSize="small"
                          sx={{
                            color: theme =>
                              alpha(theme.palette.common.white, 0.6),
                          }}
                        />
                      }
                      localeKey="components.documentValidation.validationSources.tooltip.not_found"
                      helpLink={FIELDS_VALUE_NOT_FOUND}
                    />
                  ) : null;

                case 'score':
                  return (
                    <TooltipItem
                      key={validationSource}
                      icon={
                        <Check
                          fontSize="small"
                          sx={{
                            color: theme =>
                              alpha(theme.palette.common.white, 0.6),
                          }}
                        />
                      }
                      localeKey="components.documentValidation.validationSources.tooltip.score"
                      helpLink={AUTOMATION_PROCESS}
                    >
                      <Box className={styles.ConfidenceScore}>
                        {confidenceScore?.toFixed(3) ?? '---'}
                      </Box>
                    </TooltipItem>
                  );

                default:
                  return (
                    <TooltipItem
                      key={validationSource}
                      icon={
                        <Check
                          fontSize="small"
                          sx={{
                            color: theme =>
                              alpha(theme.palette.common.white, 0.6),
                          }}
                        />
                      }
                      localeKey="components.documentValidation.validationSources.tooltip.other"
                      helpLink={AUTOMATION_PROCESS}
                    />
                  );
              }
            })}
          </Stack>
        )}
      <KeyboardShortcutsInfo />
    </Stack>
  );
};

type ValidationSourcesIconProps = {
  inFooter: boolean;
  data: AnyDatapointDataST;
  tickIconColor?: string | undefined;
};

export const ValidationSourcesIcon = ({
  inFooter,
  tickIconColor,
  data,
}: ValidationSourcesIconProps) => {
  const isSidebarDp = get(data, ['meta', 'sidebarDatapoint']);
  const allowedColors = ['green', 'gray', 'white'] as const;
  const color = allowedColors.find(c => c === tickIconColor);

  return inFooter ? (
    <TickIconBold
      className={clsx(
        styles.TickIconFooter,
        color && styles[`ColorByValidationSource-${color}`]
      )}
    />
  ) : isSidebarDp ? (
    <TickIcon
      className={clsx(
        styles.TickIcon,
        color && styles[`ColorByValidationSource-${color}`]
      )}
    />
  ) : (
    <TickIconBold
      className={clsx(
        styles.TickIcon,
        styles.TickIconSimpleMultivalue,
        color && styles[`ColorByValidationSource-${color}`]
      )}
    />
  );
};
