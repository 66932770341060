import { Url } from '@rossum/api-client';
import { DetailDrawer } from '@rossum/rossum-ui/DetailDrawer';
import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { WorkflowActivityDrawerContent } from './WorkflowActivityDrawerContent';

type WorkflowActivityDrawerProps = {
  annotationUrl: Url | undefined;
  onClose: () => void;
};

export const WorkflowActivityDrawer = ({
  annotationUrl,
  onClose,
}: WorkflowActivityDrawerProps) => {
  const intl = useIntl();
  return (
    <DetailDrawer
      PaperProps={{ elevation: 2 }}
      onClose={onClose}
      open={annotationUrl !== undefined}
      title={
        <Stack component="span" spacing={2} direction="row">
          {intl.formatMessage({
            id: 'components.workflowActivityDrawer.title',
          })}
        </Stack>
      }
    >
      {annotationUrl && (
        <WorkflowActivityDrawerContent annotationUrl={annotationUrl} />
      )}
    </DetailDrawer>
  );
};
