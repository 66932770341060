import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SidebarNavLink from '../../../components/Sidebar/SidebarNavLink';
import { LocalizationKeys } from '../../../i18n';

type Props = {
  type: 'queue' | 'user';
  items: ReadonlyArray<{
    name: string;
    text: LocalizationKeys;
    Icon: React.ElementType;
  }>;
} & RouteComponentProps;

const DetailSidebarItems = ({ match, location, type, items }: Props) => {
  return (
    <>
      {items.map(({ name, text, Icon }) => (
        <SidebarNavLink
          icon={<Icon />}
          key={`${type}-sidebar-${name}`}
          text={text}
          to={{
            pathname: `${match.url}/${name}`,
            state: location.state,
          }}
          replace
          dataCy={`${type}-sidebar-${name}-link`}
        />
      ))}
    </>
  );
};

export default DetailSidebarItems;
