import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { getType } from 'typesafe-actions';
import { isLoggableDatapoint } from '../../components/Datapoint/helpers';
import {
  logBboxCreated,
  logDatapointFocused,
  logDatapointValueChanged,
  logSuggestedPositions,
} from '../../lib/gtm';
import { initializeTracking } from '../../lib/tracking';
import { Next } from '../../types/redux';
import { State } from '../../types/state';
import {
  bboxCreatedAction,
  onDatapointSelection,
  recountDatapointPositionFulfilled,
  resetDatapointFulfilled,
  updateDatapointValueFulfilled,
} from '../modules/datapoints/actions';
import { findDatapointIndex } from '../modules/datapoints/navigation/findDatapointIndex';
import { fetchSuggestedPositionsForValueFulfilled } from '../modules/datapoints/suggestedPositionsForValue/actions';
import { repeateRequest } from '../modules/repeatedRequests/actions';
import { initializeTrackingAction } from '../modules/tracking/actions';
import { RootActionType } from '../rootActions';

let trialTracking = false;

export default (store: MiddlewareAPI<Dispatch<AnyAction>, State>) =>
  (next: Next) =>
  (action: RootActionType) => {
    if (action.type === getType(initializeTrackingAction)) {
      initializeTracking(
        action.payload,
        store.getState().user,
        store.getState().groups,
        store.getState().organizationGroup.current?.isProduction ?? null,
        store.getState().organization.internalInfo?.marketCategory ?? null
      );
      trialTracking = action.payload;
    }

    if (trialTracking && window.smartlook) {
      switch (action.type) {
        case getType(repeateRequest): {
          window.smartlook('track', 'repeated_request');
          break;
        }

        default:
          break;
      }
    }

    // log bbox events in GTM
    switch (action.type) {
      case getType(bboxCreatedAction): {
        logBboxCreated({
          annotationId: store.getState().annotation.id,
          schemaId: action.payload.schemaId,
        });
        break;
      }
      case getType(fetchSuggestedPositionsForValueFulfilled): {
        logSuggestedPositions({
          annotationId: store.getState().annotation.id,
          schemaId: action.meta.schemaId,
          numberOfResults: action.payload.length,
        });
        break;
      }
      case getType(onDatapointSelection): {
        const {
          meta: { id },
        } = action;

        if (!id) {
          break;
        }

        const datapointIndex = findDatapointIndex(
          store.getState().datapoints.content,
          id
        );

        if (datapointIndex !== -1) {
          const dp = store.getState().datapoints.content[datapointIndex];

          // if focusing a datapoint, log focus event
          if (dp && isLoggableDatapoint(dp)) {
            logDatapointFocused({
              annotationId: store.getState().annotation.id,
              schemaId: dp.schemaId,
              required: dp.schema?.constraints?.required ?? true,
              type: dp.schema?.type,
              validationSources: dp.validationSources,
            });
          }
        }
        break;
      }
      case getType(updateDatapointValueFulfilled):
      case getType(recountDatapointPositionFulfilled):
      case getType(resetDatapointFulfilled): {
        if (!action.meta) {
          break;
        }

        const {
          meta: { schemaId, reason, oldValue, newValue },
        } = action;

        logDatapointValueChanged({
          annotationId: store.getState().annotation.id,
          schemaId,
          reason,
          hasValueChanged: oldValue !== newValue,
        });
        break;
      }
      default:
        break;
    }
    return next(action);
  };
