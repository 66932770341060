import { MenuItem, Skeleton, Stack } from '@rossum/ui/material';
import { random } from 'lodash';
import { useRef } from 'react';

const MenuItemSkeleton = () => {
  const [width, multiplier] = useRef<[number, number]>([
    random(100, 150),
    random(1.5, 1.8),
  ]).current;

  return (
    <MenuItem disabled sx={{ opacity: '1 !important' }}>
      <Stack>
        <Skeleton
          width={width}
          sx={{
            fontSize: ({ typography }) => typography.body2.fontSize,
          }}
        />
        <Skeleton
          width={width * multiplier}
          sx={{
            fontSize: ({ typography }) => typography.body2.fontSize,
          }}
        />
      </Stack>
    </MenuItem>
  );
};

export default MenuItemSkeleton;
