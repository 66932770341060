import {
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Queue } from '@rossum/api-client/queues';
import { SchemaColumn } from '@rossum/api-client/shared';
import { Stack } from '@rossum/ui/material';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import SortableWrapper from '../../../../components/Dnd/SortableWrapper';
import { getColumnName, preserveMissingColumns } from '../helpers';
import { usePatchTableConfig } from '../hooks/usePatchTableConfig';
import { ColumnItem } from './ColumnItem';
import { PanelColumn } from './helpers';

type Props = {
  columns: Array<PanelColumn>;
  activeQueue: Queue | null;
  handleRemoveColumn: (column: SchemaColumn) => void;
};

export const ColumnList = ({
  columns,
  activeQueue,
  handleRemoveColumn,
}: Props) => {
  // local state is needed otherwise the ui of drag & drop does not work
  const [savedColumns, setSavedColumns] = useState(columns);
  const [draggedColumn, setDraggedColumn] = useState<PanelColumn | null>(null);
  const { updateColumns } = usePatchTableConfig({
    activeQueue,
  });

  const debouncedUpdate = useMemo(
    () => debounce(updateColumns, 300),
    [updateColumns]
  );

  const toggleColumnVisibility = (updatedColumnName: string) => () => {
    const updatedColumns = savedColumns.map(col =>
      getColumnName(col) === updatedColumnName
        ? { ...col, visible: !col.visible }
        : col
    );

    setSavedColumns(updatedColumns);
    debouncedUpdate(
      preserveMissingColumns({
        previousColumns: columns,
        updatedColumns,
      })
    );
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = savedColumns.findIndex(
        col => getColumnName(col) === active.id
      );
      const overIndex = savedColumns.findIndex(
        col => getColumnName(col) === over?.id
      );

      const reorderedColumns = arrayMove(savedColumns, activeIndex, overIndex);
      setSavedColumns(reorderedColumns);

      const updatedColumns = preserveMissingColumns({
        previousColumns: columns,
        updatedColumns: reorderedColumns,
      });

      updateColumns(updatedColumns);
    }
  };

  const removeColumn = (col: SchemaColumn) => {
    setSavedColumns(prev =>
      prev.filter(c => getColumnName(c) !== getColumnName(col))
    );
    handleRemoveColumn(col);
  };

  const renderColumn = (col: PanelColumn, id?: string, isOverlay?: boolean) => {
    const columnName = getColumnName(col);
    return !col.isColumnFilteredOut ? (
      <SortableWrapper
        // using column name (metaName or schemaId) would cause problems on all docs level where we can have duplicate ids of schema columns
        id={id ?? columnName}
        key={columnName}
        render={dragHandleProps => (
          <ColumnItem
            dragHandleProps={dragHandleProps}
            onToggleVisiblity={toggleColumnVisibility(getColumnName(col))}
            onRemoveColumn={
              col.columnType === 'schema' ? () => removeColumn(col) : undefined
            }
            isDraggable
            column={{
              columnType: col.columnType,
              visible: col.visible,
              headerName: col.headerName,
              label:
                col.columnType === 'schema'
                  ? `${col.schemaId} (${col.columnType})`
                  : undefined,
            }}
            isOverlay={isOverlay}
          />
        )}
      />
    ) : null;
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={({ active }) => {
        setDraggedColumn(
          savedColumns.find(col => getColumnName(col) === active.id) ?? null
        );
      }}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={savedColumns.map(col => getColumnName(col))}>
        <Stack spacing={1} overflow="auto">
          {savedColumns.map(col => renderColumn(col))}
        </Stack>
      </SortableContext>
      <DragOverlay dropAnimation={defaultDropAnimation}>
        {draggedColumn
          ? renderColumn(
              draggedColumn,
              `${getColumnName(draggedColumn)}-overlay`,
              true
            )
          : null}
      </DragOverlay>
    </DndContext>
  );
};
