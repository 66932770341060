import { endpoints, getIDFromUrl, Url, withSideload } from '@rossum/api-client';
import { useInfiniteQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { getNextPageFromUrl } from '../helpers/getNextPageFromUrl';
import { queueInfoKeys } from '../keys';

type QueuesInfo = {
  workspace: string;
  queue: string;
  trainingEnabled: boolean;
  id: number;
};

export type EngineQueuesInfo = {
  totalCount?: number;
  info: QueuesInfo[];
};

export const useInfiniteQueueStatsForEngine = (engineUrl: Url) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: queueInfoKeys.stats(engineUrl),
    queryFn: async ({ pageParam = 1 }) => {
      const response = await api.request(
        withSideload(
          endpoints.engines.queueStats(getIDFromUrl(engineUrl), {
            page: pageParam,
          }),
          { queues: true }
        )
      );

      const workspacesIds = response.queues.flatMap(queue =>
        queue.workspace ? [getIDFromUrl(queue.workspace)] : []
      );

      const workspaces = await api.request(
        endpoints.workspaces.list({ id: workspacesIds })
      );

      const data = response.queues.map(queue => {
        const workspace = workspaces.results.find(
          workspace => workspace.url === queue.workspace
        );

        const stats = response.results.find(item => item.url === queue.url);

        return {
          queue,
          workspace,
          stats,
        };
      });

      return {
        data,
        pagination: response.pagination,
      };
    },
    getNextPageParam: data => getNextPageFromUrl(data.pagination.next),
  });

  const data = {
    results: infiniteQuery.data?.pages.flatMap(page => page.data) ?? [],
    totalCount: infiniteQuery.data?.pages[0]?.pagination.total ?? 0,
  };

  return {
    ...infiniteQuery,
    data,
  };
};
