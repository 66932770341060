import { History } from 'history';
import { useEffect } from 'react';

type UserIDEvent = {
  event: 'elis-user identification';
  eventData: {
    user_id: number;
    user_role: number;
    organization_id: number | 'unknown organization ID';
    is_trial: boolean;
    is_production: boolean | null;
    environment: string | undefined;
    market_category: string | null;
  };
};

/* eslint-disable */
export const initGTM = (
  gtmId: string,
  userIdenteficationEvent: UserIDEvent
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(userIdenteficationEvent);

  ((w: Window, d: Document, s: 'script', l: 'dataLayer', i: string) => {
    w[l] = w[l] || [];
    w[l]?.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  })(window, document, 'script', 'dataLayer', gtmId);

  const gtm = document.createElement('script');
  gtm.async = true;
  gtm.defer = true;
  gtm.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
  document.head.appendChild(gtm);
};

export const useLogPageView = (history: History) => {
  let previousPathname: string = history.location.pathname;
  let previousQuery: string | null = null;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    const pushPageView = (pageUrl: string) => {
      window.dataLayer?.push({
        event: 'virtualPageview',
        pageUrl: pageUrl,
      });
    };

    // to push the initial landing
    pushPageView(previousPathname.replace(/\d+/g, ''));

    return history.listen((location, action) => {
      const { pathname, search } = location;
      const pathnameWithoutNumbers = pathname.replace(/\d+/g, '');

      // paths that use query to handle tab changes and pagination
      const isPathWithPagination = [
        'annotations',
        'settings/extensions',
        'users',
      ].some(pathsWithPagination => pathname.includes(pathsWithPagination));

      const shouldListenToQuery =
        !!search && isPathWithPagination && previousQuery !== search;

      switch (action) {
        case 'REPLACE':
          // when clicking on queue settings we want to avoid the initial replace action
          // e.g. settings/queues/1018 -> settings/queues/1018/settings
          const isIdleRedirect = [
            'settings',
            'fields',
            'extensions',
            'emails',
            'automation',
            'access',
          ].some(subPath => previousPathname + '/' + subPath === pathname);

          // to account for postpone or confirm the last annotation in validation page
          const isFinalDocValidationAction =
            pathname.includes('annotations') &&
            previousPathname.includes('document');

          // in profile(account), queue settings and document validation page we want to keep track of REPLACE actions
          const isValidReplace =
            ['account', 'settings', 'document'].some(pathsThatUseReplace =>
              pathname.includes(pathsThatUseReplace)
            ) || isFinalDocValidationAction;

          const shouldPushPageView =
            isValidReplace && previousPathname !== pathname && !isIdleRedirect;

          if (shouldPushPageView) {
            pushPageView(pathnameWithoutNumbers);
          }
          previousPathname = pathname;
          break;
        default:
          if (pathname !== previousPathname) {
            pushPageView(pathnameWithoutNumbers);
            previousPathname = pathname;
          } else if (shouldListenToQuery) {
            pushPageView(pathnameWithoutNumbers);
            previousQuery = search;
          }
      }
    });
  }, []);
};

export const logBookDemo = (
  bookDemoLocation: string | undefined,
  selectedDocType?: string | null
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'bookDemo',
    source: bookDemoLocation,
    documentType: selectedDocType,
  });
};

// bboxes events
type BaseLogEventData = {
  annotationId: number | null | undefined;
};

type BboxCreateEvent = BaseLogEventData & {
  schemaId: string | null | undefined;
};

export const logBboxCreated = (event: BboxCreateEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'bbox-created',
    ...event,
  });
};

type BboxSuggestEvent = BaseLogEventData & {
  schemaId: string | null | undefined;
};

// TODO: Should this be used somewhere?
// export const logSuggestedBboxClicked = (event: BboxSuggestEvent) => {
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({
//     event: 'bbox-suggested',
//     ...event,
//   });
// };

type SuggestedPositionsEvent = BaseLogEventData & {
  schemaId: string | null | undefined;
  numberOfResults: number;
};

export const logSuggestedPositions = (event: SuggestedPositionsEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'suggested-positions-requested',
    ...event,
  });
};

type DatapointFocusEvent = BaseLogEventData & {
  schemaId: string | null | undefined;
  validationSources: string[] | null | undefined;
  required: boolean | null | undefined;
  type: string | null | undefined;
};

export const logDatapointFocused = (event: DatapointFocusEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'datapoint-focused',
    ...event,
  });
};

type DatapointValueChangeEvent = BaseLogEventData & {
  schemaId: string | null | undefined;
  reason:
    | 'input-sidebar'
    | 'input-edit-box'
    | 'suggested-bbox'
    | 'suggested-position-for-value'
    | 'create-bbox'
    | 'resize-bbox'
    | 'move-bbox'
    | 'reset';
  hasValueChanged: boolean;
};

export const logDatapointValueChanged = (event: DatapointValueChangeEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'datapoint-value-changed',
    ...event,
  });
};
