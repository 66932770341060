import { Page } from '@rossum/api-client/pages';
import { Box, CircularProgress } from '@rossum/ui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { asMutable } from 'seamless-immutable';
import { useShallow } from 'zustand/react/shallow';
import Loader from '../../../containers/Loader';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { hasDataAvailable } from '../../../redux/modules/annotation/helpers';
import {
  canCreateRectangleSelector,
  currentDatapointSelector,
} from '../../../redux/modules/datapoints/selector';
import { isBoundedDatapoint } from '../../../redux/modules/datapoints/typedHelpers';
import { State } from '../../../types/state';
import { Rectangle2D } from '../document-canvas/utils/geometry';
import { isRectangleOverlapping } from '../document-store/documentGeometry';
import {
  useCanvasSelectionActions,
  useDocumentStore,
} from '../document-store/DocumentStore';
import { visiblePagesZustandSelector } from '../document-store/documentStoreSelectors';
import { SuggestedPositionDialogSvg } from './document-dialogs/SuggestedPositionDialogSvg';
import { ValidationDialogSvg } from './document-dialogs/ValidationDialogSvg';
import { DocumentCanvasGroup } from './DocumentCanvasGroup';
import { DocumentCanvasSvg } from './DocumentCanvasSvg';
import { LineItemsSVGContainer } from './line-items/LineItemsSVGContainer';
import { ConnectedMagicLine } from './magic-line/ConnectedMagicLine';
import { PageContainer } from './page/PageContainer';
import { CreatedRectangle } from './rectangle-creator/CreatedRectangle';
import { useCreateBoundingBox } from './rectangle-creator/useCreateBoundingBox';
import { useDrawBoundingBox } from './rectangle-creator/useDrawBoundingBox';
import { useDrawSelection } from './rectangle-creator/useDrawSelection';
import { useScrollToActiveDatapoint } from './scroll-to-bbox/useScrollToActiveDatapoint';
import { useScrollToActiveSearchResultBox } from './scroll-to-bbox/useScrollToActiveSearchResultBox';
import { useScrollToSuggestedPosition } from './scroll-to-bbox/useScrollToSuggestedPosition';
import { DocumentScrollbars } from './scrollbars/DocumentScrollbars';
import { IntermediateSelection } from './selection-span/IntermediateSelection';
import { SelectionSpan } from './selection-span/SelectionSpan';
import { useSelectionSpanRectangle } from './selection-span/useSelectionSpanRectangle';
import { useCanvasBoundingBoxes } from './useCanvasBoundingBoxes';
import { UseCanvasDimensions } from './useCanvasDimensions';
import { useCanvasScrollOnEdges } from './useCanvasScrollOnEdges';
import useDeselectDatapoint from './useDeselectDatapoint';
import { useKeyPressed } from './useKeyPressed';

type DocumentCanvasProps = {
  pages: Page[];
  dimensions: UseCanvasDimensions;
};

export const DocumentCanvas = ({ pages, dimensions }: DocumentCanvasProps) => {
  const visiblePages = useDocumentStore(
    useShallow(visiblePagesZustandSelector(dimensions))
  );

  useScrollToActiveDatapoint({
    dimensions,
  });

  useScrollToSuggestedPosition({
    dimensions,
  });

  useScrollToActiveSearchResultBox({
    dimensions,
  });

  useCanvasScrollOnEdges();

  useDeselectDatapoint();

  // Rectangle creator
  const { setSelectedBboxes } = useCanvasSelectionActions();
  const canvasBoundingBoxes = useCanvasBoundingBoxes({ dimensions });

  const selectDatapointsInRectangle = useCallback(
    (rectangle: Rectangle2D) => {
      const boxes = canvasBoundingBoxes.filter(({ boxRectangle }) =>
        isRectangleOverlapping(boxRectangle)(rectangle)
      );

      setSelectedBboxes(boxes.map(box => box.box.id));
    },
    [canvasBoundingBoxes, setSelectedBboxes]
  );

  const shiftKeyPressed = useKeyPressed(['Shift']);
  const canCreateBoundingBox = useSelector(canCreateRectangleSelector);

  const { handleMouseDown: handleMouseDownSelection, selection } =
    useDrawSelection({
      onFinish: selectDatapointsInRectangle,
    });

  const { createBoundingBox } = useCreateBoundingBox();
  const { handleMouseDown: handleMouseDownBoundingBox, boundingBox } =
    useDrawBoundingBox({ onFinish: createBoundingBox });

  const intermediateBoxes = selection
    ? canvasBoundingBoxes.filter(({ boxRectangle }) =>
        isRectangleOverlapping(boxRectangle)(selection)
      )
    : [];

  const selectionCursor = selection ? true : shiftKeyPressed;
  const bboxCursor = canCreateBoundingBox && !selectionCursor;
  const selectionSpanRectangle = useSelectionSpanRectangle({
    canvasBoundingBoxes,
  });

  // Loading states
  const annotationStatus = useSelector((state: State) =>
    snakeToCamel(state.annotation.status!)
  );

  const restrictedAccess = useSelector(
    (state: State) => !!state.annotation.restrictedAccess
  );

  const isImporting = annotationStatus === 'importing';

  const displayPagesLoader = hasDataAvailable({
    status: annotationStatus,
    pages,
    restrictedAccess,
  });

  // Dialogs
  const isSuggestingPositionsForValue = useSelector(
    (state: State) => state.ui.isSuggestingPositionsForValue
  );
  const activeDatapoint = useSelector(currentDatapointSelector);
  const activeDatapointPosition = canvasBoundingBoxes.find(
    box => box.box.id === activeDatapoint?.id
  )?.boxRectangle;

  return isImporting ? (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        overflow: 'hidden',
      }}
      data-cy="loading-img-wrapper"
    >
      <CircularProgress size={100} />
    </Box>
  ) : pages.length > 0 && dimensions.canvas.height > 0 ? (
    <>
      {/* This SVG (and its viewbox) define a coordinate system within the entire document canvas */}
      <DocumentCanvasSvg
        cursor={selectionCursor ? 'cell' : undefined}
        handleMouseDown={e => {
          // Mouse down events from selection / bbox drawing.
          // They trigger on disjunct conditions (shift / no shift)
          handleMouseDownSelection(e);
          handleMouseDownBoundingBox(e);
        }}
        dimensions={dimensions}
      >
        {/* This will be the group we will be applying global transformations (zoom = scale, scroll = translate) to */}
        {/* Should move all pages together */}
        <DocumentCanvasGroup>
          {asMutable(pages).map((page, i) => (
            <PageContainer
              cursor={bboxCursor ? 'crosshair' : undefined}
              isVisible={visiblePages[page.number] ?? false}
              key={page.number}
              page={page}
              pageDimensions={dimensions.pages[i]?.dimensions}
            />
          ))}
          {selection ? <CreatedRectangle rectangle={selection} /> : null}
          {boundingBox ? <CreatedRectangle rectangle={boundingBox} /> : null}
          {selectionSpanRectangle && (
            <SelectionSpan rectangle={selectionSpanRectangle} />
          )}
          {intermediateBoxes.map(box => (
            <IntermediateSelection box={box.boxRectangle} key={box.box.id} />
          ))}
          {activeDatapoint && isBoundedDatapoint(activeDatapoint) ? (
            <ConnectedMagicLine
              pageNumber={activeDatapoint.content.page}
              position={activeDatapoint.content.position}
              sourceElementId="magic-line-source"
            />
          ) : null}
        </DocumentCanvasGroup>
        <g>
          <LineItemsSVGContainer
            dimensions={dimensions}
            visiblePages={visiblePages}
          />
        </g>
      </DocumentCanvasSvg>
      <DocumentScrollbars canvasDimensions={dimensions.canvas} />

      <div
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        {isSuggestingPositionsForValue ? (
          <SuggestedPositionDialogSvg dimensions={dimensions} />
        ) : (
          <ValidationDialogSvg
            position={selectionSpanRectangle ?? activeDatapointPosition}
          />
        )}
      </div>
    </>
  ) : displayPagesLoader ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        height: '100%',
      }}
    >
      <Loader />
    </Box>
  ) : null;
};
