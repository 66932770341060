import { Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

type Props = { children: ReactNode; fullWidth: boolean };

const FieldManagerWrapper = ({ children, fullWidth }: Props) => {
  return (
    <Stack
      data-page-title="field-manager"
      p={fullWidth ? 0 : 3}
      pb={0}
      ml={fullWidth ? '0px' : '300px'}
      height="100%"
    >
      {children}
    </Stack>
  );
};

export default FieldManagerWrapper;
