import { uniq } from 'lodash';
import { asArray } from '../../lib/url';
import { annotationStatuses, Status } from '../../types/annotationStatus';
import {
  ALL_DOCUMENTS_TAB,
  AnnotationTabType,
  CONFIRMED_TAB,
  EXPORT_TAB,
  FilterableAnnotationOptions,
  IN_WORKFLOW_TAB,
  POSTPONED_TAB,
  REJECTED_TAB,
  REVIEW_TAB,
  TRASH_TAB,
} from './types';

export const getDashboardPath = () => '/documents';

export const defaultTabStatuses: Array<Status> = [
  'toReview',
  'reviewing',
  'importing',
  'failedImport',
];

const postponeStatuses: Array<Status> = ['postponed'];
const confirmedStatuses: Array<Status> = ['confirmed'];

const deletedStatuses: Array<Status> = ['deleted'];
const rejectedStatuses: Array<Status> = ['rejected'];
const exportedStatuses: Array<Status> = [
  'exporting',
  'exported',
  'failedExport',
];
const inWorkflowStatuses: Array<Status> = ['inWorkflow'];

export const allTabStatuses: Array<Status> = [
  defaultTabStatuses,
  postponeStatuses,
  confirmedStatuses,
  exportedStatuses,
  deletedStatuses,
  rejectedStatuses,
  inWorkflowStatuses,
].flat();

export const defaultTabsSorting = [
  ALL_DOCUMENTS_TAB,
  REVIEW_TAB,
  POSTPONED_TAB,
  IN_WORKFLOW_TAB,
  CONFIRMED_TAB,
  EXPORT_TAB,
  REJECTED_TAB,
  TRASH_TAB,
] as AnnotationTabType[];

const createdAtOption: FilterableAnnotationOptions[] = ['created_at'];

const baseOptions: FilterableAnnotationOptions[] = [
  ...createdAtOption,
  'modified_at',
];
const afterConfirmOptions: FilterableAnnotationOptions[] = [
  ...baseOptions,
  'confirmed_at',
];
const afterDeleteOptions: FilterableAnnotationOptions[] = [
  ...baseOptions,
  'deleted_at',
];
const afterRejectOptions: FilterableAnnotationOptions[] = [
  ...baseOptions,
  'rejected_at',
];
const afterExportOptions: FilterableAnnotationOptions[] = [
  ...afterConfirmOptions,
  'exported_at',
];

const availableFilters: Record<Status, FilterableAnnotationOptions[]> = {
  created: [],
  importing: createdAtOption,
  toReview: baseOptions,
  reviewing: baseOptions,
  failedImport: baseOptions,
  postponed: baseOptions,
  confirmed: afterConfirmOptions,
  exporting: afterConfirmOptions,
  exported: afterExportOptions,
  failedExport: afterExportOptions,
  deleted: afterDeleteOptions,
  rejected: afterRejectOptions,
  inWorkflow: baseOptions,
  purged: [],
  split: [],
};

export const asAnnotationStatuses = (
  stringStatus: string | string[]
): Status[] =>
  annotationStatuses.filter(status => asArray(stringStatus).includes(status));

export const getAvailableFilters = (statuses: Status[]) =>
  uniq(statuses.flatMap(status => availableFilters[status]));
