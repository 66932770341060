import { format, isSameDay } from 'date-fns';
import {
  getOperatorLocalisationKey,
  isFilterContextSimple,
  isFilterSimple,
} from '../../helpers';
import { FilterOperator } from '../../operators';
import { AdvancedFilterItem, FilterItem, FilterValues } from '../../types';
import {
  checkIfOperatorIsDate,
  getValidDate,
  processAdvancedOperatorValues,
  resolveDateOperatorValue,
} from '../DateTimePicker/utils';
import { isOperatorEmpty } from '../Operators/utils';

type SelectedOptions =
  | {
      operatorKey: ReturnType<typeof getOperatorLocalisationKey>;
      selectedOption: string;
      advancedFiltersCount?: number;
    }[]
  | null;

type ChipLabel = {
  columnLabel: string;
  selectedOptions: SelectedOptions;
};

const resolveStringFieldValue = (value: unknown) =>
  typeof value === 'string'
    ? value
    : Array.isArray(value)
      ? value.join(', ')
      : '';

const textNormalizer = ({ filterContext }: FilterItem): SelectedOptions =>
  isFilterContextSimple(filterContext)
    ? [
        {
          operatorKey: getOperatorLocalisationKey(filterContext.operator),
          selectedOption: resolveStringFieldValue(filterContext.value),
        },
      ]
    : null;

const multiSelectNormalizer = (filterItem: FilterItem): SelectedOptions => {
  const { filterContext, column } = filterItem;
  if (!isFilterContextSimple(filterContext) || !column.valueOptions)
    return null;

  const selectedValues = Array.isArray(filterContext.value)
    ? filterContext.value
    : [filterContext.value];

  return [
    {
      operatorKey: getOperatorLocalisationKey(filterContext.operator),
      selectedOption: column.valueOptions
        .filter(({ value }) =>
          selectedValues.some(selectedValue => selectedValue === value)
        )
        .map(v => v.label)
        .join(', '),
    },
  ];
};

const formatDateTexts = (
  dates: ReturnType<typeof processAdvancedOperatorValues>,
  dateFormatOverride?: string
): [string, string] => {
  const { startingDate, endingDate } = dates;

  if (!startingDate || !endingDate) return ['', ''];

  const m = (date: Date) => {
    if (dateFormatOverride) {
      return format(date, dateFormatOverride);
    }
    const isSameYear = endingDate.getFullYear() === startingDate.getFullYear();
    return isSameYear ? format(date, 'do MMM') : format(date, 'do MMM Y');
  };

  return [m(endingDate), m(startingDate)];
};

const dateNormalizer = ({
  filterContext,
  column,
}: FilterItem): SelectedOptions => {
  if (isFilterContextSimple(filterContext)) {
    const validDate = getValidDate(filterContext.value);

    return validDate
      ? [
          {
            operatorKey: getOperatorLocalisationKey(filterContext.operator),
            selectedOption: format(validDate, 'do MMM Y'),
          },
        ]
      : null;
  }

  const { endingDate, startingDate } = processAdvancedOperatorValues({
    filterContext,
    column,
  });

  const operatorKey = resolveDateOperatorValue({ endingDate, startingDate });

  const [formattedEndingDate, formattedStartingDate] = formatDateTexts({
    endingDate,
    startingDate,
  });

  const areDatesSameDay =
    endingDate && startingDate && isSameDay(endingDate, startingDate);

  if (operatorKey) {
    return areDatesSameDay
      ? [
          {
            operatorKey: `date.${operatorKey}`,
            selectedOption: formattedStartingDate,
          },
        ]
      : [
          { operatorKey: 'date.after', selectedOption: formattedStartingDate },
          { operatorKey: 'date.before', selectedOption: formattedEndingDate },
        ];
  }

  return null;
};

const simpleFilterNormalizers: Record<
  FilterOperator['type'],
  (filterItem: FilterItem) => SelectedOptions
> = {
  string: textNormalizer,
  number: textNormalizer,
  multiSelect: multiSelectNormalizer,
  date: dateNormalizer,
} as const;

const labelNormalizers = {
  simpleFilters: simpleFilterNormalizers,
  advancedFilters: {},
} as const;

export const getChipLabel = (filterItem: FilterItem): ChipLabel => {
  const { column, filterContext } = filterItem;
  const columnLabel = column.headerName || column.field || '';

  if (isFilterContextSimple(filterContext)) {
    const selectedOptions: SelectedOptions = isOperatorEmpty(
      filterContext.operator
    )
      ? [
          {
            operatorKey: getOperatorLocalisationKey(filterContext.operator),
            selectedOption: '',
          },
        ]
      : labelNormalizers.simpleFilters[filterContext.operator.type](filterItem);

    return {
      columnLabel,
      selectedOptions,
    };
  }

  const selectedOptions: SelectedOptions = checkIfOperatorIsDate(filterContext)
    ? labelNormalizers.simpleFilters.date(filterItem)
    : null;

  return {
    columnLabel,
    selectedOptions,
  };
};

const getFullDatesForTooltipLabel = (filterItem: FilterItem): string => {
  const { filterContext, column } = filterItem;
  const formatQuery = 'yyyy-MM-dd HH:mm';

  if (checkIfOperatorIsDate(filterContext)) {
    if (isFilterContextSimple(filterContext)) {
      const validDate = getValidDate(filterContext.value);
      return format(validDate ?? new Date(), formatQuery);
    }

    const processedDates = processAdvancedOperatorValues({
      filterContext,
      column,
    });
    const [endingDate, startingDate] = formatDateTexts(
      processedDates,
      formatQuery
    );

    return `${startingDate} - ${endingDate}`;
  }

  return '';
};

export const getDefaultFilterRulesState = ({
  column,
  filterContext,
}: AdvancedFilterItem): FilterValues[] => {
  const filterRule = filterContext[0];

  if (filterRule && filterContext.length === 1) {
    const operatorForPreparedFilter = column.operators.find(
      o => o.value !== filterRule.operator.value
    );

    const preparedRule = operatorForPreparedFilter
      ? [{ operator: operatorForPreparedFilter }]
      : [];

    return [filterRule, ...preparedRule];
  }

  return filterContext;
};

const getStringTooltip = (filterItem: FilterItem) => {
  if (!isFilterSimple(filterItem)) return '';
  const {
    filterContext: { value },
  } = filterItem;
  return resolveStringFieldValue(value);
};

const getMultiSelectTooltip = (filterItem: FilterItem) => {
  if (!isFilterSimple(filterItem)) return '';
  const {
    filterContext: { value: incomingValue },
    column,
  } = filterItem;

  const tooltip = Array.isArray(incomingValue)
    ? column.valueOptions
        ?.filter(option => incomingValue.includes(option.value))
        .map(option => option.label)
        .join(', ')
    : '';

  return tooltip ?? '';
};

export const tooltipNormalizers: Record<
  FilterOperator['type'],
  (fitlerItem: FilterItem) => string
> = {
  date: getFullDatesForTooltipLabel,
  string: getStringTooltip,
  number: getStringTooltip,
  multiSelect: getMultiSelectTooltip,
} as const;

export const getChipTooltip = (filterItem: FilterItem) => {
  if (isFilterSimple(filterItem)) {
    return tooltipNormalizers[filterItem.filterContext.operator.type](
      filterItem
    );
  }

  if (checkIfOperatorIsDate(filterItem.filterContext))
    return tooltipNormalizers.date(filterItem);

  return '';
};
