import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { asMutable } from 'seamless-immutable';
import HiddingButton from '../../../../../components/UI/HiddingButton';
import { CurrentSchemaConceptContext } from '../../../../../features/formulas/FormulaPreview/contexts/currentSchema/currentSchemaContext';
import {
  EditableSchemaObject,
  SectionSchemaObject,
} from '../../../../../types/schema';
import { State } from '../../../../../types/state';
import { usePendingChanges } from '../helpers';
import styles from '../style.module.sass';
import {
  BackendValidationStatus,
  formDataToDatapoint,
  formStateToEntity,
  ResolverContext,
  SchemaObjectFormData,
} from '../types';
import { schemaResolver } from './schemaResolver';
import { SimpleFields } from './SimpleFields';
import SimpleMultivalueFields from './SimpleMultivalueFields';
import TableMultivalueFields from './TableMultivalueFields';
import { usePropagationInMultivalues } from './usePropagationInMultivalues';
import { useTriggerIdUniquenessValidation } from './useTriggerIdUniquenessValidation';

type SchemaObjectFormProps = {
  datapoint: EditableSchemaObject;
  onSave: (_datapoint: EditableSchemaObject) => void;
  setPendingChanges?: (_: boolean) => void;
  validateSchema: (_datapoint: EditableSchemaObject) => void;
  validationTimeStamp?: number;
  schemaObjectPath: string;
  schemaConcept: SectionSchemaObject[];
};

const SchemaObjectForm = ({
  datapoint,
  onSave,
  setPendingChanges,
  validateSchema,
  validationTimeStamp,
  schemaObjectPath,
  schemaConcept,
}: SchemaObjectFormProps) => {
  const intl = useIntl();
  const [backendValidationStatus, setBackendValidationStatus] =
    useState<BackendValidationStatus>('never');

  const defaultValues = formStateToEntity(datapoint);

  const rawValidationErrors =
    useSelector((s: State) => s.schema.rawValidationErrors) ?? [];

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    getValues,
    trigger,
    setValue,
  } = useForm<SchemaObjectFormData, ResolverContext>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
    context: {
      backendValidationStatus,
      rawValidationErrors,
      schemaObjectPath,
      schemaConcept,
    },
    resolver: schemaResolver(intl),
  });

  usePropagationInMultivalues({ watch, setValue }, 'hidden');
  usePropagationInMultivalues({ watch, setValue }, 'canExport');

  useTriggerIdUniquenessValidation({
    watch,
    trigger,
    enabled: backendValidationStatus === 'never',
  });

  // Notify parent component about pending changes
  usePendingChanges(watch, defaultValues, setPendingChanges);

  const submit = handleSubmit(data => {
    onSave(formDataToDatapoint(data));
  });

  // Backend validation request - do it on each change after local validation succeeds for the first time
  watch(() => {
    if (backendValidationStatus === 'never') return;

    setBackendValidationStatus('waiting');
    validateSchema(formDataToDatapoint(getValues()));
  });

  // Backend validation response
  useEffect(() => {
    // Don't do anything on initial render
    if (backendValidationStatus === 'never') return;

    if (
      backendValidationStatus === 'waiting-autosave' &&
      rawValidationErrors.length === 0
    ) {
      submit();
    }

    setBackendValidationStatus('validated');

    // Trigger resolver again, this time with new validation results from backend
    trigger();

    // This useEffect should be executed only when new validation results arrive
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationTimeStamp]);

  const canSubmit = (() => {
    if (backendValidationStatus === 'never') {
      // 1. We are validating locally

      // Always show submit button because user doesn't have to see errors
      // for fields which were not touched.
      // I also tried switching to isValid after first submit, but
      // there are still issues when the shape of the form changes (adding columns for example)
      // so the conditions became unbearably complex.
      return true;
    }

    // 2. We are validating on backend
    // The form has to be valid, and we are not waiting for backend validation results
    return (
      isValid &&
      backendValidationStatus !== 'waiting' &&
      backendValidationStatus !== 'waiting-autosave'
    );
  })();

  const formula = useWatch({ control, name: `formula` });
  // turn on BE validations for formula fields rightaway
  useEffect(() => {
    if (formula) {
      setBackendValidationStatus('waiting');
    }
  }, [formula]);

  const fieldType = useWatch({ control, name: `fieldType` });

  const getCurrentSchemaConcept = useCallback(() => {
    return asMutable(
      // @ts-expect-error schemaConcept is actually of immutable structure type
      schemaConcept.setIn(
        schemaObjectPath.split('.'),
        formDataToDatapoint(getValues())
      )
    );
  }, [getValues, schemaConcept, schemaObjectPath]);

  return (
    <CurrentSchemaConceptContext.Provider
      value={{
        getCurrentSchemaConcept,
        schemaObjectPath,
      }}
    >
      <div className={styles.EditFieldDrawerContent}>
        <form
          onSubmit={e => {
            // The submit button is disabled while backed validation is in progress,
            // with the exception of first submit - in that case we need to perform the validation
            // and submit it automatically, if it's successful.
            if (backendValidationStatus === 'never' && isValid) {
              setBackendValidationStatus('waiting-autosave');
              validateSchema(formDataToDatapoint(getValues()));
            } else {
              submit();
            }
            e.preventDefault();
          }}
        >
          <SimpleFields control={control} namePrefix="" />
          {fieldType === 'table-multivalue' && (
            <TableMultivalueFields control={control} setValue={setValue} />
          )}
          {fieldType === 'simple-multivalue' && (
            <SimpleMultivalueFields control={control} />
          )}

          {errors?.nonFieldErrors && !isEmpty(errors.nonFieldErrors.types) && (
            <NonFieldSchemaErrors nonFieldErrors={errors.nonFieldErrors} />
          )}

          <HiddingButton
            hideCondition={false}
            // TODO switch to "Validating...", maybe show a spinner?
            messageId="containers.settings.fields.save"
            type="submit"
            disabled={!canSubmit}
          />
        </form>
      </div>
    </CurrentSchemaConceptContext.Provider>
  );
};

const NonFieldSchemaErrors = ({
  nonFieldErrors,
}: {
  nonFieldErrors: FieldError;
}) => {
  const intl = useIntl();

  const translateKeyForUser = (key: string) => {
    switch (key) {
      case 'children.children':
        return intl.formatMessage({
          id: 'containers.settings.fields.edit.tableMultivalue.title',
        });
      default:
        return key;
    }
  };

  return (
    <div className={styles.NonFieldErrors}>
      {nonFieldErrors.types
        ? Object.entries(nonFieldErrors.types).map(([key, message], i) => (
            <div // eslint-disable-next-line react/no-array-index-key
              key={`${key}-${message}-${i}`}
              className={styles.Error}
            >
              <span className={styles.MessageKey}>
                {translateKeyForUser(key)}
              </span>{' '}
              {message}
            </div>
          ))
        : null}
    </div>
  );
};

export default SchemaObjectForm;
