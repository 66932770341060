import { Stack } from '@rossum/ui/material';
import { useDispatch } from 'react-redux';
import { constructDocumentUrl } from '../../../lib/url';
import { startValidation } from '../../../redux/modules/ui/actions';
import Filename from '../components/Filename';
import StyledLink from '../components/StyledLink';
import { CellProps } from '../types';

const DEFAULT_WIDTH = 170;

const RenderFilenameCell = (
  props: CellProps<string> & { onMouseOver: () => void; onMouseOut: () => void }
) => {
  const { row, onMouseOver, onMouseOut, value: originalFileName } = props;

  const filenameActionsDisabled =
    row.status === 'failedImport' ||
    row.status === 'importing' ||
    row.pages.length === 0;

  const dispatch = useDispatch();

  const annotate = (url: string) =>
    dispatch(startValidation(url, props.api.getAllRowIds().map(Number)));

  const estimatedFilenameLength = props.colDef.width ?? DEFAULT_WIDTH;

  return (
    <Stack alignItems="center" direction="row" width="100%">
      <StyledLink
        to={constructDocumentUrl({ id: row.id })}
        onClick={e => {
          if (e.metaKey || e.ctrlKey) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          if (!filenameActionsDisabled) {
            annotate(constructDocumentUrl({ id: row.id }));
          }
        }}
        sx={{
          height: '48px',
          display: 'flex',
          alignItems: 'center',
        }}
        disabled={filenameActionsDisabled}
        onMouseOver={!filenameActionsDisabled ? onMouseOver : undefined}
        onMouseLeave={!filenameActionsDisabled ? onMouseOut : undefined}
      >
        <Filename
          status={row.status}
          disabled={filenameActionsDisabled}
          estimatedFilenameLength={
            estimatedFilenameLength > 0 ? estimatedFilenameLength : 25
          }
          originalFileName={originalFileName}
        />
      </StyledLink>
    </Stack>
  );
};

export default RenderFilenameCell;
