// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import {
  engineFieldTypeSchema,
  multilineSchema,
} from './engineFieldType.schema';

export const engineFieldSchema = z.object({
  id: idSchema,
  url: urlSchema,
  engine: urlSchema,
  name: z.string(),
  label: z.string(),
  type: engineFieldTypeSchema,
  subtype: z.string().nullable(),
  uptrainFrom: z.string().nullable(),
  tabular: z.boolean(),
  multiline: multilineSchema,
});
