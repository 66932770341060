import { SchemaDatapointEnumOption } from '@rossum/api-client/schemas';
import { ContentPaste, DeleteOutline } from '@rossum/ui/icons';
import { ContentCopy as ContentCopyIcon } from '@rossum/ui/icons';
import { Launch as LaunchIcon } from '@rossum/ui/icons';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
  GridValueSetterParams,
} from '@rossum/ui/x-data-grid-pro';
import { compact, get, isEqual, negate } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { isFeatureFlagEnabled } from '../../../../../unleash/useFeatureFlag';
import { Queue } from '../../../types/queue';
import { FlatSchemaPropertyValue } from '../../../types/schema';
import { aggregationRow, text } from '../../constants';
import {
  getCategoryOptions,
  getTypeOptions,
  getUiConfigurationEditOptions,
  getUiConfigurationTypeOptions,
} from '../../data/options';
import { queuesOperators } from '../../queue-filtering/queueFilterOperators';
import { injectStylesToOperators } from '../../ui/dataGridStyles';
import { DuplicateIcon } from '../../ui/DuplicateIcon';
import { isNonEmptyObject } from '../getProcessRowUpdate';
import { DuplicateDialogParamsSetter } from '../hooks/useDuplicateFieldToQueues';
import { RemoveDialogParamsSetter } from '../hooks/useRemoveFromQueues';
import { optionsToHash } from '../rows/optionsToHash';
import { getFieldType } from '../rows/rows';
import { GridRowModel } from '../rows/rowTypes';
import { defaultValues } from './defaultValues';
import {
  canHaveRirFieldNames,
  isDatapoint,
  isDatapointInTable,
  isEnum,
  isFormula,
  isNotButtonDatapoint,
  isNotTuple,
  isNotTupleNorSection,
  isNumberOrDate,
} from './editabilityConditions';
import { enumFormulaLabelsOperators } from './filterOperators';
import { renderFormatEditCell } from './format';
import {
  appendPercentSignToNumber,
  percentageToValue,
  validatePercentageRange,
  valueToPercentage,
} from './percentages';
import {
  countComparator,
  queuesComparator,
  rirFieldNamesComparator,
} from './sortComparators';
import {
  EnumAndFormulaCellValue,
  ExtendedCellParams,
  GridColumns,
} from './types';
import { renderChipsCell } from './ui/ChipsCell';
import { renderMultipleEditAutocomplete } from './ui/EditCellAutocomplete';
import { EditCellWithValidation } from './ui/EditCellWithValidation';
import {
  renderBooleanCell,
  renderCellWithCount,
  renderFormulaCellWithCount,
  renderSingleSelectCell,
} from './ui/renderCell';
import { StringWithIcon } from './ui/StringWithIcon';
import { renderWorkspaceWithQueues } from './ui/WorkspaceWithQueues';
import { validateNonEmptyString, validatePositiveInteger } from './validations';

const getFieldValue: GridColumns[number]['valueGetter'] = (
  params: ExtendedCellParams
) => {
  const shouldFallbackToDefault =
    params.colDef.editable && params.colDef.editabilityCondition
      ? params.colDef.editabilityCondition(params.row.meta)
      : params.colDef.editable;

  const value: FlatSchemaPropertyValue =
    get(params.row, params.field) ??
    (shouldFallbackToDefault
      ? get(defaultValues, params.field, '')
      : text.cellNotEditable);

  return value;
};

const getFieldValueWithVariants: GridColumns[number]['valueGetter'] = (
  params: ExtendedCellParams
) => {
  if (params.id === aggregationRow) {
    return params.value;
  }

  if (
    'options' in params.row &&
    isNonEmptyObject(params.row.meta.metadata.optionsHashLabelMap)
  ) {
    const count = params.row.options.length;

    const hashKey = optionsToHash(params.row.options);

    return {
      label:
        count === 0
          ? 'Empty options'
          : params.row.meta.metadata.optionsHashLabelMap[hashKey],
      count,
    };
  }

  return { label: undefined, count: undefined };
};

const getFieldValueFormulaWithVariants: GridColumns[number]['valueGetter'] = (
  params: ExtendedCellParams
) => {
  if (params.id === aggregationRow) {
    return params.value;
  }

  if (
    'formula' in params.row &&
    isNonEmptyObject(params.row.meta.metadata.formulaHashMap)
  ) {
    const count = params.row.formula?.length;

    const hashKey = params.row.formula ?? '';

    return {
      label:
        count === 0
          ? 'Empty formula'
          : params.row.meta.metadata.formulaHashMap[hashKey],
      count,
    };
  }

  return { label: undefined, count: undefined };
};

type GetColumnsParams = {
  openRemoveModal?: RemoveDialogParamsSetter;
  setOptionsForEnumDialog?: Dispatch<
    SetStateAction<{
      options: SchemaDatapointEnumOption[];
      value: EnumAndFormulaCellValue;
    } | null>
  >;
  setFormulaForFormulaDrawer?: Dispatch<
    SetStateAction<{
      formula: string;
      value: EnumAndFormulaCellValue;
    } | null>
  >;
  openDuplicateModal?: DuplicateDialogParamsSetter;
  setCopiedRow?: React.Dispatch<React.SetStateAction<GridRowModel | null>>;
  copiedRow?: GridRowModel | null;
  pasteFieldConfigurationToRows?: (rows: GridRowModel[]) => void;
  intl: IntlShape;
};

export const getColumns: (params: GetColumnsParams) => GridColumns = ({
  intl,
  openRemoveModal,
  setOptionsForEnumDialog,
  setFormulaForFormulaDrawer,
  openDuplicateModal,
  setCopiedRow,
  copiedRow,
  pasteFieldConfigurationToRows,
}) =>
  injectStylesToOperators(
    compact([
      {
        field: 'meta.schema_queues',
        filterOperators: queuesOperators,
        flex: 2.6,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.queues',
        }),
        minWidth: 260,
        renderCell: renderWorkspaceWithQueues,
        sortComparator: queuesComparator,
        valueGetter: params => {
          if (params.id === aggregationRow) {
            return getFieldValue(params);
          }
          const value = get(params.row, params.field) as Queue[] | undefined;
          return value?.flatMap(v => (v.id ? [String(v.id)] : [])) ?? [];
        },
      },
      {
        editable: true,
        field: 'label',
        flex: 2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.label',
        }),
        minWidth: 200,
        preProcessEditCellProps: validateNonEmptyString('Label', intl),
        renderEditCell: EditCellWithValidation,
      },
      {
        editable: true,
        field: 'description',
        flex: 2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.description',
        }),
        minWidth: 200,
        renderEditCell: EditCellWithValidation,
      },
      {
        editable: false,
        field: 'category',
        flex: 1.2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.category',
        }),
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) => (
          <StringWithIcon value={params.formattedValue} />
        ),
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: getCategoryOptions(intl),
      },
      {
        editabilityCondition: isEnum,
        editable: false,
        field: 'options',
        flex: 1.2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.options',
        }),
        minWidth: 120,
        sortComparator: countComparator,
        filterOperators: enumFormulaLabelsOperators,
        renderCell: renderCellWithCount(setOptionsForEnumDialog),
        type: 'singleSelect',
        valueGetter: getFieldValueWithVariants,
      },
      {
        editabilityCondition: isFormula,
        editable: false,
        field: 'formula',
        flex: 1.2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.formula',
        }),
        minWidth: 120,
        sortComparator: countComparator,
        filterOperators: enumFormulaLabelsOperators,
        renderCell: renderFormulaCellWithCount(setFormulaForFormulaDrawer),
        type: 'singleSelect',
        valueGetter: getFieldValueFormulaWithVariants,
      },
      {
        editabilityCondition: isDatapoint,
        editable: true,
        field: 'type',
        flex: 1.2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.type',
        }),
        minWidth: 120,
        renderCell: renderSingleSelectCell,
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: getTypeOptions(intl),
        valueSetter: params => {
          const rowWithNewValue = {
            ...params.row,
            type: params.value,
            ...(params.value === 'button'
              ? { rirFieldNames: null }
              : {
                  rirFieldNames:
                    get(params.row, 'rirFieldNames') ||
                    get(params.row.meta.original, 'rirFieldNames') ||
                    [],
                }),
            ...(params.value === 'enum'
              ? {
                  options:
                    get(params.row, 'options') ||
                    get(params.row.meta.original, 'options') ||
                    [],
                }
              : {}),
          };

          return {
            ...rowWithNewValue,
            meta: {
              ...rowWithNewValue.meta,
              type: getFieldType(rowWithNewValue),
            },
          };
        },
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'uiConfiguration.type',
        flex: 1.2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.uiConfigurationType',
        }),
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <StringWithIcon value={params.formattedValue ?? params.value} />
          );
        },
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: getUiConfigurationTypeOptions(intl),
        valueSetter: params => {
          const rowWithNewValue = {
            ...params.row,
            uiConfiguration: {
              type: params.value,
              edit:
                params.value === 'formula'
                  ? ('disabled' as const)
                  : 'uiConfiguration' in params.row
                    ? params.row.uiConfiguration?.edit ?? 'enabled'
                    : 'enabled',
            },
            ...(params.value === 'formula'
              ? {
                  rirFieldNames: null,
                  formula: 'formula' in params.row ? params.row.formula : '',
                }
              : {
                  rirFieldNames:
                    get(params.row, 'rirFieldNames') ||
                    get(params.row.meta.original, 'rirFieldNames') ||
                    [],
                }),
          };

          return {
            ...rowWithNewValue,
          };
        },
      },
      {
        editabilityCondition: isNumberOrDate,
        editable: true,
        field: 'format',
        flex: 2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.format',
        }),
        minWidth: 200,
        renderEditCell: renderFormatEditCell,
        valueGetter: getFieldValue,
        valueSetter: ({ row, value }) => {
          const newValue =
            typeof value === 'object' && value !== null && 'value' in value
              ? value.value
              : value;

          return {
            ...row,
            format: newValue,
          };
        },
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'uiConfiguration.edit',
        flex: 1.6,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.uiConfigurationEdit',
        }),
        minWidth: 160,
        renderCell: renderSingleSelectCell,
        type: 'singleSelect',
        valueGetter: getFieldValue,
        valueOptions: getUiConfigurationEditOptions(intl),
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'scoreThreshold',
        flex: 1.4,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.scoreThreshold',
        }),
        minWidth: 140,
        preProcessEditCellProps: validatePercentageRange(
          'Score threshold',
          intl
        ),
        renderEditCell: EditCellWithValidation,
        type: 'number',
        valueFormatter: appendPercentSignToNumber,
        valueGetter: p => {
          const value: null | number | undefined = getFieldValue(p);

          return valueToPercentage(value);
        },
        valueSetter: <T,>(params: { row: T; value: number | null }) => {
          const { value } = params;

          const scoreThreshold = percentageToValue(value);

          return {
            ...params.row,
            scoreThreshold,
          };
        },
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'constraints.required',
        flex: 1,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.constraintsRequired',
        }),
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: isNotTuple,
        editable: true,
        field: 'hidden',
        flex: 1,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.hidden',
        }),
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: params => {
          if (params.id === aggregationRow) {
            return params.value;
          }
          return negate(getFieldValue)(params);
        },
        valueSetter: params => ({ ...params.row, hidden: !params.value }),
      },
      {
        editabilityCondition: isNotTupleNorSection,
        editable: true,
        field: 'canExport',
        flex: 1,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.canExport',
        }),
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: canHaveRirFieldNames,
        editable: true,
        field: 'rirFieldNames',
        flex: 2.4,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.rirFieldNames',
        }),
        minWidth: 240,
        renderCell: renderChipsCell,
        renderEditCell: renderMultipleEditAutocomplete,
        sortComparator: rirFieldNamesComparator,
        valueGetter: getFieldValue,
        valueSetter: ({ row, value }) => {
          const newValue =
            isEqual(value, []) &&
            ('rirFieldNames' in row.meta.original &&
              row.meta.original.rirFieldNames) === null
              ? null
              : value;

          return {
            ...row,
            rirFieldNames: newValue,
          };
        },
      },
      {
        editabilityCondition: isNotTupleNorSection,
        editable: true,
        field: 'disablePrediction',
        flex: 1.6,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.disablePrediction',
        }),
        minWidth: 160,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: isDatapointInTable,
        editable: true,
        field: 'canCollapse',
        flex: 1.2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.canCollapse',
        }),
        minWidth: 120,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      {
        editabilityCondition: isNotButtonDatapoint,
        editable: true,
        field: 'defaultValue',
        flex: 2,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.defaultValue',
        }),
        minWidth: 200,
        valueGetter: getFieldValue,
        valueSetter: ({
          row,
          value,
        }: GridValueSetterParams<GridRowModel, string>) => {
          const newValue =
            value === '' &&
            ('defaultValue' in row.meta.original &&
              row.meta.original.defaultValue) === null
              ? null
              : value;

          return {
            ...row,
            defaultValue: newValue,
          };
        },
      },
      {
        editabilityCondition: isDatapointInTable,
        editable: true,
        field: 'width',
        flex: 0.8,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.width',
        }),
        minWidth: 80,
        preProcessEditCellProps: validatePositiveInteger('Width', intl),
        renderEditCell: EditCellWithValidation,
        type: 'number',
        valueGetter: getFieldValue,
        valueSetter: ({ row, value }) => ({
          ...row,
          width: value === undefined ? null : value,
        }),
      },
      {
        editabilityCondition: isDatapointInTable,
        editable: true,
        field: 'stretch',
        flex: 1,
        headerName: intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.columns.headerName.stretch',
        }),
        minWidth: 100,
        renderCell: renderBooleanCell,
        type: 'boolean',
        valueGetter: getFieldValue,
      },
      (openRemoveModal ||
        openDuplicateModal ||
        setCopiedRow ||
        pasteFieldConfigurationToRows) && {
        field: 'actions',

        getActions: ({ row }: GridRowParams<GridRowModel>) => {
          const firstQueueId = row.meta.schema_queues[0]?.id;
          const isNewQueueSettingsLayout = isFeatureFlagEnabled(
            'ac-4366-queue-settings-layout-v2'
          );

          const actions = [
            firstQueueId ? (
              <GridActionsCellItem
                key={row.id}
                icon={<LaunchIcon fontSize="small" />}
                label={intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.columns.actions.open',
                })}
                onClick={e => {
                  const url = isNewQueueSettingsLayout
                    ? // New fields to capture are smart, so it is enough to provide fields/fieldId and the parent ids are
                      // filled in the pathname automatically on fields to capture path <3
                      `/queues/${firstQueueId}/settings/fields/${row.id}`
                    : `/settings/queues/${firstQueueId}/fields`;
                  e.stopPropagation();
                  window.open(url, '_blank');
                }}
                data-cy="fm-open-fields-to-capture"
                showInMenu
              />
            ) : (
              []
            ),

            openRemoveModal ? (
              <GridActionsCellItem
                key={row.id}
                icon={<DeleteOutline />}
                label={intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.columns.actions.remove',
                })}
                onClick={() => openRemoveModal({ selectedRows: [row] })}
                data-cy="fm-remove-field-action"
                showInMenu
              />
            ) : (
              []
            ),

            // Allow to duplicate only single value fields
            openDuplicateModal && row.category === 'datapoint' ? (
              <GridActionsCellItem
                key={row.id}
                icon={<DuplicateIcon />}
                label={intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.columns.actions.duplicate',
                })}
                onClick={() => openDuplicateModal({ selectedRows: [row] })}
                data-cy="fm-duplicate-field-action"
                showInMenu
              />
            ) : (
              []
            ),
            // Allow to copy only single value fields
            setCopiedRow && row.category === 'datapoint' ? (
              <GridActionsCellItem
                key={row.id}
                icon={<ContentCopyIcon fontSize="small" />}
                label={intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.columns.actions.copyToClipBoard',
                })}
                onClick={() => setCopiedRow(row)}
                data-cy="fm-copy-field-action"
                showInMenu
              />
            ) : (
              []
            ),
            // Allow to paste only to a single value fields and NOT to the same row which was copied
            copiedRow &&
            copiedRow.meta.schema_id !== row.meta.schema_id &&
            pasteFieldConfigurationToRows &&
            row.category === 'datapoint' ? (
              <GridActionsCellItem
                key={row.id}
                icon={<ContentPaste fontSize="small" />}
                label={intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.columns.actions.paste',
                })}
                onClick={() => pasteFieldConfigurationToRows([row])}
                data-cy="fm-paste-field-action"
                showInMenu
              />
            ) : (
              []
            ),
          ].flatMap(action => action);

          return actions;
        },
        resizable: false,
        type: 'actions',
        width: 50,
      },
    ])
  );
