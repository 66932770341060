import { FieldLabel, FieldLabelProps } from '@rossum/rossum-ui/FieldLabel';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Switch,
  SwitchProps,
} from '@rossum/ui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { LabeledFieldProps, SimpleControlProps } from '../utils';

type SwitchControlType = {
  showInvertedValue?: boolean;
};

type SwitchControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SimpleControlProps<TFieldValues, TName> &
  LabeledFieldProps &
  SwitchProps &
  Pick<FormControlLabelProps, 'label'> &
  SwitchControlType;

const SwitchControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ControllerProps: { control, name },
  FieldLabelProps,
  label,
  disabled,
  showInvertedValue,
  ...switchProps
}: SwitchControlProps<TFieldValues, TName>) => {
  const { id, required, size, ...restSwitchProps } = switchProps;
  const resolvedFieldLabelProps: FieldLabelProps = {
    htmlFor: id ?? name,
    required,
    label: '',
    ...FieldLabelProps,
  };
  return (
    <Box sx={{ label: { mb: 0 } }}>
      <FieldLabel {...resolvedFieldLabelProps}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState, formState }) => (
            <FormControl>
              <FormControlLabel
                disabled={disabled || formState.isSubmitting}
                aria-disabled={disabled || formState.isSubmitting}
                value={field.value}
                onChange={() => field.onChange(!field.value)}
                control={
                  <Switch
                    checked={showInvertedValue ? !field.value : field.value}
                    size={size}
                    sx={{ position: 'inherit' }}
                    {...restSwitchProps}
                  />
                }
                componentsProps={{
                  typography: {
                    variant: 'body2',
                    ml: 1,
                  },
                }}
                label={label}
                sx={{ ml: 0, mr: 0 }}
              />
              {fieldState.invalid && (
                <FormHelperText error sx={{ mt: 0 }}>
                  {fieldState.error?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </FieldLabel>
    </Box>
  );
};

export default SwitchControl;
