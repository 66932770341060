import { PaginationQuery } from '@rossum/api-client/utils';
import {
  Button,
  Paper,
  Stack,
  TableCell,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useIntlDate } from '../../i18n/useIntlDate';
import { formatNumberToLocale } from '../../lib/helpers';
import BillingTable, { TableRow } from './components/BillingTable/BillingTable';
import EmptyTable from './components/BillingTable/EmptyTable';
import LoadingTable from './components/BillingTable/LoadingTable';
import LabeledValueInRow from './components/LabeledValueInRow';
import LoadingOverlay from './components/LoadingOverlay';
import { getVolumeFromTotals } from './helpers';
import { BillingEntity } from './hooks/useBillingEntity';
import { useBillingHistory } from './hooks/useBillingHistory';
import { useBillingHistoryExport } from './hooks/useBillingHistoryExport';

const COLUMNS = [
  'period',
  'unit',
  'purchasedVolume',
  'usedVolume',
  'usedPagesPercentage',
] as const;

type HistoryPageProps = {
  billingEntity: BillingEntity;
};

const HistoryPage = ({ billingEntity }: HistoryPageProps) => {
  const intl = useIntl();
  const { formatDate } = useIntlDate();

  const [paginationQuery, setPaginationQuery] = useState<
    Required<PaginationQuery>
  >({ page: 0, pageSize: 10 });
  const { data, isLoading } = useBillingHistory(billingEntity, paginationQuery);
  const { triggerExport, isExporting } = useBillingHistoryExport(billingEntity);

  return (
    <Stack spacing={4} sx={{ my: 8 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">
          {intl.formatMessage({ id: 'containers.billing.history' })}
        </Typography>

        <Button
          color="secondary"
          variant="outlined"
          onClick={() => triggerExport()}
          disabled={isExporting}
        >
          {isExporting && <LoadingOverlay size={16} />}
          {intl.formatMessage({
            id: 'containers.billing.export',
          })}
        </Button>
      </Stack>

      <Paper sx={{ overflow: 'hidden' }} elevation={2}>
        <BillingTable
          paginationResponse={data?.pagination}
          paginationState={paginationQuery}
          setPaginationState={setPaginationQuery}
          headColumns={COLUMNS.map(column => {
            return {
              id: `containers.billing.history.tableHead.${column}` as const,
              width: `${100 / COLUMNS.length}%`,
            };
          })}
        >
          {isLoading ? (
            <LoadingTable
              columnsCount={COLUMNS.length}
              rowsCount={paginationQuery.pageSize}
            />
          ) : data?.results.length ? (
            data.results.map(({ beginDate, endDate, values }) => {
              const period = [beginDate, endDate].map(formatDate).join(' - ');
              const volume = getVolumeFromTotals(values);

              return (
                <TableRow key={period} className="TableRowOverride">
                  <TableCell component="th" scope="row">
                    {period}
                  </TableCell>
                  {volume !== null ? (
                    <>
                      <TableCell>
                        {intl.formatMessage({
                          id: `containers.billing.usage.units.${volume.unit}`,
                        })}
                      </TableCell>
                      <TableCell>
                        {formatNumberToLocale(volume.purchased, intl.locale)}
                      </TableCell>
                      <TableCell>
                        {formatNumberToLocale(volume.billable, intl.locale)}
                      </TableCell>
                      <TableCell>
                        {`${(
                          (volume.billable / volume.purchased) *
                          100
                        ).toFixed(1)} %`}
                      </TableCell>
                    </>
                  ) : (
                    // No purchased pages or documents - don't know the unit.
                    <TableCell colSpan={4}>-</TableCell>
                  )}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable
              title="containers.billing.emptyTable"
              text="containers.billing.emptyDescription"
              columnsCount={COLUMNS.length}
            />
          )}
        </BillingTable>
      </Paper>
      {data && (
        <LabeledValueInRow
          label="containers.billing.validity"
          valueColor="text.secondary"
          value={new Date(data.updatedAt).toUTCString()}
        />
      )}
    </Stack>
  );
};

export default HistoryPage;
