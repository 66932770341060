import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { boldText } from '../../../../lib/formaterValues';
import CalculatePreviewButton from '../CalculatePreviewButton';

const CustomNoRowsOverlay = () => {
  const intl = useIntl();
  return (
    <Stack spacing={4} marginTop={4} textAlign="center" alignItems="center">
      <Stack>
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          {intl.formatMessage({
            id: 'features.queueSettings.fields.formulaPreview.noRowsOverlay.title',
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary" maxWidth={320}>
          {intl.formatMessage(
            {
              id: 'features.queueSettings.fields.formulaPreview.noRowsOverlay.description',
            },
            { bold: boldText }
          )}
        </Typography>
      </Stack>
      <CalculatePreviewButton />
    </Stack>
  );
};

export default CustomNoRowsOverlay;
