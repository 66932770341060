import { Button, CircularProgress } from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useFormulaPreviewContext } from './contexts/formulaPreview/formulaPreviewContext';
import { COLUMNS_QUERY_KEY } from './hooks/useColumns';

const CalculatePreviewButton = () => {
  const intl = useIntl();
  const { evaluationQueries, previewEnabled } = useFormulaPreviewContext();
  const queryClient = useQueryClient();
  // to avoid spinner when loading more rows by Show more button
  const clicked = useRef(false);

  const calculate = () => {
    clicked.current = true;
    queryClient.invalidateQueries({ queryKey: COLUMNS_QUERY_KEY });
    evaluationQueries.forEach(({ refetch }) => refetch());
  };

  const isFetching = evaluationQueries.some(({ isFetching }) => isFetching);

  if (!isFetching && clicked.current) {
    clicked.current = false;
  }

  const fetchingInProgress = isFetching && clicked.current;

  const buttonDisabled = fetchingInProgress || !previewEnabled;

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={calculate}
      disabled={buttonDisabled}
      startIcon={
        fetchingInProgress && <CircularProgress color="inherit" size={16} />
      }
      data-cy="formula-preview-calculate"
    >
      {intl.formatMessage({
        id: 'features.queueSettings.fields.formulaPreview.calculate',
      })}
    </Button>
  );
};

export default CalculatePreviewButton;
