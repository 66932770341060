import { Button } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { z } from 'zod';
import Container from '../../components/UI/Container';
import InfoScreen from '../../components/UI/InfoScreenHeader';
import { contactSupportLink } from '../../constants/values';
import { link } from '../../lib/formaterValues';
import { parse } from '../../lib/url';

// convert to z.union later when we support more reasons
const reasonSchema = z.literal('login-failed');

const ErrorPage = () => {
  const intl = useIntl();
  const { search } = useLocation();
  const { reason } = parse(search);

  const parsed = reasonSchema.safeParse(reason);

  if (!parsed.success) {
    return <Redirect to="/" />;
  }

  return (
    <Container dataPageTitle="error-page">
      <InfoScreen
        title={intl.formatMessage({
          id: `containers.errorPage.${parsed.data}.title`,
        })}
        subTitle={intl.formatMessage(
          {
            id: `containers.errorPage.${parsed.data}.text`,
          },
          {
            contactLink: link(contactSupportLink, {
              color: 'inherit',
            }),
          }
        )}
      />

      {/* TODO: it might not make sense to render Back to login button for other reasons... */}
      <Button
        component={Link}
        to="/"
        color="secondary"
        variant="text"
        sx={{ mt: 2 }}
        data-cy="error-page-back-button"
      >
        {intl.formatMessage({ id: 'components.user.recovery.back' })}
      </Button>
    </Container>
  );
};

export default ErrorPage;
