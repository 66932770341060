import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Button, Stack, Typography } from '@rossum/ui/material';
import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import { useHistory } from 'react-router';
import { UPLOAD_HASH } from '../../../constants/values';
import { button, linebreak } from '../../../lib/formaterValues';
import { useGetInbox } from '../../document-upload/hooks/useGetInbox';
import SuggestInbox from '../emails/components/SuggestInbox';
import * as uploadAnimation from './animations/upload.json';

type ClearFiltersButtonProps = {
  onClick: () => void;
};

const ClearFiltersButton = ({ onClick }: ClearFiltersButtonProps) => {
  const intl = useIntl();

  return (
    <Button
      color="secondary"
      variant="outlined"
      data-cy="clear-filters-btn"
      sx={{ mt: 3 }}
      onClick={onClick}
    >
      {intl.formatMessage({
        id: 'containers.allDocuments.emptyState.noResultsForFiltering.button.label',
      })}
    </Button>
  );
};

type EmptyStateTextsProps = {
  title: string;
  text: string | ReactNode;
};

const EmptyStateTexts = ({ title, text }: EmptyStateTextsProps) => {
  return (
    <>
      <Typography variant="h5" sx={{ color: 'text.secondary', mb: 2 }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: 'text.secondary', textAlign: 'center' }}
      >
        {text}
      </Typography>
    </>
  );
};

type EmptyStatesProps = {
  searchQuery: string | undefined;
  isEmailView: boolean;
  shouldCreateQueue?: boolean;
  openQueueModal?: () => void;
  queue?: Queue | null;
  onErrorReset?: () => void;
  existingFilter?: GridFilterModel | null;
  onClearFilters?: () => void;
};

export const EmptyStates = ({
  isEmailView,
  shouldCreateQueue,
  openQueueModal,
  queue,
  searchQuery,
  onErrorReset,
  existingFilter,
  onClearFilters,
}: EmptyStatesProps) => {
  const intl = useIntl();
  const history = useHistory();

  const showUploadDialog = () => {
    history.replace({ ...history.location, hash: UPLOAD_HASH });
  };

  const { data: queueInbox, isInitialLoading: queueInboxIsLoading } =
    useGetInbox(getIDFromUrl(queue?.inbox || ''));

  const shouldDisplayNoResultsForFiltering = queue
    ? Boolean(existingFilter?.items.some(i => i.field !== 'queue'))
    : Boolean(existingFilter?.items.length);

  const getContent = () => {
    if (shouldCreateQueue) {
      return (
        <>
          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            {intl.formatMessage({
              id: 'containers.allDocuments.emptyState.noQueues.title',
            })}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', my: 2 }}>
            {intl.formatMessage({
              id: 'containers.allDocuments.emptyState.noQueues.text',
            })}
          </Typography>
          {openQueueModal && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={openQueueModal}
            >
              {intl.formatMessage({
                id: 'containers.allDocuments.emptyState.noQueues.button',
              })}
            </Button>
          )}
        </>
      );
    }

    if (isEmailView) {
      if (shouldDisplayNoResultsForFiltering) {
        return (
          <>
            <EmptyStateTexts
              title={intl.formatMessage({
                id: 'containers.allDocuments.emptyState.noResultsForFiltering.title',
              })}
              text={intl.formatMessage({
                id: 'containers.allDocuments.emptyState.noResultsForFiltering.text',
              })}
            />
            {onClearFilters && <ClearFiltersButton onClick={onClearFilters} />}
          </>
        );
      }

      if (searchQuery) {
        return (
          <EmptyStateTexts
            title={intl.formatMessage(
              {
                id: `containers.allDocuments.emptyState.noResultsEmails.title`,
              },
              { search: searchQuery }
            )}
            text={intl.formatMessage({
              id: `containers.allDocuments.emptyState.noResultsEmails.text`,
            })}
          />
        );
      }

      return (
        <Stack alignItems="center" spacing={2}>
          <EmptyStateTexts
            title={intl.formatMessage({
              id: `containers.allDocuments.emptyState.noEmails.title`,
            })}
            text={intl.formatMessage({
              id: `containers.allDocuments.emptyState.noEmails.text`,
            })}
          />
          <SuggestInbox
            isLoading={queueInboxIsLoading}
            inboxEmail={queueInbox?.email}
            queueId={queue?.id}
          />
        </Stack>
      );
    }

    if (shouldDisplayNoResultsForFiltering) {
      return (
        <>
          <EmptyStateTexts
            title={intl.formatMessage({
              id: 'containers.allDocuments.emptyState.noResultsForFiltering.title',
            })}
            text={intl.formatMessage({
              id: 'containers.allDocuments.emptyState.noResultsForFiltering.text',
            })}
          />
          {onClearFilters && <ClearFiltersButton onClick={onClearFilters} />}
        </>
      );
    }

    if (searchQuery) {
      return (
        <EmptyStateTexts
          title={intl.formatMessage(
            { id: `containers.allDocuments.emptyState.noResults.title` },
            { search: searchQuery }
          )}
          text={intl.formatMessage({
            id: `containers.allDocuments.emptyState.noResults.text`,
          })}
        />
      );
    }

    if (onErrorReset)
      return (
        <EmptyStateTexts
          title={intl.formatMessage({
            id: `containers.allDocuments.emptyState.error.title`,
          })}
          text={intl.formatMessage(
            {
              id: `containers.allDocuments.emptyState.error.text`,
            },
            {
              button: button(onErrorReset),
              linebreak,
            }
          )}
        />
      );

    return (
      <>
        <Lottie
          loop
          play
          onClick={showUploadDialog}
          animationData={uploadAnimation}
          rendererSettings={{
            viewBoxSize: '0 30 300 150',
          }}
          style={{
            height: '50%',
            minHeight: '140px',
            cursor: 'pointer',
          }}
        />

        <EmptyStateTexts
          title={intl.formatMessage({
            id: `containers.allDocuments.emptyState.noDocuments.title`,
          })}
          text={intl.formatMessage(
            {
              id: `containers.allDocuments.emptyState.noDocuments.text`,
            },
            {
              button: button(showUploadDialog),
            }
          )}
        />
      </>
    );
  };

  return (
    <Stack height={1} sx={{ overflowY: 'auto' }}>
      <Stack m="auto" alignItems="center">
        {getContent()}
      </Stack>
    </Stack>
  );
};
