import { WarningRounded } from '@rossum/ui/icons';
import {
  alpha,
  Button,
  Stack,
  StackProps,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CONTACT_FORM_LINK } from '../../../constants/values';

type Props = {
  title: string;
  sx?: StackProps['sx'];
  dataCy: string;
};

export const UnpaidFeatureOverlay = ({ title, sx, dataCy }: Props) => {
  const intl = useIntl();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      flex={1}
      spacing={1}
      sx={{
        backgroundColor: t => alpha(t.palette.background.paper, 0.8),
        ...(sx ?? {}),
      }}
      data-cy={dataCy}
    >
      <Stack justifyContent="center" alignItems="center" spacing={1}>
        <WarningRounded />
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {intl.formatMessage({
            id: 'features.pricing.unpaidFeatureOverlay.text',
          })}
        </Typography>
      </Stack>

      <Button
        variant="outlined"
        color="secondary"
        component={Link}
        to={{ pathname: CONTACT_FORM_LINK }}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          ':hover , :focus': {
            color: 'secondary.main',
          },
        }}
      >
        {intl.formatMessage({
          id: 'features.pricing.unpaidFeatureOverlay.button',
        })}
      </Button>
    </Stack>
  );
};
