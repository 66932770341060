import { Message } from '@rossum/api-client/shared';
import update from 'immutability-helper';
import {
  compact,
  find,
  findIndex,
  get,
  groupBy,
  includes,
  last,
  merge,
  omit,
  orderBy,
  partition,
} from 'lodash';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { Rectangle2DCoordinates } from '../../../features/annotation-view/document-canvas/utils/geometry';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import {
  AnyDatapointDataST,
  DatapointsST,
  DatapointValueDataST,
  Grid,
  MatchedTriggerRuleDatapoint,
  MultivalueDatapointData,
  MultivalueDatapointDataST,
  RowPosition,
  SimpleDatapointData,
  SuggestedOperation,
  SuggestedOperationSource,
  SuggestedOperationState,
  TupleDatapointData,
  TupleDatapointDataST,
} from '../../../types/datapoints';
import { AggregationMessage } from '../../../types/message';
import { RootActionType } from '../../rootActions';
import {
  clearAnnotationData,
  confirmAnnotationFulfilled,
  deleteAnnotationFulfilled,
  nextAnnotableAnnotation,
  nextAnnotation,
  postponeAnnotationFulfilled,
  rejectAnnotationFulfilled,
  startAnnotationFulfilled,
} from '../annotation/actions';
import { fetchMembershipTokenFulfilled, logoutUser } from '../auth/actions';
import {
  applyColumnsToAllGrids,
  applyGridToNextPages,
  copyGrid,
  createGridFulfilled,
  deleteAllGrids,
  deleteGridFulfilled,
  pasteGrid,
  updateGridAction,
  updateGridAfterExtractAllRows,
  updateGridAfterMovedFulfilled,
  updateGridFulfilled,
} from '../grid/actions';
import {
  addNewTuples,
  getUpdatedDatapointIdsForColumns,
  removeTuplesFromContent,
  removeTuplesFromParent,
  updateGrid,
} from '../grid/helpers';
import { leaveValidation } from '../ui/actions';
import {
  addSchemasToDatapoints,
  batchDatapointConfirm,
  batchDatapointDelete,
  batchDatapointDeleteFulfilled,
  batchDatapointUpdate,
  bulkUpdateDatapointValue,
  changeDatapointsSchemaIds,
  createDatapointsFulfilled,
  deleteAllDatapoints,
  deleteAllDatapointsFulfilled,
  deleteDatapoint,
  deleteDatapointFulfilled,
  discardSuggestions,
  fetchDatapoints,
  fetchDatapointsFulfilled,
  materializeVirtualDatapoints,
  materializeVirtualDatapointsFulfilled,
  recountDatapointPosition,
  recountDatapointPositionFulfilled,
  rerenderDatapoints,
  resetDatapoint,
  resetDatapointFulfilled,
  setUnvalidatedContent,
  setValidatedMeta,
  setWaitingForSuggestions,
  sidebarDatapointHasChangedAction,
  suggestTable,
  suggestTableFullfilled,
  updateDatapointMeta,
  updateDatapointValue,
  updatePosition,
  validate,
  validateFulfilled,
  validationStart,
} from './actions';
import {
  mapAggregations,
  mapMessages,
  sortBboxAttributes,
  updateContentValue,
  updateDatapoints,
} from './helpers';
import {
  acceptSuggestedOperationsFulfilledAction,
  declineSuggestedOperationsAction,
  recountSuggestedOperationPositionAction,
  recountSuggestedOperationPositionFulfilledAction,
  updateSuggestedOperationPositionAction,
} from './suggestedOperations/actions';
import {
  fetchSuggestedPositionsForValueFulfilled,
  removeSuggestedPositionsForValue,
} from './suggestedPositionsForValue/actions';
import {
  addNewDatapoints,
  getDataForBatchUpdate,
  getDescendendDatapointIds,
  isRelevantSuggestedOperation,
  isSimpleDatapoint,
  isVirtualDatapoint,
  performSchemaIdSwaps,
  removeAllChildren,
} from './typedHelpers';

const mergeOperationState = (
  previousState: Record<number, SuggestedOperationState>,
  operations: SuggestedOperation[],
  source: SuggestedOperationSource
): Record<number, SuggestedOperationState> => {
  // Operations to add
  const newState = Object.fromEntries(
    operations.map(op => [op.id, { ...op, source }] as const)
  );

  // Operations that should stay there from previous state
  const mergeState = Object.values(previousState).reduce(
    (acc, curr) => (curr.source !== source ? { ...acc, [curr.id]: curr } : acc),
    {}
  );

  // Extensions have priority as a source - overwrite with new state in that case.
  return source === 'extension'
    ? { ...mergeState, ...newState }
    : { ...newState, ...mergeState };
};

const initialGridClipboard: DatapointsST['gridClipboard'] = {
  page: null,
  columns: [],
  rows: [],
  width: null,
  height: null,
};

const initialState: DatapointsST = {
  aggregations: {} as { [key: number]: Array<AggregationMessage> },
  content: [],
  gridClipboard: initialGridClipboard,
  initiallyValidated: false,
  loaded: false,
  messages: {} as {
    [key: number]: Message;
    all?: Message;
  },
  originalGrid: undefined,
  pendingOCR: false,
  pendingMaterialization: false,
  pendingValidation: false,
  sidebarDatapointUpdatedTimestamp: 0,
  suggestedPositionsForValue: {} as DatapointsST['suggestedPositionsForValue'],
  suggestedOperations: {} as DatapointsST['suggestedOperations'],
  matchedTriggerRules: {
    datapointLevel: {} as Record<number, MatchedTriggerRuleDatapoint[]>,
    annotationLevel: [],
  },
  unvalidatedContent: false,
  updatedTimestamp: 0,
  documentTimestamp: 0,
  loadingDatapointIds: [],
  waitingForSuggestions: false,
};

export const initialContent: DatapointValueDataST = {
  value: '',
  page: null,
  position: null,
  ocrText: '',
};

const datapointsReducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(startAnnotationFulfilled): {
      return update(state, {
        initiallyValidated: {
          $set: false,
        },
      });
    }

    case getType(validate):
    case getType(validationStart): {
      return update(state, {
        unvalidatedContent: {
          $set: false,
        },
        pendingValidation: {
          $set: true,
        },
        waitingForSuggestions: {
          $set: false,
        },
      });
    }

    case getType(setUnvalidatedContent): {
      const { payload } = action;

      return update(state, {
        unvalidatedContent: {
          $set: payload,
        },
      });
    }

    case getType(setWaitingForSuggestions): {
      return update(state, {
        waitingForSuggestions: {
          $set: action.payload,
        },
      });
    }

    case getType(suggestTable): {
      return update(state, {
        waitingForSuggestions: {
          $set: true,
        },
      });
    }

    case getType(discardSuggestions): {
      const virtualTupleIds = state.content
        .filter(dp => !isSimpleDatapoint(dp))
        .map(dp => dp.id)
        .filter(isVirtualDatapoint);

      return virtualTupleIds.reduce(
        (acc, id) => {
          const index = acc.content.findIndex(dp => dp.id === id);
          return removeAllChildren(acc, index, { removeParent: true });
        },
        update(state, {
          suggestedOperations: { $set: [] },
        })
      );
    }

    case getType(suggestTableFullfilled): {
      const { newState, suggestedOperations } = action.payload;

      const indexedOperations = suggestedOperations.filter(op =>
        isRelevantSuggestedOperation(newState, op)
      );

      return update(newState, {
        suggestedOperations: {
          $set: mergeOperationState(
            newState.suggestedOperations,
            indexedOperations,
            'table'
          ),
        },
        waitingForSuggestions: {
          $set: false,
        },
      });
    }

    case getType(validateFulfilled): {
      const {
        updatedDatapoints,
        messages,
        suggestedOperations,
        matchedTriggerRules,
        complexLineItemsEnabled,
      } = action.payload;

      const newState = updateDatapoints(
        state,
        updatedDatapoints,
        complexLineItemsEnabled
      );

      const [aggregations, _messages] = partition(
        messages,
        (msg): msg is AggregationMessage => msg.type === 'aggregation'
      );

      const [datapointLevelRules, annotationLevelRules] = partition(
        matchedTriggerRules,
        (rule): rule is MatchedTriggerRuleDatapoint => rule.type === 'datapoint'
      );

      return update(newState, {
        messages: {
          $set: mapMessages(newState.content, _messages),
        },
        aggregations: {
          $set: mapAggregations(aggregations),
        },
        updatedTimestamp: uTs => (updatedDatapoints.length ? Date.now() : uTs),
        initiallyValidated: {
          $set: true,
        },
        pendingValidation: {
          $set: false,
        },
        suggestedOperations: {
          $set: mergeOperationState(
            newState.suggestedOperations,
            suggestedOperations,
            'extension'
          ),
        },
        matchedTriggerRules: {
          $set: {
            datapointLevel: groupBy(datapointLevelRules, ({ id }) => id),
            annotationLevel: annotationLevelRules,
          },
        },
      });
    }
    case getType(rerenderDatapoints): {
      return update(state, {
        updatedTimestamp: {
          $set: Date.now(),
        },
      });
    }

    case getType(batchDatapointDelete): {
      const {
        payload: { tuplesToDelete, datapointsToReset },
      } = action;

      // We need to iterate in reverse order of the indexes, because in that case
      // the indexes towards the beginning of the array are preserved.
      // After each delete operation the indexes towards the end of an array gets changed
      const reverseSortedTuples = orderBy(tuplesToDelete, 'index', 'desc');

      return update(
        update(
          reverseSortedTuples.reduce(
            (acc, cur) =>
              removeAllChildren(acc, cur.index, { removeParent: true }),
            update(state, {
              unvalidatedContent: {
                $set: true,
              },
            })
          ),
          {
            content: datapoints =>
              datapoints.map(dp =>
                datapointsToReset.map(x => x.id).includes(dp.id) &&
                isSimpleDatapoint(dp)
                  ? {
                      ...dp,
                      content: {
                        ...dp.content,
                        position: null,
                        page: null,
                        value: '',
                      },
                    }
                  : dp
              ),
          }
        ),
        {
          suggestedOperations: {
            $unset: datapointsToReset
              .map(dp => dp.id)
              .concat(
                tuplesToDelete.flatMap(({ index: tupleIndex }) =>
                  (
                    state.content[tupleIndex] as TupleDatapointDataST
                  ).children.map(({ id }) => id)
                )
              ),
          },
        }
      );
    }

    case getType(batchDatapointDeleteFulfilled): {
      return update(state, {
        updatedTimestamp: {
          $set: Date.now(),
        },
      });
    }

    case getType(deleteAllDatapoints): {
      const {
        payload: { parentIndex },
      } = action;

      return removeAllChildren(
        update(state, {
          unvalidatedContent: {
            $set: true,
          },
        }),
        parentIndex
      );
    }

    case getType(deleteAllDatapointsFulfilled): {
      const {
        payload: { parentIndex },
      } = action;

      const parentMultivalue = state.content[parentIndex];

      // This action is also called when you batch select all
      // simple multivalue children
      if (parentMultivalue && parentMultivalue.meta.isSimpleMultivalue) {
        return state;
      }

      return update(state, {
        content: {
          [parentIndex]: {
            grid: {
              parts: grids =>
                grids.map(grid => ({
                  ...grid,
                  rows: grid.rows.map(row =>
                    row.tupleId === null
                      ? row
                      : {
                          ...row,
                          tupleId: null,
                          type: null,
                        }
                  ),
                })),
            },
          },
        },
      });
    }

    case getType(updatePosition): {
      const {
        meta: { index, page },
        payload: {
          content: { position },
        },
      } = action;
      const datapointId = state.content[index].id;

      const dp = state.content[index];

      if (dp.category === 'section' || dp.category === 'tuple') {
        return state;
      }

      return update(state, {
        content: {
          [index]: {
            content: {
              position: {
                $set: position,
              },
              page: {
                $set: page.number,
              },
            },
          },
          // TODO: Ideas to fix this?
        } as never,
        documentTimestamp: {
          $set: Date.now(),
        },
        suggestedPositionsForValue: suggestedPositions => {
          return omit(suggestedPositions, datapointId);
        },
      });
    }

    case getType(recountDatapointPosition): {
      const { index } = action.payload;

      const dp = state.content[index];

      if (dp.category === 'section' || dp.category === 'tuple') {
        return state;
      }

      return update(state, {
        content: {
          [index]: {
            content: {
              position: (rect: Rectangle2DCoordinates) =>
                rect ? sortBboxAttributes(rect) : rect,
            },
          },
        } as never,
        pendingOCR: {
          $set: dp && !isVirtualDatapoint(dp.id),
        },
      });
    }

    case getType(recountSuggestedOperationPositionAction): {
      return update(state, {
        suggestedOperations: {
          [action.meta]: {
            value: {
              content: {
                position: (rect: Rectangle2DCoordinates) =>
                  rect ? sortBboxAttributes(rect) : rect,
              },
            },
          },
        } as never,
      });
    }

    case getType(recountSuggestedOperationPositionFulfilledAction): {
      return update(state, {
        suggestedOperations: {
          [action.meta]: {
            value: {
              content: {
                value: {
                  $set: action.payload,
                },
              },
            },
          },
        },
      });
    }

    case getType(recountDatapointPositionFulfilled): {
      const updatedDatapoint =
        'content' in action.payload
          ? updateContentValue(state, action.payload)
          : undefined;

      return updatedDatapoint
        ? // TODO: Fix
          // @ts-expect-error
          update(updateDatapoints(state, [updatedDatapoint]), {
            sidebarDatapointUpdatedTimestamp: {
              $set: Date.now(),
            },
            documentTimestamp: {
              $set: Date.now(),
            },
            pendingOCR: {
              $set: false,
            },
            suggestedOperations: {
              $unset: [updatedDatapoint.id],
            },
          })
        : update(state, {
            pendingOCR: {
              $set: false,
            },
          });
    }

    case getType(setValidatedMeta): {
      const {
        meta: { index },
        payload: { isValidated, isHumanValidated },
      } = action;

      return update(state, {
        content: {
          [index]: {
            meta: {
              isValidated: {
                $set: isValidated,
              },
              isHumanValidated: {
                $set: isHumanValidated,
              },
            },
          },
        },
      });
    }

    case getType(updateDatapointMeta): {
      const {
        meta: { id, index },
        payload: { timeSpentOverall, timeSpent, validationSources },
      } = action;

      // Don't update deleted datapoints
      if (state.content[index]?.id !== id) {
        return state;
      }

      const deleteReplaceSuggestedOperationFromExtensions =
        state.suggestedOperations &&
        id in state.suggestedOperations &&
        state.suggestedOperations[id].source === 'extension' &&
        (validationSources ?? []).includes('human');

      return update(state, {
        content: {
          [index]: (datapoint: AnyDatapointDataST) => ({
            ...datapoint,
            timeSpentOverall,
            timeSpent,
            ...(validationSources ? { validationSources } : undefined),
          }),
        } as never,
        suggestedOperations: {
          $unset: deleteReplaceSuggestedOperationFromExtensions ? [id] : [],
        },
      });
    }

    case getType(updateDatapointValue): {
      const {
        meta: { id, index, validationSource },
        payload,
      } = action;

      const datapointIndex = index;

      // CONTINUE remove suggested operation
      return update(state, {
        unvalidatedContent: {
          $set: !isVirtualDatapoint(id),
        },
        content: {
          [datapointIndex]: {
            content: (content: AnyDatapointDataST) => ({
              ...content,
              ...payload,
            }),
            validationSources: {
              $set: validationSource ? [validationSource] : [],
            },
          },
        } as never,
        suggestedPositionsForValue: suggestedPositions => {
          return omit(suggestedPositions, id);
        },
      });
    }

    case getType(resetDatapoint): {
      const {
        payload: { index },
      } = action;

      return update(state, {
        content: {
          [index]: {
            content: {
              $set: initialContent,
            },
          },
        },
        documentTimestamp: {
          $set: Date.now(),
        },
      });
    }

    case getType(resetDatapointFulfilled): {
      return update(state, {
        updatedTimestamp: {
          $set: Date.now(),
        },
      });
    }

    case getType(createDatapointsFulfilled): {
      const {
        payload: { content: newDatapoints },
        meta: { parentIndex, schemas, complexLineItemsEnabled },
      } = action;

      const parentId = get(state, ['content', parentIndex, 'id']);

      return update(state, {
        content: dps =>
          addNewDatapoints({
            payload: {
              newDatapoints,
              datapoints: dps,
              schemas,
            },
            meta: { parentId, parentIndex },
            complexLineItemsEnabled,
          }),
      });
    }

    case getType(materializeVirtualDatapoints): {
      const { payload } = action;

      const datapointsToCreate = new Set(
        payload.tuplesToCreate.flatMap(t => t.validatedDatapointIds)
      );

      const datapointsToReplace = new Map(
        payload.datapointsToReplace.map(d => [d.datapointId, d])
      );

      return update(state, {
        content: content =>
          content.map(dp => {
            if (dp.category === 'datapoint' && datapointsToCreate.has(dp.id)) {
              return { ...dp, validationSources: ['human'] };
            }

            if (dp.category === 'datapoint' && datapointsToReplace.has(dp.id)) {
              const suggestedOp = state.suggestedOperations[dp.id];
              return {
                ...dp,
                validationSources: datapointsToReplace.get(dp.id)?.isValidated
                  ? ['human']
                  : ['table_suggester'],
                content: {
                  ...dp.content,
                  ...(suggestedOp?.op === 'replace'
                    ? suggestedOp.value.content
                    : {}),
                },
              };
            }

            return dp;
          }) as never,
        suggestedOperations: operations =>
          omit(
            operations,
            payload.datapointsToReplace.map(d => d.datapointId)
          ),
        pendingMaterialization: { $set: true },
      });
    }

    case getType(materializeVirtualDatapointsFulfilled): {
      const { virtualToRealIdMap, optimisticReplaceSuggestedOperations } =
        action.payload;

      const replaceSuggestedOperations = Object.fromEntries(
        optimisticReplaceSuggestedOperations
          .map(op => ({
            ...op,
            id: virtualToRealIdMap[op.id].id,
          }))
          .map(op => [op.id, op])
      );

      return update(state, {
        pendingMaterialization: { $set: false },
        suggestedOperations: ops => ({
          ...ops,
          ...replaceSuggestedOperations,
        }),
        content: content =>
          content.map(dp =>
            virtualToRealIdMap[dp.id]
              ? {
                  ...dp,
                  ...virtualToRealIdMap[dp.id],
                  ...(dp.category === 'tuple'
                    ? {
                        children: dp.children.map(child => ({
                          ...child,
                          id: virtualToRealIdMap[child.id]
                            ? virtualToRealIdMap[child.id].id
                            : child.id,
                        })),
                      }
                    : {
                        meta: {
                          ...dp.meta,
                          parentId: dp.meta.parentId
                            ? virtualToRealIdMap[dp.meta.parentId].id
                            : undefined,
                        },
                      }),
                  ...(dp.category === 'datapoint'
                    ? // when original DP was created by 'add' operations, we send an optimistic 'replace' - in that case we want to clear its value/position
                      replaceSuggestedOperations[virtualToRealIdMap[dp.id].id]
                      ? {
                          content: {
                            ...dp.content,
                            ocrPosition: null,
                            page: null,
                            position: null,
                            value: '',
                          },
                        }
                      : // for all other cases, we keep previous state
                        // TODO: Would be nicer to use actual BE response
                        {
                          validationSources:
                            virtualToRealIdMap[dp.id]?.validationSources ?? [],
                          content: {
                            ...dp.content,
                            value:
                              !dp.content?.value || dp.content.value === ''
                                ? dp.schema?.defaultValue ?? ''
                                : dp.content?.value ?? '',
                            page: dp.content?.page ?? null,
                            position: dp.content?.position ?? null,
                            ocrPosition: null,
                          },
                        }
                    : {}),
                }
              : dp.category === 'multivalue' &&
                  dp.children.some(child => !!virtualToRealIdMap[child.id])
                ? {
                    ...dp,
                    children: dp.children.map(child => ({
                      ...child,
                      id: virtualToRealIdMap[child.id]
                        ? virtualToRealIdMap[child.id].id
                        : child.id,
                    })),
                  }
                : dp
          ),
      });
    }

    case getType(deleteDatapoint): {
      const datapointId = last(action.payload.path);
      const datapointIndex = findIndex(state.content, { id: datapointId });

      // Just in case, delete all suggested operations for every simple datapoint that is being deleted here
      const dp = state.content[datapointIndex];
      const suggestedOperationsToDelete = getDescendendDatapointIds(
        state.content,
        dp
      );

      return update(
        removeAllChildren(state, datapointIndex, {
          removeParent: true,
        }),
        {
          suggestedOperations: {
            $unset: suggestedOperationsToDelete,
          },
        }
      );
    }

    case getType(fetchDatapoints):
      return update(state, {
        loaded: {
          $set: false,
        },
      });

    case getType(fetchDatapointsFulfilled):
      return update(state, {
        content: {
          $set: action.payload.content as never,
        },
        loaded: {
          $set: true,
        },
      });

    case getType(addSchemasToDatapoints):
      return update(state, {
        content: {
          $set: action.payload as never,
        },
        sidebarDatapointUpdatedTimestamp: {
          $set: Date.now(),
        },
      });

    // New Magic Grid V2

    case getType(deleteGridFulfilled): {
      const {
        meta: { datapointIndex, page, gridIndex },
      } = action;

      const grid = get(state, [
        'content',
        datapointIndex,
        'grid',
        'parts',
        gridIndex,
      ]);

      const deletedTupleIds = grid.rows
        .map(({ tupleId }: RowPosition) => tupleId)
        .filter((tupleId: string | null) => tupleId !== null);

      return update(
        update(
          update(state, {
            content: {
              [datapointIndex]: {
                grid: {
                  parts: parts =>
                    parts.filter(
                      ({ page: gridPage }: { page: number }) =>
                        page !== gridPage
                    ),
                },
              },
            },
          }),
          {
            content: {
              [datapointIndex]: dp =>
                removeTuplesFromParent(deletedTupleIds)(
                  dp as MultivalueDatapointDataST
                ),
            },
          }
        ),
        {
          content: c => removeTuplesFromContent(deletedTupleIds)(c),
          unvalidatedContent: {
            $set: !!deletedTupleIds.length,
          },
        }
      );
    }

    case getType(deleteAllGrids): {
      const {
        meta: { datapointIndex },
      } = action;

      const gridParts = get(state, [
        'content',
        datapointIndex,
        'grid',
        'parts',
      ]) as Grid[];

      const deletedTupleIds = gridParts
        .flatMap(part => part.rows.map(({ tupleId }: RowPosition) => tupleId))
        .filter(isNotNullOrUndefined);

      return update(
        update(
          update(state, {
            content: {
              [datapointIndex]: {
                grid: {
                  parts: {
                    $set: [],
                  },
                },
              },
            },
          }),
          {
            content: {
              [datapointIndex]: c =>
                removeTuplesFromParent(deletedTupleIds)(
                  c as MultivalueDatapointDataST
                ),
            },
          }
        ),
        {
          content: c => removeTuplesFromContent(deletedTupleIds)(c),
          unvalidatedContent: {
            $set: !!deletedTupleIds.length,
          },
        }
      );
    }

    case getType(applyGridToNextPages): {
      const {
        meta: { page, datapointIndex },
      } = action;

      const [deletedGrids, unDeletedGrids] = partition(
        get(state, ['content', datapointIndex, 'grid', 'parts']) as Grid[],
        ({ page: gridPage }) => gridPage > page
      );

      const deletedTupleIds = compact(
        deletedGrids.flatMap(grid =>
          grid.rows.map(({ tupleId }: RowPosition) => tupleId)
        )
      );

      return update(
        update(
          update(state, {
            content: {
              [datapointIndex]: {
                grid: {
                  parts: {
                    $set: unDeletedGrids,
                  },
                },
              },
            },
          }),
          {
            content: {
              [datapointIndex]: dps =>
                removeTuplesFromParent(deletedTupleIds)(
                  dps as MultivalueDatapointDataST
                ),
            },
          }
        ),
        {
          content: c => removeTuplesFromContent(deletedTupleIds)(c),
          unvalidatedContent: {
            $set: true,
          },
        }
      );
    }

    case getType(updateGridAction): {
      const {
        meta: { datapointIndex },
        payload: {
          datapoints: { updatedIds, clearedIds, deletedTupleIds },
          rows,
        },
      } = action;

      return update(
        update(
          update(updateGrid(state, action), {
            loadingDatapointIds: loadingIDs => [...loadingIDs, ...updatedIds],
            content: {
              [datapointIndex]: dps =>
                removeTuplesFromParent(compact(deletedTupleIds))(
                  dps as MultivalueDatapointDataST
                ),
            },
          }),
          {
            content: c => removeTuplesFromContent(compact(deletedTupleIds))(c),
          }
        ),
        {
          content: content => {
            if (!clearedIds.length) return content;

            return content.map(datapoint =>
              includes(clearedIds, datapoint.id)
                ? merge({}, datapoint, {
                    content: { value: '', position: null, page: null },
                    validationSources: [],
                  })
                : datapoint
            );
          },
          updatedTimestamp: uTs =>
            clearedIds.length || deletedTupleIds.length ? Date.now() : uTs,
          unvalidatedContent: {
            $set:
              !!updatedIds.length ||
              !!clearedIds.length ||
              !!deletedTupleIds.length ||
              !!rows.createdIndexes.length,
          },
        }
      );
    }

    case getType(createGridFulfilled): {
      return update(updateGrid(state, action), {
        unvalidatedContent: {
          $set: true,
        },
      });
    }

    case getType(updateGridAfterMovedFulfilled): {
      return update(updateGrid(state, action), {
        loadingDatapointIds: {
          $set: action.meta.updatedDatapointIds,
        },
        unvalidatedContent: {
          $set: true,
        },
      });
    }

    case getType(updateGridFulfilled): {
      const {
        payload: datapoints,
        meta: { updatedDatapointIds, createdTupleIds, gridIndex, fullResponse },
      } = action;

      return update(state, {
        content: content => {
          const [createdTuples, updatedTuples] = fullResponse
            ? // @ts-expect-error
              [datapoints[0].children, []]
            : createdTupleIds?.length
              ? partition(
                  // @ts-expect-errors
                  datapoints[0].children,
                  ({ id }: TupleDatapointData) => {
                    return includes(createdTupleIds, id);
                  }
                )
              : // @ts-expect-error
                [[], datapoints[0].children];

          const withCreatedDatapoints = createdTuples.length
            ? addNewTuples(
                datapoints[0] as MultivalueDatapointData,
                content,
                createdTuples,
                createdTupleIds ?? [],
                fullResponse ?? false,
                // Complex line items enabled
                false,
                gridIndex
              )
            : content;

          if (!updatedTuples.length) return withCreatedDatapoints;

          const updatedDatapoints: SimpleDatapointData[] =
            updatedTuples.flatMap((tuple: TupleDatapointData) =>
              tuple.children.map(child => child)
            );

          const updatedDatapointIDs = updatedDatapoints.map(({ id }) => id);

          return withCreatedDatapoints.map(datapoint =>
            includes(updatedDatapointIDs, datapoint.id)
              ? {
                  ...datapoint,
                  ...find(updatedDatapoints, { id: datapoint.id }),
                }
              : datapoint
          ) as never;
        },
        loadingDatapointIds: {
          $set: [],
        },
        documentTimestamp: timestamp =>
          updatedDatapointIds.length ? Date.now() : timestamp,
      });
    }

    case getType(deleteDatapointFulfilled): {
      const {
        meta: { parentIndex, deletedDatapointId },
      } = action;

      // not deleting tupleLine from footer but e.g. simpleMultivalue child
      if (!get(state, ['content', parentIndex, 'grid'])) {
        return state;
      }

      return update(state, {
        content: {
          [parentIndex]: {
            grid: {
              parts: gridParts =>
                gridParts.map(grid => ({
                  ...grid,
                  rows: grid.rows.map(row => ({
                    ...row,
                    tupleId:
                      row.tupleId === deletedDatapointId ? null : row.tupleId,
                    type: row.tupleId === deletedDatapointId ? null : row.type,
                  })),
                })),
            },
          },
        },
      });
    }

    case getType(updateGridAfterExtractAllRows): {
      const {
        payload: { currentDatapoint },
      } = action;

      return update(state, {
        content: {
          [currentDatapoint.meta.index]: {
            grid: {
              parts: {
                $set: [],
              },
            },
          },
        },
      });
    }

    case getType(applyColumnsToAllGrids): {
      const {
        meta: { datapointIndex, page },
        payload: { width, columns },
      } = action;

      const currentSchemaIds = compact(columns.map(({ schemaId }) => schemaId));

      const updatedGrids = state.content[
        datapointIndex
        // @ts-expect-error
      ].grid.parts.filter(({ page: gridPage }) => page !== gridPage) as Grid[];

      const datapoints = updatedGrids.reduce<{
        updatedIds: number[];
        clearedIds: number[];
      }>(
        (acc, grid) => {
          const removedSchemaIds = compact(
            grid.columns.map(({ schemaId }) => schemaId)
          ).filter(schemaId => !currentSchemaIds.includes(schemaId));

          const clearedIds = getUpdatedDatapointIdsForColumns(state, {
            payload: { grid },
            meta: { datapointIndex, page },
          })(removedSchemaIds);

          const updatedIds = getUpdatedDatapointIdsForColumns(state, {
            payload: { grid },
            meta: { datapointIndex, page },
          })(currentSchemaIds);

          return {
            updatedIds: [...acc.updatedIds, ...updatedIds],
            clearedIds: [...acc.clearedIds, ...clearedIds],
          };
        },
        {
          updatedIds: [],
          clearedIds: [],
        }
      );

      return update(
        update(state, {
          content: {
            [datapointIndex]: {
              grid: {
                parts: parts =>
                  parts.map(part => ({
                    ...part,
                    columns,
                    width,
                  })),
              },
            },
          },
        }),
        {
          content: content => {
            if (!datapoints.clearedIds.length) return content;

            return content.map(datapoint =>
              includes(datapoints.clearedIds, datapoint.id)
                ? merge({}, datapoint, {
                    content: { value: '', position: null, page: null },
                    validationSources: [],
                  })
                : datapoint
            );
          },
          loadingDatapointIds: {
            $set: datapoints.updatedIds,
          },
          updatedTimestamp: uTs => {
            return datapoints.clearedIds.length ? Date.now() : uTs;
          },
          unvalidatedContent: {
            $set:
              !!datapoints.updatedIds.length || !!datapoints.clearedIds.length,
          },
        }
      );
    }

    // Magic Grid

    case getType(pasteGrid): {
      const {
        meta: { datapointIndex, page, existingGridIndex },
      } = action;

      const grid = get(state, [
        'content',
        datapointIndex,
        'grid',
        'parts',
        existingGridIndex,
      ]) as Grid;

      const deletedTupleIds =
        existingGridIndex > -1
          ? compact(grid.rows.map(({ tupleId }: RowPosition) => tupleId))
          : [];

      return update(
        update(
          update(state, {
            content: {
              [datapointIndex]: {
                grid: {
                  parts: (parts = []) =>
                    parts.filter(part => part.page !== page),
                },
              },
            },
          }),
          {
            content: {
              [datapointIndex]: dp =>
                removeTuplesFromParent(deletedTupleIds)(
                  dp as MultivalueDatapointDataST
                ),
            },
          }
        ),
        {
          content: c => removeTuplesFromContent(deletedTupleIds)(c),
          unvalidatedContent: {
            $set: !!deletedTupleIds.length,
          },
        }
      );
    }

    case getType(copyGrid):
      return update(state, {
        gridClipboard: {
          $set: action.payload,
        },
      });

    case getType(batchDatapointUpdate): {
      const {
        meta: { datapointIndex, ids },
      } = action;

      const data = get(state, ['content', datapointIndex]);

      if (!isSimpleDatapoint(data)) return state;

      const payload = getDataForBatchUpdate(data);

      return update(state, {
        content: datapoints =>
          datapoints.map(datapoint =>
            includes(ids, datapoint.id)
              ? merge({}, datapoint, payload)
              : datapoint
          ),
        updatedTimestamp: uTs => (ids.length ? Date.now() : uTs),
      });
    }

    case getType(bulkUpdateDatapointValue): {
      const {
        meta: { updates },
      } = action;

      return update(state, {
        content: datapoints =>
          datapoints.map(datapoint => {
            const updatedValue = updates?.[datapoint.id];

            return updatedValue
              ? merge({}, datapoint, {
                  content: {
                    value: updatedValue,
                  },
                  validationSources: ['human'],
                })
              : datapoint;
          }),
        updatedTimestamp: uTs =>
          Object.entries(updates).length ? Date.now() : uTs,
      });
    }

    // swapping schema ids
    case getType(changeDatapointsSchemaIds): {
      const { operations } = action.payload;
      const { schemaMap } = action.meta;

      return update(state, {
        content: dps =>
          performSchemaIdSwaps(operations, schemaMap)(dps) as never,
      });
    }

    case getType(acceptSuggestedOperationsFulfilledAction): {
      const { updatedMultivalue } = action.payload;

      const newState = updateDatapoints(
        state,
        [updatedMultivalue],
        false /* complex line items */
      );

      return update(newState, {
        suggestedOperations: {
          $set: [],
        },
      });
    }

    case getType(updateSuggestedOperationPositionAction):
      return update(state, {
        suggestedOperations: {
          [action.meta]: {
            value: {
              content: (content: DatapointValueDataST) => ({
                ...content,
                ...action.payload.content,
              }),
            },
          },
        } as never,
      });

    case getType(declineSuggestedOperationsAction):
    case getType(sidebarDatapointHasChangedAction):
      return update(state, {
        suggestedOperations: {
          $set: {},
        },
        gridClipboard: {
          $set: initialGridClipboard,
        },
        // gridHasChanged seems to be unused
      });

    case getType(deleteAnnotationFulfilled):
    case getType(rejectAnnotationFulfilled):
    case getType(confirmAnnotationFulfilled):
    case getType(postponeAnnotationFulfilled):
      return update(state, {
        matchedTriggerRules: {
          $set: initialState.matchedTriggerRules,
        },
      });

    case getType(fetchSuggestedPositionsForValueFulfilled): {
      const newSuggestion = { [action.meta.id]: action.payload };

      return update(state, {
        suggestedPositionsForValue: previous => ({
          ...previous,
          ...newSuggestion,
        }),
      });
    }

    case getType(removeSuggestedPositionsForValue): {
      return update(state, {
        suggestedPositionsForValue: suggestedPositions =>
          omit(suggestedPositions, action.meta.id),
      });
    }

    case getType(batchDatapointConfirm): {
      const {
        payload: { datapoints },
      } = action;

      const datapointIdsSet = new Set(datapoints.map(dp => dp.id));

      return update(state, {
        content: content =>
          content.map(datapoint =>
            datapointIdsSet.has(datapoint.id)
              ? update(datapoint, { validationSources: { $push: ['human'] } })
              : datapoint
          ),
      });
    }

    // Initial State
    case getType(logoutUser):
    case getType(nextAnnotation):
    case getType(nextAnnotableAnnotation):
    case getType(fetchMembershipTokenFulfilled):
    case getType(leaveValidation):
    case getType(clearAnnotationData):
      return initialState;

    default:
      return state;
  }
};

export default datapointsReducer;
