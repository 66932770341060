import { Delete, Edit } from '@rossum/ui/icons';
import { Button, IconButton } from '@rossum/ui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../style.module.sass';

type Props = {
  children: ReactNode;
  title: ReactNode;
  className?: string;
  onEdit?: () => void;
  onDelete?: () => void;
};

const Section = ({ children, title, className, onEdit, onDelete }: Props) => (
  <div
    className={clsx(
      styles.SchemaDatapoints,
      className,
      !title && styles.SchemaDatapointsNoTitle
    )}
    data-cy="fields-datapoints-section"
  >
    <div
      className={clsx(
        styles.SchemaSectionHeader,
        !title && styles.SchemaSectionHeaderEmpty
      )}
    >
      <div className={styles.SchemaDatapointsTitleRedesign}>{title}</div>
      <div className={styles.ActionButtons}>
        {onEdit && (
          <Button
            color="inherit"
            size="small"
            startIcon={<Edit />}
            onClick={() => onEdit()}
            data-cy="edit-section"
          >
            <FormattedMessage id="containers.settings.queueFields.edit.edit" />
          </Button>
        )}
        {onDelete && (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => onDelete()}
            data-cy="delete-section"
          >
            <Delete fontSize="small" />
          </IconButton>
        )}
      </div>
    </div>
    {children}
  </div>
);

export default Section;
