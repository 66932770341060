import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import ImageFilterCenterFocus from 'mdi-react/ImageFilterCenterFocusIcon';
import { FormattedMessage } from 'react-intl';
import {
  ROSSUM_AI_ENGINE_LINK,
  SCHEMA_CUSTOM_FIELDS_LINK,
} from '../../../../../constants/values';
import styles from '../style.module.sass';
import FieldSection from './FieldSection';

const CustomFields = () => (
  <FieldSection
    title={<FormattedMessage id="containers.settings.fields.custom" />}
  >
    <div className={clsx(styles.InnerContent, styles.CustomFields)}>
      <div className={styles.Oval}>
        <ImageFilterCenterFocus className={styles.IconInOval} />
      </div>
      <div className={styles.InnerContentHeading}>
        <FormattedMessage id="containers.settings.fields.custom.heading" />
      </div>
      <div className={styles.InnerContentText}>
        <FormattedMessage id="containers.settings.fields.custom.text" />
      </div>
      <a
        className={styles.InnerContentButton}
        href={SCHEMA_CUSTOM_FIELDS_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          variant="contained"
          size="small"
          data-cy="how-to-add-custom-fields"
        >
          <FormattedMessage id="containers.settings.fields.custom.button" />
        </Button>
      </a>

      <div className={styles.LinksSection}>
        <div className={styles.CustomFieldLinks}>
          <AlertCircleOutlineIcon />
          <FormattedMessage id="containers.settings.fields.custom.linkArticleDisclaimer" />
          <a
            className={styles.ReadMoreLink}
            href={ROSSUM_AI_ENGINE_LINK}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="read-more-ai-engine"
          >
            <FormattedMessage id="containers.settings.fields.custom.linkArticle" />
          </a>
        </div>
      </div>
    </div>
  </FieldSection>
);

export default CustomFields;
