import { LogsQuery } from '@rossum/api-client/hooks';
import { Hook } from '@rossum/api-client/hooks';
import { Stack } from '@rossum/ui/material';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { asScalar } from '../../../../lib/url';
import SearchInput from '../../../../ui/search-input/SearchInput';
import DatepickerFilters from './DatepickerFilters';
import FilterSelect from './FilterSelect';
import HooksSelect from './HookSelect';

const FILTERS_SELECT_WIDTH = 280;
const LOG_LEVEL_OPTIONS = ['INFO', 'ERROR', 'WARNING'];
const PAGE_SIZE_OPTIONS = ['5', '10', '25', '50', '100'];

type Props = {
  filters: LogsQuery;
  setFilters: (filters: LogsQuery) => void;
  extensions: Array<Hook> | undefined;
  isFetchingExtensions: boolean;
  isLoadingExtensions: boolean;
};

const Filters = ({
  isFetchingExtensions,
  isLoadingExtensions,
  extensions,
  filters,
  setFilters,
}: Props) => {
  const { search } = useLocation();

  const [searchState, setSearchState] = useState(
    new URLSearchParams(search).get('search') || ''
  );

  const debouncedSetFilters = useMemo(
    () =>
      debounce((search, filters) => {
        setFilters({
          ...filters,
          search: search.length > 0 ? search : undefined,
        });
      }, 400),
    [setFilters]
  );

  const handleOnChange = (search: string) => {
    setSearchState(search);
    debouncedSetFilters(search, filters);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      minHeight={40}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={2}>
        <DatepickerFilters
          filters={filters}
          setFilters={setFilters}
          width={FILTERS_SELECT_WIDTH}
        />
        <HooksSelect
          extensions={extensions}
          value={asScalar(filters.hook)}
          setHookFilter={hooks => setFilters({ ...filters, hook: hooks })}
          isFetchingExtensions={isFetchingExtensions}
          isLoadingExtensions={isLoadingExtensions}
          width={FILTERS_SELECT_WIDTH}
        />
        <FilterSelect
          filterSelectType="logLevel"
          value={asScalar(filters.logLevel)}
          options={LOG_LEVEL_OPTIONS}
          filters={filters}
          setFilters={setFilters}
          isLoadingExtensions={isLoadingExtensions}
        />
        <FilterSelect
          filterSelectType="pageSize"
          value={asScalar(filters.pageSize)}
          filters={filters}
          options={PAGE_SIZE_OPTIONS}
          setFilters={setFilters}
          isLoadingExtensions={isLoadingExtensions}
        />
      </Stack>
      <SearchInput onChange={handleOnChange} value={searchState} />
    </Stack>
  );
};

export default Filters;
