import { userSidebarItems } from '../User/sidebarItems';

// as a convention to handle both static and dynamic paths we use functions to handle all cases related to this route.
// these functions are to be used anytime we work with /users route outside of UsersRoutes component.
const USERS_PARAM_KEY = 'userId';
export type UserParams = Record<typeof USERS_PARAM_KEY, string>;

export const userDetailsRoute = `/settings/users/:${USERS_PARAM_KEY}`;
export const userDetailsAccessRoute = `${userDetailsRoute}/access`;
export const userDetailsInfoRoute = `${userDetailsRoute}/info`;

export const usersPath = () => '/settings/users';
export const userDetailsAccessPath = (userId: number) =>
  `/settings/users/${userId}/access`;

export const getIdFromUserPath = (pathname: string): number | undefined => {
  const pathMatch = pathname.match(/^\/settings\/users\/(\d+)/);
  return pathMatch ? parseInt(pathMatch[1], 10) : undefined;
};

export const getActiveUserPage = (pathname: string) =>
  userSidebarItems.items
    .map(({ name }) => name)
    .find(page => pathname.includes(page));

export const startsWithUsersPath = (pathname: string) =>
  pathname.match(/^\/users/);
