import { Collapse, Divider, Paper, Stack } from '@rossum/ui/material';
import { Dispatch, memo, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import InviteColleague from '../../../components/InviteColleague';
import { isTrialSelector } from '../../../redux/modules/organization/selectors';
import { State } from '../../../types/state';
import { SidebarQueues } from '../../queues';
import { DashboardQuery, LevelOptions } from '../hooks/useDashboardQuery';
import { DashboardModalsState } from '../types';
import AllDocumentItem from './components/AllDocumentItem';
import Toolbar from './components/Toolbar';

export const SIDEBAR_WIDTH = 300;

type Props = {
  isSidebarOpen: boolean;
  setDashboardState: Dispatch<SetStateAction<DashboardModalsState>>;
  currentQueueId?: number;
  onQueueChange: (queueId: number) => void;
  handleQueryChange: (newQuery: DashboardQuery) => void;
  level: LevelOptions | null;
};

const Sidebar = memo(
  ({
    onQueueChange,
    isSidebarOpen,
    setDashboardState,
    currentQueueId,
    handleQueryChange,
    level,
  }: Props) => {
    const isTrial = useSelector((state: State) => isTrialSelector(state));

    const [searchValue, setSearchValue] = useState('');

    const setAllDocumentsLevelActive = () => {
      handleQueryChange({
        filtering: undefined,
        view: undefined,
        level: 'all',
      });
    };

    const handleOnAddQueueClick = () =>
      setDashboardState(state => ({
        ...state,
        addingQueue: true,
        dialogResetCounter: state.dialogResetCounter + 1,
      }));

    const handleOnAddWorkspaceClick = () => {
      setDashboardState(state => ({
        ...state,
        addingWorkspace: true,
        dialogResetCounter: state.dialogResetCounter + 1,
      }));
    };

    return (
      <Collapse in={isSidebarOpen} orientation="horizontal">
        <Stack
          flexDirection="column"
          component={Paper}
          elevation={0}
          sx={{
            width: SIDEBAR_WIDTH,
            height: '100%',
            zIndex: 99,
            borderRadius: 0,
          }}
        >
          <AllDocumentItem
            isActive={level === 'all'}
            handleOnClick={setAllDocumentsLevelActive}
            onAddQueueClick={handleOnAddQueueClick}
            onAddWorkspaceClick={handleOnAddWorkspaceClick}
          />
          <Divider />
          <Toolbar
            searchValue={searchValue}
            onSearchChange={value => setSearchValue(value)}
          />

          <SidebarQueues
            currentQueueId={currentQueueId}
            onQueueChange={onQueueChange}
            setAllDocumentsLevelActive={setAllDocumentsLevelActive}
            searchValue={searchValue}
          />

          <Divider />

          {isTrial && (
            <Stack py={4} pb={2} justifyContent="center" alignItems="center">
              <InviteColleague />
            </Stack>
          )}
        </Stack>
      </Collapse>
    );
  }
);

export default Sidebar;
