import { zodResolver } from '@hookform/resolvers/zod';
import { getIDFromUrl } from '@rossum/api-client';
import { Engine } from '@rossum/api-client/engines';
import { HttpError } from '@rossum/api-client/errors';
import { Button, Chip, Stack, Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { mapValues } from 'remeda';
import { z } from 'zod';
import { ConnectedQueueTile } from '../components/ConnectedQueueTile';
import { EngineForm } from '../components/EngineForm';
import ShowMoreButton from '../components/ShowMoreButton';
import TileLink from '../components/TileLink';
import TilesList from '../components/TilesList';
import TilesListEmptyState from '../components/TilesListEmptyState';
import { useCreateEngine } from '../hooks/useCreateEngine';
import { useInfiniteQueueStatsForEngine } from '../hooks/useInfiniteQueueStatsForEngine';
import { usePatchEngine } from '../hooks/usePatchEngine';
import { useUnpaginatedEngineFields } from '../hooks/useUnpaginatedEngineFields';
import { engineDetailPath, engineFieldDetailPath } from '../paths';

export const ENGINE_FORM_ID = 'engine_detail_form';

const resolveErrorPayload = (error: unknown) => {
  if (error instanceof HttpError) {
    const errorSchema = z
      .object({
        name: z.array(z.string()),
        description: z.array(z.string()),
      })
      .partial();

    return errorSchema.catch({}).parse(error.data);
  }

  return {};
};

export const engineFormSchema = (intl: IntlShape) =>
  z.object({
    engine: z.object({
      name: z.string().min(
        1,
        intl.formatMessage({
          id: 'features.engines.error.form.fieldRequired',
        })
      ),
      description: z.string(),
      learningEnabled: z.boolean(),
    }),
  });

const useEngineForm = ({
  error,
  engine,
}: {
  error: unknown;
  engine?: Engine;
}) => {
  const intl = useIntl();
  return useForm<z.TypeOf<ReturnType<typeof engineFormSchema>>>({
    errors: {
      // TODO: Translate some common errors / add some F/E validation.
      engine: mapValues(resolveErrorPayload(error), apiError =>
        apiError ? { type: 'api_error', message: apiError[0] } : undefined
      ),
    },
    defaultValues: {
      engine: engine ?? {
        name: '',
        learningEnabled: true,
        description: '',
      },
    },
    resolver: zodResolver(engineFormSchema(intl)),
  });
};

const AddFieldButton = ({ engineId }: { engineId: number }) => {
  const intl = useIntl();

  return (
    <Button
      component={Link}
      to={engineFieldDetailPath(engineId, 'new')}
      variant="contained"
      color="primary"
      data-cy="add-engine-field-button"
      sx={{
        '&:hover': { color: theme => theme.palette.primary.contrastText },
      }}
    >
      {intl.formatMessage({
        id: 'features.engines.engineDetail.addField',
      })}
    </Button>
  );
};

export const EngineAddPage = () => {
  const { mutate, error } = useCreateEngine();
  const history = useHistory();
  const { control, handleSubmit } = useEngineForm({ error });

  return (
    <Stack
      px={4}
      py={8}
      spacing={8}
      id={ENGINE_FORM_ID}
      component="form"
      onSubmit={handleSubmit(({ engine }) => {
        mutate(
          { ...engine, type: 'extractor' },
          {
            onSuccess: engine => history.push(engineDetailPath(engine.id)),
          }
        );
      })}
    >
      <EngineForm control={control} />
    </Stack>
  );
};

export const EngineDetailPage = ({ engine }: { engine: Engine }) => {
  const intl = useIntl();
  const { mutate, error } = usePatchEngine(engine.id);

  const {
    data: queueStats,
    fetchNextPage,
    hasNextPage,
    isFetching,
    status: queuesInfoStatus,
  } = useInfiniteQueueStatsForEngine(engine.url);

  const { data: engineFieldsData, status: fieldsStatus } =
    useUnpaginatedEngineFields({
      engine: getIDFromUrl(engine.url),
    });

  const { data: unusedEngineFields } = useUnpaginatedEngineFields(
    {
      engine: getIDFromUrl(engine.url),
      used: false,
    },
    {
      select: fields => new Set(fields.map(field => field.id)),
    }
  );

  const { control, handleSubmit } = useEngineForm({ error, engine });

  return (
    <Stack
      px={4}
      py={4}
      spacing={4}
      id={ENGINE_FORM_ID}
      component="form"
      onSubmit={handleSubmit(({ engine }) => {
        mutate(engine);
      })}
    >
      <EngineForm control={control} />

      <TilesList
        title={intl.formatMessage({
          id: 'features.engines.engineDetail.engineFields.title',
        })}
        items={engineFieldsData}
        renderTile={field => (
          <TileLink
            key={field.id}
            to={engineFieldDetailPath(engine.id, field.id)}
          >
            <Stack direction="row" spacing={4} alignItems="center">
              <Stack>
                <Typography variant="h6">{field.label}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {field.name}
                </Typography>
              </Stack>
              {unusedEngineFields?.has(field.id) && (
                <Chip
                  size="small"
                  variant="outlined"
                  color="warning"
                  label={intl.formatMessage({
                    id: 'features.engines.engineDetail.engineFields.unusedWarning',
                  })}
                />
              )}
            </Stack>
          </TileLink>
        )}
        status={fieldsStatus}
        emptyState={
          <TilesListEmptyState
            title={intl.formatMessage({
              id: 'features.engines.engineDetail.engineFields.empty.title',
            })}
            subtitle={intl.formatMessage({
              id: 'features.engines.engineDetail.engineFields.empty.subtitle',
            })}
          >
            <AddFieldButton engineId={engine.id} />
          </TilesListEmptyState>
        }
        buttons={[
          <AddFieldButton engineId={engine.id} key="add-engine-field" />,
        ]}
      />
      <TilesList
        title={intl.formatMessage({
          id: 'features.engines.engineDetail.connectedQueues.title',
        })}
        items={queueStats.results}
        renderTile={({ queue, workspace, stats }) => (
          <ConnectedQueueTile
            key={queue.id}
            queue={queue}
            workspace={workspace}
            connectedFieldsCount={stats?.numberOfUsedEngineFields}
            totalFieldsCount={engineFieldsData?.length}
          />
        )}
        status={queuesInfoStatus}
        emptyState={
          <TilesListEmptyState
            title={intl.formatMessage({
              id: 'features.engines.engineDetail.connectedQueues.empty.title',
            })}
          />
        }
        buttons={
          hasNextPage
            ? [
                <ShowMoreButton
                  key="show-more-results"
                  onClick={fetchNextPage}
                  isFetching={isFetching}
                />,
              ]
            : []
        }
      />
    </Stack>
  );
};
