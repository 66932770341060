import { Stack } from '@rossum/ui/material';
import { fieldsIconsMap } from '../../../queue-settings/fields/helpers';
import { CellIcons } from './CellIcons';

type HeaderCellProps = {
  iconType: keyof typeof fieldsIconsMap | null;
  headerName?: string;
};

const HeaderCell = ({ headerName, iconType }: HeaderCellProps) => (
  <Stack
    spacing={0.5}
    direction="row"
    component="span"
    sx={{ fontWeight: 500 }}
    alignItems="center"
  >
    <CellIcons type={iconType} />
    <span>{headerName}</span>
  </Stack>
);

export default HeaderCell;
