import { ArrowBack } from '@rossum/ui/icons';
import { Alert, Button, Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { SIMPLE_COMPONENT_WIDTH } from '../constants';

const MissingValues = ({ onBackClick }: { onBackClick?: () => void }) => {
  const intl = useIntl();

  return (
    <Stack
      p={2}
      alignItems="flex-start"
      spacing={2}
      sx={{ width: SIMPLE_COMPONENT_WIDTH }}
    >
      {onBackClick && (
        <Button
          variant="text"
          size="small"
          color="inherit"
          startIcon={<ArrowBack />}
          onClick={() => onBackClick()}
          data-cy="all-documents-filter-back-button"
        >
          {intl.formatMessage({ id: 'containers.filtering.buttons.back' })}
        </Button>
      )}
      <Stack alignItems="center" width={1}>
        <Alert color="warning" closeText="Close">
          {intl.formatMessage({ id: 'containers.filtering.valuesAreMissing' })}
        </Alert>
      </Stack>
    </Stack>
  );
};

export default MissingValues;
