import { yupResolver } from '@hookform/resolvers/yup';
import { ID } from '@rossum/api-client';
import { RelatedObjectsCounts } from '@rossum/api-client/queues';
import { DeleteForever } from '@rossum/ui/icons';
import { Stack, Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useDeleteQueue } from '../../../../business/queues/useDeleteQueue';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import ActionDialogCheckboxes from '../../../../components/UI/ActionDialog/ActionDialogCheckboxes';
import Loader from '../../../../containers/Loader';
import { boldText, nbsp } from '../../../../lib/formaterValues';
import { isNotNullOrUndefined } from '../../../../lib/typeGuards';
import { queuesSelector } from '../../../../redux/modules/queues/selector';
import { useRelatedObjectsCounts } from '../hooks/useRelatedObjectsCounts';

type Props = {
  queueId: ID;
  onDelete: (queueId: number) => void;
  onCancel: () => void;
};

type CheckboxSchemaProps = {
  intl: IntlShape;
  objectsCounts: RelatedObjectsCounts;
};

type QueueDialogFormProps = {
  objectsCounts: RelatedObjectsCounts;
} & Props;

const getCheckboxSchema = ({
  intl,
  objectsCounts: { annotations, emails },
}: CheckboxSchemaProps) =>
  [
    annotations !== 0
      ? {
          label: intl.formatMessage({
            id: 'components.modal.deleteQueue.checkbox.documents',
          }),
          name: 'documentsCheckbox' as const,
        }
      : null,
    emails !== 0
      ? {
          label: intl.formatMessage({
            id: 'components.modal.deleteQueue.checkbox.emails',
          }),
          name: 'emailsCheckbox' as const,
        }
      : null,
    {
      label: intl.formatMessage({
        id: 'components.modal.deleteQueue.checkbox.usageData',
      }),
      name: 'usageDataCheckbox' as const,
    },
  ].filter(isNotNullOrUndefined);

const getCheckboxValidationSchema = <T extends string>(ids: T[]) => {
  const shape = Object.fromEntries(
    ids.map(id => [id, yup.boolean().required().oneOf([true], '')] as const)
  );
  return yup.object().shape(shape);
};

const QueueDialogForm = ({
  objectsCounts,
  onDelete,
  onCancel,
  queueId,
}: QueueDialogFormProps) => {
  const intl = useIntl();

  const { mutate: deleteQueue, isLoading } = useDeleteQueue(onDelete);
  const queue = useSelector(queuesSelector).find(queue => queue.id === queueId);

  const checkboxSchema = getCheckboxSchema({
    intl,
    objectsCounts,
  });

  const checkboxValidationSchema = getCheckboxValidationSchema(
    checkboxSchema.map(checkbox => checkbox.name)
  );

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(checkboxValidationSchema),
    defaultValues: {
      documentsCheckbox: false,
      emailsCheckbox: false,
      usageDataCheckbox: false,
    },
  });

  const resolveKey = (documentsCount: number, emailsCount: number) => {
    if (emailsCount === 0 && documentsCount === 0) {
      return 'withoutData';
    }
    if (objectsCounts.emails === 0 && objectsCounts.annotations !== 0) {
      return 'onlyDocuments';
    }
    if (objectsCounts.emails !== 0 && objectsCounts.annotations === 0) {
      return 'onlyEmails';
    }
    return 'documentsAndEmails';
  };

  return (
    <form onSubmit={handleSubmit(() => deleteQueue(queueId))}>
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'components.modal.deleteQueue.title',
        })}
        DialogTitleIcon={DeleteForever}
        onCancel={() => onCancel()}
        dialogColor="error"
        dialogActions={
          <ActionDialogActions
            hideConfirmButton={objectsCounts.trainedDedicatedEngines > 0}
            confirmButtonProps={{ type: 'submit', disabled: isLoading }}
            confirmButtonText={intl.formatMessage({
              id: 'components.modal.deleteQueue.button.delete',
            })}
            dataCyConfirm="delete-queue-confirm-btn-footer"
            dataCyCancel="delete-queue-cancel-btn-footer"
          />
        }
        dataCy="delete-queue-cancel-btn"
      >
        {objectsCounts.trainedDedicatedEngines > 0 ? (
          <Stack spacing={2}>
            <Typography variant="body1">
              {intl.formatMessage(
                { id: 'components.modal.deleteQueueInTraining.textMain' },
                { boldText, queue: queue?.name ?? '' }
              )}
            </Typography>
            <Typography variant="body1">
              {intl.formatMessage({
                id: 'components.modal.deleteQueueInTraining.textSecondary',
              })}
            </Typography>
          </Stack>
        ) : (
          <>
            <Typography variant="body1">
              {intl.formatMessage(
                {
                  id: `components.modal.deleteQueue.text.${resolveKey(
                    objectsCounts.annotations,
                    objectsCounts.emails
                  )}`,
                },

                {
                  boldText,
                  queue: queue?.name ?? '',
                  documents: objectsCounts.annotations,
                  emails: objectsCounts.emails,
                  nbsp,
                }
              )}
            </Typography>
            <ActionDialogCheckboxes
              control={control}
              checkboxSchema={checkboxSchema}
              errorMessage={intl.formatMessage({
                id: 'components.modal.deleteQueue.checkbox.error',
              })}
            />
          </>
        )}
      </ActionDialog>
    </form>
  );
};

export const QueueDelete = (props: Props) => {
  const { data: objectsCounts, isLoading: isObjectsCountsLoading } =
    useRelatedObjectsCounts(props.queueId);

  return !objectsCounts || isObjectsCountsLoading ? (
    <Loader absolute />
  ) : (
    <QueueDialogForm {...props} objectsCounts={objectsCounts} />
  );
};
