import { ActionType, createAction } from 'typesafe-actions';
import { SearchResult } from '../../../../types/search';

type FetchSuggestedPositionsForValueFulfilledMeta = {
  id: number;
  schemaId: string;
};

export const fetchSuggestedPositionsForValueFulfilled = createAction(
  'FETCH_SUGGESTED_POSITIONS_FOR_VALUE_FULFILLED',
  (
    payload: SearchResult[],
    _meta: FetchSuggestedPositionsForValueFulfilledMeta
  ) => payload,
  (_, meta) => meta
)<SearchResult[], FetchSuggestedPositionsForValueFulfilledMeta>();

export const removeSuggestedPositionsForValue = createAction(
  'REMOVE_SUGGESTED_POSITIONS_FOR_VALUE',
  undefined,
  (_, meta: { id: number }) => meta
)<undefined, { id: number }>();

export type SuggestedPositionsForValueActions = ActionType<
  | typeof fetchSuggestedPositionsForValueFulfilled
  | typeof removeSuggestedPositionsForValue
>;
