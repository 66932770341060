import { get, includes } from 'lodash';
import { getFromUnion } from '../../lib/helpers';
import {
  AnyDatapointData,
  AnyDatapointDataST,
  SimpleDatapointDataST,
  ValidationSources,
} from '../../types/datapoints';

export const validationTickColor = (
  data: AnyDatapointDataST,
  active: boolean,
  suggestedOperation?: boolean,
  hasError?: boolean
) => {
  const validationSources = getFromUnion(data, 'validationSources');

  if (
    get(data, ['schema', 'type']) === 'button' ||
    hasError ||
    suggestedOperation
  )
    return null;

  if (data?.meta?.sidebarDatapoint) {
    if (data.meta.isHumanValidated) return active ? 'white' : 'green';

    if (data.meta.isValidated) return active ? 'white' : 'gray';
  }

  if (includes(validationSources, 'human')) return active ? 'white' : 'green';

  if (validationSources?.length) return active ? 'white' : 'gray';

  return null;
};

export const irrelevantTickInfo = (
  validationSources: Array<ValidationSources>,
  documentAutomated: boolean | undefined
) =>
  validationSources?.length === 1 &&
  validationSources.includes('not_found') &&
  !documentAutomated;

export const isLoggableDatapoint = (
  dp: AnyDatapointDataST | AnyDatapointData | undefined
): dp is SimpleDatapointDataST => {
  return dp !== undefined && dp.category === 'datapoint' && !!dp.content;
};

export const getLoggableDatapointValue = (
  dp: AnyDatapointDataST | AnyDatapointData | undefined
) => (isLoggableDatapoint(dp) ? dp.content?.value : undefined);
