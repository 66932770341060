import { Survey } from '@rossum/api-client/surveys';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SurveyName } from '../../../business/surveys/surveysUuidMap';
import { getUnsafe } from '../../../lib/helpers';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { dataCaptureSurveyBlockersSelector } from '../selectors';
import { useLatestSurvey } from './useLatestSurvey';

type UseSurveyIfEnabledProps = {
  surveyName: SurveyName;
  userEngaged: boolean;
};

const hasUserAnsweredSurvey = (survey: Survey) =>
  survey.answers?.some(answer => answer.value !== null) ?? false;

const isUserAnnoyed = (survey: Survey) =>
  Number(getUnsafe(survey.additionalData, 'skipCount')) > 1;

const enoughAuroraInteractions = (survey: Survey) =>
  Number(getUnsafe(survey.additionalData, 'auroraUsageCount')) > 5;

export const useSurveyIfEnabled = ({
  surveyName,
  userEngaged,
}: UseSurveyIfEnabledProps) => {
  const surveyOverrides = JSON.parse(
    localStorage.getItem('surveyOverrides') ?? '{}'
  );

  // Blockers for enabling / displaying surveys.
  const getSurveyDialogBlockers = useCallback(
    (survey: Survey) =>
      [
        hasUserAnsweredSurvey(survey) ? 'answered' : undefined,
        isUserAnnoyed(survey) ? 'annoyed' : undefined,
        surveyName === 'auroraForLineItemsSurvey' &&
        !enoughAuroraInteractions(survey)
          ? 'not_enough_cli_interaction'
          : undefined,
        !userEngaged ? 'insufficient_engagement' : undefined,
      ].filter(isNotNullOrUndefined),
    [surveyName, userEngaged]
  );

  const getSurveyBlockers = useSelector(dataCaptureSurveyBlockersSelector);

  const blockers = getSurveyBlockers(surveyName);

  const { survey } = useLatestSurvey({
    surveyName,
    enabled: blockers.length === 0 || surveyOverrides[surveyName] === 'true',
  });

  const viewBlockers = survey ? getSurveyDialogBlockers(survey) : undefined;

  const shouldViewSurvey = viewBlockers
    ? surveyOverrides[surveyName] === 'true' || viewBlockers.length === 0
    : false;

  return {
    survey,
    blockers,
    viewBlockers,
    shouldViewSurvey,
    surveyName,
  };
};
