import { EvaluateFormulasResponse } from '@rossum/api-client/internal';
import { UseQueryResult } from '@tanstack/react-query';
import { get } from 'lodash';
import React, { createContext, useContext, useMemo } from 'react';
import { NamePrefixType } from '../../../../../containers/Queue/containers/QueueFields/types';
import { useCurrentSchemaConceptContext } from '../currentSchema/currentSchemaContext';
import { useCalculatePreview } from './useCalculatePreview';
import { useGetAnnotationsForPreview } from './useGetAnnotationsForPreview';
import { useInitialPreview } from './useInitialPreview';

const FormulaPreviewContext = createContext<{
  annotationsQuery: ReturnType<typeof useGetAnnotationsForPreview>;
  evaluationQueries: Array<
    UseQueryResult<EvaluateFormulasResponse & { annotationId: number }>
  >;
  previewEnabled: boolean;
  currentFormulaId: string | undefined;
} | null>(null);

type FormulaPreviewProviderProps = {
  currentQueueId: number | undefined;
  children: React.ReactNode;
  namePrefix?: NamePrefixType;
  hasError: boolean;
};

export const FormulaPreviewContextProvider = ({
  currentQueueId,
  children,
  namePrefix = '',
  hasError,
}: FormulaPreviewProviderProps) => {
  const { getCurrentSchemaConcept, schemaObjectPath } =
    useCurrentSchemaConceptContext();

  const currentSchema = getCurrentSchemaConcept();

  const currentFormula = get(
    currentSchema,
    [schemaObjectPath, namePrefix].join('.').replace(/\.$/, '')
  );
  const currentFormulaId = currentFormula?.id;

  const annotationsQuery = useGetAnnotationsForPreview(
    currentFormulaId,
    currentQueueId
  );

  const annotationIds = useMemo(() => {
    return annotationsQuery.data?.pages.flatMap(page =>
      page.results.map(({ id }) => id)
    );
  }, [annotationsQuery.data]);

  const { evaluationQueries } = useCalculatePreview(
    annotationIds,
    currentFormulaId
  );

  const previewEnabled = !!(
    evaluationQueries.length !== 0 &&
    currentFormulaId &&
    currentFormula?.label &&
    currentFormula?.formula &&
    !hasError
  );

  useInitialPreview({ previewEnabled, evaluationQueries });

  return (
    <FormulaPreviewContext.Provider
      value={{
        annotationsQuery,
        evaluationQueries,
        previewEnabled,
        currentFormulaId,
      }}
    >
      {children}
    </FormulaPreviewContext.Provider>
  );
};

export const useFormulaPreviewContext = () => {
  const context = useContext(FormulaPreviewContext);

  if (context === null) {
    throw new Error(
      '`useFormulaPreviewContext` must be used within a FormulaPreviewContext provider'
    );
  }

  return context;
};
