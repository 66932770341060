import { SchemaItem } from '@rossum/api-client/schemas';
import { UseQueryResult } from '@tanstack/react-query';
import { Template } from './shared';

type UseSearchInAllParams = {
  filteredSchemaItems: Array<SchemaItem>;
  search: string;
  debouncedSearch: string;
  filteredTemplates: Array<Template>;
  auroraSuggestionsQuery: UseQueryResult<Template[]>;
};

export const useSearchInAll = ({
  filteredSchemaItems,
  filteredTemplates,
  search,
  debouncedSearch,
  auroraSuggestionsQuery,
}: UseSearchInAllParams) => {
  return [
    {
      type: 'auroraSuggestions',
      items:
        // use only first result for now
        auroraSuggestionsQuery.data && auroraSuggestionsQuery.data[0]
          ? [auroraSuggestionsQuery.data[0]]
          : [],
      loading: search !== debouncedSearch || auroraSuggestionsQuery.isFetching,
    },
    {
      type: 'fields',
      items: filteredSchemaItems.slice(0, search ? undefined : 2),
      loading: false,
    },
    {
      type: 'formulas',
      items: filteredTemplates.slice(0, search ? undefined : 2),
      loading: false,
    },
  ] as const;
};
