import { Url } from '../../types';
import { engineFieldSchema } from '../models/engineField.schema';
import { Multiline } from '../models/engineFieldType';
import { engineFieldCreatePayloadSchema } from './create.schema';

export type EngineFieldCreatePayload = {
  name: string;
  engine: Url;
  label: string;
  type: string;
  subtype?: string | null;
  uptrainFrom?: string | null;
  tabular?: boolean;
  multiline?: Multiline;
};

export const create = (payload: EngineFieldCreatePayload) => {
  return {
    endpoint: `/engine_fields`,
    method: 'POST',
    responseSchema: engineFieldSchema,
    payloadSchema: engineFieldCreatePayloadSchema,
    payload,
  } as const;
};
