import { Queue } from '@rossum/api-client/queues';
import FolderOutlineIcon from 'mdi-react/FolderOutlineIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { useState } from 'react';
import { connect } from 'react-redux';
import InfoPlaceholder from '../../../components/UI/InfoPlaceHolder';
import { useWorkspacesWithQueues } from '../../../features/queues/hooks/useWorkspacesWithQueues';
import { WorkspaceWithQueues } from '../../../features/queues/select-queue/types';
import {
  getRoleName,
  isUserAdminLike,
} from '../../../redux/modules/groups/helpers';
import {
  OpenModal,
  openModal as openModalAction,
} from '../../../redux/modules/modal/actions';
import {
  UpdateUserDetail,
  updateUserDetail as updateUserDetailAction,
} from '../../../redux/modules/users/actions';
import { filterWorkspaces } from '../../../redux/modules/workspaces/helpers';
import { Groups } from '../../../types/group';
import { State } from '../../../types/state';
import { User } from '../../../types/user';
import SearchInput from '../../../ui/search-input/SearchInput';
import Workspace from '../components/Workspace';
import styles from '../styles.module.sass';

type OwnProps = {
  selectedUser: User;
};

type StateProps = {
  groups: Groups;
};

type DispatchProps = {
  openModal: OpenModal;
  updateUserDetail: UpdateUserDetail;
};

type Props = DispatchProps & StateProps & OwnProps;

const filterActiveQueues = (queues: Queue[], userQueues: string[]) =>
  queues.filter(({ url }) => userQueues.includes(url));

const Access = ({
  groups,
  selectedUser,
  updateUserDetail,
  openModal,
}: Props) => {
  const { workspacesWithQueues: workspaces } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const [searchValue, setSearchValue] = useState('');

  if (!workspaces) {
    return null;
  }

  if (!workspaces.length || !selectedUser) {
    return (
      <InfoPlaceholder
        icon={<FolderOutlineIcon />}
        title="containers.users.access.noQueuesFound"
      />
    );
  }

  const role = getRoleName(selectedUser.groups, groups);
  const isAdmin = isUserAdminLike(role);

  if (isAdmin) {
    return (
      <InfoPlaceholder
        icon={<InformationOutlineIcon />}
        text="containers.users.access.admin.text"
        title="containers.users.access.admin.title"
      />
    );
  }
  const filteredWorkspaces = searchValue
    ? filterWorkspaces(searchValue, workspaces)
    : undefined;
  const _workspaces = filteredWorkspaces || workspaces;

  return (
    <div className={styles.AccessWrapper} data-page-title="user-access">
      <div className={styles.SearchWrapper}>
        <SearchInput
          value={searchValue}
          onChange={value => setSearchValue(value)}
        />
      </div>
      {filteredWorkspaces && !filteredWorkspaces.length ? (
        <InfoPlaceholder
          icon={<FolderOutlineIcon />}
          title="containers.users.access.noFilteredQueuesFound"
          text="containers.users.access.noFilteredQueuesFound.text"
        />
      ) : (
        _workspaces.map(
          (workspace: WorkspaceWithQueues, index: number) =>
            workspaces && (
              <Workspace
                isFirst={index === 0}
                activeQueuesCount={
                  filterActiveQueues(workspace.queues, selectedUser.queues)
                    .length
                }
                key={workspace.id}
                name={workspace.name}
                openModal={openModal}
                forceExpand={searchValue !== ''}
                queues={workspace.queues}
                updateUserDetail={(payload: Partial<User>) =>
                  updateUserDetail(selectedUser.id, payload)
                }
                userQueues={selectedUser.queues}
              />
            )
        )
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    groups: state.groups,
  };
};

const mapDispatchToProps = {
  openModal: openModalAction,
  updateUserDetail: updateUserDetailAction,
};

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(Access);
