import { z } from 'zod';
import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { ProcessingDuration } from '../models/processingDuration';
import { cancelEditPagesPayloadSchema } from './cancelEditPages.schema';

export type CancelEditPagesPayload = {
  annotations: Url[];
  cancelParent: boolean;
  processingDuration?: ProcessingDuration;
};

export const cancelEditPages = (
  parentAnnotationId: number,
  payload: CancelEditPagesPayload
) => {
  return {
    endpoint: `/annotations/${parentAnnotationId}/edit_pages/cancel`,
    method: 'POST',
    responseSchema: z.unknown(),
    payload,
    payloadSchema: cancelEditPagesPayloadSchema,
  } as const;
};
