import { useEffect, useRef } from 'react';

// https://medium.com/trabe/preventing-click-events-on-double-click-with-react-the-performant-way-1416ab03b835

// eslint-disable-next-line
type AnyFunction = (...args: any[]) => any;

const noop = () => {};

const requestTimeout = (
  fn: AnyFunction,
  delay: number,
  registerCancel: (fn: AnyFunction) => void
) => {
  const start = new Date().getTime();

  const loop = () => {
    const delta = new Date().getTime() - start;

    if (delta >= delay) {
      fn();
      registerCancel(noop);
      return;
    }

    const raf = requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };

  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

const useCancelableScheduledWork = () => {
  const cancelCallback = useRef<AnyFunction>(noop);

  const registerCancel = (fn: AnyFunction) => {
    cancelCallback.current = fn;
  };

  const cancelScheduledWork = () => cancelCallback.current();

  // Cancels the current sheduled work before the "unmount"
  useEffect(() => {
    return cancelScheduledWork;
  }, []);

  return [registerCancel, cancelScheduledWork] as const;
};

type UseDoubleClickPreventionOptions = {
  onClick: AnyFunction;
  onDoubleClick: AnyFunction;
  delay?: number;
};

export const useDoubleClickPrevention = ({
  onClick,
  onDoubleClick,
  delay = 300,
}: UseDoubleClickPreventionOptions) => {
  const [registerCancel, cancelScheduledRaf] = useCancelableScheduledWork();

  const handleClick: React.MouseEventHandler<Element> = e => {
    cancelScheduledRaf();
    requestTimeout(() => onClick(e), delay, registerCancel);
  };

  const handleDoubleClick: React.MouseEventHandler<Element> = e => {
    cancelScheduledRaf();
    onDoubleClick(e);
  };

  return [handleClick, handleDoubleClick];
};
