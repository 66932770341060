import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { get, isEmpty, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useController, useForm } from 'react-hook-form';
import { injectIntl, IntlShape } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import * as yup from 'yup';
import { useTriageSetup } from '../../../../business/deleteRecommendation/useTriageSetup';
import { QUERY_KEY_QUEUES_UNPAGINATED } from '../../../../business/queues/useUnpaginatedQueues';
import HiddingButton from '../../../../components/UI/HiddingButton';
import { EngineQueueSettings } from '../../../../features/engines/components/EngineQueueSettings';
import {
  documentArchiveFeatureSelector,
  hasSubscriptionPlanExistSelector,
} from '../../../../features/pricing/selectors';
import { useQueueSettingsContext } from '../../../../features/queue-settings/contexts/QueueSettingsContext';
import { WorkflowQueueSettings } from '../../../../features/workflows';
import { boldText } from '../../../../lib/formaterValues';
import { clearValidationsOnChangeCreator } from '../../../../lib/messages';
import {
  approvalWorkflowsEnabledSelector,
  auroraEngineConfigEnabledSelector,
} from '../../../../redux/modules/organizationGroup/selectors';
import { updateQueueDetail as updateQueueDetailAction } from '../../../../redux/modules/queues/actions';
import { isGenericEngine } from '../../../../redux/modules/queues/helpers';
import { queueErrorByIdSelector } from '../../../../redux/modules/queues/selector';
import { clearValidationMessagesAction } from '../../../../redux/modules/validationMessages/action';
import { InputMessages } from '../../../../redux/modules/validationMessages/types';
import {
  Queue,
  QueueSettings as QueueSettingsT,
} from '../../../../types/queue';
import { State } from '../../../../types/state';
import TriageConditionsDrawer from '../../../TriageConditionsDrawer/TriageConditionsDrawer';
import styles from '../../styles.module.sass';
import Advanced from './components/Advanced';
import ConfirmState from './components/ConfirmState';
import Document from './components/Document';
import DocumentArchive from './components/DocumentArchive';
import { QueueSettingsData, SessionTimeout } from './components/formType';
import General from './components/General';
import Notifications from './components/Notifications';

const sessionTimeoutInputs: SessionTimeout = [
  { name: 'sessionTimeoutHours', letter: 'h' },
  { name: 'sessionTimeoutMinutes', letter: 'm' },
  { name: 'sessionTimeoutSeconds', letter: 's' },
];

type OwnProps = {
  intl: IntlShape;
  selectedQueue: Queue;
};

type StateProps = {
  validationMessages: InputMessages;
};

type DispatchProps = {
  updateQueueDetail: (_queue: Partial<Queue>) => unknown;
  clearValidationMessages: typeof clearValidationMessagesAction;
};

type Props = DispatchProps & OwnProps & StateProps;

const queueSettingsValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    locale: yup.string(),
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.queues.messages.name.required',
      })
    ),
    sessionTimeoutHours: yup.string().matches(
      /^(\d{1,2})$/,
      intl.formatMessage({
        id: 'containers.settings.queues.messages.sessionTimeoutHours.errorThreshhold',
      })
    ),
    sessionTimeoutMinutes: yup.string().matches(
      /^(\d{1,2})$/,
      intl.formatMessage({
        id: 'containers.settings.queues.messages.sessionTimeoutMinutes.errorThreshhold',
      })
    ),
    sessionTimeoutSeconds: yup.string().matches(
      /^(\d{1,2})$/,
      intl.formatMessage({
        id: 'containers.settings.queues.messages.sessionTimeoutSeconds.errorThreshhold',
      })
    ),
    useConfirmedState: yup.boolean(),
    hideExportButton: yup.boolean(),
    suggestedEdit: yup.string(),
    deletedAnnotations: yup.boolean(),
    postponedAnnotations: yup.boolean(),
    unprocessableAttachments: yup.boolean(),
    archiveEnabled: yup.boolean(),
    emailTo: yup
      .string()
      .nullable()
      .email(
        intl.formatMessage({
          id: 'containers.settings.queues.messages.emailTo.email',
        })
      ),
    workflows: yup.object(),
    engineGroup: yup.object().shape({
      engine: yup.string().when('$engineEnabled', (value, schema) => {
        if (value) {
          return schema.required();
        }

        return schema.nullable();
      }),
      trainingEnabled: yup.boolean().optional(),
    }),
  });

const QueueSettings = ({
  intl,
  selectedQueue,
  updateQueueDetail,
  validationMessages,
  clearValidationMessages,
}: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { setHeaderMeta } = useQueueSettingsContext();

  useEffect(() => {
    setHeaderMeta(prevState => ({
      ...prevState,
      title: intl.formatMessage({
        id: `containers.settings.queues.queue.basic`,
      }),
      description: intl.formatMessage(
        {
          id: `containers.settings.queues.queue.basic.description`,
        },
        {
          email: selectedQueue?.inboxObject?.email,
          boldText,
        }
      ),
      buttons: [],
    }));
  }, [intl, selectedQueue?.inboxObject?.email, setHeaderMeta]);

  const queryClient = useQueryClient();
  const selectedQueueSettings: QueueSettingsT = get(
    selectedQueue,
    'settings',
    {}
  );

  const [sessionTimeoutH, sessionTimeoutM, sessionTimeoutS] =
    selectedQueue.sessionTimeout.split(':');

  const { isReady, setTriageEnabled, isTriageEnabled, triggerUrl } =
    useTriageSetup(selectedQueue.url);

  const defaultValues: QueueSettingsData = {
    locale: selectedQueue.locale,
    name: selectedQueue.name,
    sessionTimeoutHours: sessionTimeoutH,
    sessionTimeoutMinutes: sessionTimeoutM,
    sessionTimeoutSeconds: sessionTimeoutS,
    useConfirmedState: selectedQueue.useConfirmedState,
    hideExportButton: !!selectedQueueSettings.hideExportButton,
    suggestedEdit: selectedQueueSettings.suggestedEdit,
    deletedAnnotations:
      selectedQueueSettings.emailNotifications?.deletedAnnotations,
    postponedAnnotations:
      selectedQueueSettings.emailNotifications?.postponedAnnotations,
    unprocessableAttachments:
      selectedQueueSettings.emailNotifications?.unprocessableAttachments,
    emailTo: selectedQueueSettings.emailNotifications?.recipient?.email || '',
    triageStateEnabled: isTriageEnabled,
    workflowGroup: {
      enabled: selectedQueueSettings.workflows?.enabled ?? false,
      bypassWorkflowsAllowed:
        selectedQueueSettings.workflows?.bypassWorkflowsAllowed ?? false,
      workflows: selectedQueue.workflows ?? [],
    },
    engineGroup: {
      engine: selectedQueue.engine,
      trainingEnabled: selectedQueue.trainingEnabled,
    },
    archiveEnabled: selectedQueue.archiveEnabled,
  };

  const auroraEngineConfigEnabled = useSelector(
    auroraEngineConfigEnabledSelector
  );
  const queueEngine = selectedQueue.engine;
  const showEngineConfiguration = queueEngine && auroraEngineConfigEnabled;

  const {
    watch,
    handleSubmit,
    formState: { isValid },
    control,
    setFocus,
    setError,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(queueSettingsValidationSchema(intl)),
    context: {
      engineEnabled: showEngineConfiguration,
    },
  });

  const queueError = useSelector((state: State) =>
    queueErrorByIdSelector(state)(selectedQueue?.id)
  );

  const isEngineError = useMemo(() => {
    if (queueError) {
      return 'non_field_errors' in queueError.response;
    }
    return false;
  }, [queueError]);

  useEffect(() => {
    if (isEngineError) {
      setError('engineGroup.engine', {
        message: intl.formatMessage({
          id: 'features.engines.queueSettings.error',
        }),
      });
    }
  }, [intl, isEngineError, setError]);

  useEffect(() => {
    setValue('triageStateEnabled', isTriageEnabled);
  }, [isTriageEnabled, setValue]);

  const watchValues = watch();

  const suggestedEditEnabled = isGenericEngine(selectedQueue);

  const {
    field: { value: suggestedEditValue, onChange: suggestedEditOnChange },
  } = useController({
    name: 'suggestedEdit',
    control,
  });

  const clearValidationsOnChange = clearValidationsOnChangeCreator(
    clearValidationMessages,
    'settings',
    validationMessages
  );

  const canSubmit =
    !isEqual(watchValues, defaultValues) &&
    isValid &&
    isEmpty(validationMessages);

  const onSave = ({
    locale,
    name,
    sessionTimeoutHours,
    sessionTimeoutMinutes,
    sessionTimeoutSeconds,
    useConfirmedState,
    hideExportButton,
    suggestedEdit,
    deletedAnnotations,
    postponedAnnotations,
    unprocessableAttachments,
    emailTo,
    triageStateEnabled,
    workflowGroup,
    engineGroup,
    archiveEnabled,
  }: QueueSettingsData) => {
    if (
      triageStateEnabled !== undefined &&
      triageStateEnabled !== isTriageEnabled
    ) {
      setTriageEnabled(triageStateEnabled);
    }

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_QUEUES_UNPAGINATED],
    });

    return updateQueueDetail({
      locale,
      name,
      sessionTimeout: [
        sessionTimeoutHours,
        sessionTimeoutMinutes,
        sessionTimeoutSeconds,
      ].join(':'),
      useConfirmedState,
      archiveEnabled,
      settings: {
        ...selectedQueueSettings,
        hideExportButton,
        suggestedEdit,
        emailNotifications: {
          recipient: emailTo ? { email: emailTo } : null,
          deletedAnnotations,
          unprocessableAttachments,
          postponedAnnotations,
        },
        workflows: workflowGroup
          ? {
              enabled: workflowGroup.enabled,
              bypassWorkflowsAllowed: workflowGroup.bypassWorkflowsAllowed,
            }
          : undefined,
      },
      workflows: workflowGroup ? workflowGroup.workflows : undefined,
      engine: engineGroup.engine,
      trainingEnabled: engineGroup.trainingEnabled,
    });
  };

  const location = useLocation<{ openTriageRules: boolean }>();

  useEffect(() => {
    if (location.state?.openTriageRules) setDrawerOpen(true);
  }, [location.state]);

  const workflowsEnabled = useSelector(approvalWorkflowsEnabledSelector);

  const documentArchivePurchasedEnabled = useSelector(
    documentArchiveFeatureSelector
  );
  const hasSubscriptionPlan = useSelector(hasSubscriptionPlanExistSelector);

  return (
    <>
      <form onSubmit={handleSubmit(onSave)}>
        <Stack data-page-title="queue-settings" gap={4}>
          <General
            id={get(selectedQueue, 'id')}
            inbox={get(selectedQueue, 'inbox.email')}
            clearValidationsOnChange={clearValidationsOnChange}
            getErrorMessage={(_, { message }) => message ?? ''}
            control={control}
            sessionTimeoutInputs={sessionTimeoutInputs}
            setFocus={setFocus}
          />
          <Document
            value={suggestedEditValue}
            onChange={suggestedEditOnChange}
            suggestedEditEnabled={suggestedEditEnabled}
            openTriageSettings={() => setDrawerOpen(true)}
            control={control}
            isTriageReady={isReady}
          />
          {hasSubscriptionPlan ? (
            <DocumentArchive
              control={control}
              disabled={!documentArchivePurchasedEnabled}
            />
          ) : null}
          <Notifications
            control={control}
            intl={intl}
            clearValidationsOnChange={clearValidationsOnChange}
            getErrorMessage={(_, { message }) => message ?? ''}
          />
          <ConfirmState
            control={control}
            useConfirmedState={watchValues.useConfirmedState ?? false}
          />
          <Advanced inboxExists={!!get(selectedQueue, 'inbox.email')} />
          {workflowsEnabled && (
            <Controller
              control={control}
              name="workflowGroup"
              render={({ field }) =>
                field.value !== undefined ? (
                  <WorkflowQueueSettings
                    value={field.value}
                    onChange={field.onChange}
                  />
                ) : (
                  // TODO: This is probably an evil pattern overall, likely don't render entire Controller
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )
              }
            />
          )}
          {showEngineConfiguration ? (
            <Controller
              control={control}
              name="engineGroup"
              render={({ field, formState }) => (
                <EngineQueueSettings
                  {...field}
                  queueEngine={queueEngine}
                  error={formState.errors.engineGroup?.engine}
                />
              )}
            />
          ) : null}
          <div className={styles.ButtonWrapper}>
            <HiddingButton
              hideCondition={!canSubmit}
              messageId="containers.settings.queues.save"
              type="submit"
              dataCy="queue-settings-save-changes-btn"
            />
          </div>
        </Stack>
      </form>
      <TriageConditionsDrawer
        queueUrl={selectedQueue.url}
        triggerUrl={triggerUrl}
        DrawerProps={{
          open: drawerOpen,
          onClose: () => setDrawerOpen(false),
        }}
      />
    </>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  validationMessages: get(state.validationMessages, ['settings']),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { selectedQueue }: OwnProps
): DispatchProps => ({
  updateQueueDetail: (payload: Partial<Queue>) =>
    dispatch(updateQueueDetailAction(get(selectedQueue, 'id'), payload)),
  clearValidationMessages: clearValidationMessagesAction,
});
const QueueSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QueueSettings);

export default injectIntl<'intl', OwnProps>(QueueSettingsContainer);
