import { endpoints, Url } from '@rossum/api-client';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { AsyncTask } from '@rossum/api-client/tasks';
import { DocumentsDownloadTaskResult } from '@rossum/api-client/tasks';
import { documentsDownloadTaskResultSchema } from '@rossum/api-client/tasks';
import { uploadSchema } from '@rossum/api-client/uploads';
import {
  useQueries,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { createContext, useCallback, useContext, useState } from 'react';
import { QUERY_KEY_QUEUES_UNPAGINATED } from '../../business/queues/useUnpaginatedQueues';
import { api } from '../../lib/apiClient';
import { ALL_DOCUMENTS_QUERY_KEY } from '../document-list/hooks/useFetchDashboardData';
import { QUERY_KEY_STATUS_COUNTS } from '../document-list/statuses/hooks/useStatusTabCounts';
import { handleDownloadOriginalFilesResult } from './asyncTaskResultHandlers';

type TaskMetadata = {
  fileName: string;
  queueName: string;
  queueUrl: Url;
  detail?: string;
};

type AsynchronousTask = { taskId: number; metadata?: TaskMetadata };
type UploadFailedTask = { taskId: string; metadata?: TaskMetadata };

export type AsyncTasks = UseQueryResult<
  AsyncTask & { metadata?: TaskMetadata }
>[];

export type UploadTasks = {
  taskId: string;
  dataUpdatedAt: string;
  data: {
    type: 'uploads';
    status: 'uploads_failed';
    detail?: string;
    fileName?: TaskMetadata['fileName'];
    queueName?: TaskMetadata['queueName'];
  };
}[];

export type ImportTask = {
  annotationUrl: string;
  annotationStatus?: AnnotationStatus;
  queueUrl?: string;
  queueName?: string;
  fileName?: string;
};

type TaskContextValue = {
  tasks: {
    asyncTasks: AsyncTasks[number][];
    uploadsTasks: UploadTasks[number][];
    importTasks: ImportTask[];
  };
  registerAsyncTask: (task: AsynchronousTask) => void;
  unregisterAsyncTask: (taskId: number) => void;
  registerUploadTask: (task: UploadFailedTask) => void;
  unregisterUploadTask: (taskId: string) => void;
  clearAllTasks: () => void;
};

const TaskContext = createContext<TaskContextValue | undefined>(undefined);

export const TaskContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [asyncTasks, setAsyncTasks] = useState<AsynchronousTask[]>([]);
  const [uploadTasks, setUploadTasks] = useState<UploadFailedTask[]>([]);
  const [importTasks, setImportTasks] = useState<ImportTask[]>([]);

  const queryClient = useQueryClient();

  const asyncTasksResults = useQueries<
    UseQueryOptions<AsyncTask & { metadata?: TaskMetadata }>[]
  >({
    queries: asyncTasks.map(({ taskId, metadata }) => ({
      queryKey: ['ASYNC_TASK', taskId] as const,
      queryFn: () =>
        api
          .request(endpoints.tasks.get(taskId, { noRedirect: true }))
          .then(response => ({ ...response, metadata })),
      // refetch task info while status is running
      refetchInterval: (data, query) => {
        if (query.state.status === 'error' || data?.status !== 'running') {
          return 0;
        }

        return 1000 * 1;
      },
      onSuccess: data => {
        if (data.status === 'succeeded' && data.resultUrl) {
          // process the result of the task
          // call API with data.resultUrl, pass result to taskHandler
          if (data.type === 'documents_download') {
            api
              .request<DocumentsDownloadTaskResult, never, never>({
                endpoint: data.resultUrl,
                method: 'GET',
                responseSchema: documentsDownloadTaskResultSchema,
              })
              .then(resultResponse =>
                handleDownloadOriginalFilesResult(resultResponse, {})
              );
          } else if (data.type === 'upload_created') {
            api
              .request({
                endpoint: data.resultUrl,
                method: 'GET',
                responseSchema: uploadSchema,
              })
              .then(({ annotations }) => {
                setImportTasks(prev => [
                  ...prev,
                  ...annotations.map(url => ({
                    annotationUrl: url,
                    queueName: data.metadata?.queueName ?? undefined,
                    queueUrl: data.metadata?.queueUrl ?? undefined,
                    fileName: data.metadata?.fileName ?? undefined,
                  })),
                ]);
                unregisterAsyncTask(data.id);

                queryClient.invalidateQueries({
                  queryKey: [ALL_DOCUMENTS_QUERY_KEY],
                });
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY_STATUS_COUNTS],
                });
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY_QUEUES_UNPAGINATED],
                });
              });
          }
        }
      },
    })),
  });

  const uploadTasksResults = uploadTasks.map<UploadTasks[number]>(
    uploadTask => {
      const timestamp = Date.now();
      return {
        taskId: uploadTask.taskId,
        dataUpdatedAt: `${timestamp}`,
        data: {
          detail: uploadTask.metadata?.detail,
          type: 'uploads',
          status: 'uploads_failed',
          fileName: uploadTask.metadata?.fileName,
          queueName: uploadTask.metadata?.queueName,
        },
      };
    }
  );

  const registerAsyncTask = useCallback(
    ({ taskId, metadata }: AsynchronousTask) => {
      setAsyncTasks(tasks => [...tasks, { taskId, metadata }]);
    },
    []
  );

  const unregisterAsyncTask = useCallback((taskId: number) => {
    setAsyncTasks(tasks => tasks.filter(({ taskId: id }) => id !== taskId));
  }, []);

  const registerUploadTask = useCallback(
    ({ taskId, metadata }: UploadFailedTask) => {
      setUploadTasks(tasks => [...tasks, { taskId, metadata }]);
    },
    []
  );

  const unregisterUploadTask = useCallback((taskId: string) => {
    setUploadTasks(tasks => tasks.filter(({ taskId: id }) => id !== taskId));
  }, []);

  const clearAllTasks = useCallback(() => {
    setAsyncTasks([]);
    setUploadTasks([]);
    setImportTasks([]);
    queryClient.removeQueries({
      queryKey: ['ASYNC_TASK'],
    });
    queryClient.removeQueries({
      queryKey: ['UPLOADS_TASK'],
    });
  }, [queryClient]);

  return (
    <TaskContext.Provider
      value={{
        tasks: {
          asyncTasks: asyncTasksResults,
          uploadsTasks: uploadTasksResults,
          importTasks,
        },
        registerAsyncTask,
        unregisterAsyncTask,
        registerUploadTask,
        unregisterUploadTask,
        clearAllTasks,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => {
  const ctx = useContext(TaskContext);

  if (ctx === undefined) {
    throw new Error(
      '`useTaskContext` must be used within a TaskContext provider'
    );
  }

  return ctx;
};
