import { AddCircleOutlineRounded } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import SearchInput from '../../../ui/search-input/SearchInput';
import { FilterComponentSelector } from './components/FilterComponentSelector';
import { FILTER_ITEMS_MENU_WIDTH } from './constants';
import { isFilterSimple } from './helpers';
import { FilterOperator } from './operators';
import { FilterComponentProps, FilterItem, TypedGridColDef } from './types';

export const VERTICAL_SPACING = 0.75;

type Props = {
  columns: TypedGridColDef[];
  onConfirm: (filterItem: FilterItem) => void;
  isLoading?: boolean;
};

export const AddFilter = ({ columns, onConfirm, isLoading }: Props) => {
  const [filterCandidate, setFilterCandidate] = useState<FilterItem | null>(
    null
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const intl = useIntl();

  const onClose = () => {
    setSearchValue('');
    setAnchorEl(null);
  };

  const filteredColumns = columns?.filter(
    c =>
      !searchValue ||
      c.headerName?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const convertToAdvancedFilter: FilterComponentProps['convertToAdvancedFilter'] =
    (operator, existingInput) => {
      if (filterCandidate && isFilterSimple(filterCandidate)) {
        const newFilterContext = [
          {
            operator,
            value: existingInput ?? filterCandidate?.filterContext.value,
          },
        ];

        setFilterCandidate({
          ...filterCandidate,
          filterContext: newFilterContext,
        });
      }
    };

  const handleOnOperatorChange = useCallback(
    (operator: FilterOperator, value?: unknown) =>
      setFilterCandidate(prev =>
        prev ? { ...prev, filterContext: { operator, value } } : prev
      ),
    []
  );

  return (
    <>
      <Popover
        open={!!anchorEl}
        onClose={onClose}
        TransitionProps={{
          onExited: () => setFilterCandidate(null),
        }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            elevation: 24,
            sx: {
              width: filterCandidate ? 'auto' : FILTER_ITEMS_MENU_WIDTH,
            },
          },
        }}
      >
        <Stack sx={{ overflow: 'hidden' }}>
          {filterCandidate ? (
            <FilterComponentSelector
              applyFilter={filterContext => {
                onConfirm({ column: filterCandidate.column, filterContext });
                onClose();
              }}
              filterItem={filterCandidate}
              onBackClick={() => setFilterCandidate(null)}
              onClose={onClose}
              onOperatorChange={handleOnOperatorChange}
              convertToAdvancedFilter={convertToAdvancedFilter}
            />
          ) : (
            <Stack sx={{ p: 2 }}>
              <SearchInput
                onChange={setSearchValue}
                value={searchValue}
                withStartAdornment={false}
                placeholder={intl.formatMessage({
                  id: 'containers.filtering.placeholder.filterBy',
                })}
                sx={{
                  mb: 1,
                  width: '100%',
                }}
                InputProps={{
                  sx: {
                    fontSize: ({ typography }) => typography.body2.fontSize,
                  },
                }}
              />
              <Stack sx={{ maxHeight: 500, overflowY: 'auto' }}>
                {filteredColumns.map(column => {
                  const defaultOperator = column.operators?.[0];
                  const valuesAreLoading = !!column.areValuesLoading;

                  return column.headerName ? (
                    <MenuItem
                      key={column.field}
                      disabled={valuesAreLoading}
                      onClick={() => {
                        if (defaultOperator)
                          setFilterCandidate({
                            column,
                            filterContext: { operator: defaultOperator },
                          });
                      }}
                      sx={{ px: 2, py: 0.75 }}
                      data-cy={`all-documents-add-filter-${column.field}`}
                    >
                      <Stack flexDirection="row" gap={0.5} alignItems="center">
                        {valuesAreLoading && <CircularProgress size={16} />}
                        <Typography variant="body2">
                          {column.headerName}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ) : null;
                })}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Popover>
      <Button
        variant="text"
        startIcon={<AddCircleOutlineRounded />}
        endIcon={isLoading ? <CircularProgress size={16} /> : null}
        color="secondary"
        sx={{ textWrap: 'nowrap', my: -VERTICAL_SPACING }}
        onClick={e => setAnchorEl(e.currentTarget)}
        data-cy="all-documents-add-filter-button"
        disabled={isLoading || columns.length === 0}
      >
        {intl.formatMessage({ id: 'containers.filtering.buttons.add' })}
      </Button>
    </>
  );
};
