/* eslint-disable no-unreachable */

import { Url } from '@rossum/api-client';
import { DedicatedEngineSchemaPatchPayload } from '@rossum/api-client/dedicatedEngineSchema';
import {
  DatapointFieldPatchModel,
  DatapointFieldType,
} from '@rossum/api-client/dedicatedEngineSchema';
import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import {
  DedicatedEngineSchemaObjectKind,
  isDatapointField,
  isSimpleMultivalueField,
} from '@rossum/api-client/dedicatedEngineSchema';
import { SimpleMultivalueFieldPatchModel } from '@rossum/api-client/dedicatedEngineSchema';
import { TableMultivalueFieldPatchModel } from '@rossum/api-client/dedicatedEngineSchema';
import {
  DatapointField,
  SimpleMultivalueField,
  TableMultivalueField,
} from '@rossum/api-client/dedicatedEngineSchema';

// types inferred from yup do some weird voodoo stuff sometimes 0_o
// export type HeaderFieldFormModel = yup.TypeOf<typeof HeaderFieldFormSchema>;
// would be really nice to only use one schema validator for cases like Url typing
export type HeaderFieldFormModel = {
  __kind: 'headerField';
  label: string;
  engineOutputId: string;
  type: DatapointFieldType | null;
  description: string;
  sources: { queue: string; schemaId: string | null }[];
  isMultivalue: boolean;
};

export type LineItemFormModel = {
  __kind: 'lineItem';
  label: string;
  engineOutputId: string;
  description: string;
  freeForm: boolean;
  columns: HeaderFieldFormModel[];
};

type EngineSchemaFieldFormModel = HeaderFieldFormModel | LineItemFormModel;

const isHeaderFieldFormModel = (
  model: EngineSchemaFieldFormModel
): model is HeaderFieldFormModel => model.__kind === 'headerField';

export const headerFieldFormDefaultValues = (
  engineSchema: DedicatedEngineSchema
): HeaderFieldFormModel => ({
  __kind: 'headerField',
  label: '',
  engineOutputId: '',
  type: null,
  description: '',
  sources: engineSchema.content.trainingQueues.map(queue => ({
    queue,
    schemaId: '',
  })),
  isMultivalue: false,
});

export const lineItemFormDefaultValues = (): LineItemFormModel => ({
  __kind: 'lineItem',
  label: '',
  engineOutputId: '',
  description: '',
  freeForm: false,
  columns: [],
});

export function viewToFormModel(
  fieldModel: DatapointField | SimpleMultivalueField
): HeaderFieldFormModel;
export function viewToFormModel(
  fieldModel: TableMultivalueField
): LineItemFormModel;
export function viewToFormModel(
  fieldModel: DatapointField | TableMultivalueField | SimpleMultivalueField
): EngineSchemaFieldFormModel {
  if (isDatapointField(fieldModel)) {
    return {
      __kind: 'headerField' as const,
      label: fieldModel.label,
      engineOutputId: fieldModel.engineOutputId,
      description: fieldModel.description,
      type: fieldModel.type,
      sources: fieldModel.sources,
      isMultivalue: false,
    };
  }

  if (isSimpleMultivalueField(fieldModel)) {
    return {
      __kind: 'headerField' as const,
      label: fieldModel.children.label,
      engineOutputId: fieldModel.children.engineOutputId,
      description: fieldModel.children.description,
      type: fieldModel.children.type,
      sources: fieldModel.children.sources,
      isMultivalue: true,
    };
  }

  return {
    __kind: 'lineItem' as const,
    label: fieldModel.label,
    engineOutputId: fieldModel.engineOutputId,
    description: fieldModel.description,
    freeForm: fieldModel.type === 'freeform',
    columns: fieldModel.children.children.map(field => viewToFormModel(field)),
  };
}

const formToPayloadModel = (
  formModel: EngineSchemaFieldFormModel
): DedicatedEngineSchemaPatchPayload['content']['fields'][number] => {
  if (isHeaderFieldFormModel(formModel)) {
    if (!formModel.isMultivalue) {
      return {
        __kind: DedicatedEngineSchemaObjectKind.Datapoint,
        category: 'datapoint',
        label: formModel.label,
        engineOutputId: formModel.engineOutputId,
        description: formModel.description,
        type: formModel.type,
        sources: formModel.sources.map(source => ({
          queue: source.queue as Url,
          schemaId: source.schemaId,
        })),
      } as DatapointFieldPatchModel;
    }

    return {
      __kind: DedicatedEngineSchemaObjectKind.SimpleMultivalue,
      category: 'multivalue',
      children: {
        __kind: DedicatedEngineSchemaObjectKind.Datapoint,
        category: 'datapoint',
        label: formModel.label,
        engineOutputId: formModel.engineOutputId,
        description: formModel.description,
        type: formModel.type,
        sources: formModel.sources.map(source => ({
          queue: source.queue as Url,
          schemaId: source.schemaId,
        })),
      },
    } as SimpleMultivalueFieldPatchModel;
  }

  return {
    __kind: DedicatedEngineSchemaObjectKind.TableMultivalue,
    category: 'multivalue',
    label: formModel.label,
    engineOutputId: formModel.engineOutputId,
    description: formModel.description,
    type: formModel.freeForm ? 'freeform' : 'grid',
    children: {
      category: 'tuple',
      children: formModel.columns.map(formToPayloadModel),
    },
  } as TableMultivalueFieldPatchModel;
};

export const viewToPayloadModel = (
  field: DedicatedEngineSchema['content']['fields'][number]
): DedicatedEngineSchemaPatchPayload['content']['fields'][number] => {
  if (isDatapointField(field)) {
    return {
      __kind: field.__kind,
      category: 'datapoint',
      label: field.label,
      engineOutputId: field.engineOutputId,
      description: field.description,
      type: field.type,
      sources: field.sources.map(source => ({
        queue: source.queue,
        schemaId: source.schemaId,
      })),
    } as DatapointFieldPatchModel;
  }

  if (isSimpleMultivalueField(field)) {
    return {
      __kind: field.__kind,
      category: 'multivalue',
      children: viewToPayloadModel(field.children),
    } as SimpleMultivalueFieldPatchModel;
  }

  return {
    __kind: field.__kind,
    category: 'multivalue',
    label: field.label,
    engineOutputId: field.engineOutputId,
    description: field.description,
    type: field.type,
    children: {
      category: 'tuple',
      children: field.children.children.map(viewToPayloadModel),
    },
  } as TableMultivalueFieldPatchModel;
};

export const addFieldPayload = (
  engineSchema: DedicatedEngineSchema,
  formModel: EngineSchemaFieldFormModel
): DedicatedEngineSchemaPatchPayload => {
  return {
    content: {
      ...engineSchema.content,
      fields: [...engineSchema.content.fields, formToPayloadModel(formModel)],
    },
  };
};

export const editFieldPayload = (
  engineSchema: DedicatedEngineSchema,
  formModel: EngineSchemaFieldFormModel,
  index: number
): DedicatedEngineSchemaPatchPayload => {
  return {
    content: {
      ...engineSchema.content,
      fields: engineSchema.content.fields.map((field, i) =>
        i === index ? formToPayloadModel(formModel) : viewToPayloadModel(field)
      ),
    },
  };
};

export const deleteFieldPayload = (
  engineSchema: DedicatedEngineSchema,
  index: number
): DedicatedEngineSchemaPatchPayload => {
  return {
    content: {
      ...engineSchema.content,
      fields: engineSchema.content.fields
        .filter((_, i) => index !== i)
        .map(viewToPayloadModel),
    },
  };
};
