/* eslint-disable */

// HTMLCanvas toBlob Polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#Polyfill
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value(callback, type, quality) {
      let dataURL = this.toDataURL(type, quality).split(',')[1];
      setTimeout(() => {
        var binStr = atob(dataURL),
          len = binStr.length,
          arr = new Uint8Array(len);

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(new Blob([arr], { type: type || 'image/png' }));
      });
    },
  });
}

// TODO: Try removing after bumping react-query
/**
 * Object.entriesFrom() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Object.fromEntries) {
  Object.fromEntries = function (entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error(
        'Object.fromEntries() requires a single iterable argument'
      );
    }
    let obj = {};
    for (let [key, value] of entries) {
      obj[key] = value;
    }
    return obj;
  };
}

/**
 * Array.prototype.findLastIndex() polyfill
 * https://jsitor.com/osoTOcSoig
 */
if (!Array.prototype.findLastIndex) {
  Array.prototype.findLastIndex = function (callback, thisArg) {
    for (let i = this.length - 1; i >= 0; i--) {
      if (callback.call(thisArg, this[i], i, this)) return i;
    }
    return -1;
  };
}
