import { UniqueIdentifier } from '@dnd-kit/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { Schema, SchemaSection } from '@rossum/api-client/schemas';
import { Alert, Button, Collapse, Stack, useTheme } from '@rossum/ui/material';
import { useCallback, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Prompt, useHistory } from 'react-router';
import * as R from 'remeda';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { linebreak, link } from '../../../../lib/formaterValues';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';
import {
  DELETE_FIELD_FORM_ID,
  FIELD_FORM_ID,
  getLinkStyles,
  IDENTIFICATION_SECTION_LINk,
} from '../constants';
import { fieldsFormErrorMap } from '../form-model/errors/formErrorMap';
import {
  FieldsFormModel,
  fieldsFormSchema,
  FieldsFormValues,
} from '../form-model/formModels';
import { toFormValues } from '../form-model/transformations';
import { useConfirmationDialog } from '../hooks/useConfirmationDialog';
import { useSchemaConcept } from '../hooks/useSchemaContept';
import { useValidateSchema } from '../hooks/useValidateSchema';
import { SectionsReordering } from './SectionsReordering';

// Redefining API as this is supposed to be `SectionDetailForm` or something like that
// so it can submit, delete or error out
export type SectionContentProps = {
  data: SchemaSection | null;
  schema: Schema;
  sections: SchemaSection[];
  onSubmit: (formModel: FieldsFormModel) => void;
  onDelete: (sectionId: string) => void;
  onSectionsReorder: (
    from: readonly [UniqueIdentifier, number],
    to: readonly [UniqueIdentifier, number]
  ) => void;
  // TODO: Add `onError`
};

export const SectionContent = ({
  schema,
  data,
  sections,
  onSubmit,
  onDelete,
  onSectionsReorder,
}: SectionContentProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();

  const { dialog, setDialogState, dialogState } = useConfirmationDialog();

  const { mutateAsync: validateSchema } = useValidateSchema();

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isDirty },
  } = useForm<FieldsFormValues, undefined, FieldsFormModel>({
    defaultValues: toFormValues(
      // TODO: This should be prettier
      data || ({ category: 'section' } as SchemaSection)
    ),
    resolver: zodResolver(fieldsFormSchema(intl), {
      errorMap: fieldsFormErrorMap(intl),
    }),
  });

  const formValues = watch();

  const { handleSubmit: handleDeleteFormSubmit } = useForm({
    defaultValues: {
      id: data?.id ?? null,
    },
  });

  const schemaConcept = useSchemaConcept(schema, null, data, formValues);

  // resets on remounts and this component is being re-mounted every time `data` changes
  // not just rerendered (`key` from parent)
  const skipPrompt = useRef(false);

  const deleteHandler: SubmitHandler<{ id: string | null }> = useCallback(
    ({ id }) => {
      if (id) {
        skipPrompt.current = true;
        onDelete(id);
      }
    },
    [onDelete]
  );

  const submitHandler: SubmitHandler<FieldsFormModel> = useCallback(
    formModel => {
      // TODO: Proper validation with errors on correct fields, this is just a quick fix
      validateSchema(schemaConcept)
        .then(response => {
          if (!R.isDeepEqual(response, {})) {
            setError('root', {
              message: JSON.stringify(response, undefined, 2),
            });
            skipPrompt.current = false;
          } else {
            skipPrompt.current = true;
            onSubmit(formModel);
          }
        })
        .catch(() => {
          skipPrompt.current = false;
        });
    },
    [onSubmit, schemaConcept, setError, validateSchema]
  );

  const [errorExpanded, setErrorExpanded] = useState(false);

  return (
    <>
      <Stack
        p={4}
        spacing={4}
        id={FIELD_FORM_ID}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
      >
        {errors.root ? (
          <Alert
            variant="filled"
            severity="error"
            action={
              <Button
                color="inherit"
                variant="text"
                onClick={() => setErrorExpanded(expanded => !expanded)}
                sx={{ flexShrink: 0 }}
              >
                {intl.formatMessage({
                  id: 'features.queueSettings.fields.form.errors.encountered.more',
                })}
              </Button>
            }
          >
            {intl.formatMessage({
              id: 'features.queueSettings.fields.form.errors.encountered',
            })}
            <Collapse in={errorExpanded}>
              <pre>{errors.root.message}</pre>
            </Collapse>
          </Alert>
        ) : null}
        <ContentGroup
          title={intl.formatMessage({
            id: 'features.queueSettings.fields.form.identification.title',
          })}
          description={intl.formatMessage(
            {
              id: 'features.queueSettings.fields.form.identification.description',
            },
            {
              linebreak,
              link: link(IDENTIFICATION_SECTION_LINk, getLinkStyles(theme)),
            }
          )}
        >
          <TextFieldControl
            autoFocus
            ControllerProps={{ control, name: 'field.label' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.label.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
          <TextFieldControl
            ControllerProps={{ control, name: 'field.id' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.id.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
          <TextFieldControl
            ControllerProps={{ control, name: 'field.description' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.description.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
        </ContentGroup>
      </Stack>
      {sections.length > 1 ? (
        <SectionsReordering
          sections={sections}
          onSectionsReorder={onSectionsReorder}
        />
      ) : null}
      <Stack
        display="none"
        component="form"
        id={DELETE_FIELD_FORM_ID}
        onSubmit={e => {
          e.preventDefault();
          setDialogState({
            key: 'deleteSchemaSection',
            onConfirm: handleDeleteFormSubmit(deleteHandler),
          });
        }}
      />
      <Prompt
        message={location => {
          if (dialogState) {
            setDialogState(null);
            return true;
          }

          setDialogState({
            key: 'notSavedChanges',
            onConfirm: () => history.replace(location),
          });

          return false;
        }}
        when={!skipPrompt.current && isDirty}
      />
      {dialog}
    </>
  );
};
