import { UniqueIdentifier } from '@dnd-kit/core';
import {
  isSchemaItem,
  isSchemaSimpleMultiValue,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';
import { NavigateNextRounded } from '@rossum/ui/icons';
import {
  alpha,
  Box,
  capitalize,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { resolveIconType } from '../../../formulas/FormulaPreview/hooks/utils';
import { fieldsIconsMap } from '../helpers';
import { getUpdatedPathname } from '../utils';
import { QuickActionField, QuickActionIcon } from './form/QuickActionIcon';

export const TILE_HEIGHT = 56;

type SchemaFieldQuickAction = {
  key: QuickActionField;
  value: boolean;
  disabled: boolean;
  onClick: () => void;
};

type SchemaFieldTileContentProps = {
  isActive: boolean;
  isDragging: boolean;
  isFaded?: boolean;
} & {
  field: SchemaItem | SchemaSection;
  // TODO: Me no likey this
  parentId?: UniqueIdentifier;
  quickActions: SchemaFieldQuickAction[];
};

export const SchemaFieldTileContent = ({
  field,
  isActive,
  isFaded,
  parentId,
  quickActions,
}: SchemaFieldTileContentProps) => {
  const history = useHistory();
  const intl = useIntl();

  const type = resolveIconType(field);
  const Icon = type ? fieldsIconsMap[type] : null;

  const getActionTooltip = (
    intl: IntlShape,
    actionKey: QuickActionField,
    value: boolean
  ) => {
    const action = intl
      .formatMessage({
        id: `features.queueSettings.fields.quickActions.${actionKey}`,
      })
      .toLowerCase();

    return intl.formatMessage(
      {
        id: `features.queueSettings.fields.quickActions.quick`,
      },
      {
        action,
        newValue: intl
          .formatMessage({
            id: `features.queueSettings.fields.quickActions.${
              value ? 'off' : 'on'
            }`,
          })
          .toLowerCase(),
      }
    );
  };

  const pathToDetail = isSchemaItem(field)
    ? parentId
      ? // for simple multivalues, we are using the child's id to identify it
        `${history.location.pathname}/${parentId}/${isSchemaSimpleMultiValue(field) ? field.children.id : field.id}`
      : `${history.location.pathname}/${field.id}`
    : getUpdatedPathname({
        pathname: history.location.pathname,
        segmentsToDrop: 1,
        newSegmentToAdd: field.id,
      });

  const caption = useMemo(
    () =>
      isSchemaItem(field) ? (
        isSchemaSimpleMultiValue(field) ? (
          field.children.id
        ) : (
          field.id
        )
      ) : (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          divider={
            <Box
              sx={{
                width: 4,
                height: 4,
                borderRadius: '50%',
                backgroundColor: t => t.palette.divider,
              }}
            />
          }
        >
          {field.children.slice(0, 5).map(({ label, id }, index) => (
            <Typography variant="caption" key={id}>
              {`${label}${
                index === 4 && field.children.length > 5 ? '...' : ''
              }`}
            </Typography>
          ))}
        </Stack>
      ),
    [field]
  );

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        height: TILE_HEIGHT,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        component={Link}
        to={pathToDetail}
        tabIndex={-1}
        sx={{
          color: t => t.palette.text[isFaded ? 'secondary' : 'primary'],
          height: '100%',
          flexGrow: 1,
          cursor: 'pointer',
          alignItems: 'center',
          '&:hover': {
            color: theme => theme.palette.text.primary,
            textDecoration: 'none',
          },
        }}
      >
        {Icon ? (
          <Tooltip title={`${capitalize(type ?? '')} field type`}>
            <Stack
              width={36}
              height={36}
              justifyContent="center"
              alignItems="center"
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: t => t.palette.divider,
                borderRadius: '50%',
              }}
            >
              <Icon fontSize="small" />
            </Stack>
          </Tooltip>
        ) : null}
        <Stack flex={1}>
          <Typography variant="h6">{field.label}</Typography>
          <Typography variant="caption" color="text.secondary">
            {caption}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        {quickActions.map(({ key, value, disabled, onClick }) => (
          <Tooltip
            key={key}
            title={disabled ? '' : getActionTooltip(intl, key, value)}
          >
            <span>
              {/* TODO: Can this override cursor from the tile? :thinking: */}
              <IconButton
                onClickCapture={e => {
                  e.stopPropagation();
                  onClick();
                }}
                disabled={disabled}
              >
                <QuickActionIcon
                  fieldKey={key}
                  state={value}
                  disabled={disabled}
                />
              </IconButton>
            </span>
          </Tooltip>
        ))}
        <IconButton
          key="navigate"
          component={Link}
          to={pathToDetail}
          sx={{
            opacity: isActive ? 1 : 0,
            transition: t =>
              t.transitions.create('opacity', {
                duration: t.transitions.duration.short,
              }),
            color: theme => theme.palette.text.primary,
            '&:hover': {
              color: theme => theme.palette.text.primary,
              textDecoration: 'none',
            },
          }}
        >
          <NavigateNextRounded
            fontSize="small"
            sx={{ color: t => alpha(t.palette.action.active, 0.56) }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};
