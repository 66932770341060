import { endpoints, getIDFromUrl, Url, withSideload } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { useTransformAnnotations } from '../../annotation-simple-columns/hooks/useTransformAnnotation';
import { allowedStatuses } from '../../document-list/filtering/constants';

export const DUPLICATES_QUERY_KEY = 'duplicates';

export const useDocumentDuplicates = (parentAnnotationUrl: Url) => {
  const api = useApiClient();
  const {
    data,
    isLoading: isFetchingRelation,
    isError,
  } = useQuery({
    queryKey: [DUPLICATES_QUERY_KEY, parentAnnotationUrl] as const,
    queryFn: async () => {
      const duplicates = await api.unpaginatedRequest(endpoints.relations.list)(
        {
          type: 'duplicate',
          pageSize: 50,
          annotation: [getIDFromUrl(parentAnnotationUrl)],
        }
      );
      const annotationIds = duplicates.flatMap(duplicate =>
        duplicate.annotations.map(getIDFromUrl)
      );

      if (annotationIds.length === 0) {
        return undefined;
      }

      return api.request(
        withSideload(
          endpoints.annotations.list({
            id: annotationIds,
            pageSize: annotationIds.length,
            status: allowedStatuses,
          }),
          {
            documents: true,
            modifiers: true,
            relations: true,
            childRelations: true,
          }
        )
      );
    },
  });

  const { transformedAnnotations, isLoading: isTransforming } =
    useTransformAnnotations({
      annotations: data?.results,
      documents: data?.documents,
      modifiers: data?.modifiers,
      relations: data?.relations,
      childRelations: data?.childRelations,
    });

  const isLoading = isFetchingRelation || isTransforming;

  return {
    duplicates: transformedAnnotations ?? [],
    isLoading,
    isError,
  };
};
