import { GridFilterItem, GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { compact } from 'lodash';
import { z } from 'zod';
import { dataGridOperatorsMap, disallowedStatuses } from './constants';
import {
  isEmptyValue,
  isKeyofDataGridOperatorsMap,
  isStartsWithTooShort,
} from './helpers';
import { columnEncoderMap, transformValue } from './transformers';
import { ApiConditionModel, MQLOperators } from './types';

// filtering model is validated in the component level, this schema is not used in the request in case of invalid shape
const filteringItemSchema = z.object({
  id: z.union([z.string(), z.number()]).optional(),
  field: z.string(),
  value: z.any().nullable().optional(),
  operator: z.string(),
});

export const filteringSchema = z.object({
  items: z.array(filteringItemSchema),
  logicOperator: z.union([z.literal('and'), z.literal('or')]).optional(),
});

export type DataGridConditionModel = GridFilterModel;

export const encodeMqlQuery = (
  dataGridCondition: DataGridConditionModel
): ApiConditionModel => {
  const { items } = dataGridCondition;

  const conditions = items.flatMap(item => {
    const { field, operator, value } = item;

    if (
      !field ||
      !operator ||
      isEmptyValue(value) ||
      isStartsWithTooShort(value.length, operator)
    ) {
      return null;
    }

    const mqlOperator = isKeyofDataGridOperatorsMap(operator)
      ? dataGridOperatorsMap[operator]
      : null;

    if (!mqlOperator) return null;

    const columnEncoder = columnEncoderMap[field];

    return columnEncoder
      ? columnEncoder({ mqlOperator, value })
      : {
          [field]: {
            [mqlOperator]: transformValue(value, field),
          },
        };
  });

  const filteredConditions = compact(conditions);

  return {
    // we do not support dynamic logic operator eg. `$${dataGridCondition?.logicOperator}`, only $and for now
    // we need to solve exclusion of statuses with $nin for $or in order to support dynamic operators
    $and: [
      ...filteredConditions,
      // exclude unsupported statuses
      { status: { $nin: disallowedStatuses } },
    ],
  };
};

export const encodeMqlQueryItem = ({
  operator,
  value,
  field,
}: GridFilterItem): Array<
  Record<string, Partial<Record<MQLOperators, string | string[]>>>
> => {
  const mqlOperator = isKeyofDataGridOperatorsMap(operator)
    ? dataGridOperatorsMap[operator]
    : null;

  if (!mqlOperator) return [];

  return [
    {
      [field]: {
        [mqlOperator]: transformValue(value, field),
      },
    },
  ];
};
