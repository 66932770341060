import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import {
  Divider,
  ListSubheader,
  MenuItem,
  MenuList,
} from '@rossum/ui/material';
import { isValidFieldId } from '../../queue-settings/fields/utils';
import { schemaFieldRefPrefix } from '../FormulaEditor/constants';
import { OnInsertCallback } from './insertion-helpers';
import MenuItemContentField from './MenuItemContentField';
import { menuListSharedProps } from './shared';

const MenuListFields = ({
  schemaItems,
  setFirstRef,
  onInsert,
  search,
}: {
  schemaItems: Array<SchemaSection | SchemaItem>;
  setFirstRef: React.MutableRefObject<HTMLLIElement | null>;
  onInsert: OnInsertCallback;
  search: string;
}) => {
  return (
    <MenuList {...menuListSharedProps}>
      {schemaItems
        .filter(({ id }) => (id ? isValidFieldId(id) : false))
        .map((schemaItem, index) => {
          if (schemaItem.category === 'section') {
            return [
              index !== 0 && (
                <Divider sx={{ pt: 1 }} key={`${schemaItem.id}-divider`} />
              ),
              <ListSubheader key={`${schemaItem.id}-subheader`}>
                {schemaItem.label}
              </ListSubheader>,
            ];
          }

          return (
            <MenuItem
              key={schemaItem.id}
              ref={index === 1 ? setFirstRef : undefined}
              onClick={() =>
                onInsert(`${schemaFieldRefPrefix}.${schemaItem.id}`, {
                  insertMode: 'inline',
                })
              }
            >
              <MenuItemContentField search={search} schemaField={schemaItem} />
            </MenuItem>
          );
        })}
    </MenuList>
  );
};

export default MenuListFields;
