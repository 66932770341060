import { Paper, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';

type ContentGroupProps = {
  title: ReactNode;
  description: ReactNode;
  children: ReactNode;
  isFullWidthLayout?: boolean;
};

export const ContentGroup = ({
  title,
  description,
  children,
  isFullWidthLayout,
}: ContentGroupProps) => {
  const width = isFullWidthLayout ? '100%' : '50%';

  return (
    <Stack
      component={Paper}
      direction={isFullWidthLayout ? 'column' : 'row'}
      px={4}
      py={3}
      gap={4}
      width={1}
      sx={{
        borderRadius: 2,
      }}
    >
      <Stack width={width} spacing={1}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2" color="text.secondary" component="span">
          {description}
        </Typography>
      </Stack>
      <Stack width={width} spacing={3}>
        {children}
      </Stack>
    </Stack>
  );
};
