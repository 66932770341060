import { ArrowBack } from '@rossum/ui/icons';
import { IconButton, Stack, Typography } from '@rossum/ui/material';
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

type Props = PropsWithChildren<{
  onArrowBackClick?: () => void;
}>;

export const Header = ({ children, onArrowBackClick }: Props) => {
  const intl = useIntl();

  const FIELD_MANAGER_TITLE = intl.formatMessage({
    id: 'features.fieldManager.overview.ui.header.title',
  });
  const FIELD_MANAGER_SUBTITLE = intl.formatMessage({
    id: 'features.fieldManager.overview.ui.header.subTitle',
  });
  const { pathname } = useLocation();
  const title = pathname.includes('/detail')
    ? pathname.split('/detail/')[1]
    : FIELD_MANAGER_TITLE;

  const subtitle = title === FIELD_MANAGER_TITLE ? FIELD_MANAGER_SUBTITLE : '';

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mt: -1 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ minHeight: 40 }}
        spacing={1}
      >
        {onArrowBackClick && (
          <IconButton onClick={onArrowBackClick} data-cy="fm-back-arrow">
            <ArrowBack />
          </IconButton>
        )}
        <Stack spacing={1}>
          <Typography variant="h5">{title}</Typography>
          {subtitle ? (
            <Typography variant="body2">{subtitle}</Typography>
          ) : null}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};
