import { addMinutes, format, parseISO } from 'date-fns';
import { isSchemaColumn } from '../../../columns/helpers';
import { isFilterContextSimple } from '../../helpers';
import { dateOperators, FilterOperator } from '../../operators';
import {
  FilterContext,
  FilterItem,
  FilterValues,
  TypedGridColDef,
} from '../../types';

export type SelectedDates = [Date | null, Date | null];
export type DateOperator = (typeof dateOperators)[number];
type DateOperatorKeys = DateOperator['value'];

export const shouldBeEndOfDay = (operatorValue: FilterOperator['value']) =>
  operatorValue === 'onOrBefore' || operatorValue === 'after';

export const checkIfOperatorIsDate = (filterContext: FilterContext) =>
  isFilterContextSimple(filterContext)
    ? filterContext.operator.type === 'date'
    : filterContext.every(({ operator }) => operator.type === 'date');

export const isDateOperator = (operator: string) => {
  return dateOperators.some(({ value }) => value === operator);
};

export const isRangedDateOperator = (
  operator: FilterOperator['value']
): operator is 'is' => operator === 'is';

export const beforeOperator: (typeof dateOperators)[number] = {
  type: 'date',
  value: 'before',
  component: 'dateTimePicker',
};
export const afterOperator: (typeof dateOperators)[number] = {
  type: 'date',
  value: 'onOrAfter',
  component: 'dateTimePicker',
};

export const createDateRangeFilterQuery = ({
  from,
  to,
}: {
  from: Date;
  to: Date;
}): FilterContext => {
  return [
    {
      operator: afterOperator,
      value: dateToQuery(from),
    },
    {
      operator: beforeOperator,
      value: dateToQuery(to),
    },
  ];
};

const createEndingDate = (date: Date) => ({ endingDate: date });
const createStartingDate = (date: Date) => ({ startingDate: date });

const operatorNormalizers: Partial<
  Record<
    FilterOperator['value'],
    typeof createEndingDate | typeof createStartingDate
  >
> = {
  after: createStartingDate,
  onOrAfter: createStartingDate,
  before: createEndingDate,
  onOrBefore: createEndingDate,
};

// isNaN(Invalid Date) == true
export const getValidDate = (value: unknown) =>
  typeof value === 'string'
    ? parseISO(value)
    : value instanceof Date && !Number.isNaN(value)
      ? value
      : null;

export const processAdvancedOperatorValues = ({
  filterContext,
  column,
}: {
  filterContext: FilterValues[];
  column: TypedGridColDef;
}) =>
  filterContext.reduce<{
    endingDate: SelectedDates[number];
    startingDate: SelectedDates[number];
  }>(
    (acc, { operator, value }) => {
      const validValue = getValidDate(value);
      const normalizedValue = validValue
        ? processDateColumnValue({
            date: validValue,
            column,
            direction: 'localizer',
          })
        : null;

      if (!normalizedValue) return acc;

      const normalizer = operatorNormalizers[operator.value];

      return normalizer ? { ...acc, ...normalizer(normalizedValue) } : acc;
    },
    { endingDate: null, startingDate: null }
  );

export const resolveDateOperatorValue = ({
  endingDate,
  startingDate,
}: ReturnType<typeof processAdvancedOperatorValues>) =>
  !endingDate || !startingDate ? null : 'is';

// Considering bookmarked urls;
// A filter is considered advanced only when the same column has multiple operators.
// In Date's case an advanced filter is valid only when we receive a combination of 'before' and 'after' operators.
// We disregard other combinations because they don't make sense and they were there for historical reasons.
// This function will return either a refined operatorValue in case of valid date filters, or null in case of invalid.
export const getValidatedDateContext = (
  filterItem: FilterItem
): {
  operatorValue: DateOperatorKeys | null;
  existingDates: SelectedDates;
} => {
  const { filterContext, column } = filterItem;

  if (isFilterContextSimple(filterContext)) {
    const operatorValue =
      filterContext.operator.type === 'date'
        ? filterContext.operator.value
        : null;

    const validValue = getValidDate(filterContext.value);

    const normalizedValue = validValue
      ? processDateColumnValue({
          date: validValue,
          column,
          direction: 'localizer',
        })
      : null;

    return {
      operatorValue,
      existingDates: [normalizedValue, normalizedValue],
    };
  }

  const { endingDate, startingDate } = processAdvancedOperatorValues({
    filterContext,
    column,
  });

  const operatorValue = resolveDateOperatorValue({ endingDate, startingDate });

  return {
    operatorValue,
    existingDates:
      operatorValue === null
        ? [null, null]
        : operatorValue === 'is'
          ? [startingDate, endingDate]
          : [endingDate, startingDate],
  };
};

export const convertToUTC = (date: Date) =>
  addMinutes(date, date.getTimezoneOffset());

export const convertFromUTC = (date: Date) =>
  addMinutes(date, -date.getTimezoneOffset());

export const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm";
export const dateToQuery = (date: Date) => format(date, DATE_FORMAT);

export const processDateColumnValue = ({
  date,
  column,
  direction,
}: {
  date: Date;
  column: TypedGridColDef;
  direction: 'localizer' | 'normalizer';
}) =>
  isSchemaColumn(column)
    ? date
    : direction === 'normalizer'
      ? convertToUTC(date)
      : convertFromUTC(date);

export const processDatesBeforeSubmit = ({
  selectedDates,
  operator,
  column,
}: {
  selectedDates: SelectedDates;
  operator: FilterOperator;
  column: TypedGridColDef;
}): FilterContext | null => {
  const [from, to] = selectedDates.map(date =>
    date
      ? processDateColumnValue({
          date,
          column,
          direction: 'normalizer',
        })
      : null
  );

  if (isRangedDateOperator(operator.value) && from && to) {
    return createDateRangeFilterQuery({
      from,
      to,
    });
  }

  if (from) {
    return { operator, value: dateToQuery(from) };
  }

  return null;
};
