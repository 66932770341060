import clsx from 'clsx';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import { ReactNode } from 'react';
import styles from '../styles.module.sass';

type Props = {
  children: ReactNode;
  className?: string;
};

const Banner = ({ children, className }: Props) => (
  <div className={clsx(styles.Banner, className)}>
    <InfoIcon size={20} />
    {/* children shouldn't be formatted as flexbox items, so we put them into a span */}
    <span>{children}</span>
  </div>
);

export default Banner;
