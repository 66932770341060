import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

type EngineSelectionFormProps = {
  onClose: () => void;
  onSubmit: (formModel: { legacy: boolean }) => void;
  mutationLoading: boolean;
};

export const EngineSelectionForm = ({
  onClose,
  onSubmit,
  mutationLoading,
}: EngineSelectionFormProps) => {
  const intl = useIntl();

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      legacy: false,
    },
  });

  const options = [
    {
      value: false,
      label: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.newEngine',
      }),
      description: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.newEngine.description',
      }),
    },
    {
      value: true,
      label: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.legacyEngine',
      }),
      description: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.legacyEngine.description',
      }),
    },
  ];

  return (
    <form id="queue-detail-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="body1" sx={{ pt: 1 }}>
            {intl.formatMessage({
              id: 'components.addQueueDialog.step.engineDetail',
            })}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'components.addQueueDialog.step.engineDetail.description',
            })}
          </Typography>
        </Stack>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="legacy"
            render={({ field }) => {
              return (
                <FormControl component="fieldset" hiddenLabel>
                  <RadioGroup
                    value={field.value}
                    onChange={(_, val) => field.onChange(val === 'true')}
                    sx={{ gap: 2 }}
                  >
                    {options.map(option => (
                      <Stack
                        key={option.label}
                        spacing={1}
                        component={Paper}
                        sx={{
                          label: {
                            m: 0,
                            p: 2,
                            width: '100%',
                            justifyContent: 'space-between',
                          },
                        }}
                      >
                        <FormControlLabel
                          value={option.value}
                          control={<Radio size="small" />}
                          componentsProps={{
                            typography: {
                              variant: 'body2',
                            },
                          }}
                          labelPlacement="start"
                          label={
                            <Stack spacing={0.5} mr={1}>
                              <Typography variant="h6">
                                {option.label}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {option.description}
                              </Typography>
                            </Stack>
                          }
                        />
                      </Stack>
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            }}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              data-cy="queue-form-button-cancel"
              disabled={mutationLoading}
            >
              {intl.formatMessage({
                id: 'components.queueDetailForm.cancelButton',
              })}
            </Button>
            <Button
              variant="contained"
              startIcon={
                mutationLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null
              }
              type="submit"
              disabled={mutationLoading}
              data-cy="queue-form-button-submit"
            >
              {intl.formatMessage({
                id: 'components.queueDetailForm.finishButton',
              })}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
