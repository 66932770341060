import { Divider } from '@rossum/ui/material';
import React from 'react';
import { Control, FieldError } from 'react-hook-form';
import { FormattedMessage, IntlShape } from 'react-intl';
import ControlledToggle from '../../../../../components/ReactHookForm/Toggle';
import ControlledValidationInput from '../../../../../components/ReactHookForm/ValidationInput';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import FormLabel from '../../../../User/components/FormLabel';
import styles from '../../../styles.module.sass';
import { QueueSettingsData } from './formType';

type NotificationsProps = {
  control: Control<QueueSettingsData>;
  intl: IntlShape;
  getErrorMessage: (_id: string, _errorMessage: FieldError) => string;
  clearValidationsOnChange: (
    _id: string
  ) => (
    _handleChange: (_e: React.ChangeEvent<unknown>) => void
  ) => (_event: React.ChangeEvent<unknown>) => void;
};

const Notifications = ({
  control,
  intl,
  getErrorMessage,
  clearValidationsOnChange,
}: NotificationsProps) => (
  <PaperSection
    title={intl.formatMessage({ id: 'containers.settings.queues.bounce' })}
  >
    <div className={styles.NotificationsWrapper}>
      <div className={styles.NotificationEmailSublabel}>
        <FormattedMessage id="containers.settings.queues.bounceEmailTo.sublabel" />
      </div>
      <div className={styles.Row}>
        <div>
          <FormLabel>
            <FormattedMessage id="containers.settings.queues.bounceEmailTo" />
          </FormLabel>
        </div>
        <ControlledValidationInput<QueueSettingsData>
          control={control}
          name="emailTo"
          getErrorMessage={getErrorMessage}
          clearValidationsOnChange={clearValidationsOnChange('emailTo')}
          dataCy="basic-setting-email-input"
          placeholder={intl.formatMessage({
            id: 'containers.settings.queues.emailTo.placeholder',
          })}
        />
      </div>
      <Divider />
      <div className={styles.NotificationEventsSublabel}>
        <FormattedMessage id="containers.settings.queues.events.sublabel" />
      </div>
      <div className={styles.Row}>
        <FormLabel>
          <FormattedMessage id="containers.settings.queues.bounceUnprocessableAttachments" />
        </FormLabel>
        <div className={styles.ToggleField}>
          <ControlledToggle<QueueSettingsData>
            control={control}
            name="unprocessableAttachments"
          />
        </div>
      </div>
      <div className={styles.Row}>
        <FormLabel>
          <FormattedMessage id="containers.settings.queues.bouncePostponedAnnotations" />
        </FormLabel>
        <div className={styles.ToggleField}>
          <ControlledToggle<QueueSettingsData>
            control={control}
            name="postponedAnnotations"
          />
        </div>
      </div>
      <div className={styles.Row}>
        <FormLabel>
          <FormattedMessage id="containers.settings.queues.bounceDeletedAnnotations" />
        </FormLabel>
        <div className={styles.ToggleField}>
          <ControlledToggle<QueueSettingsData>
            control={control}
            name="deletedAnnotations"
          />
        </div>
      </div>
    </div>
  </PaperSection>
);

export default Notifications;
