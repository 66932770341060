import { CloudDownload, Delete } from '@rossum/ui/icons';
import { IconButton, Stack, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ViewerRestrictor } from '../../../components/Restrictors';
import { useDownloadOriginalDocuments } from '../../../features/document-list/hooks/useDownloadOriginalDocuments';
import { linebreak } from '../../../lib/formaterValues';
import { isDeletable } from '../../../redux/modules/annotation/helpers';
import { openModal } from '../../../redux/modules/modal/actions';
import { isImageOrPdf } from '../helpers';
import { AttachmentT } from './EmailAttachment/helpers';

type EmailAttachmentActionsProps = {
  attachment: AttachmentT;
  onDelete: () => void;
};

export const EmailAttachmentActions = ({
  attachment,
  onDelete,
}: EmailAttachmentActionsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { mutate: downloadOriginalDocuments } = useDownloadOriginalDocuments();

  const { mimeType, originalFileName, content } = attachment.document;

  const onDownload = () =>
    isImageOrPdf(mimeType)
      ? downloadOriginalDocuments({ documents: [attachment.document.url] })
      : dispatch(
          openModal({
            textId: 'unsafeAttachmentDownload',
            onConfirm: () => {
              downloadOriginalDocuments({
                documents: [attachment.document.url],
              });
            },
          })
        );

  return (
    <ViewerRestrictor>
      <Stack direction="row" spacing={1}>
        {originalFileName !== null && content && (
          <Tooltip
            title={intl.formatMessage({
              id: 'components.emailDocumentsPreview.downloadTooltip',
            })}
            placement="top"
          >
            <IconButton
              aria-label="download"
              data-cy="email-card-delete-original-file"
              onClick={onDownload}
            >
              <CloudDownload fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {attachment.annotation && isDeletable(attachment.annotation.status) && (
          <Tooltip
            placement="top"
            title={
              intl.formatMessage(
                { id: 'components.emailDocumentsPreview.deleteTooltip' },
                {
                  linebreak,
                }
              ) ?? ''
            }
            sx={{ textAlign: 'center' }}
          >
            <IconButton aria-label="delete" onClick={onDelete}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </ViewerRestrictor>
  );
};
