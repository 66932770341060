import { GridColDef } from '@rossum/ui/x-data-grid-pro';
import React from 'react';
import { IntlShape } from 'react-intl';
import { FieldMetadata, OverviewGridRowModel } from '../../types/fields';
import { text } from '../constants';
import {
  FieldOptions,
  getCategoryOptions,
  getTypeOptions,
  getUiConfigurationTypeOptions,
} from '../data/options';
import { queuesOperators } from '../queue-filtering/queueFilterOperators';
import { injectStylesToOperators } from '../ui/dataGridStyles';
import { AggregatedValuesWithOccurrences } from './AggregatedValuesWithOccurrences';
import { FieldId } from './FieldId';
import { arrayObjectOperators } from './filterOperators';
import { DataForQueuesDialog } from './QueuesDialog';
import { QueuesDialogLink } from './QueuesDialogLink';
import { arrayObjectComparator, lengthComparator } from './sortComparators';

const getOptionLabel = (options: FieldOptions, value: string) =>
  options.find(option => option.value === value)?.label || '';

type GroupedValueReturnType = Record<string, string | number> | '-';

const metadataValueGetter =
  (options: FieldOptions, option: keyof FieldMetadata) =>
  ({ row }: { row: OverviewGridRowModel }): GroupedValueReturnType => {
    const optionValue = row.metadata?.[option];
    if (optionValue) {
      const entries = Object.entries(optionValue);

      const transformedEntry = entries.reduce<Record<string, string | number>>(
        (acc, [key, value]) => {
          const newKey = getOptionLabel(options, key);

          acc[newKey] = value;

          return acc;
        },
        {}
      );
      return transformedEntry;
    }
    return text.cellNotEditable;
  };

type Props = {
  setRowForQueuesDialog: React.Dispatch<
    React.SetStateAction<DataForQueuesDialog | null>
  >;
  intl: IntlShape;
};

export const getColumns = ({
  setRowForQueuesDialog,
  intl,
}: Props): GridColDef<OverviewGridRowModel>[] =>
  injectStylesToOperators([
    {
      field: 'fieldId',
      headerName: intl.formatMessage({
        id: 'features.fieldManager.overview.columns.headerName.fieldId',
      }),
      minWidth: 80,
      flex: 1.4,
      renderCell: FieldId(),
    },
    {
      field: 'queues',
      headerName: intl.formatMessage({
        id: 'features.fieldManager.overview.columns.headerName.queues',
      }),
      minWidth: 100,
      flex: 1,
      sortComparator: lengthComparator,
      filterOperators: queuesOperators,
      renderCell: QueuesDialogLink(setRowForQueuesDialog),
      type: 'singleSelect',
      valueGetter: ({ value }: { value: OverviewGridRowModel['queues'] }) => {
        return value ? value.flatMap(v => (v.id ? [String(v.id)] : [])) : [];
      },
    },
    {
      field: 'labelWithOccurrences',
      headerName: intl.formatMessage({
        id: 'features.fieldManager.overview.columns.headerName.labelWithOccurrences',
      }),
      minWidth: 140,
      flex: 1.4,
      filterOperators: arrayObjectOperators,
      sortComparator: arrayObjectComparator,
      valueGetter: ({ row }: { row: OverviewGridRowModel }) =>
        row.metadata?.labelWithOccurrences,
      renderCell: ({ value }: { value?: GroupedValueReturnType }) => (
        <AggregatedValuesWithOccurrences value={value} />
      ),
    },
    {
      field: 'categoryWithOccurrences',
      headerName: intl.formatMessage({
        id: 'features.fieldManager.overview.columns.headerName.categoryWithOccurrences',
      }),
      minWidth: 100,
      flex: 1,
      sortComparator: arrayObjectComparator,
      filterOperators: arrayObjectOperators,
      valueGetter: metadataValueGetter(
        getCategoryOptions(intl),
        'categoryWithOccurrences'
      ),
      renderCell: ({ value }: { value?: GroupedValueReturnType }) => (
        <AggregatedValuesWithOccurrences value={value} icon />
      ),
    },
    {
      field: 'typeWithOccurrences',
      headerName: intl.formatMessage({
        id: 'features.fieldManager.overview.columns.headerName.typeWithOccurrences',
      }),
      minWidth: 100,
      flex: 1,
      sortComparator: arrayObjectComparator,
      filterOperators: arrayObjectOperators,
      valueGetter: metadataValueGetter(
        getTypeOptions(intl),
        'typeWithOccurrences'
      ),
      renderCell: ({ value }: { value?: GroupedValueReturnType }) => (
        <AggregatedValuesWithOccurrences value={value} />
      ),
    },
    {
      field: 'uiConfigurationTypeWithOccurrences',
      headerName: intl.formatMessage({
        id: 'features.fieldManager.overview.columns.headerName.uiConfigurationTypeWithOccurrences',
      }),
      minWidth: 100,
      flex: 1,
      sortComparator: arrayObjectComparator,
      filterOperators: arrayObjectOperators,
      valueGetter: metadataValueGetter(
        getUiConfigurationTypeOptions(intl),
        'uiConfigurationTypeWithOccurrences'
      ),
      renderCell: ({ value }: { value?: GroupedValueReturnType }) => (
        <AggregatedValuesWithOccurrences value={value} icon />
      ),
    },
  ]);
