import { Close } from '@rossum/ui/icons';
import { Button, Paper, Stack, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { KeyboardShortcut } from '../UI/KeyboardShortcut';

type Props = {
  onRejectClick: () => void;
  onPrevClick: (() => void) | undefined;
  onNextClick: (() => void) | undefined;
  onConfirmClick: () => void;
};

const SuggestionPositionsDialog = ({
  onRejectClick,
  onPrevClick,
  onNextClick,
  onConfirmClick,
}: Props) => {
  const intl = useIntl();

  return (
    <Stack
      component={Paper}
      flexDirection="row"
      justifyContent="space-between"
      elevation={4}
      sx={{ padding: 1 }}
      gap={0.5}
      width={270}
    >
      <Tooltip
        title={
          <Stack spacing={0.5} direction="column">
            <KeyboardShortcut
              keyName="escape"
              description={intl.formatMessage({
                id: 'components.datapoint.suggestedPositions.dialog.hide.tooltip',
              })}
            />
          </Stack>
        }
        key="reject-button"
      >
        <Button
          variant="text"
          color="secondary"
          size="small"
          data-cy="suggested-positions-reject"
          onClick={onRejectClick}
          sx={{ p: 0.5, minWidth: 0 }}
        >
          <Close />
        </Button>
      </Tooltip>
      <Tooltip
        title={
          !onPrevClick ? (
            ''
          ) : (
            <Stack spacing={0.5} direction="column">
              <KeyboardShortcut
                keyName="shiftTab"
                description={intl.formatMessage({
                  id: 'components.datapoint.suggestedPositions.dialog.previous.tooltip',
                })}
              />
            </Stack>
          )
        }
        key="prev-button"
      >
        <Button
          variant="text"
          color="secondary"
          size="small"
          data-cy="prev-suggestion"
          disabled={!onPrevClick}
          onClick={onPrevClick}
        >
          {intl.formatMessage({
            id: 'components.datapoint.suggestedPositions.dialog.previous.button',
          })}
        </Button>
      </Tooltip>
      <Tooltip
        title={
          !onNextClick ? (
            ''
          ) : (
            <Stack spacing={0.5} direction="column">
              <KeyboardShortcut
                keyName="tab"
                description={intl.formatMessage({
                  id: 'components.datapoint.suggestedPositions.dialog.next.tooltip',
                })}
              />
            </Stack>
          )
        }
        key="next-button"
      >
        <Button
          variant="text"
          color="secondary"
          size="small"
          data-cy="next-suggestion"
          disabled={!onNextClick}
          onClick={onNextClick}
        >
          {intl.formatMessage({
            id: 'components.datapoint.suggestedPositions.dialog.next.button',
          })}
        </Button>
      </Tooltip>
      <Tooltip
        title={
          <Stack spacing={0.5} direction="column">
            <KeyboardShortcut
              keyName="enter"
              description={intl.formatMessage({
                id: 'components.datapoint.suggestedPositions.dialog.confirm.tooltip',
              })}
            />
          </Stack>
        }
        key="confirm-button"
      >
        <Button
          variant="contained"
          size="small"
          data-cy="confirm-suggestion"
          onClick={onConfirmClick}
        >
          {intl.formatMessage({
            id: 'components.datapoint.suggestedPositions.dialog.confirm.button',
          })}
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default SuggestionPositionsDialog;
