import { ArrowBack } from '@rossum/ui/icons';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { throttle } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';

const CONTAINER_WIDTH = 1024;
const DESCRIPTION_MAX_LENGTH = 175;

export type HeaderProps = {
  breadcrumbs: ReactNode;
  tabs?: ReactNode;
  scrollableDivRef: React.RefObject<HTMLDivElement>;
  buttons?: ReactNode[];
  title: ReactNode;
  description: ReactNode;
  onBackButtonClicked?: () => void;
};

export const Header = ({
  breadcrumbs,
  tabs,
  scrollableDivRef,
  buttons,
  title,
  description,
  onBackButtonClicked,
}: HeaderProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [expandedDescription, setExpandedDescription] = useState(false);

  const intl = useIntl();

  const handleScroll = throttle(() => {
    if (scrollableDivRef.current) {
      const scrollY = scrollableDivRef.current.scrollTop;
      if (!hasScrolled) {
        setHasScrolled(scrollY > 170);
        return;
      }

      setHasScrolled(scrollY > 0);
    }
  }, 150);

  useEffect(() => {
    const div = scrollableDivRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
      handleScroll.cancel();
    };
  }, [handleScroll, scrollableDivRef]);

  const sideWidthSize = `calc((100% - ${CONTAINER_WIDTH}px) / 2)`;

  return (
    <Stack
      direction="row"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 2,
        borderBottom: t => `1px solid ${t.palette.divider}`,
        backgroundColor: t => t.palette.background.paper,
      }}
    >
      {onBackButtonClicked && (
        <Stack
          width={sideWidthSize}
          alignItems="flex-end"
          sx={{
            position: 'relative',
            transform: `translateX(30px)`,
            mt: hasScrolled ? 2 : breadcrumbs ? 1.5 : 2.5,
            transition: t =>
              t.transitions.create(['margin-top'], {
                duration: t.transitions.duration.short,
              }),
            '@media only screen and (min-width: 1200px)': {
              transform: `translateX(20px)`,
            },
          }}
        >
          <IconButton
            onClick={onBackButtonClicked}
            size="small"
            color="secondary"
          >
            <ArrowBack
              fontSize="small"
              sx={{ fill: t => t.palette.secondary.main }}
            />
          </IconButton>
        </Stack>
      )}
      <Stack width={CONTAINER_WIDTH} px={4} mx="auto">
        <Stack pt={2} spacing={hasScrolled ? 0 : breadcrumbs ? 2 : 0} pb={2}>
          <Collapse in={!hasScrolled}>{breadcrumbs}</Collapse>
          <Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Stack flexGrow={2} flexShrink={1} flexBasis={0} spacing={0}>
                <Typography variant={hasScrolled ? 'h4' : 'h3'}>
                  {title}
                </Typography>
                <Collapse in={!hasScrolled}>
                  {R.isString(description) ? (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                    >
                      {description.length >= DESCRIPTION_MAX_LENGTH &&
                      !expandedDescription ? (
                        <>
                          <Box component="span">{`${description.slice(0, DESCRIPTION_MAX_LENGTH)}...`}</Box>
                          <Box
                            component="span"
                            color="text.disabled"
                            onClick={() => setExpandedDescription(true)}
                            ml={1}
                            sx={{ cursor: 'pointer' }}
                          >
                            {intl.formatMessage({
                              id: 'containers.settings.queues.queue.settings.expandDescription',
                            })}
                          </Box>
                        </>
                      ) : (
                        description
                      )}
                    </Typography>
                  ) : (
                    // It is a ReactNode | null - display as-is.
                    description
                  )}
                </Collapse>
              </Stack>
              <Stack
                flexGrow={1}
                flexShrink={1}
                flexBasis={0}
                direction="row"
                justifyContent="flex-end"
                gap={1}
              >
                {buttons}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Collapse in={!hasScrolled}>{tabs ?? null}</Collapse>
      </Stack>
      {onBackButtonClicked && <Box width={sideWidthSize} />}
    </Stack>
  );
};
