import { get, isString } from 'lodash';
import { useEffect } from 'react';
import { FieldPath, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { flattenWithPaths } from '../helpers';
import { SchemaObjectFormData } from '../types';

/**
 * Ensure that validation is triggered for all ID fields in the form.
 * Otherwise, user would see the uniqueness error only for the edited field.
 *
 * The validation itself is performed in the YUP schema for the 'id' field.
 */
export const useTriggerIdUniquenessValidation = ({
  watch,
  enabled,
  trigger,
}: {
  watch: UseFormWatch<SchemaObjectFormData>;
  enabled: boolean;
  trigger: UseFormTrigger<SchemaObjectFormData>;
}) => {
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      // The set of relevant IDs in the edited schema object can change by:
      // 1. editing any ID field
      // 2. deleting a column for a table multivalue
      // 3. changing fieldType (some ID fields became inactive or active)
      const needToRevalidateIdFields =
        enabled &&
        name &&
        (name === 'id' ||
          name.endsWith('.id') ||
          name === 'children.children' ||
          name === 'fieldType');

      if (needToRevalidateIdFields) {
        const idsToRevalidate = flattenWithPaths<
          string,
          FieldPath<SchemaObjectFormData>
        >(values, isString).filter(
          ([path]: [FieldPath<SchemaObjectFormData>, string]) =>
            // Current field is being validated on change, so no need to validate it again
            path !== name &&
            // ID of the tuple is automatically generated and shouldn't be handled here
            path !== 'children.id' &&
            // Validate all other 'id' fields even the hidden ones (the error on children should disappear after we change field type)
            (path === 'id' || path.endsWith('.id')) &&
            // Empty values are handled by the normal required validation
            get(values, path) !== ''
        );

        trigger(idsToRevalidate.map(([path]) => path));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, enabled, trigger]);
};
