import { Schema, SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { fieldsFormErrorMap } from '../form-model/errors/formErrorMap';
import { fieldsFormSchema, FieldsFormValues } from '../form-model/formModels';
import { toSchemaPatchPayload } from '../form-model/transformations/toSchema';
import { SchemaAction } from '../form-model/transformations/toSchema.utils';

export const getUnsafeSchemaConcept = ({
  schema,
  parentId,
  apiModel,
  formValues,
}: {
  schema: Schema;
  parentId: string | null;
  apiModel: SchemaSection | SchemaItem | null;
  formValues: FieldsFormValues;
}) => {
  const schemaAction = apiModel
    ? { op: 'edit', id: apiModel.id, formModel: formValues }
    : { op: 'add', parentId, formModel: formValues };

  return toSchemaPatchPayload(schema, schemaAction as SchemaAction);
};

export const getSchemaConcept = ({
  schema,
  parentId,
  apiModel,
  formValues,
  intl,
}: {
  schema: Schema;
  parentId: string | null;
  apiModel: SchemaSection | SchemaItem | null;
  formValues: FieldsFormValues;
  intl: IntlShape;
}) => {
  const parsedFormModel = fieldsFormSchema(intl).safeParse(formValues, {
    errorMap: fieldsFormErrorMap(intl),
  });

  if (!parsedFormModel.success) {
    return schema;
  }

  const schemaAction: SchemaAction = apiModel
    ? { op: 'edit', id: apiModel.id, formModel: parsedFormModel.data }
    : { op: 'add', parentId, formModel: parsedFormModel.data };

  const parsedSchema = toSchemaPatchPayload(schema, schemaAction);

  if (!parsedSchema) {
    return schema;
  }

  return parsedSchema;
};

/**
 * Return a WIP version of `Schema` that would be created if the form were to be submitted with `formValues`
 */
export const useSchemaConcept = (
  schema: Schema,
  parentId: string | null,
  apiModel: SchemaSection | SchemaItem | null,
  formValues: FieldsFormValues
): Schema => {
  const intl = useIntl();

  return useMemo(
    () =>
      getSchemaConcept({
        schema,
        parentId,
        apiModel,
        formValues,
        intl,
      }),
    [apiModel, formValues, intl, parentId, schema]
  );
};
