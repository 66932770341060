import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../style.module.sass';

type Props = {
  children: ReactNode;
  title: ReactNode;
  className?: string;
};

const FieldSection = ({ children, title, className }: Props) => (
  <div className={clsx(styles.SchemaDatapoints, className)}>
    <div className={styles.SchemaDatapointsTitle}>{title}</div>
    {children}
  </div>
);

export default FieldSection;
