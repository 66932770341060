import { get } from 'lodash';
import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  takeUntil,
} from 'rxjs/operators';
import { clickApiErrorHandler } from '../../../../lib/api';
import { SearchResult } from '../../../../types/search';
import { getUITypeFromSchema } from '../../schema/helpers';
import { schemaMapSelector } from '../../schema/schemaMapSelector';
import { leaveValidation } from '../../ui/actions';
import { isActionOf, makeEpic } from '../../utils';
import { updateDatapointValueFulfilled } from '../actions';
import { fetchSuggestedPositionsForValueFulfilled } from './actions';

const suggestBboxesForDatapointValueEpic = makeEpic(
  (action$, state$, { authGetJSON$ }) =>
    action$.pipe(
      filter(isActionOf(updateDatapointValueFulfilled)),
      filter(
        ({ meta }) =>
          !!(
            (meta && meta.reason === 'input-sidebar') ||
            (meta && meta.reason === 'input-edit-box')
          )
      ),
      filter(
        ({ meta }) => !!(meta && meta.newValue && meta.newValue.length > 3)
      ),
      filter(({ meta }) => {
        const datapointSchema = schemaMapSelector(state$.value).get(
          meta?.schemaId ?? ''
        );

        const isHeaderField = datapointSchema?.path.length === 3;

        // alternatively, we could use canHaveBbox if we want to support also legacy fields
        const isCapturedField =
          datapointSchema &&
          getUITypeFromSchema(datapointSchema) === 'captured';
        const isEnum = datapointSchema?.type === 'enum';

        return !!(isHeaderField && isCapturedField && !isEnum);
      }),
      filter(({ meta, payload }) => {
        const ocrText = get(payload, ['content', 'ocrText'], '');

        return !!(meta && meta.newValue && ocrText !== meta.newValue);
      }),
      mergeMap(({ meta, payload }) =>
        authGetJSON$<{ results: SearchResult[] }>(
          `${state$.value.annotation.url}/search`,
          {
            query: {
              phrase: meta && meta.newValue,
              excludeDatapointOverlaps: true,
            },
          }
        ).pipe(
          takeUntil(action$.pipe(filter(isActionOf([leaveValidation])))),
          pluck('results'),
          map(results => {
            return fetchSuggestedPositionsForValueFulfilled(results, {
              id: payload.id,
              schemaId: payload.schemaId,
            });
          }),
          catchError(clickApiErrorHandler)
        )
      )
    )
);

export default combineEpics(suggestBboxesForDatapointValueEpic);
