import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { FieldRouteItem } from '../types';

const getNewFieldUrl = (currentUrl: string, newRouteIds: string[] | null) => {
  const urlParts = currentUrl.split('/');
  const fieldsIndex = urlParts.findIndex(part => part === 'fields');

  if (fieldsIndex !== -1) {
    const baseUrlParts = urlParts.slice(0, fieldsIndex + 1);
    const baseUrl = baseUrlParts.join('/');

    if (newRouteIds) {
      return `${baseUrl}/${newRouteIds.join('/')}`;
    }
    return baseUrl;
  }

  return currentUrl;
};

export const useResolveUrl = () => {
  const {
    location: { pathname },
  } = useHistory();

  return useCallback(
    (
      fieldDoesNotExist: boolean,
      schemaPath: FieldRouteItem[],
      urlFieldRoute: string[]
    ) => {
      const schemaFieldRoute = schemaPath.map(item => item.id);

      if (fieldDoesNotExist) {
        const canBeParent = !schemaPath.some(item => !item.canBeParent);

        // check if every field in the path is able to be a parent or if the path is empty
        if (canBeParent || !schemaFieldRoute.length) {
          const filteredUrlFieldRoute = urlFieldRoute.slice(0, -1);

          // we have to prevent create field in the wrong place
          // check if the path from url for new field is same as the path from schema, if it does ~> return null
          if (isEqual(schemaFieldRoute, filteredUrlFieldRoute)) {
            return null;
          }
        }

        // new field cannot be parent or the path from url is different from the schema path ~> return base url
        return getNewFieldUrl(pathname, null);
      }

      // field exists but has wrong path in url ~> return correct url
      return getNewFieldUrl(pathname, schemaFieldRoute);
    },
    [pathname]
  );
};
