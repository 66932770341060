import { Delete, DragIndicator, Edit } from '@rossum/ui/icons';
import { Button, IconButton } from '@rossum/ui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DragHandleProps } from '../../../../../components/Dnd/SortableWrapper';
import { preDefinedDescriptions } from '../../../../../features/queue-settings/fields/constants';
import styles from '../style.module.sass';

type Props = {
  datapoint: { id: string; hidden?: boolean; label: string };
  indexInSection: number;
  onDelete?: (_index: number, _label: string) => void;
  onToggle?: (_index: number) => void;
  onClick?: (_index: number) => void;
  canClick?: boolean;
  dragHandleProps?: DragHandleProps;
  isOverlay?: boolean;
  isVisibleDuringDragging?: boolean;
  isDraggingByKeyboard?: boolean;
};
const Field = ({
  datapoint,
  indexInSection,
  onDelete,
  onToggle,
  onClick,
  canClick,
  dragHandleProps,
  isOverlay,
  isVisibleDuringDragging,
  isDraggingByKeyboard,
}: Props) => {
  const { id, hidden, label } = datapoint;
  const description = get(preDefinedDescriptions, id);
  const VisibilityIcon = hidden ? EyeOffIcon : EyeIcon;

  const handleClick = () => onClick && onClick(indexInSection);

  const handleToggle = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();

    if (onToggle) {
      onToggle(indexInSection);
    }
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (onDelete) {
      onDelete(indexInSection, label);
    }
  };

  const isDragging = dragHandleProps && dragHandleProps.isDragging;
  const isVisiblePlaceholder = isDragging && isVisibleDuringDragging;

  return (
    <div className={styles.SchemaDatapointBackground}>
      <div
        className={clsx(
          styles.SchemaDatapoint,
          !hidden && styles.SchemaDatapointChecked,
          canClick === false && styles.NotAllowedCursor,
          isVisiblePlaceholder && styles.SchemaDatapointIsDragging,
          isDraggingByKeyboard && styles.SchemaDatapointIsDraggingByKeyboard
        )}
        onClick={handleClick}
      >
        <div className={styles.DragHandle}>
          <DragIndicator
            fontSize="inherit"
            {...dragHandleProps?.listeners}
            {...dragHandleProps?.attributes}
            sx={{
              cursor: isOverlay
                ? 'grabbing'
                : dragHandleProps
                  ? 'grab'
                  : 'not-allowed',
            }}
          />
        </div>
        <div className={styles.SelectHiddenability}>
          <VisibilityIcon onClick={handleToggle} />
        </div>
        <div
          className={clsx(
            styles.SchemaDatapointInfo,
            hidden && styles.SchemaDatapointInfoUnchecked
          )}
        >
          <div className={styles.Label}>{label}</div>
          {description && (
            <div className={styles.Description}>{description}</div>
          )}
        </div>
        <div className={styles.ActionButtons}>
          <Button
            color="inherit"
            size="small"
            startIcon={<Edit />}
            data-cy="edit-field"
          >
            <FormattedMessage id="containers.settings.queueFields.edit.edit" />
          </Button>
          <IconButton
            color="inherit"
            size="small"
            onClick={handleDelete}
            data-cy="delete-field"
          >
            <Delete fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Field;
