import { isNumber } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'remeda';
import { filter, map, tap } from 'rxjs/operators';
import { useObservableContext } from '../../components/ObservableProvider';
import { prevent } from '../../lib/DOM';
import {
  recountDatapointPosition,
  updatePosition,
} from '../../redux/modules/datapoints/actions';
import { currentDatapointSelector } from '../../redux/modules/datapoints/selector';
import { suggestedPositionsForValueSelector } from '../../redux/modules/datapoints/suggestedPositionsForValue/selectors';
import { setIsSuggestingPositionsForValue } from '../../redux/modules/ui/actions';
import { State } from '../../types/state';

type SuggestedPositionsContextValue = {
  suggestedPositionRef: SVGRectElement | null;
  setSuggestedPositionRef: (ref: SVGRectElement) => void;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  setNextPosition: (() => void) | undefined;
  setPreviousPosition: (() => void) | undefined;
  confirmSuggestion: () => void;
  closeSuggestingPositions: () => void;
};

const keyboardKeys = ['Tab', 'Escape', 'Enter'] as const;

export const SuggestedPositionsContext = createContext<
  SuggestedPositionsContextValue | undefined
>(undefined);

export const SuggestedPositionsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { onKeyDownObserver } = useObservableContext();
  const currentDatapoint = useSelector(currentDatapointSelector);
  const isSuggestingPositionsForValue = useSelector(
    (state: State) => state.ui.isSuggestingPositionsForValue
  );
  const suggestedPositions = useSelector(suggestedPositionsForValueSelector);
  const suggestedPositionsForValue = currentDatapoint
    ? suggestedPositions[currentDatapoint.id]
    : undefined;

  // using useState instead of useRef because we want to trigger rerender
  const [suggestedPositionRef, setSuggestedPositionRef] =
    useState<SVGRectElement | null>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const suggestedPositionsLength = suggestedPositionsForValue?.length ?? 0;

  const setNext = useCallback(
    () =>
      setCurrentIndex(s =>
        Math.min(s + 1, Math.max(suggestedPositionsLength - 1, 0))
      ),
    [suggestedPositionsLength]
  );

  const setPrevious = useCallback(
    () => setCurrentIndex(s => Math.max(s - 1, 0)),
    []
  );

  const dispatch = useDispatch();

  const closeSuggestingPositions = useCallback(() => {
    dispatch(setIsSuggestingPositionsForValue(false));
  }, [dispatch]);

  const confirmSuggestion = useCallback(() => {
    const { rectangle: position, page } =
      suggestedPositionsForValue?.[currentIndex] || {};
    const currentDatapointIndex = currentDatapoint?.meta.index;

    if (!(position && isNumber(page) && isNumber(currentDatapointIndex))) {
      return;
    }

    dispatch(
      updatePosition(
        { index: currentDatapointIndex, page: { number: page } },
        {
          content: {
            position,
          },
        }
      )
    );
    dispatch(
      recountDatapointPosition(currentDatapointIndex, {
        oldValue:
          (currentDatapoint &&
            'content' in currentDatapoint &&
            currentDatapoint.content?.value) ||
          '',
        reason: 'suggested-position-for-value',
      })
    );
  }, [currentDatapoint, currentIndex, dispatch, suggestedPositionsForValue]);

  useEffect(() => {
    if (isSuggestingPositionsForValue && suggestedPositionsLength) {
      const subscription = onKeyDownObserver
        .pipe(
          map(event => {
            const key = keyboardKeys.find(
              handledKey => handledKey === event.key
            );
            return key ? { event, key, shiftKey: event.shiftKey } : undefined;
          }),
          filter(R.isDefined),
          // prevent to focus elements on the screen when Tab is pressed
          tap(({ event }) => prevent(event))
        )
        .subscribe(({ key, shiftKey }) => {
          const actions = {
            Tab: shiftKey ? setPrevious : setNext,
            Escape: closeSuggestingPositions,
            Enter: confirmSuggestion,
          } as const;

          actions[key]();
        });

      return () => {
        subscription.unsubscribe();
      };
    }

    return () => {};
  }, [
    onKeyDownObserver,
    isSuggestingPositionsForValue,
    suggestedPositionsLength,
    setNext,
    setPrevious,
    closeSuggestingPositions,
    confirmSuggestion,
  ]);

  useEffect(() => {
    return () => setCurrentIndex(0);
  }, [isSuggestingPositionsForValue]);

  const contextValue = useMemo(
    () => ({
      suggestedPositionRef,
      setSuggestedPositionRef,
      currentIndex,
      setCurrentIndex,
      setPreviousPosition: currentIndex !== 0 ? setPrevious : undefined,
      setNextPosition:
        currentIndex !== suggestedPositionsLength - 1 ? setNext : undefined,
      closeSuggestingPositions,
      confirmSuggestion,
    }),
    [
      closeSuggestingPositions,
      confirmSuggestion,
      currentIndex,
      setNext,
      setPrevious,
      suggestedPositionRef,
      suggestedPositionsLength,
    ]
  );

  useEffect(() => {
    if (suggestedPositionRef) {
      suggestedPositionRef.scrollIntoView({ behavior: 'smooth' });
    }
  }, [suggestedPositionRef]);

  return (
    <SuggestedPositionsContext.Provider value={contextValue}>
      {children}
    </SuggestedPositionsContext.Provider>
  );
};

export const useSuggestedPositionsContext = () => {
  const ctx = useContext(SuggestedPositionsContext);

  if (ctx === undefined) {
    throw new Error(
      '`useSuggestedPositionsContext` must be used within a SuggestedPositionsContext provider'
    );
  }

  return ctx;
};
