import { useMemo } from 'react';
import { SortingValue } from '../../../../types/user';
import {
  queueSortFns,
  workspaceSortFns,
  WorkspaceWithToReviewCount,
} from '../../helpers';

export const useSortWorkspacesWithQueues = ({
  sorting,
  workspaces,
}: {
  sorting: SortingValue;
  workspaces: WorkspaceWithToReviewCount[];
}) => {
  const sortedWorkspaces = useMemo(
    () =>
      workspaces
        ?.map(workspace => {
          const sortedQueues = workspace.queues.sort(queueSortFns[sorting]);

          return {
            ...workspace,
            queues: sortedQueues,
          };
        })
        ?.sort(workspaceSortFns[sorting]),

    [workspaces, sorting]
  );

  return {
    sortedWorkspaces,
  };
};
