import * as dateLocales from 'date-fns/locale';
import { LanguageCode } from 'humanize-duration';
import { mapValues } from 'lodash';
import {
  defaultLocaleKeys,
  isEmbedded,
  supportedLocales,
} from '../constants/config';
import cs from './cs/cs.json';
import embedded_cs from './cs/embedded_cs.json';
import embedded_da from './da/embedded_da.json';
import de from './de/de.json';
import embedded_de from './de/embedded_de.json';
import embedded_en from './en/embedded_en.json';
import en from './en/en.json';
import embedded_es from './es/embedded_es.json';
import es from './es/es.json';
import embedded_fi from './fi/embedded_fi.json';
import embedded_fr from './fr/embedded_fr.json';
import fr from './fr/fr.json';
import embedded_hr from './hr/embedded_hr.json';
import embedded_hu from './hu/embedded_hu.json';
import embedded_it from './it/embedded_it.json';
import embedded_ja from './ja/embedded_ja.json';
import ja from './ja/ja.json';
import embedded_nb from './nb/embedded_nb.json';
import embedded_nl from './nl/embedded_nl.json';
import embedded_pl from './pl/embedded_pl.json';
import embedded_pt from './pt/embedded_pt.json';
import embedded_pt_br from './pt_BR/embedded_pt_BR.json';
import pt_br from './pt_BR/pt_BR.json';
import embedded_sl from './sl/embedded_sl.json';
import embedded_sq from './sq/embedded_sq.json';
import embedded_sr from './sr/embedded_sr.json';
import embedded_sv from './sv/embedded_sv.json';
import embedded_tr from './tr/embedded_tr.json';
import embedded_cn from './zh_CN/embedded_zh_CN.json';
import cn from './zh_CN/zh_CN.json';

export const defaultLocales = { ...en, ...embedded_en };

export const getLocaleKey = (
  locale: string | undefined | null
): IntlLocale | undefined => {
  if (!locale) return undefined;

  const availableLocales = isEmbedded() ? supportedLocales : defaultLocaleKeys;
  return availableLocales.find(
    availableLocale => availableLocale.toLowerCase() === locale.toLowerCase()
  );
};

const localizationKeys = {
  main: Object.keys(en),
  embedded: Object.keys(embedded_en),
};

export const markValuesWithLineNumbers = (
  locales: Record<string, Record<string, string>>
) => {
  if (localStorage.getItem('localizationToolsEnabled') !== 'true') {
    return locales;
  }

  return mapValues(locales, l =>
    mapValues(l, (value, key) => {
      const findIn = (allKeys: string[], type: string) => {
        const index = allKeys.indexOf(key);
        if (index >= 0) {
          // line numbers are 1-based and first line is always "{", that's why +2
          return `[${type}${index + 2}] ${value}`;
        }
        return undefined;
      };

      return (
        findIn(localizationKeys.main, '') ??
        findIn(localizationKeys.embedded, 'E') ??
        value
      );
    })
  );
};

const translations = {
  en: defaultLocales,
  zh: {
    ...defaultLocales,
    ...cn,
    ...embedded_cn,
  },
  cs: {
    ...defaultLocales,
    ...cs,
    ...embedded_cs,
  },
  da: {
    ...defaultLocales,
    ...embedded_da,
  },
  de: {
    ...defaultLocales,
    ...de,
    ...embedded_de,
  },
  es: {
    ...defaultLocales,
    ...es,
    ...embedded_es,
  },
  fi: {
    ...defaultLocales,
    ...embedded_fi,
  },
  fr: {
    ...defaultLocales,
    ...fr,
    ...embedded_fr,
  },
  hr: {
    ...defaultLocales,
    ...embedded_hr,
  },
  hu: {
    ...defaultLocales,
    ...embedded_hu,
  },
  it: {
    ...defaultLocales,
    ...embedded_it,
  },
  ja: {
    ...defaultLocales,
    ...ja,
    ...embedded_ja,
  },
  nb: {
    ...defaultLocales,
    ...embedded_nb,
  },
  nl: {
    ...defaultLocales,
    ...embedded_nl,
  },
  pl: {
    ...defaultLocales,
    ...embedded_pl,
  },
  pt: {
    ...defaultLocales,
    ...embedded_pt,
  },
  'pt-br': {
    ...defaultLocales,
    ...pt_br,
    ...embedded_pt_br,
  },
  sl: {
    ...defaultLocales,
    ...embedded_sl,
  },
  sq: {
    ...defaultLocales,
    ...embedded_sq,
  },
  sr: {
    ...defaultLocales,
    ...embedded_sr,
  },
  sv: {
    ...defaultLocales,
    ...embedded_sv,
  },
  tr: {
    ...defaultLocales,
    ...embedded_tr,
  },
};

// custom mapping to our locale keys
export const dateFnsLocales = {
  ...dateLocales,
  en: dateLocales.enGB,
  zh: dateLocales.zhCN,
  'pt-br': dateLocales.ptBR,
};

export default translations;

export type LocaleKey = keyof typeof dateFnsLocales;
export type IntlLocale = keyof typeof translations;

export const humanizeDurationLocaleMap: Record<string, LanguageCode> = {
  zh: 'zh_CN',
  'pt-br': 'pt',
};

export type NeverTranslated<K extends string = '%'> = TranslationEnv["enabled"] extends false ? never : `missing:${K}`;
export type LocalizationKeys = TranslationEnv["enabled"] extends false ?
  | keyof (typeof defaultLocales) : NeverTranslated;
