// Generated by ts-to-zod
import { z } from 'zod';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const usersListQuerySchema = z
  .object({
    id: z.union([z.number(), z.array(z.number())]).optional(),
    organization: z.string().optional(),
    username: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    isActive: z.boolean().optional(),
    lastLogin: z.string().optional().nullable(),
    groups: z.array(z.number()).optional(),
    queues: z.array(z.string()).optional(),
    deleted: z.boolean().optional(),
  })
  .and(paginationQuerySchema);
