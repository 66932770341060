import { AnnotationListColumn } from '@rossum/api-client/shared';

export const SEARCH_VALUE_MIN_LENGTH = 3;

export const searchValueIsTooShort = (searchValue: string) =>
  searchValue.length < SEARCH_VALUE_MIN_LENGTH;

export type PanelColumn = AnnotationListColumn & {
  headerName?: string;
  isColumnFilteredOut?: boolean;
};
