import { Schema } from '@rossum/api-client/schemas';
import { Workspace } from '@rossum/api-client/workspaces';
import {
  Button,
  CircularProgress,
  DialogActions,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Queue } from '../../types/queue';
import { getPartId } from '../create-field/helpers';
import { Dialog } from '../ui/Dialog';
import SelectSchemaLocation from '../ui/SelectSchemaLocation';

const createWorkspaceOptions = (
  workspaces: Workspace[],
  queues: Queue[],
  schemas: Schema[]
) => {
  return workspaces
    .filter(workspace => workspace.queues.length > 0)
    .map(workspace => ({
      id: `workspaceId:${workspace.id}`,
      label: workspace.name,
      options: workspace.queues.flatMap(queueUrl => {
        const queue = queues.find(q => q.url === queueUrl);
        if (!queue) return [];

        const schema = schemas.find(s => s.url === queue.schema);
        if (!schema || !schema.content || schema.content.length === 0)
          return [];

        return [
          {
            id: `workspaceId:${workspace.id}.queueId:${queue.id}.schemaId:${schema.id}`,
            label: queue.name,
            options: schema.content.flatMap(content => {
              // To achieve the desired behaviour, on a schema option level the structure is flat
              const multivalues = content.children
                .filter(
                  child =>
                    child.category === 'multivalue' &&
                    child.children.category === 'tuple'
                )
                .map(multivalue => ({
                  id: `workspaceId:${workspace.id}.schemaId:${schema.id}.sectionId:${content.id}.multivalueId:${multivalue.id}`,
                  label: `${multivalue.label} (${multivalue.category === 'multivalue' ? 'line items' : multivalue.category})`,
                }));

              return [
                {
                  id: `workspaceId:${workspace.id}.schemaId:${schema.id}.sectionId:${content.id}`,
                  label: `${content.label} (${content.category})`,
                },
                ...multivalues,
              ];
            }),
          },
        ];
      }),
    }));
};

type AddFieldButtonWithDialogProps = {
  isLoading: boolean;
  workspaces: Workspace[];
  queues: Queue[];
  schemas: Schema[];
};

const AddFieldButtonWithDialog = ({
  isLoading,
  workspaces,
  queues,
  schemas,
}: AddFieldButtonWithDialogProps) => {
  const { push } = useHistory();
  const intl = useIntl();
  const { pathname } = useLocation();

  // Dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpening = useCallback(
    () => setDialogOpen(!dialogOpen),
    [dialogOpen]
  );

  const resetDialog = useCallback(() => {
    setSelectedItems([]);
    setExpandedItems([]);
    setDialogOpen(!dialogOpen);
  }, [dialogOpen]);

  // Location list
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const workspacesWithQueuesAndSchemas = useMemo(
    () => createWorkspaceOptions(workspaces, queues, schemas),
    [workspaces, queues, schemas]
  );

  const onSubmitDialog = useCallback(
    (selectedItem: string) => {
      const parentId =
        getPartId(selectedItem, 'multivalueId') ??
        getPartId(selectedItem, 'sectionId');
      const schemaId = getPartId(selectedItem, 'schemaId');
      push(`${pathname}/schema/${schemaId}/parent/${parentId}/create-field`);
    },
    [pathname, push]
  );

  return (
    <>
      <Button
        data-cy="fm-add-field-btn"
        variant="contained"
        disabled={isLoading}
        onClick={handleDialogOpening}
      >
        {intl.formatMessage({
          id: 'features.fieldManager.overview.addFieldButtonWithDialog.button.addField',
        })}
      </Button>
      <Dialog
        onClose={handleDialogOpening}
        open={dialogOpen}
        title={intl.formatMessage({
          id: 'features.fieldManager.overview.addFieldButtonWithDialog.title',
        })}
        actions={
          <DialogActions>
            <Button
              variant="outlined"
              onClick={resetDialog}
              color="secondary"
              data-cy="fm-select-location-cancel-btn"
            >
              {intl.formatMessage({
                id: 'features.fieldManager.overview.addFieldButtonWithDialog.button.cancel',
              })}
            </Button>
            <Button
              variant="contained"
              data-cy="fm-select-location-submit-btn"
              disabled={selectedItems.length === 0}
              onClick={() => {
                if (selectedItems[0]) {
                  onSubmitDialog(selectedItems[0]);
                }
              }}
            >
              {intl.formatMessage({
                id: 'features.fieldManager.overview.addFieldButtonWithDialog.button.selectLocation',
              })}
            </Button>
          </DialogActions>
        }
      >
        <Stack spacing={2}>
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'features.fieldManager.overview.addFieldButtonWithDialog.description',
            })}
          </Typography>
          <Stack sx={{ maxHeight: 'min(600px, 40vh)', overflow: 'auto' }}>
            {workspacesWithQueuesAndSchemas?.length > 0 ? (
              <SelectSchemaLocation
                options={workspacesWithQueuesAndSchemas}
                mode="single-select"
                onSelect={ids => setSelectedItems(ids)}
                selectedOptions={selectedItems}
                expandedOptions={expandedItems}
                setExpandedOptions={setExpandedItems}
              />
            ) : (
              <CircularProgress />
            )}
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default AddFieldButtonWithDialog;
