import { Alert, Button } from '@rossum/ui/material';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormSkeleton } from './components/skeletons/FormSkeleton';
import { TilesListSkeleton } from './components/skeletons/TilesListSkeleton';
import { useRouteResources } from './hooks/useRouteResources';
import { EngineAddPage, EngineDetailPage } from './pages/EngineDetailPage';
import { EngineListPage } from './pages/EngineListPage';
import { FieldAddPage, FieldDetailPage } from './pages/FieldDetailPage';
import { basePath, engineDetailPath } from './paths';

export const EngineRoutes = ({
  auroraConfigEnabled,
}: {
  auroraConfigEnabled: boolean;
}) => {
  const intl = useIntl();
  const { engine, field } = useRouteResources();

  // We are not using <Route /> tags because the hook lets us re-use the logic between sibling components.
  // TODO: Resolve redirects from invalid routes.

  // When aurora config is disabled, allow only `EngineListPage` without aurora engines.
  if (!auroraConfigEnabled) {
    return <EngineListPage />;
  }

  if (field.isInitialLoading) {
    return <FormSkeleton />;
  }

  if (engine.isInitialLoading) {
    return (
      <>
        <FormSkeleton />
        <TilesListSkeleton />
      </>
    );
  }

  if (field.isError || engine.isError) {
    const id = `features.engines.error.${
      engine.isError ? ('engineError' as const) : ('fieldError' as const)
    }` as const;

    return (
      <Alert
        variant="outlined"
        color="error"
        sx={{ my: 4 }}
        action={
          <Button
            component={Link}
            color="inherit"
            to={engine.isSuccess ? engineDetailPath(engine.data.id) : basePath}
          >
            {intl.formatMessage({ id: 'features.engines.error.back' })}
          </Button>
        }
      >
        {intl.formatMessage({ id })}
      </Alert>
    );
  }

  // Add engine
  if (engine.isCreating) {
    return <EngineAddPage />;
  }

  // Add field
  if (engine.isSuccess && field.isCreating) {
    return <FieldAddPage engine={engine.data} />;
  }

  // Field detail
  if (engine.isSuccess && field.isSuccess) {
    return <FieldDetailPage field={field.data} />;
  }

  // Engine detail
  if (engine.isSuccess) {
    return (
      <Fragment key={engine.data.id}>
        <EngineDetailPage engine={engine.data} />
      </Fragment>
    );
  }

  // Engine list
  return <EngineListPage auroraConfigEnabled />;
};
