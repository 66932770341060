import {
  CircularProgress,
  Drawer,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type PagePreviewDrawerProps = {
  previewImage: string | null | undefined;
  originalFileName: string | undefined;
  pageCount: number;
  isNested?: boolean;
};

const PREVIEW_DRAWER_WIDTH = 400;

const PagePreviewDrawer = ({
  previewImage,
  originalFileName,
  pageCount,
  isNested,
}: PagePreviewDrawerProps) => {
  const intl = useIntl();

  return (
    <Drawer
      sx={{
        position: 'static',
        pointerEvents: 'none',
      }}
      hideBackdrop
      PaperProps={{
        elevation: 2,
        sx: {
          right: isNested ? 800 : undefined,
          background: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
        },
      }}
      open
    >
      <Stack
        direction="column"
        alignItems="center"
        spacing={1}
        sx={{ width: PREVIEW_DRAWER_WIDTH, maxWidth: '100%' }}
      >
        {previewImage ? (
          <img
            src={previewImage}
            alt="Document preview"
            width="90%"
            data-cy="file-preview"
          />
        ) : (
          <CircularProgress />
        )}
        <Typography variant="body1" pt={2}>
          {originalFileName ?? ''}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage(
            { id: 'components.annotationOverview.pageCount' },
            { pageCount }
          )}
        </Typography>
      </Stack>
    </Drawer>
  );
};

export default PagePreviewDrawer;
