import { toNestError } from '@hookform/resolvers';
import { yupResolver } from '@hookform/resolvers/yup';
import { fromPairs, partition } from 'lodash';
import { FieldValues, Resolver, ResolverResult } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import { flattenWithPaths, isStringOrStringArray } from '../helpers';
import {
  ResolverContext,
  SchemaObjectFormData,
  schemaObjectSchema,
} from '../types';

type BackendErrorType = [
  string,
  {
    type: 'backend';
    message: string;
  },
];

const frontendResolver = (intl: IntlShape) =>
  yupResolver(schemaObjectSchema(intl));

export const schemaResolver =
  (intl: IntlShape): Resolver<SchemaObjectFormData, ResolverContext> =>
  (values, context, options) => {
    // What to do if context is not provided?
    if (!context) {
      return {
        values: {},
        errors: {},
      };
    }

    const { schemaObjectPath, rawValidationErrors, backendValidationStatus } =
      context;

    if (backendValidationStatus === 'never') {
      return frontendResolver(intl)(values, context, options) as Promise<
        ResolverResult<FieldValues>
      >;
    }

    const allErrorsWithPaths = flattenWithPaths(
      rawValidationErrors,
      isStringOrStringArray
    );

    if (allErrorsWithPaths.length === 0) {
      return { values, errors: {} };
    }

    const [localErrors, outsideErrors] = partition(
      allErrorsWithPaths,
      ([fieldPath]) => fieldPath.startsWith(`${schemaObjectPath}.`)
    );

    const remapPaths = (path: string) => {
      // Simple and table multivalue are separated in the form state,
      // but they use the same 'children' key in the API model
      if (values.fieldType === 'simple-multivalue') {
        return path.replace(/^children\./, 'simpleMultivalueChildren.');
      }
      return path;
    };

    // +1 ensures the relative path doesn't start with a dot
    const toRelativePath = (path: string) =>
      path.substring(schemaObjectPath.length + 1);

    const localErrorsWithRelativePath: BackendErrorType[] = localErrors.map(
      ([path, value]) => [
        remapPaths(toRelativePath(path)),
        {
          type: 'backend',
          message: typeof value === 'string' ? value : value[0],
        },
      ]
    );

    const [nonFieldErrors, fieldErrors] = partition(
      localErrorsWithRelativePath,
      path => !(options.names || []).includes(path[0])
    );

    return {
      values: {},
      errors: {
        // toNestError converts [path,error] pairs to the structure that matches the form state
        // This function is also used by yupResolver implementation
        ...toNestError(fromPairs(fieldErrors), options),
        // All other errors are mapped to a special nonFieldErrors field
        nonFieldErrors: {
          type: 'backend',
          message: '',
          types: fromPairs([
            ...nonFieldErrors.map(e => [e[0], e[1].message]),
            ...outsideErrors,
          ]),
        },
      },
    };
  };
