import { getSimpleFilterComponent } from '../componentMap';
import { isFilterAdvanced, isFilterSimple } from '../helpers';
import { FilterComponentProps, FilterItem } from '../types';
import { AdvancedFilterComponent } from './AdvancedFilterComponent';
import { DateTimePicker } from './DateTimePicker';
import { checkIfOperatorIsDate } from './DateTimePicker/utils';
import MissingValues from './MissingValues';

export const FilterComponentSelector = ({
  filterItem,
  ...filterProps
}: FilterComponentProps & { filterItem: FilterItem }) => {
  const isDateFilter = checkIfOperatorIsDate(filterItem.filterContext);
  const {
    column: { disableWithNoValues, areValuesLoading, valueOptions },
  } = filterItem;

  // TODO: @unified-dashboard
  // use if(isSuccess && valueOptions?.length === 0) as a condition here
  // send isSuccess to the columns instead of disableWithNoValues
  if (!areValuesLoading && disableWithNoValues && valueOptions?.length === 0) {
    return <MissingValues onBackClick={filterProps.onBackClick} />;
  }

  if (isDateFilter)
    return <DateTimePicker filterItem={filterItem} {...filterProps} />;

  if (isFilterSimple(filterItem)) {
    const SimpleFilterComponent = getSimpleFilterComponent(filterItem);
    return SimpleFilterComponent ? (
      <SimpleFilterComponent filterItem={filterItem} {...filterProps} />
    ) : null;
  }

  return isFilterAdvanced(filterItem) ? (
    <AdvancedFilterComponent filterItem={filterItem} {...filterProps} />
  ) : null;
};
