import { getIDFromUrl } from '@rossum/api-client';
import { EditRelation } from '@rossum/api-client/relations';
import { Link, SvgIcon, Tooltip } from '@rossum/ui/material';
import { MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { EditDocumentsRestrictor } from '../../components/Restrictors';
import { constructDocumentUrl } from '../../lib/url';
import { ReactComponent as SplitDone } from './icons/split-done.svg';

export const DocumentWasEdited = ({
  edit,
  onClick,
  childAnnotationId,
  dataCySuffix,
}: {
  edit: EditRelation;
  onClick?: MouseEventHandler;
  childAnnotationId: number;
  dataCySuffix: string;
}) => {
  const intl = useIntl();

  const editParentUrl = constructDocumentUrl({
    id: getIDFromUrl(edit.parent),
    nestedPath: '/edit',
    query: { from: childAnnotationId },
  });

  return (
    <EditDocumentsRestrictor>
      <Tooltip
        title={intl.formatMessage({
          id: 'containers.editDocument.documentWasEdited.tooltip',
        })}
        placement="top"
      >
        <div onClick={(...args) => onClick && onClick(...args)}>
          <Link
            href={editParentUrl}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'inherit',
              '&:hover': {
                color: 'inherit',
              },
            }}
            data-cy={`edited-document-indicator-${dataCySuffix}`}
          >
            <SvgIcon fontSize="small" component={SplitDone} color="inherit" />
          </Link>
        </div>
      </Tooltip>
    </EditDocumentsRestrictor>
  );
};
