import { useTheme } from '@rossum/ui/material';
import { Rectangle2DCoordinates } from '../utils/geometry';
import { BoundingBox } from './BoundingBox';

type SuggestedPositionForValueProps = {
  position: Rectangle2DCoordinates;
  current: boolean;
  setSuggestedPositionRef: (ref: SVGRectElement) => void;
  onClick: () => void;
};

export const SuggestedPositionForValue = ({
  position,
  current,
  onClick,

  setSuggestedPositionRef,
}: SuggestedPositionForValueProps) => {
  const theme = useTheme();
  return (
    <BoundingBox
      ref={current ? setSuggestedPositionRef : undefined}
      position={position}
      outlineStyle={current ? 'solid' : 'dashed'}
      outlineColor={theme.palette.aurora.main}
      interactive
      style={{ scrollMargin: '200px' }}
      onClick={onClick}
    />
  );
};
