// Generated by ts-to-zod
import { z } from 'zod';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';

export const suggestFormulaPayloadSchema = z.object({
  fieldSchemaId: z.string(),
  hint: z.string(),
  schemaContent: z.array(schemaSectionSchema),
});

export const suggestFormulaResultSchema = z.object({
  type: z.string(),
  name: z.string(),
  description: z.string(),
  formula: z.string(),
});

export const suggestFormulaResponseSchema = z.object({
  results: z.array(suggestFormulaResultSchema),
});
