import './styles.sass';
import humanizeDuration from 'humanize-duration';
import { every } from 'lodash';
import { ReactNode } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as TooltipComponent,
  XAxis,
  YAxis,
} from 'recharts';
import { fallbackLocale } from '../../constants/config';
import EmptySeries from '../../containers/Statistics/components/EmptySeries';
import { humanizeDurationLocaleMap } from '../../i18n/index';
import { toHumanString } from '../../lib/helpers';
import { getDurationUnit, limitUnits, shortenTimes } from '../../lib/timeUtils';
import { State } from '../../types/state';
import GraphsContainer from './components/GraphContainer';
import { colorPrimary, gray, grayLight, tickCount } from './constants';
import NumberTooltip from './Tooltips/NumberTooltip';
import TimeTooltip from './Tooltips/TimeTooltip';
import { GraphTooltipProps } from './Tooltips/types';
import { BarGraphDataKey } from './types';

type BarGraphDataValues = Partial<Record<BarGraphDataKey, number>>;
type DataType = BarGraphDataValues & { date: string };

export type OwnProps = {
  isTmpState: boolean;
  total: number;
  intl: IntlShape;
  data: Array<DataType>;
  children: ReactNode;
} & (
  | {
      name: Exclude<BarGraphDataKey, 'correctionsPerDocumentAvg'>;
      typeOfY: 'time';
    }
  | {
      name: Extract<BarGraphDataKey, 'correctionsPerDocumentAvg'>;
      typeOfY: 'number';
    }
);

type StateProps = {
  formatTimeShorten: (_time: number) => string;
  formatTime: (_time: number) => string;
};

type Props = StateProps & OwnProps;

const BarGraph = ({
  formatTime,
  children,
  formatTimeShorten,
  data,
  name,
  typeOfY,
  isTmpState,
}: Props) => {
  const displayPlaceholder = every(data, dataEl => dataEl[name] === 0);

  return (
    <GraphsContainer graphName={name}>
      <span style={{ opacity: displayPlaceholder ? 0.1 : 1 }}>{children}</span>
      {displayPlaceholder ? (
        <EmptySeries inGraph graphName={name} />
      ) : (
        <ResponsiveContainer height="70%">
          <BarChart data={data}>
            <CartesianGrid vertical={false} strokeWidth={0.3} />
            <XAxis tickSize={0} tickMargin={20} dataKey="date" />
            <YAxis
              tickFormatter={
                typeOfY === 'time' ? formatTimeShorten : toHumanString
              }
              tickSize={0}
              tickMargin={12}
              axisLine={false}
              dataKey={name}
              tickCount={tickCount}
            />
            {!isTmpState && (
              <TooltipComponent
                content={(props: GraphTooltipProps) =>
                  typeOfY === 'time' ? (
                    <TimeTooltip {...props} formatTime={formatTime} />
                  ) : (
                    <NumberTooltip {...props} name={name} />
                  )
                }
                cursor={{ fill: grayLight, opacity: 0.5 }}
              />
            )}
            <Bar
              barSize={32}
              fill={isTmpState ? gray : colorPrimary}
              isAnimationActive={false}
              dataKey={name}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </GraphsContainer>
  );
};

const mapStateToProps = (_: State, { intl, total }: OwnProps) => {
  const maxDurationUnit = getDurationUnit(total ?? 0);

  return {
    formatTimeShorten: shortenTimes(intl.locale, maxDurationUnit),
    formatTime: (seconds = 0) =>
      humanizeDuration(seconds * 1000, {
        largest: 2,
        round: true,
        language: intl.locale,
        fallbacks: [humanizeDurationLocaleMap[intl.locale], fallbackLocale],
        units: limitUnits(maxDurationUnit),
      }),
  };
};

export default injectIntl<'intl', OwnProps>(connect(mapStateToProps)(BarGraph));
