import { Store } from 'redux';
import { getDefaultLocale } from '../../../../../../constants/config';
import { defaultTheme } from '../../../../../../constants/values';
import { RootActionType } from '../../../../../../redux/rootActions';
import { State } from '../../../../../../types/state';

type ConfigAppProps = {
  onClose: () => void;
  setShouldRefetch: (shouldRefetch: boolean) => void;
  setShouldLeaveSafely: (shouldLeaveSafely: boolean) => void;
  store: Store<State, RootActionType>;
};

export class ConfigApp {
  #onClose: () => void;

  #setShouldRefetch: (shouldRefetch: boolean) => void;

  #setShouldLeaveSafely: (shouldLeaveSafely: boolean) => void;

  #store: Store<State, RootActionType>;

  constructor({
    store,
    onClose,
    setShouldRefetch,
    setShouldLeaveSafely,
  }: ConfigAppProps) {
    this.#onClose = onClose;
    this.#setShouldRefetch = setShouldRefetch;
    this.#setShouldLeaveSafely = setShouldLeaveSafely;
    this.#store = store;
  }

  getUiSettings = () => {
    const { user, organization } = this.#store.getState();

    return {
      theme:
        user.uiSettings.theme ?? organization.uiSettings?.theme ?? defaultTheme,
      locale: user.uiSettings.locale ?? getDefaultLocale(),
      branding: organization.uiSettings?.branding,
    };
  };

  finish = () => {
    this.#onClose();
    return { closed: true };
  };

  refetchOnClose = (shouldRefetch: boolean) => {
    this.#setShouldRefetch(shouldRefetch);
    return { shouldRefetch };
  };

  setShouldLeaveSafely = (shouldLeaveSafely: boolean) => {
    this.#setShouldLeaveSafely(shouldLeaveSafely);
    return { shouldLeaveSafely };
  };
}
