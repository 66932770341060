import { FilterMultiSelect } from './components/FilterMultiSelect';
import { FilterMultiString } from './components/FilterMultiString';
import { FilterQueueSelect } from './components/FilterQueueSelect';
import {
  FilterContains,
  FilterStartsWith,
  FilterTextField,
} from './components/FilterTextField';
import { FilterUserSelect } from './components/FilterUserSelect';
import { FilterOperator } from './operators';
import { SimpleFilterComponentProps, SimpleFilterItem } from './types';

type ComponentNames = FilterOperator['component'];

type FilterComponent = (
  props: SimpleFilterComponentProps
) => JSX.Element | null;

export const simpleFilterComponentMap: Partial<
  Record<ComponentNames, FilterComponent>
> = {
  filterMultiSelect: FilterMultiSelect,
  filterUserSelect: FilterUserSelect,
  filterTextField: FilterTextField,
  filterQueueSelect: FilterQueueSelect,
  filterStartsWith: FilterStartsWith,
  filterMultiString: FilterMultiString,
  filterContains: FilterContains,
};

export const getSimpleFilterComponent = (filterItem: SimpleFilterItem) => {
  const { column, filterContext } = filterItem;

  const componentKey = column.operators?.find(
    op => filterContext.operator.value === op.value
  )?.component;

  return componentKey ? simpleFilterComponentMap[componentKey] : null;
};
