import { Relation } from '@rossum/api-client/relations';
import { take, uniq } from 'lodash';
import { getAnnotationId } from '../../lib/url';
import { SnakeCaseStatus } from '../../types/annotationStatus';
import { Url } from '../../types/basic';

const MAX_ANNOTATIONS_COUNT = 100;

export const isProperDuplicateRelation =
  (annotationUrl: Url) =>
  (relation: Relation): boolean => {
    // Checks whether duplicates haven't been purged.
    const isEmptyDuplicate = (annotations: Url[]) =>
      annotations.length === 1 && annotations[0] === annotationUrl;
    const { type, annotations } = relation;
    return type === 'duplicate' && !isEmptyDuplicate(annotations);
  };

export const getDuplicatesQuery = (relations: Array<Relation>) => {
  const annotationIds = take(
    uniq(relations.flatMap(r => r.annotations)),
    MAX_ANNOTATIONS_COUNT + 1
  ).map(annotation => getAnnotationId(annotation));

  return {
    pageSize: MAX_ANNOTATIONS_COUNT + 1,
    sideload: ['documents' as const, 'modifiers' as const],
    id: annotationIds,
  };
};

export const getCurrentRelationShape = <
  A extends {
    url: string;
    modifier: string | null;
    id: number;
    createdAt: string;
    queue: string;
    status: SnakeCaseStatus;
  },
  U extends { url: string; id: number; username?: string },
  D extends { annotations: Array<string>; originalFileName: string | null },
>(
  {
    results,
    documents,
    modifiers,
  }: { results: Array<A>; modifiers: Array<U>; documents: Array<D> },
  urls: Array<string>
) => {
  const bigRelation = results.length > MAX_ANNOTATIONS_COUNT;

  const _annotations = take(results, MAX_ANNOTATIONS_COUNT).map(annotation => ({
    ...annotation,
    document: documents.find(
      ({ annotations }) => annotations[0] === annotation.url
    ),
    modifier: modifiers.find(({ url }) => url === annotation.modifier) || {
      id: undefined,
      username: undefined,
    },
  }));

  return {
    bigRelation,
    annotations: _annotations,
    urls,
  };
};

export const resolveAnnotationDuplicates = (
  annotationUrl: Url,
  annotationRelations: Array<Url>,
  relations: Array<Relation>
) =>
  annotationRelations.filter(relation => {
    return relations.some(
      relationData =>
        relationData.url === relation &&
        isProperDuplicateRelation(annotationUrl)(relationData)
    );
  });
