import {
  chain,
  compact,
  findIndex,
  findLastIndex,
  last,
  mergeWith,
  uniq,
} from 'lodash';
import { combineEpics } from 'redux-observable';
import { EMPTY, interval, merge, of } from 'rxjs';
import {
  buffer,
  catchError,
  debounce,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';
import { getType } from 'typesafe-actions';
import { errorHandler } from '../../../lib/api';
import {
  Grid,
  MultivalueDatapointData,
  MultivalueDatapointDataST,
  RowPosition,
} from '../../../types/datapoints';
import { addSchemaToTreeDatapointsHelper } from '../datapoints/helpers';
import { flattenDatapoints } from '../datapoints/typedHelpers';
import { createGridModel } from '../magicGrid/helpers';
import { getGridSchema } from '../magicGrid/selector';
import { setGridActionInProgress } from '../ui/actions';
import { complexLineItemsEnabledSelector } from '../ui/selectors';
import { isActionOf, makeEpic } from '../utils';
import {
  applyColumnsToAllGrids,
  applyGridToNextPages,
  createGrid,
  createGridFulfilled,
  deleteAllGrids,
  deleteGrid,
  deleteGridFulfilled,
  pasteGrid,
  pasteGridToPage,
  updateGridAction,
  updateGridAfterColumnsCleared,
  updateGridAfterExtractAllRows,
  updateGridAfterHorizontalSeparatorCreated,
  updateGridAfterHorizontalSeparatorDeleted,
  updateGridAfterHorizontalSeparatorMove,
  updateGridAfterMoved,
  updateGridAfterMovedFulfilled,
  updateGridAfterResized,
  updateGridAfterRowsCleared,
  updateGridAfterRowTypeChanged,
  updateGridAfterSchemaIdAssigned,
  updateGridAfterVerticalSeparatorDeleted,
  updateGridAfterVerticalSeparatorMove,
  updateGridFulfilled,
  UpdateGridRows,
  upgradeGridAfterVerticalSeparatorCreated,
} from './actions';
import {
  findGridIndex,
  getAllDatapointIds,
  getCreatedDatapointIds,
  getCreatedTuples,
  getTupleIds,
  getUpdatedDatapointIds,
  getUpdatedDatapointIdsForColumns,
  getUpdatedTuples,
} from './helpers';

// Partial Grid Operations
// https://elis.develop.r8.lol/api/docs/internal/#partial-grid-updates

const updateGridEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(updateGridAction)),
    map(action => {
      const {
        meta: { datapointIndex, gridIndex, actionType },
        payload: { grid, rows, columns, datapoints },
      } = action;

      const { id } = state$.value.datapoints.content[
        datapointIndex
      ] as MultivalueDatapointDataST & {
        url: string;
      };

      const updatedDatapointIds = chain([
        ...datapoints.updatedIds,
        ...datapoints.clearedIds,
        datapoints.deletedTupleIds.length && id,
      ])
        .compact()
        .thru(ids =>
          ids.length ? [...ids, ...datapoints.updatedTupleIds] : ids
        )
        .value();

      return {
        grid,
        gridIndex,
        datapointIndex,
        rows,
        datapoints,
        updatedDatapointIds,
        actionType,
        columns,
      };
    }),
    buffer(
      action$.pipe(
        filter(isActionOf(updateGridAction)),
        debounce(({ meta: { actionType } }) =>
          actionType ? interval(700) : interval(1)
        )
      )
    ),
    map(multiData => {
      // we know `multiData` is not empty here because of the `buffer` ^
      const { grid, gridIndex, datapointIndex } = last(multiData)!;

      const { url } = state$.value.datapoints.content[
        datapointIndex
      ] as MultivalueDatapointDataST & {
        url: string;
      };

      const batchOperation = multiData.length > 1;

      const gridOperationUrl = `${url}/grid_parts_operations`;

      const rows = multiData.reduce<Omit<UpdateGridRows, 'deletedIndexes'>>(
        (acc, { rows }) => {
          const createdIndexes = acc.createdIndexes.map(createdIndex =>
            rows.createdIndexes.some(index => index <= createdIndex)
              ? createdIndex + 1
              : createdIndex
          );

          const updatedIndexes = acc.updatedIndexes.map(updatedIndex =>
            rows.createdIndexes.some(index => index <= updatedIndex)
              ? updatedIndex + 1
              : updatedIndex
          );

          return {
            createdIndexes: [...createdIndexes, ...rows.createdIndexes],
            updatedIndexes: uniq(
              [...updatedIndexes, ...rows.updatedIndexes].filter(
                index =>
                  ![...createdIndexes, ...rows.createdIndexes].some(
                    createdIndex => index === createdIndex
                  )
              )
            ),
          };
        },
        { createdIndexes: [], updatedIndexes: [] }
      );

      const updatedDatapointIds = uniq(
        multiData.flatMap(({ updatedDatapointIds }) => updatedDatapointIds)
      );

      const datapoints = multiData.reduce(
        (acc, { datapoints }) =>
          mergeWith(acc, datapoints, (objValue, sourceValue) => [
            ...objValue,
            ...sourceValue,
          ]),
        {
          clearedIds: [],
          deletedTupleIds: [],
          updatedIds: [],
          updatedTupleIds: [],
        }
      );

      const operations = {
        rows: [
          ...rows.updatedIndexes.map(rowIndex => ({
            op: 'update',
            rowIndex,
            tupleId: grid.rows[rowIndex].tupleId,
          })),
          ...datapoints.deletedTupleIds.map(tupleId => ({
            op: 'delete',
            tupleId,
          })),
          ...rows.createdIndexes.map(rowIndex => ({ op: 'create', rowIndex })),
        ],
        // There is no batch updates for columns now -> there is only one item array
        columns: [
          ...multiData[0].columns.updatedIds.map(schemaId => ({
            op: 'update',
            schemaId,
          })),
          ...multiData[0].columns.clearedIds.map(schemaId => ({
            op: 'delete',
            schemaId,
          })),
        ],
      };

      return {
        gridOperationUrl,
        grid,
        gridIndex,
        operations,
        datapointIndex,
        rows,
        updatedDatapointIds,
        batchOperation,
      };
    }),
    mergeMap(
      ({
        gridOperationUrl,
        grid,
        gridIndex,
        operations,
        datapointIndex,
        rows,
        updatedDatapointIds,
        batchOperation,
      }) =>
        merge(
          // to set loading state while the request is in flight, otherwise invalid
          // states are reachable
          of(setGridActionInProgress(true)),
          authPost$<MultivalueDatapointData>(
            gridOperationUrl,
            {
              grid,
              gridIndex,
              operations,
            },
            { query: { fullResponse: batchOperation } }
          ).pipe(
            map(payload => {
              const datapoints = addSchemaToTreeDatapointsHelper(
                state$.value.schema.content ?? [],
                [payload]
              ) as unknown as MultivalueDatapointData[];

              const createdDatapointIds = rows.createdIndexes.length
                ? getCreatedDatapointIds(
                    state$.value.datapoints,
                    datapointIndex,
                    gridIndex,
                    datapoints
                  )
                : [];

              const createdTupleIds = rows.createdIndexes.length
                ? getCreatedTuples(
                    state$.value.datapoints,
                    datapointIndex,
                    gridIndex,
                    datapoints
                  ).map(({ id }) => id)
                : [];

              return updateGridFulfilled({
                datapoints,
                updatedDatapointIds: compact([
                  ...updatedDatapointIds,
                  ...createdDatapointIds,
                ]),
                createdTupleIds,
                gridIndex,
                fullResponse: batchOperation,
              });
            }),
            catchError(errorHandler)
          )
        )
    )
  )
);

// Grid Operations
// https://elis.develop.r8.lol/api/docs/internal/#grid-operations

const updateGridAfterMovedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterMoved)),
    map(action => {
      const {
        payload: { grid },
        meta: { datapointIndex },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      if (gridIndex === undefined) {
        return EMPTY;
      }

      const updatedDatapointIds = getAllDatapointIds(
        state$.value.datapoints,
        action
      );

      return updateGridAfterMovedFulfilled({
        gridIndex,
        grid,
        datapointIndex,
        updatedDatapointIds,
      });
    })
  )
);

const updateGridAfterMovedFulfilledEpic = makeEpic(
  (action$, state$, { authPost$ }) =>
    action$.pipe(
      filter(isActionOf(updateGridAfterMovedFulfilled)),
      mergeMap(action => {
        const {
          payload: { grid },
          meta: { datapointIndex, gridIndex },
        } = action;

        const { url } = state$.value.datapoints.content[
          datapointIndex
        ] as MultivalueDatapointDataST & {
          url: string;
        };

        const gridOperationUrl = `${url}/grid_operations`;
        const operations = [{ op: 'update', grid, gridIndex }];

        return authPost$<MultivalueDatapointData>(gridOperationUrl, {
          operations,
        }).pipe(
          map(payload => {
            const datapoints = addSchemaToTreeDatapointsHelper(
              state$.value.schema.content ?? [],
              [payload]
            );

            const updatedDatapointIds = flattenDatapoints(
              {
                content: datapoints,
              },
              complexLineItemsEnabledSelector(state$.value)
            ).content.map(({ id }) => id);

            return updateGridFulfilled({
              datapoints,
              updatedDatapointIds,
            });
          }),
          catchError(errorHandler)
        );
      })
    )
);

const createGridEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(createGrid)),
    map(
      ({ payload: { rectangle, rowType }, meta: { datapointIndex, page } }) => {
        const grid = createGridModel(
          page,
          rectangle,
          (state$.value.bbox[page] ?? []).filter(
            ({ rectangle: [, bTop] }) =>
              bTop >= rectangle[1] && bTop <= rectangle[1] + rectangle[3]
          ),
          rowType
        );

        const dp = state$.value.datapoints.content[datapointIndex];

        if (!dp || dp.category !== 'multivalue') {
          return EMPTY;
        }

        const existingGridParts: Grid[] = dp.grid?.parts ?? [];

        // create new grid on correct index to preserve their order
        const gridIndex =
          findLastIndex(existingGridParts, part => part.page < page) + 1;

        return createGridFulfilled({ datapointIndex, gridIndex, grid });
      }
    )
  )
);

const createGridFulfilledEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(createGridFulfilled)),
    mergeMap(({ payload: { grid }, meta: { gridIndex, datapointIndex } }) => {
      const { url } = state$.value.datapoints.content[
        datapointIndex
      ] as MultivalueDatapointDataST & {
        url: string;
      };

      const gridOperationUrl = `${url}/grid_operations`;
      const operations = [{ op: 'create', grid, gridIndex }];

      return authPost$<MultivalueDatapointData>(gridOperationUrl, {
        operations,
      }).pipe(
        map(payload => {
          const datapoints = addSchemaToTreeDatapointsHelper(
            state$.value.schema.content ?? [],
            [payload]
          );

          const updatedDatapointIds = flattenDatapoints(
            {
              content: datapoints,
            },
            complexLineItemsEnabledSelector(state$.value)
          ).content.map(({ id }) => id);

          const createdTupleIds =
            compact(
              payload.grid?.parts[0]?.rows.map(
                ({ tupleId }: RowPosition) => tupleId
              )
            ) ?? [];

          return updateGridFulfilled({
            datapoints,
            updatedDatapointIds,
            createdTupleIds,
            gridIndex,
          });
        }),
        catchError(errorHandler)
      );
    })
  )
);

const deleteGridEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteGrid)),
    map(action => {
      const {
        meta: { datapointIndex, page },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      if (gridIndex === undefined) {
        return EMPTY;
      }

      return deleteGridFulfilled({ datapointIndex, page, gridIndex });
    })
  )
);

const deleteGridFulfilledEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(deleteGridFulfilled)),
    mergeMap(action => {
      const {
        meta: { datapointIndex, gridIndex },
      } = action;

      const { url, id } = state$.value.datapoints.content[
        datapointIndex
      ] as MultivalueDatapointDataST & {
        url: string;
      };

      const operations = [{ op: 'delete', gridIndex }];

      const gridOperationUrl = `${url}/grid_operations`;

      return authPost$(gridOperationUrl, { operations }).pipe(
        map(payload => {
          const datapoints = addSchemaToTreeDatapointsHelper(
            state$.value.schema.content ?? [],
            [payload as MultivalueDatapointData]
          );

          return updateGridFulfilled({
            datapoints,
            updatedDatapointIds: [id],
          });
        }),
        catchError(errorHandler)
      );
    })
  )
);

const deleteAllGridsEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(deleteAllGrids)),
    mergeMap(action => {
      const {
        meta: { datapointIndex, gridCount },
      } = action;

      const { url, id } = state$.value.datapoints.content[
        datapointIndex
      ] as MultivalueDatapointDataST & {
        url: string;
      };

      const gridOperationUrl = `${url}/grid_operations`;

      // operations are applied sequentially so we want to delete the first one N times
      const operations = Array(gridCount).fill({
        op: 'delete',
        gridIndex: 0,
      });

      return authPost$(gridOperationUrl, { operations }).pipe(
        mergeMap(payload => {
          const datapoints = addSchemaToTreeDatapointsHelper(
            state$.value.schema.content ?? [],
            [payload as MultivalueDatapointData]
          );

          return of(
            updateGridFulfilled({
              datapoints,
              updatedDatapointIds: [id],
            })
          );
        }),
        catchError(errorHandler)
      );
    })
  )
);

const applyGridToNextPagesEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(applyGridToNextPages)),
    mergeMap(action => {
      const {
        meta: { page, datapointIndex, grids },
      } = action;

      const datapoint = state$.value.datapoints.content[datapointIndex];

      const grid = grids.find(({ page: gridPage }) => gridPage === page);

      if (!grid || !datapoint || datapoint.category !== 'multivalue') {
        return EMPTY;
      }

      const removedIndexes = compact(
        grids.map(({ page: gridPage }, index) =>
          gridPage > page ? index : null
        )
      ).sort((a, b) => a - b);

      const pagesCount = state$.value.annotation.pages?.length;

      if (pagesCount === undefined) {
        return EMPTY;
      }

      const deleteOperations = removedIndexes.map((gridIndex, index) => ({
        op: 'delete',
        gridIndex: gridIndex - index,
      }));

      const newGridIndex = grids.length - 1 - removedIndexes.length;

      const createOperations = Array(pagesCount - grid.page)
        .fill(1)
        .map((_, index) => ({
          op: 'create',
          grid: {
            ...grid,
            page: grid.page + index + 1,
          },
          gridIndex: newGridIndex + index + 1,
        }));

      const gridOperationUrl = `${datapoint.url}/grid_operations`;

      return authPost$<MultivalueDatapointData>(gridOperationUrl, {
        operations: [...deleteOperations, ...createOperations],
      }).pipe(
        map(payload => {
          const datapoints = addSchemaToTreeDatapointsHelper(
            state$.value.schema.content ?? [],
            [payload]
          );

          const updatedDatapointIds = flattenDatapoints(
            {
              content: datapoints,
            },
            complexLineItemsEnabledSelector(state$.value)
          ).content.map(({ id }) => id);

          const createdTupleIds =
            payload.grid?.parts?.flatMap(grid =>
              compact(grid.rows.map(({ tupleId }: RowPosition) => tupleId))
            ) ?? [];

          return updateGridFulfilled({
            datapoints,
            updatedDatapointIds,
            createdTupleIds,
          });
        }),
        catchError(errorHandler)
      );
    })
  )
);

const pasteGridToPageEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(pasteGridToPage)),
    map(action => {
      const {
        meta: { datapointIndex, page },
      } = action;

      const datapoint = state$.value.datapoints.content[
        datapointIndex
      ] as MultivalueDatapointDataST;

      // potential existing grid on the same page
      const existingGridIndex =
        datapoint.grid?.parts?.findIndex(
          ({ page: gridPage }) => gridPage === page
        ) ?? -1;

      return pasteGrid({ datapointIndex, page, existingGridIndex });
    })
  )
);

const pasteGridEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(pasteGrid)),
    mergeMap(action => {
      const {
        meta: { datapointIndex, page, existingGridIndex },
      } = action;

      const datapoint = state$.value.datapoints.content[datapointIndex];

      if (
        !datapoint ||
        datapoint.category !== 'multivalue' ||
        !datapoint.grid
      ) {
        return EMPTY;
      }

      const gridOperationUrl = `${datapoint.url}/grid_operations`;

      // grid we're pasting
      const newGrid = state$.value.datapoints.gridClipboard;

      const deleteOperations =
        existingGridIndex > -1
          ? [
              {
                op: 'delete',
                gridIndex: existingGridIndex,
              },
            ]
          : [];

      const createOperations = [
        {
          op: 'create',
          grid: {
            ...newGrid,
            page,
          },
          gridIndex: datapoint.grid.parts.length,
        },
      ];

      return authPost$<MultivalueDatapointData>(gridOperationUrl, {
        operations: [...deleteOperations, ...createOperations],
      }).pipe(
        map(payload => {
          const datapoints = addSchemaToTreeDatapointsHelper(
            state$.value.schema.content ?? [],
            [payload]
          );

          const updatedDatapointIds = flattenDatapoints(
            {
              content: datapoints,
            },
            complexLineItemsEnabledSelector(state$.value)
          ).content.map(({ id }) => id);

          // This is grid coming from server response so `tupleId` probably shouldn't be null?
          const createdTupleIds =
            payload.grid?.parts?.flatMap(grid =>
              compact(grid.rows.map(({ tupleId }: RowPosition) => tupleId))
            ) ?? [];

          return updateGridFulfilled({
            datapoints,
            updatedDatapointIds,
            createdTupleIds,
            gridIndex: datapoint.grid?.parts?.length ?? 0,
          });
        }),
        catchError(errorHandler)
      );
    })
  )
);

const updateGridAfterColumnsClearedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterColumnsCleared)),
    map(action => {
      const {
        payload: { grid },
        meta: { datapointIndex },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      if (gridIndex === undefined) {
        return EMPTY;
      }

      const clearedColumnIds = compact(grid.columns.map(col => col.schemaId));

      const clearedSimpleDatapointIds = clearedColumnIds.length
        ? getUpdatedDatapointIdsForColumns(
            state$.value.datapoints,
            action
          )(clearedColumnIds)
        : [];

      const updatedTupleIds = getTupleIds(grid);

      return updateGridAction({
        datapointIndex,
        grid: {
          ...grid,
          columns: [{ ...grid.columns[0], schemaId: null }],
        },
        gridIndex,
        columns: { clearedIds: clearedColumnIds },
        datapoints: { updatedTupleIds, clearedIds: clearedSimpleDatapointIds },
      });
    })
  )
);

const updateGridAfterRowsClearedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterRowsCleared)),
    map(action => {
      const {
        payload: { grid },
        meta: { datapointIndex },
      } = action;

      const { rowTypesToExtract } = getGridSchema(state$.value);

      const oldRowType = grid.rows[0].type;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      if (gridIndex === undefined) {
        return EMPTY;
      }

      const deletedIndexes = grid.rows.map((_row, index) => index).slice(1);

      const deletedTupleIds = getUpdatedTuples(
        state$.value.datapoints,
        action,
        { rowIndexes: deletedIndexes }
      ).map(tuple => tuple.id);

      // only update row if it's type is extractable
      const updatedTupleIds = getUpdatedTuples(
        state$.value.datapoints,
        action,
        {
          rowIndexes:
            typeof oldRowType === 'string' &&
            rowTypesToExtract.includes(oldRowType)
              ? [0]
              : [],
        }
      ).map(tuple => tuple.id);

      const updatedSimpleDatapointIds = getUpdatedDatapointIds(
        state$.value.datapoints,
        action,
        {
          rowIndexes:
            typeof oldRowType === 'string' &&
            rowTypesToExtract.includes(oldRowType)
              ? [0]
              : [],
        }
      );

      return updateGridAction({
        datapointIndex,
        grid: { ...grid, rows: [{ ...grid.rows[0] }] },
        gridIndex,
        rows: {
          deletedIndexes,
          updatedIndexes:
            typeof oldRowType === 'string' &&
            rowTypesToExtract.includes(oldRowType)
              ? [0]
              : [],
        },
        datapoints: {
          updatedTupleIds,
          updatedIds: updatedSimpleDatapointIds,
          deletedTupleIds,
        },
      });
    })
  )
);

// Grid events handlers

const updateGridAfterVerticalSeparatorMoveEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterVerticalSeparatorMove)),
    map(action => {
      const {
        payload: { grid },
        meta: { separatorIndex, datapointIndex },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      if (gridIndex === undefined) {
        return EMPTY;
      }

      const updatedColumnIds = compact([
        grid.columns[separatorIndex].schemaId,
        grid.columns[separatorIndex - 1].schemaId,
      ]);

      const updatedSimpleDatapointIds = updatedColumnIds.length
        ? getUpdatedDatapointIdsForColumns(
            state$.value.datapoints,
            action
          )(updatedColumnIds)
        : [];

      const updatedTupleIds = getTupleIds(action.payload.grid);

      return updateGridAction({
        datapointIndex,
        grid,
        gridIndex,
        columns: { updatedIds: updatedColumnIds },
        datapoints: { updatedIds: updatedSimpleDatapointIds, updatedTupleIds },
      });
    })
  )
);

const updateGridAfterVerticalSeparatorDeletedEpic = makeEpic(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(updateGridAfterVerticalSeparatorDeleted)),
      map(action => {
        const {
          payload: { grid },
          meta: { separatorIndex, datapointIndex },
        } = action;

        const gridIndex = findGridIndex(state$.value.datapoints, action);

        const dp = state$.value.datapoints.content[datapointIndex];

        if (
          gridIndex === undefined ||
          !dp ||
          dp.category !== 'multivalue' ||
          !dp.grid?.parts?.length
        ) {
          return EMPTY;
        }

        const deletedColumnId =
          dp.grid.parts[gridIndex].columns[separatorIndex].schemaId;

        const clearedIds = compact([deletedColumnId]);
        const updatedIds = compact([grid.columns[separatorIndex - 1].schemaId]);

        const updatedTupleIds = getTupleIds(action.payload.grid);

        const clearedSimpleDatapointIds = deletedColumnId
          ? getUpdatedDatapointIdsForColumns(
              state$.value.datapoints,
              action
            )([deletedColumnId])
          : [];

        const updatedSimpleDatapointIds = updatedIds.length
          ? getUpdatedDatapointIdsForColumns(
              state$.value.datapoints,
              action
            )([updatedIds[0]])
          : [];

        return updateGridAction({
          grid,
          gridIndex,
          datapointIndex,
          columns: { clearedIds, updatedIds },
          datapoints: {
            updatedTupleIds,
            clearedIds: clearedSimpleDatapointIds,
            updatedIds: updatedSimpleDatapointIds,
          },
        });
      })
    )
);

const upgradeGridAfterVerticalSeparatorCreatedEpic = makeEpic(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(upgradeGridAfterVerticalSeparatorCreated)),
      map(action => {
        const {
          payload: { grid },
          meta: { newPosition, datapointIndex },
        } = action;

        const newIndex = findIndex(
          grid.columns,
          ({ leftPosition }) => leftPosition === newPosition
        );

        const gridIndex = findGridIndex(state$.value.datapoints, action);

        if (gridIndex === undefined) {
          return EMPTY;
        }

        const updatedColumnId = grid.columns[newIndex - 1].schemaId;

        const updatedSimpleDatapointIds = updatedColumnId
          ? getUpdatedDatapointIdsForColumns(
              state$.value.datapoints,
              action
            )([updatedColumnId])
          : [];

        return updateGridAction({
          grid,
          gridIndex,
          datapointIndex,
          columns: { updatedIds: compact([updatedColumnId]) },
          datapoints: {
            updatedTupleIds: getTupleIds(grid),
            updatedIds: updatedSimpleDatapointIds,
          },
        });
      })
    )
);

const updateGridAfterSchemaIdAssignedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterSchemaIdAssigned)),
    map(action => {
      const {
        payload: { grid },
        meta: { changes, datapointIndex },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      if (gridIndex === undefined) {
        return EMPTY;
      }

      const updatedColumnId = changes[0].current;
      const removedColumnId = changes[0].prev;

      const updatedSimpleDatapointIds = updatedColumnId
        ? getUpdatedDatapointIdsForColumns(
            state$.value.datapoints,
            action
          )([updatedColumnId])
        : [];

      const removedDatapointIds = removedColumnId
        ? getUpdatedDatapointIdsForColumns(
            state$.value.datapoints,
            action
          )([removedColumnId])
        : [];

      const updatedTupleIds = getTupleIds(action.payload.grid);

      return updateGridAction({
        datapointIndex,
        grid,
        gridIndex,
        columns: {
          clearedIds: compact([removedColumnId]),
          updatedIds: compact([updatedColumnId]),
        },
        datapoints: {
          clearedIds: removedDatapointIds,
          updatedIds: updatedSimpleDatapointIds,
          updatedTupleIds,
        },
      });
    })
  )
);

const udpateGridAfterHorizontalSeparatorDeleted = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterHorizontalSeparatorDeleted)),
    map(action => {
      const {
        payload: { grid },
        meta: { separatorIndex, datapointIndex },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      const dp = state$.value.datapoints.content[datapointIndex];

      if (
        gridIndex === undefined ||
        !dp ||
        dp.category !== 'multivalue' ||
        !dp.grid?.parts?.length
      ) {
        return EMPTY;
      }

      const currentGrid = dp.grid.parts[gridIndex];

      if (!currentGrid) {
        return EMPTY;
      }

      const deletedRowType = currentGrid.rows[separatorIndex].type;

      const { rowTypesToExtract } = getGridSchema(state$.value);

      const deletedIndexes =
        typeof deletedRowType === 'string' &&
        rowTypesToExtract.includes(deletedRowType)
          ? [separatorIndex]
          : [];

      const deletedTupleIds = deletedIndexes.length
        ? [currentGrid.rows[separatorIndex].tupleId]
        : [];

      const prevRowTypeIsExtracted =
        typeof currentGrid.rows[separatorIndex - 1].type === 'string' &&
        rowTypesToExtract.includes(currentGrid.rows[separatorIndex - 1].type!);

      const updatedIndexes = prevRowTypeIsExtracted ? [separatorIndex - 1] : [];

      const updatedSimpleDatapointIds = updatedIndexes.length
        ? getUpdatedDatapointIds(state$.value.datapoints, action, {
            rowIndexes: updatedIndexes,
          })
        : [];

      const updatedTupleIds = getTupleIds(currentGrid);

      return updateGridAction({
        rows: { deletedIndexes, updatedIndexes },
        datapointIndex,
        datapoints: {
          deletedTupleIds,
          updatedIds: updatedSimpleDatapointIds,
          updatedTupleIds,
        },
        grid,
        gridIndex,
      });
    })
  )
);

const updateGridAfterHorizontalSeparatorCreatedEpic = makeEpic(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(updateGridAfterHorizontalSeparatorCreated)),
      map(action => {
        const {
          payload: { grid },
          meta: { newPosition, datapointIndex },
        } = action;

        const newIndex = findIndex(
          grid.rows,
          ({ topPosition }) => topPosition === newPosition
        );

        const gridIndex = findGridIndex(state$.value.datapoints, action);

        if (gridIndex === undefined) {
          return EMPTY;
        }

        const { rowTypesToExtract } = getGridSchema(state$.value);
        const previousRowType = grid.rows[newIndex - 1].type;
        const rowType = grid.rows[newIndex].type;

        const createdIndexes =
          typeof rowType === 'string' && rowTypesToExtract.includes(rowType)
            ? [newIndex]
            : [];

        const updatedIndexes =
          typeof previousRowType === 'string' &&
          rowTypesToExtract.includes(previousRowType)
            ? [newIndex - 1]
            : [];

        const updatedSimpleDatapointIds = updatedIndexes.length
          ? getUpdatedDatapointIds(state$.value.datapoints, action, {
              rowIndexes: updatedIndexes,
            })
          : [];

        const updatedTupleIds = getUpdatedTuples(
          state$.value.datapoints,
          action,
          {
            rowIndexes: updatedIndexes,
          }
        ).map(({ id }) => id);

        return updateGridAction({
          rows: { createdIndexes, updatedIndexes },
          datapointIndex,
          datapoints: {
            updatedIds: updatedSimpleDatapointIds,
            updatedTupleIds,
          },
          gridIndex,
          grid,
          actionType: getType(updateGridAfterHorizontalSeparatorCreated),
        });
      })
    )
);

const updateGridAfterHorizontalSeparatorMoveEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterHorizontalSeparatorMove)),
    map(action => {
      const {
        meta: { separatorIndex, datapointIndex },
        payload: { grid },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      if (gridIndex === undefined) {
        return EMPTY;
      }

      const rowIndexes = (
        separatorIndex > 0
          ? [separatorIndex - 1, separatorIndex]
          : [separatorIndex]
      ).filter(rowIndex => grid.rows[rowIndex].tupleId !== null);

      const updatedTupleIds = getUpdatedTuples(
        state$.value.datapoints,
        action,
        {
          rowIndexes,
        }
      ).map(({ id }) => id);

      const updatedSimpleDatapointIds = getUpdatedDatapointIds(
        state$.value.datapoints,
        action,
        {
          rowIndexes,
        }
      );

      return updateGridAction({
        datapointIndex,
        grid,
        gridIndex,
        rows: { updatedIndexes: rowIndexes },
        datapoints: { updatedIds: updatedSimpleDatapointIds, updatedTupleIds },
      });
    })
  )
);

const updateGridAfterRowTypeChangedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterRowTypeChanged)),
    map(action => {
      const {
        meta: { datapointIndex, separatorIndex, rowType },
        payload: { grid },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      const dp = state$.value.datapoints.content[datapointIndex];

      if (
        gridIndex === undefined ||
        !dp ||
        dp.category !== 'multivalue' ||
        !dp.grid?.parts?.length
      ) {
        return EMPTY;
      }

      const currentGrid = dp.grid.parts[gridIndex];

      const { rowTypesToExtract } = getGridSchema(state$.value);

      const prevRowTypeIsExtracted =
        typeof currentGrid.rows[separatorIndex].type === 'string' &&
        rowTypesToExtract.includes(currentGrid.rows[separatorIndex].type!);
      const currentRowTypeIsExtracted =
        typeof rowType === 'string' && rowTypesToExtract.includes(rowType);

      const removedRowIndexes =
        prevRowTypeIsExtracted && !currentRowTypeIsExtracted
          ? [separatorIndex]
          : [];

      const createdRowIndexes =
        !prevRowTypeIsExtracted && currentRowTypeIsExtracted
          ? [separatorIndex]
          : [];

      const updatedRowIndexes =
        prevRowTypeIsExtracted && currentRowTypeIsExtracted
          ? [separatorIndex]
          : [];

      const updatedSimpleDatapointIds = updatedRowIndexes.length
        ? getUpdatedDatapointIds(state$.value.datapoints, action, {
            rowIndexes: updatedRowIndexes,
          })
        : [];

      const updatedTupleIds = getUpdatedTuples(
        state$.value.datapoints,
        action,
        {
          rowIndexes: updatedRowIndexes,
        }
      ).map(({ id }) => id);

      const deletedTupleIds = removedRowIndexes.length
        ? [currentGrid.rows[separatorIndex].tupleId]
        : [];

      return updateGridAction({
        rows: {
          createdIndexes: createdRowIndexes,
          deletedIndexes: removedRowIndexes,
          updatedIndexes: updatedRowIndexes,
        },
        datapointIndex,
        datapoints: {
          deletedTupleIds,
          updatedIds: updatedSimpleDatapointIds,
          updatedTupleIds,
        },
        grid,
        gridIndex,
      });
    })
  )
);

const updateGridAfterExtractAllRowsEpic = makeEpic(
  (action$, state$, { authPost$ }) =>
    action$.pipe(
      filter(isActionOf(updateGridAfterExtractAllRows)),
      mergeMap(({ payload: { currentDatapoint } }) => {
        const deleteOperations =
          currentDatapoint.grid?.parts?.map(() => ({
            op: 'delete',
            gridIndex: 0,
          })) ?? [];

        const defaultRowType =
          currentDatapoint.schema?.grid?.defaultRowType || 'data';

        const createOperations =
          currentDatapoint.grid?.parts?.flatMap((grid, gridIndex) => ({
            op: 'create',
            grid: {
              ...grid,
              rows: grid.rows.map(row => ({ ...row, type: defaultRowType })),
            },
            gridIndex,
          })) ?? [];

        const gridOperationUrl = `${currentDatapoint.url}/grid_operations`;

        return authPost$<MultivalueDatapointData>(gridOperationUrl, {
          operations: [...deleteOperations, ...createOperations],
        }).pipe(
          map(payload => {
            const datapoints = addSchemaToTreeDatapointsHelper(
              state$.value.schema.content ?? [],
              [payload]
            );

            const updatedDatapointIds = flattenDatapoints(
              {
                content: datapoints,
              },
              complexLineItemsEnabledSelector(state$.value)
            ).content.map(({ id }) => id);

            const createdTupleIds =
              payload.grid?.parts?.flatMap(grid =>
                compact(grid.rows.map(({ tupleId }) => tupleId))
              ) ?? [];

            return updateGridFulfilled({
              datapoints,
              updatedDatapointIds,
              createdTupleIds,
            });
          }),
          catchError(errorHandler)
        );
      })
    )
);

const updateGridAfterResizedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateGridAfterResized)),
    map(action => {
      const {
        meta: {
          datapointIndex,
          removedRowsIndexes,
          removedColumnsIndexes,
          resizingEdge,
        },
        payload: { grid },
      } = action;

      const gridIndex = findGridIndex(state$.value.datapoints, action);

      const dp = state$.value.datapoints.content[datapointIndex];

      if (
        gridIndex === undefined ||
        !dp ||
        dp.category !== 'multivalue' ||
        !dp.grid?.parts?.length
      ) {
        return EMPTY;
      }

      const currentGrid = dp.grid.parts[gridIndex];

      const { rowTypesToExtract } = getGridSchema(state$.value);

      const deletedRowsIndexes = removedRowsIndexes.filter(
        index =>
          typeof currentGrid.rows[index].type === 'string' &&
          rowTypesToExtract.includes(currentGrid.rows[index].type!)
      );

      // when can tupleId be null?
      const deletedTupleIds = compact(
        deletedRowsIndexes.map(index => currentGrid.rows[index].tupleId)
      );

      const moveWithTopEdge = resizingEdge.includes('top');

      const moveWithHorizontal =
        moveWithTopEdge || resizingEdge.includes('bottom');

      const updatedRowIndexes = moveWithHorizontal
        ? moveWithTopEdge
          ? typeof currentGrid.rows[0].type === 'string' &&
            rowTypesToExtract.includes(currentGrid.rows[0].type)
            ? [0]
            : []
          : typeof currentGrid.rows[grid.rows.length - 1].type === 'string' &&
              rowTypesToExtract.includes(
                currentGrid.rows[grid.rows.length - 1].type!
              )
            ? [grid.rows.length - 1]
            : []
        : [];

      const updatedTupleIds = updatedRowIndexes.length
        ? [currentGrid.rows[updatedRowIndexes[0]].tupleId]
        : [];

      // Columns

      const moveWithLeftEdge = resizingEdge.includes('left');
      const moveWithVertical =
        moveWithLeftEdge || resizingEdge.includes('right');

      const updatedColumnIds = moveWithVertical
        ? moveWithLeftEdge
          ? compact([grid.columns[0].schemaId])
          : compact([last(grid.columns)?.schemaId])
        : [];

      const clearedIds = removedColumnsIndexes.reduce<string[]>(
        (acc, index) => {
          if (currentGrid.columns[index].schemaId)
            return [...acc, currentGrid.columns[index].schemaId!];
          return acc;
        },
        []
      );

      const clearedSimpleDatapointIds = clearedIds.length
        ? clearedIds.flatMap(clearedId =>
            getUpdatedDatapointIdsForColumns(
              state$.value.datapoints,
              action
            )([clearedId])
          )
        : [];

      const updatedSimpleDatapointIdsfromRows = updatedRowIndexes.length
        ? getUpdatedDatapointIds(state$.value.datapoints, action, {
            rowIndexes: updatedRowIndexes,
          })
        : [];

      const updatedSimpleDatapointIdsfromColumns = updatedColumnIds.length
        ? getUpdatedDatapointIdsForColumns(
            state$.value.datapoints,
            action
          )(updatedColumnIds)
        : [];

      const updatedSimpleDatapointIds = uniq([
        ...updatedSimpleDatapointIdsfromRows,
        ...updatedSimpleDatapointIdsfromColumns,
      ]);

      return updateGridAction({
        grid,
        gridIndex,
        datapointIndex,
        datapoints: {
          deletedTupleIds,
          clearedIds: clearedSimpleDatapointIds,
          updatedTupleIds,
          updatedIds: updatedSimpleDatapointIds,
        },
        rows: {
          deletedIndexes: deletedRowsIndexes,
          updatedIndexes: updatedRowIndexes,
        },
        columns: { clearedIds, updatedIds: updatedColumnIds },
      });
    })
  )
);

const applyColumnsToAllGridsEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(applyColumnsToAllGrids)),
    mergeMap(action => {
      const {
        meta: { datapointIndex, page },
      } = action;

      const dp = state$.value.datapoints.content[datapointIndex];

      if (!dp || dp.category !== 'multivalue' || !dp.grid?.parts || !dp.url) {
        return EMPTY;
      }

      const gridOperationUrl = `${dp.url}/grid_operations`;

      const operations = dp.grid.parts.reduce<
        Array<{ op: string; grid: Grid; gridIndex: number }>
      >((acc, grid, gridIndex) => {
        if (grid.page === page) return acc;
        return [...acc, { op: 'update', grid, gridIndex }];
      }, []);

      return authPost$<MultivalueDatapointData>(gridOperationUrl, {
        operations,
      }).pipe(
        map(payload => {
          const datapoints = addSchemaToTreeDatapointsHelper(
            state$.value.schema.content ?? [],
            [payload]
          );

          const updatedDatapointIds = flattenDatapoints(
            {
              content: datapoints,
            },
            complexLineItemsEnabledSelector(state$.value)
          ).content.map(({ id }) => id);

          return updateGridFulfilled({
            datapoints,
            updatedDatapointIds,
          });
        }),
        catchError(errorHandler)
      );
    })
  )
);

export default combineEpics(
  applyColumnsToAllGridsEpic,
  applyGridToNextPagesEpic,
  createGridEpic,
  createGridFulfilledEpic,
  deleteAllGridsEpic,
  deleteGridEpic,
  deleteGridFulfilledEpic,
  pasteGridEpic,
  pasteGridToPageEpic,
  udpateGridAfterHorizontalSeparatorDeleted,
  updateGridAfterColumnsClearedEpic,
  updateGridAfterExtractAllRowsEpic,
  updateGridAfterHorizontalSeparatorCreatedEpic,
  updateGridAfterHorizontalSeparatorMoveEpic,
  updateGridAfterMovedEpic,
  updateGridAfterMovedFulfilledEpic,
  updateGridAfterResizedEpic,
  updateGridAfterRowTypeChangedEpic,
  updateGridAfterRowsClearedEpic,
  updateGridAfterSchemaIdAssignedEpic,
  updateGridAfterVerticalSeparatorDeletedEpic,
  updateGridAfterVerticalSeparatorMoveEpic,
  updateGridEpic,
  upgradeGridAfterVerticalSeparatorCreatedEpic
);
