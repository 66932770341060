import { createSelector } from 'reselect';
import { isEmbedded } from '../../constants/config';
import { State } from '../../types/state';
import { HardLimitKeys, SoftLimitKeys } from './schemas';

export const resolvedFeaturesSelector = (state: State) =>
  state.organizationGroup.resolvedFeatures;

const hardLimitSelector = createSelector(
  resolvedFeaturesSelector,
  (_: State, featureKey: HardLimitKeys) => featureKey,
  (resolvedFeatures, featureKey) => {
    const feature = resolvedFeatures?.[featureKey];
    return feature ? feature.purchased : false;
  }
);

const softLimitSelector = createSelector(
  resolvedFeaturesSelector,
  (_: State, featureKey: SoftLimitKeys) => featureKey,
  (resolvedFeatures, featureKey) => {
    const feature = resolvedFeatures?.[featureKey];
    return feature ? feature.limit > 0 : false;
  }
);

export const hasSubscriptionPlanExistSelector = (state: State) =>
  Boolean(state.organizationGroup.resolvedFeatures?.hasSubscriptionPlan);

export const embeddedFeatureSelector = (state: State) =>
  isEmbedded()
    ? hardLimitSelector(state, 'embeddedMode')
    : // ignoring usage embeddedMode.purchased flag if not in embedded,
      // we don't want to block users from using the app
      true;

export const duplicateHandlingFeatureSelector = (state: State) =>
  hardLimitSelector(state, 'duplicateHandling');

export const statisticsFeatureSelector = (state: State) =>
  hardLimitSelector(state, 'usageReports');

export const logsEnabledSelector = (state: State) =>
  hardLimitSelector(state, 'extensionLogs');

export const extensionsEnabledSelector = (state: State) =>
  hardLimitSelector(state, 'extensions');

export const mailboxFeatureSelector = (state: State) =>
  hardLimitSelector(state, 'intelligentMailbox');

export const documentArchiveFeatureSelector = (state: State) =>
  softLimitSelector(state, 'documentArchive');

export const labelsFeatureSelector = (state: State) =>
  hardLimitSelector(state, 'labels');

export const attachmentsFeatureSelector = (state: State) =>
  hardLimitSelector(state, 'attachments');
