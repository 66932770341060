// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const documentRejectionConditionsSchema = z.object({
  enabled: z.boolean(),
  resolutionLowerThanPx: z.tuple([z.number(), z.number()]),
  fileSizeLessThanB: z.number().nullable(),
  mimeTypes: z.array(z.string()),
  fileNameRegexes: z.array(z.string()).nullable(),
});

export const inboxSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: urlSchema,
  queues: z.array(urlSchema),
  email: z.string(),
  emailPrefix: z.string(),
  bounceEmailTo: z.string().optional().nullable(),
  bounceUnprocessableAttachments: z.boolean(),
  bouncePostponedAnnotations: z.boolean(),
  bounceDeletedAnnotations: z.boolean(),
  bounceEmailWithNoAttachments: z.boolean(),
  metadata: z.unknown().optional(),
  filters: z.object({
    allowedSenders: z.array(z.string()),
    deniedSenders: z.array(z.string()),
    documentRejectionConditions: documentRejectionConditionsSchema,
  }),
  dmarcCheckAction: z.string(),
});
