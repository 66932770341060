import { endpoints } from '@rossum/api-client';
import { HooksListQuery } from '@rossum/api-client/hooks';
import { HookTemplate } from '@rossum/api-client/hookTemplates';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY_HOOKS } from '../../../business/hooks/useHooks';
import { api } from '../../../lib/apiClient';
import { fetchExtensionDetailFulfilled } from '../../../redux/modules/extensions/actions';

const resolveState = ({
  isInstallable,
  isFetching,
  isAlreadyInstalled,
}: {
  isInstallable: boolean;
  isFetching: boolean;
  isAlreadyInstalled: boolean;
}) => {
  if (isInstallable) {
    if (isFetching) {
      return 'installable-checking' as const;
    }
    return isAlreadyInstalled
      ? ('installable-open' as const)
      : ('installable-install' as const);
  }

  return 'not-installable' as const;
};

export const useCheckIfAlreadyInstalled = (
  hookTemplate: HookTemplate | null
) => {
  // let's assume 'fullscreen' apps as installable for now,
  // in future it would be handy to have a separate hook.type or hook_template.install_action
  const isInstallable = hookTemplate?.config.app?.displayMode === 'fullscreen';

  const configAppUrl = hookTemplate?.config.app?.url;

  const enabled = !!configAppUrl && isInstallable;

  const hookListQuery: HooksListQuery | undefined = enabled
    ? { configAppUrl, pageSize: 1 }
    : undefined;

  const dispatch = useDispatch();

  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEY_HOOKS, hookListQuery] as const,
    queryFn: ({ queryKey }) => api.request(endpoints.hooks.list(queryKey[1])),
    enabled,
    onSuccess: data => {
      const extension = data.results[0];
      if (extension) {
        // @ts-expect-error there is a type clash between API client and redux
        dispatch(fetchExtensionDetailFulfilled(extension));
      }
    },
  });

  const installedHookId: number | undefined = isFetching
    ? undefined
    : data?.results[0]?.id;

  const hookTemplateStatus = resolveState({
    isInstallable,
    isFetching,
    isAlreadyInstalled: !!installedHookId,
  });

  return {
    installedHookId,
    hookTemplateStatus,
  };
};
