import { Add as AddIcon } from '@rossum/ui/icons';
import { Button, Popover } from '@rossum/ui/material';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { IAceEditor } from 'react-ace/lib/types';
import { useIntl } from 'react-intl';
import { useCurrentSchemaConceptContext } from '../FormulaPreview/contexts/currentSchema/currentSchemaContext';
import { handleInsertion, OnInsertCallback } from './insertion-helpers';
import Suggester from './Suggester';

const SUGGESTER_WIDTH = 400;
const SUGGESTER_MAX_HEIGHT = 465;

const TemplateSuggesterButton = ({ editor }: { editor: IAceEditor }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onInsert = useCallback<OnInsertCallback>(
    (value, meta) => {
      handleInsertion({ value, meta, editor });

      setAnchorEl(null);

      // need to use setTimeout because closing the menu
      // steals focus from editor back to the button
      setTimeout(() => {
        editor.focus();
      });
    },
    [editor]
  );

  const { getCurrentSchemaConcept, schemaObjectPath } =
    useCurrentSchemaConceptContext();

  const schemaContent = getCurrentSchemaConcept();

  const fieldSchemaId = get(schemaContent, schemaObjectPath)?.id;
  const fieldSchemaLabel = get(schemaContent, schemaObjectPath)?.label;

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={e => setAnchorEl(e.currentTarget)}
        data-cy="template-suggester-button"
        disabled={!fieldSchemaId || !fieldSchemaLabel}
      >
        {intl.formatMessage({
          id: 'features.queueSettings.fields.formulaEditor.insertFormula',
        })}
      </Button>
      <Popover
        open={!!anchorEl}
        onClose={onClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            elevation: 4,
            sx: {
              mt: 0.5,
              width: SUGGESTER_WIDTH,
              maxHeight: SUGGESTER_MAX_HEIGHT,
            },
          },
        }}
      >
        <Suggester onInsert={onInsert} />
      </Popover>
    </>
  );
};

export default TemplateSuggesterButton;
