import { UniqueIdentifier } from '@dnd-kit/core';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  isSchemaTableMultiValue,
  Schema,
  SchemaItem,
} from '@rossum/api-client/schemas';
import { ValidationError } from '@rossum/rossum-ui/ValidationError';
import {
  Alert,
  Box,
  Button,
  Collapse,
  MenuItem,
  Stack,
  useTheme,
} from '@rossum/ui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Prompt, useHistory, useLocation } from 'react-router';
import * as R from 'remeda';
import SwitchControl from '../../../../components/ReactHookForm/controls/SwitchControl';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import FormTypeahead from '../../../../components/ReactHookForm/FormTypeahead';
import { EXTRACTED_FIELD_TYPES_LINK } from '../../../../constants/values';
import EnumEditorInDrawer from '../../../../containers/Queue/containers/QueueSchema/components/EnumEditorInDrawer';
import { boldText, linebreak, link } from '../../../../lib/formaterValues';
import { getCurrentQueueId } from '../../../../lib/url';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';
import FormulaEditor from '../../../formulas/FormulaEditor/FormulaEditor';
import CalculatePreviewButton from '../../../formulas/FormulaPreview/CalculatePreviewButton';
import { CurrentSchemaConceptContext } from '../../../formulas/FormulaPreview/contexts/currentSchema/currentSchemaContext';
import { FormulaPreviewContextProvider } from '../../../formulas/FormulaPreview/contexts/formulaPreview/formulaPreviewContext';
import FormulaPreview from '../../../formulas/FormulaPreview/FormulaPreview';
import TemplateSuggesterButton from '../../../formulas/FormulaSuggester/TemplateSuggesterButton';
import {
  dataTypeOptions,
  dateFieldFormatOptions,
  DELETE_FIELD_FORM_ID,
  editingOptions,
  FIELD_FORM_ID,
  getLinkStyles,
  IDENTIFICATION_SECTION_LINk,
  legacyValueSourceOptions,
  multivalueDataTypeOptions,
  numberFieldFormatOptions,
  properValueSourceOptions,
  uiValidFieldTypeOptions,
  uiValidLineItemFieldTypeOptions,
} from '../constants';
import { fieldsFormErrorMap } from '../form-model/errors/formErrorMap';
import {
  FieldsFormModel,
  fieldsFormSchema,
  FieldsFormValues,
} from '../form-model/formModels';
import { toFormValues } from '../form-model/transformations';
import {
  isSchemaItemChildPath,
  retrieveFromSchema,
} from '../form-model/transformations/toSchema.utils';
import {
  fieldsIconsMap,
  getChildListPath,
  getSchemaObjectPaths,
} from '../helpers';
import { useConfirmationDialog } from '../hooks/useConfirmationDialog';
import {
  getUnsafeSchemaConcept,
  useSchemaConcept,
} from '../hooks/useSchemaContept';
import { useValidateSchema } from '../hooks/useValidateSchema';
import { isValidFieldId } from '../utils';
import { AiEngineSection } from './form/AiEngineSection';
import {
  isCanObtainTokenAvailable,
  isChildrenAvailable,
  isDataTypeAvailable,
  isEditingAvailable,
  isFormatAvailable,
  isFormulaEditorAvailable,
  isOptionsAvailable,
  isUrlAvailable,
  isValueSourceAvailable,
} from './form/conditions';
import { ContentOption } from './form/ContentOption';
import PreFillFormSection from './form/PreFillFormSection';
import { QuickActionIcon } from './form/QuickActionIcon';
import {
  TableMultiValueChildren,
  TableMultiValueChildrenProps,
} from './TableMultiValueChildren';

export type ItemContentProps = {
  data: SchemaItem | null;
  onSubmit: (formModel: FieldsFormModel) => void;
  onDelete: (itemId: string) => void;
  onChildrenReorder: (
    from: readonly [UniqueIdentifier, number],
    to: readonly [UniqueIdentifier, number]
  ) => void;
  onQuickAction: (
    parentId: UniqueIdentifier,
    items: Record<
      UniqueIdentifier,
      {
        prop: 'hidden' | 'canExport' | 'required';
        value: boolean;
      }
    >
  ) => void;
  schema: Schema;
  parentId: string;
  supportLegacyUser?: boolean;
  formulaFieldsEnabled: boolean;
};

export const ItemContent = ({
  data,
  onSubmit,
  onDelete,
  onChildrenReorder,
  onQuickAction,
  schema,
  parentId,
  supportLegacyUser,
  formulaFieldsEnabled,
}: ItemContentProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();

  const { dialog, setDialogState, dialogState } = useConfirmationDialog();

  const { mutateAsync: validateSchema } = useValidateSchema();

  const schemaObjectPaths = useMemo(
    () => getSchemaObjectPaths(schema),
    [schema]
  );

  const parentSchemaObjectPath = useMemo(
    () => schemaObjectPaths[parentId] ?? null,
    [parentId, schemaObjectPaths]
  );

  const itemSchemaObjectPath = useMemo(() => {
    const childListPath = parentSchemaObjectPath
      ? getChildListPath(parentSchemaObjectPath)
      : null;
    const currentSchemaObjectPath = data && schemaObjectPaths[data.id];

    if (currentSchemaObjectPath) {
      return currentSchemaObjectPath;
    }

    if (childListPath) {
      const lengthOfSiblings = retrieveFromSchema(
        schema,
        childListPath
      )?.length;

      if (lengthOfSiblings !== undefined) {
        return [...childListPath, lengthOfSiblings];
      }
    }

    return null;
  }, [data, parentSchemaObjectPath, schema, schemaObjectPaths]);

  const currentQueueId = useMemo(() => {
    const queueIdInPathname = getCurrentQueueId(pathname);
    // Field manager uses the formulapreview outside of the queue context,
    // in such case, there is no queue in the pathname and we need to take the
    // queue from the schema
    if (queueIdInPathname) return queueIdInPathname;

    const firstSchemaQueue = R.first(schema.queues);

    const schemaQueueId = getCurrentQueueId(firstSchemaQueue);
    // TODO: There is an edge case when queue is in deletion but still assigned to a schema
    // in such case it might be better to try GET the first queue and in case of 404 try another one
    // but I am leaving it as it is for now
    if (schemaQueueId) {
      return schemaQueueId;
    }

    return undefined;
  }, [pathname, schema.queues]);

  // RHF cannot infer this from `resolver`, sheesh
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm<FieldsFormValues, undefined, FieldsFormModel>({
    defaultValues: toFormValues(data, itemSchemaObjectPath),
    resolver: zodResolver(fieldsFormSchema(intl), {
      errorMap: fieldsFormErrorMap(intl),
    }),
  });

  const { handleSubmit: handleDeleteFormSubmit } = useForm({
    defaultValues: {
      id: data?.id ?? null,
    },
  });

  const formValues = getValues();

  const {
    field: {
      id,
      hidden,
      exported,
      required,
      valueSource,
      editing,
      dataType,
      format,
    },
    fieldType,
  } = watch();

  const valueSourceOptions = [
    ...properValueSourceOptions.filter(option =>
      option === 'formula' ? formulaFieldsEnabled : true
    ),
    ...(supportLegacyUser ? legacyValueSourceOptions : []),
  ];

  const typeOptions = isSchemaItemChildPath(itemSchemaObjectPath)
    ? uiValidLineItemFieldTypeOptions
    : uiValidFieldTypeOptions;

  const nestedItemsData = useMemo(() => {
    if (isSchemaTableMultiValue(data)) {
      return data;
    }

    return null;
  }, [data]);

  const schemaConcept = useSchemaConcept(schema, parentId, data, formValues);

  const getSchemaConceptContent = useCallback(
    () =>
      // reusing memoized `schemaConcept` here brought some troubles with
      // requests not being sent with the most recent schema concept
      getUnsafeSchemaConcept({
        schema,
        parentId,
        apiModel: data,
        formValues: getValues(),
      })?.content ?? [],
    [schema, parentId, data, getValues]
  );

  // resets on remounts and this component is being re-mounted every time `data` changes
  // not just rerendered (`key` from parent)
  const skipPrompt = useRef(false);

  const deleteHandler: SubmitHandler<{ id: string | null }> = useCallback(
    ({ id }) => {
      if (id) {
        skipPrompt.current = true;
        onDelete(id);
      }
    },
    [onDelete]
  );

  const submitHandler: SubmitHandler<FieldsFormModel> = useCallback(
    formModel => {
      if (itemSchemaObjectPath) {
        // TODO: Proper validation with errors on correct fields, this is just a quick fix
        validateSchema(schemaConcept)
          .then(response => {
            if (!R.isDeepEqual(response, {})) {
              setError('root', {
                message: JSON.stringify(response, undefined, 2),
              });
            } else {
              skipPrompt.current = true;
              onSubmit(formModel);
            }
          })
          .catch(() => {
            skipPrompt.current = false;
          });
      }
    },
    [itemSchemaObjectPath, onSubmit, schemaConcept, setError, validateSchema]
  );

  const childrenReorderHandler: TableMultiValueChildrenProps['onChildrenReorder'] =
    useCallback(
      (from, to) => {
        onChildrenReorder(from, to);
      },
      [onChildrenReorder]
    );

  useEffect(() => {
    if (fieldType === 'multivalue' && valueSource === 'formula') {
      setValue('field.valueSource', 'captured');
    }

    if (fieldType === 'multivalue') {
      setValue('field.dataType', 'string');
    }
  }, [fieldType, setValue, valueSource]);

  const [errorExpanded, setErrorExpanded] = useState(false);

  // Do not change field ID before input gets blurred.
  // Otherwise, it would fetch engine field on every keystroke.
  const [fieldId, setFieldId] = useState(id);

  if (!itemSchemaObjectPath) {
    return null;
  }

  return (
    <>
      <Stack
        p={4}
        spacing={4}
        id={FIELD_FORM_ID}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
      >
        {errors.root ? (
          <Alert
            variant="filled"
            severity="error"
            action={
              <Button
                color="inherit"
                variant="text"
                onClick={() => setErrorExpanded(expanded => !expanded)}
                sx={{ flexShrink: 0 }}
              >
                {intl.formatMessage({
                  id: 'features.queueSettings.fields.form.errors.encountered.more',
                })}
              </Button>
            }
          >
            {intl.formatMessage({
              id: 'features.queueSettings.fields.form.errors.encountered',
            })}
            <Collapse in={errorExpanded}>
              <pre>{errors.root.message}</pre>
            </Collapse>
          </Alert>
        ) : null}
        <Stack direction="row" spacing={4}>
          <ContentOption
            title={intl.formatMessage({
              id: 'features.queueSettings.fields.form.visible.label',
            })}
            helperText={intl.formatMessage({
              id: 'features.queueSettings.fields.form.visible.helperText',
            })}
            icon={<QuickActionIcon fieldKey="hidden" state={!hidden} />}
          >
            <SwitchControl
              ControllerProps={{ control, name: 'field.hidden' }}
              label=""
              size="small"
              showInvertedValue
            />
          </ContentOption>
          <ContentOption
            title={intl.formatMessage({
              id: 'features.queueSettings.fields.form.exported.label',
            })}
            helperText={intl.formatMessage({
              id: 'features.queueSettings.fields.form.exported.helperText',
            })}
            icon={<QuickActionIcon fieldKey="exported" state={!!exported} />}
          >
            <SwitchControl
              ControllerProps={{ control, name: 'field.exported' }}
              label=""
              size="small"
            />
          </ContentOption>
          <ContentOption
            title={intl.formatMessage({
              id: 'features.queueSettings.fields.form.required.label',
            })}
            helperText={intl.formatMessage({
              id: 'features.queueSettings.fields.form.required.helperText',
            })}
            icon={<QuickActionIcon fieldKey="required" state={!!required} />}
          >
            <SwitchControl
              ControllerProps={{ control, name: 'field.required' }}
              label=""
              size="small"
            />
          </ContentOption>
        </Stack>
        <ContentGroup
          title={intl.formatMessage({
            id: 'features.queueSettings.fields.form.identification.title',
          })}
          description={intl.formatMessage(
            {
              id: 'features.queueSettings.fields.form.identification.description',
            },
            {
              linebreak,
              link: link(IDENTIFICATION_SECTION_LINk, getLinkStyles(theme)),
            }
          )}
        >
          <TextFieldControl
            autoFocus
            ControllerProps={{ control, name: 'field.label' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.label.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
          <TextFieldControl
            ControllerProps={{ control, name: 'field.id' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.id.label',
            })}
            helperText={
              isValidFieldId(formValues.field.id)
                ? ''
                : intl.formatMessage({
                    id: 'features.queueSettings.fields.form.id.helperText',
                  })
            }
            FieldLabelProps={{
              layout: 'floating',
            }}
            onBlur={e => setFieldId(e.currentTarget.value)}
          />
          <TextFieldControl
            ControllerProps={{ control, name: 'field.description' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.description.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
        </ContentGroup>
        <ContentGroup
          title={intl.formatMessage({
            id: 'features.queueSettings.fields.form.type.title',
          })}
          description={intl.formatMessage({
            id: 'features.queueSettings.fields.form.type.description',
          })}
        >
          <TextFieldControl
            ControllerProps={{ control, name: 'fieldType' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.fieldType.label',
            })}
            select
            autoComplete="off"
            FieldLabelProps={{
              layout: 'floating',
            }}
          >
            {typeOptions.map(o => {
              const Icon = fieldsIconsMap[o];
              return (
                <MenuItem key={o} value={o}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Icon
                      fontSize="small"
                      sx={{ color: t => t.palette.text.secondary }}
                    />
                    {intl.formatMessage({
                      id: `features.queueSettings.fields.form.fieldType.option.${o}`,
                    })}
                  </Stack>
                </MenuItem>
              );
            })}
          </TextFieldControl>
          {isDataTypeAvailable(formValues) && (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.dataType' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.dataType.label',
              })}
              select
              autoComplete="off"
              FieldLabelProps={{
                layout: 'floating',
              }}
            >
              {(formValues.fieldType === 'multivalue'
                ? multivalueDataTypeOptions
                : dataTypeOptions
              ).map(o => (
                <MenuItem key={o} value={o}>
                  {intl.formatMessage({
                    id: `features.queueSettings.fields.form.dataType.option.${o}`,
                  })}
                </MenuItem>
              ))}
            </TextFieldControl>
          )}
          {isFormatAvailable(formValues) && (
            <FormTypeahead
              name="field.format"
              control={control}
              options={
                dataType === 'number'
                  ? numberFieldFormatOptions
                  : dateFieldFormatOptions
              }
              getOptionLabel={option =>
                typeof option === 'string' ? option : option?.label
              }
              label={intl.formatMessage({
                id: `features.queueSettings.fields.form.format.label`,
              })}
              disablePortal
              autoSelect
              freeSolo
              onInputChange={(_, value) => setValue('field.format', value)}
              getOptionValue={option => option.value}
              helperText={
                (dataType === 'number'
                  ? numberFieldFormatOptions
                  : dateFieldFormatOptions
                ).find(o => o.value === format)?.helpText ?? ''
              }
              renderOption={(props, option) => (
                <MenuItem key={option.value} {...props}>
                  {option.label}
                </MenuItem>
              )}
              size="small"
            />
          )}
          {isValueSourceAvailable(formValues) && (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.valueSource' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.valueSource.label',
              })}
              select
              autoComplete="off"
              FieldLabelProps={{
                layout: 'floating',
              }}
              helperText={intl.formatMessage({
                id: `features.queueSettings.fields.form.valueSource.option.${valueSource}.helperText`,
              })}
            >
              {valueSourceOptions.map(o => {
                const Icon = fieldsIconsMap[o];

                // TODO: get correct options from the one place and remove this condition from here
                // formula is not supported for multivalue fields
                if (fieldType === 'multivalue' && o === 'formula') {
                  return null;
                }

                return (
                  <MenuItem key={o} value={o}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Icon
                        fontSize="small"
                        sx={{ color: t => t.palette.text.secondary }}
                      />
                      {intl.formatMessage({
                        id: `features.queueSettings.fields.form.valueSource.option.${o}`,
                      })}
                    </Stack>
                  </MenuItem>
                );
              })}
            </TextFieldControl>
          )}
          {isEditingAvailable(formValues) && (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.editing' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.editing.label',
              })}
              select
              autoComplete="off"
              FieldLabelProps={{
                layout: 'floating',
              }}
              helperText={intl.formatMessage(
                {
                  id: `features.queueSettings.fields.form.editing.option.${editing}.helperText`,
                },
                { linebreak }
              )}
            >
              {editingOptions.map(o => (
                <MenuItem key={o} value={o}>
                  {intl.formatMessage({
                    id: `features.queueSettings.fields.form.editing.option.${o}`,
                  })}
                </MenuItem>
              ))}
            </TextFieldControl>
          )}
          {isUrlAvailable(formValues) && (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.url' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.url.label',
              })}
              FieldLabelProps={{
                layout: 'floating',
              }}
              helperText={intl.formatMessage({
                id: 'features.queueSettings.fields.form.url.helperText',
              })}
            />
          )}
          {isCanObtainTokenAvailable(formValues) && (
            <SwitchControl
              ControllerProps={{ control, name: 'field.canObtainToken' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.canObtainToken.label',
              })}
              size="small"
            />
          )}
        </ContentGroup>
        <AiEngineSection
          control={control}
          formValues={formValues}
          fieldId={fieldId}
        />
        <PreFillFormSection control={control} formValues={formValues} />
        {isOptionsAvailable(formValues) && (
          <Box sx={{ minHeight: '250px' }}>
            {/* TODO: Remove this wrapper after redesign EnumEditorInDrawer: AC-4611  */}
            <ContentGroup
              title={intl.formatMessage({
                id: 'features.queueSettings.fields.form.options.title',
              })}
              description={intl.formatMessage(
                {
                  id: 'features.queueSettings.fields.form.options.description',
                },
                {
                  boldText,
                  linebreak,
                  link: link(EXTRACTED_FIELD_TYPES_LINK, getLinkStyles(theme)),
                }
              )}
            >
              <Controller
                control={control}
                name="field.options"
                render={({ field: { value, onChange } }) => (
                  <EnumEditorInDrawer
                    options={value || []}
                    onCurrentSchemaPartChange={options => onChange(options)}
                    showPasteText={false}
                  />
                )}
              />
            </ContentGroup>
          </Box>
        )}

        {isFormulaEditorAvailable(formValues) && (
          <>
            {/* Remove as much contexts as possible after removing old fields forms */}
            <CurrentSchemaConceptContext.Provider
              value={{
                getCurrentSchemaConcept: getSchemaConceptContent,
                schemaObjectPath: `${itemSchemaObjectPath.join('.')}`,
              }}
            >
              <FormulaPreviewContextProvider
                currentQueueId={currentQueueId}
                hasError={!!Object.keys(errors).length}
              >
                <ContentGroup
                  title={intl.formatMessage({
                    id: 'containers.settings.fields.edit.formula.title',
                  })}
                  description={intl.formatMessage({
                    id: 'containers.settings.fields.edit.formula.description',
                  })}
                  isFullWidthLayout
                >
                  <Controller
                    control={control}
                    name="field.formula"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Stack spacing={1} sx={{ marginBottom: 3 }}>
                        <FormulaEditor
                          onChange={onChange}
                          value={value}
                          renderButtons={({ editor }) => (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <CalculatePreviewButton />
                              <TemplateSuggesterButton editor={editor} />
                            </Stack>
                          )}
                        />
                        {error && error.message ? (
                          <ValidationError errorMessage={error.message} />
                        ) : null}
                      </Stack>
                    )}
                  />
                  {currentQueueId ? <FormulaPreview /> : null}
                </ContentGroup>
              </FormulaPreviewContextProvider>
            </CurrentSchemaConceptContext.Provider>
          </>
        )}
      </Stack>
      {nestedItemsData && isChildrenAvailable(formValues) ? (
        <TableMultiValueChildren
          parentId={id}
          data={nestedItemsData}
          onChildrenReorder={childrenReorderHandler}
          onQuickAction={onQuickAction}
        />
      ) : null}
      <Stack
        display="none"
        component="form"
        id={DELETE_FIELD_FORM_ID}
        onSubmit={e => {
          e.preventDefault();
          setDialogState({
            key: 'deleteSchemaField',
            onConfirm: () => handleDeleteFormSubmit(deleteHandler)(e),
          });
        }}
      />
      <Prompt
        message={location => {
          if (dialogState) {
            setDialogState(null);
            return true;
          }

          setDialogState({
            key: 'notSavedChanges',
            onConfirm: () => history.replace(location),
          });

          return false;
        }}
        when={!skipPrompt.current && isDirty}
      />
      {dialog}
    </>
  );
};
