import {
  CreateQueueFromTemplatePayload,
  CreateQueueFromTemplateQuery,
} from '@rossum/api-client/queues';
import { Queue } from '@rossum/api-client/queues';
import { Workspace } from '@rossum/api-client/workspaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addQueueMutation } from '../../../business/queues/addQueueMutation';
import { createConfiguredQueue } from '../../../business/queues/createQueueWithSettings';
import { addQueueToWorkspaceMutation } from '../../../business/workspaces/addQueueToWorkspaceMutation';
import { addWorkspaceMutation } from '../../../business/workspaces/addWorkspaceMutation';
import { useCreateWorkspace } from '../../../business/workspaces/useCreateWorkspace';
import { QUERY_KEY_LAZY_WORKSPACES } from '../../../business/workspaces/useLazyWorkspaces';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import {
  createQueueFulfilled,
  fetchQueueFulfilled,
} from '../../../redux/modules/queues/actions';
import { createWorkspaceFulfilled } from '../../../redux/modules/workspaces/actions';
import { formToApiModel, QueueDetailFormModel } from './utils';

export const useCreateQueueAndWorkspace = ({
  onSuccess,
}: {
  onSuccess: (queueId: number) => void;
}) => {
  const dispatch = useDispatch();
  const organization = useSelector(safeOrganizationSelector);
  const queryClient = useQueryClient();

  const { mutate: createQueue, status: createQueueStatus } = useMutation({
    mutationFn: (queueParams: {
      payload: CreateQueueFromTemplatePayload;
      query: CreateQueueFromTemplateQuery;
    }) => createConfiguredQueue(queueParams),
  });

  const { mutate: createWorkspace, status: createWorkspaceStatus } =
    useCreateWorkspace();

  const mutationLoading =
    createQueueStatus === 'loading' || createWorkspaceStatus === 'loading';

  const handleOnSuccess = useCallback(
    (queue: Queue, workspace?: Workspace) => {
      // @ts-expect-error
      dispatch(fetchQueueFulfilled(queue));
      // @ts-expect-error
      dispatch(createQueueFulfilled(queue, { skipRedirect: true }));

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LAZY_WORKSPACES],
      });

      if (workspace) {
        // add workspace to list of workspaces
        addWorkspaceMutation(queryClient, workspace);
      }
      // update queues for specific workspace
      addQueueToWorkspaceMutation(queryClient, queue);
      // update queue list
      addQueueMutation(queryClient, queue);

      onSuccess(queue.id);
    },
    [dispatch, onSuccess, queryClient]
  );

  const submitForm = useCallback(
    (
      formModel: QueueDetailFormModel,
      engineParams: { legacy?: boolean } = {}
    ) => {
      if (formModel.addingNewWorkspace && organization) {
        createWorkspace(
          {
            name: formModel.newWorkspaceName,
            organization: organization.url,
          },
          {
            onSuccess: workspace => {
              dispatch(createWorkspaceFulfilled(workspace));
              createQueue(
                {
                  payload: formToApiModel({
                    ...formModel,
                    workspaceUrl: workspace.url,
                  }),
                  query: engineParams,
                },
                {
                  onSuccess: queue => handleOnSuccess(queue, workspace),
                }
              );
            },
          }
        );
      } else {
        createQueue(
          { payload: formToApiModel(formModel), query: engineParams },
          { onSuccess: queue => handleOnSuccess(queue) }
        );
      }
    },
    [createQueue, createWorkspace, dispatch, handleOnSuccess, organization]
  );

  return { submitForm, mutationLoading };
};
