import { useRouteMatch } from 'react-router';
import { automationEnginesPath } from './helpers';

export const usePageKey = () => {
  const automationEnginesPathMatch = useRouteMatch(automationEnginesPath());

  return automationEnginesPathMatch
    ? ({
        pageKey: 'aiEngines',
        showTabs: automationEnginesPathMatch.isExact,
      } as const)
    : ({
        pageKey: 'automationSettings',
        showTabs: true,
      } as const);
};
