import { isEqual, isObjectLike } from 'lodash';
import { useMemo, useState } from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseFormSetError,
  UseFormTrigger,
} from 'react-hook-form';
import { getSchemaValidationAnnotations } from './schemaValidation';

export const useJSONfield = <T extends FieldValues>({
  name,
  control,
  trigger,
  setError,
  saveField,
  defaultValue,
  schema: originalSchema,
}: {
  control: Control<T>;
  name: Path<T>;
  setError: UseFormSetError<T>;
  trigger: UseFormTrigger<T>;
  saveField?: (name: Path<T>, value: PathValue<T, Path<T>>) => void;
  defaultValue?: PathValue<T, Path<T>>;
  schema?: object;
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const stringifiedFieldValue = JSON.stringify(field.value, null, 2) || '';
  const [value, setValue] = useState(stringifiedFieldValue);
  const isChanged = !isEqual(field.value, defaultValue);
  const schema: object | undefined = useMemo(
    // conversion roundtrip needed because schema validation for enums was not working otherwise :-/
    () =>
      originalSchema ? JSON.parse(JSON.stringify(originalSchema)) : undefined,
    [originalSchema]
  );

  const onValidate = (annotations: Array<{ type: string }>) => {
    const hasErrors = annotations.some(({ type }) => type === 'error');

    if (hasErrors) {
      setError(name, {
        type: 'manual',
        message: 'invalid JSON',
      });
    }
  };

  const schemaErrorAnnotations = useMemo(() => {
    try {
      const parsed = JSON.parse(value);

      return getSchemaValidationAnnotations({
        schema,
        valueAsObject: parsed,
        valueAsString: value,
      });
    } catch {
      return [];
    }
  }, [value, schema]);

  const onChange = (value: string) => {
    setValue(value);

    try {
      const parsedValue = JSON.parse(value);

      const isValueValid = isObjectLike(parsedValue);

      if (isValueValid) {
        trigger(name);
      } else {
        setError(name, {
          type: 'manual',
          message: 'invalid JSON',
        });
      }

      // This line is needed as Ace editor is confused on linebreaks
      if (!isEqual(parsedValue, field.value)) {
        field.onChange(parsedValue);
      }
    } catch {
      setError(name, {
        type: 'manual',
        message: 'invalid JSON',
      });
    }
  };

  const canPrettify =
    (!!schemaErrorAnnotations.length || !error) &&
    value !== stringifiedFieldValue;
  const onPrettify = () => {
    if (!!schemaErrorAnnotations.length || !error) {
      setValue(stringifiedFieldValue);
    }
  };

  const canSave = !error && isChanged;
  const onSave = saveField
    ? () => {
        saveField(name, field.value);
      }
    : null;

  const canReset = isChanged;
  const onReset = defaultValue
    ? () => {
        onChange(JSON.stringify(defaultValue, null, 2) || '');
      }
    : null;

  return {
    value,
    error,
    schemaErrorAnnotations,
    onChange,
    onPrettify,
    canPrettify,
    onSave,
    canSave,
    onReset,
    canReset,
    onValidate,
  };
};
