import { create } from './create';
import { deleteEndpoint } from './delete';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
import { listUptrainFromFields } from './uptrainFromFields';
export { type EngineFieldCreatePayload } from './create';
export { type EngineFieldListQuery } from './list';
export { type EngineFieldPatchPayload } from './patch';

export const engineFields = {
  get,
  list,
  create,
  patch,
  delete: deleteEndpoint,
  listUptrainFromFields,
};
