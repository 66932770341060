import { Label } from '@rossum/api-client/labels';
import { Delete } from '@rossum/ui/icons';
import {
  alpha,
  Dialog,
  IconButton,
  ListItemButton,
  ListItemText,
} from '@rossum/ui/material';
import { useState } from 'react';
import { DeleteLabelDialog, EditLabelDialog } from './LabelDialogs';

// matching the height of the list items in /settings/users
const LIST_ITEM_MAX_HEIGHT = 44;
type Props = {
  label: Label;
};
export const LabelListRow = ({ label: { name, id } }: Props) => {
  const [labelAction, setLabelAction] = useState<'delete' | 'edit' | null>(
    null
  );

  return (
    <>
      <ListItemButton
        component="li"
        sx={{
          cursor: 'pointer',
          maxHeight: LIST_ITEM_MAX_HEIGHT,
          borderRadius: '4px',
          '&:hover,&:focus,&:focus-within': {
            background: ({
              palette: {
                primary: { main },
              },
            }) =>
              `linear-gradient(to left, ${main} , ${main}, ${main}, ${alpha(
                main,
                0
              )})`,
          },
        }}
        onClick={() => setLabelAction('edit')}
        disableRipple
      >
        <ListItemText
          primary={name}
          primaryTypographyProps={{ fontSize: 14 }}
        />
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setLabelAction('delete');
          }}
          size="small"
          sx={{ color: 'text.secondary' }}
        >
          <Delete fontSize="small" />
        </IconButton>
      </ListItemButton>
      <Dialog open={labelAction === 'delete'}>
        <DeleteLabelDialog
          id={id}
          name={name}
          onCancel={() => setLabelAction(null)}
        />
      </Dialog>
      <Dialog open={labelAction === 'edit'}>
        <EditLabelDialog
          id={id}
          name={name}
          onCancel={() => setLabelAction(null)}
        />
      </Dialog>
    </>
  );
};
