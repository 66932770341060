import { getIDFromUrl } from '@rossum/api-client';
import { useIntl } from 'react-intl';
import { isDefined } from 'remeda';
import { Breadcrumb } from '../../ui/breadcrumb/Breadcrumb';
import { useRouteResources } from './hooks/useRouteResources';
import { engineDetailPath, engineFieldDetailPath } from './paths';

export const useEngineBreadcrumbs = () => {
  const intl = useIntl();
  const { engine, field } = useRouteResources();

  const engineBreadcrumb = engine.data ? (
    <Breadcrumb key="engine" to={engineDetailPath(engine.data.id)}>
      {engine.data.name}
    </Breadcrumb>
  ) : engine.isCreating ? (
    <Breadcrumb key="engine_add" to={engineDetailPath('new')}>
      {intl.formatMessage({ id: 'features.engines.header.title.newEngine' })}
    </Breadcrumb>
  ) : undefined;

  const fieldBreadcrumb = field.data ? (
    <Breadcrumb
      key="field"
      to={engineFieldDetailPath(getIDFromUrl(field.data.engine), field.data.id)}
    >
      {field.data.label}
    </Breadcrumb>
  ) : engine.data && field.isCreating ? (
    <Breadcrumb key="field" to={engineFieldDetailPath(engine.data.id, 'new')}>
      {intl.formatMessage({ id: 'features.engines.header.title.newField' })}
    </Breadcrumb>
  ) : undefined;

  return [engineBreadcrumb, fieldBreadcrumb].filter(isDefined.strict);
};
