import { MenuItem, MenuList } from '@rossum/ui/material';
import { OnInsertCallback } from './insertion-helpers';
import MenuItemContentFormula from './MenuItemContentFormula';
import { menuListSharedProps, Template } from './shared';

const MenuListFormulas = ({
  setFirstRef,
  templates,
  onInsert,
  search,
}: {
  setFirstRef: React.MutableRefObject<HTMLLIElement | null>;
  templates: Array<Template>;
  onInsert: OnInsertCallback;
  search: string;
}) => {
  return (
    <MenuList {...menuListSharedProps}>
      {templates.map((template, index) => (
        <MenuItem
          ref={index === 0 ? setFirstRef : undefined}
          key={template.title}
          onClick={() =>
            onInsert(template.snippet, { insertMode: 'new-block' })
          }
        >
          <MenuItemContentFormula search={search} template={template} />
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default MenuListFormulas;
