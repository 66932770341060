import { Active, Over } from '@dnd-kit/core';
import { isArray, isEqual, reduce } from 'lodash';
import { useEffect, useState } from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import {
  insertInArray,
  removeIndexInArray,
  swapItemsInArray,
} from '../../../../redux/modules/utils';
import {
  MultivalueSchemaObject,
  OriginalAnyDatapointSchema,
} from '../../../../types/schema';

type DropLocationMeta = {
  droppableId: string;
  index: number;
};

type DropResult = {
  source: DropLocationMeta;
  destination: DropLocationMeta;
};

export const getDropResult = (
  active: Active,
  over: Over,
  increaseDestinationIndex?: boolean
): DropResult => {
  // current data of destination can be droppable even when destiantion have not any items
  const overCurrentData = over.data.current;

  // if destination and active section aren't equal and destination index is the last place,
  // it's necessary to increase index to the "new last place" manually
  const increaseValue = increaseDestinationIndex ? 1 : 0;

  return {
    source: {
      droppableId: active.data.current?.sortable.containerId,
      index: active.data.current?.sortable.index,
    },
    destination: {
      droppableId: !overCurrentData
        ? over.id
        : over.data.current?.sortable.containerId,
      index: !overCurrentData
        ? 0
        : over.data.current?.sortable.index + increaseValue,
    },
  };
};

export const calculateCanExport = (data: MultivalueSchemaObject) => {
  if (data.children.category === 'tuple') {
    return data.children.children.some(
      item => typeof item.canExport === 'undefined'
    );
  }

  return data.children.canExport ?? true;
};

export const reorderSchemaAndRecalculateHidden = (
  schema: OriginalAnyDatapointSchema[],
  dropResult: DropResult
) => reorderSchemaFields(schema, dropResult).map(dtp => recalculateHidden(dtp));

export const propagatePropertiesToChildren = (
  node: OriginalAnyDatapointSchema,
  properties: Partial<OriginalAnyDatapointSchema>
): OriginalAnyDatapointSchema => {
  if (node.children) {
    const children = isArray(node.children)
      ? node.children.map(child =>
          propagatePropertiesToChildren(child, properties)
        )
      : propagatePropertiesToChildren(node.children, properties);

    return {
      ...node,
      children,
      ...properties,
    };
  }
  return {
    ...node,
    ...properties,
  };
};

export const recalculateHidden = (
  node: OriginalAnyDatapointSchema
): OriginalAnyDatapointSchema => {
  if (node && node.children) {
    // Don't change visibility if there are no children (otherwise it would hide such objects)
    if (isArray(node.children) && node.children.length === 0) {
      return node;
    }

    const children = isArray(node.children)
      ? node.children.map((ch: OriginalAnyDatapointSchema) =>
          recalculateHidden(ch)
        )
      : recalculateHidden(node.children);

    const isHidden = isArray(children)
      ? !children.some(({ hidden }) => !hidden)
      : children.hidden;

    return {
      ...node,
      children,
      hidden: isHidden ? true : undefined,
    };
  }

  return node;
};

const reorderSchemaFields = (
  schema: OriginalAnyDatapointSchema[],
  dropResult: DropResult
): OriginalAnyDatapointSchema[] => {
  const { source, destination } = dropResult;

  const fromSectionIndex = schema.findIndex(
    section => section.id === source.droppableId
  );

  const toSectionIndex = schema.findIndex(
    section => section.id === destination.droppableId
  );

  if (fromSectionIndex === -1 || toSectionIndex === -1) return schema;

  return schema.map((section, index) => {
    // We know that top-level DatapointSchema objects have children as array,
    // so this is just to make TS happy
    if (!(section.children instanceof Array)) return section;

    // Swap
    if (index === fromSectionIndex && index === toSectionIndex) {
      return {
        ...section,
        children: swapItemsInArray(
          section.children,
          source.index,
          destination.index
        ),
      };
    }

    // Move - source
    if (index === fromSectionIndex) {
      return {
        ...section,
        children: removeIndexInArray(section.children, source.index),
      };
    }

    // Move - destination
    if (index === toSectionIndex) {
      // Sections always have children array
      const movedField = (
        schema[fromSectionIndex].children as Array<OriginalAnyDatapointSchema>
      )[source.index];

      return {
        ...section,
        children: insertInArray(
          section.children,
          destination.index,
          movedField
        ),
      };
    }
    return section;
  });
};

// Type guards for flattenWithPaths
const isArrayLike = (x: unknown): x is unknown[] => Array.isArray(x);
const isPlainObjectLike = (x: unknown): x is Record<string, unknown> =>
  typeof x === 'object' && x !== null && !Array.isArray(x);
/**
 * Extract leafs with their paths from a tree consisting of arrays/objects.
 * E.g. used for getting errors from schema validation results.
 *
 * It doesn't return FieldPath<typeof obj> type, because it doesn't work, when
 * 'obj' is the errors structure from RHF (compiler runs out of memory...).
 * So if you want to have a better type you need to provide it explicitly
 * For errors you can use FieldPath<FormState>, since the structure is the same
 */
export const flattenWithPaths = <T, P = string>(
  obj: unknown[] | Record<string, unknown>,
  isLeaf: (value: unknown, key: string) => value is T,
  pathPrefix = ''
): [P, T][] =>
  reduce<unknown[] | Record<string, unknown>, [P, T][]>(
    obj,
    (result, value, key) => {
      const newPathPrefix = `${pathPrefix}${key}` as P;
      const valueToConcat: [P, T][] = isLeaf(value, key)
        ? [[newPathPrefix, value]]
        : isArrayLike(value)
          ? value.flatMap((item, i) =>
              flattenWithPaths([item], isLeaf, `${newPathPrefix}.${i}.`)
            )
          : isPlainObjectLike(value)
            ? flattenWithPaths(value, isLeaf, `${newPathPrefix}.`)
            : [];

      return result.concat(valueToConcat);
    },
    []
  );

/**
 * YUP uses array[0].property
 * RHF uses array.0.property
 */
export const yupToReactHookFormPathNotation = (path: string) =>
  path.replaceAll(/\[(\d+)\]/g, (_match, index) => `.${index}`);

export const isStringOrStringArray = (x: unknown): x is string | string[] =>
  typeof x === 'string' || (x instanceof Array && typeof x[0] === 'string');

// TODO object parameter + custom equality
export const usePendingChanges = <T extends FieldValues>(
  watch: UseFormWatch<T>,
  defaultValues: T,
  setPendingChanges?: (pendingChanges: boolean) => void
) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  watch(values => {
    const pendingChanges = !isEqual(values, defaultValues);
    setHasPendingChanges(pendingChanges);

    if (setPendingChanges) {
      setPendingChanges(pendingChanges);
    }
  });

  // using it like a componentWillUnmount
  useEffect(
    () => (setPendingChanges ? () => setPendingChanges(false) : undefined),
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return hasPendingChanges;
};
