import { Link, Stack } from '@rossum/ui/material';
import { Link as RouterLink } from 'react-router-dom';
import { globals, RowModel } from '../hooks/useRows';
import { CellIcons } from './CellIcons';
import CellValueSkeleton from './CellValueSkeleton';
import { resolveMessagesByPriority } from './utils';

const DocumentCell = ({
  value,
  globals: { messages, automationBlockers },
  annotationId,
}: {
  annotationId: number;
  value: string | undefined;
  globals: RowModel[typeof globals];
}) => {
  return value === undefined ? (
    <CellValueSkeleton />
  ) : (
    <Stack direction="row" spacing={1} width="100%">
      <Stack>
        <CellIcons
          type={null}
          messages={resolveMessagesByPriority(messages ?? [])}
          automationBlockers={automationBlockers}
        />
      </Stack>

      <Link
        component={RouterLink}
        to={`/document/${annotationId}`}
        target="_blank"
        sx={{
          color: 'inherit',
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          overflow: 'hidden',
          '&:hover': {
            color: 'inherit',
          },
        }}
      >
        {value}
      </Link>
    </Stack>
  );
};

export default DocumentCell;
