import { ValidationError } from '@rossum/rossum-ui/ValidationError';
import { InfoOutlined } from '@rossum/ui/icons';
import {
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import {
  Control,
  Controller,
  FieldError,
  useController,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SwitchControl from '../../../../../components/ReactHookForm/controls/SwitchControl';
import TextFieldControl from '../../../../../components/ReactHookForm/controls/TextFieldControl';
import FormTypeahead from '../../../../../components/ReactHookForm/FormTypeahead';
import SelectItems from '../../../../../components/ReactHookForm/SelectItems';
import { EXTRACTED_FIELD_TYPES_LINK } from '../../../../../constants/values';
import FormulaEditor from '../../../../../features/formulas/FormulaEditor/FormulaEditor';
import CalculatePreviewButton from '../../../../../features/formulas/FormulaPreview/CalculatePreviewButton';
import { FormulaPreviewContextProvider } from '../../../../../features/formulas/FormulaPreview/contexts/formulaPreview/formulaPreviewContext';
import FormulaPreview from '../../../../../features/formulas/FormulaPreview/FormulaPreview';
import TemplateSuggesterButton from '../../../../../features/formulas/FormulaSuggester/TemplateSuggesterButton';
import { RELEASE_DATE_OF_NEW_FIELDS_TYPE } from '../../../../../features/queue-settings/fields/constants';
import { linebreak, link } from '../../../../../lib/formaterValues';
import { formulaFieldsEnabledSelector } from '../../../../../redux/modules/organizationGroup/selectors';
import { currentQueueFromPathnameSelector } from '../../../../../redux/modules/queues/selector';
import { userSelector } from '../../../../../redux/modules/user/selectors';
import {
  FieldEditability,
  FieldType,
  FieldTypeLabels,
} from '../../../../../types/schema';
import { useFeatureFlag } from '../../../../../unleash/useFeatureFlag';
import FormLabel from '../../../../User/components/FormLabel';
import EnumEditorInDrawer from '../../QueueSchema/components/EnumEditorInDrawer';
import styles from '../style.module.sass';
import {
  isMultivalueFieldType,
  NamePrefixType,
  SchemaObjectFormData,
  UiFieldTypeFormValues,
} from '../types';

const sectionDatapointTypesOptions: Array<Record<'value', FieldType>> = [
  { value: 'string' },
  { value: 'number' },
  { value: 'date' },
  { value: 'enum' },
  { value: 'button' },
  { value: 'simple-multivalue' },
  { value: 'table-multivalue' },
];

const sectionDatapointTypesLabels: Record<FieldType, FieldTypeLabels> = {
  string: 'String',
  number: 'Number',
  date: 'Date',
  enum: 'Enum (Dropdown options)',
  button: 'Button',
  'simple-multivalue': 'Multivalue field',
  'table-multivalue': 'Table',
};

const nestedDatapointTypesOptions = sectionDatapointTypesOptions.filter(
  o => o.value !== 'simple-multivalue' && o.value !== 'table-multivalue'
);

const fieldTypesOptions: Array<Record<'value', UiFieldTypeFormValues>> = [
  { value: 'captured' },
  { value: 'data' },
  { value: 'manual' },
];

const fieldEditabilityOptions: Array<Record<'value', FieldEditability>> = [
  { value: 'enabled' },
  { value: 'enabled_without_warning' },
  { value: 'disabled' },
];

const numberFormatOptions = [
  { label: '# ##0,#', helpText: '1 230,0', value: '# ##0,#' },
  { label: '# ##0.#', helpText: '1 230.0', value: '# ##0.#' },
  { label: '#,##0.#', helpText: '1,230.0', value: '#,##0.#' },
  { label: `#'##0.#`, helpText: `1'230.0`, value: `#'##0.#` },
  { label: `#.##0,#`, helpText: `1.230,0`, value: `#.##0,#` },
  { label: '# ##0', helpText: '1 230', value: '# ##0' },
  { label: '#,##0', helpText: '1,230', value: '#,##0' },
  { label: `#'##0`, helpText: `1'230`, value: `#'##0` },
  { label: `#.##0`, helpText: `1.230`, value: `#.##0` },
];

const dateFormatOptions = [
  { label: 'D/M/YYYY', helpText: '23/1/2019', value: 'D/M/YYYY' },
  { label: 'MM/DD/YYYY', helpText: '01/23/2019', value: 'MM/DD/YYYY' },
  { label: 'YYYY-MM-DD', helpText: '2019-01-23', value: 'YYYY-MM-DD' },
  { label: 'DD.MM.YY', helpText: '23.01.19', value: 'DD.MM.YY' },
];

export const SimpleFields = ({
  control,
  namePrefix,
}: {
  control: Control<SchemaObjectFormData>;
  namePrefix: NamePrefixType;
}) => {
  const popupForTrustedButtonsEnabled = useFeatureFlag(
    'ac-4438-popup-for-trusted-buttons'
  );
  const scoreThresholdField = useController({
    name: `${namePrefix}scoreThreshold`,
    control,
  });

  const originalData = useWatch({ name: `${namePrefix}originalData`, control });

  const intl = useIntl();
  const user = useSelector(userSelector);
  const currentQueue = useSelector(currentQueueFromPathnameSelector);
  const scoreThresholdPlaceholder = intl.formatMessage(
    {
      id: 'containers.settings.fields.edit.scoreThreshold.placeholder',
    },
    { value: currentQueue?.defaultScoreThreshold }
  );

  const fieldType = useWatch({ control, name: `${namePrefix}fieldType` });
  const uiFieldType = useWatch({ control, name: `${namePrefix}uiFieldType` });
  const { errors } = useFormState({ control });

  const prevUiFieldType = useRef<UiFieldTypeFormValues>(uiFieldType ?? 'unset');

  const isNested = namePrefix !== '';
  const isSimpleValueChildren = namePrefix === 'simpleMultivalueChildren.';

  const fieldTypeIsSection = fieldType === 'section';
  const fieldTypeIsButton = fieldType === 'button';
  const fieldTypeIsEnum = fieldType === 'enum';
  const fieldTypeIsSimpleMultiValue = fieldType === 'simple-multivalue';

  const uiFieldTypeIsManual = uiFieldType === 'manual';
  const uiFieldTypeIsData = uiFieldType === 'data';
  const uiFieldTypeIsFormula = uiFieldType === 'formula';

  const scoreThresholdValue =
    originalData && 'scoreThreshold' in originalData
      ? originalData.scoreThreshold ?? ''
      : '';

  const scoreThresholdOnChange = scoreThresholdField.field.onChange;

  useEffect(() => {
    if (
      uiFieldType === 'captured' &&
      prevUiFieldType.current !== 'unset' &&
      prevUiFieldType.current !== uiFieldType
    ) {
      scoreThresholdOnChange(null);
    } else if (
      uiFieldType === 'unset' &&
      prevUiFieldType.current !== 'captured' &&
      prevUiFieldType.current !== uiFieldType
    ) {
      scoreThresholdOnChange(scoreThresholdValue);
    }

    prevUiFieldType.current = uiFieldType ?? 'unset';
  }, [uiFieldType, scoreThresholdValue, scoreThresholdOnChange]);

  const getErrorMessage = (_: unknown, error?: FieldError) => {
    return error ? error.message : '';
  };

  const dateJoined = user.dateJoined ? new Date(user.dateJoined) : new Date();
  const releaseDate = new Date(RELEASE_DATE_OF_NEW_FIELDS_TYPE);
  const joinedBeforeRelease = dateJoined < releaseDate;

  const legacyFieldTypeOption = joinedBeforeRelease
    ? [{ value: 'unset' as const }]
    : [];

  const formulaFieldsEnabled = useSelector(formulaFieldsEnabledSelector);

  // helper for appending 'formula' after 'data' type, the order will be hardcoded
  // in fieldTypesOptions when we full reveal formula fields
  // but we should still hide formula option for simpleMultivalueChildren datapoints
  const fieldTypesOptionsWithFormula = fieldTypesOptions.flatMap(o =>
    !isSimpleValueChildren && o.value === 'data' && formulaFieldsEnabled
      ? [o, { value: 'formula' as const }]
      : o
  );

  const extendedFieldTypesOptions = fieldTypesOptionsWithFormula.concat(
    legacyFieldTypeOption
  );

  return (
    <>
      <div className={styles.FormField}>
        <FormLabel>
          <FormattedMessage id="containers.settings.fields.edit.label" />
        </FormLabel>
        <div className={styles.FormValue}>
          <TextFieldControl
            ControllerProps={{ control, name: `${namePrefix}label` }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.fields.edit.label.placeholder',
            })}
            sx={{ width: '100%' }}
            FieldLabelProps={{ layout: 'none' }}
          />
        </div>
      </div>
      <div className={styles.FormField}>
        <FormLabel>
          <FormattedMessage id="containers.settings.fields.edit.id" />
          <Tooltip
            placement="top"
            title={intl.formatMessage({
              id: 'containers.settings.fields.edit.id.tooltip',
            })}
          >
            <InfoOutlined />
          </Tooltip>
        </FormLabel>
        <div className={styles.FormValue}>
          <TextFieldControl
            ControllerProps={{ control, name: `${namePrefix}id` }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.fields.edit.id.placeholder',
            })}
            sx={{ width: '100%' }}
            FieldLabelProps={{ layout: 'none' }}
          />
        </div>
      </div>
      {!fieldTypeIsSection && (
        <div className={styles.FormField}>
          <FormLabel>
            <FormattedMessage id="containers.settings.fields.edit.fieldType" />
          </FormLabel>
          <div className={styles.FormValue}>
            <SelectItems<SchemaObjectFormData, FieldType>
              withDescription={false}
              name={`${namePrefix}fieldType`}
              control={control}
              options={
                isNested
                  ? nestedDatapointTypesOptions
                  : sectionDatapointTypesOptions
              }
              getLabel={value => sectionDatapointTypesLabels[value]}
              placeholderKey="containers.settings.fields.edit.fieldType.placeholder"
              dataCy="data-type-drop-down"
            />
          </div>
        </div>
      )}

      {!fieldTypeIsSection &&
        !fieldTypeIsButton &&
        !isMultivalueFieldType(fieldType) && (
          <div className={styles.FormField}>
            <FormLabel>
              <FormattedMessage id="containers.settings.fields.edit.uiFieldType" />
            </FormLabel>
            <div className={styles.FormValue}>
              <SelectItems<SchemaObjectFormData, UiFieldTypeFormValues>
                withDescription
                name={`${namePrefix}uiFieldType`}
                control={control}
                options={extendedFieldTypesOptions}
                getLabel={value =>
                  intl.formatMessage({
                    id: `containers.settings.fields.edit.uiFieldType.option.${value}`,
                  })
                }
                getDescription={value =>
                  intl.formatMessage({
                    id: `containers.settings.fields.edit.uiFieldType.option.${value}.description`,
                  })
                }
                placeholderKey="containers.settings.fields.edit.uiFieldType.placeholder"
              />
            </div>
          </div>
        )}
      {!fieldTypeIsSection &&
        !fieldTypeIsButton &&
        !isMultivalueFieldType(fieldType) &&
        !uiFieldTypeIsFormula && (
          <div className={styles.FormField}>
            <FormLabel>
              <FormattedMessage id="containers.settings.fields.edit.edit" />
            </FormLabel>
            <div className={styles.FormValue}>
              <SelectItems<SchemaObjectFormData, FieldEditability>
                withDescription
                name={`${namePrefix}edit`}
                control={control}
                options={fieldEditabilityOptions}
                getLabel={value =>
                  intl.formatMessage({
                    id: `containers.settings.fields.edit.edit.${value}`,
                  })
                }
                getDescription={value =>
                  intl.formatMessage(
                    {
                      id: `containers.settings.fields.edit.edit.${value}.description`,
                    },
                    { linebreak }
                  )
                }
                placeholderKey="containers.settings.fields.edit.edit.placeholder"
              />
            </div>
          </div>
        )}
      {!isMultivalueFieldType(fieldType) &&
        !fieldTypeIsSection &&
        !fieldTypeIsButton &&
        !uiFieldTypeIsManual &&
        !uiFieldTypeIsData &&
        !uiFieldTypeIsFormula && (
          <div className={styles.FormField}>
            <FormLabel>
              <FormattedMessage id="containers.settings.fields.edit.scoreThreshold" />
            </FormLabel>
            <div className={styles.FormValue}>
              <TextFieldControl
                ControllerProps={{
                  control,
                  name: `${namePrefix}scoreThreshold`,
                }}
                placeholder={scoreThresholdPlaceholder}
                sx={{ width: '100%' }}
                FieldLabelProps={{ layout: 'none' }}
              />
            </div>
          </div>
        )}
      {['number', 'date'].includes(fieldType) && (
        <div className={styles.FormField}>
          <FormLabel>
            <FormattedMessage id="containers.settings.fields.edit.format" />
          </FormLabel>
          <div className={styles.FormValue}>
            <FormTypeahead
              name={`${namePrefix}format`}
              control={control}
              getErrorMessage={getErrorMessage}
              options={
                fieldType === 'number' ? numberFormatOptions : dateFormatOptions
              }
              getOptionLabel={option =>
                typeof option === 'string' ? option : option?.label
              }
              disablePortal
              getOptionValue={option => option?.value}
              renderOption={(props, option) => (
                // We can separate this into a component but it's a good example of how inversion
                // of control is good for us
                // I'd prefer Ivan's suggestion to split this entire form into components
                // per field and not mind it being verbose-ish
                // This can become a component _later_
                <ListItem
                  key={option.value}
                  {...props}
                  dense
                  secondaryAction={
                    <Typography variant="caption" sx={{ opacity: 0.5 }}>
                      {option.helpText}
                    </Typography>
                  }
                >
                  <ListItemText>{option.label}</ListItemText>
                </ListItem>
              )}
              freeSolo
              size="small"
            />
          </div>
        </div>
      )}
      {!isMultivalueFieldType(fieldType) &&
        !fieldTypeIsButton &&
        !fieldTypeIsSection && (
          <div className={styles.FormField}>
            <FormLabel>
              <FormattedMessage id="containers.settings.fields.edit.required" />
              <Tooltip
                placement="top"
                title={intl.formatMessage({
                  id: 'containers.settings.fields.edit.required.tooltip',
                })}
              >
                <InfoOutlined />
              </Tooltip>
            </FormLabel>
            <div className={clsx(styles.FormValue, styles.SwitchControl)}>
              <SwitchControl
                ControllerProps={{ control, name: `${namePrefix}required` }}
                label=""
                size="small"
                data-cy={`${namePrefix}required`}
              />
            </div>
          </div>
        )}

      {!fieldTypeIsSection && (
        <div className={styles.FormField}>
          <FormLabel>
            <FormattedMessage id="containers.settings.fields.edit.visible" />
          </FormLabel>
          <div className={clsx(styles.FormValue, styles.SwitchControl)}>
            <SwitchControl
              ControllerProps={{ control, name: `${namePrefix}hidden` }}
              label=""
              size="small"
              showInvertedValue
              data-cy={`${namePrefix}hidden`}
            />
          </div>
        </div>
      )}
      {!fieldTypeIsSection && (
        <div className={styles.FormField}>
          <FormLabel>
            <FormattedMessage id="containers.settings.fields.edit.canExport" />
          </FormLabel>
          <div className={clsx(styles.FormValue, styles.SwitchControl)}>
            <SwitchControl
              ControllerProps={{ control, name: `${namePrefix}canExport` }}
              label=""
              size="small"
              data-cy={`${namePrefix}canExport`}
            />
          </div>
        </div>
      )}
      {fieldTypeIsButton && (
        <div className={styles.FormField}>
          <FormLabel>
            <FormattedMessage id="containers.settings.fields.edit.popupUrl.label" />
          </FormLabel>
          <div className={styles.FormValue}>
            <TextFieldControl
              ControllerProps={{ control, name: `${namePrefix}popupUrl` }}
              placeholder={intl.formatMessage({
                id: 'containers.settings.fields.edit.popupUrl.placeholder',
              })}
              sx={{ width: '100%' }}
              FieldLabelProps={{ layout: 'none' }}
            />
          </div>
        </div>
      )}
      {fieldTypeIsButton && popupForTrustedButtonsEnabled && (
        <div className={styles.FormField}>
          <FormLabel>
            <FormattedMessage id="features.queueSettings.fields.form.canObtainToken.label" />
          </FormLabel>
          <div className={clsx(styles.FormValue, styles.SwitchControl)}>
            <SwitchControl
              ControllerProps={{ control, name: `${namePrefix}canObtainToken` }}
              label=""
              size="small"
              data-cy={`${namePrefix}canObtainToken`}
            />
          </div>
        </div>
      )}
      {!fieldTypeIsButton &&
        !fieldTypeIsSimpleMultiValue &&
        !fieldTypeIsSection &&
        !uiFieldTypeIsFormula &&
        !uiFieldTypeIsData &&
        !uiFieldTypeIsManual && (
          <>
            <div className={styles.SectionLabel}>
              <FormattedMessage id="containers.settings.fields.edit.rirFieldNames.title" />
            </div>
            <div className={styles.SectionDescription}>
              <FormattedMessage
                id="containers.settings.fields.edit.rirFieldNames.description"
                values={{ link: link(EXTRACTED_FIELD_TYPES_LINK), linebreak }}
              />
            </div>
            <div className={styles.FormField}>
              <FormLabel>
                <FormattedMessage id="containers.settings.fields.edit.rirFieldNames" />
              </FormLabel>
              <div className={styles.FormValue}>
                <FormTypeahead
                  name={`${namePrefix}rirFieldNames`}
                  control={control}
                  disablePortal
                  getErrorMessage={getErrorMessage}
                  options={[]}
                  freeSolo
                  multiple
                  size="small"
                />
              </div>
            </div>
          </>
        )}
      {uiFieldTypeIsFormula && (
        <FormulaPreviewContextProvider
          currentQueueId={currentQueue?.id}
          namePrefix={namePrefix}
          hasError={!!Object.keys(errors).length}
        >
          <Stack
            className={styles.SectionLabel}
            spacing={1}
            direction="row"
            alignItems="center"
          >
            <span>
              {intl.formatMessage({
                id: 'containers.settings.fields.edit.formula.title',
              })}
            </span>
          </Stack>
          <div className={styles.SectionDescription}>
            <FormattedMessage id="containers.settings.fields.edit.formula.description" />
          </div>
          <Controller
            control={control}
            name={`${namePrefix}formula`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Stack spacing={1} sx={{ marginBottom: 3 }}>
                <FormulaEditor
                  onChange={onChange}
                  value={value}
                  renderButtons={({ editor }) => (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CalculatePreviewButton />
                      <TemplateSuggesterButton editor={editor} />
                    </Stack>
                  )}
                />
                {error && error.message ? (
                  <ValidationError errorMessage={error.message} />
                ) : null}
              </Stack>
            )}
          />
          {currentQueue?.id ? <FormulaPreview /> : null}
        </FormulaPreviewContextProvider>
      )}
      {fieldTypeIsEnum && (
        <Controller
          control={control}
          name={`${namePrefix}options`}
          render={({ field: { value, onChange } }) => (
            <EnumEditorInDrawer
              options={value || []}
              onCurrentSchemaPartChange={options => onChange(options)}
            />
          )}
        />
      )}
    </>
  );
};
