import { EngineFieldType } from '@rossum/api-client/engineFields';
import { Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { Control, Controller, useController, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import SingleCheckboxControl from '../../../components/ReactHookForm/controls/SingleCheckboxControl';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { Paragraphs } from './Paragraphs';
import { PretrainedFieldsInput } from './PretrainedFieldsInput';
import { TypesSubtypesInput } from './TypesSubtypesInput';

type FieldFormProps = {
  control: Control<{
    field: {
      label: string;
      name: string;
      uptrainFrom: string | null;
      type: EngineFieldType;
      subtype: string;
      tabular: boolean;
      multiline: string;
    };
  }>;

  // A lot of booleans to share this component between fields to capture and engine detail.
  isCompact?: boolean;
  tabular?: boolean;
  disableName?: boolean;
};

const Wrapper = ({
  title,
  description,
  children,
  isCompact,
}: {
  title: ReactNode;
  description: ReactNode;
  children: ReactNode;
  isCompact?: boolean;
}) => {
  return isCompact ? (
    <>{children}</>
  ) : (
    <ContentGroup title={title} description={description}>
      {children}
    </ContentGroup>
  );
};

export const FieldForm = ({
  control,
  isCompact,
  tabular,
  disableName,
}: FieldFormProps) => {
  const intl = useIntl();
  const uptrainFrom = useWatch({
    control,
    name: 'field.uptrainFrom',
  });

  const {
    field: { value: subtype, onChange: setSubtype },
    fieldState: { error: subtypeError },
  } = useController({
    control,
    name: 'field.subtype',
  });

  const {
    field: { value: type, onChange: setType },
    fieldState: { error: typeError },
  } = useController({
    control,
    name: 'field.type',
  });

  const {
    field: { onChange: setTabular },
  } = useController({
    control,
    name: 'field.tabular',
  });

  const {
    field: { onChange: setMultiline },
  } = useController({
    control,
    name: 'field.multiline',
  });

  return (
    <Stack spacing={isCompact ? 2 : 4}>
      <Wrapper
        isCompact={isCompact}
        title={intl.formatMessage({
          id: 'features.engines.fieldDetail.basicInformation.title',
        })}
        description={
          <Paragraphs>
            {intl.formatMessage({
              id: 'features.engines.fieldDetail.basicInformation.description',
            })}
          </Paragraphs>
        }
      >
        <TextFieldControl
          autoFocus
          ControllerProps={{ control, name: 'field.label' }}
          label={intl.formatMessage({
            id: 'features.engines.fieldDetail.basicInformation.field.name',
          })}
          FieldLabelProps={{
            layout: 'floating',
          }}
          size="medium"
        />
        <TextFieldControl
          disabled={disableName}
          ControllerProps={{ control, name: 'field.name' }}
          label={intl.formatMessage({
            id: 'features.engines.fieldDetail.basicInformation.field.id',
          })}
          FieldLabelProps={{
            layout: 'floating',
          }}
          size="medium"
        />
        <Controller
          control={control}
          render={({ field }) => (
            <PretrainedFieldsInput
              {...field}
              tabular={tabular}
              onChange={(value, dataType) => {
                field.onChange(value);

                if (dataType) {
                  setType(dataType.type);
                  setSubtype(dataType.subtype);
                }

                if (dataType && tabular === undefined) {
                  // If tabular is not set up on parent, then it can be modified.
                  setTabular(dataType.tabular);
                }

                setMultiline(dataType ? dataType.multiline : 'false');
              }}
            />
          )}
          name="field.uptrainFrom"
        />
      </Wrapper>

      <Wrapper
        isCompact={isCompact}
        title={intl.formatMessage({
          id: 'features.engines.fieldDetail.type.title',
        })}
        description={
          <Paragraphs>
            {intl.formatMessage({
              id: 'features.engines.fieldDetail.type.description',
            })}
          </Paragraphs>
        }
      >
        <TypesSubtypesInput
          onChange={value => {
            setType(value?.type ?? '');
            setSubtype(value?.subtype ?? '');
          }}
          value={{
            type,
            subtype,
          }}
          disabled={!!uptrainFrom}
          TextFieldProps={{
            error: Boolean(typeError) || Boolean(subtypeError),
            helperText: typeError?.message || subtypeError?.message,
          }}
        />
        <SingleCheckboxControl
          FieldLabelProps={{ layout: 'none' }}
          ControllerProps={{ control, name: 'field.tabular' }}
          label={intl.formatMessage({
            id: 'features.engines.engineDetail.basicInformation.field.tabular',
          })}
          size="medium"
          // If tabular is set in parent, don't let people change it.
          disabled={!!uptrainFrom || tabular !== undefined}
        />
      </Wrapper>
    </Stack>
  );
};
