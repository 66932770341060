import { Tab, Tabs } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { tabs } from '../../components/AppBar/navigationStructure';
import { useEngineHeaderProps } from '../../features/engines/useEngineHeaderProps';
import { Header } from '../../ui/header/Header';
import { AutomationBreadcrumbs } from './AutomationBreadcrumbs';
import { usePageKey } from './usePageKey';

export const AutomationHeader = ({
  scrollableDivRef,
}: {
  scrollableDivRef: React.RefObject<HTMLDivElement>;
}) => {
  const headerTabs = tabs.find(tab => tab.name === 'automation')?.menu ?? [];
  const intl = useIntl();
  const history = useHistory();
  const { pageKey, showTabs } = usePageKey();

  const automationSettingsHeaderProps = {
    title: intl.formatMessage({
      id: 'containers.settings.automation.header.title',
    }),
    description: intl.formatMessage({
      id: 'containers.settings.automation.header.description',
    }),
    buttons: [],
  };
  const aiEnginesHeaderProps = useEngineHeaderProps();

  const headerPropsMap = {
    automationSettings: automationSettingsHeaderProps,
    aiEngines: aiEnginesHeaderProps,
  };

  return (
    <Header
      {...headerPropsMap[pageKey]}
      scrollableDivRef={scrollableDivRef}
      breadcrumbs={<AutomationBreadcrumbs pageKey={pageKey} />}
      tabs={
        showTabs ? (
          <Tabs
            value={pageKey}
            onChange={(_, value) => {
              const link = headerTabs.find(tab => tab.name === value)?.link;
              if (link) {
                history.push(link);
              }
            }}
          >
            {headerTabs.map(tab => (
              <Tab
                key={tab.name}
                label={intl.formatMessage({
                  id: `components.appBar.menu.${tab.translationKey}`,
                })}
                data-cy={`tab-${tab.translationKey}`}
                value={tab.name}
              />
            ))}
          </Tabs>
        ) : null
      }
    />
  );
};
