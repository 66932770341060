import { endpoints } from '@rossum/api-client';
import { SuggestFormulaResponse } from '@rossum/api-client/internal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash';
import * as R from 'remeda';
import { api } from '../../../lib/apiClient';
import { useCurrentSchemaConceptContext } from '../FormulaPreview/contexts/currentSchema/currentSchemaContext';
import { Template } from './shared';

const narrowSuggestionResultsToTemplates = (
  data: SuggestFormulaResponse
): Array<Template> =>
  data.results.map(r => ({
    title: r.name,
    description: r.description,
    snippet: r.formula,
    example: '',
    auroraSuggestion: true,
  }));

export const useSuggestFormula = (debouncedSearch: string) => {
  const { getCurrentSchemaConcept, schemaObjectPath } =
    useCurrentSchemaConceptContext();
  const schemaContent = getCurrentSchemaConcept();
  const fieldSchemaId = get(schemaContent, schemaObjectPath)?.id;

  const queryClient = useQueryClient();

  // to bypass the validation of formula, we need to set it to empty string
  const schemaContentWithEmptyFormula = R.setPath(
    schemaContent,
    // @ts-expect-error can it even be typesafe??
    schemaObjectPath
      .split('.')
      .map(pathPart =>
        Number.isNaN(Number(pathPart)) ? pathPart : Number(pathPart)
      )
      .concat('formula'),
    ''
  );

  const queryKey = [
    'formula-suggestions',
    fieldSchemaId,
    debouncedSearch,
  ] as const;

  const auroraSuggestionsQuery = useQuery({
    queryKey,
    queryFn: () => {
      return api.request(
        endpoints.internal.schemas.suggestFormula({
          hint: debouncedSearch,
          schemaContent: schemaContentWithEmptyFormula,
          fieldSchemaId,
        })
      );
    },
    select: narrowSuggestionResultsToTemplates,
    enabled: !!(debouncedSearch.length && fieldSchemaId),
    cacheTime: 0, // clears data on closing popover suggester,
    onError: () => {
      // since we do not have whole schema content object in query key,
      // we need to invalidate it manually by setting results to empty array
      queryClient.setQueryData(queryKey, () => ({
        results: [],
      }));
    },
  });

  return auroraSuggestionsQuery;
};
