import { Typography } from '@rossum/ui/material';
import React from 'react';
import { Control, FieldError, UseFormSetFocus } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledSelect from '../../../../../components/ReactHookForm/Select';
import ValidationInput from '../../../../../components/ReactHookForm/ValidationInput';
import { regions } from '../../../../../constants/regions';
import { docsLinks } from '../../../../../constants/values';
import { linebreak, link } from '../../../../../lib/formaterValues';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import styles from '../../../styles.module.sass';
import { QueueSettingsData, SessionTimeout } from './formType';
import MultiInput from './MultiInput';
import QueueSettingsLabel from './QueueSettingsLabel';

type GeneralProps = {
  id: number | undefined;
  inbox?: string;
  clearValidationsOnChange: (
    _id: string
  ) => (
    _handleChange: (_e: React.ChangeEvent<unknown>) => void
  ) => (_event: React.ChangeEvent<unknown>) => void;
  getErrorMessage: (_id: string, _errorMessage: FieldError) => string;
  control: Control<QueueSettingsData>;
  sessionTimeoutInputs: SessionTimeout;
  setFocus: UseFormSetFocus<QueueSettingsData>;
};

const General = ({
  id,
  clearValidationsOnChange,
  control,
  getErrorMessage,
  inbox,
  sessionTimeoutInputs,
  setFocus,
}: GeneralProps) => {
  const intl = useIntl();

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.general',
      })}
    >
      <div className={styles.Row}>
        <div>
          <Typography>
            {intl.formatMessage({
              id: 'containers.settings.queues.name.label',
            })}
          </Typography>
        </div>
        <div>
          <ValidationInput<QueueSettingsData>
            clearValidationsOnChange={clearValidationsOnChange('name')}
            getErrorMessage={getErrorMessage}
            name="name"
            control={control}
            dataCy="basic-setting-name-input"
            placeholder={intl.formatMessage({
              id: 'containers.settings.queues.name.placeholder',
            })}
          />
        </div>
      </div>
      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel
            titleId="containers.settings.queues.documentRegion.label"
            tooltipId="containers.settings.queues.documentRegion.tooltip"
          />
        </div>
        <div data-cy="basic-setting-document-region-select">
          <ControlledSelect<QueueSettingsData>
            control={control}
            name="locale"
            options={regions}
            placeholder={intl.formatMessage({
              id: `containers.settings.queues.locale.placeholder`,
            })}
          />
        </div>
      </div>

      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel
            titleId="containers.settings.queues.sessionTimeout.label"
            tooltipId="containers.settings.queues.sessionTimeout.tooltip"
          />
        </div>
        <div data-cy="basic-setting-session-timeout-select">
          <MultiInput
            clearValidationsOnChange={clearValidationsOnChange}
            control={control}
            getErrorMessage={getErrorMessage}
            inputs={sessionTimeoutInputs}
            intl={intl}
            setFocus={setFocus}
          />
        </div>
      </div>
      {inbox && (
        <div className={styles.Row}>
          <div>
            <QueueSettingsLabel
              titleId="containers.settings.queues.inbox.label"
              tooltipId="containers.settings.queues.inbox.tooltip"
              tooltipValues={{
                link: link(docsLinks.inbox),
                linebreak,
              }}
            />
          </div>
          <div>
            <div className={styles.ReadOnlyValue}>{inbox}</div>
          </div>
        </div>
      )}
      {!!id && (
        <div className={styles.Row}>
          <div>
            <QueueSettingsLabel titleId="containers.settings.queues.id.label" />
          </div>
          <div>
            <div className={styles.ReadOnlyValue}>{id}</div>
          </div>
        </div>
      )}
    </PaperSection>
  );
};

export default General;
