import { z } from 'zod';

const softLimitSchema = z
  .object({
    ignore: z.boolean(),
    used: z.number(),
    limit: z.number(),
  })
  .catch({
    ignore: true,
    used: 0,
    limit: 0,
  });

const getHardLimitSchema = (fallbackValue: boolean) =>
  z.object({ purchased: z.boolean() }).catch({
    purchased: fallbackValue,
  });

type Features = {
  labels?: { enabled?: boolean };
  usageReports?: { enabled?: boolean };
  attachments?: { enabled?: boolean };
};

export const getSubscriptionUsageSchema = <T extends Features | undefined>(
  features: T
) =>
  z.object({
    queues: softLimitSchema,
    documentArchive: softLimitSchema,
    sandboxes: softLimitSchema,
    extensions: getHardLimitSchema(false),
    usageReports: getHardLimitSchema(Boolean(features?.usageReports?.enabled)),
    embeddedMode: getHardLimitSchema(false),
    labels: getHardLimitSchema(Boolean(features?.labels?.enabled)),
    extensionLogs: getHardLimitSchema(false),
    duplicateHandling: getHardLimitSchema(false),
    intelligentMailbox: getHardLimitSchema(false),
    attachments: getHardLimitSchema(Boolean(features?.attachments?.enabled)),
  });

// mimics exactly what comes from BE
export type SubscriptionUsage = z.TypeOf<
  ReturnType<typeof getSubscriptionUsageSchema>
>;

// When pricing is not active for some reason BE sends an empty usage object.
// In this case in FE we add `hasSubscriptionPlan` boolean to our state to be able to handle some components that should behave differently when there is no subscription plan.
export type ActiveSubscriptionUsage = SubscriptionUsage & {
  hasSubscriptionPlan: boolean;
};
export type FeatureKey = keyof SubscriptionUsage;

export type HardLimitKeys = {
  [K in FeatureKey]: 'purchased' extends keyof SubscriptionUsage[K] ? K : never;
}[FeatureKey];

export type SoftLimitKeys = Exclude<FeatureKey, HardLimitKeys>;
export type SoftLimit = z.TypeOf<typeof softLimitSchema>;
