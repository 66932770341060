import { endpoints, withSideload } from '@rossum/api-client';
import {
  AnnotationsSearchPayload,
  AnnotationsSearchQuery,
} from '@rossum/api-client/annotations';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { api } from '../../../lib/apiClient';
import { asScalar } from '../../../lib/url';
import { useTransformAnnotations } from '../../annotation-simple-columns/hooks/useTransformAnnotation';
import {
  useNextCursorStore,
  usePagination,
  useRowCount,
} from '../../document-list/hooks/usePagination';

const SEARCH_DOC_ATTACHMENTS_KEY = 'search-doc-attachments';

type SearchAttachmentsProps = {
  payload: AnnotationsSearchPayload;
};

export const searchAttachmentsPagination = { page: 0, pageSize: 5 };

export const useSearchAttachments = ({ payload }: SearchAttachmentsProps) => {
  const searchValue = payload.queryString?.string;

  const searchRef = useRef(searchValue);

  const {
    searchAfter,
    paginationModel,
    handlePaginationModelChange,
    setNextToMap,
    resetPagination,
    shouldForceSetNextCursor,
  } = usePagination(searchAttachmentsPagination);

  const query: AnnotationsSearchQuery = {
    pageSize: searchAttachmentsPagination.pageSize,
    searchAfter: asScalar(searchAfter),
  };

  const { data, isFetching, isError } = useQuery({
    queryKey: [SEARCH_DOC_ATTACHMENTS_KEY, query, payload],

    queryFn: () =>
      api.request(
        withSideload(endpoints.annotations.search(query, payload), {
          documents: true,
          modifiers: true,
          relations: true,
          childRelations: true,
        })
      ),

    enabled: !!searchValue,
  });

  // go back to the first page evertime we make a new search request
  if (isFetching && searchValue !== searchRef.current) {
    searchRef.current = searchValue;
    resetPagination();
  }

  const { transformedAnnotations, isLoading: isTransforming } =
    useTransformAnnotations({
      annotations: data?.results,
      documents: data?.documents,
      modifiers: data?.modifiers,
      relations: data?.relations,
      childRelations: data?.childRelations,
    });

  const rowCount = useRowCount(data?.pagination.total);

  useNextCursorStore({
    gridIsLoading: isFetching,
    setNextToMap,
    next: data?.pagination.next,
    shouldForceSetNextCursor,
  });

  return {
    attachments: transformedAnnotations,
    isLoading: isTransforming || isFetching,
    rowCount,
    isError,
    paginationContext: {
      rowCount,
      paginationModel,
      handlePaginationModelChange,
    },
  };
};
