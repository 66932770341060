import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../style.module.sass';

type WorkspaceNameProps = {
  badgeComponent: ReactNode;
  className?: string;
  name: string;
};

const WorkspaceName = ({
  className,
  name,
  badgeComponent,
}: WorkspaceNameProps) => (
  <div className={styles.WorkspacePlaceholder}>
    <div
      className={clsx(className, styles.WorkspaceName)}
      data-cy="workspace-name"
    >
      {name}
    </div>
    {badgeComponent}
  </div>
);

export default WorkspaceName;
