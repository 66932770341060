import { yupResolver } from '@hookform/resolvers/yup';
import { EmailTemplate } from '@rossum/api-client/emailTemplates';
import { FieldLabel } from '@rossum/rossum-ui/FieldLabel';
import {
  Block,
  CancelRounded,
  Close,
  Forward,
  Reply,
  Send,
  WatchLater,
} from '@rossum/ui/icons';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  cardHeaderClasses,
  CardProps,
  CircularProgress,
  Grow,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
} from '@rossum/ui/material';
import { EditorState } from 'draft-js';
import { uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import ConditionalAuroraChip from '../../../../components/conditional-aurora-chip/ConditionalAuroraChip';
import EmailAddressValidationTypeahead from '../../../../components/ReactHookForm/controls/EmailAddressValidationTypeaheadControl';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { WysiwygControl2 } from '../../../../components/ReactHookForm/controls/WysiwygControl/WysiwygControl';
import { LocalizationKeys } from '../../../../i18n';
import { editorStateToHtml, htmlToContentState } from '../../../../lib/draft';
import { emailChatCompletionsEnabledSelector } from '../../../../redux/modules/organizationGroup/selectors';
import { Email } from '../../api-client';
import { EmailDocumentsPreview } from '../DocumentEmailCard/EmailDocumentsPreview';
import { AttachedAnnotation, AttachmentT } from '../EmailAttachment/helpers';
import EmailAnnotationsPreview from '../EmailDocumentsPreview';
import EmailSentAlert from '../EmailSent/EmailSentAlert';
import GenerateRejectionEmail from '../GenerateRejectionEmail/GenerateRejectionEmail';
import { useGetFormValues } from './hooks/useGetFormValues';
import { useSendEmail } from './hooks/useSendEmail';
import { useSuggestedRecipients } from './hooks/useSuggestedRecipients';
import { EmailResponseFormMode, SendEmailFormData } from './types';
import { createEmailAddressObject } from './utils';
import { sendEmailValidationSchema } from './validationSchema';

const AI_TEMPLATE = 'ai-template';

type SubmittedValues = {
  subject: string;
  message: string;
};

export type EmailResponseFormProps = {
  queueUrl: string;
  rootEmail?: Email | undefined;
  parentEmailUrl?: string | undefined;
  defaultAttachments: 'loading' | AttachmentT[];
  onClose: (...args: unknown[]) => void;
  onSend?: (email: Email) => void;
  mode: EmailResponseFormMode;
  CardProps?: CardProps;
  emailTemplates: EmailTemplate[];
  animationFinished?: boolean;
  emailThreadUrl?: string;
  attachmentMode?: 'document' | 'annotation';
  parentAnnotation?: AttachedAnnotation;
  hideTemplateButton?: boolean;
};

const formTitleIcon: Record<EmailResponseFormMode, JSX.Element> = {
  forward: <Forward fontSize="small" />,
  forwardDocument: <Forward fontSize="small" />,
  postponeDocument: <WatchLater fontSize="small" />,
  rejectDocument: <Block fontSize="small" />,
  rejectEmail: <Block fontSize="small" />,
  reply: <Reply fontSize="small" />,
};

const formTitle: Record<EmailResponseFormMode, LocalizationKeys> = {
  forward: 'components.emailResponseForm.title.forward',
  forwardDocument: 'components.emailResponseForm.title.forwardDocument',
  postponeDocument: 'components.emailResponseForm.title.postponeDocument',
  rejectDocument: 'components.emailResponseForm.title.rejectDocument',
  rejectEmail: 'components.emailResponseForm.title.rejectEmail',
  reply: 'components.emailResponseForm.title.reply',
};

const EmailResponseForm = ({
  queueUrl,
  rootEmail,
  parentEmailUrl,
  defaultAttachments,
  onClose,
  onSend,
  mode,
  CardProps,
  emailTemplates,
  emailThreadUrl,
  attachmentMode,
  parentAnnotation,
  hideTemplateButton,
}: EmailResponseFormProps) => {
  const intl = useIntl();
  const alertRef = useRef<HTMLDivElement>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { data: suggestedRecipients } = useSuggestedRecipients({
    emailThreadUrl,
    annotationUrl: parentAnnotation?.url,
    queueUrl,
  });

  const suggestedRecipientEmails = uniq(
    suggestedRecipients?.map(({ email }) => email)
  );

  // useMemo to get a stable reference for useEffect to avoid an infinite loop of re-renders
  const wysiwygTemplates = useMemo(
    () =>
      emailTemplates.filter(
        template =>
          template.type === (mode === 'rejectDocument' ? 'rejection' : 'custom')
      ),
    [mode, emailTemplates]
  );

  const [submittedValues, setSubmittedValues] =
    useState<SubmittedValues | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<
    EmailTemplate | undefined
  >(undefined);

  const [ccVisible, setCcVisible] = useState(false);
  const [bccVisible, setBccVisible] = useState(false);

  const formModesWithAttachments: EmailResponseFormMode[] = [
    'forward',
    'forwardDocument',
    'rejectEmail',
    'rejectDocument',
    'postponeDocument',
  ];
  const displayAttachments = formModesWithAttachments.includes(mode);

  const {
    mutate: sendEmail,
    isLoading: isSendingEmail,
    errorType: sendingEmailError,
  } = useSendEmail({
    mode,
    onSend,
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    resetField,
    setValue,
    reset,
  } = useForm<SendEmailFormData>({
    mode: 'onChange',
    defaultValues: {
      to: [],
      cc: [],
      bcc: [],
      message: '',
      subject: '',
      attachments: [],
    },
    resolver: yupResolver(sendEmailValidationSchema(intl)),
  });

  useEffect(() => {
    if (isDirty) {
      if (!ccVisible) {
        resetField('cc');
      }
      if (!bccVisible) {
        resetField('bcc');
      }
    }
  }, [bccVisible, ccVisible, resetField, isDirty]);

  const { getFormValues, isLoading } = useGetFormValues({
    rootEmail,
    mode,
    emailTemplates,
    availableAttachments:
      defaultAttachments === 'loading' ? [] : defaultAttachments,
    contextAnnotationUrl: parentAnnotation?.url,
  });

  const onTemplateChange = useCallback(
    async (templateUrl: string | undefined) => {
      const template = wysiwygTemplates.find(
        template => template.url === templateUrl
      );

      const { templateValues } = await getFormValues(template);
      reset(templateValues);
      setCcVisible(templateValues.cc.length > 0);
      setBccVisible(templateValues.bcc.length > 0);
      setEditorState(e =>
        EditorState.push(
          e,
          htmlToContentState(templateValues.message),
          'change-block-data'
        )
      );
      setSelectedTemplate(template ?? undefined);
    },
    [getFormValues, reset, wysiwygTemplates]
  );

  useEffect(() => {
    onTemplateChange(undefined);
  }, [onTemplateChange]);

  const labels = (() => {
    if (mode === 'rejectDocument' || mode === 'rejectEmail') {
      return ['rejection'];
    }

    if (mode === 'forward' || mode === 'forwardDocument') {
      return ['forwarded'];
    }

    if (mode === 'postponeDocument') {
      return ['postponed'];
    }

    if (mode === 'reply') {
      return [mode];
    }

    return [];
  })();

  const onSubmit = ({
    to,
    cc,
    bcc,
    message,
    subject,
    attachments,
  }: SendEmailFormData) => {
    // those attachments that are annotations and their document is attached
    const relatedAnnotations =
      defaultAttachments !== 'loading'
        ? defaultAttachments
            .filter(att => attachments.includes(att.document.url))
            .flatMap(att => (att.annotation ? [att.annotation.url] : []))
        : [];

    // documents that are attached, optionally extracted from annotation
    const relatedDocuments =
      defaultAttachments !== 'loading'
        ? defaultAttachments
            .map(att => att.document.url)
            .filter(url => attachments.includes(url))
        : [];

    const payload = {
      to: createEmailAddressObject(to),
      cc: ccVisible ? createEmailAddressObject(cc) : [],
      bcc: bccVisible ? createEmailAddressObject(bcc) : [],
      parentEmail: parentEmailUrl,
      queue: queueUrl,
      // arrives in the actual e-mail
      attachments: {
        documents: attachments,
      },
      relatedAnnotations,
      relatedDocuments,
      templateValues: {
        subject,
        message,
      },
      labels,
      emailTemplate: selectedTemplate?.url,
    };

    setSubmittedValues({
      subject,
      message,
    });

    sendEmail({ payload });
  };

  const emailWasSuccessfullySent =
    !!submittedValues && !isSendingEmail && !sendingEmailError;

  const getAttachmentSecondaryAction =
    (onChange: (_attachments: string[]) => void, value: string[]) =>
    (attachment: AttachmentT) => (
      <IconButton
        data-cy="email-delete-attachment"
        aria-label="remove"
        onClick={() => {
          const { url } = attachment.document;
          onChange(value.filter(attachmentUrl => attachmentUrl !== url));
        }}
      >
        <Close fontSize="small" color="inherit" />
      </IconButton>
    );

  const handleAlertClose = () => {
    onClose();
  };

  // scroll to the form when needed
  const scrollAlertIntoView = () => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  const useEmailChatCompletions = useSelector(
    emailChatCompletionsEnabledSelector
  );

  const showGenerateEmailButton =
    labels.find(l => l === 'rejection') && useEmailChatCompletions;

  const [aiTemplateSelected, setAiTemplateSelected] = useState<boolean>(
    !!showGenerateEmailButton
  );

  return (
    <SwitchTransition>
      <Grow
        key={emailWasSuccessfullySent ? 'alert' : 'form'}
        appear
        unmountOnExit
        onEntering={scrollAlertIntoView}
      >
        {emailWasSuccessfullySent && submittedValues ? (
          <Box ref={alertRef}>
            <EmailSentAlert
              hideTemplateButton={hideTemplateButton}
              sentEmail={{
                subject: submittedValues.subject,
                message: submittedValues.message,
                queueUrl,
                type: mode === 'rejectDocument' ? 'rejection' : 'custom',
              }}
              parentEmail={rootEmail}
              emailTemplate={selectedTemplate}
              onClose={handleAlertClose}
            />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Card
                elevation={8}
                sx={{
                  boxShadow: theme => theme.shadows[2],
                }}
                {...CardProps}
              >
                <CardHeader
                  sx={{
                    [`.${cardHeaderClasses.avatar}`]: {
                      alignSelf: 'flex-start',
                    },
                  }}
                  avatar={formTitleIcon[mode]}
                  titleTypographyProps={{
                    variant: 'body1',
                  }}
                  title={intl.formatMessage({ id: formTitle[mode] })}
                  action={
                    <IconButton size="small" onClick={onClose}>
                      <Close
                        sx={{ color: theme => theme.palette.text.secondary }}
                      />
                    </IconButton>
                  }
                />
                <CardContent sx={{ pt: '0px' }}>
                  <Stack spacing={3} px={5} mb={2}>
                    {!hideTemplateButton && (
                      <FieldLabel
                        layout="floating"
                        label={intl.formatMessage({
                          id: 'components.emailTemplateForm.chooseTemplate',
                        })}
                      >
                        <TextField
                          select
                          size="small"
                          InputProps={{
                            endAdornment: isLoading ? (
                              <Box mr={2}>
                                <CircularProgress size={20} />
                              </Box>
                            ) : undefined,
                          }}
                          autoFocus
                          value={
                            selectedTemplate?.url ??
                            (showGenerateEmailButton ? AI_TEMPLATE : 'default')
                          }
                          label={intl.formatMessage({
                            id: 'components.emailTemplateForm.chooseTemplate',
                          })}
                          onChange={e => {
                            setAiTemplateSelected(
                              e.target.value === AI_TEMPLATE
                            );
                            onTemplateChange(e.target.value);
                          }}
                        >
                          {showGenerateEmailButton ? (
                            <MenuItem value={AI_TEMPLATE}>
                              <Stack direction="row" gap={1}>
                                {intl.formatMessage({
                                  id: 'components.emailResponseForm.autoCompose.templateName',
                                })}
                                <ConditionalAuroraChip size="small" />
                              </Stack>
                            </MenuItem>
                          ) : (
                            <MenuItem value="default">
                              {intl.formatMessage({
                                id: 'components.emailResponseForm.noTemplate',
                              })}
                            </MenuItem>
                          )}
                          {wysiwygTemplates.map(template => (
                            <MenuItem
                              key={template.url}
                              value={template.url}
                              data-cy={`queue-form-workspace-item-${template.id}`}
                            >
                              {template.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FieldLabel>
                    )}

                    <EmailAddressValidationTypeahead
                      control={control}
                      options={suggestedRecipientEmails}
                      name="to"
                      label={intl.formatMessage({
                        id: 'components.emailResponseForm.fields.edit.to.label',
                      })}
                      endAdornment={
                        <Stack
                          direction="row"
                          sx={{
                            marginRight: '32px',
                            position: 'absolute',
                            right: 0,
                          }}
                        >
                          <Link
                            variant="body2"
                            color={ccVisible ? 'primary' : 'inherit'}
                            sx={{
                              mx: 1,
                              cursor: 'pointer',
                            }}
                            onClick={() => setCcVisible(!ccVisible)}
                          >
                            {intl.formatMessage({
                              id: 'components.emailResponseForm.fields.edit.buttons.copy.label',
                            })}
                          </Link>
                          <Link
                            variant="body2"
                            color={bccVisible ? 'primary' : 'inherit'}
                            sx={{
                              mx: 1,
                              cursor: 'pointer',
                            }}
                            onClick={() => setBccVisible(!bccVisible)}
                          >
                            {intl.formatMessage({
                              id: 'components.emailResponseForm.fields.edit.buttons.blindCopy.label',
                            })}
                          </Link>
                        </Stack>
                      }
                    />
                    {ccVisible && (
                      <EmailAddressValidationTypeahead
                        control={control}
                        options={suggestedRecipientEmails}
                        name="cc"
                        label={intl.formatMessage({
                          id: 'components.emailResponseForm.fields.edit.cc.label',
                        })}
                      />
                    )}
                    {bccVisible && (
                      <EmailAddressValidationTypeahead
                        control={control}
                        options={suggestedRecipientEmails}
                        name="bcc"
                        label={intl.formatMessage({
                          id: 'components.emailResponseForm.fields.edit.bcc.label',
                        })}
                      />
                    )}
                    <TextFieldControl
                      ControllerProps={{ control, name: 'subject' }}
                      FieldLabelProps={{
                        layout: 'floating',
                      }}
                      label={intl.formatMessage({
                        id: 'components.emailResponseForm.fields.subject.label',
                      })}
                    />
                    {showGenerateEmailButton &&
                      parentAnnotation &&
                      aiTemplateSelected && (
                        <GenerateRejectionEmail
                          editorStateHtmlValue={editorStateToHtml(editorState)}
                          annotationUrl={parentAnnotation.url}
                          handleGeneratedMessage={message => {
                            setEditorState(e => {
                              const newEditorState = EditorState.push(
                                e,
                                htmlToContentState(message),
                                'change-block-data'
                              );
                              setValue(
                                'message',
                                editorStateToHtml(newEditorState)
                              );

                              return newEditorState;
                            });
                          }}
                        />
                      )}
                    <WysiwygControl2
                      onChange={newEditorState => {
                        setEditorState(newEditorState);
                        setValue('message', editorStateToHtml(newEditorState));
                      }}
                      value={editorState}
                    />
                    {displayAttachments && (
                      <Controller
                        control={control}
                        name="attachments"
                        render={({ field: { value, onChange } }) =>
                          attachmentMode === 'document' ? (
                            <EmailDocumentsPreview
                              documents={
                                defaultAttachments !== 'loading'
                                  ? defaultAttachments
                                      ?.filter(attch =>
                                        value.includes(attch.document.url)
                                      )
                                      .map(attachment => attachment.document)
                                  : []
                              }
                            />
                          ) : (
                            <EmailAnnotationsPreview
                              annotationCount={value.length}
                              attachments={
                                defaultAttachments !== 'loading'
                                  ? defaultAttachments?.filter(attch =>
                                      value.includes(attch.document.url)
                                    )
                                  : 'loading'
                              }
                              getSecondaryAction={getAttachmentSecondaryAction(
                                onChange,
                                value
                              )}
                            />
                          )
                        }
                      />
                    )}
                  </Stack>
                  <CardActions sx={{ px: 5, justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      data-cy="email-footer-button-send"
                      type="submit"
                      sx={{ alignSelf: 'flex-end' }}
                      startIcon={<Send />}
                      endIcon={
                        isSendingEmail ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : null
                      }
                      disabled={
                        !isValid ||
                        isSendingEmail ||
                        defaultAttachments === 'loading'
                      }
                    >
                      {intl.formatMessage({
                        id: `components.emailResponseForm.fields.edit.buttons.send.${mode}.label`,
                      })}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      data-cy="email-footer-button-cancel"
                      sx={{ alignSelf: 'flex-end' }}
                      startIcon={<CancelRounded />}
                      onClick={onClose}
                      disabled={isSendingEmail}
                    >
                      {intl.formatMessage({
                        id: 'components.emailResponseForm.fields.edit.buttons.cancel.label',
                      })}
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
              <Grow
                in={!!sendingEmailError}
                appear
                unmountOnExit
                onEntering={scrollAlertIntoView}
              >
                <Alert severity="error" variant="filled" ref={alertRef}>
                  {sendingEmailError &&
                    intl.formatMessage({
                      id: `components.emailResponseForm.${sendingEmailError}`,
                    })}
                </Alert>
              </Grow>
            </Stack>
          </form>
        )}
      </Grow>
    </SwitchTransition>
  );
};

export default EmailResponseForm;
