/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  KeyboardArrowDownRounded,
  NavigateNextRounded,
  Sos,
} from '@rossum/ui/icons';
import {
  Box,
  Button,
  Collapse,
  Fade,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { IntlShape, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSectionsAccordions } from '../../hooks/useSectionsAccordions';
import {
  containerHasItem,
  isContainerIdentifier,
  itemContainer,
  itemIndex,
  itemPosition,
  moveItem,
} from '../helpers';
import { DraggableRow } from './DraggableRow';
import { QuickActionField, QuickActionIcon } from './form/QuickActionIcon';
import { SortableContainer } from './SortableContainer';

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

type SchemaFieldBulkAction = {
  key: QuickActionField;
  // `true` <===> ALL fields have this `true`
  value: boolean;
  onClick: () => void;
};

export type FieldsListProps = {
  items: Record<UniqueIdentifier, UniqueIdentifier[]>;
  renderFieldItem: (
    fieldId: UniqueIdentifier,
    isActive: boolean,
    isDragging: boolean,
    parentId?: UniqueIdentifier
  ) => JSX.Element | null;
  sectionsData: Record<UniqueIdentifier, { label: string; isHidden?: boolean }>;
  newFieldPathForSection?: (parentId: UniqueIdentifier) => string;
  bulkActionsForSection?: (
    sectionId: UniqueIdentifier
  ) => ReadonlyArray<SchemaFieldBulkAction>;
  detailPathForSection?: (sectionId: UniqueIdentifier) => string;
  onItemsReorder: (
    from: readonly [UniqueIdentifier, number],
    to: readonly [UniqueIdentifier, number]
  ) => void;
  sectionsAccordionsProps?: ReturnType<typeof useSectionsAccordions>;
};

const getActionTooltip = (
  intl: IntlShape,
  actionKey: QuickActionField,
  value: boolean
) => {
  const action = intl
    .formatMessage({
      id: `features.queueSettings.fields.quickActions.${actionKey}`,
    })
    .toLowerCase();

  return intl.formatMessage(
    {
      id: `features.queueSettings.fields.quickActions.bulk`,
    },
    {
      action,
      newValue: intl
        .formatMessage({
          id: `features.queueSettings.fields.quickActions.${
            value ? 'off' : 'on'
          }`,
        })
        .toLowerCase(),
    }
  );
};

const BULK_ACTIONS_CLASS = 'bulk-actions';

const AddNewFieldButton = ({ to }: { to: string }) => {
  const intl = useIntl();

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Button
        variant="contained"
        component={Link}
        to={to}
        // TODO: How to fix these things for Link Buttons?
        sx={{
          '&:hover': {
            color: theme => theme.palette.text.primary,
          },
        }}
      >
        {intl.formatMessage({
          id: 'features.queueSettings.actions.addField',
        })}
      </Button>
    </Stack>
  );
};

export const FieldsList = ({
  items,
  renderFieldItem,
  sectionsData,
  newFieldPathForSection,
  bulkActionsForSection,
  detailPathForSection,
  onItemsReorder,
  sectionsAccordionsProps,
}: FieldsListProps) => {
  // Used to render correct items order during dragging
  const [localItems, setLocalItems] = useState(items);

  // store the original path of the dragged item (because it can move between containers during drag)
  // maybe it could be a ref instead of state
  const [draggedItem, setDraggedItem] = useState<{
    containerId: UniqueIdentifier;
    itemId: UniqueIdentifier;
    index: number;
  } | null>(null);

  const sections = Object.keys(localItems) as UniqueIdentifier[];

  const intl = useIntl();

  const { sectionsAccordionsState, toggleSectionAccordion } =
    sectionsAccordionsProps ?? {};

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (active.data.current) {
      setDraggedItem({
        containerId: active.data.current.sortable.containerId,
        itemId: active.id,
        index: active.data.current.sortable.index,
      });
    }
  };

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over || !active) {
        return;
      }

      // if `over` is a container, it means it is empty, so just move `active` to the top of it
      if (
        isContainerIdentifier(over.id)(localItems) &&
        !containerHasItem(over.id, active.id)(localItems)
      ) {
        setLocalItems(moveItem(active.id, [over.id, 0]));

        return;
      }

      const sourceContainer = itemContainer(active.id)(localItems);
      const targetContainer = itemContainer(over.id)(localItems);
      const targetIndex = itemIndex(over.id)(localItems);

      // nothing happens if the `active` item didn't change container
      if (
        !targetContainer ||
        !sourceContainer ||
        sourceContainer === targetContainer ||
        typeof targetIndex !== 'number' ||
        targetIndex === -1
      ) {
        return;
      }

      // move `active` to `targetIndex` in `targetContainer`
      setLocalItems(moveItem(active.id, [targetContainer, targetIndex]));
    },
    [localItems]
  );

  // TODO: Add handling of failed update (revert sorted order?)
  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!active || !over || !draggedItem) {
        return;
      }

      // same as in `handleDragOver`, if `over` is a container, put item on top of it
      if (isContainerIdentifier(over.id)(localItems)) {
        onItemsReorder(
          [draggedItem.containerId, draggedItem.index],
          [over.id, 0]
        );

        // localItems should be up to date but just in case they aren't, update
        if (!containerHasItem(over.id, active.id)(localItems)) {
          setLocalItems(moveItem(active.id, [over.id, 0]));
        }

        setDraggedItem(null);

        return;
      }

      const sourceContainer = draggedItem.containerId;
      const targetContainer = itemContainer(over.id)(localItems);

      // same container and same `over` and `active` === noop
      if (sourceContainer === targetContainer) {
        if (active.id === over.id) {
          setDraggedItem(null);
          return;
        }
      }

      const to = itemPosition(over.id)(localItems);

      if (to) {
        onItemsReorder([draggedItem.containerId, draggedItem.index], to);
        setLocalItems(moveItem(active.id, to));
      }

      setDraggedItem(null);
    },
    [draggedItem, localItems, onItemsReorder]
  );

  const handleDragCancel = useCallback(() => {
    setLocalItems(items);
    setDraggedItem(null);
  }, [items]);

  // TODO: The animated header interferes with dragging when it changes state during drag
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToVerticalAxis]}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      collisionDetection={closestCenter}
    >
      {sections.map(section => {
        const label = sectionsData[section]?.label;
        const isHidden = sectionsData[section]?.isHidden;

        const itemsInSection = localItems[section];

        const bulkActions = bulkActionsForSection
          ? bulkActionsForSection(section)
          : null;

        if (!itemsInSection) return null;

        const pathToDetail = detailPathForSection
          ? detailPathForSection(section)
          : null;

        const pathToNewField = newFieldPathForSection
          ? newFieldPathForSection(section)
          : null;

        const sectionIsExpanded = sectionsAccordionsState?.[section] ?? true;

        return (
          <Stack
            key={section}
            spacing={2}
            sx={{
              // To not need the UI state of DroppableSection, CSS trick
              [`&:is(:hover, :focus, :focus-within) .${BULK_ACTIONS_CLASS}`]: {
                opacity: 1,
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ opacity: isHidden ? 0.7 : 1 }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                {sectionsAccordionsProps ? (
                  <IconButton
                    key="collapse-expand"
                    onClick={() =>
                      toggleSectionAccordion &&
                      toggleSectionAccordion(`${section}`)
                    }
                    sx={{
                      transform: sectionIsExpanded
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                      transition: t =>
                        t.transitions.create('transform', {
                          duration: t.transitions.duration.short,
                        }),
                      '&:hover': {
                        color: theme => theme.palette.action.disabled,
                        textDecoration: 'none',
                      },
                    }}
                  >
                    <KeyboardArrowDownRounded fontSize="small" />
                  </IconButton>
                ) : null}
                <Typography
                  color={isHidden ? 'text.secondary' : 'text.primary'}
                  variant="h5"
                >
                  {label ?? ''}
                </Typography>
              </Stack>
              <Stack
                className={BULK_ACTIONS_CLASS}
                spacing={2}
                direction="row"
                alignItems="center"
                sx={{
                  mx: 1,
                  opacity: 0,
                  transition: t =>
                    t.transitions.create('opacity', {
                      duration: t.transitions.duration.short,
                    }),
                }}
              >
                {bulkActions
                  ? bulkActions.map(({ key, value, onClick }) => (
                      <Tooltip
                        key={key}
                        title={getActionTooltip(intl, key, value)}
                      >
                        <Fade in={sectionIsExpanded}>
                          <IconButton onClick={onClick}>
                            <QuickActionIcon
                              fieldKey={key}
                              state={!value}
                              disabled
                            />
                          </IconButton>
                        </Fade>
                      </Tooltip>
                    ))
                  : null}
                {pathToDetail ? (
                  <IconButton
                    key="navigate"
                    component={Link}
                    to={pathToDetail}
                    sx={{
                      color: theme => theme.palette.action.disabled,
                      '&:hover': {
                        color: theme => theme.palette.action.disabled,
                        textDecoration: 'none',
                      },
                    }}
                  >
                    <NavigateNextRounded fontSize="small" />
                  </IconButton>
                ) : (
                  // This is to fill in the empty space
                  <IconButton sx={{ visibility: 'hidden' }} color="error">
                    <Sos fontSize="small" />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <Collapse in={sectionIsExpanded} timeout="auto" unmountOnExit>
              <Stack spacing={2}>
                <Box sx={{ opacity: isHidden ? 0.7 : 1 }}>
                  <SortableContainer
                    key={section}
                    id={section}
                    items={itemsInSection}
                  >
                    {itemsInSection.length ? (
                      itemsInSection.map(item => (
                        <DraggableRow
                          key={item}
                          id={item}
                          renderChildren={(isActive, isDragging) =>
                            renderFieldItem(item, isActive, isDragging, section)
                          }
                        />
                      ))
                    ) : (
                      <Stack alignItems="center" spacing={2}>
                        <Typography variant="h5" color="text.secondary">
                          {intl.formatMessage({
                            id: 'features.queueSettings.fields.sections.emptyState.title',
                          })}
                        </Typography>
                        <Typography
                          component="div"
                          color="text.secondary"
                          textAlign="center"
                        >
                          {intl.formatMessage({
                            id: 'features.queueSettings.fields.sections.emptyState.description',
                          })}
                        </Typography>

                        {pathToNewField ? (
                          <AddNewFieldButton to={pathToNewField} />
                        ) : null}
                      </Stack>
                    )}
                  </SortableContainer>
                </Box>
                {pathToNewField && itemsInSection.length ? (
                  <AddNewFieldButton to={pathToNewField} />
                ) : null}
              </Stack>
            </Collapse>
          </Stack>
        );
      })}
      {createPortal(
        <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
          {draggedItem ? (
            <DraggableRow
              id={draggedItem.itemId}
              renderChildren={(isActive, isDragging) =>
                renderFieldItem(draggedItem.itemId, isActive, isDragging)
              }
              isPlaceholder
            />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};
