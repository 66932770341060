import {
  SchemaDatapointButton,
  SchemaDatapointDate,
  SchemaDatapointEnum,
  SchemaDatapointNumber,
  SchemaDatapointString,
  SchemaSection,
  SchemaSimpleMultivalue,
  SchemaTableMultivalue,
  SchemaTuple,
} from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { FieldsFormModel } from '../formModels';
import {
  UnknownRecord,
  ValueTypeAtStringPath,
} from '../transformations-utils/types';

export type AllKeys<T> = T extends unknown ? keyof T : never;

type CombineProps2<S extends UnknownRecord, T extends UnknownRecord> = {
  [K in keyof T | keyof S]:
    | (K extends keyof T ? T[K] : never)
    | (K extends keyof S ? S[K] : never);
};

type CombineProps<T extends Array<unknown>> = T extends [
  infer First extends UnknownRecord,
  infer Second extends UnknownRecord,
  ...infer Tail,
]
  ? CombineProps<[CombineProps2<First, Second>, ...Tail]>
  : T extends [infer First extends UnknownRecord]
    ? First
    : never;

// Represents an object that contains _all_ available properties of `SchemaItem` or `SchemaSection`
// export type AnySchemaItem = Required<Merge<SchemaSection | SchemaItem>>;
export type AnySchemaItem = CombineProps<
  [
    SchemaDatapointString,
    SchemaDatapointNumber,
    SchemaDatapointDate,
    SchemaDatapointEnum,
    SchemaDatapointButton,
    SchemaSimpleMultivalue,
    SchemaTableMultivalue,
    SchemaSection,
    SchemaTuple,
  ]
>;

type TypeAt<Path extends string> = ValueTypeAtStringPath<AnySchemaItem, Path>;

export type PropertyExtractor<Path extends string> = (
  fallback: TypeAt<Path>
) => (blueprint: FieldsFormModel) => TypeAt<Path>;

export type PropertyEvolver<Path extends string> = (
  blueprint: FieldsFormModel,
  inChildren?: boolean
) => (fallback: TypeAt<Path>) => TypeAt<Path> | undefined;

const isEmpty = (val: unknown) =>
  val === undefined || val === null || val === '';

// Strips "empty" (see above) values deeply
// TODO: How to better types
export const stripEmpties = <T>(obj: T): T | undefined => {
  return R.pipe(
    obj,
    R.conditional(
      [
        R.isPlainObject,
        R.piped(
          R.entries(),
          R.map(([key, value]) => [
            key,
            key === 'options' // we are not stripping options even if they are empty
              ? value
              : R.pipe(
                  value,
                  R.conditional(
                    [R.isPlainObject, stripEmpties],
                    [R.isArray, arr => R.map(arr, stripEmpties)],
                    R.conditional.defaultCase(R.identity)
                  )
                ),
          ]),
          R.fromEntries,
          R.omitBy(
            R.conditional(
              [R.isPlainObject, obj => R.values(obj).every(isEmpty)],
              R.conditional.defaultCase(isEmpty)
            )
          )
        ),
      ],
      R.conditional.defaultCase(R.identity)
    )
  ) as T | undefined;
};
