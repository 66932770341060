import { PageContainer } from '@rossum/rossum-ui/PageContainer';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useQueueSettingsContext } from '../../../../features/queue-settings/contexts/QueueSettingsContext';
import { schemaLoadedSelector } from '../../../../redux/modules/schema/selectors';
import { Queue } from '../../../../types/queue';
import Loader from '../../../Loader';
import { AutomationPage } from './AutomationPage';

type Props = {
  selectedQueue: Queue;
};

const AutomationDetail = ({ selectedQueue }: Props) => {
  const schemaLoaded = useSelector(schemaLoadedSelector);

  const intl = useIntl();

  const { setHeaderMeta } = useQueueSettingsContext();

  useEffect(() => {
    setHeaderMeta(prevState => ({
      ...prevState,
      title: intl.formatMessage({
        id: `containers.settings.queues.queue.automation`,
      }),
      description: intl.formatMessage({
        id: `containers.settings.queues.queue.automation.description`,
      }),
      buttons: [],
    }));
  }, [intl, setHeaderMeta]);

  if (!schemaLoaded) {
    return (
      <PageContainer data-page-title="automation-detail">
        <Loader />
      </PageContainer>
    );
  }

  return <AutomationPage selectedQueue={selectedQueue} />;
};

export default AutomationDetail;
