import { toString } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { shouldShowIntercomSelector } from '../redux/modules/intercom/selector';
import { organizationSelector } from '../redux/modules/organization/selectors';
import { userSelector } from '../redux/modules/user/selectors';
import { useFeatureFlag } from '../unleash/useFeatureFlag';

export const BootIntercom = () => {
  const intercomFlag = useFeatureFlag('ac-1035-intercom');
  const user = useSelector(userSelector);
  const organization = useSelector(organizationSelector);

  const shouldShowIntercom = useSelector(shouldShowIntercomSelector);

  const { boot, shutdown } = useIntercom();
  useEffect(() => {
    if (shouldShowIntercom && intercomFlag) {
      boot({
        name: `${user.firstName} ${user.lastName}`,
        email: user.username,
        userId: toString(organization.id),
      });
    }
  }, [boot, intercomFlag, organization.id, shouldShowIntercom, user]);

  useEffect(() => {
    if (user.id === -1) {
      shutdown();
    }
  }, [shutdown, user.id]);

  return null;
};
