import { getIDFromUrl, Url } from '@rossum/api-client';
import { ExpandMore } from '@rossum/ui/icons';
import {
  AccordionDetails,
  AccordionSummary,
  CardHeader,
  CardProps,
  Divider,
  Stack,
} from '@rossum/ui/material';
import { compact, uniq } from 'lodash';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteAnnotations } from '../../../redux/modules/annotations/actions';
import { openModal } from '../../../redux/modules/modal/actions';
import { ActivityAccordion } from '../../../ui/activity-accordion/ActivityAccordion';
import DocumentFileName from '../../../ui/filename/DocumentFileName';
import { Email } from '../api-client';
import { AttachmentT } from './EmailAttachment/helpers';
import { EmailAttachmentActions } from './EmailAttachmentActions';
import { EmailCardChip } from './EmailCardChip';
import { EmailCardHeaderSubtitle } from './EmailCardHeaderSubtitle';
import { EmailContent } from './EmailContent';
import EmailDocumentsPreview from './EmailDocumentsPreview';
import { emailSummaryIcon } from './icons';

// TODO: localization of the dates will require a bit more magic, see:
// https://robertmarshall.dev/blog/dynamically-import-datefns-locale-mui-datepicker-localization/
// const usersLocale = localStorage.getItem('locale');

const annotationIdsForEmail = (email: Email): number[] => {
  const annotationUrls =
    email.type === 'incoming'
      ? email.annotations ?? []
      : email.relatedAnnotations ?? [];
  return compact(uniq(annotationUrls.map(getIDFromUrl)));
};

const areAttachmentsValid = (
  emailAttachments: 'loading' | AttachmentT[] | undefined
): emailAttachments is AttachmentT[] =>
  !!emailAttachments &&
  emailAttachments !== 'loading' &&
  !!emailAttachments.length;

type EmailCardProps = CardProps & {
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  email: Email;
  emailAttachments: 'loading' | AttachmentT[] | undefined;
  shouldDisplayAttachmentError?: boolean;
};

const EmailCard = forwardRef<HTMLDivElement, EmailCardProps>(
  (
    {
      expanded,
      setExpanded,
      email,
      emailAttachments,
      shouldDisplayAttachmentError,
    }: EmailCardProps,
    ref
  ) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onDelete = (url: Url) =>
      dispatch(
        openModal({
          textId: 'annotationDelete',
          confirmAction: () => deleteAnnotations([url], true, email.id),
          values: { count: 1 },
        })
      );

    // these are all the attachments that were not purged AND they are not necessarily annotations
    const availableAttachments = areAttachmentsValid(emailAttachments)
      ? emailAttachments.filter(attachment =>
          attachment.annotation
            ? attachment.annotation.status !== 'purged'
            : true
        )
      : [];

    const availableAnnotations = availableAttachments.filter(
      attachment => attachment.annotation
    );

    // resolve chip label - if only one annotation is present, use filename,
    // otherwise use number of annotations (without explicitly fetching them)
    const chipLabel = availableAttachments.length
      ? availableAttachments[0].document.originalFileName
      : '0';

    const annotationCount = availableAnnotations.length;

    const hasActionableDocuments =
      email.type === 'outgoing'
        ? areAttachmentsValid(emailAttachments) && emailAttachments.length !== 0
        : email.documents.length !== 0;

    // We don't account for purged annotations here, we only account for
    // when we initially receive a root email with no attachments whatsoever
    const showAttachmentError =
      shouldDisplayAttachmentError && annotationIdsForEmail(email).length === 0;

    const getSecondaryAction = (attachment: AttachmentT) => (
      <EmailAttachmentActions
        attachment={attachment}
        onDelete={() =>
          attachment.annotation && onDelete(attachment.annotation?.url)
        }
      />
    );

    const label =
      annotationCount === 1 ? (
        <DocumentFileName originalFileName={chipLabel} />
      ) : (
        annotationCount
      );

    return (
      <ActivityAccordion
        elevation={8}
        ref={ref}
        expanded={expanded}
        onChange={setExpanded ? (_, v) => setExpanded(v) : undefined}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <CardHeader
            title={
              email?.subject ||
              intl.formatMessage({
                id: 'components.emailCard.noSubject',
              })
            }
            subheader={
              <EmailCardHeaderSubtitle
                chip={
                  hasActionableDocuments ? (
                    <EmailCardChip
                      label={label}
                      showAttachmentError={!!showAttachmentError}
                    />
                  ) : null
                }
                email={email}
              />
            }
            avatar={
              emailSummaryIcon[email?.labels[0]] || emailSummaryIcon.default
            }
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3} pl={5} pr={5}>
            <Divider />
            <EmailContent email={email} />
            {hasActionableDocuments ? (
              <EmailDocumentsPreview
                attachments={emailAttachments ?? 'loading'}
                shouldDisplayAttachmentError={showAttachmentError}
                annotationCount={annotationCount}
                getSecondaryAction={getSecondaryAction}
              />
            ) : null}
          </Stack>
        </AccordionDetails>
      </ActivityAccordion>
    );
  }
);

export default EmailCard;
