import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { approvalWorkflowsEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';

export const useShowWorkflowActivity = (annotationId: number | undefined) => {
  const useWorkflows = useSelector(approvalWorkflowsEnabledSelector);
  const query = useQuery({
    queryKey: ['showWorkflowActivity', annotationId],

    queryFn: () =>
      api.request(
        endpoints.workflowRuns.list({
          annotation: annotationId,
        })
      ),

    enabled: !!annotationId && !!useWorkflows,
  });
  return query.data && query.data.pagination.total > 0;
};
