import { endpoints } from '@rossum/api-client';
import { QueueListQueryWithFields } from '@rossum/api-client/queues';
import { Queue } from '@rossum/api-client/queues';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const QUERY_KEY_QUEUES = 'queues';

export const useQueues = <FieldsT extends keyof Queue>(
  query: QueueListQueryWithFields<FieldsT>
) =>
  useQuery({
    queryKey: [QUERY_KEY_QUEUES, query] as const,
    queryFn: () => api.request(endpoints.queues.list(query)),
  });
