import { useQuery } from '@tanstack/react-query';

export const QUERY_KEY_USAGE_VALIDITY = 'query-key-usage-validity';

export const useUsageValidity = () => {
  const { data } = useQuery({
    queryKey: [QUERY_KEY_USAGE_VALIDITY] as const,
    queryFn: () => null,
  });

  return data;
};
