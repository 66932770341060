import { useEffect } from 'react';
import { useHistory } from 'react-router';

export type ScrollRestorationProps = {
  scrollableContainer: HTMLElement | null;
};

// For now we just always scroll to top on location change
// We could implment a proper restoration using sessionStorage to remember positions of
// scrollable containers if needed and then retrieve them here
// We would then move this component to the root of the Router
// One hick-up is using custom `scrollableContainer` prop, we could get rid of it and just use
// window scroll. I think its only purpose is re-styled scrollbars?

// TLDR; this is better than broken scroll positions but could be specced out to deliver smooth scrolling UX when navigating
// Also I think newer RR versions have this built-in wink wink
// https://reactrouter.com/en/main/components/scroll-restoration#scrollrestoration-
export const ScrollRestoration = ({
  scrollableContainer,
}: ScrollRestorationProps) => {
  const history = useHistory();
  useEffect(() => {
    if (scrollableContainer) {
      scrollableContainer.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }
  }, [scrollableContainer, history.location.pathname]);

  return null;
};
