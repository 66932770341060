import { Queue } from '@rossum/api-client/queues';
import {
  AnnotationListColumn,
  UserTableConfig,
} from '@rossum/api-client/shared';
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridEventListener,
  GridPinnedColumns,
} from '@rossum/ui/x-data-grid-pro';
import { GridApiPro } from '@rossum/ui/x-data-grid-pro';
import { compact } from 'lodash';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { updateUiSettings } from '../../../redux/modules/user/actions';
import {
  isUserAdmin,
  userSelector,
} from '../../../redux/modules/user/selectors';
import { swapItemsInArray } from '../../../redux/modules/utils';
import {
  getAnnotationListColumns,
  getColumnName,
  preserveMissingColumns,
} from '../columns/helpers';
import { usePatchTableConfig } from '../columns/hooks/usePatchTableConfig';
import { useTableConfig } from '../columns/hooks/useTableConfig';
import { MetaColDef, SchemaColDef } from '../filtering/types';
import { useFetchOrganization } from './useFetchOrganization';

type UseCustomizeTable = {
  apiRef: MutableRefObject<GridApiPro>;
  columnsAreReady: boolean;
  setAreColumnsInMotion: (value: boolean) => void;
  activeQueue: Queue | null;
  columns: Array<SchemaColDef | MetaColDef>;
};

const initialPinnedColumns: Required<GridPinnedColumns> = {
  right: ['actions'],
  left: [GRID_CHECKBOX_SELECTION_FIELD],
};

export const useCustomizeTable = ({
  apiRef,
  columnsAreReady,
  setAreColumnsInMotion,
  activeQueue,
  columns,
}: UseCustomizeTable) => {
  const pinnedColumns = useRef<GridPinnedColumns>(initialPinnedColumns).current;

  const orgId = useSelector(safeOrganizationSelector)?.id;

  const { data: organization } = useFetchOrganization({ orgId });

  const { tableConfig } = useTableConfig({
    queueId: activeQueue?.id ?? 0,
    orgId: organization?.id ?? 0,
  });

  const isUserAdminLike = useSelector(isUserAdmin);

  const { uiSettings } = useSelector(userSelector);

  const { updateColumns } = usePatchTableConfig({
    activeQueue,
  });

  useEffect(() => {
    const startHandler = () => {
      setAreColumnsInMotion(true);
    };

    const endHandler = () => {
      setAreColumnsInMotion(false);
    };

    // The `subscribeEvent` method returns unsubscribe functions for start/end events
    const startEvents = (
      ['columnHeaderDragStart', 'columnResizeStart'] as const
    ).map(event => apiRef.current?.subscribeEvent?.(event, startHandler));

    const endEvents = (
      ['columnHeaderDragEnd', 'columnResizeStop'] as const
    ).map(event => apiRef.current?.subscribeEvent?.(event, endHandler));

    const unsubscribers = compact([...startEvents, ...endEvents]);

    return () => {
      unsubscribers.forEach(unsubscribe => unsubscribe());
    };
    // queueId is needed in the dependency array because this useEffect does not seem to be updated when navigating through queues
    // this results into losing the event handlers on column customization actions
  }, [apiRef, columnsAreReady, setAreColumnsInMotion, activeQueue?.id]);

  const dispatch = useDispatch();

  const handleWidthChange: GridEventListener<'columnWidthChange'> = useCallback(
    changedColumn => {
      const udpateWidth = (col: AnnotationListColumn) => ({
        ...col,
        width:
          changedColumn.colDef.field === getColumnName(col)
            ? changedColumn.width
            : col.width,
      });

      // if table config has never been saved before, we construct a new one and save it here.
      const updatedColumns =
        tableConfig.columns.length > 0
          ? tableConfig.columns.map(udpateWidth)
          : getAnnotationListColumns(columns).map(udpateWidth);

      if (isUserAdminLike) updateColumns(updatedColumns);

      const widthToUpdate = {
        [changedColumn.colDef.field]: changedColumn.width,
      };
      const payload: UserTableConfig = activeQueue
        ? {
            ...uiSettings.documentsDashboard,
            queues: {
              width: { [activeQueue.id]: widthToUpdate },
            },
          }
        : {
            ...uiSettings.documentsDashboard,
            organization: { width: widthToUpdate },
          };

      dispatch(
        updateUiSettings({
          documentsDashboard: payload,
        })
      );
    },
    [
      uiSettings,
      dispatch,
      isUserAdminLike,
      updateColumns,
      columns,
      tableConfig,
      activeQueue,
    ]
  );

  const handleColumnOrderChange: GridEventListener<'columnOrderChange'> =
    useCallback(
      changedColumn => {
        // if table config has never been saved before, we construct a new one and save it here.
        if (tableConfig.columns.length === 0) {
          const targetColumns = getAnnotationListColumns(columns);

          const reorderedColumns = swapItemsInArray(
            targetColumns,
            // - 1 is here because of the first column being the checkbox
            changedColumn.oldIndex - 1,
            changedColumn.targetIndex - 1
          );

          return updateColumns(reorderedColumns);
        }

        const reorderedColumns = swapItemsInArray(
          columns,
          // - 1 is here because of the first column being the checkbox
          changedColumn.oldIndex - 1,
          changedColumn.targetIndex - 1
        );

        const updatedColumns = preserveMissingColumns({
          previousColumns: tableConfig.columns,
          updatedColumns: getAnnotationListColumns(reorderedColumns),
        });

        return updateColumns(updatedColumns);
      },
      [updateColumns, columns, tableConfig]
    );

  return {
    pinnedColumns,
    handleWidthChange,
    handleColumnOrderChange,
    isDisabled: !isUserAdminLike,
  };
};
