import { Pagination, Stack } from '@rossum/ui/material';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router';
import PageSizeSelector from '../../../components/UI/PageSizeSelector';
import { setQueryCreator } from '../../../lib/url';
import styles from './styles.module.sass';

export type ListFooterProps = {
  listLength: number | undefined;
  totalPages: number | undefined;
};

const ListFooter = ({ listLength, totalPages }: ListFooterProps) => {
  const history = useHistory();
  const location = useLocation();
  const { page: currentPage, pageSize } = parse(location.search);

  const setQuery = setQueryCreator(history);

  return (
    <div className={styles.FooterWrapper}>
      <Stack direction="row" alignItems="center" spacing={3}>
        <PageSizeSelector
          setPageSize={pageSize => setQuery({ pageSize: `${pageSize}` })}
          pageSize={pageSize}
        />
        {!!listLength && totalPages && (
          <Pagination
            page={Number(currentPage)}
            count={totalPages}
            onChange={(_, page: number) => setQuery({ page: `${page}` })}
          />
        )}
      </Stack>
    </div>
  );
};

export default ListFooter;
