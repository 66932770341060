import { ActionType, createAction } from 'typesafe-actions';
import { AnnotationTabType } from '../../../containers/AnnotationList/types';
import { ID } from '../../../types/basic';
import { UiSettings, User } from '../../../types/user';

export type PartialUser = Partial<Omit<User, 'uiSettings'>> & {
  uiSettings?: Partial<UiSettings>;
};

export const fetchUser = createAction(
  'FETCH_USER',
  (userId?: ID) => userId
)<ID>();

export const fetchUserFulfilled = createAction(
  'FETCH_USER_FULFILLED',
  (payload: User) => payload
)<User>();

export const updateUser = createAction(
  'UPDATE_USER',
  (payload: PartialUser, _meta: { withMessage?: boolean } = {}) => payload,
  (_payload, meta = {}) => meta
)<PartialUser, { withMessage?: boolean }>();

export const updateUiSettings = createAction(
  'UPDATE_UI_SETTINGS',
  (payload: Partial<UiSettings>) => payload
)<Partial<UiSettings>>();

export const updateUserFulfilled = createAction(
  'UPDATE_USER_FULFILLED',
  (payload: User, _meta: { withMessage?: boolean } = {}) => payload,
  (_payload, meta = {}) => meta
)<User, { withMessage?: boolean }>();

export const updateUserPasswordRejected = createAction(
  'UPDATE_USER_PASSWORD_REJECTED',
  (payload: unknown) => payload
)<unknown>();

export const setTabsSorting = createAction(
  'UPDATE_UI_SETTINGS',
  (tabsSorting: AnnotationTabType[]) => ({ dashboard: { tabsSorting } })
)<{ dashboard: { tabsSorting: AnnotationTabType[] } }>();

export type FetchUser = typeof fetchUser;

export type UserActions = ActionType<
  | FetchUser
  | typeof fetchUserFulfilled
  | typeof updateUser
  | typeof updateUserFulfilled
  | typeof updateUserPasswordRejected
  | typeof updateUiSettings
  | typeof setTabsSorting
>;
