import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { MiniValidationScreen } from '@rossum/mini-validation-screen';
import { Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import {
  Redirect,
  Route as ReactRoute,
  RouteChildrenProps,
  Router,
  Switch,
} from 'react-router';
import { useDefaultListBacklink } from '../components/AnnotationInformation/components/useAnnotationBacklink';
import Announcements from '../components/Announcements';
import AppBar from '../components/AppBar';
import {
  EmbeddedModeGuard,
  ExtensionLogsGuard,
  ExtensionsCreateGuard,
  ExtensionsGuard,
  LabelsGuard,
  NonAdminRestrictor,
  nonAdminRestrictorRoute,
  StatisticsRouteGuard,
} from '../components/Restrictors';
import { DEV_FEATURES_ENABLED } from '../constants/config';
import Dashboard from '../containers/AnnotationList/Dashboard';
import { AutomationRoutes } from '../containers/Automation/AutomationRoutes';
import { automationPath } from '../containers/Automation/helpers';
import { ChangesSaved } from '../containers/ChangesSaved';
import { CHANGES_SAVED_PATH } from '../containers/ChangesSaved/constants';
import DocumentValidation from '../containers/DocumentValidation';
import ExtensionEditor from '../containers/Extension/containers/ExtensionEditor';
import ExtensionSettings from '../containers/Extension/containers/ExtensionSettings';
import Extensions from '../containers/Extensions';
import CreateExtension from '../containers/Extensions/containers/CreateExtension';
import ExtensionsStore from '../containers/Extensions/containers/Store';
import IntegrationTest from '../containers/IntegrationTest';
import IntroScreen from '../containers/IntroScreen';
import Login from '../containers/Login';
import Maintenance from '../containers/Maintenance';
import Modals from '../containers/Modals';
import OrganizationLoader from '../containers/OrganizationLoader';
import PasswordConfirm from '../containers/Password/Confirm';
import PasswordReset from '../containers/Password/Reset';
import PersonalInfo from '../containers/PersonalInfo';
import Queue from '../containers/Queue';
import AutomationDetail from '../containers/Queue/containers/AutomationDetail';
import QueueAccess from '../containers/Queue/containers/QueueAccess';
import Emails from '../containers/Queue/containers/QueueEmails';
import Fields from '../containers/Queue/containers/QueueFields';
import QueueSchema from '../containers/Queue/containers/QueueSchema';
import QueueSettings from '../containers/Queue/containers/QueueSettings';
import Settings from '../containers/Settings';
import Statistics from '../containers/Statistics';
import TimeExpired from '../containers/TimeExpired';
import { usersPath } from '../containers/Users/helpers';
import { UsersRoutes } from '../containers/Users/UsersRoutes';
import { WorkflowRoutes } from '../containers/WorkflowRoutes/WorkflowRoutes';
import RequireAuth from '../decorators/RequireAuth';
import { Billing } from '../features/billing';
import { EditDocument } from '../features/document-edit/EditDocument';
import DocumentList from '../features/document-list/DocumentList';
import { ErrorPage } from '../features/error-page';
import { Logs } from '../features/extensions/logs';
import { FieldManagerComponent } from '../features/field-manager';
import { fieldManagerSettingsPath } from '../features/field-manager/constants';
import { labelsPath } from '../features/labels/components/constants';
import { LabelList } from '../features/labels/components/LabelList';
import { NewLayoutSettingsWrapper } from '../features/queue-settings/routes/NewLayoutSettingsWrapper';
import { QueueSettingsRoute } from '../features/queue-settings/routes/QueueSettingsRoute';
import { TaskContextProvider } from '../features/tasks/TaskContext';
import { TasksDialog } from '../features/tasks/TasksDialog';
import { useLogPageView } from '../lib/gtm';
import { history } from '../redux/configureStore';
import {
  fontFamilySelector,
  monoFontSelector,
} from '../redux/modules/organization/selectors';
import {
  enterCreateExtension,
  enterExtension,
  enterExtensionEditor,
  enterExtensionsList,
  enterLogin,
  enterQueue,
  enterQueueList,
  enterStatistics,
  enterValidation,
  exitQueue,
  leaveExtension,
  leaveExtensionEditor,
  leaveStatistics,
  leaveValidation,
} from '../redux/modules/ui/actions';
import { State } from '../types/state';
import Route from './Route';
import styles from './style.module.sass';

type StateProps = {
  useMonoFont: boolean;
  fontFamily: string;
};

type DispatchProps = {
  enterCreateExtension: () => void;
  enterExtension: () => void;
  enterExtensionEditor: () => void;
  enterExtensionsList: () => void;
  enterLogin: () => void;
  enterQueue: () => void;
  enterQueueList: () => void;
  enterStatistics: () => void;
  enterValidation: () => void;
  exitQueue: () => void;
  leaveExtension: () => void;
  leaveExtensionEditor: () => void;
  leaveStatistics: () => void;
  leaveValidation: () => void;
};

type Props = StateProps & DispatchProps;

const Routes = ({
  enterExtensionsList: _enterExtensionsList,
  enterExtension: _enterExtension,
  enterCreateExtension: _enterCreateExtension,
  enterExtensionEditor: _enterExtensionEditor,
  leaveExtension: _leaveExtension,
  leaveExtensionEditor: _leaveExtensionEditor,
  enterLogin: _enterLogin,
  enterQueue: _enterQueue,
  enterQueueList: _enterQueueList,
  enterStatistics: _enterStatistics,
  enterValidation: _enterValidation,
  exitQueue: _exitQueue,
  leaveStatistics: _leaveStatistics,
  leaveValidation: _leaveValidation,
  useMonoFont,
  fontFamily,
}: Props) => {
  useLogPageView(history);
  useDefaultListBacklink(history);

  // the user will always be defined under RequireAuth hence this flag should always be used within the scope of RequireAuth

  return (
    <Router history={history}>
      <Stack
        className={clsx(
          styles.BasepageContainerLayout,
          useMonoFont && 'monospace',
          'basepageContainerLayout'
        )}
        sx={{
          fontFamily,
          backgroundColor: 'background.default',
        }}
      >
        <Announcements />
        <AppBar />
        <div className={styles.BasepageContainer}>
          <Modals />
          <ReduxToastr
            preventDuplicates
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
          <Switch>
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/timeExpired" component={TimeExpired} />
            <Route path="/error" component={ErrorPage} />
            <Route path={CHANGES_SAVED_PATH} component={ChangesSaved} />
            <Route path="/recovery" component={PasswordReset} />
            <Route path="/resetPassword" component={PasswordConfirm} />
            <Route path="/integration-test" component={IntegrationTest} />

            <Route path="/mobileIntroScreen" component={IntroScreen} />
            <Route path="/organizationLoading" component={OrganizationLoader} />
            <Route exact path="/" component={Login} onEnter={_enterLogin} />
            <RequireAuth>
              <TaskContextProvider>
                <TasksDialog />
                <Switch>
                  <Route path="/documents" component={DocumentList} />
                  <Route path="/annotations/:queueId?" component={Dashboard} />
                  <Route path="/emails/:queueId?" component={Dashboard} />
                  <Route
                    onEnter={_enterValidation}
                    onExit={_leaveValidation}
                    path={[
                      '/document/:annotationId?',
                      '/embedded/document/:annotationId?',
                    ]}
                    render={() => (
                      <EmbeddedModeGuard>
                        <Switch>
                          <Route
                            path={[
                              '/document/:annotationId/edit',
                              '/embedded/document/:annotationId/edit',
                            ]}
                            component={EditDocument}
                          />
                          <Route>
                            {(
                              props: RouteChildrenProps<{
                                annotationId?: string;
                              }>
                            ) => {
                              return props.match?.params.annotationId ? (
                                <DocumentValidation {...props} />
                              ) : (
                                <Redirect to="/" />
                              );
                            }}
                          </Route>
                        </Switch>
                      </EmbeddedModeGuard>
                    )}
                  />
                  {DEV_FEATURES_ENABLED ? (
                    <Route
                      path={['/document-v2/:annotationId']}
                      render={({ match }) => (
                        <MiniValidationScreen
                          annotationId={Number(match.params.annotationId)}
                        />
                      )}
                    />
                  ) : null}
                  <Route path="/requests" component={WorkflowRoutes} />

                  {/* these routes are restricted for non admin users.
                TODO add remaining /settings routes in the path array */}
                  <Route
                    path={[
                      usersPath(),
                      automationPath(),
                      labelsPath(),
                      fieldManagerSettingsPath(),
                    ]}
                    render={() => (
                      <NonAdminRestrictor
                        restrictComponent={<Redirect to="/annotations" />}
                      >
                        <Switch>
                          <Route path={usersPath()} component={UsersRoutes} />
                          <Route
                            path={automationPath()}
                            component={AutomationRoutes}
                          />
                          <Route path={labelsPath()}>
                            <LabelsGuard>
                              <LabelList />
                            </LabelsGuard>
                          </Route>
                          {/* Move to function */}
                          {/* Add lazy loading */}
                          <Route
                            path={fieldManagerSettingsPath()}
                            component={FieldManagerComponent}
                          />
                        </Switch>
                      </NonAdminRestrictor>
                    )}
                  />

                  <Route
                    path={['/users', '/users/:id']}
                    render={({ location }) => (
                      <Redirect
                        to={location.pathname.replace('/users', usersPath())}
                      />
                    )}
                  />
                  <ReactRoute path="/queues/:queueId/settings">
                    {nonAdminRestrictorRoute(routeProps => (
                      <QueueSettingsRoute {...routeProps} />
                    ))}
                  </ReactRoute>

                  <Route path="/settings">
                    {nonAdminRestrictorRoute(
                      ({
                        match: settingsRouteMatch,
                        ...settingsRouteProps
                      }: RouteChildrenProps) =>
                        settingsRouteMatch !== null ? (
                          <Switch>
                            <Route
                              path={`${settingsRouteMatch.path}/queues/:queueId/schema`}
                              component={QueueSchema}
                            />
                            <Route
                              path={`${settingsRouteMatch.path}/extensions/:extensionId/code`}
                              component={ExtensionEditor}
                              onEnter={_enterExtensionEditor}
                              onExit={_leaveExtensionEditor}
                            />
                            <Settings
                              {...settingsRouteProps}
                              match={settingsRouteMatch}
                            >
                              {({ selectedQueue, selectedExtension }) => (
                                <Switch>
                                  <Route
                                    path={`${settingsRouteMatch.path}/automation`}
                                    render={({ location }) => (
                                      <Redirect
                                        to={location.pathname.replace(
                                          `${settingsRouteMatch.path}/automation`,
                                          automationPath()
                                        )}
                                      />
                                    )}
                                  />
                                  <Route
                                    exact
                                    onEnter={_enterCreateExtension}
                                    path={`${settingsRouteMatch.path}/extensions/create`}
                                  >
                                    <ExtensionsCreateGuard>
                                      <CreateExtension />
                                    </ExtensionsCreateGuard>
                                  </Route>
                                  <Route
                                    exact
                                    component={ExtensionsStore}
                                    path={`${settingsRouteMatch.path}/store`}
                                  />
                                  <Route
                                    path={`${settingsRouteMatch.path}/extensions`}
                                    exact
                                    onEnter={_enterExtensionsList}
                                  >
                                    <ExtensionsGuard>
                                      <Extensions />
                                    </ExtensionsGuard>
                                  </Route>
                                  <Route
                                    path={`${settingsRouteMatch.path}/extensions/logs`}
                                    exact
                                  >
                                    <ExtensionLogsGuard>
                                      <Logs />
                                    </ExtensionLogsGuard>
                                  </Route>

                                  <Route
                                    path={`${settingsRouteMatch.path}/queues/:queueId`}
                                    onEnter={_enterQueue}
                                    onExit={_exitQueue}
                                  >
                                    {(
                                      queueRouteProps: RouteChildrenProps<{
                                        queueId?: string;
                                      }>
                                    ) => {
                                      const { match: queueRouteMatch } =
                                        queueRouteProps;
                                      return queueRouteMatch !== null ? (
                                        <Queue
                                          selectedQueue={selectedQueue}
                                          {...queueRouteProps}
                                          match={queueRouteMatch}
                                        >
                                          <Switch>
                                            <Route
                                              path={`${queueRouteMatch.path}/settings`}
                                            >
                                              {selectedQueue ? (
                                                <NewLayoutSettingsWrapper
                                                  routeProps={queueRouteProps}
                                                  route="basic"
                                                >
                                                  <QueueSettings
                                                    selectedQueue={
                                                      selectedQueue
                                                    }
                                                  />
                                                </NewLayoutSettingsWrapper>
                                              ) : null}
                                            </Route>
                                            <Route
                                              path={`${queueRouteMatch.path}/fields`}
                                            >
                                              <NewLayoutSettingsWrapper
                                                routeProps={queueRouteProps}
                                                route="fields"
                                              >
                                                <Fields />
                                              </NewLayoutSettingsWrapper>
                                            </Route>
                                            <Route
                                              path={`${queueRouteMatch.path}/emails`}
                                            >
                                              {selectedQueue ? (
                                                <NewLayoutSettingsWrapper
                                                  routeProps={queueRouteProps}
                                                  route="emails"
                                                >
                                                  <Emails
                                                    selectedQueue={
                                                      selectedQueue
                                                    }
                                                  />
                                                </NewLayoutSettingsWrapper>
                                              ) : null}
                                            </Route>
                                            <Route
                                              path={`${queueRouteMatch.path}/automation`}
                                            >
                                              {selectedQueue ? (
                                                <NewLayoutSettingsWrapper
                                                  routeProps={queueRouteProps}
                                                  route="automation"
                                                >
                                                  <AutomationDetail
                                                    selectedQueue={
                                                      selectedQueue
                                                    }
                                                  />
                                                </NewLayoutSettingsWrapper>
                                              ) : null}
                                            </Route>
                                            <Route
                                              path={`${queueRouteMatch.path}/access`}
                                            >
                                              <NewLayoutSettingsWrapper
                                                routeProps={queueRouteProps}
                                                route="access"
                                              >
                                                <QueueAccess
                                                  {...queueRouteProps}
                                                  match={queueRouteMatch}
                                                  selectedQueue={selectedQueue}
                                                />
                                              </NewLayoutSettingsWrapper>
                                            </Route>
                                            <Route
                                              render={() => (
                                                <Redirect
                                                  to={{
                                                    pathname: `${queueRouteMatch.url}/settings`,
                                                    state: get(
                                                      queueRouteProps,
                                                      'location.state'
                                                    ),
                                                  }}
                                                />
                                              )}
                                            />
                                          </Switch>
                                        </Queue>
                                      ) : null;
                                    }}
                                  </Route>
                                  <Route
                                    onEnter={_enterExtension}
                                    onExit={_leaveExtension}
                                    path={`${settingsRouteMatch.path}/extensions/:extensionId`}
                                  >
                                    {({
                                      match: extensionRouteMatch,
                                    }: RouteChildrenProps) =>
                                      extensionRouteMatch !== null ? (
                                        <Switch>
                                          <Route
                                            path={`${extensionRouteMatch.path}`}
                                          >
                                            {selectedExtension ? (
                                              <ExtensionSettings
                                                selectedExtension={
                                                  selectedExtension
                                                }
                                                url={`${extensionRouteMatch.url}`}
                                              />
                                            ) : null}
                                          </Route>
                                          <Route
                                            render={() => (
                                              <Redirect
                                                to={`${extensionRouteMatch.path}`}
                                              />
                                            )}
                                          />
                                        </Switch>
                                      ) : null
                                    }
                                  </Route>
                                  <Route
                                    render={() => <Redirect to="/documents" />}
                                  />
                                </Switch>
                              )}
                            </Settings>
                          </Switch>
                        ) : null
                    )}
                  </Route>
                  <Route path="/account/:section" component={PersonalInfo} />
                  <Route path="/billing" component={Billing} />
                  <Route
                    path="/statistics"
                    onEnter={_enterStatistics}
                    onExit={_leaveStatistics}
                    render={props => (
                      <StatisticsRouteGuard>
                        <Statistics {...props} />
                      </StatisticsRouteGuard>
                    )}
                  />

                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </TaskContextProvider>
            </RequireAuth>
          </Switch>
        </div>
      </Stack>
    </Router>
  );
};

const mapDispatchToProps = {
  enterCreateExtension,
  enterExtension,
  enterExtensionEditor,
  enterExtensionsList,
  enterLogin,
  enterQueue,
  enterQueueList,
  enterStatistics,
  leaveStatistics,
  enterValidation,
  exitQueue,
  leaveExtension,
  leaveExtensionEditor,
  leaveValidation,
};

const mapStateToProps = (state: State) => ({
  useMonoFont: monoFontSelector(state),
  fontFamily: fontFamilySelector(state),
});

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
