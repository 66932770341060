import { SettingsRounded } from '@rossum/ui/icons';
import { IconButton } from '@rossum/ui/material';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';
import { ViewOptions } from '../hooks/useDashboardQuery';

type Props = {
  queueId: number;
  view: ViewOptions;
};

const settingsAppendixPerView = {
  documents: '',
  emails: '/emails',
};

export const SettingsButton = ({ queueId, view }: Props) => {
  const {
    location: { pathname, search },
  } = useHistory();
  const isNewQueueSettingsLayout = useFeatureFlag(
    'ac-4366-queue-settings-layout-v2'
  );

  const route = isNewQueueSettingsLayout
    ? `/queues/${queueId}/settings${settingsAppendixPerView[view]}`
    : `/settings/queues/${queueId}${settingsAppendixPerView[view]}`;

  return (
    <IconButton
      data-cy="queue-settings-btn"
      sx={{
        color: 'secondary.main',
        '&:hover': {
          color: theme => theme.palette.secondary.main,
        },
        '&:visited': {
          color: theme => theme.palette.secondary.main,
        },
      }}
      to={{
        pathname: route,
        state: { backLink: `${pathname}${search}` },
      }}
      component={Link}
    >
      <SettingsRounded />
    </IconButton>
  );
};
