import { PersonAddAlt } from '@rossum/ui/icons';
import { Button, Dialog } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';
import AddUserDialogContent from './AddUserDialogContent';

const MODAL_WIDTH = 436;

const AddUserDialog = () => {
  const [open, setOpen] = useState(false);

  const intl = useIntl();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<PersonAddAlt />}
        data-cy="users-add-user-button"
      >
        {intl.formatMessage({
          id: 'containers.settings.sidebar.users.addUser',
        })}
      </Button>

      <Dialog
        data-cy="add-user-modal"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: MODAL_WIDTH, position: 'fixed' },
          elevation: 2,
        }}
      >
        {open && (
          <>
            <DialogTitle
              title={intl.formatMessage({
                id: 'containers.settings.sidebar.users.addUser',
              })}
              onClose={handleClose}
              closeDataCy="add-user-close-icon"
            />
            <AddUserDialogContent onCancel={handleClose} />
          </>
        )}
      </Dialog>
    </>
  );
};

export default AddUserDialog;
