import {
  Collapse,
  Dialog,
  DialogContent,
  List,
  Stack,
} from '@rossum/ui/material';
import { sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import DialogTitle from '../../ui/dialog-title/DialogTitle';
import { AnnotationProgress } from './AnnotationProgress';
import { AsyncTaskProgress } from './AsyncTaskProgress';
import { classifyTasks } from './helpers/classifyTasks';
import {
  getTaskDialogCounter,
  getTaskDialogTitle,
} from './helpers/getTaskDialogTitle';
import { useTaskContext } from './TaskContext';
import { UploadsTaskProgress } from './UploadsTaskProgress';
import { useImportingAnnotations } from './useImportingAnnotations';

export const TasksDialog = () => {
  const intl = useIntl();
  const {
    tasks: { asyncTasks, uploadsTasks, importTasks },
    clearAllTasks,
  } = useTaskContext();

  const {
    childAnnotationsQuery: { data: childAnnotations },
    uploadedAnnotationsQuery: { data: uploadedAnnotations },
  } = useImportingAnnotations({
    annotationUrls: importTasks.map(importTask => importTask.annotationUrl),
  });

  const [dialogCollapsed, setDialogCollapsed] = useState(false);

  const asyncTasksSortedByUpdateDate = useMemo(
    () => sortBy(asyncTasks, task => task.dataUpdatedAt).reverse(),
    [asyncTasks]
  );

  const uploadsTasksSortedByUpdateDate = useMemo(
    () => sortBy(uploadsTasks, task => task.dataUpdatedAt).reverse(),
    [uploadsTasks]
  );

  const tasks = classifyTasks({
    importTasks,
    asyncTasks,
    uploadsTasks,
    childAnnotations,
    uploadedAnnotations,
  });

  const intlId = getTaskDialogTitle(tasks);
  const counter = getTaskDialogCounter(tasks);

  return (
    <Dialog
      open={tasks.length > 0}
      hideBackdrop
      disableAutoFocus
      disableEscapeKeyDown
      disableRestoreFocus
      disableScrollLock
      disableEnforceFocus
      sx={{
        position: 'initial',
      }}
      PaperProps={{
        'data-cy': 'async-tasks-dialog',
        sx: {
          position: 'fixed !important',
          bottom: -30,
          right: 0,
          zIndex: theme => theme.zIndex.drawer,
          width: 430,
        },
        elevation: 2,
      }}
    >
      <DialogTitle
        title={`${intl.formatMessage({ id: intlId })} (${counter}/${
          tasks.length
        })`}
        onClose={() => clearAllTasks()}
        onToggleMinimize={() => setDialogCollapsed(collapsed => !collapsed)}
      />
      <Collapse in={!dialogCollapsed} unmountOnExit>
        <DialogContent
          sx={{ p: '0px !important', overflow: 'auto', maxHeight: '300px' }}
        >
          <Stack spacing={3}>
            <List sx={{ p: 1 }}>
              <Stack spacing={1}>
                {[
                  ...(childAnnotations ?? []).map(annotationInfo => {
                    return (
                      <AnnotationProgress
                        key={`annotation-${annotationInfo.annotationUrl}`}
                        importTask={annotationInfo}
                      />
                    );
                  }),
                  ...importTasks.map(importTask => {
                    const freshInfo = uploadedAnnotations?.find(
                      annotation =>
                        annotation.annotationUrl === importTask.annotationUrl
                    );
                    return (
                      <AnnotationProgress
                        key={`annotation-${importTask.annotationUrl}`}
                        importTask={{ ...importTask, ...freshInfo }}
                      />
                    );
                  }),
                  ...asyncTasksSortedByUpdateDate.map((task, index) => (
                    <AsyncTaskProgress
                      key={
                        task.data
                          ? `async-task-${task.data.id}`
                          : `index-${index}`
                      }
                      task={task}
                    />
                  )),
                  ...uploadsTasksSortedByUpdateDate.map(task => (
                    <UploadsTaskProgress
                      key={`uploads-task-${task.taskId}`}
                      task={task}
                    />
                  )),
                ]}
              </Stack>
            </List>
          </Stack>
        </DialogContent>
      </Collapse>
    </Dialog>
  );
};
