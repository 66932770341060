import { Ace } from 'ace-builds';
import {
  SchemaItem,
  SchemaSection,
  SchemaTuple,
} from '@rossum/api-client/schemas';
import langTools from 'ace-builds/src-noconflict/ext-language_tools';
import { useMemo } from 'react';
import { ICommand } from 'react-ace/lib/types';
import * as R from 'remeda';
import { useFormulaPreviewContext } from '../../FormulaPreview/contexts/formulaPreview/formulaPreviewContext';
import { customCommands } from '../constants';
import {
  getAceCompleter,
  getFieldCompletions,
  matchMultivalueDatapointReference,
  matchSchemaFieldRefPrefix,
} from './helpers';

export const useCommands = (
  fields: Array<SchemaSection | SchemaItem>,
  defaultCompleters: Array<Ace.Completer>
): ICommand[] => {
  const { currentFormulaId } = useFormulaPreviewContext();

  const commands: ICommand[] = useMemo(() => {
    const fieldCompletersWithoutPrefix = [
      getAceCompleter(getFieldCompletions(fields), () => {
        langTools.setCompleters(defaultCompleters);
      }),
    ];

    const datapointIdsInTuples = R.fromEntries(
      fields
        .filter((field): field is SchemaTuple => field.category === 'tuple')
        .map(tuple => [tuple.id, tuple.children.map(child => child.id)])
    );

    const allValuesCompleters = [
      getAceCompleter(
        [
          {
            caption: 'all_values',
            value: 'all_values',
            meta: 'attribute',
          } satisfies Ace.Completion,
        ],
        () => {
          langTools.setCompleters(defaultCompleters);
        }
      ),
    ];

    return [
      {
        name: customCommands.fieldsDotCommand,
        bindKey: { win: '.', mac: '.' },
        exec: editor => {
          const pos = editor.selection.getCursor();
          const { session } = editor;
          const curLine = session.getDocument().getLine(pos.row).trim();
          const curTokens = curLine.slice(0, pos.column).split(/\s+/);
          const curCmd = curTokens[0];
          if (!curCmd) return;
          const lastToken = curTokens[curTokens.length - 1];
          if (!lastToken) return;
          editor.insert('.');

          const completers = (() => {
            if (matchSchemaFieldRefPrefix(lastToken)) {
              return fieldCompletersWithoutPrefix;
            }

            if (
              matchMultivalueDatapointReference({
                currentFormulaId,
                datapointIdsInTuples,
                lastToken,
              })
            ) {
              return allValuesCompleters;
            }

            return null;
          })();

          if (completers) {
            // detach currently open completer to replace options with new completers
            // @ts-expect-error wrongly typed ICommandBindKey interface in react-ace
            editor.completer?.detach?.();
            langTools.setCompleters(completers);

            editor.execCommand('startAutocomplete');
          }
        },
      },
    ];
  }, [fields, defaultCompleters, currentFormulaId]);

  return commands;
};
