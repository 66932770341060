import SidebarNavLink from '../../../components/Sidebar/SidebarNavLink';
import { fieldManagerSettingsPath } from '../../../features/field-manager/constants';
import { labelsPath } from '../../../features/labels/components/constants';
import { useLabelsEnabled } from '../../../features/labels/hooks/useLabelsEnabled';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';
import { usersPath } from '../../Users/helpers';
import SidebarSection from '../components/SidebarSection';

export const SettingsSidebarItems = () => {
  const isLabelsEnabled = useLabelsEnabled();
  const isFieldManagerEnabled = useFeatureFlag(
    'ac-4198-field-manager-improvements'
  );
  return (
    <SidebarSection label="containers.settings.sidebar.users">
      <SidebarNavLink
        text="containers.settings.sidebar.item.users"
        to={usersPath()}
        replace
        dataCy="users-sidebar-users-link"
      />
      {isFieldManagerEnabled && (
        <SidebarNavLink
          text="containers.settings.sidebar.item.fieldManager"
          to={fieldManagerSettingsPath()}
          replace
          dataCy="settings-sidebar-field-manager-link"
        />
      )}
      {isLabelsEnabled && (
        <SidebarNavLink
          text="containers.settings.sidebar.item.labels"
          to={labelsPath()}
          replace
          dataCy="settings-sidebar-labels-link"
        />
      )}
    </SidebarSection>
  );
};
