// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { automationLevelSchema } from './automationLevel.schema';
import { queueCountsSchema } from './queueCounts.schema';
import { queueSettingsSchema } from './queueSettings.schema';

export const queueSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: urlSchema,
  workspace: urlSchema.nullable(),
  connector: urlSchema.nullable(),
  webhooks: z.array(urlSchema),
  hooks: z.array(urlSchema),
  schema: urlSchema,
  inbox: urlSchema.nullable(),
  users: z.array(urlSchema),
  sessionTimeout: z.string(),
  rirUrl: urlSchema.nullable(),
  rirParams: z.string().nullable(),
  counts: queueCountsSchema,
  defaultScoreThreshold: z.number(),
  automationEnabled: z.boolean(),
  automationLevel: automationLevelSchema,
  locale: z.string(),
  metadata: z.unknown().optional(),
  useConfirmedState: z.boolean(),
  archiveEnabled: z.boolean(),
  settings: queueSettingsSchema,
  documentLifetime: z.string().nullable(),
  dedicatedEngine: urlSchema.nullable(),
  genericEngine: urlSchema.nullable(),
  engine: urlSchema.nullable(),
  status: z.string(),
  workflows: z.array(z.record(z.union([z.string(), z.number()]))),
  trainingEnabled: z.boolean(),
});
