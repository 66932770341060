import { Annotation } from '@rossum/api-client/annotations';
import { Email } from '@rossum/api-client/email';
import { WorkflowActivity } from '@rossum/api-client/workflowActivities';
import { Skeleton, Stack } from '@rossum/ui/material';
import { DocumentEmailCard } from '../../../containers/EmailDrawer/components/DocumentEmailCard/DocumentEmailCard';
import { ActivityCard } from './ActivityCard';
import { useEmailsForAnnotation } from './hooks/useEmailsForAnnotation';
import { useRequestDetailActivity } from './hooks/useRequestDetailActivity';

type RequestDetailBodyActivityProps = {
  annotation: Annotation;
};

const getSortedActivityEntries = (
  activities: WorkflowActivity[],
  emails: Email[]
) =>
  [
    ...activities.map(entry => ({
      type: 'activity' as const,
      value: entry,
      time: entry.createdAt,
    })),
    ...emails.map(entry => ({
      type: 'email' as const,
      value: entry,
      time: entry.createdAt,
    })),
  ].sort(
    ({ time: time1 }, { time: time2 }) =>
      new Date(time2).getTime() - new Date(time1).getTime()
  );

export const RequestDetailBodyActivity = ({
  annotation,
}: RequestDetailBodyActivityProps) => {
  const { emails, documents } = useEmailsForAnnotation({ annotation });
  const { activity, users, workflowSteps, workflows } =
    useRequestDetailActivity({
      annotation,
    });

  const entries =
    activity.isSuccess && emails.isSuccess
      ? getSortedActivityEntries(activity.data, emails.data)
      : [];

  return (
    <Stack spacing={1} sx={{ mb: 2 }}>
      {(activity.isInitialLoading || emails.isInitialLoading) && (
        <Stack spacing={1} justifyContent="center">
          <Skeleton variant="rectangular" height="70px" />
          <Skeleton variant="rectangular" height="70px" />
          <Skeleton variant="rectangular" height="70px" />
        </Stack>
      )}
      {entries.map(entry => {
        if (entry.type === 'activity') {
          const workflowStep = workflowSteps.data?.results.find(
            step => step.url === entry.value.workflowStep
          );
          const workflowName = workflows.data?.results.find(
            workflow => workflow.url === workflowStep?.workflow
          );
          return (
            <ActivityCard
              key={entry.value.id}
              activity={entry.value}
              user={users.data?.results.find(
                user => user.url === entry.value.createdBy
              )}
              assignees={users.data?.results.filter(user => {
                const { assignees } = entry.value;
                return assignees ? assignees.includes(user.url) : false;
              })}
              workflowStepName={workflowStep?.name}
              workflowName={workflowName?.name}
            />
          );
        }
        return (
          <DocumentEmailCard
            key={entry.value.id}
            email={entry.value}
            documents={documents.data?.filter(document =>
              entry.value.documents.includes(document.url)
            )}
          />
        );
      })}
    </Stack>
  );
};
