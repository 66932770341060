import { Queue } from '@rossum/api-client/queues';
import { PageContainer } from '@rossum/rossum-ui/PageContainer';
import { Stack, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import Brain from 'mdi-react/BrainIcon';
import FolderOutlineIcon from 'mdi-react/FolderOutlineIcon';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { AutomationIconBoxOutline } from '../../components/UI/AutomationIcon';
import Collapse from '../../components/UI/Collapse';
import InfoPlaceholder from '../../components/UI/InfoPlaceHolder';
import ListTable from '../../components/UI/ListTable';
import ListTableRow from '../../components/UI/ListTable/ListTableRow';
import { AUTOMATION_DATA_CAPTURE_LINK } from '../../constants/values';
import { useUnpaginatedDedicatedEngines } from '../../features/legacy-engines/hooks/useUnpaginatedDedicatedEngines';
import { useUnpaginatedGenericEngines } from '../../features/legacy-engines/hooks/useUnpaginatedGenericEngines';
import { useWorkspacesWithQueues } from '../../features/queues/hooks/useWorkspacesWithQueues';
import { boldText, link, white } from '../../lib/formaterValues';
import { filterWorkspaces } from '../../redux/modules/workspaces/helpers';
import SearchInput from '../../ui/search-input/SearchInput';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import Loader from '../Loader/index';
import { NoWorkspacesPlaceholder } from './components/NoWorkspacesPlaceholder';
import WorkspaceName from './components/WorkspaceName';
import styles from './style.module.sass';

type Props = RouteComponentProps;

const Queues = ({ history }: Props) => {
  const { workspacesWithQueues: workspaces } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const [searchValue, setSearchValue] = useState('');
  const intl = useIntl();

  const filteredWorkspaces = searchValue
    ? filterWorkspaces(searchValue, workspaces ?? [])
    : undefined;

  const _workspaces = filteredWorkspaces || workspaces;

  const { data: genericEngines } = useUnpaginatedGenericEngines();
  const { data: dedicatedEngines } = useUnpaginatedDedicatedEngines();
  const engineUrlMap = useMemo(() => {
    const allEngines = [...(genericEngines ?? []), ...(dedicatedEngines ?? [])];
    return new Map(allEngines.map(obj => [obj.url, obj]));
  }, [dedicatedEngines, genericEngines]);

  const findEngineByQueue = (queue: Queue) => {
    const engineUrl = queue.dedicatedEngine ?? queue.genericEngine;
    return engineUrl ? engineUrlMap.get(engineUrl) : undefined;
  };

  const isNewQueueSettingsLayout = useFeatureFlag(
    'ac-4366-queue-settings-layout-v2'
  );

  return (
    <PageContainer maxWidth={false} data-page-title="automation">
      <Stack spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minHeight={35}
        >
          <Typography variant="h6">
            {intl.formatMessage({
              id: 'containers.settings.sidebar.automation',
            })}
          </Typography>
          <SearchInput
            value={searchValue}
            onChange={value => setSearchValue(value)}
          />
        </Stack>
        <div className={styles.InfoBox}>
          <AutomationIconBoxOutline />
          <FormattedMessage
            id="containers.settings.automation.introBanner"
            values={{
              boldText,
              white,
              link: link(AUTOMATION_DATA_CAPTURE_LINK),
            }}
          />
        </div>
        <Typography variant="h6">
          {intl.formatMessage({ id: 'containers.settings.automation.queues' })}
        </Typography>
        {workspaces && !workspaces.length ? (
          <NoWorkspacesPlaceholder />
        ) : filteredWorkspaces && !filteredWorkspaces.length ? (
          <InfoPlaceholder
            icon={<FolderOutlineIcon />}
            title="containers.users.access.noFilteredQueuesFound"
            text="containers.users.access.noFilteredQueuesFound.text"
          />
        ) : workspaces ? (
          _workspaces?.map((workspace, i: number) => (
            <Collapse
              key={workspace.id}
              disabled={!workspace.queues.length}
              name={workspace.name}
              nameComponent={({ name, className, badgeComponent }) => (
                <WorkspaceName
                  className={className}
                  name={name}
                  badgeComponent={badgeComponent}
                />
              )}
              defaultExpand={i === 0}
              forceExpand={searchValue !== ''}
              childrenLength={workspace.queues.length}
            >
              <ListTable>
                <tbody>
                  {workspace.queues.map(queue => {
                    const route = isNewQueueSettingsLayout
                      ? `/queues/${queue.id}/settings/automation`
                      : `/settings/queues/${queue.id}/automation`;
                    return (
                      <ListTableRow
                        onClick={() =>
                          history.push({
                            pathname: route,
                            state: { backLink: '/settings/automation' },
                          })
                        }
                        key={queue.id}
                        dataCy="queue"
                      >
                        <td
                          className={clsx(
                            styles.QueueColumn,
                            styles.MainColumn
                          )}
                        >
                          <div className={styles.Indicator}>{queue.name}</div>
                        </td>
                        <td>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Brain />
                            <span>{findEngineByQueue(queue)?.name ?? '-'}</span>
                          </Stack>
                        </td>
                        <td
                          className={clsx(
                            styles.QueueColumn,
                            styles.SmallColumn
                          )}
                        >
                          <FormattedMessage
                            id={`containers.settings.automation.automationLevel.${queue.automationLevel}`}
                          />
                        </td>
                      </ListTableRow>
                    );
                  })}
                </tbody>
              </ListTable>
            </Collapse>
          ))
        ) : (
          <div className={styles.QueueLoaderContainer}>
            <Loader />
          </div>
        )}
      </Stack>
    </PageContainer>
  );
};

export default Queues;
