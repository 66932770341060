import { styled, SxProps } from '@rossum/ui/material';
import clsx from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';
import styles from './style.module.sass';

type Props = {
  sx?: SxProps;
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  selected?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  isImporting?: boolean;
  dataCy?: string;
};

const CustomTableRow = styled('tr')({});

const ListTableRow = ({
  sx,
  dataCy,
  selected,
  isDisabled,
  children,
  isActive,
  className,
  isImporting,
  onClick,
}: Props) => (
  <CustomTableRow
    sx={sx}
    className={clsx(
      styles.TableRow,
      isDisabled && styles.TableRowDisabled,
      !isDisabled && styles.TableRowHoverable,
      !isDisabled && !isImporting && styles.TableRowFocusable,
      isActive && styles.TableRowActive,
      selected && styles.TableRowSelected,
      className
    )}
    onClick={(...args) => isDisabled || (onClick && onClick(...args))}
    data-cy={dataCy}
  >
    {children}
  </CustomTableRow>
);

ListTableRow.defaultProps = {
  isDisabled: false,
  onClick: () => {},
  selected: false,
  className: '',
  dataCy: undefined,
};

export default ListTableRow;
