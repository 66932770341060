import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { currentDatapointSelector } from '../../../../redux/modules/datapoints/selector';
import { suggestedPositionsForValueSelector } from '../../../../redux/modules/datapoints/suggestedPositionsForValue/selectors';
import { State } from '../../../../types/state';
import { rectangleFromCoordinates } from '../../document-canvas/utils/geometry';
import { getBoxCoordinates } from '../../document-store/documentGeometry';
import { useCanvasGeometryActions } from '../../document-store/DocumentStore';
import { useSuggestedPositionsContext } from '../../SuggestedPositionsContext';
import { UseCanvasDimensions } from '../useCanvasDimensions';

export const useScrollToSuggestedPosition = ({
  dimensions,
}: {
  dimensions: UseCanvasDimensions;
}) => {
  const currentDatapoint = useSelector(currentDatapointSelector);
  const { currentIndex } = useSuggestedPositionsContext();
  const suggestedPositions = useSelector(suggestedPositionsForValueSelector);
  const suggestedBoxes = currentDatapoint
    ? suggestedPositions[currentDatapoint.id]
    : undefined;

  const isSuggestingPositionsForValue = useSelector(
    (state: State) => state.ui.isSuggestingPositionsForValue
  );

  const matrixActions = useCanvasGeometryActions();
  const previousIndex = useRef<number | undefined>(undefined);
  const index = isSuggestingPositionsForValue ? currentIndex : undefined;

  useEffect(() => {
    if (index !== undefined && index !== previousIndex.current) {
      const box = suggestedBoxes?.[currentIndex];

      if (box) {
        matrixActions.translateIntoViewport(
          getBoxCoordinates(
            box.page,
            rectangleFromCoordinates(box.rectangle),
            dimensions.pages
          )
        );
      }
    }
    previousIndex.current = index;
  }, [
    index,
    dimensions.canvas,
    dimensions.pages,
    matrixActions,
    suggestedBoxes,
    currentIndex,
  ]);
};
