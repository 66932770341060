import { getIDFromUrl } from '@rossum/api-client';
import { Annotation } from '@rossum/api-client/annotations';
import { Document } from '@rossum/api-client/documents';
import { Relation } from '@rossum/api-client/relations';
import { User } from '@rossum/api-client/users';
import { useMemo } from 'react';
import { unique } from 'remeda';
import { useUnpaginatedQueuesByIds } from '../../../business/queues/useUnpaginatedQueuesByIds';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { resolveAnnotationEdits } from '../../document-edit/helpers';
import { resolveModifierFromUrl } from '../../document-list/columns/helpers';
import { toDict } from '../../document-list/helpers';
import { resolveAnnotationDuplicates } from '../../duplicates/helpers';

// This hook is designed to return transformed annotations that can be used to feed the rows of the simple DataGrid (used in AttachmentsDrawer, AttachmentsModal or DuplicatesDrawer).
export const useTransformAnnotations = ({
  annotations,
  documents,
  modifiers,
  relations,
  childRelations,
}: {
  annotations: Annotation[] | undefined;
  documents: Document[] | undefined;
  modifiers: User[] | undefined;
  relations?: Relation[];
  childRelations?: Relation[];
}) => {
  const queueIds = annotations?.map(annotation =>
    getIDFromUrl(annotation.queue)
  );

  const { data: queues, isInitialLoading: isQueuesLoading } =
    useUnpaginatedQueuesByIds(
      { id: unique(queueIds ?? []) },
      { enabled: !!queueIds, keepPreviousData: true }
    );

  const transformedAnnotations = useMemo(() => {
    const existingAttachmentRelations = [
      ...(relations ?? []),
      ...(childRelations ?? []),
    ].filter(relation => relation.type === 'attachment');

    return annotations?.map(({ status, ...annotation }) => {
      const queueName = queues?.find(
        queue => queue.url === annotation.queue
      )?.name;

      const documentName =
        documents?.find(doc => doc.url === annotation.document)
          ?.originalFileName ?? null;

      const modifier = resolveModifierFromUrl(
        annotation.modifier,
        toDict(modifiers)
      );

      const edit = relations
        ? resolveAnnotationEdits(annotation.url, relations)
        : undefined;

      const duplicates = relations
        ? resolveAnnotationDuplicates(
            annotation.url,
            annotation.relations,
            relations
          ).map(duplicateUrl =>
            relations?.find(relation => relation.url === duplicateUrl)
          )
        : [];

      return {
        ...annotation,
        restricted_access: annotation.restrictedAccess,
        hasAttachmentRelation: existingAttachmentRelations.some(
          relation =>
            relation.parent === annotation.url ||
            relation.annotations.includes(annotation.url)
        ),
        status: snakeToCamel(status),
        original_file_name: documentName,
        modifier,
        queueName,
        edit,
        duplicates,
      };
    });
  }, [annotations, childRelations, documents, modifiers, queues, relations]);

  return {
    transformedAnnotations,
    isLoading: isQueuesLoading,
  };
};

export type TransformedAnnotation = NonNullable<
  ReturnType<typeof useTransformAnnotations>['transformedAnnotations']
>[number];
