import { useFlag } from '@unleash/proxy-client-react';
import { unleashClient, UnleashFlagName } from './Unleash';

const getLocalUnleashOverride = (name: UnleashFlagName): null | boolean => {
  try {
    const overrides =
      JSON.parse(localStorage.getItem('localUnleashOverrides') || '') ?? {};

    return overrides[name] === undefined ? null : Boolean(overrides[name]);
  } catch {
    // Don't crash the app, if the value is not a valid JSON
    return null;
  }
};

export const useFeatureFlag = (name: UnleashFlagName) =>
  getFeatureFlagValue(name, useFlag);

/**
 * This function is intended for use outside of React,
 * for example in epics.
 */
export const isFeatureFlagEnabled = (name: UnleashFlagName) =>
  getFeatureFlagValue(name, n => unleashClient.isEnabled(n));

const getFeatureFlagValue = (
  name: UnleashFlagName,
  readFromUnleash: (name: string) => boolean
) => {
  // It's important that readFromUnleash is always called the same number of times,
  // otherwise we can break the rules of hooks (if called from useFeatureFlag).
  const valueFromUnleash = readFromUnleash(name);
  const antivalueFromUnleash = readFromUnleash(`anti-${name}`);

  return (
    getLocalUnleashOverride(name) ?? (valueFromUnleash && !antivalueFromUnleash)
  );
};
