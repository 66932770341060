// Generated by ts-to-zod
import { z } from 'zod';
import { engineFieldSchema } from '../models/engineField.schema';

export const engineFieldPatchPayloadSchema = engineFieldSchema
  .partial()
  .pick({
    label: true,
    uptrainFrom: true,
    type: true,
    subtype: true,
    tabular: true,
    multiline: true,
  });
