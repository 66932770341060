import { ReactNode } from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import { QueueSettingsContextProvider } from '../contexts/QueueSettingsContext';

type Props = {
  children: ReactNode;
  routeProps: RouteChildrenProps<{ queueId?: string }, unknown>;
  route: string;
};

export const NewLayoutSettingsWrapper = ({
  children,
  routeProps,
  route,
}: Props) => {
  const { match } = routeProps;

  const isNewQueueSettingsLayout = false;

  if (isNewQueueSettingsLayout && match) {
    return (
      <Redirect
        to={{
          pathname: `/queues/${routeProps.match?.params.queueId}/settings/${route}`,
          state: routeProps.location.state,
        }}
      />
    );
  }

  return (
    <QueueSettingsContextProvider>{children}</QueueSettingsContextProvider>
  );
};
