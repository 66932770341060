import { Typography, TypographyProps } from '@rossum/ui/material';
import { forwardRef } from 'react';

export const ExtensionText = forwardRef<
  HTMLParagraphElement,
  {
    primaryText: string;
    secondaryText: string;
  } & TypographyProps
>(({ primaryText, secondaryText, sx, ...props }, ref) => (
  <Typography
    ref={ref}
    variant="body2"
    sx={{
      color: 'text.disabled',
      span: { color: 'text.primary' },
      fontSize: 12,
      ...sx,
    }}
    {...props}
  >
    {secondaryText} - <span>{primaryText}</span>
  </Typography>
));
