import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { FieldsFormModel } from '../formModels';
import { adjustIds } from './toApiModel.adjusters';
import { backfillValues } from './toApiModel.backfills';
import { makeEvolver } from './toApiModel.evolvers';
import { stripEmpties } from './toApiModel.utils';

// TODO: Can we improve type safety here?
export const toApiModel = (
  originalItem: SchemaSection | SchemaItem | null,
  formModel: FieldsFormModel
): SchemaItem | SchemaSection => {
  return R.pipe(
    formModel,
    R.prop('fieldType'),
    backfillValues(originalItem),
    R.evolve(makeEvolver(formModel)),
    adjustIds(originalItem),
    stripEmpties
    // TODO: Can we get rid of the cast?
  ) as SchemaItem | SchemaSection;
};
